import { isDirty } from 'redux-form'
import { useLocation } from 'react-router-dom'
import { openModal } from '../../actions/app'

/* tabs: {
    isDefault: true/false,
    Component: component to show when tab clicked,
    label: '',
    compId

  },
  showUnsavedModalOnTransitionTab: { compId, formName }
*/

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps.showUnsavedModalOnTransitionTab || {}
  const { tabs } = ownProps

  return {
    hasUnsavedChanges: formName && isDirty(formName)(state),
  }
}

const Tabs = ({ showUnsavedModalOnTransitionTab, hasUnsavedChanges, openModal, tabs }) => {
  const { hash } = useLocation()
  const defaultOpenTab = tabs.find(t => hash === `#${t.compId}Tab`)
  const tabContainer = useRef(null)
  const tabRefs = useRef([])
  const [activeTab, setActiveTab] = useState(
    defaultOpenTab || tabs.find(t => t.isDefault) || tabs[0]
  )
  const { Component, compId, label } = activeTab
  const numOfTabs = tabs.length

  function setScrollToActivePanel() {
    tabContainer.current.scrollIntoView({ behavior: 'smooth' })
  }

  function switchTabs(t) {
    setActiveTab(t)
  }

  function changeTab(t, e) {
    const callback = () => switchTabs(t)
    e.preventDefault()

    if (
      showUnsavedModalOnTransitionTab &&
      showUnsavedModalOnTransitionTab.compId === activeTab.compId &&
      hasUnsavedChanges
    ) {
      openModal('ConfirmUnsavedChangesModal', {
        form: showUnsavedModalOnTransitionTab.formName,
        callback,
      })
    } else {
      callback()
    }

    return false
  }

  function enableKeyboardNavigation(i, e) {
    const { LEFT, RIGHT } = APRICOT.utils.keys
    const tabCount = tabs.length
    const right = e.keyCode === RIGHT
    const left = e.keyCode === LEFT

    if (!right && !left) {
      return
    }

    const index =
      right && i + 1 < tabCount
        ? i + 1
        : right && i + 1 === tabCount
        ? 0
        : left && i - 1 < 0
        ? tabCount - 1
        : i - 1

    tabRefs.current[index].focus()
  }

  useEffect(() => {
    if (defaultOpenTab) {
      setScrollToActivePanel()
      setActiveTab(defaultOpenTab)
    }
  }, [defaultOpenTab])

  return (
    <div>
      <ul className="nav nav-tabs" data-cb-element="no-cb" role="tablist" ref={tabContainer}>
        {tabs.map((t, i) => {
          const isActive = label === t.label
          return (
            <li role="presentation" id={`tab${i}`} className={isActive ? 'active' : ''} key={i}>
              <a
                href={`#${compId}Tab`}
                role="tab"
                aria-selected={isActive}
                aria-controls={t.compId}
                aria-posinset={i + 1}
                aria-setsize={numOfTabs}
                ref={node => (tabRefs.current[i] = node)}
                onKeyDown={e => enableKeyboardNavigation(i, e)}
                onClick={e => changeTab(t, e)}
                className="cb-small-font-size"
              >
                {t.label}
                {t.count && t.count > 0 ? (
                  <span className="badge" style={{ marginLeft: '10px', fontSize: '.8rem' }}>
                    {t.count}
                  </span>
                ) : null}
              </a>
            </li>
          )
        })}
      </ul>
      <div role="tabpanel" id={compId}>
        <Component />
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  { openModal }
)(Tabs)
