import { change } from 'redux-form'
import { Input, Date } from '../../common'
import { CREATE_FORM_NAME, CREATE_FORM_FIELDS as FIELDS } from '../../../constants/SectionConstants'

const mapStateToProps = state => {
  const { selectedEducationPeriod, availableEducationPeriods } = state.settingsEducationPeriod

  return {
    minDate: availableEducationPeriods[selectedEducationPeriod].academicYrStartDate,
    maxDate: availableEducationPeriods[selectedEducationPeriod].academicYrEndDate,
  }
}

class SecondSemesterSelector extends Component {
  componentWillUnmount() {
    this.props.change(CREATE_FORM_NAME, FIELDS.date, null)
    this.props.change(CREATE_FORM_NAME, FIELDS.attested, false)
  }

  render() {
    const { minDate, maxDate } = this.props

    return (
      <div className="cb-small-font-size" style={{ marginTop: -12 }}>
        <div style={{ display: 'flex' }}>
          <Date
            name={FIELDS.date}
            form={CREATE_FORM_NAME}
            label="Start Date"
            showLabel={true}
            isRequired={true}
            minDate={minDate}
            maxDate={maxDate}
            labelStyle={{ whiteSpace: 'nowrap', lineHeight: '48px', paddingRight: '20px' }}
          />
        </div>
        <Input
          type="checkbox"
          name={FIELDS.attested}
          style={{ marginTop: -12 }}
          labelStyle={{ lineHeight: '1.4em', marginBottom: 24 }}
          label="I attest that the course schedule and start date selected for this section are accurate. If the section is taught on a different schedule, final costs for exams may be impacted."
          isRequired={true}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  { change }
)(SecondSemesterSelector)
