import { useEffect } from 'react'
import { openModal } from '../../actions/app'
import { updateMultiSelect, resetMultiSelect } from '../../actions/studentsByOrg'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    studentsByOrg: {
      exams,
      multi: { updating, updated },
      selected,
    },
    app: {
      breakpoint: { name: breakpoint },
    },
    settingsExamWindows,
  } = state

  const selectedEnrollments = []
  for (let i = 0; i < exams.length; i++) {
    if (selected.includes(exams[i].enrollmentId)) {
      selectedEnrollments.push(exams[i])
    }
  }

  return {
    orgId,
    educationPeriodCd,
    updating,
    updated,
    breakpoint,
    settingsExamWindows,
    selectedEnrollments,
  }
}

const UpdateMultiSelectModal = connect(mapStateToProps, {
  updateMultiSelect,
  resetMultiSelect,
  openModal,
})(
  ({
    orgId,
    educationPeriodCd,
    updated,
    updating,
    modalCloseFocusElem,
    ModalComponent,
    breakpoint,
    settingsExamWindows,
    selectedEnrollments,
    openModal,
    updateMultiSelect,
    resetMultiSelect,
  }) => {
    const courseName = selectedEnrollments[0]?.name // multi-select only works with enrollments from one course
    const [successModalText, setSuccessText] = useState(null)

    const getFooterActions = (selections, options, disabled) => [
      {
        buttonLabel: 'Cancel',
        isDismissable: true,
        isDisabled: updating,
      },
      {
        buttonLabel: 'Update',
        isPrimary: true,
        isDisabled: disabled || updating,
        busy: updating,
        onClick: () =>
          updateMultiSelect({
            selected: selections,
            options,
            orgId,
            educationPeriodCd,
            settingsExamWindows,
          }),
      },
    ]

    useEffect(() => {
      resetMultiSelect()
    }, [])

    useEffect(() => {
      if (updated && successModalText)
        openModal('UpdateMultiSelectSuccessModal', { modalCloseFocusElem, text: successModalText })
    }, [updated])

    return (
      <ModalComponent
        modalProps={{
          shouldCloseModal: updated,
          modalCloseFocusElem,
          modalStyles: breakpoint !== 'mobile' ? { width: 750 } : {},
        }}
        getFooterActions={getFooterActions}
        setSuccessText={text => setSuccessText(text)}
        courseName={courseName}
      />
    )
  }
)

export default UpdateMultiSelectModal
