import * as c from '../constants/ShipmentConstants'
import axios, { getServerErrorMessage } from '../utils/axios'
import { convertTitleCase } from '../utils/common'
import { sortColumnByDate } from '../utils/sort'
import { SHIP_STANDARD } from '../constants/ShipmentConstants'

/**
 *
 * Fetch shipment information for the specific organization and education period.
 * Shipments includes all AP initiated suborders and uninitiated orders like AP ID Labels.
 * Since AP labels are queried from different domain and have some different attributes,
 * it is returned as a different key.
 *
 */
export const fetchShipmentsSummary =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    try {
      dispatch({ type: c.FETCH_SHIPMENTS_SUMMARY_PENDING })
      const { data } = await axios.get(
        `${Config.API_URL}/v1/orders/organizations/${orgId}/education-periods/${educationPeriodCd}/shipments?status=processing`
      )
      const processedData = {
        ...data,
        suborders: data?.suborders.map(item => ({
          ...item,
          displayName: convertTitleCase(item.testDayType),
          ...(item.testDayType === SHIP_STANDARD ? { canSplit: true } : {}),
        })),
      }
      /**
       * Apply specific sort criteria for each collection of suborders.
       * Since AP label are uninitiated orders, they lack the sequence attribute that defines if an order is main or supplement.
       * Hence, AP label orders are sorted by createDate attribute.
       *
       * For the rest of the suborders, the order is pre-defined by business requirements.
       */
      const testDayTypes = [
        c.TYPE_AP_LABELS,
        ...processedData?.suborders.map(item => item.testDayType),
        c.TYPE_ALL_ADMIN,
      ]
      const suborders = testDayTypes.reduce((acc, testType) => {
        acc[testType] = processedData.suborders.filter(
          suborder => suborder.testDayType === testType
        )
        return { ...acc, AP_LABELS: processedData.apLabels || [] }
      }, {})
      // Ordering suborders by Main/ Supplemental and returning back the array.
      const orderByType = (section, dayType) => {
        if (suborders[dayType]) {
          if (section !== c.SECTION_EXAM_WITH_SPECIAL_MATERIALS_NONSTOCK) {
            return c.SUBTYPES.reduce((acc, subtype) => {
              const suborder = suborders[dayType].filter(
                suborder => suborder.contents === section && suborder.type === subtype
              )
              if (suborder.length) {
                acc.push(...suborder)
              }
              return acc
            }, [])
          }
          // for nonstock.
          return suborders[dayType]
            .filter(suborder => suborder.contents.includes(c.NONSTOCK))
            .sort((a, b) =>
              `${a.lastName}${a.firstName}${a.courseName}` >
              `${b.lastName}${b.firstName}${b.courseName}`
                ? 1
                : -1
            )
        }
        return []
      }
      // Going through each section present to order them.
      const orderbySections = (dayType, SECTIONS) =>
        suborders[dayType] &&
        SECTIONS.reduce(
          (accumulator, section) => [...accumulator, ...orderByType(section, dayType)],
          []
        )
      const groupedSuborders = testDayTypes.reduce((acc, dayType) => {
        let sectionSuborders
        if (dayType === c.ALL_ADMIN) {
          sectionSuborders = orderbySections(dayType, c.SCORE_SECTIONS)
        } else if (dayType === c.TYPE_AP_LABELS) {
          sectionSuborders = sortColumnByDate(suborders[dayType], 'createdDate')
        } else {
          sectionSuborders = orderbySections(dayType, c.SECTIONS)
        }
        if (sectionSuborders?.length) {
          acc[dayType] = sectionSuborders
        }
        return acc
      }, {})

      dispatch({
        type: c.FETCH_SHIPMENTS_SUMMARY_FULFILLED,
        payload: { ...processedData, suborders: groupedSuborders },
      })
    } catch (err) {
      dispatch({ type: c.FETCH_SHIPMENTS_SUMMARY_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

/**
 * @param {number} id Suborder id.
 * returns all the line items for a specific suborderId.
 */
export const fetchShipmentsSuborder = id => async dispatch => {
  try {
    dispatch({ type: c.FETCH_SHIPMENTS_SUBORDER_PENDING })
    const { data } = await axios.get(`${Config.API_URL}/v1/suborders/${id}`)
    dispatch({ type: c.FETCH_SHIPMENTS_SUBORDER_FULFILLED, payload: { data } })
  } catch (err) {
    dispatch({ type: c.FETCH_SHIPMENTS_SUBORDER_REJECTED, payload: getServerErrorMessage(err) })
  }
}

/**
 * @param {string} aiCode
 * Retrieve the shipping address for the organization.
 */
export const fetchShippingAddress = aiCode => async dispatch => {
  const VISITING_ADDR_TYPE_CD = '1'
  const SHIPPING_ADDR_TYPE_CD = '3'
  dispatch({ type: c.FETCH_SHIPPING_ADDRESS_PENDING })

  try {
    const {
      data: {
        hits: { hits = [] },
      },
    } = await axios.request({
      method: 'get',
      url: `${Config.CBAPI_ORGCDS.url}/aisearch?aicode=${aiCode}&aidomain=ap`,
      withCredentials: false,
      transformRequest: (data, headers) => {
        headers.common = { 'X-Api-Key': Config.CBAPI_ORGCDS.apiKey }
        return data
      },
    })
    const searchResult = hits?.[0]._source
    const { org_full_name } = searchResult
    const defaultAddress =
      searchResult.org_addresses.find(adr => adr.addr_type_cd === VISITING_ADDR_TYPE_CD) ?? {}
    const addr =
      searchResult.org_addresses.find(adr => adr.addr_type_cd === SHIPPING_ADDR_TYPE_CD) ??
      defaultAddress

    dispatch({
      type: c.FETCH_SHIPPING_ADDRESS_FULFILLED,
      payload: {
        [aiCode]: addr
          ? {
              schoolName: org_full_name,
              state: addr.state_cd,
              city: addr.city,
              countryCode: addr.country_iso_cd,
              streetAddr1: addr.street_addr1,
              streetAddr2: addr.street_addr2,
              zip5: addr.zip5,
            }
          : null,
      },
    })
  } catch (err) {
    dispatch({
      type: c.FETCH_SHIPPING_ADDRESS_REJECTED,
      payload: 'Error: Unable to retrieve shipping address. Please try again later.',
    })
  }
}
