import { Field } from 'redux-form'
import Label from './Label'
import { AddRequiredValidation } from '..'

export const renderTextInputField = ({
  input,
  isRequired,
  isSearchBox,
  additionalText,
  autocomplete = 'on',
  maxlength,
  showErrorsOnLoad,
  placeholder,
  disabled,
  label,
  ariaLabelledBy,
  ariaLabel,
  meta,
  showErrorIcon = true,
  onPaste,
  labelStyle = {},
  inputStyle = {},
  formGroupStyle = {},
  errorStyle = {},
  errorOnPristine = false, // to display error when value is required but none has been entered
  //onFocus,
  //onBlur,
}) => {
  const { touched, error, warning, dirty, submitFailed } = meta || {}
  const errored =
    ((touched && ((!errorOnPristine && dirty) || errorOnPristine)) ||
      submitFailed ||
      showErrorsOnLoad) &&
    Boolean(error)
  const errorId = `errMessage-${input.name}`

  //const onFocusHandler = e => {
  //  onFocus && onFocus(e)
  //}

  //const onBlurHandler = e => {
  //  onBlur && onBlur(e)
  //}
  return (
    <div className={`form-group${errored ? ' has-error' : ''}`} style={formGroupStyle}>
      {label ? (
        <Label name={input.name} isRequired={isRequired} label={label} style={labelStyle} />
      ) : null}
      {additionalText ? (
        <p style={{ marginBottom: '5px', fontSize: '.9em' }}>{additionalText}</p>
      ) : null}
      <div className={`cb-input-state${isSearchBox ? ' input-group' : ''}`} style={inputStyle}>
        {isSearchBox ? (
          <span className="input-group-addon">
            <i className="cb-glyph cb-search" data-alt="Search" />
          </span>
        ) : null}
        <input
          {...input}
          id={input.name}
          type={input.type ?? 'text'}
          placeholder={placeholder ?? `Enter ${label}`}
          className="form-control"
          maxLength={maxlength}
          aria-label={label || ariaLabel}
          aria-labelledby={ariaLabelledBy ?? null}
          aria-required={isRequired}
          aria-invalid={errored}
          aria-describedby={errored ? errorId : null}
          disabled={disabled}
          aria-disabled={disabled}
          autoComplete={autocomplete}
          onPaste={onPaste}
          //onFocus={onFocusHandler}
          //onBlur={onBlurHandler}
          // onFocus={e => {
          //   e.target.select()
          //   input.onFocus && input.onFocus()
          // }}
        />
        {errored && showErrorIcon ? <i className="cb-glyph cb-x-mark" aria-hidden={true} /> : null}
      </div>
      {errored ? (
        <p
          tabIndex="-1"
          id={errorId}
          className="cb-error-msg"
          role="alert"
          style={errorStyle}
          aria-live="polite"
        >
          {error}
        </p>
      ) : null}
    </div>
  )
}

//store empty strings as nulls for backend...
export default props => (
  <Field
    {...props}
    component={renderTextInputField}
    validate={AddRequiredValidation(props.isRequired, props.validate)}
    normalize={val => (val === '' ? null : props.normalize ? props.normalize(val) : val)}
  />
)
