import { saveCoordNonSecureAncillaryMaterials } from '../../../../actions/orders'
import {
  getOrdersNonSecureAncillaryMaterialsData,
  getOrdersNonSecureAncillaryMaterialsProps,
  getOrdersNonSecureAncillaryMaterialsIsPristine,
  getOrdersNonSecureAncillaryMaterialsHasNoErrors,
} from '../../../../selectors/order'
import { getSortedExamWindowRefData, getExamWindowRefData } from '../../../../selectors/examWindows'
import { isEmpty } from '../../../../utils/common'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = state
  const examWindowRefData = getExamWindowRefData(state)
  const { saving, savingError } = getOrdersNonSecureAncillaryMaterialsProps(state)
  const { formData: changedData } = state.ordersNonSecureAncillaryMaterials
  const allTestingMaterials = getOrdersNonSecureAncillaryMaterialsData(state)
  const pristine = getOrdersNonSecureAncillaryMaterialsIsPristine(state)
  const hasNoErrors = getOrdersNonSecureAncillaryMaterialsHasNoErrors(state)
  return {
    orgId: selectedOrgId,
    educationPeriodCd: selectedEducationPeriod,
    saving,
    savingError,
    changedData,
    allTestingMaterials,
    pristine,
    hasNoErrors,
    sortedExamWindowData: getSortedExamWindowRefData(examWindowRefData),
    examWindowRefData,
  }
}

const compileSummaryItemsByTestWindow = (changedData = {}, testWindowData = {}, testWindow) => {
  const summaryItems = []
  const keys = Object.keys(testWindowData)
  keys.forEach(key => {
    const material = testWindowData[key]
    const changedMaterial = changedData[key]
    if (
      changedMaterial?.selectedQuantity !== 0 &&
      !isEmpty(changedMaterial?.providedReasonForOrder) &&
      isEmpty(changedMaterial?.reasonValidationError)
    ) {
      summaryItems.push({
        ...material,
        selectedQuantity: changedMaterial.selectedQuantity,
        testWindow,
      })
    }
  })
  return summaryItems
}

const SummaryItemRow = ({ item }) => {
  const quantityDifference = item.selectedQuantity - item.quantityOrdered
  return (
    <Fragment key={`row_${item.ancillaryCd}`}>
      <tr>
        <td
          headers={`quantity ${item.testWindow.toLowerCase()}Materials`}
          style={{ width: '3em', verticalAlign: 'top' }}
        >
          {item.selectedQuantity}
        </td>
        <td
          headers={`material ${item.testWindow.toLowerCase()}Materials`}
          style={{ lineHeight: '1.2' }}
        >
          {item.description}
          {quantityDifference !== 0 && (
            <p style={{ fontStyle: 'italic', fontSize: '.8em', marginBottom: '8px' }}>
              {quantityDifference} pending
            </p>
          )}
        </td>
      </tr>
    </Fragment>
  )
}

const SummaryItemsForTestWindow = ({ items }) =>
  items.map(item => <SummaryItemRow key={`summary_${item.ancillaryCd}`} item={item} />)

const SummarySection = ({ title, adminWindow, items, notFirst }) => {
  const testWindowHeaderStyle = {
    textDecoration: 'underline',
    fontWeight: 'normal',
    paddingTop: notFirst ? '1em' : '0',
  }
  if (items.length) {
    return (
      <>
        <tr>
          <th
            id={`${adminWindow.replace(/\s/, '').toLowerCase()}Materials`}
            colSpan="2"
            className="cb-roboto-slab cb-small-font-size"
            style={testWindowHeaderStyle}
          >
            {title}
          </th>
        </tr>
        <SummaryItemsForTestWindow items={items} />
      </>
    )
  }
  return null
}

const OrderSummaryList = ({ examWindows, changedData, allTestingMaterials = {}, pristine }) => {
  const summaryItemsAllWindows = []
  examWindows.forEach(({ adminWindow, displayName }) => {
    const summaryItemsForWindow = compileSummaryItemsByTestWindow(
      changedData,
      allTestingMaterials[adminWindow],
      adminWindow
    )
    if (summaryItemsForWindow.length) {
      summaryItemsAllWindows.push({
        title: `${displayName} Testing`,
        adminWindow,
        items: summaryItemsForWindow,
      })
    }
  })

  if (summaryItemsAllWindows.length) {
    return (
      <table style={{ width: '100%', marginBottom: '1em' }}>
        <thead>
          <tr>
            <th id="quantity">Qty.</th>
            <th id="material">Material</th>
          </tr>
        </thead>
        <tbody>
          {summaryItemsAllWindows.map(({ title, adminWindow, items }, i) => (
            <SummarySection
              key={APRICOT.utils.uniqueID()}
              title={title}
              adminWindow={adminWindow}
              items={items}
              notFirst={i !== 0}
            />
          ))}
        </tbody>
      </table>
    )
  }

  if (summaryItemsAllWindows.every(window => window.items.length === 0) && !pristine) {
    return (
      <p>
        Click on the &#39;Update Order&#39; button to remove the previously added materials from you
        order.
      </p>
    )
  }

  return <p>No items added</p>
}

const OrderSummary = ({
  orgId,
  educationPeriodCd,
  changedData,
  allTestingMaterials,
  saving,
  savingError,
  pristine,
  hasNoErrors,
  sortedExamWindowData,
  examWindowRefData,
  saveCoordNonSecureAncillaryMaterials,
}) => {
  return (
    <div style={{ backgroundColor: '#e0e0e0', padding: '15px' }}>
      <h3>Order Summary</h3>
      <OrderSummaryList
        examWindows={sortedExamWindowData}
        changedData={changedData}
        allTestingMaterials={allTestingMaterials}
        pristine={pristine}
      />
      {savingError && (
        <p className="cb-error-msg" aria-live="polite">
          {savingError}
        </p>
      )}
      <button
        type="submit"
        disabled={pristine || !hasNoErrors || saving}
        className="btn btn-sm btn-primary"
        style={{ width: '100%' }}
        onClick={e => {
          e.preventDefault()
          saveCoordNonSecureAncillaryMaterials({
            orgId,
            educationPeriodCd,
            changedData,
            initialDataByTestWindow: allTestingMaterials,
            examWindowRefData,
          })
        }}
      >
        {saving ? 'Updating...' : 'Update Order'}
      </button>
    </div>
  )
}

export default connect(mapStateToProps, { saveCoordNonSecureAncillaryMaterials })(OrderSummary)
