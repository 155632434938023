import { Autosuggest, Error } from '@myap/ui-library'
import { updateAttendingSchool } from '../../../actions/csr'
import { fetchSchoolsByAiCode } from '../../../actions/typeaheads'
import { SearchForStudents, ActionBar } from '.'
import styles from '../../../assets/style/scss/csr.scss'

const mapStateToProps = state => {
  return {
    educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
    orgId: state.user.data.selectedOrgId,
  }
}

const SearchForSchool = ({ save, saving, error, saved }) => {
  const [term, setTerm] = useState('')
  const [school, setSchool] = useState(null)

  return (
    <>
      <div className={styles['tool-body-inner']}>
        {!saved?.data ? (
          <div className={styles['tool-body-item']}>
            <Autosuggest
              name="newAttendingSchool"
              value={term}
              label="Search for attending school"
              placeholder="Start typing to search"
              renderSuggestion={({ name, address, aicode }) => (
                <div>
                  <div>
                    {name} ({aicode})
                  </div>
                  <div>{address}</div>
                </div>
              )}
              className={styles['search-input']}
              fetchSuggestions={fetchSchoolsByAiCode}
              onChange={e => setTerm(e.target.value)}
              selectSuggestion={val => {
                setTerm(`${val.name} (${val.aicode})`)
                setSchool(val)
              }}
            />
          </div>
        ) : null}
        {error && !saving ? <Error title="Error Saving Data" message={error} /> : null}
        {saved.data ? (
          <div className={styles['tool-body-item']}>
            <p>The following students&#39; attending school has been changed to {school.name}:</p>
            {saved.data.map(d => (
              <ul className="list-unstyled" key={d.studentId}>
                <li>
                  <div>
                    {d.lastName}, {d.firstName}
                  </div>
                  <div>{d.studentId}</div>
                  <div>School Code: {d.aiCode}</div>
                </li>
              </ul>
            ))}
          </div>
        ) : null}
      </div>
      <ActionBar
        buttons={[
          {
            title: 'Confirm',
            disabled: !school || saving || saved.orgid === school.orgid,
            onClick: () => save(school.orgid),
          },
        ]}
      />
    </>
  )
}

const ChangeAttendingSchool = ({ orgId, educationPeriod, updatePanel, panel, subpanel }) => {
  const [selected, setSelected] = useState([])
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)
  const [saved, setSaved] = useState({})

  useEffect(() => {
    updatePanel(panel, 'Change Attending School: Search Students')
  }, [])

  useEffect(() => {
    setSaved({})
  }, [subpanel])

  const save = async orgid => {
    await setSaving(true)
    const { data = [], error } = await updateAttendingSchool(
      orgId,
      educationPeriod,
      selected.map(student => student.studentId),
      orgid
    )
    setSaving(false)
    setError(error)
    setSaved({
      data: data.map(d => {
        const theog = selected.find(s => s.studentId === d.studentId)
        return { ...theog, ...d }
      }),
      orgid,
    })
  }

  return subpanel === 0 ? (
    <SearchForStudents
      orgId={orgId}
      educationPeriod={educationPeriod}
      inputLabel="Search for students whose attending school needs to be changed."
      getButtons={() => [
        {
          title: 'Change Attending School',
          onClick: selections => {
            updatePanel(panel, 'Change Attending School: Search School', 1)
            setSelected(selections)
          },
        },
      ]}
      component={({ student }) => (
        <>
          <div>School Code: {student.aiCode}</div>
          <div>
            {student.homeState}, {student.homeCountry}
          </div>
        </>
      )}
    />
  ) : (
    <SearchForSchool save={save} saving={saving} error={error} saved={saved} selected={selected} />
  )
}

export default connect(mapStateToProps)(ChangeAttendingSchool)
