import { getFormValues } from 'redux-form'
import { formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { sortColumnByKey } from '../../../utils/sort'
import {
  STUDENT_LABEL_SORT_ORDER_FORM,
  STUDENT_LABEL_SORT_ORDER_SELECT,
  STUDENT_LABEL_SORT_ORDER_MAP,
} from '../../../constants/OrderConstants'

import tableUtilStyles from '../../../assets/style/scss/table-utils.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsExamWindows: {
      data: {
        [selectedOrgId]: { [selectedEducationPeriod]: allCourseWindows = {} },
      },
    },
  } = state
  const formValues = getFormValues(STUDENT_LABEL_SORT_ORDER_FORM)(state) || {}
  const SAMPLE = [
    {
      studentId: 1,
      studentName: 'Shirley, Anne',
      grade: 10,
      courseName: 'AP English Literature and Composition',
      testCd: 13,
      adminWindow: 'ADMIN2',
    },
    {
      studentId: 2,
      studentName: 'Decker, Theo',
      grade: 12,
      courseName: 'AP Art History',
      testCd: 2,
      adminWindow: 'ADMIN2',
    },
    {
      studentId: 3,
      studentName: 'Finch, Jean Louise',
      grade: 9,
      courseName: 'AP United States History',
      testCd: 1,
      adminWindow: 'ADMIN1',
    },
    {
      studentId: 4,
      studentName: 'Waters, Augustus',
      grade: 11,
      courseName: 'AP Biology',
      testCd: 6,
      adminWindow: 'ADMIN1',
    },
    {
      studentId: 5,
      studentName: 'Wheelwright, John',
      grade: 12,
      courseName: 'AP Physics 1',
      testCd: 92,
      adminWindow: 'ADMIN1',
    },
    {
      studentId: 6,
      studentName: 'Bennet, Mary',
      grade: 10,
      courseName: 'AP Pyschology',
      testCd: 30,
      adminWindow: 'ADMIN2',
    },
    {
      studentId: 7,
      studentName: 'Meminger, Liesel',
      grade: 9,
      courseName: 'AP English Literature and Composition',
      testCd: 13,
      adminWindow: 'ADMIN1',
    },
    {
      studentId: 8,
      studentName: 'Nolan, Francie',
      grade: 10,
      courseName: 'AP Physics 1',
      testCd: 92,
      adminWindow: 'ADMIN1',
    },
    {
      studentId: 9,
      studentName: 'Sawtelle, Edgar',
      grade: 9,
      courseName: 'AP Biology',
      testCd: 6,
      adminWindow: 'ADMIN1',
    },
    {
      studentId: 10,
      studentName: 'March, Amy',
      grade: 11,
      courseName: 'AP Art History',
      testCd: 2,
      adminWindow: 'ADMIN1',
    },
  ]
  const sampleData = SAMPLE.map(item => ({
    ...item,
    examDateTime: allCourseWindows[item.testCd]?.[item.adminWindow]?.examDateTime,
  }))
  return {
    sortKey: STUDENT_LABEL_SORT_ORDER_MAP[formValues[STUDENT_LABEL_SORT_ORDER_SELECT]],
    allCourseWindows,
    sampleData,
  }
}

const cellStyle = {
  verticalAlign: 'middle',
}

export default connect(mapStateToProps)(({ sortKey, allCourseWindows, sampleData }) => (
  <table
    className="table cb-no-table-border cb-table-striped-reverse"
    summary="Choose how student labels will be sorted when they are shipped to you. Use the SORT BY dropdown to change the sort order of the student labels listed below. Default sort order is by Student last name in ascending order."
  >
    <caption className="sr-only">Sample of list of students</caption>
    <thead>
      <tr role="row">
        <th scope="col">Name</th>
        <th scope="col">Grade</th>
        <th scope="col">First Exam</th>
        <th scope="col">Exam Date</th>
      </tr>
    </thead>
    <tbody>
      {sortColumnByKey(sampleData, sortKey, 'asc').map(
        ({ studentId, studentName, grade, adminWindow, courseName, testCd }, i) => (
          <tr key={studentId}>
            <th scope="row" style={cellStyle}>
              {studentName}
            </th>
            <td className="text-right" style={cellStyle}>
              {grade}
            </td>
            <td style={cellStyle}>{courseName}</td>
            <td>
              <div
                className={`${tableUtilStyles['exam-date-wrapper']} ${tableUtilStyles['with-time']} ${tableUtilStyles['with-icon-container']}`}
              >
                <span className={tableUtilStyles['exam-type']}>
                  {allCourseWindows[testCd]?.[adminWindow]?.displayName}
                </span>
                <span className={tableUtilStyles['exam-date']}>
                  {formatDateTime(
                    allCourseWindows[testCd]?.[adminWindow]?.examDateTime,
                    DATETIME_FORMATS.shortMonthDay
                  )}
                </span>
                <span className={tableUtilStyles['exam-time']}>
                  {formatDateTime(
                    allCourseWindows[testCd]?.[adminWindow]?.examDateTime,
                    DATETIME_FORMATS.hour
                  )}
                </span>
              </div>
            </td>
          </tr>
        )
      )}
    </tbody>
  </table>
))
