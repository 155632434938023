import { fetchStudentDetails } from '../../../actions/studentsByOrg'
import {
  fetchAllowedSSDMaterials,
  fetchSpecialDigitalFormatsMetadata,
  fetchExamPricing,
} from '../../../actions/settingsGlobal'
import { Loader, Error } from '../../common'
import FeesStatusCheckbox from './updatedropdowns/FeesStatusCheckbox'
import StudentSchoolInformation from './StudentSchoolInformation'
import StudentContactInformation from './StudentContactInformation'
import StudentDetailsSection from './StudentDetailsSection'
import StudentDetailsTotalCost from './StudentDetailsTotalCost'
import StudentSSDAccommodations from './StudentSSDAccommodations'
import StudentExcludeSubsidiesCheckbox from './StudentExcludeSubsidiesCheckbox'
import { sortColumnByKey } from '../../../utils/sort'
import { hasDigitalExams } from '../../../selectors/student'

import styles from '../../../assets/style/scss/student-details.scss'

const mapStateToProps = (state, { studentId }) => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod: period },
    settingsPricing,
    settingsSSDMaterials,
    settingsSpecialDigitalFormats,
    studentsByOrg: {
      fetching,
      fetched,
      error,
      exams = [],
      student = {},
      courseMap,
      subsidies = [],
      accommodationsInfo = {},
    },
    user: {
      data: { isLevelTwo, selectedOrgId, isSchoolAdmin, isCSR },
    },
  } = state

  return {
    studentId,
    exams: sortColumnByKey(
      exams.map(e => ({ ...e, courseName: courseMap[e.testCd].name })),
      'courseName',
      'asc'
    ),
    student,
    accommodationsInfo,
    subsidies: sortColumnByKey(subsidies, 'subsidyName', 'asc'),
    fetching,
    fetched,
    error,
    shouldSeeCost: isLevelTwo,
    settingsPricing,
    settingsSSDMaterials,
    settingsSpecialDigitalFormats,
    period,
    selectedOrgId,
    isSchoolAdmin,
    isCSR,
    hasDigitalExams: hasDigitalExams(state),
  }
}

const StudentDetails = ({
  studentId,
  exams,
  student,
  accommodationsInfo,
  subsidies,
  fetching,
  fetched,
  error,
  shouldSeeCost,
  settingsPricing,
  settingsSSDMaterials,
  settingsSpecialDigitalFormats,
  period,
  selectedOrgId,
  isSchoolAdmin,
  isCSR,
  hasDigitalExams,
  fetchStudentDetails,
  fetchAllowedSSDMaterials,
  fetchSpecialDigitalFormatsMetadata,
  fetchExamPricing,
}) => {
  const studentHasNoActiveEnrollments = exams.every(e => e.reasonInactive)

  useEffect(() => {
    if (studentId && selectedOrgId && period) {
      fetchStudentDetails(studentId, selectedOrgId, period)
    }
  }, [studentId, selectedOrgId, period])

  useEffect(() => {
    if (!settingsSSDMaterials.fetching && !settingsSSDMaterials.fetched) {
      fetchAllowedSSDMaterials()
    }

    if (!settingsSpecialDigitalFormats.fetching && !settingsSpecialDigitalFormats.fetched) {
      fetchSpecialDigitalFormatsMetadata()
    }

    if (!settingsPricing.fetching && !settingsPricing.fetched) {
      fetchExamPricing()
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const ssdIcon = () => {
    if (accommodationsInfo?.ssdId)
      return (
        <span
          className="cb-small-font"
          style={{
            display: 'inline-block',
            padding: '2px 5px',
            marginLeft: 10,
            verticalAlign: 'bottom',
            border: '1px solid #505050',
          }}
        >
          SSD
        </span>
      )

    return null
  }

  if (error)
    return (
      <div style={{ marginTop: 20 }}>
        <Error title="Error Retrieving Data" message={error} />
      </div>
    )

  if (fetching) {
    return <Loader />
  }

  if (fetched && student)
    return (
      <>
        <div className="row">
          <div className="col-xs-12">
            <h2>
              {student.firstName} {student.lastName} {ssdIcon()}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 col-md-6 col-xs-12">
            <div className="row">
              <div className="col-xs-6" style={{ marginBottom: 20 }}>
                <StudentSchoolInformation {...student} studentId={studentId} />
              </div>
              <div className="col-xs-6" style={{ marginBottom: 20 }}>
                <StudentContactInformation {...student} />
              </div>
            </div>
          </div>
          {shouldSeeCost ? (
            <div className="col-lg-5 col-md-6 col-xs-12">
              <div className={styles['fees-status-and-cost']}>
                <FeesStatusCheckbox
                  exam={{ studentId }}
                  inactive={studentHasNoActiveEnrollments}
                  showLabel={true}
                />
                <StudentExcludeSubsidiesCheckbox studentId={studentId} />
              </div>
              <StudentDetailsTotalCost exams={exams} subsidies={subsidies} />
            </div>
          ) : null}
        </div>

        <h3 style={{ marginTop: '1.5em', marginBottom: '.75em' }}>Courses</h3>
        {exams.length > 0 ? (
          exams.map((exam, i) => (
            <StudentDetailsSection
              exam={exam}
              index={i}
              key={exam.examId}
              hasDigitalExams={hasDigitalExams}
            />
          ))
        ) : (
          <p>This student has no enrollments at this school.</p>
        )}
        <hr />
        <StudentSSDAccommodations
          accommodationsInfo={accommodationsInfo}
          isSchoolAdmin={isSchoolAdmin}
          isCSR={isCSR}
        />
      </>
    )

  return null
}

export default connect(mapStateToProps, {
  fetchAllowedSSDMaterials,
  fetchSpecialDigitalFormatsMetadata,
  fetchExamPricing,
  fetchStudentDetails,
})(StudentDetails)
