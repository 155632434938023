import DetailedProgressBar from './DetailedProgressBar'
import { ShipmentWindowTitle } from './PackingPageTitle'
import {
  PACKAGES_ENUM_TO_HEADING,
  PACKING_LIST_STATUS,
  SUBMITTED,
} from '../../constants/PackingListConstants'
import packingListStyle from '../../assets/style/scss/packing-list.scss'

const mapStateToProps = state => ({
  splitShipment: state.packingListSummary?.splitShipment,
})

const PackageContainer = ({ pkgs }) => {
  return pkgs.map(pkg => {
    const {
      examType,
      confirmedCourses,
      totalCourses: totalCount,
      status,
      packingListId,
      packingShipmentsId,
    } = pkg
    const heading = PACKAGES_ENUM_TO_HEADING[examType]
    const progressBarText = 'Courses Confirmed'
    const footerObj = {
      footerText: PACKING_LIST_STATUS[status] || '',
      footerTextIcon: status === SUBMITTED ? 'check' : '',
      linkText: status === SUBMITTED ? 'View Shipment' : 'Edit Shipment',
      link: `/packingshipments/${packingShipmentsId}/packinglist/${packingListId}`,
    }

    const progressBarProps = {
      heading,
      progressBarText,
      footerObj,
      totalCount,
      progressedCount: confirmedCourses,
    }
    return <DetailedProgressBar key={packingListId} {...progressBarProps} />
  })
}

const ShipmentBlock = ({ pkgs }) => {
  return (
    <div className={packingListStyle['testDay-contianer']}>
      <h3 style={{ marginBottom: '5px' }}>
        <ShipmentWindowTitle pkg={pkgs[0]} />
      </h3>
      <PackageContainer pkgs={pkgs} />
    </div>
  )
}

const PackingListSummary = ({ packingLists }) => {
  const testDaysInPackage = Object.keys(packingLists)
  const hasPackages = Boolean(testDaysInPackage.length)

  return (
    <>
      <h2>Packing Shipments</h2>
      {hasPackages ? (
        testDaysInPackage.map(group => {
          if (packingLists[group]?.length) {
            if (packingLists[group].length > 1) {
              // Only split the shipment that has weekNumbers
              if (packingLists[group][0]?.weekNumber) {
                return (
                  <Fragment key={group}>
                    <ShipmentBlock pkgs={[packingLists[group][0]]} />
                    <ShipmentBlock pkgs={packingLists[group].slice(1)} />
                  </Fragment>
                )
              }
              return <ShipmentBlock key={group} pkgs={packingLists[group]} />
            }
            return <ShipmentBlock key={group} pkgs={[packingLists[group][0]]} />
          }
          return null
        })
      ) : (
        <p>There are no packing lists available.</p>
      )}
    </>
  )
}

export default connect(mapStateToProps)(PackingListSummary)
