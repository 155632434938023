import { GRADE_OPTIONS } from '@myap/metadata'
import Select from '@myap/ui-library/esm/components/forms/Select'
import { saveChangeGradeLevel } from '../../../actions/csr'
import { SearchForStudents } from '.'

const mapStateToProps = state => {
  return {
    educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
    orgId: state.user.data.selectedOrgId,
  }
}

const ChangeGradeLevel = ({ orgId, educationPeriod }) => {
  const [grades, setGrade] = useState({})
  const save = async selected => {
    const { data = [], error } = await saveChangeGradeLevel(
      orgId,
      educationPeriod,
      selected.reduce((updates, { studentId }) => {
        const newGrade = grades[studentId]
        return newGrade ? [...updates, { studentId, grade: newGrade }] : updates
      }, [])
    )
    return { data: data.map(({ grade, studentId }) => ({ grade, studentId })), error }
  }

  const SelectGrade = ({ student, checked }) => {
    const { studentId, grade } = student

    useEffect(() => {
      const { [student.studentId]: updated, ...unchanged } = grades
      if (!checked && updated) setGrade(unchanged)
    }, [checked])

    return (
      <>
        <div style={{ marginBottom: 10 }}>{GRADE_OPTIONS.find(g => g.value === grade)?.label}</div>
        {checked ? (
          <Select
            name={`gradeSelect-${studentId}`}
            value={(checked && grades[studentId]) || grade}
            onChange={e => setGrade({ ...grades, [studentId]: parseInt(e.target.value, 10) })}
            disabled={!checked}
            emptyLabel={null}
            options={GRADE_OPTIONS}
            label="Select grade level:"
          />
        ) : null}
      </>
    )
  }

  return (
    <SearchForStudents
      orgId={orgId}
      educationPeriod={educationPeriod}
      inputLabel="Search for students whose grade needs to be changed."
      component={SelectGrade}
      getButtons={selected => [
        {
          title: 'Update Grade',
          save,
          disabled: selected.filter(s => grades[s.studentId]).length === 0,
        },
      ]}
    />
  )
}

export default connect(mapStateToProps)(ChangeGradeLevel)
