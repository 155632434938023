import Text from './Text'
import { EmailValidation, ConfirmEmailValidation } from './Validations'

export default ({ label, emailProps, maxlength, commonPlaceholder, showConfirmErrorOnLoad = false }) => {

  return (
    <>
      <Text
        name={emailProps.name}
        isRequired={emailProps.required}
        validate={[EmailValidation]}
        label={label}
        maxlength={maxlength}
        placeholder={`Enter ${commonPlaceholder}`}
      />
      <Text
        name={`confirm.${emailProps.name}`}
        isRequired={emailProps.required}
        label={`Confirm ${label}`}
        maxlength={maxlength}
        placeholder={`Confirm ${commonPlaceholder}`}
        showErrorsOnLoad={showConfirmErrorOnLoad}
        validate={[ConfirmEmailValidation]}
        onPaste={e => {
          e.preventDefault()
        }}
      />
    </>
  )
}