import { isPristine, submit } from 'redux-form'
import RosterManagementForm from '../../../containers/forms/RosterManagementForm'
import { Error, Loader, ChangesSavedLabelNotification } from '../../common'
import { fetchOrgSettings } from '../../../actions/settingsOrg'
import { ROSTER_MANAGEMENT_FORM } from '../../../constants/SetupConstants'
import ExpireAllJoinCodes from './ExpireAllJoinCodes'

const mapStateToProps = state => {
  const { selectedEducationPeriod } = state.settingsEducationPeriod
  const { selectedOrgId } = state.user.data
  const orgSettings = state.settingsOrg[selectedOrgId]
  const { fetching, fetched, error, updating, updated } = orgSettings || {}
  const pristine = isPristine(ROSTER_MANAGEMENT_FORM)(state)
  return {
    fetching,
    fetched,
    error,
    updating,
    updated,
    selectedOrgId,
    pristine,
    selectedEducationPeriod,
  }
}

const changesSavedNotificationDisplayDuration = 500

class RosterManagementPanel extends Component {
  constructor(props) {
    super(props)
    this.state = { saved: false }
  }

  componentDidMount() {
    const {
      fetchOrgSettings,
      selectedOrgId,
      selectedEducationPeriod,
      fetching,
      fetched,
    } = this.props

    if (!fetching && !fetched) {
      fetchOrgSettings(selectedOrgId, selectedEducationPeriod)
    }
  }

  componentDidUpdate(prevProps) {
    const { updated } = this.props
    const { saved } = this.state
    if (!prevProps.updated && updated) {
      this.setState({ saved: true })
      setTimeout(() => {
        this.setState({ saved: false })
      }, changesSavedNotificationDisplayDuration)
    }
  }

  render() {
    const { fetching, fetched, error, updating, updated, pristine, submit } = this.props
    const { saved } = this.state

    if (fetching) {
      return <Loader />
    }

    if (error) {
      return (
        <Error title="Error Retrieving Data" message="An error occurred. Please try again later." />
      )
    }

    if (fetched) {
      return (
        <div>
          <div>
            <ChangesSavedLabelNotification
              in={saved}
              durationOut={1500}
              overrideDefaultStyle={{ float: 'right' }}
            />
            <p>
              AP Coordinators can choose whether AP teachers can manage changes to the student
              roster through dropping students from their class sections or moving students between
              their own class sections.
            </p>
            <p>Your current preference is below. You can update this selection as desired.</p>
            <RosterManagementForm />
            <p>
              <strong>Note:</strong> If you have left this option enabled, you may want to disable
              it{' '}
              <strong>
                <em>after</em>
              </strong>{' '}
              you submit your exam order to avoid unintended adjustments to the roster.
            </p>
            <button
              type="submit"
              disabled={pristine || updating}
              className="btn btn-sm btn-primary"
              style={{ position: 'relative', marginBottom: '40px' }}
              id="submit-roster-management-button"
              ref={node => (this.trigger = node)}
              onClick={() => submit(ROSTER_MANAGEMENT_FORM)}
            >
              Save
            </button>
          </div>
          <ExpireAllJoinCodes />
        </div>
      )
    }

    return null
  }
}

export default connect(
  mapStateToProps,
  { fetchOrgSettings, submit }
)(RosterManagementPanel)
