import * as c from '../constants/FundingConstants'

const initialFundingState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  error: null,
  step: 1,
  fundingType: null,
  data: {},
  savedData: {},
}

export const funding = (state = initialFundingState, { type, payload }) => {
  switch (type) {
    case c.SET_FUNDING_STEP:
      return { ...state, step: payload }

    case c.FETCH_DISTRICT_FUNDING_DATA_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    case c.FETCH_DISTRICT_FUNDING_DATA_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: payload }

    case c.FETCH_DISTRICT_FUNDING_DATA_REJECTED:
      return { ...state, fetching: false, fetched: true, error: payload }

    case c.SAVE_DISTRICT_FUNDING_DATA_PENDING:
      return { ...state, saving: true, saved: false, error: null }

    case c.SAVE_DISTRICT_FUNDING_DATA_FULFILLED:
      return { ...state, saving: false, saved: true }

    case c.SAVE_DISTRICT_FUNDING_DATA_REJECTED:
      return { ...state, saving: false, saved: false, error: payload }

    case c.DELETE_DISTRICT_FUNDING_PENDING:
      return { ...state, deleting: true, deleted: false }

    case c.DELETE_DISTRICT_FUNDING_FULFILLED:
      return { ...state, deleting: false, deleted: true }
    
    case c.DELETE_DISTRICT_FUNDING_REJECTED:
      return { ...state, deleting: false, deleted: false, error: payload }

    case c.RESET_DISTRICT_FUNDING_DATA:
      return { ...state, data: {} }

    case c.SET_DISTRICT_FUNDING_CONTACT_INFO_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      }

    case c.SET_DISTRICT_FUNDING_SCHOOL_SELECTION_DATA:
      return { ...state, data: { ...state.data, ...payload } }

    case c.SET_DISTRICT_FUNDING_TYPE_DATA: {
      const { ...fundingData } = state.data
      if (payload === c.FUNDING_CENTRAL_BILL) {
        // remove subsidies property from data
        delete fundingData?.subsidies
      }
      return {
        ...state,
        fundingType: payload,
        data: {
          ...fundingData,
        },
      }
    }

    case c.SET_DISTRICT_FUNDING_SUBSIDY_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          subsidies: {
            ...state.data.subsidies,
            ...payload,
          },
        },
      }

    case c.SET_SAVED_DISTRICT_FUNDING_DATA:
      return {
        ...state,
        savedData: payload,
      }

    default:
      return state
  }
}

const initialChildOrgsState = {
  fetching: false,
  fetched: false,
  error: null,
  data: [],
}

export const childOrgs = (state = initialChildOrgsState, { type, payload }) => {
  switch (type) {
    case c.FETCH_CHILD_ORGS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    case c.FETCH_CHILD_ORGS_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: payload }

    case c.FETCH_CHILD_ORGS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    default:
      return state
  }
}

const initialStateFundingState = {
  fetching: false,
  fetched: false,
  error: null,
  data: [],
}

export const stateFundingAndPricing = (state = initialStateFundingState, { type, payload }) => {
  switch (type) {
    case c.FETCH_STATE_FUNDING_AND_PRICING_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    case c.FETCH_STATE_FUNDING_AND_PRICING_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: payload }

    case c.FETCH_STATE_FUNDING_AND_PRICING_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    default:
      return state
  }
}

const initialDistrictLockStatusState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export const districtFundingLockStatus = (
  state = initialDistrictLockStatusState,
  { type, payload }
) => {
  switch (type) {
    case c.FETCH_DISTRICT_FUNDING_LOCK_DATE_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }
    case c.FETCH_DISTRICT_FUNDING_LOCK_DATE_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: payload }
    case c.FETCH_DISTRICT_FUNDING_LOCK_DATE_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }
    default:
      return state
  }
}
