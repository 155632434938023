import { useHistory, useParams } from 'react-router-dom'
import { Error, Loader } from '@myap/ui-library/esm/components'
import { setNavLink, unsetNavLink } from '../../actions/nav'
import { addFetchAction, removeFetchAction } from '../../actions/app'
import { fetchInvoices } from '../../actions/invoices'
import InvoiceAvailable from '../../components/invoices/InvoiceAvailable'
import InvoiceNotAvailable from '../../components/invoices/InvoiceNotAvailable'
import InvoiceReimbursementCopy from '../../components/invoices/InvoiceReimbursementCopy'
import { NAV_LINKS } from '../../constants/NavConstants'
import { isDirty } from '../../components/invoices/utils'
import styles from '../../assets/style/scss/invoices.scss'

const mapStateToProps = ({
  user,
  settingsEducationPeriod,
  invoices,
  settingsDeadlines: { data: settingsDeadlinesData },
}) => {
  const { selectedEducationPeriod: educationPeriodCd } = settingsEducationPeriod
  const invoiceAvailableDateIsPast =
    settingsDeadlinesData?.[educationPeriodCd]?.invoiceAvailableDateIsPast
  return {
    orgId: user.data.selectedOrgId,
    educationPeriodCd,
    invoiceAvailableDateIsPast,
    ...invoices,
  }
}

const InvoicesPage = props => {
  const {
    orgId,
    educationPeriodCd,
    error,
    fetching,
    fetched,
    data = [],
    fetchInvoices,
    invoiceAvailableDateIsPast,
    setNavLink,
    unsetNavLink,
    addFetchAction,
    removeFetchAction,
  } = props
  const history = useHistory()
  const { type } = useParams()

  useEffect(() => {
    addFetchAction({
      name: 'fetchInvoices',
      action: (orgId, educationPeriodCd) => fetchInvoices(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchInvoices')
  }, [])

  useEffect(() => {
    const link = type ? NAV_LINKS.invoice : NAV_LINKS.packingshipments
    const title = type ? 'Back to Invoice' : 'Back to Packing List & Invoice'

    setNavLink(link, title)
    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    if (type && (!invoiceAvailableDateIsPast || isDirty(data))) history.push(NAV_LINKS.invoice)
  })

  if (error)
    return <Error useWrapper={true} title="Error retrieving invoice data" message={error} />

  if (fetching) return <Loader />

  if (fetched || !invoiceAvailableDateIsPast)
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <div className={`container ${styles.invoices}`}>
          {type ? (
            <InvoiceReimbursementCopy type={type} />
          ) : invoiceAvailableDateIsPast ? (
            <InvoiceAvailable />
          ) : (
            <InvoiceNotAvailable />
          )}
        </div>
      </CSSTransition>
    )

  return null
}

export default connect(mapStateToProps, {
  fetchInvoices,
  setNavLink,
  unsetNavLink,
  addFetchAction,
  removeFetchAction,
})(InvoicesPage)
