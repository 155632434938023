import {
  manipulateDays,
  isDatePast,
  isDateTimePast,
  manipulateMinutes,
  parseDateTime,
  isValidDate,
  formatDate,
} from '@myap/ui-library/esm/date'
import memoize from 'memoizee'
import orderBy from 'lodash-es/orderBy'
import {
  CHECKIN_STATUSES,
  EXAM_STATUSES,
  MAKEUP_STATUSES,
  SETUP_STATUSES,
  DEFAULT_STATUS,
  STATUS_NOT_STARTED,
  STATUS_COMPLETE,
  STATUS_INCOMPLETE,
  STATUS_IN_PROGRESS,
  PRACTICE_STATUSES,
  DEMO_PREFIX,
  PRACTICE_PREFIX,
  STATUS_NOT_APPLICABLE,
  SORT_KEYS,
} from '../constants/ExamReadinessConstants'
import { DIGITAL_EXAM } from '../constants/SettingsConstants'
import { sortColumnByKey } from '../utils/sort'
import { EXAM_FILTER_FUNCTIONS } from './filters/examReadinessFilters'
import { filterData } from './filters/utils'

const getCheckpoints = memoize((start, end) => {
  const startDate = formatDate(start) // remove time
  return {
    installAlertStartDate: manipulateDays(startDate, -7),
    setupAvailableDate: manipulateDays(startDate, -3),
    setupAlertStartDate: startDate,
    checkinAvailableDate: manipulateMinutes(start, -30),
    examStartDelayedDate: manipulateMinutes(start, 10),
    examAlertEndDate: manipulateMinutes(end, 5),
    examNextDate: manipulateDays(startDate, 1),
    examSubmissionOverDate: manipulateDays(startDate, 6),
  }
})

const setPaperStatus = (examFormat, processedStatus) =>
  examFormat !== DIGITAL_EXAM ? STATUS_NOT_APPLICABLE : processedStatus

const getInstallProps = ({
  appInstalled,
  showAlert,
  installAlertStartDate,
  examDelayHasPassed,
}) => ({
  appInstalledFormatted: appInstalled ? 'Yes' : 'No',
  appInstalledAlert: {
    showAlert: showAlert && !appInstalled && isDatePast(installAlertStartDate),
    showAlertAsWarning: examDelayHasPassed,
  },
})

const getPracticeProps = ({ toolsPracticeStatus, practiceStatus }) => {
  const useToolsStatus =
    (!practiceStatus && toolsPracticeStatus) ||
    (practiceStatus !== STATUS_COMPLETE && toolsPracticeStatus === STATUS_COMPLETE)
  const status = useToolsStatus
    ? `${DEMO_PREFIX}${toolsPracticeStatus}`
    : `${PRACTICE_PREFIX}${practiceStatus || STATUS_NOT_STARTED}`

  return {
    combinedPracticeStatus: status,
    practiceStatusFormatted: PRACTICE_STATUSES[status],
  }
}

const getSetupProps = ({
  setupStatus,
  showAlert,
  setupAvailableDate,
  setupAlertStartDate,
  examDelayHasPassed,
  examFormat,
}) => {
  const isSetupAvailable = isDatePast(setupAvailableDate)
  const processedStatus = setPaperStatus(
    examFormat,
    isSetupAvailable && !setupStatus ? STATUS_NOT_STARTED : setupStatus
  )

  return {
    setupStatus: processedStatus,
    setupStatusFormatted: SETUP_STATUSES[processedStatus] || DEFAULT_STATUS,
    setupStatusAlert: {
      showAlert: showAlert && setupStatus !== STATUS_COMPLETE && isDatePast(setupAlertStartDate),
      showAlertAsWarning: examDelayHasPassed,
    },
  }
}

const getCheckinProps = ({
  checkinStatus,
  checkinAvailableDate,
  examDelayHasPassed,
  showAlert,
  examFormat,
}) => {
  const isCheckinPast = isDateTimePast(checkinAvailableDate)
  const processedStatus = setPaperStatus(
    examFormat,
    isCheckinPast && !checkinStatus ? STATUS_IN_PROGRESS : checkinStatus
  )

  return {
    checkinStatus: processedStatus,
    checkinStatusFormatted: CHECKIN_STATUSES[processedStatus] || DEFAULT_STATUS,
    checkinStatusAlert: {
      showAlert: showAlert && examDelayHasPassed && checkinStatus !== STATUS_COMPLETE,
      showAlertAsWarning: true, // there is not an active alert for checkins
    },
  }
}

const getExamProps = ({
  examStatus,
  checkinStatus,
  examNextDate,
  examSubmissionOverDate,
  examDelayHasPassed,
  examAlertEndDate,
  examHasEnded,
  showAlert,
  examFormat,
}) => {
  const processedStatus = setPaperStatus(
    examFormat,
    examHasEnded && examStatus === STATUS_IN_PROGRESS
      ? STATUS_INCOMPLETE
      : examDelayHasPassed && checkinStatus === STATUS_COMPLETE && !examStatus
      ? STATUS_NOT_STARTED
      : examStatus
  )

  return {
    examStatus: processedStatus,
    examStatusFormatted: EXAM_STATUSES[processedStatus] || DEFAULT_STATUS,
    examStatusAlert: {
      showAlert:
        showAlert &&
        ((processedStatus === STATUS_NOT_STARTED && examDelayHasPassed) ||
          (processedStatus === STATUS_INCOMPLETE && isDateTimePast(examAlertEndDate))),
      showAlertAsWarning:
        (processedStatus === STATUS_NOT_STARTED && isDatePast(examNextDate)) ||
        (processedStatus === STATUS_INCOMPLETE && isDatePast(examSubmissionOverDate)),
    },
  }
}

const getMakeupProps = ({ makeupStatus, examFormat }) => {
  const processedStatus = setPaperStatus(examFormat, makeupStatus)
  return {
    makeupStatus: processedStatus,
    makeupStatusFormatted: MAKEUP_STATUSES[processedStatus] || DEFAULT_STATUS,
  }
}

export const getStatusProps = props => {
  const checkpoints = getCheckpoints(props.examDateTime, props.examEndTime)
  const statusProps = {
    ...props,
    ...checkpoints,
    showAlert: props.examFormat === DIGITAL_EXAM && !props.makeupStatus && props.isLatestExam,
    examHasEnded: isDateTimePast(props.examEndTime),
    examDelayHasPassed: isDateTimePast(checkpoints.examStartDelayedDate),
  }
  return {
    checkpoints,
    statuses: {
      ...getInstallProps(statusProps),
      ...getPracticeProps(statusProps),
      ...getSetupProps(statusProps),
      ...getCheckinProps(statusProps),
      ...getExamProps(statusProps),
      ...getMakeupProps(statusProps),
    },
  }
}

export const filterPassedCheckpoints = checkpoints =>
  checkpoints.filter(c => isValidDate(parseDateTime(c)) && !isDateTimePast(c))

export const reprocessExamReadinessData = exams => {
  const examObj = {}
  const examLen = exams.length
  for (let i = 0; i < examLen; i += 1) {
    const exam = exams[i]
    const { statuses } = getStatusProps(exam)
    examObj[exam.studentExamId] = {
      ...exam,
      ...statuses,
      isLatestExam: true,
    }
  }
  return examObj
}

export const processExamReadinessData = memoize(data => {
  const { students, sectionMap, examWindowMap } = data
  const examObj = {}
  const uniqueCourses = []
  const examRefreshAlarms = {}
  const studentLen = students.length

  for (let i = 0; i < studentLen; i += 1) {
    const { courses, id: apId, ...studentCommon } = students[i]
    const courseLen = courses.length

    for (let i = 0; i < courseLen; i += 1) {
      const { exams, sectionId, name: courseName, ...courseCommon } = courses[i]
      const { testCode, ...section } = sectionMap[sectionId]
      const examsLen = exams.length
      const sortedExams =
        examsLen > 1
          ? orderBy(exams, e => examWindowMap[e.examWindow].examMap[testCode].examDateTime, 'desc')
          : exams

      // for course dropdown filter
      if (!uniqueCourses.find(c => c.testCode === testCode))
        uniqueCourses.push({ testCode, courseName })

      for (let i = 0; i < examsLen; i += 1) {
        const exam = exams[i]
        const { displayName, examMap } = examWindowMap[exam.examWindow]
        const examWindow = examMap[testCode]
        const isLatestExam = examsLen === 1 || sortedExams[0].id === exam.id
        const studentExamId = `${apId}-${exam.id}`
        const alarmId = `${exam.examWindow}-${testCode}`
        const props = {
          studentExamId,
          ...exam,
          ...section,
          ...studentCommon,
          ...courseCommon,
          ...examWindow,
          courseName,
          displayName,
          testCode,
          sectionId,
          apId,
          isLatestExam,
        }
        const { checkpoints, statuses } = getStatusProps(props)

        // for tracking upcoming checkpoints to automatically update statuses/alerts as time passes
        // saves times and exams to re-process when checkpoint time passes
        if (isLatestExam && examWindow.examFormat === DIGITAL_EXAM) {
          if (examRefreshAlarms[alarmId])
            examRefreshAlarms[alarmId].exams = [...examRefreshAlarms[alarmId].exams, studentExamId]
          else
            examRefreshAlarms[alarmId] = {
              exams: [studentExamId],
              alarmId,
              checkpoints: filterPassedCheckpoints([
                examWindow.examDateTime,
                ...Object.values(checkpoints),
              ]),
            }
        }

        examObj[studentExamId] = { ...props, ...statuses }
      }
    }
  }

  return {
    examObj,
    courses: sortColumnByKey(uniqueCourses, 'courseName', 'asc'),
    examRefreshAlarms,
  }
})

export const sortExamReadinessData = memoize((data, sortBy, sortDirection) => {
  const keys = SORT_KEYS[sortBy]
  return keys ? sortColumnByKey(data, keys, sortDirection) : data
})

export const filterExamReadinessData = memoize(
  (filters, data) =>
    Object.keys(filters).length ? filterData(data, filters, EXAM_FILTER_FUNCTIONS) : data,
  { length: 2 }
)

export const getExamValues = memoize((obj, filterFn) => {
  const values = Object.values(obj)
  return filterFn ? values.filter(filterFn) : values
})
