import { openModal } from '../../../actions/app'
import {
  buildSpecialDigitalFormats,
  anySpecialDigitalFormatsSelected,
} from '../../../selectors/section'
import { isDigitalExamInCutoffPeriod } from '../../../selectors/examWindows'
import styles from '../../../assets/style/scss/student-details.scss'

const mapStateToProps = state => ({
  descriptions: state.settingsSpecialDigitalFormats?.ssdCdDescriptions ?? {},
  accommodations: state.studentsByOrg?.accommodationsInfo?.accommodations ?? [],
})

const mapEditButtonStateToProps = (state, ownProps) => {
  const { exam } = ownProps
  const isAfterCutoff = isDigitalExamInCutoffPeriod(state, exam)
  return { isAfterCutoff }
}

const EditButton = connect(mapEditButtonStateToProps)(
  ({ exam: { specialDigitalFormats = {}, examId }, modalAction, isAfterCutoff }) => {
    const buttonRef = useRef(null)

    if (!isAfterCutoff) {
      return (
        <button
          type="button"
          className={`btn-link ${styles['order-ssd']}`}
          style={{ padding: '1px 0', border: 0 }}
          ref={buttonRef}
          onClick={() =>
            modalAction('OrderSpecialDigitalFormatsModal', {
              examId,
              modalCloseFocusElem: buttonRef.current,
            })
          }
        >
          {anySpecialDigitalFormatsSelected(specialDigitalFormats) ? (
            <span>edit</span>
          ) : (
            <span>Order Special Digital Formats</span>
          )}
        </button>
      )
    }
    return null
  }
)

const EditAccommodationsLink = connect(mapEditButtonStateToProps)(
  ({ exam, isAfterCutoff, modalAction }) => {
    if (!isAfterCutoff) {
      return (
        <span style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
          {' '}
          (<EditButton exam={exam} modalAction={modalAction} />)
        </span>
      )
    }
    return null
  }
)

const truncateAtChars = 100

const ListOfSelectedSpecialDigitalFormats = ({ exam, descriptions, accommodations, openModal }) => {
  const [expanded, setExpanded] = useState(false)
  const { specialDigitalFormats = {} } = exam

  const ExpandButton = () => (
    <button
      className="btn-link"
      type="button"
      onClick={() => setExpanded(!expanded)}
      style={{ display: 'inline', marginLeft: '5px', padding: '1px 0', border: 0 }}
    >
      {expanded ? 'less' : 'more'}
    </button>
  )

  if (anySpecialDigitalFormatsSelected(specialDigitalFormats)) {
    const specialDigitalFormatsSelectedString = buildSpecialDigitalFormats({
      descriptions,
      specialDigitalFormats,
      accommodations,
    })
    const outputList =
      specialDigitalFormatsSelectedString.length > truncateAtChars
        ? APRICOT.utils.textTruncate(
            specialDigitalFormatsSelectedString,
            expanded ? 10000 : truncateAtChars,
            'last',
            !expanded ? '...' : ''
          )
        : specialDigitalFormatsSelectedString
    return (
      <p className={styles['selected-ssd-materials']}>
        {outputList}
        {specialDigitalFormatsSelectedString.length > truncateAtChars && <ExpandButton />}
        <EditAccommodationsLink exam={exam} modalAction={openModal} />
      </p>
    )
  }

  return <EditButton exam={exam} modalAction={openModal} />
}

export default connect(mapStateToProps, { openModal })(ListOfSelectedSpecialDigitalFormats)
