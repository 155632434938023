import SubOrderStatus from './SubOrderStatus'
import SubOrderDetailsTable from './SubOrderDetailsTable'

const CollapsableSections = ({
  suborder,
  sectionBody,
  sectionHeader,
  btnTextExpanded = 'hide details',
  btnTextCollapsed = 'view details',
}) => {
  const [displayBody, setDisplayBody] = useState(false)

  const onClickDetailsButton = () => setDisplayBody(!displayBody)

  if (!suborder) return null

  return (
    <div className="row">
      <div className="col-xs-12" style={{ marginBottom: '24px' }}>
        {/* Section Header */}
        <h5 style={{ marginBottom: '5px' }}>{sectionHeader}</h5>

        <div style={{ marginRight: '10px', border: '1px solid black', paddingBottom: '10px' }}>
          {/* Section Top */}
          <SubOrderStatus suborder={suborder} />

          {sectionBody && (
            <div className="row" style={{ margin: '3px' }}>
              <button
                type="button"
                className="btn-link cb-small-font-size"
                onClick={onClickDetailsButton}
                aria-expanded={displayBody}
              >
                {displayBody ? btnTextExpanded : btnTextCollapsed}
              </button>
            </div>
          )}

          {/* Section Body */}
          {displayBody && <SubOrderDetailsTable suborder={suborder} />}
        </div>
      </div>
    </div>
  )
}

export default CollapsableSections
