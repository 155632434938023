export default class NotFoundPage extends Component {
  render() {
    return (
      <CSSTransition classNames="animate-fade" in={true} timeout={500} appear={true} enter={false} exit={false}>
        <div className="container" style={{ marginTop: 50 }}>
          <div className="row">
            <div className="col-xs-12">
              <h1>404 - Page Not Found</h1>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}
