import {
  TEST_LOCATION_HOME,
  TEST_LOCATION_SCHOOL,
} from '../../../../../constants/SettingsConstants'
import { STUDENT_ENROLLMENT_STATUS_YES } from '../../../../../constants/StudentConstants'
import { BasicInput } from '../../../../common'
import { isAdministeringDigitalExams } from '../../../../../selectors/settings'

const FIELD_NAME = 'testLocation'

const getLabelText = multiStudentUpate => {
  if (multiStudentUpate)
    return {
      school: (
        <>
          I acknowledge that the students I've selected understand they are taking{' '}
          <strong>digital exams in school</strong>. These students meet all requirements for digital
          testing in school.
        </>
      ),
      home: (
        <>
          I acknowledge that the students I've selected understand they are taking{' '}
          <strong>digital exams at home</strong>. These students meet all requirements for digital
          testing at home.
        </>
      ),
      paper: (
        <>
          I acknowledge that the students I’ve selected understand they are taking{' '}
          <strong>paper exams administered in school</strong>.
        </>
      ),
    }

  return {
    school: (
      <>
        I acknowledge that this student understands they are taking a{' '}
        <strong>digital exam in school</strong>. This student meets all requirements for digital
        testing in school.{' '}
      </>
    ),
    home: (
      <>
        I acknowledge that this student understands they are taking a{' '}
        <strong>digital exam at home</strong>. This student meets all requirements for digital
        testing at home.{' '}
      </>
    ),
    paper: (
      <>
        I acknowledge that this student understands they are taking a{' '}
        <strong>paper exam administered in school</strong>.
      </>
    ),
  }
}

// Default examIntent to YES because components that use TestingLocation and do not send examIntent
// are only displaying TestingLocation in cases where examIntent must be YES
const TestingLocation = ({
  isDigital,
  examIntent = STUDENT_ENROLLMENT_STATUS_YES,
  testLocation,
  updateTestLocation,
  attestTestLocation,
  multiStudentUpate,
  administeringDigitalExams,
}) => {
  const { home, school, paper } = getLabelText(multiStudentUpate)
  if (administeringDigitalExams && examIntent === STUDENT_ENROLLMENT_STATUS_YES) {
    if (isDigital) {
      return (
        <fieldset className="form-group" style={{ marginTop: '24px' }}>
          <legend className="sr-only">Select testing location:</legend>
          <BasicInput
            type="radio"
            style={{ marginBottom: 10 }}
            input={{
              name: FIELD_NAME,
              value: TEST_LOCATION_SCHOOL,
              onChange: updateTestLocation,
            }}
            label={school}
            checked={testLocation === TEST_LOCATION_SCHOOL}
          />
          <BasicInput
            type="radio"
            input={{
              name: FIELD_NAME,
              value: TEST_LOCATION_HOME,
              onChange: updateTestLocation,
            }}
            label={home}
            checked={testLocation === TEST_LOCATION_HOME}
          />
        </fieldset>
      )
    }

    return (
      <BasicInput
        type="checkbox"
        input={{
          name: FIELD_NAME,
          value: true,
          onChange: attestTestLocation,
        }}
        label={paper}
      />
    )
  }
  return null
}

export default connect(state => ({
  administeringDigitalExams: isAdministeringDigitalExams(state),
}))(TestingLocation)
