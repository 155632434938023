import { useCallback } from 'react'
import { change } from 'redux-form'
import { fetchAvailableCourses } from '../../../actions/course'
import { Select, normalizeToNumber } from '../../common'
import { CREATE_FORM_NAME, CREATE_FORM_FIELDS } from '../../../constants/SectionConstants'
import { getAllCourses } from '../../../selectors/course'
import { mapToLabelValueObjectArrayAndSort } from '../../../utils/sort'

const mapStateToProps = (state, { testCd = null, orgId }) => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    availableCourses: { fetching: fetchingCourses },
  } = state
  const data = getAllCourses({ state })
  return {
    testCd,
    orgId,
    educationPeriodCd,
    fetchingCourses,
    availableCourses: mapToLabelValueObjectArrayAndSort(data, 'courseName', 'testCd'),
  }
}

export default connect(mapStateToProps, { fetchAvailableCourses, change })(
  ({
    testCd,
    orgId,
    educationPeriodCd,
    isCourseCreateMode,
    fetchingCourses,
    availableCourses,
    fetchAvailableCourses,
    change,
  }) => {
    const [selectedCourse, setSelectedCourse] = useState({ value: null, label: '' })
    const fetchCourses = useCallback(() => {
      if (orgId && educationPeriodCd && !availableCourses?.length) {
        fetchAvailableCourses({ orgId, educationPeriodCd })
      }
    }, [orgId, educationPeriodCd, availableCourses])

    useEffect(() => {
      if (!fetchingCourses) {
        // Need to fetch available courses at least once or when org changes
        fetchCourses()
      }
    }, [availableCourses, fetchCourses, fetchingCourses])

    useEffect(() => {
      if (availableCourses?.length && testCd) {
        const course = availableCourses.find(c => c.value === parseInt(testCd, 10))
        // for the impossible case where a user tries to create sections for a course
        // they do not have access to
        if (course && Object.keys(course).length) {
          setSelectedCourse(course)
        } else {
          setSelectedCourse({ value: null, label: '' })
        }
      }
    }, [availableCourses, testCd])

    if (!testCd && availableCourses?.length)
      return (
        <Select
          name={CREATE_FORM_FIELDS.testCd}
          label="Course"
          options={availableCourses}
          normalize={normalizeToNumber}
          isRequired={true}
          onChange={e => {
            const course = availableCourses.find(c => c.value === parseInt(e.target.value, 10))
            setSelectedCourse(course)
          }}
        />
      )

    return (
      <div
        className="roboto-slab"
        style={{ fontSize: '1.2em', marginTop: '-15px', marginBottom: '20px' }}
      >
        {selectedCourse?.label}{' '}
        {isCourseCreateMode && (
          <button
            type="button"
            className="btn-link cb-small-font-size cb-roboto"
            onClick={() => change(CREATE_FORM_NAME, CREATE_FORM_FIELDS.testCd, null)}
          >
            change
          </button>
        )}
      </div>
    )
  }
)
