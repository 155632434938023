import { MATERIAL_TYPES } from '../../constants/ShipmentConstants'
import { fetchShipmentsSuborder } from '../../actions/shipments'
import { sortColumnByKey } from '../../utils/sort'
import ExamTable from './ExamTable'
import NonstockExamTable from './NonstockExamTable'

const mapStateToProps = (state, ownProps) => {
  const {
    suborder: { id },
  } = ownProps
  const {
    suborderDetails: { data },
  } = state
  return {
    id,
    details: data[id],
  }
}

const organizeDetails = shippedItems => {
  return shippedItems.reduce(
    (acc, item) => {
      const newItem = item
      if (newItem.cds?.length) {
        newItem.cds = sortColumnByKey(newItem.cds, 'examComponentDesc', 'asc')
      }
      if (newItem.examMaterial) acc.EXAM_MATERIALS.push(newItem)
      else acc.ANCILLARY_MATERIALS.push(newItem)
      return acc
    },
    { EXAM_MATERIALS: [], ANCILLARY_MATERIALS: [] }
  )
}

const SubOrderDetailsTable = ({ id, details, suborder, fetchShipmentsSuborder }) => {
  useEffect(() => {
    if (!details) {
      fetchShipmentsSuborder(id)
    }
  }, [])

  if (!details) return null

  if (details?.length) {
    const organizedSuborders = organizeDetails(sortColumnByKey(details, ['productDesc'], ['asc']))
    return MATERIAL_TYPES.map(
      type =>
        organizedSuborders[type].length !== 0 && (
          <ExamTable
            key={APRICOT.utils.uniqueID()}
            organizedSuborders={organizedSuborders}
            type={type}
            id={id}
          />
        )
    )
  }
  if (suborder?.ssdMaterials.length) {
    // non stock
    return <NonstockExamTable suborder={suborder} />
  }
  return (
    <div className="cb-small-font-size" style={{ padding: '0 10px' }}>
      No data Available
    </div>
  )
}

export default connect(
  mapStateToProps,
  { fetchShipmentsSuborder }
)(SubOrderDetailsTable)
