import { Error, Loader } from '@myap/ui-library'
import { fetchExamRoomSchedule } from '../../actions/examRoom'
import { Tabs } from '../../components/common'
import ExamRoomSchedule from '../../components/examRoom/ExamRoomSchedule'

const mapStateToProps = state => ({
  orgId: state.user.data.selectedOrgId,
  educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
  ...state.examRoomSchedule,
})

const ExamRoomPage = props => {
  const { orgId, educationPeriod, fetching, fetched, error, fetchExamRoomSchedule, data } = props

  useEffect(() => {
    fetchExamRoomSchedule(orgId, educationPeriod)
  }, [])

  if (fetching) return <Loader />

  if (error)
    return <Error title="Error loading exam room schedule" message={error} useWrapper={true} />

  if (fetched)
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <div className="container">
          <h2 style={{ marginTop: '1em', marginBottom: '1.5em' }}>Exam Room Schedule</h2>
          <Tabs
            page="examroom"
            tabs={Object.values(data).map((d, i) => ({
              isDefault: i === 0,
              label: d.description,
              Component: () => <ExamRoomSchedule type={d.id} />,
              compId: d.id,
            }))}
          />
        </div>
      </CSSTransition>
    )

  return null
}

export default connect(
  mapStateToProps,
  { fetchExamRoomSchedule }
)(ExamRoomPage)
