import DownloadInvoiceLink from './DownloadInvoiceLink'
import { isDirty, isCentralBill } from './utils'

const ProgramInvoice = ({ data, date }) => {
  const { invoicePDFGenerated } = data
  const updating = !invoicePDFGenerated || isDirty(data)

  return isCentralBill(data) ? null : (
    <>
      <h3 className="h4" style={{ marginTop: '1em' }}>
        AP Program Invoice
      </h3>
      {updating ? (
        <p>
          You have made changes which have impacted your AP Program Invoice. We will notify you when
          an updated PDF version is ready to access.
        </p>
      ) : (
        <>
          <p>
            Mail this invoice with payment by <strong>{date}</strong>. Make checks payable to{' '}
            <strong>College Board</strong>.
          </p>
          <DownloadInvoiceLink showAsButton={true} title="View AP Program Invoice" />
        </>
      )}
    </>
  )
}

export default ProgramInvoice
