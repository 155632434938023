import { Error, Loader } from '@myap/ui-library/esm/components'
import { setNavLink, unsetNavLink } from '../../actions/nav'
import { fetchInvoiceHistory } from '../../actions/invoices'
import { NAV_LINKS } from '../../constants/NavConstants'
import styles from '../../assets/style/scss/invoices.scss'
import InvoiceHistory from '../../components/invoices/InvoiceHistory'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    invoiceHistory: { fetching, error, data },
  } = state

  return {
    orgId,
    educationPeriodCd,
    fetching,
    error,
    data,
  }
}

const InvoicesPage = ({
  orgId,
  educationPeriodCd,
  error,
  fetching,
  data = [],
  fetchInvoiceHistory,
  setNavLink,
  unsetNavLink,
}) => {
  useEffect(() => {
    setNavLink(NAV_LINKS.invoice, 'Back to Invoice Details')
    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchInvoiceHistory({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd])

  if (error)
    return <Error useWrapper={true} title="Error retrieving invoice history" message={error} />

  if (fetching) return <Loader />

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className={`container ${styles.invoices}`}>
        <h2>Invoice History</h2>
        <InvoiceHistory data={data} />
      </div>
    </CSSTransition>
  )
}
//
export default connect(mapStateToProps, { fetchInvoiceHistory, setNavLink, unsetNavLink })(
  InvoicesPage
)
