import { updateAttendingSchool } from '../../../actions/csr'
import { SearchForStudents } from '.'

const mapStateToProps = state => ({
  educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
  orgId: state.user.data.selectedOrgId,
})

const RemoveAttendingSchool = ({ orgId, educationPeriod }) => {
  const save = selected =>
    updateAttendingSchool(orgId, educationPeriod, selected.map(student => student.studentId))

  return (
    <SearchForStudents
      orgId={orgId}
      educationPeriod={educationPeriod}
      inputLabel="Search for students whose attending school needs to be removed."
      getButtons={() => [{ title: 'Remove Attending School', save }]}
      component={({ student }) => (
        <>
          <div>School Code: {student.aiCode}</div>
          <div>
            {student.homeState}, {student.homeCountry}
          </div>
        </>
      )}
    />
  )
}

export default connect(mapStateToProps)(RemoveAttendingSchool)
