export const FETCH_EXAM_READINESS_PENDING = 'FETCH_EXAM_READINESS_PENDING'
export const FETCH_EXAM_READINESS_FULFILLED = 'FETCH_EXAM_READINESS_FULFILLED'
export const FETCH_EXAM_READINESS_REJECTED = 'FETCH_EXAM_READINESS_REJECTED'

export const REPROCESS_EXAM_STATUSES = 'REPROCESS_EXAM_STATUSES'
export const UPDATE_EXAM_STATUS = 'UPDATE_EXAM_STATUS'

export const UPDATE_MAKEUP_STATUS_PENDING = 'UPDATE_MAKEUP_STATUS_PENDING'
export const UPDATE_MAKEUP_STATUS_FULFILLED = 'UPDATE_MAKEUP_STATUS_FULFILLED'
export const UPDATE_MAKEUP_STATUS_REJECTED = 'UPDATE_MAKEUP_STATUS_REJECTED'

export const STATUS_NOT_APPLICABLE = 'NOT_APPLICABLE' // UI only
export const STATUS_NOT_STARTED = 'NOT_STARTED' // UI only
export const STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const STATUS_INCOMPLETE = 'INCOMPLETE' // UI only
export const STATUS_COMPLETE = 'COMPLETE'

export const DEFAULT_STATUS = '-'
export const PRACTICE_PREFIX = 'PRACTICE_'
export const DEMO_PREFIX = 'DEMO_'
export const PRACTICE_WINDOW = 'PRACTICE'

export const PRACTICE_STATUSES = {
  [`${PRACTICE_PREFIX}${STATUS_NOT_STARTED}`]: 'Not Started',
  [`${DEMO_PREFIX}${STATUS_IN_PROGRESS}`]: 'Demo: In Progress',
  [`${DEMO_PREFIX}${STATUS_COMPLETE}`]: 'Demo: Complete',
  [`${PRACTICE_PREFIX}${STATUS_IN_PROGRESS}`]: 'Practice: In Progress',
  [`${PRACTICE_PREFIX}${STATUS_COMPLETE}`]: 'Practice: Complete',
}

export const SETUP_STATUSES = {
  [STATUS_NOT_APPLICABLE]: 'n/a',
  [STATUS_NOT_STARTED]: 'Not Started',
  [STATUS_IN_PROGRESS]: 'In Progress',
  [STATUS_COMPLETE]: 'Complete',
}

export const CHECKIN_STATUSES = {
  [STATUS_NOT_APPLICABLE]: 'n/a',
  [STATUS_IN_PROGRESS]: 'Not Checked In',
  [STATUS_COMPLETE]: 'Check-In Complete',
}

export const EXAM_STATUSES = {
  [STATUS_NOT_APPLICABLE]: 'n/a',
  [STATUS_NOT_STARTED]: 'Exam Not Started',
  [STATUS_IN_PROGRESS]: 'Exam Started',
  [STATUS_INCOMPLETE]: 'Not Submitted',
  [STATUS_COMPLETE]: 'Submission Complete',
}

export const MAKEUP_REQUESTED = 'REQUESTED'
export const MAKEUP_DENIED = 'DENIED'
export const MAKEUP_APPROVED = 'APPROVED'
export const MAKEUP_STATUSES = {
  [STATUS_NOT_APPLICABLE]: 'n/a',
  [MAKEUP_APPROVED]: 'Approved',
  [MAKEUP_DENIED]: 'Denied',
  [MAKEUP_REQUESTED]: 'Requested',
}

export const TABLE_KEYS = {
  student: 'studentName',
  course: 'courseName',
  date: 'examDate',
  install: 'appInstalled',
  setup: 'setupStatus',
  checkin: 'checkinStatus',
  exam: 'examStatus',
  makeup: 'makeupStatus',
  practice: 'practiceStatus',
}

export const SORT_KEYS = {
  [TABLE_KEYS.student]: ['lastName', 'firstName', 'courseName'],
  [TABLE_KEYS.course]: ['courseName', 'lastName', 'firstName'],
  [TABLE_KEYS.date]: ['examDateTime', 'courseName', 'lastName', 'firstName'],
  [TABLE_KEYS.install]: ['appInstalledFormatted', 'lastName', 'firstName', 'courseName'],
  [TABLE_KEYS.setup]: ['setupStatusFormatted', 'courseName', 'lastName', 'firstName'],
  [TABLE_KEYS.checkin]: ['checkinStatusFormatted', 'courseName', 'lastName', 'firstName'],
  [TABLE_KEYS.exam]: ['examStatusFormatted', 'courseName', 'lastName', 'firstName'],
  [TABLE_KEYS.makeup]: ['makeupStatusFormatted', 'courseName', 'lastName', 'firstName'],
  [TABLE_KEYS.practice]: ['practiceStatusFormatted', 'courseName', 'lastName', 'firstName'],
}

const COMMON_TOOLTIP_HEADERS = {
  [TABLE_KEYS.date]: {
    label: 'Exam Date',
    msg: 'Unless otherwise noted, all times shown are local.',
  },
  [TABLE_KEYS.install]: {
    label: 'Login',
    msg: 'Students will need to log in to the app to verify installation was successful.',
  },
  [TABLE_KEYS.setup]: {
    label: 'Setup',
    msg:
      'Students should complete exam setup before test day to make sure their devices can run the exam.',
  },
}

export const COORDINATOR_TOOLTIP_HEADERS = {
  ...COMMON_TOOLTIP_HEADERS,
  [TABLE_KEYS.exam]: {
    label: 'Exam',
    msg:
      'See if students have checked in, started their exams, and submitted their answers. If answer submission fails, students should relaunch and try again.',
  },
  [TABLE_KEYS.makeup]: {
    label: 'Makeup',
    msg:
      'All makeup requests will be automatically approved 7 days before the makeup exam, unless you deny the request.',
  },
}

export const TEACHER_TOOLTIP_HEADERS = {
  ...COMMON_TOOLTIP_HEADERS,
  [TABLE_KEYS.practice]: {
    label: 'Practice',
    msg:
      "Students have two practice experiences available. In the digital practice, they'll go through exam setup, check-in, and practice questions that approximate a full AP exam. A shorter experience called exam app demo runs through setup, check-in, and just a few questions.",
  },
  [TABLE_KEYS.checkin]: { label: 'Check-In', msg: 'Check-in opens 30 minutes before the exams.' },
  [TABLE_KEYS.exam]: {
    label: 'Exam',
    msg:
      'See if students have started their exams and submitted their answers. If answer submission fails, students should relaunch and try again.',
  },
  [TABLE_KEYS.makeup]: {
    label: 'Makeup',
    msg:
      'All makeup requests will be automatically approved 7 days before the makeup exam, unless the AP coordinator denies the request.',
  },
}
