/**
 * Accepts:
 * label : the dropdown menu's label
 * id : the id to scope the dropdown by
 * menuitems
 *   val : the value to add to the menuitem's anchor's data-val attribute
 *   clickEvent : the function to execute when a menuitem is clicked
 *   label : the label to show in the menuitem
 **/

import { createPortal } from 'react-dom'
import { getWindowOffsetTop, getWindowOffsetLeft } from '../../utils/common'
import { LOCAL_NAVIGATION_HEIGHT } from '../../constants/AppConstants'

const DEFAULT_PADDING = 20

const mapStateToProps = state => ({ breakpoint: state.app.breakpoint.name })

const DropdownPortal = ({
  id,
  menuItems,
  label,
  applyStyles = {},
  ariaDescribedBy,
  ariaLabelledBy,
  rightAligned,
  breakpoint,
  fixWidth, // should be an integer defining the width of the menu in pixels
  menuOpen = false, // set to true if menu should stay open
  toggleOffFunc, // function to call if menuOpen is being used and menu is explicitly closed
}) => {
  const containerRef = useRef(null)
  const triggerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [open, setOpen] = useState(menuOpen)
  const domMenuId = `${id}-menu`
  const DROPDOWN_MIN_WIDTH = fixWidth ?? 160

  const closeDropdown = () => {
    if (toggleOffFunc && menuOpen) {
      toggleOffFunc()
    }
    setOpen(false)
  }

  const clickOutsideDropdown = e => {
    if (open && !containerRef.current.contains(e.target)) {
      closeDropdown()
    }
  }

  const getOffsetTop = (coords, windowOffsetTop) => {
    // accounts for position fixed top navigation after scrolling
    const accountForTopNavigationBeingFixed = windowOffsetTop > 50 ? LOCAL_NAVIGATION_HEIGHT : 0
    const accountForScrollInSmallBreakpoints =
      breakpoint === 'mobile' || breakpoint === 'tablet' ? 20 : 0

    return (
      windowOffsetTop +
      coords.top +
      coords.height -
      accountForTopNavigationBeingFixed +
      accountForScrollInSmallBreakpoints
    )
  }

  const getCoordinates = () => {
    const windowOffsetLeft = getWindowOffsetLeft()
    const windowOffsetTop = getWindowOffsetTop()
    const coords = (triggerRef.current && triggerRef.current.getBoundingClientRect()) || {}
    const minWidth =
      coords.width && coords.width > DROPDOWN_MIN_WIDTH
        ? coords.width + DEFAULT_PADDING
        : DROPDOWN_MIN_WIDTH
    const left = windowOffsetLeft + coords.left - (rightAligned ? minWidth - coords.width : 0)
    const top = getOffsetTop(coords, windowOffsetTop)

    return { top, left, minWidth }
  }

  const getFocusableElem = adjustment => {
    const focusableElems = Array.from(
      document.querySelectorAll(
        'a:not(:disabled), button:not(:disabled), select:not(:disabled), input:not(:disabled), textarea:not(:disabled), [tab-index="0"]:not(:disabled)'
      )
    )
    return focusableElems[focusableElems.indexOf(triggerRef.current) + adjustment]
  }

  const changeFocusMenuElement = (e, index) => {
    const { TAB, ESC, ENTER } = APRICOT.utils.keys
    const isTabbing = e.keyCode === TAB

    if (e.keyCode === ESC) closeDropdown()

    if (!index && isTabbing && e.shiftKey) {
      e.preventDefault()
      triggerRef.current.focus()
    } else if (index === menuItems.length - 1 && isTabbing && !e.shiftKey) {
      e.preventDefault()
      closeDropdown()
      getFocusableElem(1).focus()
    }
  }

  const changeFocusTriggerElement = e => {
    const { DOWN, TAB } = APRICOT.utils.keys
    const isTabbing = e.keyCode === TAB
    const isDown = e.keyCode === DOWN
    const firstMenuItem = dropdownRef.current && dropdownRef.current.getElementsByTagName('a')[0]

    if (isDown && !menuItems.length) setOpen(true)

    if ((isDown || (isTabbing && !e.shiftKey)) && open) {
      e.preventDefault()
      firstMenuItem.focus()
    }

    if (isTabbing && open && e.shiftKey) {
      e.preventDefault()
      closeDropdown()
      getFocusableElem(-1).focus()
    }
  }

  useEffect(() => {
    if (open) {
      const { firstChild: elem } = dropdownRef.current || {}
      const firstMenuItem = dropdownRef.current.getElementsByTagName('a')[0]
      const { top, left, minWidth } = getCoordinates()
      dropdownRef.current.setAttribute(
        'style',
        `top: ${top}px; left: ${left}px; position: absolute`
      )
      elem.setAttribute('style', `width: ${minWidth}px; min-width: ${minWidth}px`)
      firstMenuItem.focus()
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('click', clickOutsideDropdown)
    return () => {
      document.removeEventListener('click', clickOutsideDropdown)
    }
  })

  useEffect(() => {
    window.addEventListener('scroll', closeDropdown)
    window.addEventListener('resize', closeDropdown)
    return () => {
      window.removeEventListener('scroll', closeDropdown)
      window.removeEventListener('resize', closeDropdown)
    }
  }, [])

  return (
    <div className="dropdown cb-dropdown" style={applyStyles} ref={containerRef}>
      {menuItems.length > 0 ? (
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          href="#"
          id={`${id}_Dropdown`}
          role="button"
          aria-labelledby={ariaLabelledBy}
          aria-expanded={open}
          aria-haspopup={true}
          aria-describedby={ariaDescribedBy}
          onClick={e => {
            e.preventDefault()
            setOpen(!open)
          }}
          onKeyDown={changeFocusTriggerElement}
          ref={triggerRef}
        >
          <span id={id}>{label}</span>
          <i className="cb-glyph cb-glyph-right cb-down" aria-hidden="true" />
          <i className="cb-glyph cb-glyph-right cb-up hidden" aria-hidden="true" />
        </a>
      ) : (
        <span>{label}</span>
      )}
      {open
        ? createPortal(
            <div
              className="dropdown cb-dropdown dropdown-portal open"
              ref={dropdownRef}
              id={domMenuId}
            >
              <ul className="dropdown-menu" role="menu" aria-labelledby={id}>
                {menuItems.map((item, i) => (
                  <li
                    role="presentation"
                    key={i}
                    className={`${item.multiLine ? 'cb-multi' : ''}${
                      item.multiLine && item.disabled ? ' ' : ''
                    }${item.disabled ? 'disabled' : ''}`}
                    style={item.gutterIcon ? { position: 'relative' } : {}}
                  >
                    <a
                      id={`${id}-link-${i}`}
                      role="menuitem"
                      data-val={item.val}
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        if (!item.disabled) {
                          item.clickEvent(e)
                          if (!item.stayOpen) {
                            closeDropdown()
                            triggerRef.current.focus()
                          }
                        } else {
                          e.nativeEvent.stopImmediatePropagation()
                        }
                      }}
                      onKeyDown={e => changeFocusMenuElement(e, i)}
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>,

            document.body
          )
        : null}
    </div>
  )
}
export default connect(mapStateToProps)(DropdownPortal)
