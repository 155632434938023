import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { formatter, isCentralBill } from './utils'

import styles from '../../assets/style/scss/invoices.scss'

const mapStateToProps = ({
  invoices: { data = {} },
  settingsEducationPeriod: { selectedEducationPeriod },
  settingsDeadlines: { data: settingsDeadlinesData },
}) => {
  const { paymentDeadline, paymentDeadlineIsPast } =
    settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  return {
    paymentDeadlineDate: formatDate(paymentDeadline, DATETIME_FORMATS.longMonthDayYear),
    paymentDeadlineIsPast,
    ...data,
  }
}

const InvoiceStatus = props => {
  const {
    paymentDeadlineDate,
    paymentDeadlineIsPast,
    totalInvoiceDueAmount = 0,
    centralBillingGrantorName,
    className = '',
  } = props
  const isOverDue = paymentDeadlineIsPast && totalInvoiceDueAmount > 0
  const isRefund = totalInvoiceDueAmount < 0

  return isCentralBill(props) ? (
    <div className={styles[className]} style={{ marginBottom: '.5em', marginRight: '50px' }}>
      Payment for your school&#39;s AP Exams is managed centrally by your district,{' '}
      {centralBillingGrantorName}. You do not need to remit payment for your school individually.
      Before your district processes final payment, please review your invoice to verify accuracy.
    </div>
  ) : (
    <div
      className={`row ${styles[className]} ${isOverDue ? styles['invoice-overdue'] : ''} ${
        isRefund ? styles['invoice-refund'] : ''
      }`}
    >
      <div className="col-sm-4">
        <h3>Amount Due</h3>
        <div className={styles.status}>{formatter(totalInvoiceDueAmount)}</div>
      </div>
      <div className="col-sm-8">
        <h3>Status</h3>
        <div className={styles.status}>
          {totalInvoiceDueAmount === 0
            ? 'Paid'
            : isOverDue
            ? 'Payment Overdue'
            : isRefund
            ? 'Refund'
            : `Payment Due by ${paymentDeadlineDate}`}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(InvoiceStatus)
