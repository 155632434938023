import { reduxForm, isDirty, isSubmitting, change, formValueSelector } from 'redux-form'
import { Error, Loader, BasicInput } from '../common'
import { StepButtons, StepTracker } from '.'
import {
  changeStep,
  setDistrictFundingSchoolSelectionData,
  fetchChildOrgs,
} from '../../actions/funding'
import {
  SCHOOL_SELECTION_FORM_NAME,
  SCHOOL_SELECTION_FORM_FIELDS as FIELDS,
  TOTAL_FUNDING_STEPS,
} from '../../constants/FundingConstants'
import { fundingDataExists } from '../../selectors/funding'
import { sortColumnByKey } from '../../utils/sort'
import { isEmpty } from '../../utils/common'
import styles from '../../assets/style/scss/funding.scss'

const filterSchools = ({ schoolsList, excludeTheseSchools }) =>
  schoolsList.filter(
    ({ orgId }) => !excludeTheseSchools.some(({ orgId: excludeOrgId }) => orgId === excludeOrgId)
  )

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    funding: {
      data: { includeOrgs = [], excludeOrgs = [], excludeCharterSchools = false },
      savedData,
    },
    childOrgs: { fetching: fetchingChildOrgs, error: errorChildOrgs, data: childOrgsData },
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
  } = state

  const selector = formValueSelector(SCHOOL_SELECTION_FORM_NAME)
  let excludeOrgsData = []
  let includeOrgsData = []
  if (excludeOrgs.length > 0) {
    excludeOrgsData = excludeOrgs
    if (includeOrgs.length === 0) {
      childOrgsData.forEach(({ orgId, orgName }) => {
        if (!excludeOrgs.find(excludedOrg => orgId === excludedOrg.orgId)) {
          includeOrgsData.push({
            orgId,
            orgName,
          })
        }
      })
    } else {
      includeOrgsData = includeOrgs
    }
  } else if (includeOrgs.length > 0) {
    includeOrgsData = includeOrgs
    childOrgsData.forEach(({ orgId, orgName }) => {
      if (!includeOrgs.find(includedOrg => orgId === includedOrg.orgId)) {
        excludeOrgsData.push({
          orgId,
          orgName,
        })
      }
    })
  } else if (excludeOrgs.length === 0 && !isEmpty(savedData)) {
    // When excludeOrgs is empty and there is saved data, the user must
    // have added all schools to the funded list
    includeOrgsData = childOrgsData.map(({ orgId, orgName }) => ({
      orgId,
      orgName,
    }))
  } else {
    excludeOrgsData = childOrgsData.map(({ orgId, orgName }) => ({
      orgId,
      orgName,
    }))
  }

  // Build a list of charter schools
  const charterSchools = []
  for (let i = 0; i < childOrgsData.length; i++) {
    const { orgId, orgName, charterInd = 'N' } = childOrgsData[i]
    if (charterInd === 'Y') {
      charterSchools.push({ orgId, orgName })
    }
  }

  return {
    orgId,
    fetchingChildOrgs,
    hasChildOrgsData: childOrgsData.length > 0,
    errorChildOrgs,
    charterSchools,
    academicYear: availableEducationPeriods[selectedEducationPeriod]?.descr,
    dirty: isDirty(SCHOOL_SELECTION_FORM_NAME)(state),
    submitting: isSubmitting(SCHOOL_SELECTION_FORM_NAME)(state),
    formComplete: !isEmpty(includeOrgsData),
    dataExists: fundingDataExists(state),
    initialValues: {
      excludeOrgs: excludeCharterSchools
        ? filterSchools({ schoolsList: excludeOrgsData, excludeTheseSchools: charterSchools })
        : excludeOrgsData,
      includeOrgs: excludeCharterSchools
        ? filterSchools({ schoolsList: includeOrgsData, excludeTheseSchools: charterSchools })
        : includeOrgsData,
      excludeCharterSchools,
    },
    currentValues: {
      excludeOrgs: selector(state, FIELDS.excludeOrgs.name) ?? [],
      includeOrgs: selector(state, FIELDS.includeOrgs.name) ?? [],
      excludeCharterSchools: selector(state, FIELDS.excludeCharterSchools.name),
    },
  }
}

const SchoolSelection = ({
  orgId,
  fetchingChildOrgs,
  hasChildOrgsData,
  errorChildOrgs,
  charterSchools,
  academicYear,
  initialValues,
  currentValues,
  formComplete,
  handleSubmit,
  dirty,
  dataExists,
  submitting,
  fetchChildOrgs,
  change,
}) => {
  const excludeOrgsRef = useRef()
  const includeOrgsRef = useRef()
  const [hasSelectedFromeExcludeOrgs, setHasSelectedFromExcludeOrgs] = useState(false)
  const [hasSelectedFromIncludeOrgs, setHasSelectedFromIncludeOrgs] = useState(false)

  const moveFromListToList = (listA, listB, itemsToMove, setAFunc, setBFunc) => {
    let updatedListA = listA
    let updatedListB = listB
    Array.from(itemsToMove).forEach(({ orgId, orgName }) => {
      updatedListA = updatedListA.filter(aItem => aItem.orgId !== orgId)
      updatedListB = [...updatedListB, { orgId, orgName }]
    })
    setAFunc(updatedListA)
    setBFunc(updatedListB)
  }

  const moveFromExcludeToInclude = itemsToMove => {
    moveFromListToList(
      currentValues[FIELDS.excludeOrgs.name],
      currentValues[FIELDS.includeOrgs.name],
      itemsToMove,
      values => change(FIELDS.excludeOrgs.name, values),
      values => change(FIELDS.includeOrgs.name, values)
    )
  }

  const moveFromIncludeToExclude = itemsToMove => {
    moveFromListToList(
      currentValues[FIELDS.includeOrgs.name],
      currentValues[FIELDS.excludeOrgs.name],
      itemsToMove,
      values => change(FIELDS.includeOrgs.name, values),
      values => change(FIELDS.excludeOrgs.name, values)
    )
  }

  const buildSelectedItems = (items, moveAll = false) => {
    const selectedItems = []
    Array.from(items).forEach(item => {
      if (item.selected || moveAll) {
        selectedItems.push({
          orgId: parseInt(item.value, 10),
          orgName: item.label,
        })
      }
    })
    return selectedItems
  }

  const alertBeforeLeaving = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    window.scroll(0, 0)
    if (dataExists || (dirty && !submitting)) {
      window.addEventListener('beforeunload', alertBeforeLeaving)
    }
    return () => {
      if (dataExists || (dirty && !submitting)) {
        window.removeEventListener('beforeunload', alertBeforeLeaving)
      }
    }
  }, [dirty, submitting])

  useEffect(() => {
    if (isEmpty(initialValues[FIELDS.includeOrgs.name]) && !hasChildOrgsData) {
      fetchChildOrgs(orgId)
    }
  }, [])

  //console.log('Charter Schools:', charterSchools)
  //console.log('Included Schools:', currentValues[FIELDS.includeOrgs.name])
  //console.log('Excluded Schools:', currentValues[FIELDS.excludeOrgs.name])

  if (errorChildOrgs) {
    return <Error message={errorChildOrgs} />
  }

  if (fetchingChildOrgs) {
    return <Loader />
  }

  return (
    <>
      <StepTracker />
      {!isEmpty(currentValues[FIELDS.excludeOrgs.name]) ||
      !isEmpty(currentValues[FIELDS.includeOrgs.name]) ? (
        <>
          <p>
            The schools in your district that are eligible for funding are listed below. Please
            indicate the school(s) your district will be providing funding or paying for in{' '}
            {academicYear}.
          </p>
          <form onSubmit={handleSubmit} className={`row ${styles['select-schools']}`}>
            <div className="col-xs-5">
              <label htmlFor={FIELDS.excludeOrgs.name} className={styles['select-label']}>
                All Schools
              </label>
              <select
                id={FIELDS.excludeOrgs.name}
                name={FIELDS.excludeOrgs.name}
                className={styles['select-schools-controls']}
                multiple={true}
                size="20"
                ref={excludeOrgsRef}
                onChange={e => {
                  const { options = [] } = e.target
                  let hasSelected = false
                  for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                      hasSelected = true
                      break
                    }
                  }
                  setHasSelectedFromExcludeOrgs(hasSelected)
                }}
              >
                {sortColumnByKey(currentValues[FIELDS.excludeOrgs.name], ['orgName'], ['asc']).map(
                  ({ orgId, orgName }) => (
                    <option key={orgId} value={orgId} className={styles['school-option']}>
                      {orgName}
                    </option>
                  )
                )}
              </select>
              <BasicInput
                type="checkbox"
                label="Exclude Charter Schools"
                style={{ marginTop: '8px' }}
                input={{
                  name: FIELDS.excludeCharterSchools.name,
                  value: true,
                  checked: currentValues.excludeCharterSchools,
                  onChange: e => {
                    const { checked } = e.target
                    if (checked) {
                      const filteredExcludedSchools = filterSchools({
                        schoolsList: currentValues[FIELDS.excludeOrgs.name],
                        excludeTheseSchools: charterSchools,
                      })
                      change(FIELDS.excludeOrgs.name, filteredExcludedSchools)
                      const filteredIncludedSchools = filterSchools({
                        schoolsList: currentValues[FIELDS.includeOrgs.name],
                        excludeTheseSchools: charterSchools,
                      })
                      change(FIELDS.includeOrgs.name, filteredIncludedSchools)
                    } else {
                      const filteredCharterSchools = filterSchools({
                        schoolsList: charterSchools,
                        excludeTheseSchools: [
                          ...currentValues[FIELDS.includeOrgs.name],
                          ...currentValues[FIELDS.excludeOrgs.name],
                        ],
                      })
                      if (filteredCharterSchools.length > 0) {
                        change(FIELDS.excludeOrgs.name, [
                          ...currentValues[FIELDS.excludeOrgs.name],
                          ...filteredCharterSchools,
                        ])
                      }
                    }
                    change(FIELDS.excludeCharterSchools.name, checked)
                  },
                }}
              />
            </div>
            <div className="col-xs-2">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  height: '512px',
                  margin: '24px 0',
                  gap: '15px',
                }}
              >
                <button
                  type="button"
                  className={`btn btn-sm btn-primary ${styles['move-school-button']}`}
                  disabled={!hasSelectedFromeExcludeOrgs}
                  onClick={e => {
                    e.preventDefault()
                    // move selected right
                    const itemsToMove = buildSelectedItems(excludeOrgsRef.current.options)
                    moveFromExcludeToInclude(itemsToMove)
                    setHasSelectedFromExcludeOrgs(false)
                  }}
                >
                  Add Selected <span className="cb-glyph cb-right" />
                </button>
                <button
                  type="button"
                  className={`btn btn-sm btn-primary ${styles['move-school-button']}`}
                  disabled={!hasSelectedFromIncludeOrgs}
                  onClick={e => {
                    e.preventDefault()
                    // move selected left
                    const itemsToMove = buildSelectedItems(includeOrgsRef.current.options)
                    moveFromIncludeToExclude(itemsToMove)
                    setHasSelectedFromIncludeOrgs(false)
                  }}
                >
                  <span className="cb-glyph cb-left" /> Remove Selected
                </button>
                <button
                  type="button"
                  className={`btn btn-sm btn-primary ${styles['move-school-button']}`}
                  onClick={e => {
                    e.preventDefault()
                    // move all right
                    const itemsToMove = buildSelectedItems(excludeOrgsRef.current.options, true)
                    moveFromExcludeToInclude(itemsToMove)
                  }}
                  disabled={currentValues[FIELDS.excludeOrgs.name]?.length === 0}
                >
                  Add All <span className="cb-glyph cb-dbl-right" />
                </button>
                <button
                  type="button"
                  className={`btn btn-sm btn-primary ${styles['move-school-button']}`}
                  onClick={e => {
                    e.preventDefault()
                    // move all left
                    const itemsToMove = buildSelectedItems(includeOrgsRef.current.options, true)
                    moveFromIncludeToExclude(itemsToMove)
                  }}
                  disabled={currentValues[FIELDS.includeOrgs.name]?.length === 0}
                >
                  <span className="cb-glyph cb-dbl-left" /> Remove All
                </button>
              </div>
            </div>
            <div className="col-xs-5">
              <label htmlFor={FIELDS.includeOrgs.name} className={styles['select-label']}>
                Funded Schools
              </label>
              <select
                id={FIELDS.includeOrgs.name}
                name={FIELDS.includeOrgs.name}
                className={`${styles['select-schools-controls']}`}
                multiple={true}
                size="20"
                ref={includeOrgsRef}
                onChange={e => {
                  const { options = [] } = e.target
                  let hasSelected = false
                  for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                      hasSelected = true
                      break
                    }
                  }
                  setHasSelectedFromIncludeOrgs(hasSelected)
                }}
              >
                {sortColumnByKey(currentValues[FIELDS.includeOrgs.name], ['orgName'], ['asc']).map(
                  ({ orgId, orgName }) => (
                    <option key={orgId} value={orgId} className={styles['school-option']}>
                      {orgName}
                    </option>
                  )
                )}
              </select>
            </div>
          </form>
        </>
      ) : (
        <p>There are no schools available for funding in this district.</p>
      )}
      <StepButtons
        form={SCHOOL_SELECTION_FORM_NAME}
        previousStep={1}
        formComplete={formComplete}
        totalSteps={TOTAL_FUNDING_STEPS}
      />
    </>
  )
}

export default connect(mapStateToProps, {
  changeStep,
  setDistrictFundingSchoolSelectionData,
  fetchChildOrgs,
  change,
})(
  reduxForm({
    form: SCHOOL_SELECTION_FORM_NAME,
    enableReinitialize: true,
    validate: (vals, props) => {
      if (vals[FIELDS.includeOrgs.name].length < 1) {
        return {
          [FIELDS.includeOrgs.name]: 'At least one school must be selected for funding.',
        }
      }
      return {}
    },
    onSubmit: (vals, dispatch, { charterSchools, setDistrictFundingSchoolSelectionData }) => {
      const includeOrgs = vals[FIELDS.includeOrgs.name]
      let excludeOrgs = []
      const excludeCharterSchools = vals[FIELDS.excludeCharterSchools.name]
      if (excludeCharterSchools) {
        excludeOrgs = [...vals[FIELDS.excludeOrgs.name], ...charterSchools]
      } else {
        excludeOrgs = vals[FIELDS.excludeOrgs.name]
      }
      return setDistrictFundingSchoolSelectionData({
        includeOrgs,
        excludeOrgs,
        excludeCharterSchools,
      })
    },
    onSubmitSuccess: (result, dispatch, { changeStep }) => changeStep(3),
  })(SchoolSelection)
)
