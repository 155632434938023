import { Redirect } from 'react-router-dom'
import { Loader } from '@myap/ui-library/esm/components'
import { getUserDetails } from '../../actions/user'
import { useCatapultAuth } from '../../utils/hooks'
import { getMyAPUrl } from '../../components/routes/utils'
import { queryParamsToObj, isEmpty } from '../../utils/common'
import { NAV_LINKS } from '../../constants/NavConstants'

// redirect rules:
// when previous url is not a deep link
// OR it is terms and condition
// OR it is login
// do not redirect if url query params have redirect=off
const shouldRedirect = ({ from, pathname }) => {
  return (
    pathname === '/' ||
    from === NAV_LINKS.tac ||
    from === NAV_LINKS.login ||
    from === NAV_LINKS.selectRole
  )
}

const validApcUrl = url => {
  if (isEmpty(url)) return false
  const pathArray = url.split('/')
  const protocol = pathArray[0]
  const host = pathArray[2]
  const testUrl = `${protocol}//${host}`
  return testUrl === Config.FINE_TUNE_URL // Check URL against the FT Url for this environment
}

// * The first time this component renders, no user role information will be available, so skip
//   any/all redirection behavior until user role information becomes avaialble.
// * Only students, teachers, coordinators, and admins can use the DURL parameter to redirect to
//   allowed FT URL for the current environment.
// * Otherwise, users with redirect=off parameter will skip redirection, all other cases will
//   redirect to MyAP.
const RedirectPage = connect(
  state => {
    const {
      user: {
        data: { roles, isStudent, isTeacher, isCoordinator, isSchoolAdmin, activeRoleCd },
      },
    } = state
    return {
      isStudent,
      isTeacher,
      isCoordinator,
      isSchoolAdmin,
      hasRoles: roles.length > 0,
      activeRoleCd,
    }
  },
  { getUserDetails }
)(
  ({
    location,
    isStudent,
    isTeacher,
    isCoordinator,
    isSchoolAdmin,
    hasRoles,
    activeRoleCd,
    getUserDetails,
  }) => {
    const { basicProfile } = useCatapultAuth()

    const getParams = search => {
      // If there's a DURL present, we don't want to further split out params in the DURL,
      // so this will capture everything after the DURL parameter and send it on, otherwise we'll
      // perform the usual queryParamToObj processing.
      const durlIndex = search.indexOf('DURL=')
      if (durlIndex !== -1) {
        return { DURL: search.slice(durlIndex + 5) }
      }
      return queryParamsToObj(search) ?? {}
    }

    const queryParams = getParams(location.search)
    const isRedirectOff = queryParams?.redirect === 'off'
    const hasDeepLink = validApcUrl(queryParams.DURL)

    let redirectUrl
    if (!isRedirectOff && activeRoleCd) {
      redirectUrl =
        (isStudent || isTeacher || isCoordinator || isSchoolAdmin) && hasDeepLink
          ? queryParams.DURL
          : getMyAPUrl(basicProfile)
    } else if (!isRedirectOff && !hasRoles && !hasDeepLink) {
      // For example, user is a professional with no authorizations
      // and no roles to select from
      redirectUrl = getMyAPUrl(basicProfile)
    }
    const shouldRedirectFromPreviousLocation = shouldRedirect(location)
    const isPreviousDeepLink = Object.values(NAV_LINKS).find(n => n === location.from)

    // console.info(basicProfile, iam().getAuthSession())
    // console.info('IN REDIRECT!!!!!')
    // console.info(isRedirectOff, location.search)
    // console.info(props)

    if (!isRedirectOff && shouldRedirectFromPreviousLocation && redirectUrl) {
      window.location = redirectUrl
      return <Loader />
    }

    if (activeRoleCd)
      return (
        <Redirect
          to={{
            pathname:
              isPreviousDeepLink && !shouldRedirectFromPreviousLocation
                ? location.from
                : NAV_LINKS.dashboard,
            search: location.search,
          }}
        />
      )

    // User needs to select a role before anything else can happen
    return (
      <Redirect
        to={{
          pathname: NAV_LINKS.selectRole,
          search: location.search,
        }}
      />
    )
  }
)

export default RedirectPage
