import { SelectOrgDropdown } from '@myap/ui-library/esm/components'
import { isAnyDistrict } from '@myap/metadata'
import { setSelectedRole } from '../../actions/user'
import { clearFilters } from '../../actions/filters'
import { openModal } from '../../actions/app'
import { clearSelectedEducationPeriod } from '../../actions/settingsGlobal'
import styles from '../../assets/style/scss/select-school-menu.scss'

const mapStateToProps = state => {
  const { roles, selectedRole, selectedOrgId } = state.user.data
  return {
    roles,
    selectedRole,
    selectedOrgId,
    suppressSchoolMenu: state.nav.suppressSchoolMenu,
  }
}

const SelectSchool = props => {
  const { roles, selectedOrgId, selectedRole, suppressSchoolMenu, alignSchoolMenuRight } = props
  const rolesToShow = suppressSchoolMenu
    ? [roles[selectedRole]]
    : roles.filter(
        ({ role, districtOrg = false }) =>
          !isAnyDistrict(role) || (isAnyDistrict(role) && !districtOrg)
      )

  return (
    <div className={alignSchoolMenuRight ? styles['align-menu-right'] : styles['align-menu-left']}>
      <SelectOrgDropdown
        alignDropdownRight={alignSchoolMenuRight}
        roles={rolesToShow}
        selectedOrgId={selectedOrgId}
        action={role => {
          const { orgId } = role
          const { openModal, unsavedForm } = props
          const switchRoles = () => {
            const { setSelectedRole, onRoleChange, clearSelectedEducationPeriod } = props
            clearSelectedEducationPeriod()
            setSelectedRole(orgId)
            clearFilters()
            onRoleChange && onRoleChange(orgId)
          }

          if (!orgId) return

          if (unsavedForm)
            openModal('ConfirmUnsavedChangesModal', {
              form: unsavedForm,
              callback: switchRoles.bind(this, orgId),
              saveButtonText: 'Save & Continue',
              onSuccessAction: switchRoles.bind(this, orgId),
            })
          else switchRoles()
        }}
      />
    </div>
  )
}

export default connect(mapStateToProps, {
  setSelectedRole,
  openModal,
  clearSelectedEducationPeriod,
})(SelectSchool)
