import RenderExamComponent from './RenderExamComponent'
import { openModal } from '../../actions/app'
import {
  PACKING_LIST_COMPONENT_MAPPING,
  PACKING_LIST_CTA_MAPPING,
  PACKING_LIST_LABEL_MAPPING,
  EXAM_MATERIALS,
  CHANGE_PACKING_LIST_ITEMS_MODAL,
} from '../../constants/PackingListConstants'

/**
 * Handle exam materials returned.
 */
const ExamMaterialsReturned = ({
  id,
  headerTitle,
  materials,
  shippedQuantity,
  packingListItemId,
  unused,
  readOnly,
  openModal,
}) => {
  const editButtonRef = useRef(null)
  const filterMaterials = materials => {
    return Object.keys(materials).filter(key => EXAM_MATERIALS.includes(key))
  }
  const filteredMaterials = filterMaterials(materials)

  const handleClick = e => {
    e.preventDefault()

    const formattedMaterials = filterMaterials(materials).map(key => ({
      label: PACKING_LIST_COMPONENT_MAPPING[key],
      name: key,
      quantity: materials[key],
      returnedReason: materials[`${key}Reason`] || '',
    }))

    openModal(CHANGE_PACKING_LIST_ITEMS_MODAL, {
      materials: formattedMaterials,
      shippedQuantity,
      packingListItemId,
      unused,
      headerTitle,
      modalCloseFocusElem: editButtonRef.current,
    })
  }

  return (
    <>
      <h4>{PACKING_LIST_LABEL_MAPPING[id] || ''}</h4>
      {filteredMaterials.sort().map(key => (
        <div key={key} style={{ display: 'inline-block', padding: '24px', paddingLeft: '0' }}>
          <RenderExamComponent label={PACKING_LIST_COMPONENT_MAPPING[key]} count={materials[key]} />
        </div>
      ))}
      {!readOnly && (
        <div style={{ marginBottom: '24px' }}>
          <button
            style={{ padding: '0' }}
            type="button"
            className="btn-link"
            ref={editButtonRef}
            onClick={handleClick}
          >
            {PACKING_LIST_CTA_MAPPING[id] || 'Edit '}
          </button>
        </div>
      )}
    </>
  )
}

export default connect(null, { openModal })(ExamMaterialsReturned)
