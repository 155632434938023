import { setAcceptedTermsAndConditions } from '../../actions/user'
import { suppressNav, unSuppressNav } from '../../actions/nav'
import { Error } from '../../components/common'
import GeneralTermsAndConditionsContent from '../../components/content/GeneralTermsAndConditionsContent'
import FundingTermsAndConditionsContent from '../../components/content/FundingTermsAndConditionsContent'
import { TAC_GENERAL, TAC_FUNDING } from '../../constants/UserConstants'
import { analyticsTermsAndConditionsEvent } from '../../utils/analytics'
import stylesheet from '../../assets/style/scss/terms-and-conditions.scss'

const mapStateToProps = state => {
  const { current, availableEducationPeriods } = state.settingsEducationPeriod
  const { apAdminYear, descr: academicYearDescr } = availableEducationPeriods?.[current] ?? {}
  return {
    user: state.user.data,
    apAdminYear,
    academicYearDescr,
  }
}

const ACCEPT_TAC_NAME = 'acceptTACName'
const ACCEPT_TAC_LABEL = 'I have read and accept the terms of service'

const TermsAndConditionsPage = ({
  user,
  apAdminYear,
  academicYearDescr,
  error,
  suppressNav,
  unSuppressNav,
  setAcceptedTermsAndConditions,
}) => {
  const [tacAccepted, setTacAccepted] = useState(false)
  const { isDistrictFundingAdmin, isDistrictSuperAdmin, acceptedGeneralTAC, acceptedFundingTAC } =
    user

  analyticsTermsAndConditionsEvent()

  let tacType
  let tacContent
  if (!acceptedGeneralTAC) {
    tacContent = <GeneralTermsAndConditionsContent />
    tacType = TAC_GENERAL
  } else if ((isDistrictFundingAdmin || isDistrictSuperAdmin) && !acceptedFundingTAC) {
    tacContent = (
      <FundingTermsAndConditionsContent
        adminYear={apAdminYear}
        academicYearDescr={academicYearDescr}
      />
    )
    tacType = TAC_FUNDING
  }

  useEffect(() => {
    suppressNav()
    return () => unSuppressNav()
  }, [])

  const changeTacAccepted = () => setTacAccepted(!tacAccepted)

  const acceptTAC = e => {
    e.preventDefault()
    setAcceptedTermsAndConditions(tacType)
    setTacAccepted(false)
  }

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className="container" style={{ marginTop: 20 }}>
        {error ? <Error message={error} /> : null}
        <h2 className="h1">Terms and Conditions</h2>
        {tacContent}
        <div className="row">
          <div className={`col-xs-12 ${stylesheet['tac-controls']}`}>
            <form>
              <div className="form-group">
                <div className={`checkbox ${stylesheet['tac-check']}`}>
                  <label htmlFor={ACCEPT_TAC_NAME}>
                    <input
                      type="checkbox"
                      className="cb-checkbox"
                      name={ACCEPT_TAC_NAME}
                      id={ACCEPT_TAC_NAME}
                      aria-label={ACCEPT_TAC_LABEL}
                      onChange={changeTacAccepted}
                      checked={tacAccepted}
                    />
                    <span className="cb-span" />
                    {ACCEPT_TAC_LABEL}
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className={`btn btn-sm btn-primary ${stylesheet['tac-submit']}`}
                disabled={!tacAccepted}
                aria-disabled={!tacAccepted}
                onClick={acceptTAC}
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default connect(mapStateToProps, {
  setAcceptedTermsAndConditions,
  suppressNav,
  unSuppressNav,
})(TermsAndConditionsPage)
