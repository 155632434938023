import { reduxForm, formValueSelector, FieldArray, change } from 'redux-form'
import {
  SELECT_SECURE_MATERIALS_FORM,
  REASON_FOR_SECURE_MATERIALS_FIELD,
  SECURE_MATERIAL_APPROVED,
} from '../../constants/OrderConstants'
import { Select, Text, Error, LimitedStringValidation } from '../../components/common'
import { updateSelectedSecureMaterials } from '../../actions/orders'
import { isEmpty } from '../../utils/common'
import { getExamWindowRefData } from '../../selectors/examWindows'
import { sortColumnByKey } from '../../utils/sort'

const maxUnlockedQuantity = 100

const secureMaterialOptions = ({
  allowedMaxQuantity,
  locked,
  selectedQuantity,
  quantityOrdered,
}) => {
  let opts = []
  let maxAllowed
  let selectedOverMax = false
  const startCountVal = quantityOrdered > allowedMaxQuantity ? quantityOrdered : 1
  if (locked) {
    if (selectedQuantity > allowedMaxQuantity) {
      selectedOverMax = true
    }
    maxAllowed = allowedMaxQuantity
  } else {
    maxAllowed = maxUnlockedQuantity
  }
  for (var i = startCountVal; i <= maxAllowed; i++) {
    opts.push({ label: i, value: i })
  }
  if (selectedOverMax) {
    opts.push({ label: selectedQuantity, value: selectedQuantity })
  }
  return opts
}

class renderSelectSecureMaterialQuantity extends Component {
  constructor() {
    super()
    this.validateQuantity = this.validateQuantity.bind(this)
  }

  validateQuantity(value, allValues, props, name) {
    const { secureMaterials } = this.props
    const index = parseInt(name.match(/[0-9]+/g), 10)
    const { locked, allowedMaxQuantity, quantityOrdered } = secureMaterials[index]
    let validationError = undefined
    if (value !== quantityOrdered && locked && value > allowedMaxQuantity) {
      validationError = `Error: Quantity is greater than ${allowedMaxQuantity}.`
    }
    return validationError
  }

  render() {
    const { fields, secureMaterials, selectedValues } = this.props

    return (
      <>
        {fields.map((name, index) => {
          return (
            <tr key={`material[${index}]`}>
              <th scope="row" style={{ verticalAlign: 'middle' }}>
                <label htmlFor={name}>{secureMaterials[index].productDescription}</label>
              </th>
              <td style={{ verticalAlign: 'middle', width: '102px' }}>
                <Select
                  name={name}
                  label={null}
                  isRequired={true}
                  showEmptyOption={false}
                  normalize={value => parseInt(value, 10)}
                  value={selectedValues.quantities[index]}
                  visibleLabel={selectedValues.quantities[index]}
                  options={secureMaterialOptions(secureMaterials[index])}
                  validate={[this.validateQuantity]}
                  style={{ marginBottom: 0 }}
                />
              </td>
            </tr>
          )
        })}
      </>
    )
  }
}

const SelectSecureMaterialsForm = ({
  handleSubmit,
  error,
  course: { courseName, testDayType },
  initialValues,
  secureMaterials,
  selectedValues,
  unlockedAndOverMax,
  etsApprovalStatus,
  quantitiesUnchanged,
  examWindowRefData,
}) => (
  <form onSubmit={handleSubmit}>
    {error && <Error message={error} />}
    <div className="table-responsive">
      <table className="table cb-no-table-border" style={{ marginBottom: '24px' }}>
        <caption className="sr-only">
          Review and order CDs for {courseName} ({examWindowRefData[testDayType]?.displayName})
        </caption>
        <thead>
          <tr>
            <th scope="col">CD Type</th>
            <th scope="col">Quantity</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="quantities"
            component={renderSelectSecureMaterialQuantity}
            secureMaterials={sortColumnByKey(secureMaterials, 'productDescription', 'asc')}
            initialValues={initialValues}
            selectedValues={selectedValues}
          />
        </tbody>
      </table>
    </div>
    {unlockedAndOverMax ? (
      <Text
        type="text"
        name={REASON_FOR_SECURE_MATERIALS_FIELD}
        label="Reason for Order"
        value={selectedValues[REASON_FOR_SECURE_MATERIALS_FIELD]}
        placeholder="Please provide a reason for ordering additional materials"
        maxlength="200"
        className="form-control"
        isRequired={true}
        disabled={etsApprovalStatus === SECURE_MATERIAL_APPROVED && quantitiesUnchanged}
        validation={[LimitedStringValidation]}
      />
    ) : null}
  </form>
)

const selector = formValueSelector(SELECT_SECURE_MATERIALS_FORM)

const mapStateToProps = (state, ownProps) => {
  const { course } = ownProps
  const {
    maxAllowedSecureMaterialsForCourse: { entities: maxAllowedEntities = {} },
    secureMaterialsForCourse: {
      data: {
        [REASON_FOR_SECURE_MATERIALS_FIELD]: reasonForOrderField,
        entities: selectedSecureMaterialsEntities = {},
        etsApprovalStatus,
      },
    },
  } = state
  const examWindowRefData = getExamWindowRefData(state)
  const maxAllowedItems = maxAllowedEntities.items || {}
  const initialValues = {
    quantities: [],
    [REASON_FOR_SECURE_MATERIALS_FIELD]: reasonForOrderField,
  }
  const secureMaterials = Object.keys(maxAllowedItems).map(key => {
    let output = {}
    if (
      !isEmpty(selectedSecureMaterialsEntities) &&
      !isEmpty(selectedSecureMaterialsEntities.items) &&
      Object.keys(selectedSecureMaterialsEntities.items) &&
      !isEmpty(selectedSecureMaterialsEntities.items[key])
    ) {
      let selectedQuantity = selectedSecureMaterialsEntities.items[key].quantity
      if (
        maxAllowedItems[key].locked &&
        selectedSecureMaterialsEntities.items[key].quantity >
          maxAllowedItems[key].allowedMaxQuantity &&
        selectedSecureMaterialsEntities.items[key].quantity !==
          selectedSecureMaterialsEntities.items[key].quantityOrdered
      ) {
        selectedQuantity = selectedSecureMaterialsEntities.items[key].quantityOrdered
      }

      output = {
        ...maxAllowedItems[key],
        selectedQuantity,
        quantityOrdered: selectedSecureMaterialsEntities.items[key].quantityOrdered,
      }
    } else {
      // If no selections have been made, we default to 1
      output = {
        ...maxAllowedItems[key],
        selectedQuantity: 1,
        quantityOrdered: 1,
      }
    }
    initialValues.quantities.push(output.selectedQuantity)
    return output
  })
  const quantities = selector(state, 'quantities')
  const selectedValues = {
    quantities: !isEmpty(quantities) ? quantities : initialValues.quantities,
    [REASON_FOR_SECURE_MATERIALS_FIELD]: selector(state, REASON_FOR_SECURE_MATERIALS_FIELD),
  }
  let unlockedAndOverMax = false
  for (let i = 0; i < secureMaterials.length; i++) {
    if (
      selectedValues.quantities[i] > secureMaterials[i].allowedMaxQuantity &&
      !secureMaterials[i].locked
    ) {
      unlockedAndOverMax = true
      break
    }
  }
  const quantitiesUnchanged = initialValues.quantities.every(
    (quantity, i) => quantity === selectedValues.quantities[i]
  )

  return {
    initialValues,
    course,
    selectedValues,
    secureMaterials,
    unlockedAndOverMax,
    etsApprovalStatus,
    quantitiesUnchanged,
    examWindowRefData,
  }
}

export default connect(
  mapStateToProps,
  { updateSelectedSecureMaterials, change }
)(
  reduxForm({
    form: SELECT_SECURE_MATERIALS_FORM,
    onChange: (values, dispatch, props) => {
      const { quantitiesUnchanged, etsApprovalStatus, initialValues, change } = props
      if (quantitiesUnchanged && etsApprovalStatus === SECURE_MATERIAL_APPROVED) {
        dispatch(
          change(
            REASON_FOR_SECURE_MATERIALS_FIELD,
            initialValues[REASON_FOR_SECURE_MATERIALS_FIELD]
          )
        )
      }
    },
    onSubmit: (data, dispatch, props) => {
      const submitObj = {
        reasonForOrder: !props.unlockedAndOverMax ? '' : data[REASON_FOR_SECURE_MATERIALS_FIELD],
        testCd: props.course.testCd,
        testDayType: props.course.testDayType,
        items: data.quantities.map((quantity, index) => ({
          ancillaryCd: props.secureMaterials[index].ancillaryCd,
          quantity,
        })),
      }
      return props.updateSelectedSecureMaterials(submitObj)
    },
  })(SelectSecureMaterialsForm)
)
