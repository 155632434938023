import contactStyles from '../../assets/style/scss/CustomerServiceContact.scss'

const CustomerServiceContact = () => (
  <div className={contactStyles['customer-service-contact']}>
    <strong>AP Services for Educators</strong>
    <dl>
      <dt>Phone:</dt>
      <dd>877-274-6474 (toll free in the United States and Canada)</dd>
      <dt>Phone:</dt>
      <dd>212-632-1781</dd>
      <dt>Fax:</dt>
      <dd>610-290-8979</dd>
    </dl>
    <dl>
      <dt>Email:</dt>
      <dd><a href="mailto:apexams@info.collegeboard.org">apexams@info.collegeboard.org</a></dd>
      <dt>Email:</dt>
      <dd><a href="mailto:apexams-intl@info.collegeboard.org">apexams-intl@info.collegeboard.org</a></dd>
      <dd>(for educators outside the United States)</dd>
    </dl>
    <dl>
      <dt style={{ float: 'none' }}>Call Center Hours</dt>
      <dd>M–F, 8 a.m.–8 p.m. ET</dd>
      <dd>April 20–May 29, 2020, M–F, 7 a.m.–9 p.m. ET</dd>
    </dl>
  </div>
)

export default CustomerServiceContact
