import { Route, Redirect } from 'react-router-dom'
import { useCatapultAuth } from '../../utils/hooks'
import { logoutUser } from '../../actions/user'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => ({})

const PublicRoute = connect(mapStateToProps, { logoutUser })(
  ({ component: Component, componentProps, ...props }) => {
    const {
      location: { search, pathname, from },
      logoutUser,
    } = props
    const { isAuthed } = useCatapultAuth()
    const previous = from && from !== '/' ? from : pathname

    // console.info('am in the public page', isAuthed, from, pathname)

    useEffect(() => {
      logoutUser()
    }, [])

    return isAuthed ? (
      <Redirect to={{ pathname: NAV_LINKS.redirect, from: previous, search }} />
    ) : (
      <Component {...componentProps} {...props} />
    )
  }
)

export default ({ component, componentProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => <PublicRoute component={component} componentProps={componentProps} {...rest} />}
    />
  )
}
