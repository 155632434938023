import { formatDate, formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { Error } from '../../common'
import { openModal } from '../../../actions/app'
import { resetExpireAllJoinCodes, fetchExpireJoinCodesStatus } from '../../../actions/section'
import {
  EXPIRE_JOIN_CODES_STATUS_IN_PROGRESS,
  EXPIRE_JOIN_CODES_STATUS_FAIL,
} from '../../../constants/SectionConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    expireJoinCodes: {
      fetching,
      error,
      data: { status, lastExpiredOn = null },
    },
  } = state
  return {
    orgId: selectedOrgId,
    fetching,
    error,
    status,
    lastExpiredOn,
  }
}

const ExpireStatusSection = ({ status, lastExpiredOn, error }) => {
  const failed = status === EXPIRE_JOIN_CODES_STATUS_FAIL

  if (error) {
    return (
      <div style={{ marginTop: '24px' }}>
        <Error title="Error Retrieving Data" message={error} />
      </div>
    )
  }

  if (status) {
    return (
      <div style={{ marginTop: '24px' }}>
        {lastExpiredOn && (
          <p>
            Last expired on: {formatDate(lastExpiredOn, DATETIME_FORMATS.shortMonthDayYear)} @{' '}
            {formatDateTime(lastExpiredOn, DATETIME_FORMATS.time)} ET
          </p>
        )}
        {failed && (
          <p className="cb-error-msg" aria-live="polite">
            Expiration of join codes failed, please try again!
          </p>
        )}
      </div>
    )
  }

  return null
}

const ExpireAllJoinCodes = ({
  orgId,
  fetching,
  error,
  status,
  lastExpiredOn,
  resetExpireAllJoinCodes,
  fetchExpireJoinCodesStatus,
  openModal,
}) => {
  const expireBtnRef = useRef()
  const inProgress = status === EXPIRE_JOIN_CODES_STATUS_IN_PROGRESS
  const buttonText = inProgress ? 'Expiring Join Codes' : 'Expire All Join Codes'

  useEffect(() => {
    fetchExpireJoinCodesStatus(orgId)
  }, [])

  return (
    <div style={{ marginBottom: '24px' }}>
      <p>
        Click the button below to expire all existing and generate new join codes for your school.
      </p>
      <button
        type="button"
        className="btn btn-secondary btn-sm"
        ref={expireBtnRef}
        disabled={fetching || inProgress}
        onClick={async () => {
          await resetExpireAllJoinCodes()
          openModal('ConfirmExpireAllJoinCodesModal', { modalCloseFocusElem: expireBtnRef.current })
        }}
      >
        {buttonText}
      </button>
      <ExpireStatusSection status={status} lastExpiredOn={lastExpiredOn} error={error} />
    </div>
  )
}

export default connect(mapStateToProps, {
  resetExpireAllJoinCodes,
  fetchExpireJoinCodesStatus,
  openModal,
})(ExpireAllJoinCodes)
