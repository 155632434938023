import axios, { getServerErrorMessage } from '../utils/axios'

const request = async req => {
  try {
    const { data } = await req()
    return { data }
  } catch (err) {
    return { error: getServerErrorMessage(err) }
  }
}

const enrollmentUrl = (orgId, educationPeriod, suffix) =>
  `${Config.API_URL}/enrollments/organizations/${orgId}/education-period-cd/${educationPeriod}/${suffix}`
const orderUrl = (orgId, educationPeriod, suffix) =>
  `${Config.API_URL}/orders/organizations/${orgId}/education-periods/${educationPeriod}/${suffix}`

export const fetchOrdersAfterDeadlineCourses = async (orgId, educationPeriod) => {
  const response = await request(() =>
    axios.get(enrollmentUrl(orgId, educationPeriod, 'exam-ordering-lock-status'))
  )
  return response
}

export const saveOrdersAfterDeadlineCourses = async (updatedCourses, orgId, educationPeriod) => {
  const response = await request(() =>
    axios.put(enrollmentUrl(orgId, educationPeriod, 'exam-ordering-lock-status'), updatedCourses)
  )
  return response
}

export const fetchExceptionTestingCourses = async (orgId, educationPeriod, adminWindow) => {
  const response = await request(() =>
    axios.get(
      enrollmentUrl(orgId, educationPeriod, `exception-ordering-lock-status/${adminWindow}`)
    )
  )
  return response
}

export const saveExceptionTestingCourses = async (
  updatedCourses,
  orgId,
  educationPeriod,
  adminWindow
) => {
  const response = await request(() =>
    axios.put(
      enrollmentUrl(orgId, educationPeriod, `exception-ordering-lock-status/${adminWindow}`),
      updatedCourses
    )
  )
  return response
}

export const fetchSecureAncillaryMaterials = async (orgId, educationPeriod) => {
  const response = await request(() =>
    axios.get(orderUrl(orgId, educationPeriod, 'ancillary-material/secure/lock-status'))
  )
  return response
}

export const saveSecureAncillaryMaterials = async (updatedMaterials, orgId, educationPeriod) => {
  const response = await request(() =>
    axios.put(
      orderUrl(orgId, educationPeriod, 'ancillary-material/secure/lock-status'),
      updatedMaterials
    )
  )
  return response
}

export const fetchNonSecureAncillaryMaterials = async (orgId, educationPeriod) => {
  const response = await request(() =>
    axios.get(orderUrl(orgId, educationPeriod, 'ancillary-material/non-secure/lock-status'))
  )
  return response
}

export const saveNonSecureAncillaryMaterials = async (updatedMaterials, orgId, educationPeriod) => {
  const response = await request(() =>
    axios.put(
      orderUrl(orgId, educationPeriod, 'ancillary-material/non-secure/lock-status'),
      updatedMaterials
    )
  )
  return response
}

export const fetchFeeRemovals = async (orgId, educationPeriod, feeType) => {
  const response = await request(() =>
    axios.get(orderUrl(orgId, educationPeriod, 'fee-removals/browse'), {
      params: { 'fee-type': feeType },
    })
  )
  return response
}

export const saveFeeRemovals = async (orgId, educationPeriod, feeRemovalObj) => {
  const response = await request(() =>
    axios.put(orderUrl(orgId, educationPeriod, 'fee-removals'), feeRemovalObj)
  )
  return response
}

export const fetchStudentsBySearch = async ({ orgId, educationPeriod, searchTerm, params }) => {
  const response = await request(() =>
    axios.get(
      `${Config.API_URL}/enrollments/organizations/${orgId}/education-periods/${educationPeriod}/students/${searchTerm}`,
      { params }
    )
  )
  return response
}

export const fetchStudentProfile = async ({ studentId }) => {
  const response = await request(() =>
    axios.get(`${Config.API_URL}/students/profile`, {
      params: { idType: 'APID', id: studentId },
    })
  )
  return response
}

export const saveFeeStatus = async (orgId, educationPeriod, feeStatusObj) => {
  const response = await request(() =>
    axios.put(
      `${Config.API_URL}/enrollments/organizations/${orgId}/education-periods/${educationPeriod}/fee-status`,
      feeStatusObj
    )
  )
  return response
}

export const updateAttendingSchool = async (orgId, educationPeriod, studentIds, newOrgId) => {
  const response = await request(() =>
    axios.put(
      `${Config.API_URL}/students/registrations/education-periods/${educationPeriod}/attending-organization`,
      { attendingOrgId: newOrgId, studentIds }
    )
  )
  return response
}

export const saveStudentProfileData = async ({ profileData }) => {
  const response = await request(() =>
    axios.put(`${Config.API_URL}/students/profile`, { ...profileData })
  )
  return response
}

export const saveChangeGradeLevel = async (orgId, educationPeriod, students) => {
  const response = await request(() =>
    axios.put(
      `${Config.API_URL}/students/registrations/education-periods/${educationPeriod}/grade`,
      students
    )
  )
  return response
}

const augmentScoreReportingServicesResponse = data => {
  try {
    // Response data should only contain a single item and we're going to augment
    // that here with an id and description that will be displayed in CSR Tools
    return {
      data: [
        (data[0] = {
          ...data[0],
          itemId: 'srs',
          description: 'Score Reporting Services',
        }),
      ],
    }
  } catch (err) {
    throw new Error('Score Reporting Lock Status should contain a single item', err)
  }
}

export const fetchScoreReportingServicesLockStatus = async (orgId, educationPeriod) => {
  try {
    const response = await request(() =>
      axios.get(orderUrl(orgId, educationPeriod, 'score-reporting/lock-status'))
    )
    return augmentScoreReportingServicesResponse(response.data)
  } catch (err) {
    throw new Error('CSR Tool - Score Reporting Services fetch', err)
  }
}

export const saveScoreReportingServicesLockStatus = async (
  scoreReportingLockStatus,
  orgId,
  educationPeriod
) => {
  try {
    const response = await request(() =>
      axios.put(
        orderUrl(orgId, educationPeriod, 'score-reporting/lock-status'),
        scoreReportingLockStatus
      )
    )
    return augmentScoreReportingServicesResponse(response.data)
  } catch (err) {
    throw new Error('CSR Tool - Score Reporting Services save', err)
  }
}

const augmentLargeVolumeRebatesResponse = data => {
  try {
    // Response data should only contain a single item and we're going to augment
    // that here with an id and description that will be displayed in CSR Tools
    return {
      data: [
        (data[0] = {
          ...data[0],
          itemId: 'lvr',
          description: 'Unlock Large Volume Rebates',
        }),
      ],
    }
  } catch (err) {
    throw new Error('Large Volume Rebates Lock Status should contain a single item', err)
  }
}

export const fetchLargeVolumeRebatesLockStatus = async (orgId, educationPeriod) => {
  try {
    const response = await request(() =>
      axios.get(orderUrl(orgId, educationPeriod, 'large-volume-rebate/lock-status'))
    )
    return augmentLargeVolumeRebatesResponse(response.data)
  } catch (err) {
    throw new Error('CSR Tool - Large Volume Rebates fetch', err)
  }
}

export const saveLargeVolumeRebatesLockStatus = async (
  largeVolumeRebatesLockStatus,
  orgId,
  educationPeriod
) => {
  try {
    const response = await request(() =>
      axios.put(
        orderUrl(orgId, educationPeriod, 'large-volume-rebate/lock-status'),
        largeVolumeRebatesLockStatus
      )
    )
    return augmentLargeVolumeRebatesResponse(response.data)
  } catch (err) {
    throw new Error('CSR Tool - Large Volume Rebates save', err)
  }
}

export const fetchExamIntentCourses = async (orgId, educationPeriod) => {
  const response = await request(() =>
    axios.get(enrollmentUrl(orgId, educationPeriod, 'exam-intent-lock-status'))
  )
  return response
}

export const saveExamIntentCourses = async (updatedCourses, orgId, educationPeriod) => {
  const response = await request(() =>
    axios.put(enrollmentUrl(orgId, educationPeriod, 'exam-intent-lock-status'), updatedCourses)
  )
  return response
}
