import { hasSubmitSucceeded, submit, isSubmitting, isDirty } from 'redux-form'
import { useHistory } from 'react-router-dom'
import StudentLabelSortByForm from '../../containers/forms/StudentLabelSortByForm'
import {
  STUDENT_LABEL_SORT_ORDER_SELECT,
  STUDENT_LABEL_SORT_ORDER_FORM,
} from '../../constants/OrderConstants'
import { SETTINGS_PANELS } from '../../constants/SettingsConstants'
import { Modal, Error } from '../../components/common'
import { NAV_LINKS } from '../../constants/NavConstants'

const SHIPPING_MINIMUM = 150
const FORM = `${STUDENT_LABEL_SORT_ORDER_FORM}Modal`

const mapStateToProps = (state, ownProps) => {
  const {
    ordersStudentLabels: { error, labelSortOrder },
    ordersSummary: { mainOrderSummary: m, hasPendingApprovalExams = false },
  } = state

  return {
    showShippingSettings: parseInt((m && m.numberExams) || 0) >= SHIPPING_MINIMUM,
    hasPendingApprovalExams,
    error,
    success: hasSubmitSucceeded(FORM)(state),
    submitting: isSubmitting(FORM)(state),
    dirty: isDirty(FORM)(state),
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ submit }, dispatch)

const ShippingSettingsSection = () => (
  <div style={{ marginBottom: '1.5em' }}>
    <h5 className="roboto-bold">Return Shipping Settings</h5>
    <p>
      Since you are ordering {SHIPPING_MINIMUM} or more AP Exams, your school will be automatically
      enrolled in and required to use the split shipment program. All All of the first week’s AP
      Exam materials should be returned at the beginning of the second week of testing. You will
      receive split shipment materials and instructions in the spring, and you must return exam
      materials following the split shipment procedures to avoid potential score delays.
    </p>
  </div>
)

const StudentLabelsSection = () => (
  <div>
    <h5 className="roboto-bold">Student Labels</h5>
    <p>
      Select how student exam labels will be sorted in your shipment. If needed, you can adjust your
      selection by going to the Student Labels section of the Orders page.
    </p>
    <label className="sr-only" htmlFor={STUDENT_LABEL_SORT_ORDER_SELECT}>
      Sort by
    </label>
    <div className="row">
      <div className="col-xs-5">
        <StudentLabelSortByForm showSelectOnly={true} form={FORM} />
      </div>
    </div>
  </div>
)

const OrderSubmittedModal = ({
  isMainOrder,
  hasPendingApprovalExams,
  showShippingSettings,
  submitting,
  success,
  error,
  dirty,
  redirectOnSuccess,
  submit,
}) => {
  const history = useHistory()
  const [shouldCloseModal, setShouldCloseModal] = useState(false)

  useEffect(() => {
    if (redirectOnSuccess && !isMainOrder) {
      history.push(NAV_LINKS.orders)
    }
  }, [success])

  const submitNewStudentLabelSortOrderOrRedirect = () => {
    if (dirty) {
      submit(FORM)
    } else setShouldCloseModal(true)
  }

  const footerActions = () => [
    {
      buttonLabel: 'Continue',
      isPrimary: true,
      isDismissable: !isMainOrder,
      isDisabled: submitting,
      onClick: submitNewStudentLabelSortOrderOrRedirect,
    },
  ]

  const goFromModal = (e, destination) => {
    e.preventDefault()
    history.push(destination)
    setShouldCloseModal(true)
  }

  return (
    <Modal
      hideCloseButton={true}
      shouldCloseModal={success || shouldCloseModal}
      headerTitle={isMainOrder ? 'Order Submitted' : 'Order Changes Submitted'}
      footerActions={footerActions()}
    >
      {error ? <Error title="Error" message={error} /> : null}
      {isMainOrder ? (
        <div>
          <p>
            Your order has been submitted. We&#39;ll notify you when the materials are processed for
            shipping.
          </p>
          {showShippingSettings ? <ShippingSettingsSection /> : null}
          <StudentLabelsSection />
        </div>
      ) : (
        <p>Your order changes have been submitted.</p>
      )}
      <p>
        If your institution requires a purchase order for AP exams, please update your purchase
        order number under the{' '}
        <a
          href={`${NAV_LINKS.settings}?scroll=${SETTINGS_PANELS.partForm}`}
          onClick={e => goFromModal(e, `${NAV_LINKS.settings}?scroll=${SETTINGS_PANELS.partForm}`)}
        >
          School Information and Participation Contacts
        </a>{' '}
        section of the settings page.
      </p>
      {hasPendingApprovalExams ? (
        <p>
          <strong>NOTE:</strong> There are exams and/or materials in your order that require
          approval. You can review these on the{' '}
          <a
            href={NAV_LINKS.orderapprovalreqs}
            onClick={e => goFromModal(e, NAV_LINKS.orderapprovalreqs)}
          >
            Approval Requests
          </a>{' '}
          page.
        </p>
      ) : null}
    </Modal>
  )
}

export default connect(mapStateToProps, { submit })(OrderSubmittedModal)
