import { fetchStudents, resetStudentData } from '../../actions/studentsByOrg'
import { addFetchAction, removeFetchAction } from '../../actions/app'
import { CoordinatorStudents } from '../../components/students'
import { isFeatureDisabled } from '../../selectors/features'

const mapStateToProps = state => {
  const {
    status: { fetched },
    user: {
      data: { isLevelTwo },
    },
    settingsExamWindows: { fetched: examWindowsFetched },
  } = state
  return {
    examWindowsFetched,
    statusLoaded: fetched || !isLevelTwo,
    statusPanelDisabled: isFeatureDisabled(state, 'STATUS_PANEL'),
  }
}

const StudentsPage = ({
  fetchStudents,
  examWindowsFetched,
  resetStudentData,
  addFetchAction,
  removeFetchAction,
  statusLoaded,
  statusPanelDisabled,
}) => {
  useEffect(() => {
    if (examWindowsFetched) {
      addFetchAction({
        name: 'fetchStudents',
        action: (orgId, educationPeriodCd) => fetchStudents(orgId, educationPeriodCd),
      })
      return () => removeFetchAction('fetchStudents')
    }
  }, [examWindowsFetched])

  useEffect(() => {
    return () => resetStudentData()
  }, [])

  if (statusLoaded || statusPanelDisabled)
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <CoordinatorStudents />
      </CSSTransition>
    )

  return null
}

export default connect(mapStateToProps, {
  fetchStudents,
  resetStudentData,
  addFetchAction,
  removeFetchAction,
})(StudentsPage)
