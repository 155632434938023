const TestDateIcon = ({ type, id, showLateReason }) => {
  const styles = {
    //position: 'relative',
    //top: showLateReason ? '5px' : '13px',
    //left: '5px',
    fontSize: '1em',
    marginRight: 5,
  }

  switch (type) {
    case 'C':
      return (
        <i className="cb-glyph cb-bell" style={styles}>
          <span className="sr-only" id={`conflict${id}`} tabIndex="-1">
            Conflict
          </span>
        </i>
      )
    case 'H':
      return (
        <i className="cb-glyph cb-home" style={styles}>
          <span className="sr-only" id={`location${id}`} tabIndex="-1">
            Testing at Home
          </span>
        </i>
      )
    default:
      return null
  }
}

export default TestDateIcon
