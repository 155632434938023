import { reduxForm, formValueSelector, FieldArray, change, SubmissionError } from 'redux-form'
import { Select, Text } from '../../components/common'
import { updatePackingListItems } from '../../actions/packingList'
import {
  CHANGE_PACKING_LIST_ITEMS_FORM,
  REASON_FOR_DISCREPANCY_OPTIONS,
  EXAM_MATERIALS,
  OTHER_MATERIALS,
  OTHER_MATERIAL_RETURN_MAPPING,
  ANSWER_SHEETS_RETURNED,
} from '../../constants/PackingListConstants'
import packingStyles from '../../assets/style/scss/packing-details.scss'

class RenderMaterials extends Component {
  constructor(props) {
    super(props)
    this.onQuantityChange = this.onQuantityChange.bind(this)
  }

  onQuantityChange(index) {
    const { selectedValues, shippedQuantity, unused, change } = this.props

    // Convert selected quantity into integer
    if (!Number.isNaN(parseInt(selectedValues.materials[index].quantity, 10))) {
      change(
        CHANGE_PACKING_LIST_ITEMS_FORM,
        `materials[${index}].quantity`,
        parseInt(selectedValues.materials[index].quantity, 10)
      )
    }

    // if quantity is set back to original shipped quantity, then reset reason to null
    if (selectedValues.materials[index].name === ANSWER_SHEETS_RETURNED) {
      if (
        parseInt(selectedValues.materials[index].quantity, 10) === shippedQuantity - unused &&
        selectedValues.materials[index].returnedReason !== null
      ) {
        change(CHANGE_PACKING_LIST_ITEMS_FORM, `materials[${index}].returnedReason`, '')
      }
    } else if (
      parseInt(selectedValues.materials[index].quantity, 10) === shippedQuantity &&
      selectedValues.materials[index].returnedReason !== null
    ) {
      change(CHANGE_PACKING_LIST_ITEMS_FORM, `materials[${index}].returnedReason`, '')
    }
  }

  isNotDisabled(materialName, selectedQuantity) {
    const { shippedQuantity, unused } = this.props

    if (materialName === ANSWER_SHEETS_RETURNED) {
      if (selectedQuantity === shippedQuantity - unused) {
        return true
      }
    } else if (selectedQuantity === shippedQuantity) {
      return true
    }
    return false
  }

  render() {
    const { fields, selectedValues, shippedQuantity } = this.props

    return (
      <>
        {fields.map((materials, index) => {
          return (
            <tr key={selectedValues.materials[index].name}>
              <th scope="row" style={{ verticalAlign: 'middle' }}>
                <label htmlFor={`materials[${index}].quantity`}>
                  {selectedValues.materials[index].label}
                </label>
              </th>
              <td style={{ verticalAlign: 'middle', width: '90px' }}>
                <Text
                  name={`materials[${index}].quantity`}
                  value={selectedValues.materials[index].quantity}
                  maxlength={5}
                  placeholder="Enter quantity"
                  onChange={this.onQuantityChange(index)}
                  formGroupStyle={{ marginBottom: 0 }}
                />
              </td>
              <td style={{ verticalAlign: 'middle', width: '210px' }}>
                <Select
                  name={`materials[${index}].returnedReason`}
                  label={null}
                  isRequired={false}
                  showEmptyOption={true}
                  value={selectedValues.materials[index].returnedReason}
                  visibleLabel={selectedValues.materials[index].returnedReason}
                  options={REASON_FOR_DISCREPANCY_OPTIONS}
                  disabled={this.isNotDisabled(
                    selectedValues.materials[index].name,
                    parseInt(selectedValues.materials[index].quantity, 10)
                  )}
                  style={{ marginBottom: 0 }}
                />
              </td>
            </tr>
          )
        })}
      </>
    )
  }
}
const ConnectedRenderMaterials = connect(null, { change })(RenderMaterials)

const PackingListQuantityDiscrepanciesForm = ({
  handleSubmit,
  error,
  materials,
  shippedQuantity,
  selectedValues,
  unused,
}) => (
  <form onSubmit={handleSubmit}>
    {error && (
      <p className="cb-error-msg" role="alert" aria-live="polite">
        {error}
      </p>
    )}
    <div className={`table-responsive ${packingStyles['inlined-controls']}`}>
      <table className="table cb-no-table-border" style={{ marginBottom: '24px' }}>
        <caption className="sr-only">Update material quantity discrepancies</caption>
        <thead>
          <tr>
            <th scope="col">Material</th>
            <th scope="col">Quantity</th>
            <th scope="col">Reason for Discrepancy</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="materials"
            materials={materials}
            component={ConnectedRenderMaterials}
            selectedValues={selectedValues}
            shippedQuantity={shippedQuantity}
            unused={unused}
          />
        </tbody>
      </table>
    </div>
  </form>
)

const selector = formValueSelector(CHANGE_PACKING_LIST_ITEMS_FORM)

const mapStateToProps = (state, ownProps) => {
  const { materials, shippedQuantity, packingListItemId, unused } = ownProps
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    packingListSummary: { packingShipmentsId },
    packingListDetails,
  } = state
  const selectedMaterials = selector(state, 'materials')
  let selectedValues = {}
  if (selectedMaterials) selectedValues = { materials: selectedMaterials }
  else selectedValues = { materials }

  return {
    orgId,
    educationPeriodCd,
    materials,
    shippedQuantity,
    initialValues: { materials },
    selectedValues,
    packingShipmentsId,
    packingListDetails,
    packingListItemId,
    unused,
  }
}

export default connect(mapStateToProps, { updatePackingListItems, change })(
  reduxForm({
    form: CHANGE_PACKING_LIST_ITEMS_FORM,
    onChange: (values, dispatch, props) => {},
    validate: (values, props) => {
      const errors = {}

      // Check for valid numerical values on all quantity fields
      for (var i = 0; i < values.materials.length; i++) {
        if (
          Number.isNaN(parseInt(values.materials[i].quantity, 10)) ||
          parseInt(values.materials[i].quantity, 10) < 0
        ) {
          errors._error = `Error: ${values.materials[i].label} quantity must be a valid positive number.`
        }
      }
      return errors
    },
    onSubmit: (values, dispatch, props) => {
      const {
        orgId,
        educationPeriodCd,
        shippedQuantity,
        packingShipmentsId,
        packingListDetails,
        packingListItemId,
      } = props

      const noReasonError = () => {
        throw new SubmissionError({
          _error: 'A reason for the discrepancy must be selected for any changed quantities',
        })
      }

      // Validate a reason is selected for all materials with changed quantities
      for (var i = 0; i < values.materials.length; i++) {
        if (values.materials[i].name === ANSWER_SHEETS_RETURNED) {
          if (
            values.materials[i].quantity !== shippedQuantity - props.unused &&
            (values.materials[i].returnedReason === null ||
              values.materials[i].returnedReason === '')
          ) {
            noReasonError()
          }
        } else if (
          values.materials[i].quantity !== shippedQuantity &&
          (values.materials[i].returnedReason === null || values.materials[i].returnedReason === '')
        ) {
          noReasonError()
        }
      }

      const submitObj = {}
      values.materials.forEach(material => {
        if (EXAM_MATERIALS.includes(material.name)) {
          submitObj[material.name] = material.quantity
          submitObj[`${material.name}Reason`] = material.returnedReason
        } else if (OTHER_MATERIALS.includes(material.name)) {
          submitObj[OTHER_MATERIAL_RETURN_MAPPING[material.name]] = material.quantity
          submitObj[`${OTHER_MATERIAL_RETURN_MAPPING[material.name]}Reason`] =
            material.returnedReason
        }
      })
      return props.updatePackingListItems({
        orgId,
        educationPeriodCd,
        packingListDetails,
        packingListItemId,
        payload: submitObj,
      })
    },
  })(PackingListQuantityDiscrepanciesForm)
)
