import { APPROVAL_REQUEST_FILTERS } from '../../../constants/OrderConstants'
import { setApprovalRequestsFilter } from '../../../actions/orders'
import { getCurrentApprovalRequestsFilter } from '../../../selectors/order'
import { BasicSelect, Label } from '../../common'

const mapStateToProps = state => ({ selectedFilter: getCurrentApprovalRequestsFilter(state) })

const filterOptions = Object.keys(APPROVAL_REQUEST_FILTERS).map(key => APPROVAL_REQUEST_FILTERS[key])

class FilterApprovalRequestsControl extends Component {
  handleChangeFilter(e) {
    const { setApprovalRequestsFilter } = this.props

    setApprovalRequestsFilter(e.target.value)
  }

  render() {
    const { selectedFilter } = this.props

    return (
      <div
        className="cb-md-pull-right cb-lg-pull-right"
        style={{ display: 'inline-block', width: '237px' }}
      >
        <Label
          name="statusFilterSelect"
          className=""
          style={{ display: 'inline-block', width: '42px', marginRight: '10px' }}
          label="Show"
        />
        <BasicSelect
          style={{ display: 'inline-block', width: '185px', marginBottom: '13px' }}
          srOnlyLabel={true}
          showEmptyOption={false}
          input={{
            name: "statusFilterSelect",
            value: selectedFilter,
            onChange: this.handleChangeFilter.bind(this)
          }}
          options={filterOptions}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, { setApprovalRequestsFilter })(FilterApprovalRequestsControl)
