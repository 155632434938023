import ShippingAddress from '../common/ShippingAddress'
import Suborders from './Suborders'
import shipmentStyle from '../../assets/style/scss/shipments.scss'

const mapStateToProps = state => {
  const {
    shipments: { splitShipment = false },
  } = state
  return {
    splitShipment,
  }
}

const Shipments = ({ splitShipment }) => (
  <div className="container">
    <h2>Shipments</h2>
    <div className="row">
      <div id={`${shipmentStyle.shipmentAccordionContainer}`} className="col-sm-8 col-xs-12">
        <Suborders />
      </div>
      <div className="col-sm-4 col-xs-12">
        <div id={`${shipmentStyle.rightShipment}`}>
          <div className={`${shipmentStyle.rightShipmentColumn}`}>
            <h2>Shipping Address</h2>
            <ShippingAddress />
          </div>
          <div className={`${shipmentStyle.rightShipmentColumn}`}>
            <h2>Return Shipping</h2>
            <p>
              Once you are ready to send back your exams, go to the Packing List page to make sure
              your materials are properly accounted for.
            </p>
            {splitShipment && (
              <>
                <p style={{ marginBottom: '5px' }}>
                  <strong>NOTE: Split Shipment Participant</strong>
                </p>
                <p>
                  Due to the volume of your exam order, you&#39;ll need to return exams using the
                  split shipment program. Instructions will be sent to you in the spring.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default connect(mapStateToProps)(Shipments)
