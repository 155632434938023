import { SubmissionError } from 'redux-form'
import { iam } from '@myap/ui-library/esm/profile'
import * as c from '../constants/SettingsConstants'
import { TEACHER_GRANT_ACCESS_SEARCH_ERROR } from '../constants/MessageConstants'
import axios, { getServerErrorMessage } from '../utils/axios'

const { getJWTToken, getAuthSession } = iam()

export const searchForTeacher =
  ({ email }) =>
  async dispatch => {
    dispatch({ type: c.SEARCH_FOR_TEACHER_PENDING })
    try {
      const { data } = await axios.get(`${Config.API_URL}/v1/teacher/search`, { params: { email } })
      dispatch({ type: c.SEARCH_FOR_TEACHER_FULFILLED, payload: data })
    } catch (err) {
      if (err?.message?.includes('404')) {
        dispatch({
          type: c.SEARCH_FOR_TEACHER_REJECTED,
          payload: TEACHER_GRANT_ACCESS_SEARCH_ERROR,
        })
        throw new SubmissionError({ _error: TEACHER_GRANT_ACCESS_SEARCH_ERROR })
      } else {
        dispatch({
          type: c.SEARCH_FOR_TEACHER_REJECTED,
          payload: getServerErrorMessage(err),
        })
        throw new SubmissionError({ _error: getServerErrorMessage(err) })
      }
    }
  }

export const resetSearchForTeacher = () => dispatch => {
  dispatch({ type: c.RESET_SEARCH_FOR_TEACHER })
  dispatch({ type: c.RESET_GRANT_TEACHER_ACCESS_TO_COURSE })
}

export const grantTeacherAccessToCourse =
  ({
    dispatchType = 'GRANT_TEACHER_ACCESS_TO_COURSE',
    proId,
    testCd,
    orgId,
    educationPeriodCd,
    onlineInd = false,
    certOverrideInd,
  }) =>
  async dispatch => {
    try {
      dispatch({ type: `${dispatchType}_PENDING` })
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      const { data } = await axios.post(
        `${Config.API_GATE_URL}/course-auth/professionals/${proId}/courses/grant-access`,
        { educationPeriodCd, orgId, onlineInd, testCd },
        config
      )
      dispatch({
        type: `${dispatchType}_FULFILLED`,
        payload: { ...data, ...(certOverrideInd ? { certOverrideInd } : {}) },
      })
    } catch (err) {
      dispatch({
        type: `${dispatchType}_REJECTED`,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const fetchTeachersGrantedAccess = (orgId, educationPeriodCd) => async dispatch => {
  try {
    dispatch({ type: c.TEACHERS_GRANTED_ACCESS_PENDING })
    const { data } = await axios.get(
      `${Config.API_URL}/course-auth/organizations/${orgId}/grant-access/teachers-granted-access`,
      {
        params: { educationPeriodCd },
      }
    )
    dispatch({
      type: c.TEACHERS_GRANTED_ACCESS_FULFILLED,
      payload: {
        allowedToGrantAccess: data?.allowedToGrantAccess,
        aproGrantedCourses: data?.aproGrantedCourses ?? [],
        apcaGrantedCourses: data?.apcaGrantedCourses ?? [],
      },
    })
  } catch (err) {
    dispatch({
      type: c.TEACHERS_GRANTED_ACCESS_REJECTED,
      payload: getServerErrorMessage(err),
    })
  }
}

export const removeTeacherWithGrantedAccess =
  ({
    dispatchType = 'REMOVE_TEACHER_GRANTED_ACCESS',
    proId,
    orgId,
    educationPeriodCd,
    testCd,
    onlineInd,
    certOverrideInd,
  }) =>
  async dispatch => {
    try {
      dispatch({ type: `${dispatchType}_PENDING` })
      const config = {
        headers: {
          'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
          'X-CB-Catapult-Authorization-Token': getJWTToken(),
        },
      }
      await axios.post(
        `${Config.API_GATE_URL}/course-auth/professionals/${proId}/courses/revoke-access`,
        { educationPeriodCd, orgId, onlineInd, testCd },
        config
      )
      dispatch({
        type: `${dispatchType}_FULFILLED`,
        payload: { proId, testCd, ...(certOverrideInd ? { certOverrideInd } : {}) },
      })
    } catch (err) {
      dispatch({
        type: `${dispatchType}_REJECTED`,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const toggleTeacherAuthorization =
  ({ proId, orgId, educationPeriodCd, testCd, onlineInd = false, certOverrideInd }) =>
  dispatch => {
    // if teacher is currently not authorized, toggle to authorized
    if (!certOverrideInd) {
      dispatch(
        grantTeacherAccessToCourse({
          dispatchType: 'TOGGLE_AUTHORIZED_TEACHERS_ACCESS',
          proId,
          orgId,
          educationPeriodCd,
          testCd,
          onlineInd,
          certOverrideInd,
        })
      )
    } else {
      // teacher is currently authorized, toggle to unauthorized
      dispatch(
        removeTeacherWithGrantedAccess({
          dispatchType: 'TOGGLE_AUTHORIZED_TEACHERS_ACCESS',
          proId,
          orgId,
          educationPeriodCd,
          testCd,
          onlineInd,
          certOverrideInd,
        })
      )
    }
  }

export const fetchAllowedToGrantAccess =
  ({ orgId }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_ALLOWED_TO_GRANT_ACCESS_PENDING })
    const config = {
      headers: {
        'X-CB-Catapult-Authentication-Token': getAuthSession().sessionId,
        'X-CB-Catapult-Authorization-Token': getJWTToken(),
      },
    }
    try {
      const { data } = await axios.get(
        `${Config.API_GATE_URL}/course-auth/organizations/${orgId}/courses/grant-access`,
        config
      )
      dispatch({
        type: c.FETCH_ALLOWED_TO_GRANT_ACCESS_FULFILLED,
        payload: data?.active ?? false,
      })
    } catch (err) {
      dispatch({
        type: c.FETCH_ALLOWED_TO_GRANT_ACCESS_REJECTED,
        payload: getServerErrorMessage(err),
      })
    }
  }

export const resetGrantRevokeTeacherAccess = () => dispatch =>
  dispatch({ type: c.RESET_GRANT_REVOKE_TEACHER_ACCESS })
export const resetEnableDisableTeacherAuthorization = () => dispatch =>
  dispatch({ type: c.RESET_ENABLE_DISABLE_TEACHER_AUTHORIZATION })
