const iconStyles = {
	display: 'inline-block',
  color: 'rgb(0, 119, 200)',
  fontSize: '8px'
}

export default ({ srText }) => (
	<span style={{ display: 'inline-block' }}>
		<i className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question" style={iconStyles} aria-hidden={!srText} />
		{srText ? <span className="sr-only">{srText}</span> : null }
	</span>
)
