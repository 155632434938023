import { openModal } from '../../../actions/app'
import { buildSSDMaterials, anySSDMaterialsSelected } from '../../../selectors/section'
import { isEditAllowed } from '../../../selectors/student'
import styles from '../../../assets/style/scss/student-details.scss'

const mapStateToProps = state => ({ ...state.settingsSSDMaterials })

const EditButton = ({ exam: { ssdMaterials, examId }, modalAction }) => {
  const buttonRef = useRef(null)

  return (
    <button
      type="button"
      className={`btn-link ${styles['order-ssd']}`}
      style={{ padding: '1px 0', border: 0 }}
      ref={buttonRef}
      onClick={e =>
        modalAction('OrderSSDMaterialsModal', { examId, modalCloseFocusElem: buttonRef.current })
      }
    >
      {anySSDMaterialsSelected(ssdMaterials) ? (
        <span>edit</span>
      ) : (
        <span>Order Special Exam Materials</span>
      )}
    </button>
  )
}

const EditMaterialsLink = ({ exam, modalAction }) => {
  if (isEditAllowed(exam)) {
    return (
      <span style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
        {' '}
        (<EditButton exam={exam} modalAction={modalAction} />)
      </span>
    )
  }

  return null
}

const ListOfSelectedSSDMaterials = ({ exam, metaData, openModal }) => {
  const { ssdMaterials } = exam

  if (anySSDMaterialsSelected(ssdMaterials)) {
    return (
      <p className={styles['selected-ssd-materials']}>
        {buildSSDMaterials(metaData, ssdMaterials)}
        <EditMaterialsLink exam={exam} modalAction={openModal} />
      </p>
    )
  }

  return <EditButton exam={exam} modalAction={openModal} />
}

export default connect(
  mapStateToProps,
  { openModal }
)(ListOfSelectedSSDMaterials)
