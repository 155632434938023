import { Redirect } from 'react-router-dom'
import { ROLE_MAP } from '@myap/metadata'
import { selectRole } from '../../actions/user'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { roleTypes },
    },
  } = state

  return {
    roleTypes,
  }
}

const SelectRolePage = ({ roleTypes, selectRole, location }) => {
  if (roleTypes.length > 1) {
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <div className="container" style={{ marginTop: '50px' }}>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
              <div className="panel">
                <div className="panel-heading">
                  <div className="panel-title">
                    <h4>
                      You have multiple active user roles for this application. Please select the
                      role you want to log in as below. In order to switch roles, you will need to
                      log out of the application.
                    </h4>
                  </div>
                </div>
                <div className="panel-body">
                  <form>
                    {roleTypes.map((role, i) => (
                      <div key={role} className="cb-margin-bottom-16">
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary"
                          style={{ width: '100%' }}
                          onClick={() => selectRole(role)}
                        >
                          {ROLE_MAP[role]}
                        </button>
                      </div>
                    ))}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  } else {
    const { search } = location
    return <Redirect to={{ pathname: NAV_LINKS.redirect, search, from: NAV_LINKS.selectRole }} />
  }
}

export default connect(mapStateToProps, { selectRole })(SelectRolePage)
