/**
 * User lib - pulls data from global IAM Obj
 * @type {Object}
 */

export const authObject = () => {
  return CBCORE.iam.getAuthSession() || {};
}

export const isAuthed = () => authObject().isLoggedIn


