import { Field } from 'redux-form'
import { AddRequiredValidation } from '..'
import { convertReactElementToString } from '../../../utils/common'

export const renderInput = ({
  input,
  disabled,
  label,
  srOnlyLabel,
  type,
  meta,
  checked,
  className,
  style = null,
  labelId,
  labelClasses,
  labelStyle = {},
  errorStyle = {},
  ariaLabel = '',
  ariaLabelledBy = null,
  ariaDescribedBy = null,
  showErrorsOnLoad = false,
  showError = true,
}) => {
  const isChecked = checked ? { defaultChecked: true } : {}
  const { touched, error, warning, dirty, submitFailed } = meta || {}
  const errored = (touched || submitFailed || showErrorsOnLoad) && Boolean(error)
  const errorId = `errMessage-${input.name}`

  return (
    <div
      className={`${type}${disabled ? ' disabled' : ''}${className ? ` ${className}` : ''}`}
      style={style}
    >
      <label
        htmlFor={`${input.name}-${input.value}`}
        id={labelId}
        className={labelClasses}
        style={labelStyle}
      >
        <input
          {...input}
          {...isChecked}
          type={type}
          className={`cb-${type}`}
          id={`${input.name}-${input.value}`}
          aria-label={ariaLabel || convertReactElementToString(label)}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
          disabled={disabled}
        />
        <span className="cb-span" />
        {srOnlyLabel ? <span className="sr-only">{label}</span> : label}
        {errored && showError ? (
          <p
            tabIndex="-1"
            id={errorId}
            className="cb-error-msg"
            role="alert"
            style={errorStyle}
            aria-live="polite"
          >
            {error}
          </p>
        ) : null}
      </label>
    </div>
  )
}

export default props => (
  <Field
    {...props}
    component={renderInput}
    validate={AddRequiredValidation(props.isRequired, props.validate, props.type === 'checkbox')}
    normalize={props.normalize}
  />
)
