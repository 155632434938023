import * as c from '../constants/SettingsConstants'
import { CHANGE_STEP, SET_PARTFORM_TO_SIGNED } from '../constants/SetupConstants'

const initialState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  errored: null,
  error: null,
}

export default (state = { setupStep: 0, allowedToOrder: {} }, { type, payload }) => {
  switch (type) {
    case c.FETCH_ORG_SETTINGS_PENDING:
      return {
        ...state,
        [payload]: {
          ...initialState,
          ...state[payload],
          fetching: true,
          fetched: false,
          error: null,
        },
      }
    case c.UPDATE_ORG_SETTINGS_PENDING:
      return {
        ...state,
        [payload]: { ...state[payload], updating: true, updated: false, errored: null },
      }

    case c.FETCH_ORG_SETTINGS_FULFILLED:
      return {
        ...state,
        allowedToOrder: { ...state.allowedToOrder, [payload.orgId]: payload.allowedToOrder },
        [payload.orgId]: {
          ...state[payload.orgId],
          administeringDigitalExams: payload.administeringDigitalExams,
          data: payload.data,
          fetching: false,
          fetched: true,
        },
      }
    case c.UPDATE_ORG_SETTINGS_FULFILLED:
      return {
        ...state,
        [payload.orgId]: {
          ...state[payload.orgId],
          data: payload.data,
          updating: false,
          updated: true,
          errored: null,
        },
      }

    case c.FETCH_ORG_SETTINGS_REJECTED:
      return {
        ...state,
        [payload.orgId]: { ...state[payload.orgId], fetching: false, error: payload.error },
      }
    case c.UPDATE_ORG_SETTINGS_REJECTED:
      return {
        ...state,
        [payload.orgId]: { ...state[payload.orgId], updating: false, errored: payload.error },
      }

    case CHANGE_STEP:
      return { ...state, setupStep: payload }

    case SET_PARTFORM_TO_SIGNED: {
      const selectedOrgData = state[payload.orgId]
      return {
        ...state,
        allowedToOrder: { ...state.allowedToOrder, [payload.orgId]: true },
        [payload.orgId]: {
          ...selectedOrgData,
          data: {
            ...selectedOrgData.data,
            participationForm: {
              ...selectedOrgData.data.participationForm,
              coordinatorSigned: true,
              dateCompleted: payload.date,
            },
          },
        },
      }
    }

    default:
      return state
  }
}
