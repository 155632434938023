import { Field } from 'redux-form'
import Label from './Label'
import { AddRequiredValidation } from '..'

const Textarea = props => {
  const {
    input,
    rows = 3,
    columns,
    isRequired,
    maxlength,
    placeholder,
    disabled,
    label,
    ariaLabel,
    meta,
    labelStyle = {},
    inputStyle = {},
    formGroupStyle = {},
    errorOnPristine = false, // to display error when value is required but none has been entered
  } = props
  const { touched, error, dirty, submitFailed } = meta || {}
  const errored =
    ((touched && ((!errorOnPristine && dirty) || errorOnPristine)) || submitFailed) &&
    Boolean(error)
  const errorId = `errMessage-${input.name}`

  return (
    <div className={`form-group ${errored ? 'has-error' : ''}`} style={formGroupStyle}>
      {label ? (
        <Label name={input.name} isRequired={isRequired} label={label} style={labelStyle} />
      ) : null}
      <textarea
        {...input}
        rows={rows}
        columns={columns}
        id={input.name}
        type={input.type}
        placeholder={placeholder || `Enter ${label}...`}
        className="form-control"
        style={inputStyle}
        maxLength={maxlength}
        required={isRequired}
        aria-required={isRequired}
        aria-label={label || ariaLabel}
        aria-invalid={errored}
        aria-describedby={errored ? errorId : null}
        disabled={disabled}
        aria-disabled={disabled}
      />
      {errored ? (
        <p tabIndex="-1" id={errorId} className="cb-error-msg" role="alert" aria-live="polite">
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default props => (
  <Field
    {...props}
    component={Textarea}
    validate={AddRequiredValidation(props.isRequired, props.validate)}
    normalize={val => (val === '' ? null : props.normalize ? props.normalize(val) : val)}
  />
)
