import { Route, Switch } from 'react-router-dom'
import { Error, Loader } from '@myap/ui-library/esm/components'
import { fetchEducationPeriod } from '../../actions/settingsGlobal'
import { fetchFeatures } from '../../actions/features'
import { breakpointChange } from '../../actions/app'
import { getUserDetails } from '../../actions/user'
import { DocusignRedirectPage, SelectRolePage, RedirectPage } from '../../containers'
import StudentApp from './StudentApp'
import TeacherApp from './TeacherApp'
import CSRApp from './CSRApp'
import CoordinatorApp from './CoordinatorApp'
import FundingApp from './FundingApp'
import FetchByOrgIdOrYear from './FetchByOrgIdOrYear'
import AppRoute from '../routes/AppRoute'
import TACRoute from '../routes/TACRoute'
import NoAccessRoute from '../routes/NoAccessRoute'
import PrivateRoute from '../routes/PrivateRoute'
import { NAV_LINKS } from '../../constants/NavConstants'

import '../../assets/style/css/global.css'

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod, fetching: fetchingEdPd, error: edpdError },
    user: { hasAccess, isLoaded, isLoading, error, data },
  } = state

  return {
    hasAccess: hasAccess && isLoaded,
    isLoading: isLoading && !data.isCSR,
    isLoaded: isLoaded || data.isCSR,
    hasError: edpdError || (error && !data.isCSR),
    user: data || {},
    fetchingEdPd,
  }
}

const MainApp = props => {
  const {
    fetchEducationPeriod,
    breakpointChange,
    getUserDetails,
    fetchFeatures,
    hasError,
    isLoading,
    isLoaded,
    user,
    fetchingEdPd,
  } = props

  const getUserApp = () => {
    const { isStudent, isTeacher, isCoordinator, isCSR, isDistrictFundingAdmin } = user

    if (isStudent) return StudentApp
    if (isTeacher) return TeacherApp
    if (isCSR) return CSRApp
    if (isCoordinator) return CoordinatorApp
    if (isDistrictFundingAdmin) return FundingApp

    return null
  }

  useEffect(() => {
    getUserDetails()
    fetchFeatures()
    APRICOT.utils.breakpoints(true)
    breakpointChange(APRICOT.utils.viewport())
  }, [])

  useEffect(() => {
    $(window).on('breakpoint_change', (e, data) => breakpointChange(data))
    return () => $(window).off('breakpoint_change')
  }, [])

  useEffect(() => {
    const {
      selectedOrgId,
      isStudent,
      personId,
      isDistrictAdmin,
      isDistrictFundingAdmin,
      isDistrictSuperAdmin,
      activeRoleCd,
    } = user
    let idType = null
    if (!isStudent && !isDistrictFundingAdmin) {
      idType = 'orgId'
    } else if (isStudent && personId) {
      idType = 'studentId'
    }
    const id = isStudent ? personId : selectedOrgId
    if (activeRoleCd && !fetchingEdPd && (selectedOrgId !== null || isStudent)) {
      fetchEducationPeriod({ idType, id })
    }
  }, [user])

  if (hasError)
    return (
      <Error
        title="Error retrieving data"
        message="An error occurred. Please try again later"
        useWrapper={true}
      />
    )

  if (isLoading) return <Loader />

  if (isLoaded)
    return (
      <Switch>
        <NoAccessRoute path={NAV_LINKS.noaccess} />
        <TACRoute path={NAV_LINKS.tac} />
        <Route path={NAV_LINKS.selectRole} exact render={props => <SelectRolePage {...props} />} />
        <Route path={NAV_LINKS.docusign} component={DocusignRedirectPage} />
        <PrivateRoute path={NAV_LINKS.redirect} component={RedirectPage} />
        <FetchByOrgIdOrYear>
          <AppRoute component={getUserApp()} />
        </FetchByOrgIdOrYear>
      </Switch>
    )

  return null
}

export default connect(mapStateToProps, {
  fetchEducationPeriod,
  breakpointChange,
  getUserDetails,
  fetchFeatures,
})(MainApp)
