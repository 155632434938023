import { formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { DownloadToCSV } from '../common'
import { formatSSDMaterials } from './utils'

const STUDENT_KEYS = ['firstName', 'lastName', 'aiCode', 'id', 'ssdId', 'ssdMaterials']
const STUDENT_LABELS = [
  'Student First Name',
  'Student Last Name',
  'School Code',
  'AP ID',
  'SSD ID',
  'SSD Materials',
]

const ROOM_KEYS = ['examTime', 'totalCapacity', 'proctorNames', 'description']
const ROOM_LABELS = ['Exam Date', 'Room Capacity', 'Proctor(s)', 'Room Description']

const [STUDENT_HEADER, ROOM_HEADER, DIVIDER] = STUDENT_KEYS.reduce(
  (acc, key, i) => {
    const [stu, room, div] = acc
    return [
      { ...stu, [key]: STUDENT_LABELS[i] },
      { ...room, [key]: ROOM_LABELS[i] || '' },
      { ...div, [key]: '' },
    ]
  },
  [{}, {}, {}]
)

const ExamRoomRosterDownload = props => {
  const { students, disabled, name, courseName, style } = props
  const [mainHeader, roomDetails] = STUDENT_KEYS.reduce(
    (acc, key, i) => {
      const [main, dets] = acc
      const roomDetailValue = props[ROOM_KEYS[i]] || ''
      return [
        [...main, { label: i === 0 ? `${courseName}: ${name} Roster` : '', key }],
        {
          ...dets,
          [key]: i === 0 ? formatDateTime(roomDetailValue, 'MM/dd/yyyy h:mm a') : roomDetailValue,
        },
      ]
    },
    [[], {}]
  )

  return (
    <DownloadToCSV
      style={style}
      disabled={disabled}
      filename={`${courseName} ${name} Roster`}
      header={mainHeader}
      title="Roster"
      timestampHeaderKey={STUDENT_KEYS[0]}
      data={[
        ROOM_HEADER,
        roomDetails,
        DIVIDER,
        STUDENT_HEADER,
        ...students.map(s => ({ ...s, ssdMaterials: formatSSDMaterials(s) })),
      ]}
    />
  )
}

export default ExamRoomRosterDownload
