import * as c from '../constants/ShipmentConstants'
import template from './template'

const initialState = {
  error: null,
  fetching: false,
  fetched: false,
  splitShipment: false,
  suborders: [],
  data: {},
}

export const shipments = (state = initialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_SHIPMENTS_SUMMARY_FULFILLED: {
      const { suborders, splitShipmentInd: splitShipment } = payload
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        suborders,
        splitShipment,
      }
    }
    case c.FETCH_SHIPMENTS_SUMMARY_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }
    case c.FETCH_SHIPMENTS_SUMMARY_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    default:
      return state
  }
}

const detailsInitialState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export const suborderDetails = (state = detailsInitialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_SHIPMENTS_SUBORDER_FULFILLED: {
      const {
        data: { id, shippedItems },
      } = payload
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: { ...state.data, [id]: shippedItems },
      }
    }
    case c.FETCH_SHIPMENTS_SUBORDER_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }
    case c.FETCH_SHIPMENTS_SUBORDER_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }
    default:
      return state
  }
}

export const shipmentsAddress = (state, action) => template(state, action, c, 'SHIPPING_ADDRESS')
