import styles from '../../../../assets/style/scss/csr.scss'

export default ({ selectAll, selectNone, dataLen, selectionsLen }) => {
  if (dataLen > 1) {
    return (
      <div className={styles['tool-body-divider']}>
        <button
          type="button"
          className="btn-link"
          onClick={selectAll}
          disabled={selectionsLen === dataLen || !dataLen}
        >
          Select All
        </button>
        <button type="button" className="btn-link" onClick={selectNone} disabled={!selectionsLen}>
          Select None
        </button>
      </div>
    )
  }
  return null
}
