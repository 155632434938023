import { Loader, DisplayExamDate } from '../../common'
import { dollarFormat } from '../../../utils/numbers'
import { truncateLongNamesWithSpaces } from '../../../utils/common'
import { getProposedExamPrices } from '../../../actions/pricing'

const mapStateToProps = (state, { exam }) => ({
  ...state.pricingProposedExam,
  ...state.studentsByOrg.courseMap[exam.testCd],
})

const UpdateTable = ({
  exam,
  exam: { lateReasonCode },
  studentName,
  name,
  examPrice = {},
  fetching,
  error,
  getProposedExamPrices,
}) => {
  const { lateOrderFee, alternateExamFee, cancellationFee, standardTestPrice, totalPrice } =
    examPrice
  const showCost = cost => cost !== null && cost !== undefined
  const hasLateOrderFee = showCost(lateOrderFee)
  const hasAlternateExamFee = showCost(alternateExamFee)
  const hasCancellationFee = showCost(cancellationFee)

  useEffect(() => {
    getProposedExamPrices(exam)
  }, [lateReasonCode])

  if (error) {
    return (
      <p className="cb-error-msg" role="alert" aria-live="polite">
        {error}
      </p>
    )
  }

  if (fetching) {
    return <Loader size="md" />
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <caption className="sr-only">Review of order cost changes</caption>
        <thead>
          <tr role="row">
            <th scope="col">Student Name</th>
            <th scope="col">Course</th>
            <th scope="col">Exam Date</th>
            <th scope="col">Exam Fee</th>
            {hasLateOrderFee ? <th scope="col">Late Order Fee</th> : null}
            {hasAlternateExamFee ? <th scope="col">Late-Testing Fee</th> : null}
            {hasCancellationFee ? <th scope="col">Unused / Cancellation Fee</th> : null}
            <th scope="col">Total Exam Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{truncateLongNamesWithSpaces(studentName, 25, true)}</td>
            <td>{name}</td>
            <td>
              <DisplayExamDate course={exam} />
            </td>
            <td className="text-right">{dollarFormat(standardTestPrice)}</td>
            {hasLateOrderFee ? <td className="text-right">{dollarFormat(lateOrderFee)}</td> : null}
            {hasAlternateExamFee ? (
              <td className="text-right">{dollarFormat(alternateExamFee)}</td>
            ) : null}
            {hasCancellationFee ? (
              <td className="text-right">{dollarFormat(cancellationFee)}</td>
            ) : null}
            <td className="text-right">{dollarFormat(totalPrice)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, { getProposedExamPrices })(UpdateTable)
