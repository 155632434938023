import memoize from 'memoizee'
import {
  ORDER_SUBMITTED,
  ORDER_NOT_SUBMITTED,
  ALL_APPROVAL_REQUESTS,
} from '../constants/OrderConstants'
import { isEmpty } from '../utils/common'
import { getExamWindowRefData } from '../selectors/examWindows'

export const hasUnsubmittedChanges = memoize(status => status === ORDER_NOT_SUBMITTED)
export const isSubmitted = memoize(status => status === ORDER_SUBMITTED)

export const getCurrentApprovalRequestsFilter = state => state.approvalRequestFilter.selectedFilter

export const getFilteredSpecialExamReqeusts = state => {
  const currentFilter = getCurrentApprovalRequestsFilter(state)
  return state.ordersSpecialExamRequests.data?.filter(
    request =>
      currentFilter === ALL_APPROVAL_REQUESTS || request.etsApprovalStatus === currentFilter
  )
}

export const getFilteredSecureMaterialsReqeusts = state => {
  const currentFilter = getCurrentApprovalRequestsFilter(state)
  return state.ordersSecureMaterialsRequests.data?.filter(
    request =>
      currentFilter === ALL_APPROVAL_REQUESTS || request.etsApprovalStatus === currentFilter
  )
}

export const getFilteredNonSecureMaterialsReqeusts = state => {
  const currentFilter = getCurrentApprovalRequestsFilter(state)
  return state.ordersNonSecureMaterialsRequests.data?.filter(
    request =>
      currentFilter === ALL_APPROVAL_REQUESTS || request.etsApprovalStatus === currentFilter
  )
}

export const getFilteredExceptionTestingRequests = state => {
  const currentFilter = getCurrentApprovalRequestsFilter(state)
  return state.ordersExceptionTestingRequests.data?.filter(
    request =>
      currentFilter === ALL_APPROVAL_REQUESTS || request.etsApprovalStatus === currentFilter
  )
}

export const getFilteredOrdersAfterDeadlineRequests = state => {
  const currentFilter = getCurrentApprovalRequestsFilter(state)
  return state.ordersAfterDeadlineRequests.data?.filter(
    request =>
      currentFilter === ALL_APPROVAL_REQUESTS || request.etsApprovalStatus === currentFilter
  )
}

export const getSpecialExamRequestsStatusProps = state => {
  const { fetching, fetched, error } = state.ordersSpecialExamRequests
  return { fetching, fetched, error }
}

export const getSecureMaterialsRequestsStatusProps = state => {
  const { fetching, fetched, error } = state.ordersSecureMaterialsRequests
  return { fetching, fetched, error }
}

export const getNonSecureMaterialsRequestsStatusProps = state => {
  const { fetching, fetched, error } = state.ordersNonSecureMaterialsRequests
  return { fetching, fetched, error }
}

export const getExceptionTestingRequestsStatusProps = state => {
  const { fetching, fetched, error } = state.ordersExceptionTestingRequests
  return { fetching, fetched, error }
}

export const getOrdersAfterDeadlineRequestsStatusProps = state => {
  const { fetching, fetched, error } = state.ordersAfterDeadlineRequests
  return { fetching, fetched, error }
}

export const getOrdersNonSecureAncillaryMaterialsData = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    ordersNonSecureAncillaryMaterials: { data },
  } = state
  const { [selectedOrgId]: allTestingMaterials = {} } = data
  return allTestingMaterials
}

export const getOrdersNonSecureAncillaryMaterialsProps = state => {
  const {
    ordersNonSecureAncillaryMaterials: { fetching, fetched, error, saving, saved, savingError },
  } = state
  return { fetching, fetched, error, saving, saved, savingError }
}

const isPristine = ({
  selectedQuantity = 0,
  providedReasonForOrder = null,
  quantity: initialQuantity = 0,
  reasonForOrder: initialReasonForOrder = null,
}) => initialQuantity === selectedQuantity && initialReasonForOrder === providedReasonForOrder

export const getOrdersNonSecureAncillaryMaterialsIsPristine = state => {
  const {
    ordersNonSecureAncillaryMaterials: { formData = {} },
  } = state
  const examWindowRefData = getExamWindowRefData(state)
  const allTestingMaterials = getOrdersNonSecureAncillaryMaterialsData(state)
  const allInitialData = Object.keys(examWindowRefData).reduce(
    (acc, adminWindow) => Object.assign(acc, allTestingMaterials[adminWindow]),
    {}
  )
  const combinedData = Object.keys(formData).map(key => ({
    ...allInitialData[key],
    ...formData[key],
  }))
  return combinedData.every(item => isPristine(item))
}

const isErrorFree = ({ selectedQuantity, providedReasonForOrder, reasonValidationError }) => {
  return (
    selectedQuantity === 0 ||
    (selectedQuantity > 0 && !isEmpty(providedReasonForOrder) && isEmpty(reasonValidationError))
  )
}

export const getOrdersNonSecureAncillaryMaterialsHasNoErrors = state => {
  const { formData = {} } = state.ordersNonSecureAncillaryMaterials
  return Object.keys(formData).every(key => isErrorFree(formData[key]))
}

export const getExceptionTestingApprovalDetails = (state, id) => {
  const { exceptionTestingApprovalDetails } = state
  return exceptionTestingApprovalDetails?.[id] ?? {}
}

export const getOrdersAfterDeadlineApprovalDetails = (state, id) => {
  const { ordersAfterDeadlineApprovalDetails } = state
  return ordersAfterDeadlineApprovalDetails?.[id] ?? {}
}
