import * as c from '../constants/StudentConstants'

const initialState = {
  fetching: false,
  error: null,
  students: [],
  update: {
    id: null,
    type: null,
    updating: false,
    error: null,
  },
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_STUDENTS_BYSECTION_PENDING:
      return { ...state, fetching: true, error: null }
    case c.FETCH_STUDENTS_BYSECTION_FULFILLED:
      return { ...state, fetching: false, ...payload }
    case c.FETCH_STUDENTS_BYSECTION_REJECTED:
      return { ...state, fetching: false, error: payload }

    case c.UPDATE_SECTION_STUDENT_PENDING:
      return {
        ...state,
        update: {
          type: payload.type,
          id: payload.student.studentId,
          updating: true,
          error: null,
        },
      }
    case c.UPDATE_SECTION_STUDENT_REJECTED:
      return { ...state, update: { ...state.update, error: payload, updating: false }}

    case c.UPDATE_SECTION_STUDENT_EXAM_FULFILLED:
      return {
        ...state,
        update: initialState.update,
        students: state.students.map(s => (s.studentId === payload.studentId ? payload : s)),
      }

    case c.DROP_SECTION_STUDENT_FULFILLED:
    case c.MOVE_SECTION_STUDENT_FULFILLED:
      return {
        ...state,
        update: initialState.update,
        students: state.students.filter(s => s.studentId !== payload),
      }

    case c.RESET_UPDATE_TYPE:
      return { ...state, update: initialState.update }

    default:
      return state
  }
}
