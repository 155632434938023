import FilterContainer from '../common/FilterContainer'
import { FilterNoResultsMessage } from '../common/FilterMessages'
import {
  AdminFilterDropdown,
  CourseFilterDropdown,
  TeacherFilterDropdown,
} from '../common/Dropdowns'
import {
  ExamDateFilterDropdown,
  LoginFilterDropdown,
  SetupStatusFilterDropdown,
  ExamStatusFilterDropdown,
  MakeupStatusFilterDropdown,
  PracticeStatusFilterDropdown,
} from './ExamFilterDropdowns'
import { DigitalOnlyFilterCheckbox, ActiveAlertsFilterCheckbox } from './ExamFilterCheckboxes'
import { getExamFilterTagData } from '../../../selectors/filters/examReadinessFilters'

const mapStateToProps = state => ({ ...state.examReadiness })

export default connect(mapStateToProps)(props => {
  const { activeFilters, visibleExams, examObj = {}, examWindowMap, teacherMap, courses } = props
  const showFilterContainer = Object.keys(examObj).length
  const totalVisibleStudents = visibleExams.length

  return showFilterContainer ? (
    <>
      <FilterContainer
        label="Exam Readiness Filters"
        activeFilters={activeFilters}
        styles={{ marginTop: 0 }}
        getFilterTagData={(filter, value) =>
          getExamFilterTagData({ courses, teacherMap, examWindowMap }, filter, value)
        }
      >
        <AdminFilterDropdown windows={Object.keys(examWindowMap)} />
        <ExamDateFilterDropdown windows={Object.values(examWindowMap)} />
        <TeacherFilterDropdown
          teachers={Object.keys(teacherMap).map(key => ({
            teacherId: key,
            name: `${teacherMap[key].firstName} ${teacherMap[key].lastName}`,
          }))}
        />
        <CourseFilterDropdown courses={courses} labelKey="courseName" valueKey="testCode" />
        <LoginFilterDropdown />
        <PracticeStatusFilterDropdown />
        <SetupStatusFilterDropdown />
        <ExamStatusFilterDropdown combineStatuses={true} />
        <MakeupStatusFilterDropdown />
        <DigitalOnlyFilterCheckbox />
        <ActiveAlertsFilterCheckbox />
      </FilterContainer>
      {Object.keys(activeFilters).length && !totalVisibleStudents ? (
        <FilterNoResultsMessage text="exams" />
      ) : null}
    </>
  ) : null
})
