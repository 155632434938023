import memoize from 'memoizee'
import { COURSE_SCHEDULE_TYPES, CREATE_FORM_EXAM_ONLY_VALUE } from '../constants/SectionConstants'
import {
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_ENROLLMENT_STATUS_UNUSED,
  SSD_MATERIALS_FIELDS,
  SSD_MATERIALS_FORM_DEFAULTS,
  PAPERFORMATS_CUSTOM_SIZE,
} from '../constants/StudentConstants'
import { isEmpty } from '../utils/common'

export const isStandardSection = sectionType => {
  return (
    sectionType === COURSE_SCHEDULE_TYPES.S_FY.value || sectionType === CREATE_FORM_EXAM_ONLY_VALUE
  )
}

export const isExamOnly = sectionType => sectionType === CREATE_FORM_EXAM_ONLY_VALUE

export const hasFullYearSections = sections => {
  return (
    Object.keys(sections)
      .map(key => sections[key])
      .filter(section => section.sectionType === COURSE_SCHEDULE_TYPES.S_FY.value).length > 0
  )
}

export const hasSecondSemesterSections = sections => {
  return (
    Object.keys(sections)
      .map(key => sections[key])
      .filter(section => section.sectionType === COURSE_SCHEDULE_TYPES.S_SS.value).length > 0
  )
}

export const getOrderDeadlineIsPast = (
  { type },
  { secondSemesterOrderingDeadlineIsPast, regularOrderingDeadlineIsPast }
) => {
  return type
    ? isStandardSection(type)
      ? regularOrderingDeadlineIsPast
      : secondSemesterOrderingDeadlineIsPast
    : false
}

export const isTakingExam = ({ examIntent }) => examIntent === STUDENT_ENROLLMENT_STATUS_YES

export const isUnusedExam = ({ examIntent }) => examIntent === STUDENT_ENROLLMENT_STATUS_UNUSED

const SSD_MATERIALS_CUSTOM_DESCRIPTIONS = {
  [SSD_MATERIALS_FIELDS.fontSize.name]: (val, metaData) => {
    let fontDesc = ''
    // For fontSize 14pt, 20pt, custom, we do not save large block answer sheet value,
    // instead, we programmatically add it in the UI when one of those three font sizes
    // has been selected.
    let additionalDesc = ''
    if (
      val === SSD_MATERIALS_FIELDS['14point'].value ||
      val === SSD_MATERIALS_FIELDS['20point'].value
    ) {
      additionalDesc = `, ${metaData[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.value].description}`
    }
    if (val === SSD_MATERIALS_FIELDS.customFontSize.value) {
      additionalDesc = metaData[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.value].description
    }
    // For Braille, the Reader Copy will automatically be included, so it is not saved to the order,
    // instead, we programmatically add it in the UI when Braille has been selected
    if (val === SSD_MATERIALS_FIELDS.braille.value) {
      additionalDesc = `, ${metaData[SSD_MATERIALS_FIELDS.readerCopy.value].description}`
    }
    // Otherwise, we'll get 'Custom font size' prepended to the list, and nobody
    // wants that.
    if (val !== 2) {
      fontDesc = `${metaData[val].description}${additionalDesc}`
    } else {
      fontDesc = additionalDesc
    }
    return fontDesc
  },
  [SSD_MATERIALS_FIELDS.customFontSizeNumber.name]: val => `${val} point font`,
  [SSD_MATERIALS_FIELDS.otherAccommodations.name]: (val, metaData) =>
    metaData[SSD_MATERIALS_FIELDS.otherAccommodations.value].description,
}

export const flattenSpecialDigitalFormats = flattenObj =>
  Object.keys(flattenObj).reduce((r, k) => r.concat(Object.entries(flattenObj[k])), [])

export const buildSSDMaterials = memoize((metaData, ssdMaterials) => {
  return Object.keys(ssdMaterials)
    .map(key => {
      const material = ssdMaterials[key]
      const custom = SSD_MATERIALS_CUSTOM_DESCRIPTIONS[key]
      const { value } = SSD_MATERIALS_FIELDS[key] || {}

      if (!material) return

      if (custom) return custom(material, metaData)

      if (Number.isInteger(material)) return metaData[material].description

      return metaData[value].description
    })
    .filter(m => m)
    .join(', ')
})

export const displayWithCustomFontSize = ({ description, accommodations = [] }) => {
  return accommodations?.length > 0
    ? description.replace(
        /Other/,
        `${
          accommodations.find(
            ({ accommodationCode }) => accommodationCode === PAPERFORMATS_CUSTOM_SIZE
          )?.comments
        } point`
      )
    : description
}

export const getAccommodationDescription = ({
  accommodationCode,
  description,
  accommodations = [],
}) => {
  return accommodationCode === PAPERFORMATS_CUSTOM_SIZE
    ? displayWithCustomFontSize({ description, accommodations })
    : description
}

export const buildSpecialDigitalFormats = memoize(
  ({ descriptions, specialDigitalFormats, accommodations = [] }) => {
    const listOfAccommodations = []
    Object.keys(specialDigitalFormats).forEach(category => {
      Object.keys(specialDigitalFormats[category]).forEach(cd => {
        if (specialDigitalFormats[category][cd] === true) {
          listOfAccommodations.push(
            getAccommodationDescription({
              accommodationCode: cd,
              description: descriptions[category][cd],
              accommodations,
            })
          )
        }
      })
    })
    return listOfAccommodations.sort().join(', ')
  }
)

export const getOrgLevelAttrs = (
  { level, preAPOnly, orderByAnotherSchool, aiCode },
  isCoordinator
) => ({
  isLevelOne: Boolean((level === 1 || orderByAnotherSchool) && !(preAPOnly && isCoordinator)),
  isLevelTwo: Boolean(level === 2 && !(preAPOnly && isCoordinator) && !orderByAnotherSchool),
  isPreAPOnly: Boolean(preAPOnly || isEmpty(aiCode)),
})

export const anySSDMaterialsSelected = ssdMaterials => {
  let anySelected = false

  if (!isEmpty(ssdMaterials)) {
    Object.keys(ssdMaterials).map(key => {
      if (ssdMaterials[key] !== SSD_MATERIALS_FORM_DEFAULTS[key]) {
        anySelected = true
      }
    })
  }

  return anySelected
}

export const anySpecialDigitalFormatsSelected = accommodations => {
  let anySelected = false
  Object.keys(accommodations).map(category => {
    Object.keys(accommodations[category]).map(accommodation => {
      if (accommodations[category][accommodation] === true) {
        anySelected = true
      }
    })
  })

  return anySelected
}
