import { getNotifications } from '../../../actions/notifications'
import {
  DENIED_NOTIFICATION,
  NEEDS_INFO_NOTIFICATION,
} from '../../../constants/NotificationConstants'
import CoordinatorApprovalRequestNotification from './CoordinatorApprovalRequestNotification'
import CoordinatorHoldNotification from './CoordinatorHoldNotification'
import CoordinatorMergeNotification from './CoordinatorMergeNotification'
import CoordinatorPartFormNotification from './CoordinatorPartFormNotification'
import CoordinatorTransferNotification from './CoordinatorTransferNotification'
import CoordinatorMaterialChangeNotification from './CoordinatorMaterialChangeNotification'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId, isCSR, isSchoolAdmin },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = state

  return {
    isSchoolAdmin,
    isCSR,
    educationPeriodCd: selectedEducationPeriod,
    orgId: selectedOrgId,
  }
}

const CoordinatorNotifications = ({
  isSchoolAdmin,
  isCSR,
  orgId,
  educationPeriodCd,
  getNotifications,
}) => {
  useEffect(() => {
    if (orgId && educationPeriodCd && !isCSR && !isSchoolAdmin) {
      getNotifications({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd, isCSR, !isSchoolAdmin])

  return (
    <>
      <CoordinatorPartFormNotification />
      <CoordinatorHoldNotification />
      <CoordinatorMergeNotification />
      <CoordinatorTransferNotification />
      <CoordinatorMaterialChangeNotification />
      <CoordinatorApprovalRequestNotification notificationType={DENIED_NOTIFICATION} />
      <CoordinatorApprovalRequestNotification notificationType={NEEDS_INFO_NOTIFICATION} />
    </>
  )
}

export default connect(mapStateToProps, { getNotifications })(CoordinatorNotifications)
