import { getFormValues, change } from 'redux-form'
import get from 'lodash-es/get'
import {
  Text,
  Input,
  PositiveNumberValidation,
  PositiveMaximumNumberValidation,
  Tooltip,
} from '../../common'
import {
  SCHOOL_INFORMATION_FORM,
  SCHOOL_INFORMATION_FIELDS as FIELDS,
} from '../../../constants/SetupConstants'
import { FRPL_STUDENTS_TOOLTIP } from '../../../constants/SettingsConstants'
import { isEmpty } from '../../../utils/common'

const hasValue = (value, allValues, props, name) => {
  const reducedFeeCEPInd = get(allValues, FIELDS.REDUCED_FEE_CEP_IND.name)
  if (reducedFeeCEPInd === false && isEmpty(value)) {
    return 'Error: A population must be provided'
  }
  return undefined
}

export default connect(
  state => ({
    currentFormValues: getFormValues(SCHOOL_INFORMATION_FORM)(state) ?? {},
  }),
  { change }
)(({ hideTitle, currentFormValues, change }) => (
  <div>
    {!hideTitle ? (
      <>
        <legend
          className="h4"
          id="students-frpl-selection-group"
          style={{ border: 0, margin: '24px 0', fontSize: '1.1em' }}
        >
          Percentage of students who qualify for free- or reduced-price lunch{' '}
          <span className="cb-roboto" style={{ fontWeight: 400 }}>
            (including Non-AP students)
          </span>{' '}
          <div style={{ display: 'inline-block' }}>
            <Tooltip
              title={FRPL_STUDENTS_TOOLTIP}
              placement="top"
              container="body"
              label="More information: FRPL Students"
            >
              <i
                aria-hidden="true"
                className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
                style={{ display: 'inline-block', fontSize: '8px' }}
                data-alt={FRPL_STUDENTS_TOOLTIP}
              />
            </Tooltip>
          </div>
        </legend>
      </>
    ) : null}
    <fieldset role="radiogroup" aria-labelledby="students-frpl-selection-group">
      <div className="row">
        <div className={`col-xs-${hideTitle ? '12' : '6'}`}>
          <Input
            type="radio"
            name={FIELDS.REDUCED_FEE_CEP_IND.name}
            isRequired={FIELDS.REDUCED_FEE_CEP_IND.required}
            label={FIELDS.REDUCED_FEE_PCT.label}
            value={false}
            normalize={val => val === 'true'}
            onChange={val => {
              if (!val) {
                change(SCHOOL_INFORMATION_FORM, FIELDS.REDUCED_FEE_PCT.name, '')
              }
            }}
            style={{ lineHeight: '50px', display: 'inline', position: 'absolute' }}
          />
          <Text
            name={FIELDS.REDUCED_FEE_PCT.name}
            placeholder="Enter population"
            isRequired={FIELDS.REDUCED_FEE_PCT.required}
            validate={[PositiveNumberValidation, PositiveMaximumNumberValidation]}
            maxlength={3}
            disabled={get(currentFormValues, FIELDS.REDUCED_FEE_CEP_IND.name) !== false}
            normalize={value => {
              if (!Number.isNaN(parseInt(value, 10))) {
                return parseInt(value, 10)
              }
              // return the original value so it can be handled by validators
              return value
            }}
            errorOnPristine={true}
            inputStyle={{
              display: 'inline-block',
              marginLeft: '250px',
              width: 'calc(100% - 250px)',
              minWidth: '140px',
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Input
            type="radio"
            name={FIELDS.REDUCED_FEE_CEP_IND.name}
            isRequired={FIELDS.REDUCED_FEE_CEP_IND.required}
            label={FIELDS.REDUCED_FEE_CEP_IND.label}
            value={true}
            normalize={val => val === 'true'}
            onChange={val => {
              if (val) {
                change(SCHOOL_INFORMATION_FORM, FIELDS.REDUCED_FEE_PCT.name, null)
              }
            }}
          />
        </div>
      </div>
    </fieldset>
  </div>
))
