import {
  Label,
  BasicSelect,
  BasicTextInput,
  RequiredValidation,
  LimitedStringValidation,
  PositiveNumberValidation,
} from '../../../common'
import {
  changeNonSecureMaterialSelectedQuantity,
  changeNonSecureMaterialProvidedReason,
  setNonSecureMaterialsReasonValidationError,
} from '../../../../actions/orders'
import { getOrdersNonSecureAncillaryMaterialsData } from '../../../../selectors/order'
import { isEmpty } from '../../../../utils/common'
import { NON_SECURE_MATERIALS_TEXT_INPUTS } from '../../../../constants/OrderConstants'

const mapStateToProps = (state, ownProps) => {
  const { ancillaryCd, testWindow, isLast } = ownProps
  const material = getOrdersNonSecureAncillaryMaterialsData(state)[testWindow][ancillaryCd]
  const {
    ordersNonSecureAncillaryMaterials: { formData },
  } = state
  const selectedQuantity = formData[ancillaryCd]?.selectedQuantity || 0
  const quantityValidationError = formData[ancillaryCd]?.quantityValidationError || null
  const providedReasonForOrder = formData[ancillaryCd]?.providedReasonForOrder || null
  const reasonValidationError = formData[ancillaryCd]?.reasonValidationError || null
  const { description, fullDescription, locked, quantityOrdered } = material
  const startCountVal = quantityOrdered // quantity can't go below approved quantity
  const maxAllowed = 100
  const opts = []
  // Answer Sheets will use text inputs instead of select controls
  if (!NON_SECURE_MATERIALS_TEXT_INPUTS.includes(ancillaryCd)) {
    for (let i = startCountVal; i <= maxAllowed; ++i) {
      opts.push({ label: i, value: i })
    }
  }
  const quantityFieldName = `${testWindow}.${ancillaryCd}.quantity`
  return {
    ancillaryCd,
    testWindow,
    description,
    fullDescription,
    locked,
    isLast,
    quantityFieldName,
    reasonFieldName: `${testWindow}.${ancillaryCd}.reasonForOrder`,
    opts,
    selectedQuantity,
    providedReasonForOrder,
    reasonValidationError,
    quantityValidationError,
  }
}

const NonSecureMaterialRow = ({
  ancillaryCd,
  testWindow,
  description,
  fullDescription,
  locked,
  isLast,
  quantityFieldName,
  reasonFieldName,
  opts,
  selectedQuantity,
  providedReasonForOrder,
  reasonValidationError,
  quantityValidationError,
  changeNonSecureMaterialSelectedQuantity,
  changeNonSecureMaterialProvidedReason,
  setNonSecureMaterialsReasonValidationError,
}) => {
  const reasonInputRef = useRef(null)
  const [immediateSelectedQuantity, setImmediateSelectedQuantity] = useState(selectedQuantity)
  const [immediateProvidedReasonForOrder, setImmediateProvidedReasonForOrder] =
    useState(providedReasonForOrder)
  const [reasonRequired, setReasonRequired] = useState(selectedQuantity !== 0)
  const [reasonChanged, setReasonChanged] = useState(false)
  const [immediateQuantityValidationError, setImmediateQuantityValidationError] =
    useState(quantityValidationError)
  const [immediateReasonValidationError, setImmediateReasonValidationError] =
    useState(reasonValidationError)
  const [descriptionExpanded, setDescriptionExpanded] = useState(false)

  const bottomBorder = !isLast ? { borderBottom: '1px solid #ddd' } : {}
  const key = `${testWindow}_${ancillaryCd}`
  const labelId = `${key}-label-${quantityFieldName}`
  const descriptionId = `${key}-description-${quantityFieldName}`

  useEffect(() => {
    changeNonSecureMaterialSelectedQuantity(immediateSelectedQuantity, ancillaryCd)
  }, [immediateSelectedQuantity])

  useEffect(() => {
    changeNonSecureMaterialProvidedReason(immediateProvidedReasonForOrder, ancillaryCd)
  }, [immediateProvidedReasonForOrder])

  useEffect(() => {
    setNonSecureMaterialsReasonValidationError(immediateReasonValidationError, ancillaryCd)
  }, [immediateReasonValidationError])

  useEffect(() => {
    if (opts.length && reasonRequired) {
      reasonInputRef.current.focus()
    }
  }, [reasonRequired])

  const handleDescriptionClick = e => {
    e.preventDefault()
    setDescriptionExpanded(!descriptionExpanded)
  }

  const normalizeValue = value => {
    if (!Number.isNaN(parseInt(value, 10))) {
      return parseInt(value, 10)
    }
    // return the original value so it can be handled by validators
    return value
  }

  return (
    <div key={key} style={{ marginBottom: '18px', paddingBottom: '18px', ...bottomBorder }}>
      <div style={{ display: 'flex', alignItems: 'top', marginBottom: 0 }}>
        <div>
          <Label
            name={quantityFieldName}
            label={description}
            labelId={labelId}
            style={{ display: 'inline-block', fontWeight: 700, marginTop: '10px' }}
          />
          <span style={{ fontSize: '.8em', marginLeft: '1em' }}>
            (
            <button
              type="button"
              className="btn-link"
              aria-controls={descriptionId}
              aria-describedby={labelId}
              onClick={handleDescriptionClick}
              style={{ padding: 0, borderWidth: 0 }}
            >
              {descriptionExpanded ? 'less' : 'more'}
            </button>
            )
          </span>
        </div>
        {opts.length ? (
          <BasicSelect
            name={quantityFieldName}
            input={{
              name: quantityFieldName,
              value: immediateSelectedQuantity,
              onChange: e => {
                const { value } = e.target
                const parsedValue = parseInt(value, 10)
                setImmediateSelectedQuantity(parsedValue)
                if (parsedValue === 0) {
                  // If quantity is changing to 0, we are going to hide the reason, so
                  // just go ahead and make the change in app state.
                  setImmediateProvidedReasonForOrder(null)
                  setImmediateReasonValidationError(undefined)
                  setReasonRequired(false)
                } else {
                  // A non-zero quantity was selected
                  setReasonRequired(true)
                }
              },
            }}
            options={opts}
            disabled={locked}
            showEmptyOption={false}
            style={{ width: '120px', marginLeft: 'auto', marginBottom: 0 }}
          />
        ) : (
          <BasicTextInput
            name={quantityFieldName}
            input={{
              type: 'text',
              name: quantityFieldName,
              value: immediateSelectedQuantity,
              onChange: e => {
                const { value } = e.target
                const parsedValue = normalizeValue(value)
                const validationError =
                  RequiredValidation(parsedValue) ||
                  PositiveNumberValidation(
                    parsedValue,
                    {},
                    { [`minimum${quantityFieldName}`]: 0 },
                    quantityFieldName
                  )
                setImmediateQuantityValidationError(validationError)
                setImmediateSelectedQuantity(parsedValue)
                if (!isEmpty(validationError) || parsedValue === 0) {
                  // If quantity is changing to 0, we are going to hide the reason, so
                  // just go ahead and make the change in app state.
                  setImmediateProvidedReasonForOrder(null)
                  setImmediateReasonValidationError(undefined)
                  setReasonRequired(false)
                } else {
                  // A non-zero quantity was selected
                  setReasonRequired(true)
                }
              },
            }}
            placeholder="Enter quantity"
            disabled={locked}
            inputStyle={{ width: '120px', marginLeft: 'auto' }}
            formGroupStyle={{ width: '400px', marginLeft: 'auto', marginBottom: 0 }}
            maxlength="5"
            meta={{
              touched: !isEmpty(immediateSelectedQuantity),
              dirty: !isEmpty(immediateQuantityValidationError),
              error: immediateQuantityValidationError,
            }}
          />
        )}
      </div>
      <div
        role="region"
        aria-labelledby={labelId}
        id={descriptionId}
        className="cb-small-font-size"
        style={{ marginTop: '.8em', display: descriptionExpanded ? 'block' : 'none' }}
      >
        {fullDescription}
      </div>
      <BasicTextInput
        name={reasonFieldName}
        label="Reason for Order"
        placeholder="Please provide a reason for ordering additional materials"
        input={{
          name: reasonFieldName,
          value: immediateProvidedReasonForOrder === null ? '' : immediateProvidedReasonForOrder,
          ref: reasonInputRef,
          onChange: e => {
            const { value } = e.target
            setImmediateProvidedReasonForOrder(value)
          },
          onBlur: e => {
            const { value } = e.target
            const validationError = RequiredValidation(value) || LimitedStringValidation(value)
            const reasonJustChanged = immediateProvidedReasonForOrder !== providedReasonForOrder
            setReasonChanged(reasonJustChanged)
            if (reasonRequired && !isEmpty(validationError)) {
              setImmediateReasonValidationError(validationError)
            } else if (!isEmpty(reasonValidationError)) {
              // if there's no validation error, then clear reasonValidationError
              setImmediateReasonValidationError(undefined)
            }
          },
        }}
        isRequired={true}
        disabled={locked || !reasonRequired}
        showErrorsOnLoad={false}
        maxlength="255"
        formGroupStyle={{
          marginTop: '1em',
          marginBottom: 0,
          display: reasonRequired ? 'block' : 'none',
        }}
        meta={{
          touched: reasonRequired,
          dirty: !isEmpty(immediateReasonValidationError),
          error: immediateReasonValidationError,
        }}
      />
    </div>
  )
}

export default connect(mapStateToProps, {
  changeNonSecureMaterialSelectedQuantity,
  changeNonSecureMaterialProvidedReason,
  setNonSecureMaterialsReasonValidationError,
})(NonSecureMaterialRow)
