import {
  fetchLargeVolumeRebatesLockStatus,
  saveLargeVolumeRebatesLockStatus,
} from '../../../actions/csr'
import { LockUnlockComponent } from '.'

import styles from '../../../assets/style/scss/csr.scss'

export default () => (
  <LockUnlockComponent
    compId="largeVolumeRebates"
    introText="Select Large Volume Rebates to unlock opt-in for Large Volume Rebates. Large Volume Rebates opt-in will be active for 24 hours."
    emptyText="Large Volume Rebates cannot be changed"
    fnFetch={fetchLargeVolumeRebatesLockStatus}
    fnSave={saveLargeVolumeRebatesLockStatus}
    itemProps={{ itemId: 'itemId', itemDescription: 'description' }}
  />
)
