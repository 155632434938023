import { HYBRID_EXAM } from './SettingsConstants'

export const FETCH_PACKING_LIST_SUMMARY_PENDING = 'FETCH_PACKING_LIST_SUMMARY_PENDING'
export const FETCH_PACKING_LIST_SUMMARY_FULFILLED = 'FETCH_PACKING_LIST_SUMMARY_FULFILLED'
export const FETCH_PACKING_LIST_SUMMARY_REJECTED = 'FETCH_PACKING_LIST_SUMMARY_REJECTED'

export const FETCH_PACKING_LIST_DETAILS_PENDING = 'FETCH_PACKING_LIST_DETAILS_PENDING'
export const FETCH_PACKING_LIST_DETAILS_FULFILLED = 'FETCH_PACKING_LIST_DETAILS_FULFILLED'
export const FETCH_PACKING_LIST_DETAILS_REJECTED = 'FETCH_PACKING_LIST_DETAILS_REJECTED'

export const UPDATE_PACKING_CARTONS_PENDING = 'UPDATE_PACKING_CARTONS_PENDING'
export const UPDATE_PACKING_CARTONS_FULFILLED = 'UPDATE_PACKING_CARTONS_FULFILLED'
export const UPDATE_PACKING_CARTONS_REJECTED = 'UPDATE_PACKING_CARTONS_REJECTED'

export const SUBMIT_PACKING_LIST_PENDING = 'SUBMIT_PACKING_LIST_PENDING'
export const SUBMIT_PACKING_LIST_FULFILLED = 'SUBMIT_PACKING_LIST_FULFILLED'
export const SUBMIT_PACKING_LIST_REJECTED = 'SUBMIT_PACKING_LIST_REJECTED'

export const UPDATE_PACKING_LIST_ITEM_PENDING = 'UPDATE_PACKING_LIST_ITEM_PENDING'
export const UPDATE_PACKING_LIST_ITEM_FULFILLED = 'UPDATE_PACKING_LIST_ITEM_FULFILLED'
export const UPDATE_PACKING_LIST_ITEM_REJECTED = 'UPDATE_PACKING_LIST_ITEM_REJECTED'

export const UPDATE_PACKING_LIST_STATUS_COUNTS = 'UPDATE_PACKING_LIST_STATUS_COUNTS'

export const RESET_UPDATE_PACKING_LIST_ITEM = 'RESET_UPDATE_PACKING_LIST_ITEM_UPDATING'

export const FLUSH_PACKING_LIST_DETAILS = 'FLUSH_PACKING_LIST_DETAILS'

export const SUBMITTED = 'SUBMITTED'
export const NOT_SUBMITTED = 'NOT_SUBMITTED'

export const STANDARD = 'STANDARD'
export const LATE = 'LATE'
export const EXCEPTION = 'EXCEPTION'
export const OFFCYCLE = 'OFFCYCLE'
export const PACKING_LIST_GROUPS = [STANDARD, LATE, EXCEPTION, OFFCYCLE]

/**
 * Mapping Enum sent to Display text.
 */
export const TITLE_WEEK_1 = 'Week 1'
export const TITLE_WEEK_2 = 'Week 2'

/**
 * Display values for packages.
 */
export const STANDARD_PACKAGE = 'Regular Exams'
export const BRAILLE_LARGE_FONT_PACKAGE = 'Braille & Large Type Exams'
export const STUDIO_ART_PACKAGE = 'Studio Art'
export const NON_STOCK_PACKAGE = 'Exams with Other Special Materials'

/**
 * Enums for packages.
 */
export const STANDARD_PACKAGE_ENUM = 'STANDARD'
export const BRAILLE_LARGE_FONT_PACKAGE_ENUM = 'BRAILLE'
export const STUDIO_ART_PACKAGE_ENUM = 'STUDIO_ART'
export const NON_STOCK_PACKAGE_ENUM = 'NON_STOCK'

/**
 * Display order of packages ENUMs.
 */
export const PACKAGES_ENUM = [
  STANDARD_PACKAGE_ENUM,
  BRAILLE_LARGE_FONT_PACKAGE_ENUM,
  STUDIO_ART_PACKAGE_ENUM,
  NON_STOCK_PACKAGE_ENUM,
]

/**
 * Mapping of packages display value and respective enums.
 */
export const PACKAGES_ENUM_TO_HEADING = {
  [STANDARD_PACKAGE_ENUM]: STANDARD_PACKAGE,
  [BRAILLE_LARGE_FONT_PACKAGE_ENUM]: BRAILLE_LARGE_FONT_PACKAGE,
  [STUDIO_ART_PACKAGE_ENUM]: STUDIO_ART_PACKAGE,
  [NON_STOCK_PACKAGE_ENUM]: NON_STOCK_PACKAGE,
}

export const PACKING_LIST_STATUS = {
  [SUBMITTED]: 'Submitted',
  [NOT_SUBMITTED]: 'Not Submitted',
}

export const STATUS_NOT_SUBMITTED = 'NOT_SUBMITTED'
export const STATUS_UNCONFIRMED = 'UNCONFIRMED'
export const STATUS_CONFIRMED = 'CONFIRMED'
export const STATUS_SUBMITTED = 'SUBMITTED'
export const STATUS_NO_VALUE = 'NO VALUE'

export const SUBMISSION_STATUS = {
  [STATUS_NOT_SUBMITTED]: {
    title: 'Not Submitted',
    explanation:
      'Enter the number of cartons being returned and confirm all administered exams in order to submit the packing list.',
    message: 'Submit Packing List',
    lock: true,
  },
  [STATUS_UNCONFIRMED]: {
    title: 'Not Submitted',
    explanation: 'Confirm all administered exams in order to submit this packing list.',
    message: 'Submit Packing List',
    lock: true,
  },
  [STATUS_NO_VALUE]: {
    title: 'Not Submitted',
    explanation: 'Enter the number of cartons being returned to submit the packing list.',
    message: 'Submit Packing List',
    lock: true,
  },
  [STATUS_CONFIRMED]: {
    title: 'Ready to Submit',
    explanation:
      'Be sure to review your list for any errors.  Once submitted, you cannot make any changes.',
    message: 'Submit Packing List',
    lock: false,
  },
  [STATUS_SUBMITTED]: {
    title: 'Submitted',
    explanation:
      'Your packing list has been submitted.  Please include a copy of this list with your shipment.',
    message: 'Print Packing List',
    lock: false,
  },
}
// export const PACKING_GENERAL_COMPONENTS_ORDER = [ 'Shipped', 'Unused', 'Returned' ]
export const PACKING_GENERAL_COMPONENTS_ORDER = ['quantity', 'unused', 'returned']

export const EXAM_MATERIAL = 'examMaterial'
export const EXAM = 'exam'
export const LISTENING_CDS = 'listeningCDs'
export const SPEAKING_CDS = 'speakingCDs'
export const SINGING_CDS = 'singingCDs'
export const EXAM_CDS = 'examCDs'
export const PORTFOLIOS = 'portfolios'
export const RESPONSES_RETURNED = 'responsesReturned'
export const PORTFOLIO_SUBMISSION = 'portfolioSubmission'
export const ONLINE_SUBMISSION = 'onlineSubmission'
export const SSD_MATERIALS_SUBMISSION = 'ssdMaterialsReturned'
export const STUDENTS = 'students'
export const ANSWER_SHEETS_RETURNED = 'answerSheetsReturned'
export const FREE_RESPONSE_BOOKLETS_RETURNED = 'freeResponseBookletsReturned'
export const MULTIPLE_CHOICE_BOOKLETS_RETURNED = 'multipleChoiceBookletsReturned'
export const ORANGE_BOOKLETS_RETURNED = 'orangeBookletsReturned'
export const SHORT_ANSWER_BOOKLETS_RETURNED = 'shortAnswerBookletsReturned'
export const RESPONSE_BOOKS_RETURNED = 'responseBooksReturned'

export const PACKING_LIST_COMPONENT_MAPPING = {
  [ANSWER_SHEETS_RETURNED]: 'Answer Sheets',
  [FREE_RESPONSE_BOOKLETS_RETURNED]: 'Free Response Booklets',
  [RESPONSE_BOOKS_RETURNED]: 'Response Books',
  [MULTIPLE_CHOICE_BOOKLETS_RETURNED]: 'M/C Booklets',
  [ORANGE_BOOKLETS_RETURNED]: 'Orange Booklets',
  [SHORT_ANSWER_BOOKLETS_RETURNED]: 'Short Answer Booklets',
  quantity: 'Shipped',
  returned: 'Returned',
  unused: 'Unused',
  examQuantity: 'Shipped',
}

export const EXAM_MATERIALS = [
  ANSWER_SHEETS_RETURNED,
  FREE_RESPONSE_BOOKLETS_RETURNED,
  RESPONSE_BOOKS_RETURNED,
  MULTIPLE_CHOICE_BOOKLETS_RETURNED,
  ORANGE_BOOKLETS_RETURNED,
  SHORT_ANSWER_BOOKLETS_RETURNED,
]

export const OTHER_MATERIALS = [LISTENING_CDS, SPEAKING_CDS, SINGING_CDS, EXAM_CDS, PORTFOLIOS]

export const OTHER_MATERIAL_RETURN_MAPPING = {
  [LISTENING_CDS]: 'masterListeningCdsReturned',
  [SPEAKING_CDS]: 'masterSpeakingCdsReturned',
  [SINGING_CDS]: 'masterSightSingingCdsReturned',
  [EXAM_CDS]: 'examCdsReturned',
  [PORTFOLIOS]: 'portfoliosReturned',
}

export const PACKING_LIST_LABEL_MAPPING = {
  [EXAM_MATERIAL]: 'Materials Returning to College Board',
  [EXAM]: 'Exams',
  [LISTENING_CDS]: 'Master Listening CDs',
  [SPEAKING_CDS]: 'Master Persuasive Essay/Speaking 2-Disc CDs',
  [SINGING_CDS]: 'Master Sight-Singing CDs',
  [EXAM_CDS]: 'Exam CDs',
  [PORTFOLIOS]: 'Portfolios',
  [STUDENTS]: 'Students',
  [PORTFOLIO_SUBMISSION]: 'Confirm Portfolio Submission',
  [ONLINE_SUBMISSION]: 'Confirm Online Submission',
  [RESPONSES_RETURNED]: 'How were these responses returned?',
  [SSD_MATERIALS_SUBMISSION]: 'Confirm Special Exam Materials Submission',
}

export const PACKING_LIST_CTA_MAPPING = {
  [EXAM_MATERIAL]: 'Edit Material Quantities',
  [EXAM]: 'Edit Unused Exams',
  [LISTENING_CDS]: 'Edit Quantities',
  [SPEAKING_CDS]: 'Edit Quantities',
  [SINGING_CDS]: 'Edit Quantities',
  [EXAM_CDS]: 'Edit Quantities',
  [PORTFOLIOS]: 'Edit Quantities',
}

export const SUBMISSIONS = {
  [PORTFOLIO_SUBMISSION]: 'Confirm Portfolio Submission',
  [ONLINE_SUBMISSION]: 'These have been submitted via the DAS Portal.',
  [SSD_MATERIALS_SUBMISSION]:
    'All special exam materials (test booklets, used answer sheets, free-response booklets, etc.) have been packed and will be shipped in this return shipment.',
}

export const CHANGE_PACKING_LIST_ITEMS_FORM = 'PackingListQuantityDiscrepanciesForm'
export const CHANGE_PACKING_LIST_ITEMS_MODAL = 'ChangePackingListItemsModal'

// Following items will be returned by an API in the following stories post deployment.
export const RETURNED_ITEMS = [PORTFOLIOS, LISTENING_CDS, SPEAKING_CDS, EXAM_CDS]

export const REASON_FOR_DISCREPANCY_OPTIONS = [
  { value: 'Shipment Overage', label: 'Shipment Overage' },
  { value: 'Shipment Shortage', label: 'Shipment Shortage' },
  { value: 'Defective Material', label: 'Defective Material' },
  { value: 'Reader Copy', label: 'Reader Copy' },
  { value: 'Large Print', label: 'Large Print' },
]

export const RETURNED_RESPONSE = ['Internet', 'USB Drive', 'Both', 'Other']

export const PORTFOLIO_LABEL_MAPPING = {
  95: 'Confirm Performance Task and Score Submission',
  94: 'Confirm Performance Task and Score Submission',
  103: 'Confirm Performance Task Submission',
}

export const PORTFOLIO_DESCRIPTION_MAPPING = {
  3: 'These have been submitted via Studio Art Submission.',
  4: 'These have been submitted via Studio Art Submission.',
  5: 'These have been submitted via the AP Studio Art Digital Submission Application.',
  95: 'Students have submitted performance tasks as final and teachers have submitted scores via the Digital Portfolio.',
  94: 'Students have submitted performance tasks as final and teachers have submitted scores via the Digital Portfolio.',
  103: 'Students have submitted performance tasks as final via the Digital Portfolio.',
}

export const SHIPPEDKEY = 'Shipped'
export const UNUSEDKEY = 'Unused'
export const RETURNCDSKEY = 'ReturnedCDs'
export const ANSWERSHEETKEY = 'AnswerSheets'
export const FREERESPONSEKEY = 'FreeResponse'
export const RESPONSEBOOKSKEY = 'ResponseBooks'
export const MULTICHOICEKEY = 'MultipleChoice'
export const SHORTKEY = 'ShortAnswer'
export const ORANGEKEY = 'Orange'
export const CJCDSKEY = 'CJExamCDs'

export const MATERIALS = {
  TITLES: {
    Exams: 'Exams',
    ReturningMaterials: 'Materials Returning To College Board',
  },
  ITEMS: {
    [SHIPPEDKEY]: 'Shipped',
    [UNUSEDKEY]: 'Unused',
    [RETURNCDSKEY]: 'Returned CDs',
    [ANSWERSHEETKEY]: 'Answer Sheets',
    [FREERESPONSEKEY]: 'Free Response Booklets',
    [RESPONSEBOOKSKEY]: 'Response Books',
    [MULTICHOICEKEY]: 'Multiple Choice Booklets',
    [SHORTKEY]: 'Short Answer Booklets',
    [ORANGEKEY]: 'Orange Booklets',
    [CJCDSKEY]: 'Chinese/Japanese Exam CDs',
  },
}
