import { reduxForm, getFormValues, SubmissionError, clearSubmitErrors } from 'redux-form'
import {
  SELECT_SSD_MATERIALS_FORM,
  SSD_MATERIALS_FIELDS,
  SSD_MATERIALS_FORM_DEFAULTS,
  UPDATE_TYPE_SSD,
} from '../../constants/StudentConstants'
import {
  SpecialFormats,
  ReaderMaterials,
  TestingTime,
  OtherAccommodations,
  NoSSDMaterials,
} from '../../components/students/ssdmaterials'
import { isEmpty, removeAttributesFromObject } from '../../utils/common'
import { updateStudentExamMaterials } from '../../actions/studentsByOrg'
import { Error } from '../../components/common'

const SelectSSDMaterialsToBeOrderedForm = props => {
  const { metaData, allowedSSDMaterialCodes, handleSubmit, error, selectedSSDMaterials } = props

  return (
    <form onSubmit={handleSubmit}>
      {error && <Error message={error} />}

      <SpecialFormats
        metaData={metaData}
        allowedSSDMaterialCodes={allowedSSDMaterialCodes}
        selectedSSDMaterials={selectedSSDMaterials}
      />

      <ReaderMaterials
        metaData={metaData}
        allowedSSDMaterialCodes={allowedSSDMaterialCodes}
        selectedSSDMaterials={selectedSSDMaterials}
      />

      <TestingTime
        metaData={metaData}
        allowedSSDMaterialCodes={allowedSSDMaterialCodes}
        selectedSSDMaterials={selectedSSDMaterials}
      />

      <OtherAccommodations
        allowedSSDMaterialCodes={allowedSSDMaterialCodes}
        selectedSSDMaterials={selectedSSDMaterials}
      />

      <NoSSDMaterials
        metaData={metaData}
        allowedSSDMaterialCodes={allowedSSDMaterialCodes}
        selectedSSDMaterials={selectedSSDMaterials}
      />
    </form>
  )
}

const mapStateToProps = (state, { exam }) => {
  /*********************************/
  // Used only to set the initial state of the SSD Materials controls
  const ssdMaterials = exam.ssdMaterials || SSD_MATERIALS_FORM_DEFAULTS
  // For fontSize 14pt, 20pt, custom, we do not save large block answer sheet value,
  // instead, we programmatically add it in the UI when one of those three font sizes
  // has been selected.
  if (
    ssdMaterials[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS['14point'].value ||
    ssdMaterials[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS['20point'].value ||
    ssdMaterials[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS.customFontSize.value
  ) {
    ssdMaterials[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name] = true
  }
  // For Braille, Reader Copy will automatically included in the order, so it is not saved. Instead,
  // we programmatically add it in the UI here.
  if (ssdMaterials[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS.braille.value) {
    ssdMaterials[SSD_MATERIALS_FIELDS.readerCopy.name] = true
  }

  /*********************************/
  const { metaData, allowedTestSSDMaterialCodes } = state.settingsSSDMaterials
  return {
    exam,
    metaData,
    allowedSSDMaterialCodes: allowedTestSSDMaterialCodes[exam.testCd].allowedSSDMaterialCodes,
    selectedSSDMaterials: getFormValues(SELECT_SSD_MATERIALS_FORM)(state) || {},
    initialValues: {
      specialFormatsCheck: Boolean(
        ssdMaterials[SSD_MATERIALS_FIELDS.fontSize.name] ||
          ssdMaterials[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name]
      ),
      readerMaterialCheck: Boolean(
        ssdMaterials[SSD_MATERIALS_FIELDS.readerCopy.name] ||
          ssdMaterials[SSD_MATERIALS_FIELDS.printedScript.name]
      ),
      testingTimeCheck: Boolean(ssdMaterials[SSD_MATERIALS_FIELDS.multipleDayTesting.name]),
      otherAccommodationsCheck: Boolean(
        ssdMaterials[SSD_MATERIALS_FIELDS.otherAccommodations.name]
      ),
      [SSD_MATERIALS_FIELDS.fontSize.name]: ssdMaterials[SSD_MATERIALS_FIELDS.fontSize.name],
      [SSD_MATERIALS_FIELDS.customFontSizeNumber.name]:
        ssdMaterials[SSD_MATERIALS_FIELDS.customFontSizeNumber.name],
      [SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name]:
        ssdMaterials[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name],
      [SSD_MATERIALS_FIELDS.readerCopy.name]: ssdMaterials[SSD_MATERIALS_FIELDS.readerCopy.name],
      [SSD_MATERIALS_FIELDS.printedScript.name]:
        ssdMaterials[SSD_MATERIALS_FIELDS.printedScript.name],
      [SSD_MATERIALS_FIELDS.multipleDayTesting.name]:
        ssdMaterials[SSD_MATERIALS_FIELDS.multipleDayTesting.name],
      [SSD_MATERIALS_FIELDS.otherAccommodations.name]:
        ssdMaterials[SSD_MATERIALS_FIELDS.otherAccommodations.name],
      [SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name]:
        ssdMaterials[SSD_MATERIALS_FIELDS.ssdMaterialsNotOrdered.name],
    },
  }
}

const validateForm = values => {
  const errors = {}
  if (
    values[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS.customFontSize.value &&
    (values[SSD_MATERIALS_FIELDS.customFontSizeNumber.name] === null ||
      values[SSD_MATERIALS_FIELDS.customFontSizeNumber.name] === '')
  ) {
    errors[SSD_MATERIALS_FIELDS.customFontSizeNumber.name] = 'Font size is required'
  }
  return errors
}

export default connect(
  mapStateToProps,
  { updateStudentExamMaterials, clearSubmitErrors }
)(
  reduxForm({
    form: SELECT_SSD_MATERIALS_FORM,
    onSubmit: (values, dispatch, props) => {
      const { exam, updateStudentExamMaterials } = props

      if (
        values.specialFormatsCheck &&
        (values[SSD_MATERIALS_FIELDS.fontSize.name] === null &&
          values[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name] === false)
      ) {
        throw new SubmissionError({
          specialFormatsCheck: 'A Special Formats option must be selected',
          _error: 'Checked groups must have at least one selection',
        })
      }
      if (
        values.readerMaterialCheck &&
        values[SSD_MATERIALS_FIELDS.readerCopy.name] === false &&
        values[SSD_MATERIALS_FIELDS.printedScript.name] === false
      ) {
        throw new SubmissionError({
          readerMaterialCheck: 'A Reader Material option must be selected',
          _error: 'Checked groups must have at least one selection',
        })
      }
      if (
        values.testingTimeCheck &&
        values[SSD_MATERIALS_FIELDS.multipleDayTesting.name] === false
      ) {
        throw new SubmissionError({
          testingTimeCheck: 'A Multiple-Day option must be selected',
          _error: 'Checked groups must have at least one selection',
        })
      }
      if (
        values.otherAccommodationsCheck &&
        isEmpty(values[SSD_MATERIALS_FIELDS.otherAccommodations.name])
      ) {
        throw new SubmissionError({
          otherAccommodationsCheck: 'Other Materials must be provided',
          _error: 'Checked groups must have at least one selection',
        })
      }

      // For fontSize 14pt, 20pt, custom, we do not send the large block answer sheet value
      // to the back-end, so it must be set to false here, before sending.
      if (
        values.specialFormatsCheck &&
        (values[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS['14point'].value ||
          values[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS['20point'].value ||
          values[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS.customFontSize.value)
      ) {
        values[SSD_MATERIALS_FIELDS.largeBlockAnswerSheet.name] = false
      }

      // For Braille, Reader Copy will automatically be included, so do not include it here
      if (
        values.specialFormatsCheck &&
        values[SSD_MATERIALS_FIELDS.fontSize.name] === SSD_MATERIALS_FIELDS.braille.value
      ) {
        values[SSD_MATERIALS_FIELDS.readerCopy.name] = false
      }

      const unusedSSDAttributes = [
        'specialFormatsCheck',
        'readerMaterialCheck',
        'testingTimeCheck',
        'otherAccommodationsCheck',
      ]
      const processedValues = removeAttributesFromObject(values, unusedSSDAttributes)
      return updateStudentExamMaterials(exam, UPDATE_TYPE_SSD, processedValues)
    },
    onChange: (values, dispatch, props) => {
      if (props.error || !isEmpty(props.submitErrors)) {
        props.clearSubmitErrors(SELECT_SSD_MATERIALS_FORM)
      }
    },
    validate: validateForm,
  })(SelectSSDMaterialsToBeOrderedForm)
)
