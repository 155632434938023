import { CODE_TYPES } from '../../../constants/SectionConstants'
import { convertArrayToString } from '../../../utils/common'
import {
  updateEnrollmentSubsidies,
  updateRegistrationSubsidy,
} from '../../../actions/studentsByOrg'
import { isExamOnly } from '../../../selectors/section'
import { isAPCourse, isCareerKickstartCourse } from '../../../selectors/course'
import StudentDetailsSSDMaterialsSection from './StudentDetailsSSDMaterialsSection'
import StudentDetailsSpecialDigitalFormatsSection from './StudentDetailsSpecialDigitalFormatsSection'
import StudentDetailsSectionCost from './StudentDetailsSectionCost'
import StudentNotTakingExamReason from './StudentNotTakingExamReason'
import { BootstrapLabel, BasicInput, Loader } from '../../common'
import TakingExamDropdown from './updatedropdowns/TakingExamDropdown'
import TestDateDropdown from './updatedropdowns/TestDateDropdown'
import { DIGITAL_EXAM, HYBRID_EXAM, PAPER_EXAM } from '../../../constants/SettingsConstants'
import {
  EXCLUDE_SUBSIDIES,
  INCLUDE_SUBSIDIES,
  UPDATE_TYPE_ENROLLMENT_SUBSIDIES,
  EXCLUDE_FROM_SUBSIDIES,
  INCLUDE_IN_SUBSIDIES,
  UPDATE_TYPE_FEE,
  MANUALLY_APPLIED,
  UPDATE_TYPE_REGISTRATION_SUBSIDY,
} from '../../../constants/StudentConstants'

import styles from '../../../assets/style/scss/student-details.scss'
import { isFeatureDisabled } from '../../../selectors/features'

const mapStateToProps = (state, { exam }) => {
  const {
    app: { breakpoint },
    settingsEducationPeriod: { selectedEducationPeriod, current },
    user: {
      data: { isLevelTwo, selectedOrgId },
    },
    studentsByOrg: { courseMap, sectionMap, teacherMap, update },
    settingsSpecialDigitalFormats,
  } = state
  const section = sectionMap[exam.sectionId]
  const course = courseMap[exam.testCd]
  const { type, id, updating, error } = update
  const updatingSubsidyOption =
    type === UPDATE_TYPE_ENROLLMENT_SUBSIDIES &&
    updating &&
    Array.isArray(id) &&
    id.includes(exam?.enrollmentId)
  const updatingRegSubsidyItem = type === UPDATE_TYPE_REGISTRATION_SUBSIDY ? id : null
  return {
    exam,
    isDigital: exam.examFormat === DIGITAL_EXAM,
    isHybrid: exam.examFormat === HYBRID_EXAM,
    isPaper: exam.examFormat === PAPER_EXAM,
    hideSsd:
      (exam.examFormat === DIGITAL_EXAM || exam.examFormat === HYBRID_EXAM) &&
      selectedEducationPeriod === current &&
      isFeatureDisabled(state, 'SSD_FORMAT_LINKS'),
    showCost: (isAPCourse(course) || isCareerKickstartCourse(course)) && isLevelTwo,
    hideIncludeExcludeCheckboxes: isCareerKickstartCourse(course),
    breakpoint: breakpoint.name,
    section,
    courseName: course.name,
    teachers: convertArrayToString(
      section.teacherIds.map(id => teacherMap[id].name),
      ', ',
      ' and '
    ),
    updatingSubsidyOption,
    orgId: selectedOrgId,
    period: selectedEducationPeriod,
    updatingFeeStatus: type === UPDATE_TYPE_FEE && updating,
    updatingRegSubsidyItem,
    settingsSpecialDigitalFormats,
  }
}

const EnrollmentStatusIndicator = ({ reasonInactive, enrollReason }) => {
  let text = null
  let body = null
  if (reasonInactive) {
    switch (reasonInactive) {
      case 'T':
        text = 'Transferred OUT'
        body =
          'This student has transferred out of your school and is no longer enrolled in this course. To remove any additional enrollments for the student based on this transfer, use the Transfer Out action on the Students tab.'
        break
      case 'D':
        text = 'Dropped'
        body = 'The student has been dropped from this course in the system.'
        break
      case 'M':
        text = 'Merged'
        body =
          'Two accounts for the student have been consolidated. This account is no longer active.'
        break
      default:
        break
    }
  } else if (enrollReason === CODE_TYPES.transfer.reason) {
    text = 'Transferred In'
    body = 'This student has transferred into your school and will not incur late ordering fees.'
  }

  return text && body ? <BootstrapLabel text={text} tooltip={{ body }} /> : null
}

const IncludeExcludeCheckbox = ({
  label,
  enrollmentId,
  checked,
  studentId,
  orgId,
  period,
  action,
  updating,
  style = {},
  actionFn,
  disabled,
}) => {
  const [clicked, doClick] = useState(false)
  const checkRef = useRef(null)

  useEffect(() => {
    if (clicked && !updating) {
      checkRef.current?.focus()
      doClick(false)
    }
  }, [updating, clicked])

  const handleClick = () => doClick(true)

  if (updating)
    return (
      <div className="checkbox" style={style}>
        <Loader size="sm" style={{ display: 'inline-block', marginRight: '6px' }} />{' '}
        <strong>{label}</strong>
      </div>
    )

  return (
    <BasicInput
      type="checkbox"
      input={{
        name: `${action}_${enrollmentId}`,
        value: enrollmentId,
        checked,
        onChange: e =>
          actionFn({
            studentId,
            orgId,
            period,
            enrollmentIds: [enrollmentId],
            action,
            value: e.target.checked,
          }),
        onClick: handleClick,
        'aria-describedby': `coursename_${enrollmentId}`,
      }}
      label={label}
      disabled={updating || disabled}
      style={style}
      ref={checkRef}
    />
  )
}

const RegistrationSubsidyCheckbox = ({
  label,
  studentId,
  subsidyId,
  enrollmentId,
  manuallyApplied,
  style = {},
  locked,
  updatingSubsidyOption,
  excludeSubsidy,
  updatingRegSubsidyItem,
  actionFn,
}) => {
  const [clicked, doClick] = useState(false)
  const checkRef = useRef(null)
  const updatingRegSubsidy = updatingRegSubsidyItem === `${subsidyId}_${enrollmentId}`

  useEffect(() => {
    if (clicked && !updatingRegSubsidy && !updatingSubsidyOption) {
      checkRef.current?.focus()
      doClick(false)
    }
  }, [updatingRegSubsidy, updatingSubsidyOption, clicked])

  const handleClick = () => doClick(true)

  if (updatingSubsidyOption || updatingRegSubsidy)
    return (
      <div className="checkbox" style={style}>
        <Loader size="sm" style={{ display: 'inline-block', marginRight: '6px' }} />{' '}
        <strong>{label}</strong>
      </div>
    )

  return (
    <BasicInput
      type="checkbox"
      input={{
        name: `${subsidyId}_${enrollmentId}`,
        value: enrollmentId,
        checked: manuallyApplied,
        onChange: e =>
          actionFn({
            studentId,
            subsidyId,
            enrollmentId,
            action: e.target.checked,
          }),
        onClick: handleClick,
        'aria-describedby': `manualsubsidy_${subsidyId}_${enrollmentId}`,
      }}
      label={label}
      disabled={locked || updatingRegSubsidy || updatingSubsidyOption || excludeSubsidy}
      style={style}
      ref={checkRef}
    />
  )
}

const StudentDetailsSection = ({
  exam,
  hideSsd,
  isDigital,
  isHybrid,
  isPaper,
  teachers,
  courseName,
  section: { name, type },
  index,
  showCost,
  breakpoint,
  updatingSubsidyOption,
  updatingRegSubsidyItem,
  settingsSpecialDigitalFormats,
  orgId,
  period,
  hasDigitalExams,
  updatingFeeStatus,
  updateEnrollmentSubsidies,
  updateRegistrationSubsidy,
  hideIncludeExcludeCheckboxes,
}) => {
  const {
    studentId,
    reasonInactive,
    enrollReason,
    specialDigitalFormats = {},
    enrollmentId,
    [EXCLUDE_FROM_SUBSIDIES]: excludeSubsidy = false,
    [INCLUDE_IN_SUBSIDIES]: includeSubsidy = false,
  } = exam
  const hasSpecialDigitalFormats = Object.keys(specialDigitalFormats).length > 0
  return (
    <div
      className={`panel ${styles['section-panel']} ${
        reasonInactive ? styles['has-indicator'] : ''
      }`}
      key={index}
    >
      <div className="panel-heading" style={{ padding: '10px 15px' }}>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h4 className="panel-title" id={`coursename_${enrollmentId}`}>
              {courseName}
            </h4>
          </div>
          {!hideIncludeExcludeCheckboxes && showCost && (
            <div className="col-md-6 col-sm-12">
              <div className="cb-lg-align-right cb-md-align-right cb-sm-align-left cb-xs-align-left">
                {isExamOnly(type) ? (
                  <IncludeExcludeCheckbox
                    label="Include in Subsidies"
                    enrollmentId={enrollmentId}
                    checked={includeSubsidy}
                    studentId={studentId}
                    orgId={orgId}
                    period={period}
                    action={INCLUDE_SUBSIDIES}
                    updating={updatingSubsidyOption}
                    style={{
                      display: 'inline-block',
                    }}
                    actionFn={updateEnrollmentSubsidies}
                    disabled={updatingFeeStatus}
                  />
                ) : null}
                <IncludeExcludeCheckbox
                  label="Exclude from Subsidies"
                  enrollmentId={enrollmentId}
                  checked={excludeSubsidy}
                  studentId={studentId}
                  orgId={orgId}
                  period={period}
                  action={EXCLUDE_SUBSIDIES}
                  updating={updatingSubsidyOption}
                  style={{ display: 'inline-block', marginLeft: '15px' }}
                  actionFn={updateEnrollmentSubsidies}
                  disabled={updatingFeeStatus}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-xs-12">
            <div className={styles.description}>
              <div className={styles['section-name-and-teachers']}>
                {name} {isExamOnly(type) ? <em>(Exam Only)</em> : `with ${teachers}`}
                <EnrollmentStatusIndicator
                  reasonInactive={reasonInactive}
                  enrollReason={enrollReason}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-sm-2 col-xs-12">
            <div className={`${styles['taking-exam-container']} ${breakpoint}`}>
              <TakingExamDropdown
                exam={exam}
                showLabel={true}
                dropdownOptions={{ ariaLabelledBy: `examIntent_${exam.examId}` }}
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-4 col-xs-12">
            <div className={styles['exam-date-container']}>
              <TestDateDropdown
                exam={exam}
                studentId={studentId}
                showLabel={true}
                showLateReason={true}
                showTestLocation={hasDigitalExams}
                dropdownOptions={{ ariaLabelledBy: `testDate_${exam.examId}` }}
              />
            </div>
          </div>
          {showCost ? <StudentDetailsSectionCost {...exam} /> : null}
          {!hideSsd ? (
            (isDigital || isHybrid) &&
            hasSpecialDigitalFormats &&
            settingsSpecialDigitalFormats.fetched ? (
              <StudentDetailsSpecialDigitalFormatsSection exam={exam} />
            ) : isPaper ? (
              <StudentDetailsSSDMaterialsSection exam={exam} />
            ) : null
          ) : null}
        </div>
        <StudentNotTakingExamReason exam={exam} />
      </div>
      {showCost && exam?.registrationSubsidies?.length ? (
        <div className="panel-heading" style={{ padding: '10px 15px' }}>
          <div className="cb-lg-align-right cb-md-align-right cb-sm-align-left cb-xs-align-left">
            {exam.registrationSubsidies.map(subsidy => (
              <div className="row" key={`${subsidy.subsidyId}_${enrollmentId}`}>
                <div className="col-md-12 col-sm-12">
                  <RegistrationSubsidyCheckbox
                    label={subsidy.subsidyName}
                    studentId={studentId}
                    subsidyId={subsidy.subsidyId}
                    enrollmentId={enrollmentId}
                    manuallyApplied={subsidy[MANUALLY_APPLIED]}
                    style={{ display: 'inline-block', marginLeft: '15px' }}
                    locked={subsidy.locked}
                    updatingSubsidyOption={updatingSubsidyOption}
                    excludeSubsidy={excludeSubsidy}
                    updatingRegSubsidyItem={updatingRegSubsidyItem}
                    actionFn={updateRegistrationSubsidy}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default connect(mapStateToProps, { updateEnrollmentSubsidies, updateRegistrationSubsidy })(
  StudentDetailsSection
)
