import { Redirect, Route } from 'react-router-dom'
import { NoAccessPage } from '../../containers'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = ({ user }) => ({ showPage: !user.hasAccess })

const NoAccessRoute = connect(mapStateToProps)(({ component: Component, showPage, ...rest }) => {
  return showPage ? (
    <Component {...rest} />
  ) : (
    <Redirect to={{ pathname: NAV_LINKS.redirect, from: NAV_LINKS.noaccess }} />
  )
})

export default () => (
  <Route
    path={NAV_LINKS.noaccess}
    render={props => <NoAccessRoute {...props} component={NoAccessPage} />}
  />
)
