import { Loader, Error } from '../../common'
import { fetchExamIntentCourses, saveExamIntentCourses } from '../../../actions/csr'
import { DEFAULT_REQUEST_ERROR } from '../../../constants/MessageConstants'
import { sortColumnByKey } from '../../../utils/sort'
import { LockUnlockComponent } from '.'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriod },
  } = state
  return {
    educationPeriod,
    orgId,
  }
}

export default connect(mapStateToProps)(({ educationPeriod, orgId }) => {
  const [courses, setCourses] = useState([])
  const [errorFetching, setErrorFetching] = useState(null)
  const [fetchingCourses, setFetchingCourses] = useState(true)

  const sortCourseData = courseData => ({
    data: sortColumnByKey(courseData, ['courseName'], ['asc']),
  })

  const prepareCourseData = () => sortCourseData(courses)

  const saveData = async itemsToSave => {
    const response = await saveExamIntentCourses(itemsToSave, orgId, educationPeriod)
    if (response?.data) {
      await setCourses(response.data ?? [])
      return sortCourseData(response.data)
    }
    return response
  }

  useEffect(() => {
    let isCurrent = true

    const fetchCourses = async () => {
      await setFetchingCourses(true)
      const { data = {}, error = null } = await fetchExamIntentCourses(orgId, educationPeriod)
      setCourses(data ?? [])
      setErrorFetching(error)
      setFetchingCourses(false)
    }
    if (isCurrent) {
      fetchCourses()
    }

    return () => {
      isCurrent = false
    }
  }, [])

  if (errorFetching) {
    return (
      <div style={{ padding: '15px' }}>
        <Error title="Error retrieving courses" message={DEFAULT_REQUEST_ERROR} />
      </div>
    )
  }

  if (fetchingCourses) {
    return <Loader />
  }

  return (
    <LockUnlockComponent
      compId="exceptionTestingCourses"
      introText="Select the courses to unlock for exam intent changes. Exam intent changes will be active for 24 hours."
      emptyText="There are no courses to order exams."
      fnFetch={prepareCourseData}
      fnSave={saveData}
      itemProps={{
        itemId: 'testCd',
        itemDescription: 'courseName',
      }}
    />
  )
})
