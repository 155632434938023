import { BasicInput } from '../../../../common'

const getLabels = isMulti => {
  if (isMulti) {
    return {
      questionLabel: 'Please indicate if the selected students:',
      hasStartedLabel: 'Have started exam',
      hasNotStartedLabel: 'Did not start exam',
    }
  }
  return {
    questionLabel: 'Please indicate if the:',
    hasStartedLabel: 'Student has started exam',
    hasNotStartedLabel: 'Student did not start exam',
  }
}

export default ({
  showHasStudentStartedExam,
  examStarted,
  updateHasStartedExam,
  isMulti = false,
}) => {
  const labels = getLabels(isMulti)
  if (showHasStudentStartedExam) {
    return (
      <div className="form-group" style={{ marginTop: '24px' }}>
        <label htmlFor="hasStartedExam">{labels.questionLabel}</label>
        <BasicInput
          type="radio"
          input={{
            name: 'hasStartedExam',
            value: true,
            onChange: () => updateHasStartedExam(true),
          }}
          label={labels.hasStartedLabel}
          checked={examStarted === true}
        />
        <BasicInput
          type="radio"
          input={{
            name: 'hasStartedExam',
            value: false,
            onChange: () => updateHasStartedExam(false),
          }}
          label={labels.hasNotStartedLabel}
          checked={examStarted === false}
        />
      </div>
    )
  }
  return null
}
