import { setNavLink, unsetNavLink } from '../../actions/nav'
import { fetchPackingListSummary } from '../../actions/packingList'
import PackingDetails from '../../components/packingList/PackingDetails'
import { Error, Loader } from '../../components/common'
import { getExamWindowRefData } from '../../selectors/examWindows'
import { isEmpty } from '../../utils/common'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    packingListSummary: { error, fetching, fetched },
  } = state

  return {
    orgId,
    educationPeriodCd,
    examWindowRefData: getExamWindowRefData(state),
    error,
    fetching,
    fetched,
  }
}

const PackingListPage = ({
  orgId,
  educationPeriodCd,
  examWindowRefData,
  fetching,
  error,
  fetched,
  fetchPackingListSummary,
  setNavLink,
  unsetNavLink,
}) => {
  useEffect(() => {
    setNavLink(NAV_LINKS.packingshipments, 'Packing List & Invoice')

    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    if (orgId && educationPeriodCd && !isEmpty(examWindowRefData)) {
      fetchPackingListSummary({ orgId, educationPeriodCd, examWindowRefData })
    }
  }, [orgId, educationPeriodCd, examWindowRefData])

  if (error)
    return (
      <div className="container" style={{ marginTop: 20 }}>
        <Error title="Error Retrieving Data" message={error} />
      </div>
    )

  if (fetching) return <Loader />

  if (fetched)
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <div className="container">
          <PackingDetails />
        </div>
      </CSSTransition>
    )

  return null
}

export default connect(mapStateToProps, {
  fetchPackingListSummary,
  setNavLink,
  unsetNavLink,
})(PackingListPage)
