import * as m from '.'

const mapStateToProps = (state, props) => ({ modal: state.app.modal || {} })

class Modal extends Component {

	shouldComponentUpdate(nextProps) {
		return !nextProps.modal.transitioning
	}

	render() {
		let { name, props } = this.props.modal
		let Component = m[name]

		return (
			<div className="modal-container">{Component ? <Component modalName={name} {...props} /> : null }</div>
		)
	}
}
export default connect(mapStateToProps)(Modal)
