import { useHistory } from 'react-router-dom'
import { Modal } from '../../components/common'
import { NAV_LINKS } from '../../constants/NavConstants'

/**
 * overrideShowModal is used when the multi-action being performed doesn't go through the usual
 * updateMultiSelect() action (which updates the studentsByOrg.multi state with the status
 * of the multi-action being performed).
 **/
const UpdateMultiSelectSuccessModal = connect(state => ({
  isSubmitted: state.status.data.isSubmitted,
  showModal: state.studentsByOrg.multi.updated,
}))(({ text, isSubmitted, modalCloseFocusElem, showModal, overrideShowModal, overrideTitle }) => {
  const history = useHistory()
  return showModal || overrideShowModal ? (
    <Modal
      headerTitle={
        overrideTitle ? overrideTitle : isSubmitted ? 'Change Order Updated' : 'Main Order Updated'
      }
      modalCloseFocusElem={modalCloseFocusElem}
      footerActions={[
        { buttonLabel: 'Close', isDismissable: true, isPrimary: false },
        ...(!overrideShowModal
          ? [
              {
                buttonLabel: 'View Order',
                isPrimary: true,
                isDismissable: true,
                onClick: () => history.push(NAV_LINKS.orders),
              },
            ]
          : []),
      ]}
    >
      {text}
    </Modal>
  ) : null
})

export default UpdateMultiSelectSuccessModal
