import { Redirect } from 'react-router-dom'
import { isDateTimePast } from '@myap/ui-library/esm/date'
import { Loader, Error } from '@myap/ui-library/esm/components'
import CoordinatorExamReadiness from '../../components/examReadiness/coordinator/CoordinatorExamReadiness'
import TeacherExamReadiness from '../../components/examReadiness/teacher/TeacherExamReadiness'
import { fetchExamReadiness, reprocessExamReadinessStatus } from '../../actions/examReadiness'
import { addFetchAction, removeFetchAction } from '../../actions/app'
import { useCatapultAuth, useMinutes } from '../../utils/hooks'
import { isAdministeringDigitalExams } from '../../selectors/settings'

import { NAV_LINKS } from '../../constants/NavConstants'

const MSG_REALTIME_DISABLED =
  'An error occurred. The table will not auto-update. Please refresh the page to correct the issue.'

const ExamStatusPage = ({
  isTeacher,
  isLevelTwo,
  addFetchAction,
  removeFetchAction,
  fetchExamReadiness,
  fetching,
  error,
  examRefreshAlarms = {},
  examObj = {},
  administeringDigitalExams,
  reprocessExamReadinessStatus,
}) => {
  const Component = isTeacher ? TeacherExamReadiness : CoordinatorExamReadiness
  const alarms = Object.values(examRefreshAlarms) || []
  const ellapsed = useMinutes(alarms.find(a => a.checkpoints.length))
  const { loading, error: catapultError } = useCatapultAuth()

  if (!isTeacher && !isLevelTwo) return <Redirect to={NAV_LINKS.dashboard} />

  useEffect(() => {
    if (administeringDigitalExams) {
      addFetchAction({
        name: 'fetchExamReadiness',
        action: (orgId, educationPeriodCd) => fetchExamReadiness(orgId, educationPeriodCd),
      })
    }
    return () => {
      if (administeringDigitalExams) removeFetchAction('fetchExamReadiness')
    }
  }, [])

  useEffect(() => {
    alarms.forEach(c => {
      const { checkpoints, alarmId, exams } = c
      const hasExpiredCheckpoint = checkpoints.find(c => isDateTimePast(c))
      if (hasExpiredCheckpoint) reprocessExamReadinessStatus(examObj, exams, alarmId, checkpoints)
    })
  }, [ellapsed])

  if (error) return <Error title="Error retrieving data" message={error} useWrapper={true} />

  if (loading || fetching) return <Loader />

  if (administeringDigitalExams) {
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <Component realTimeUpdatesDisabledMsg={catapultError ? MSG_REALTIME_DISABLED : null} />
      </CSSTransition>
    )
  }

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className="container" role="region" style={{ marginTop: 20 }}>
        <p>Digital exams are not offered during this academic year.</p>
      </div>
    </CSSTransition>
  )
}

export default connect(
  state => ({
    isTeacher: state.user.data.isTeacher,
    isLevelTwo: state.user.data.isLevelTwo,
    administeringDigitalExams: isAdministeringDigitalExams(state),
    ...state.examReadiness,
  }),
  { fetchExamReadiness, addFetchAction, removeFetchAction, reprocessExamReadinessStatus }
)(ExamStatusPage)
