import { reduxForm, formValueSelector } from 'redux-form'
import {
  ROSTER_MANAGEMENT_FORM,
  ROSTER_MANAGEMENT_FIELDS as FIELDS,
} from '../../constants/SetupConstants'
import { Input } from '../../components/common'
import { updateOrgSettings, changeStep } from '../../actions/settingsOrg'

const selector = formValueSelector(ROSTER_MANAGEMENT_FORM)

const normalize = val => val === 'true'

const mapStateToProps = state => {
  const { selectedOrgId } = state.user.data
  const orgSettings = state.settingsOrg[selectedOrgId] || { data: { rosterManagement: {} } }
  const initialValues = orgSettings.data.rosterManagement || {
    [FIELDS.TEACHER_MANAGEMENT.name]: true,
  }
  const selectedValues = {
    [FIELDS.TEACHER_MANAGEMENT.name]:
      selector(state, FIELDS.TEACHER_MANAGEMENT.name) ||
      initialValues[FIELDS.TEACHER_MANAGEMENT.name],
  }

  return {
    initialValues,
    selectedValues,
    orgSettings,
  }
}

const RosterManagementForm = ({ initialValues, selectedValues, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Input
      type="radio"
      name={FIELDS.TEACHER_MANAGEMENT.name}
      value={true}
      normalize={normalize}
      label={
        <>
          <strong>Enabled:</strong> Teacher Management of the Student Roster
        </>
      }
      ariaLabel="Enabled: Teacher Management of the Student Roster"
      isRequired={FIELDS.TEACHER_MANAGEMENT.required}
    />
    <Input
      type="radio"
      name={FIELDS.TEACHER_MANAGEMENT.name}
      style={{ marginTop: '20px', marginBottom: '20px' }}
      value={false}
      normalize={normalize}
      label={
        <>
          <strong>Disabled:</strong> Teacher Management of the Student Roster
        </>
      }
      ariaLabel="Disabled: Teacher Management of the Student Roster"
      isRequired={FIELDS.TEACHER_MANAGEMENT.required}
    />
  </form>
)

export default connect(mapStateToProps)(
  reduxForm({
    form: ROSTER_MANAGEMENT_FORM,
    enableReinitialize: true,
    onSubmit: (data, dispatch, props) => {
      // console.log(data)
      // console.log(props)
      return dispatch(
        updateOrgSettings({
          ...props.orgSettings.data,
          rosterManagement: {
            ...props.orgSettings.data.rosterManagement,
            ...data,
          },
        })
      )
    },
  })(RosterManagementForm)
)
