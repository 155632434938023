import * as c from '../constants/InvoiceConstants'
import template from './template'

export const invoices = (state, action) => template(state, action, c, 'INVOICES')
export const invoiceHistory = (state, action) => template(state, action, c, 'INVOICE_HISTORY')

const downloadInvoiceUrlInitialState = {
  fetching: false,
  fetched: false,
  error: null,
  url: null,
}

export const invoiceDownloadUrl = (state = downloadInvoiceUrlInitialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_INVOICE_DOWNLOAD_URL_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
        url: null,
      }
    case c.FETCH_INVOICE_DOWNLOAD_URL_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        url: payload,
        error: null,
      }
    case c.FETCH_INVOICE_DOWNLOAD_URL_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: payload,
        url: null,
      }

    case c.FLUSH_INVOICE_DOWNLOAD_URL:
      return downloadInvoiceUrlInitialState

    default:
      return state
  }
}
