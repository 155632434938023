import { reduxForm, submit, isInvalid, getFormValues, getFormError } from 'redux-form'
import {
  Error,
  Modal,
  PositiveNumberValidation,
  PositiveMaximumNumberValidation,
} from '../../components/common'
import { saveSurveyData } from '../../actions/survey'
import SurveyFormBuilder from '../../components/surveys/SurveyFormBuilder'
import {
  EDUCATIONPERIOD24_COURSE_SURVEY_FORM,
  // EDUCATIONPERIOD24_COURSE_SURVEY_SCHEMA,
  TYPE_MULTI_SELECT,
  TYPE_NUMERIC_RANGE,
} from '../../constants/SurveyConstants'
import { isEmpty } from '../../utils/common'
import { safeNumber } from '../../utils/numbers'

// const rawQuestions = EDUCATIONPERIOD24_COURSE_SURVEY_SCHEMA.questions

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    survey: { fetching, saving, saved, data: surveyData },
  } = state

  const currentSurvey = surveyData?.find(({ active }) => active) ?? {} // find first survey that is active

  const currentValues = getFormValues(EDUCATIONPERIOD24_COURSE_SURVEY_FORM)(state)
  const initialValues = {}
  const surveyQuestions = {}
  let sequence = 0
  if (Object.keys(currentSurvey).length > 0) {
    currentSurvey?.questions
      .filter(q => !q.pqid) // get top-level questions
      .forEach(topLevelQ => {
        sequence += 1 // objects don't have any inherent order, so provide a sequence to order by when rendering
        surveyQuestions[topLevelQ.qid] = { ...topLevelQ, sequence }
        currentSurvey?.questions
          .filter(childQ => childQ.pqid === topLevelQ.qid) // get child questions to this top-level question
          .filter(({ pResponse }) => pResponse === currentValues?.[topLevelQ.qid]?.value) // get child questions given response on top-level question
          .forEach(selectedChildQ => {
            sequence += 1 // add sequence
            surveyQuestions[selectedChildQ.qid] = { ...selectedChildQ, sequence }
          })
      })
  }

  // build initialValues
  Object.keys(surveyQuestions).forEach(qid => {
    if (surveyQuestions[qid]?.type === TYPE_MULTI_SELECT) {
      // multi-select fields create an array of elements where the name of the field starts with 'value|'
      // in this way, we can get the actual value of the field by splitting on the '|' character and
      // and taking the second element of the resulting array, and the field value, which is the state of
      // the checkbox itself
      initialValues[qid] = surveyQuestions[qid]?.options.map((opt, index) => ({
        [`value|${opt.value}`]: currentValues?.[qid]?.[index]?.[`value|${opt.value}`] ?? false,
        ...(surveyQuestions[qid]?.options?.[index]?.inline
          ? { text: currentValues?.[qid]?.[index]?.text ?? '' } // if field has an associated text field, that is added as a prop to the same object
          : {}),
      }))
    } else {
      // all other field types are simple key value pairs with the possible addition of a text
      // prop for radios that have associated text fields
      initialValues[qid] = {
        value: currentValues?.[qid]?.value ?? null,
        ...(!isEmpty(currentValues?.[qid]?.text) ? { text: currentValues?.[qid]?.text } : {}),
      }
    }
  })

  return {
    orgId: selectedOrgId,
    educationPeriodCd: selectedEducationPeriod,
    surveyData: currentSurvey,
    surveyQuestions,
    fetching,
    saving,
    initialValues,
    submitSucceeded: saved,
    currentValues,
    error: getFormError(EDUCATIONPERIOD24_COURSE_SURVEY_FORM)(state),
    invalid: isInvalid(EDUCATIONPERIOD24_COURSE_SURVEY_FORM)(state),
  }
}

const EducationPeriod24CourseSurveyModal = ({
  surveyData,
  surveyQuestions,
  invalid,
  saving,
  submitSucceeded,
  submitFailed,
  handleSubmit,
  error,
  submit,
}) => {
  const modalRef = useRef(null)
  const footerActions = [
    {
      buttonLabel: error ? 'Close' : 'Submit',
      isPrimary: true,
      busy: saving,
      isDisabled: (saving || invalid) && !submitFailed,
      onClick: () => !submitFailed && submit(EDUCATIONPERIOD24_COURSE_SURVEY_FORM),
      isDismissable: !isEmpty(error),
    },
  ]

  // If submit fails, an error will be displayed at the top of the form, scroll to top so error is visible
  if (submitFailed) {
    modalRef.current.scrollIntoView()
  }

  return (
    <Modal
      // headerTitle="Questions"
      shouldCloseModal={submitSucceeded}
      footerActions={footerActions}
      hideCloseButton={true}
      isDismissable={false}
    >
      <div ref={modalRef}>
        <p dangerouslySetInnerHTML={{ __html: `${surveyData?.description}` }} />
        {error ? <Error message={error} style={{ marginTop: '1em' }} /> : null}
        <form onSubmit={handleSubmit}>
          <SurveyFormBuilder surveyQuestions={surveyQuestions} />
        </form>
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps, { saveSurveyData, submit })(
  reduxForm({
    form: EDUCATIONPERIOD24_COURSE_SURVEY_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    validate: (values, { surveyQuestions }) => {
      const errors = {}
      Object.entries(values).forEach(([qid, value]) => {
        const { type, range, required } = surveyQuestions?.[qid] ?? {}
        // iterate through each question, check if question is required and if so, whether
        // there's a value and that it meets validation rules
        switch (type) {
          case TYPE_NUMERIC_RANGE:
            if (isEmpty(values[qid]?.value)) {
              errors[qid] = 'Error: Field is required'
            } else {
              const valueEntered = safeNumber(values[qid]?.value)
              const [minRange, maxRange ] = range?.split('-') ?? []
              // Ordered like this so a minimum number validation failure will display first
              const errorMax = {
                value: PositiveMaximumNumberValidation(
                  valueEntered,
                  {},
                  { [`maximum${qid}.value`]: parseInt(maxRange, 10) },
                  `${qid}.value`
                ),
              }
              const errorMin = {
                value: PositiveNumberValidation(
                  valueEntered,
                  {},
                  { [`minimum${qid}.value`]: parseInt(minRange, 10) },
                  `${qid}.value`
                ),
              }
              errors[qid] = errorMin?.value ? errorMin : errorMax;
            }
            break
        }
      })
      return errors
    },
    onSubmit: async (
      values,
      dispatch,
      { orgId, educationPeriodCd, surveyQuestions, surveyData, saveSurveyData }
    ) => {
      const saveData = {
        orgId,
        educationPeriodCd,
        survey: {
          surveyId: surveyData?.surveyId,
          surveyIndex: surveyData?.surveyIndex,
          questions: Object.keys(values).reduce((questions, qid) => {
            if (values[qid]?.value === null) {
              questions.push({ qid, response: [] })
            } else if (!isEmpty(values[qid])) {
              questions.push({
                qid,
                response: Array.isArray(values[qid]) // only multi-select fields will be an array
                  ? values[qid].reduce((acc, opt) => {
                      const retObj = {}
                      Object.entries(opt).forEach(([optKey, optVal]) => {
                        if (optVal) {
                          const qName = optKey.split('|') // array fields start with 'value|'
                          if (qName?.length === 2) {
                            retObj.value = qName[1]
                          } else if (qName?.length === 1 && qName[0] === 'text') {
                            retObj.text = optVal
                          }
                        }
                      })
                      if (Object.keys(retObj)?.length > 0) {
                        acc.push(retObj)
                      }
                      return acc
                    }, [])
                  : [{ ...values[qid] }],
              })
            }
            return questions
          }, []),
        },
      }
      // console.log('SURVEY:', saveData)
      await saveSurveyData({ orgId, educationPeriodCd, data: saveData })
    },
  })(EducationPeriod24CourseSurveyModal)
)
