import { Loader, Error } from '../../../common'
import { ActionBar } from '../'


import styles from '../../../../assets/style/scss/csr.scss'


export default ({ sections, selectedStudents, feeTypeLabel, error, saving, saveData, goBack }) => {
	const selectedLength = selectedStudents.length
	const sectionsToShow = sections.reduce((all, section) => {
		const updatedEnrollments = section.enrollments.reduce((all, e) => selectedStudents.find(id => e.enrollmentId === id) ? [...all, e] : all, [])
		return updatedEnrollments.length ? [...all, {...section, enrollments: updatedEnrollments}] : all
	}, [])
	
	return (		
		<>
			<div className={styles['tool-body-inner']}>
				<p className={styles['tool-body-item']}>{feeTypeLabel} will be removed for {selectedLength} student{selectedLength > 1 ? 's' : ''}</p>
				{ error ? <Error title="Error Saving Data" message={error} style={{ margin: 0, padding: 15 }} /> : null }
				{ sectionsToShow.map((s, i) => (
					<div key={i} className={styles['tool-body-item']}>
						<h5 className="roboto" style={{ marginTop: 20 }}>{s.name}</h5>
						<ul style={{ listStyle: 'none', padding: 0 }}>
							{s.enrollments.map((s, i) => (
								<li key={i} style={{ fontSize: '1.1em', marginBottom: 10 }}>
									<div>{s.lastName}, {s.firstName}</div>
									<div style={{fontSize: '.9em'}}>{s.studentId}</div>
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
			<ActionBar buttons={[ 
				{ title: 'Cancel', isPrimary: false, onClick: goBack },
				{ title: 'Remove Fee', disabled: saving, onClick: saveData } 
				]} />
		</>
	)

}