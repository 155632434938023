import { Modal, Error } from '../../components/common'
import { confirmSectionExamDecision } from '../../actions/section'
import { CONFIRM_SECTION_EXAM_DECISION_ERROR } from '../../constants/MessageConstants'

const mapStateToProps = (state, ownProps) => {
  let {sectionId, modalCloseFocusElem} = ownProps
  let {courses: {entities: {sections}}} = state
  let section = sections[sectionId]

  return {
    section,
    fetching: section.fetching,
    error: section.error,
    modalCloseFocusElem
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ confirmSectionExamDecision }, dispatch)

class ConfirmSectionExamDecisionModal extends Component {

  footerActions() {
    let {section, fetching, confirmSectionExamDecision} = this.props

    if(this.hasEnrollments)
      return [{buttonLabel: 'Close', isDismissable: true, isPrimary: true }]

    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      { buttonLabel: 'Confirm', isPrimary: true, onClick: () => confirmSectionExamDecision(section), isDisabled: fetching },
    ]
  }

  render() {
    let {section, error, modalCloseFocusElem, onCloseAction} = this.props

    return (
      <div>
        <Modal shouldCloseModal={section.teacherConfirmedExamDecisionsDate} modalCloseFocusElem={modalCloseFocusElem} onCloseAction={onCloseAction}
          headerTitle="Confirm Exam Decisions"
          footerActions={this.footerActions()}>
          <div>
            {/*error ? <Error message={error} /> : null */}
            <p>Are you sure you want to enable Student Decisions? You will not be able to undo your selection after you save it.</p>
          </div>
        </Modal>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSectionExamDecisionModal)

