import memoize from 'memoizee'
import { dollarDecimalFormat } from '../../utils/numbers'
import {
  STATE_SUBSIDY_CREDIT,
  DISTRICT_SUBSIDY_CREDIT,
  AP_FREE_RESPONSE_BOOKS,
  TYPE_DISTRICT,
  TYPE_REIMBURSE,
  TYPE_STATE,
  TYPE_DIRECT,
  TYPE_CENTRAL_BILL,
  QUANTITY_LINKS,
} from '../../constants/InvoiceConstants'

const SUPPRESS_QUANTITY = [AP_FREE_RESPONSE_BOOKS]

export const formatter = memoize(number => {
  const num = dollarDecimalFormat(Math.abs(number))
  return number < 0 ? `(${num})` : num
})

export const isDirty = memoize(
  ({ invoiceDirty, fees = [], credits = [] }) =>
    invoiceDirty || [...fees, ...credits].some(item => item.dirty)
)

export const isCentralBill = ({ invoiceType }) => invoiceType === TYPE_CENTRAL_BILL
export const isStateSubsidyCredit = ({ product }) => product === STATE_SUBSIDY_CREDIT
export const isDistrictSubsidyCredit = ({ product }) => product === DISTRICT_SUBSIDY_CREDIT
export const isSubsidyCredit = credit =>
  isStateSubsidyCredit(credit) || isDistrictSubsidyCredit(credit)

export const getDistrictReimbursementGrantor = ({ grantors = [] }) =>
  grantors.find(g => g.grantorType === TYPE_DISTRICT && g.billingType === TYPE_REIMBURSE)

export const getStateReimbursementGrantor = ({ grantors = [] }) =>
  grantors.find(g => g.grantorType === TYPE_STATE && g.billingType === TYPE_REIMBURSE)

export const getStateDirectGrantor = ({ grantors = [] }) =>
  grantors.find(g => g.grantorType === TYPE_STATE && g.billingType === TYPE_DIRECT)

export const getQuantityLink = item => {
  let link = QUANTITY_LINKS[item.product]
  if (link && isSubsidyCredit(item)) link = `${link}${encodeURIComponent(item.description)}`
  return link
}

export const suppressQuantity = item => SUPPRESS_QUANTITY.includes(item.product)

export const getReimbursements = ({ reimbursementCredits = [] }, type) => {
  const filter = type === TYPE_STATE.toLowerCase() ? isStateSubsidyCredit : isDistrictSubsidyCredit
  return reimbursementCredits.filter(r => filter(r))
}
