import { Link } from 'react-router-dom'


export default ({ linkText, linkRoute }) => {
  const urlRegex = /^(?:http(s))/g
  const external = urlRegex.test(linkRoute)

  if (!linkRoute)
    return null

  return (
    <div><span className="cb-glyph cb-left" /> {!external
      ? <Link to={linkRoute}>{linkText}</Link>
      : <a href={linkRoute}>{linkText}</a>}
    </div>
  )
}
