import { openModal } from '../../../actions/app'

function mapStateToProps(state, ownProps) {
  const { isLevelOne, isPreAPOnly } = state.user.data
  const { testCd } = ownProps
  const isCourseCreateMode = !testCd

  return {
    testCd,
    isCourseCreateMode,
    showButton: isCourseCreateMode ? !isLevelOne && !isPreAPOnly : true,
  }
}

const AddSectionButton = ({ testCd, isCourseCreateMode, showButton, styles, openModal }) => {
  const buttonRef = useRef()
  const applyStyles = { padding: '0', ...styles }

  if (showButton)
    return (
      <button
        type="button"
        className="btn-link cb-small-font-size"
        style={applyStyles}
        onClick={() =>
          openModal('CreateAndEditSectionModal', {
            courseTestCd: testCd,
            creating: true,
            isCourseCreateMode,
            modalCloseFocusElem: buttonRef.current,
          })
        }
        ref={buttonRef}
        aria-describedby={`course_${testCd}`}
      >
        <span aria-hidden="true" className="cb-glyph cb-plus" /> Add{' '}
        {isCourseCreateMode ? 'Course' : 'Section'}
      </button>
    )

  return null
}

export default connect(mapStateToProps, { openModal })(AddSectionButton)
