import { getFormValues } from 'redux-form'
import get from 'lodash-es/get'
import { manipulateDays, manipulateYear, formatDate } from '@myap/ui-library/esm/date'
import { Date as DateSelector } from '../../common'
import {
  SCHOOL_INFORMATION_FIELDS as FIELDS,
  SCHOOL_INFORMATION_FORM,
} from '../../../constants/SetupConstants'
import AverageCostPerExam from './AverageCostPerExam'
import SchoolGPAPolicy from './SchoolGPAPolicy'

import stylesheet from '../../../assets/style/scss/settings-form.scss'

const mapStateToProps = state => {
  const { selectedEducationPeriod, availableEducationPeriods } = state.settingsEducationPeriod
  const { isLevelOne } = state.user.data
  return {
    yearRange: availableEducationPeriods[selectedEducationPeriod]?.descr,
    academicYearStart: availableEducationPeriods[selectedEducationPeriod]?.academicYrStartDate,
    academicYearEnd: availableEducationPeriods[selectedEducationPeriod]?.academicYrEndDate,
    currentFormValues: getFormValues(SCHOOL_INFORMATION_FORM)(state) || {},
    isLevelOne,
  }
}

export default connect(mapStateToProps)(props => {
  const {
    hideTitle,
    isLevelOne,
    academicYearStart,
    academicYearEnd,
    yearRange,
    currentFormValues,
  } = props

  return (
    <fieldset>
      {!hideTitle ? (
        <>
          <legend className="h2" style={{ border: 0, paddingTop: 40 }}>
            School Info
          </legend>
          <p>
            {`Indicate the first and last days of class
              for your seniors in ${yearRange.replace('-20', '-')}.`}
          </p>
        </>
      ) : null}
      <div className="row">
        <div className="col-xs-6">
          <DateSelector
            name={FIELDS.START_DATE.name}
            isRequired={FIELDS.START_DATE.required}
            form={SCHOOL_INFORMATION_FORM}
            showLabel={true}
            labelClasses={stylesheet['item-header']}
            label={FIELDS.START_DATE.label}
            minDate={manipulateYear(academicYearStart, -1)}
            maxDate={
              get(currentFormValues, FIELDS.END_DATE.name)
                ? manipulateDays(get(currentFormValues, FIELDS.END_DATE.name), -1)
                : academicYearEnd
            }
            openToDate={
              get(currentFormValues, FIELDS.START_DATE.name) || formatDate(academicYearStart)
            }
          />
        </div>
        <div className="col-xs-6">
          <DateSelector
            name={FIELDS.END_DATE.name}
            isRequired={FIELDS.END_DATE.required}
            form={SCHOOL_INFORMATION_FORM}
            label={FIELDS.END_DATE.label}
            showLabel={true}
            labelClasses={stylesheet['item-header']}
            minDate={
              get(currentFormValues, FIELDS.START_DATE.name)
                ? manipulateDays(get(currentFormValues, FIELDS.START_DATE.name), 1)
                : academicYearStart
            }
            maxDate={manipulateYear(academicYearEnd, 1)}
            openToDate={get(currentFormValues, FIELDS.END_DATE.name) || formatDate(academicYearEnd)}
          />
        </div>
      </div>
      {!isLevelOne ? (
        <>
          <AverageCostPerExam />
          <SchoolGPAPolicy />
        </>
      ) : null}
    </fieldset>
  )
})
