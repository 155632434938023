import memoize from 'memoizee'
import { DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { DownloadToCSV, Tooltip } from '../../common'
import { getExamFilterTagData } from '../../../selectors/filters/examReadinessFilters'
import { getFilterTags } from '../../../selectors/filters/utils'
import { formatExamWindow } from '../../../selectors/examWindows'

const ROW_HEADERS = {
  studentName: 'Student Name',
  apId: 'AP ID',
  courseName: 'Course Name',
  examDateTime: 'Exam Date',
  appInstalledFormatted: 'Login',
  setupStatusFormatted: 'Setup',
  examStatusFormatted: 'Exam',
  makeupStatus: 'Makeup',
}
const KEYS = Object.keys(ROW_HEADERS)
const HEADER_ROW = KEYS.map(key => ({ label: '', key }))
const BLANK_ROW = KEYS.reduce((obj, key) => ({ ...obj, [key]: '' }), {})

const createMainHeaderRow = label => {
  const [FIRST_COLUMN, ...REMAINING_COLUMNS] = HEADER_ROW
  return [{ ...FIRST_COLUMN, label }, ...REMAINING_COLUMNS]
}

const createSubHeaderRow = label => ({ ...BLANK_ROW, [KEYS[0]]: label })

const mapStateToProps = state => {
  const { selectedRole, roles } = state.user.data
  return { ...state.examReadiness, orgName: roles[selectedRole]?.orgName }
}

const transformData = memoize(data =>
  data.map(d => ({
    ...d,
    studentName: `${d.lastName}, ${d.firstName}`,
    examDateTime: `${formatExamWindow(
      d.examDateTime,
      `${DATETIME_FORMATS.shortMonthDay} ${DATETIME_FORMATS.hour}`,
      d.examFormat,
      true
    )}`,
  }))
)

const DownloadCoordinatorExamReadiness = props => {
  const { visibleExams, activeFilters, orgName, courses, teacherMap, examWindowMap } = props
  const filterTags = getFilterTags(activeFilters, (filter, value) =>
    getExamFilterTagData({ courses, teacherMap, examWindowMap }, filter, value)
  ).map(tag => tag.label)
  const hasFilters = Boolean(filterTags.length)

  return (
    <div>
      <DownloadToCSV
        filename={`Digital Exam Readiness Roster - ${orgName} `}
        header={createMainHeaderRow(`Digital Exam Readiness Roster for ${orgName}`)}
        title={`${hasFilters ? 'Filtered ' : ''} Digital Exam Readiness Roster`}
        timestampHeaderKey="studentName"
        transformData={() => {
          const dataArr = [ROW_HEADERS, ...transformData(visibleExams)]
          if (hasFilters)
            dataArr.unshift(createSubHeaderRow(`Filtered by: ${filterTags.join(' | ')}`), {})
          return dataArr
        }}
        style={{ display: 'inline-block' }}
      />
      {hasFilters && (
        <Tooltip
          title="Filters are currently applied to the Digital Exam Readiness Roster. Clear all filters to download the complete roster."
          placement="top"
          container="body"
          label="More information: Roster download filtered information"
          style={{
            display: 'inline-block',
            verticalAlign: 'top',
            paddingLeft: '5px',
            fontSize: '.7em',
          }}
        >
          <i className="cb-glyph cb-glyph-circular cb-exclamation" />
        </Tooltip>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(DownloadCoordinatorExamReadiness)
