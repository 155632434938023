import { change, getFormValues } from 'redux-form'
import { SEMINAR } from '@myap/metadata'
import { Input, Tooltip } from '../../common'
import {
  SELECT_SPECIAL_DIGITAL_FORMATS_FORM,
  SDF_EXTENDED_TIME,
  SDF_MULTIDAY,
  SDF_ACCESSIBILITY,
  SDF_ADDITIONAL_ACCOMMODATIONS,
  SDF_PAPER_BASED_EXAM,
  SDF_FREE_RESPONSE_BOOKLET,
  PAPERFORMATS_PAPER,
  PAPERFORMATS_BRAILLE,
  PAPERFORMATS_CUSTOM_SIZE,
  PAPERFORMATS_14POINT,
  PAPERFORMATS_20POINT,
  PAPERFORMATS_LARGEPRINT_A,
  PAPERFORMATS_LARGEPRINT_B,
  MULTIDAY_ONLY2DAY,
  MULTIDAY_OVER2DAY,
  PAPERFORMATS_SORT_ORDER,
} from '../../../constants/StudentConstants'
import { sortColumnByKey } from '../../../utils/sort'
import { getAccommodationDescription } from '../../../selectors/section'

import styles from '../../../assets/style/scss/materials-modal.scss'

const ExtendedTimeDigitalMessaging = () => (
  <p>
    For digital exams, extended time will be applied at the part(s) or section(s) level, depending
    on what type of extended time the student has been approved for. If the student is approved for
    multiple types of extended time, the greatest amount of approved extended time applicable for
    the part or section will be provided.
  </p>
)

const ExtendedTimeHybridMessaging = () => (
  <p>
    For hybrid exams, extended time will be applied at the part(s) or section(s) level, depending on
    what type of extended time the student has been approved for. If the student is approved for
    multiple types of extended time, the greatest amount of approved extended time applicable for
    the part or section will be provided.
  </p>
)

const MultiDayMessaging = ({ hasOnlyOneOption, testCd }) => (
  <>
    <p>
      This student has approved accommodations that may make them eligible for multiple-day testing
      on this exam. Confirm with the SSD coordinator and the student whether multiple-day testing
      will be needed for this exam before submitting any update.{' '}
      <strong>
        If the student does need multiple-day testing for this exam, you need to select it here and
        update your exam order.
      </strong>
    </p>
    {hasOnlyOneOption ? (
      testCd === SEMINAR ? (
        <p>
          <strong>
            Note: Multiple-day testing is not supported digitally for{' '}
            <span style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}>Seminar</span>.
          </strong>{' '}
          If this student&#39;s accommodations require them to test over multiple days and
          "Multiple-Day Testing" is selected, the student will be provided with a paper-based exam.
        </p>
      ) : (
        <p>
          <strong>
            Note: Multiple-day testing for digital exams enables a student to test over{' '}
            <span style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}>2 days</span>.
          </strong>{' '}
          If this student&#39;s accommodations require them to test over more than 2 days, contact
          the College Board SSD office.
        </p>
      )
    ) : (
      <p>
        <strong>
          Note: Multiple-day testing for digital exams enables a student to test over{' '}
          <span style={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}>2 days</span>
        </strong>
        . If this student&#39;s accommodations require them to test over more than 2 days and
        &quot;More than 2 day Multiple-Day Testing&quot; is selected, the student will be provided
        with a paper-based exam.
      </p>
    )}
  </>
)

const AdditionalAccommodations = () => (
  <p>
    This student has approved accommodations that do not impact the digital test format, such as
    modified test settings and other accommodations. Confirm with the SSD coordinator and the
    student whether these accommodations will be needed on test day.
  </p>
)

const PaperBasedExamAccommodations = () => (
  <p>
    This student has approved accommodations that make them eligible for a paper-format of this
    exam. Confirm with the SSD Coordinator and the student whether they want to take the
    paper-format instead of the digital format of the exam.{' '}
    <strong>
      If the student does need a paper-format exam, you need to select it here and update your exam
      order.
    </strong>
  </p>
)

const LargePrintTestBooklet = () => (
  <p>
    This student has an approved accommodation for a large-print format exam and will receive a
    large-print test booklet that is appropriate for their accommodation. The student will write
    their responses in the printed booklet. Students who opt out of receiving a large-print test
    booklet will receive a standard free-response booklet.
  </p>
)

const TooltipInfo = ({ category, code }) => {
  if (category === SDF_ACCESSIBILITY) {
    // Human Reader
    if (code === '025') {
      const HUMANREADERMSG =
        'If a student approved for a human reader plans to take a digital exam using a screen reader, keep their accommodation for human reader selected so the student receives an accessible format of the digital exam.'
      return (
        <Tooltip
          title={HUMANREADERMSG}
          placement="top"
          container="body"
          label="More information: Human Reader"
          style={{ display: 'inline-block', marginLeft: '8px' }}
        >
          <span
            className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
            aria-hidden="true"
            data-alt={HUMANREADERMSG}
            style={{ fontSize: '8px' }}
          />
        </Tooltip>
      )
    }
    // Writer/scribe
    if (code === '034') {
      const WRITERSCRIBEMSG =
        'If a student approved for a writer/scribe plans to take a digital exam using voice recognition software, keep their accommodation for writer/scribe selected so the student receives an accessible format of the digital exam.'
      return (
        <Tooltip
          title={WRITERSCRIBEMSG}
          placement="top"
          container="body"
          label="More information: Writer/scribe"
          style={{ display: 'inline-block', marginLeft: '8px' }}
        >
          <span
            className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
            aria-hidden="true"
            data-alt={WRITERSCRIBEMSG}
            style={{ fontSize: '8px' }}
          />
        </Tooltip>
      )
    }
    // Pre-recorded audio
    if (code === '026') {
      const PRERECORDEDAUDIOMSG =
        'If a student approved for pre-recorded audio (MP3 via streaming) plans to take a digital exam using a screen reader, keep their accommodation for pre-recorded audio (MP3 via streaming) selected so the student receives an accessible format of the digital exam.'
      return (
        <Tooltip
          title={PRERECORDEDAUDIOMSG}
          placement="top"
          container="body"
          label="More information: Pre-recorded audio"
          style={{ display: 'inline-block', marginLeft: '8px' }}
        >
          <span
            className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
            aria-hidden="true"
            data-alt={PRERECORDEDAUDIOMSG}
            style={{ fontSize: '8px' }}
          />
        </Tooltip>
      )
    }
  }

  return null
}

const mapStateToProps = state => {
  const {
    settingsSpecialDigitalFormats: { extendedAccessibleSsdCodes = [] },
  } = state
  return {
    extendedAccessibleSsdCodes,
    selectedSpecialDigitalFormats: getFormValues(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state),
  }
}

export default connect(mapStateToProps, { change })(
  ({
    categoryTitle,
    categoryName,
    descriptions,
    accommodations,
    extendedAccessibleSsdCodes,
    selectedSpecialDigitalFormats,
    anyMultiDayEligibleSelected,
    isDigital,
    isHybrid,
    testCd,
    accommodationsInfo,
    change,
  }) => {
    const inlineBlock = { display: 'inline-block' }
    const groupId = `${categoryName}FormGroup`
    const accommodationCodes = Object.keys(accommodations)

    useEffect(() => {
      if (categoryName === SDF_MULTIDAY && !anyMultiDayEligibleSelected) {
        // If hiding multiday, also set it to false
        if (accommodations[MULTIDAY_ONLY2DAY] !== undefined) {
          change(SELECT_SPECIAL_DIGITAL_FORMATS_FORM, `${SDF_MULTIDAY}.${MULTIDAY_ONLY2DAY}`, false)
        }
        if (accommodations[MULTIDAY_OVER2DAY] !== undefined) {
          change(SELECT_SPECIAL_DIGITAL_FORMATS_FORM, `${SDF_MULTIDAY}.${MULTIDAY_OVER2DAY}`, false)
        }
      }
    }, [categoryName, anyMultiDayEligibleSelected])

    // Utility function to set a given field to a given value
    function toggleField(fieldName, value) {
      change(SELECT_SPECIAL_DIGITAL_FORMATS_FORM, fieldName, value)
    }

    if (accommodationCodes.length > 0) {
      const accommodationObjects = accommodationCodes.map(cd => ({
        code: cd,
        value: accommodations[cd],
        description: getAccommodationDescription({
          accommodationCode: cd,
          description: descriptions[categoryName][cd],
          accommodations: accommodationsInfo,
        }),
      }))
      // Specifically do not change the order of Paper-based Exam options
      const sortedAccommodations =
        categoryName === SDF_PAPER_BASED_EXAM
          ? accommodationObjects
              .slice()
              .sort(
                (a, b) =>
                  PAPERFORMATS_SORT_ORDER.indexOf(a.code) - PAPERFORMATS_SORT_ORDER.indexOf(b.code)
              )
          : sortColumnByKey(accommodationObjects, 'description', 'asc')

      // Utility function, if a particular code is found, set it to given value
      function toggleIfFound(category, code, value) {
        if (sortedAccommodations.some(item => item.code === code)) {
          toggleField(`${category}.${code}`, value)
        }
      }

      if (
        categoryName !== SDF_MULTIDAY ||
        (categoryName === SDF_MULTIDAY && anyMultiDayEligibleSelected)
      ) {
        return (
          <fieldset name={`${categoryName}-form-group`} className={styles['materials-fieldset']}>
            <legend id={groupId}>{categoryTitle}</legend>
            {categoryName === SDF_EXTENDED_TIME && isDigital ? (
              <ExtendedTimeDigitalMessaging />
            ) : categoryName === SDF_EXTENDED_TIME && isHybrid ? (
              <ExtendedTimeHybridMessaging />
            ) : null}
            {categoryName === SDF_MULTIDAY ? (
              <MultiDayMessaging
                hasOnlyOneOption={sortedAccommodations?.length === 1}
                testCd={testCd}
              />
            ) : null}
            {categoryName === SDF_ADDITIONAL_ACCOMMODATIONS ? <AdditionalAccommodations /> : null}
            {categoryName === SDF_PAPER_BASED_EXAM ? <PaperBasedExamAccommodations /> : null}
            {categoryName === SDF_FREE_RESPONSE_BOOKLET ? <LargePrintTestBooklet /> : null}
            <div role="group" aria-labelledby={groupId} className={styles['materials-radiogroup']}>
              {sortedAccommodations.map(({ code, value, description }) => {
                const disabled =
                  (categoryName === SDF_EXTENDED_TIME &&
                    extendedAccessibleSsdCodes.includes(code)) ||
                  (categoryName === SDF_PAPER_BASED_EXAM &&
                    (code === PAPERFORMATS_LARGEPRINT_A || code === PAPERFORMATS_LARGEPRINT_B) &&
                    selectedSpecialDigitalFormats[SDF_PAPER_BASED_EXAM][
                      PAPERFORMATS_CUSTOM_SIZE
                    ]) ||
                  (categoryName === SDF_PAPER_BASED_EXAM &&
                    (code === PAPERFORMATS_LARGEPRINT_A || code === PAPERFORMATS_LARGEPRINT_B) &&
                    selectedSpecialDigitalFormats[SDF_PAPER_BASED_EXAM][PAPERFORMATS_14POINT]) ||
                  (categoryName === SDF_PAPER_BASED_EXAM &&
                    (code === PAPERFORMATS_LARGEPRINT_A || code === PAPERFORMATS_LARGEPRINT_B) &&
                    selectedSpecialDigitalFormats[SDF_PAPER_BASED_EXAM][PAPERFORMATS_20POINT])
                // Indent all options under paperFormats category
                // slide(1) because the first item in the array is Paper, which should not be
                // indented
                const inputStyle =
                  categoryName === SDF_PAPER_BASED_EXAM &&
                  PAPERFORMATS_SORT_ORDER.slice(1).includes(code)
                    ? { marginLeft: '16px', ...inlineBlock }
                    : inlineBlock
                return (
                  <div
                    key={`${categoryName}_${code}`}
                    className={styles['material-radiogroup-wrap-full']}
                  >
                    <Input
                      type="checkbox"
                      name={`${categoryName}.${code}`}
                      style={inputStyle}
                      label={
                        <>
                          {description}
                          <TooltipInfo category={categoryName} code={code} />
                        </>
                      }
                      ariaLabel={description}
                      normalize={val => !!val}
                      disabled={disabled}
                      input={{
                        name: `${categoryName}.${code}`,
                        checked: accommodations[code],
                        value,
                        onChange: e => {
                          const val = e.target.value === 'true'
                          toggleField(`${categoryName}.${code}`, !val)
                          if (
                            categoryName === SDF_ACCESSIBILITY &&
                            extendedAccessibleSsdCodes.includes(code)
                          ) {
                            toggleField(`${SDF_EXTENDED_TIME}.${code}`, !val)
                          }
                          // Select top level Paper-format exam if any child items selected
                          if (
                            categoryName === SDF_PAPER_BASED_EXAM &&
                            PAPERFORMATS_SORT_ORDER.slice(1).includes(code) &&
                            !val
                          ) {
                            toggleField(`${SDF_PAPER_BASED_EXAM}.${PAPERFORMATS_PAPER}`, true)
                          }
                          // If top level Paper-format exam item is unchecked,
                          // deselect any child items
                          if (
                            categoryName === SDF_PAPER_BASED_EXAM &&
                            code === PAPERFORMATS_PAPER &&
                            val
                          ) {
                            PAPERFORMATS_SORT_ORDER.slice(1).forEach(paperFormatCode =>
                              toggleIfFound(SDF_PAPER_BASED_EXAM, paperFormatCode, false)
                            )
                          }
                          // If any of the large font size options are selected,
                          // also select the available large block answer sheet item
                          if (
                            categoryName === SDF_PAPER_BASED_EXAM &&
                            (code === PAPERFORMATS_14POINT ||
                              code === PAPERFORMATS_20POINT ||
                              code === PAPERFORMATS_CUSTOM_SIZE) &&
                            !val
                          ) {
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_LARGEPRINT_A, true)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_LARGEPRINT_B, true)
                          }
                          // Only one of Bralle, LPTB-14, LPTB-20, or LPTB-Other may be selected
                          // at a time
                          if (
                            categoryName === SDF_PAPER_BASED_EXAM &&
                            code === PAPERFORMATS_BRAILLE &&
                            !val
                          ) {
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_14POINT, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_20POINT, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_CUSTOM_SIZE, false)
                          }
                          if (
                            categoryName === SDF_PAPER_BASED_EXAM &&
                            code === PAPERFORMATS_14POINT &&
                            !val
                          ) {
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_BRAILLE, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_20POINT, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_CUSTOM_SIZE, false)
                          }
                          if (
                            categoryName === SDF_PAPER_BASED_EXAM &&
                            code === PAPERFORMATS_20POINT &&
                            !val
                          ) {
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_BRAILLE, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_14POINT, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_CUSTOM_SIZE, false)
                          }
                          if (
                            categoryName === SDF_PAPER_BASED_EXAM &&
                            code === PAPERFORMATS_CUSTOM_SIZE &&
                            !val
                          ) {
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_BRAILLE, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_14POINT, false)
                            toggleIfFound(SDF_PAPER_BASED_EXAM, PAPERFORMATS_20POINT, false)
                          }
                          // Only one of ONLY2DAY or OVER2DAY may be selected at a time
                          if (categoryName === SDF_MULTIDAY && code === MULTIDAY_ONLY2DAY && !val) {
                            toggleIfFound(SDF_MULTIDAY, MULTIDAY_OVER2DAY, false)
                          }
                          if (categoryName === SDF_MULTIDAY && code === MULTIDAY_OVER2DAY && !val) {
                            toggleIfFound(SDF_MULTIDAY, MULTIDAY_ONLY2DAY, false)
                          }
                        },
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </fieldset>
        )
      }
    }

    return null
  }
)
