import { submit, isDirty, isValid } from 'redux-form'
import { educationPeriodDateSpread } from '@myap/ui-library/esm/date'
import { Modal, Loader, CustomerServiceContactPhoneNumbers } from '../../components/common'
import { SELECT_SECURE_MATERIALS_FORM } from '../../constants/OrderConstants'
import {
  fetchMaxAllowedSecureMaterials,
  fetchSelectedSecureMaterials,
  fetchExamOrderSummary,
} from '../../actions/orders'
import SelectSecureMaterialsForm from '../forms/SelectSecureMaterialsForm'
import { isEmpty } from '../../utils/common'
import { getExamWindowRefData } from '../../selectors/examWindows'

const mapStateToProps = (state, ownProps) => {
  const { course, modalCloseFocusElem } = ownProps
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
    secureMaterialsForCourse: {
      fetching: fetchingSelectedSecureMaterials,
      fetched: fetchedSelectedSecureMaterials,
      updating: updatingSelectedSecureMaterials,
      updated: updatedSelectedSecureMaterials,
    },
    maxAllowedSecureMaterialsForCourse: {
      fetching: fetchingMaxAllowed,
      fetched: fetchedMaxAllowed,
      entities: maxAllowedEntities,
    },
  } = state
  const examWindowRefData = getExamWindowRefData(state)
  const maxAllowedItems =
    !isEmpty(maxAllowedEntities) && !isEmpty(maxAllowedEntities.items)
      ? maxAllowedEntities.items
      : {}
  const start = availableEducationPeriods[selectedEducationPeriod].academicYrStartDate
  const end = availableEducationPeriods[selectedEducationPeriod].academicYrEndDate

  return {
    course,
    modalCloseFocusElem,
    fetchingMaxAllowed,
    fetchedMaxAllowed,
    maxAllowedItems,
    selectedOrgId,
    selectedEducationPeriod,
    fetchingSelectedSecureMaterials,
    fetchedSelectedSecureMaterials,
    updatingSelectedSecureMaterials,
    updatedSelectedSecureMaterials,
    dirty: isDirty(SELECT_SECURE_MATERIALS_FORM)(state),
    valid: isValid(SELECT_SECURE_MATERIALS_FORM)(state),
    courseLocked:
      maxAllowedItems && Object.keys(maxAllowedItems).every(key => maxAllowedItems[key].locked),
    date: start && end ? educationPeriodDateSpread(start, end, '-') : null,
    examWindowRefData,
  }
}

class OrderSecureMaterialsModal extends Component {
  componentDidMount() {
    const { course, fetchMaxAllowedSecureMaterials, fetchSelectedSecureMaterials } = this.props

    fetchMaxAllowedSecureMaterials(course)
    fetchSelectedSecureMaterials(course)
  }

  footerActions() {
    const { updatingSelectedSecureMaterials, dirty, submit, valid } = this.props

    return [
      {
        buttonLabel: 'Cancel',
        isDismissable: true,
        isPrimary: false,
        isDisabled: updatingSelectedSecureMaterials,
      },
      {
        buttonLabel: 'Update',
        isPrimary: true,
        onClick: () => submit(SELECT_SECURE_MATERIALS_FORM),
        isDisabled: updatingSelectedSecureMaterials || !dirty || !valid,
        busy: updatingSelectedSecureMaterials,
      },
    ]
  }

  onCloseAction() {
    const {
      selectedOrgId,
      selectedEducationPeriod,
      updatedSelectedSecureMaterials,
      fetchExamOrderSummary,
    } = this.props

    if (updatedSelectedSecureMaterials)
      fetchExamOrderSummary(selectedOrgId, selectedEducationPeriod)
  }

  render() {
    const {
      course,
      course: { courseName, testDayType },
      fetchedMaxAllowed,
      fetchedSelectedSecureMaterials,
      updatedSelectedSecureMaterials,
      courseLocked,
      modalCloseFocusElem,
      date,
      examWindowRefData,
    } = this.props

    return (
      <Modal
        headerTitle={`CDs for ${courseName} (${examWindowRefData[testDayType]?.displayName})`}
        shouldCloseModal={updatedSelectedSecureMaterials}
        modalCloseFocusElem={modalCloseFocusElem}
        onCloseAction={this.onCloseAction.bind(this)}
        footerActions={this.footerActions()}
      >
        <p>
          The quantities of the CDs for this exam are listed below. The allowable quantity is based
          on the number of students taking the exam.
        </p>
        {courseLocked && (
          <p>
            If you need to order more than the quantity allowed below, contact AP Services for
            Educators at: <CustomerServiceContactPhoneNumbers />.
          </p>
        )}
        {fetchedMaxAllowed && fetchedSelectedSecureMaterials ? (
          <SelectSecureMaterialsForm course={course} />
        ) : (
          <Loader />
        )}
      </Modal>
    )
  }
}

export default connect(
  mapStateToProps,
  {
    fetchMaxAllowedSecureMaterials,
    fetchSelectedSecureMaterials,
    fetchExamOrderSummary,
    submit,
  }
)(OrderSecureMaterialsModal)
