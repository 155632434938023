export const getDistrictOrgId = state => {
  const {
    user: {
      data: { roles },
    },
  } = state
  const daRole = roles.find(r => r.districtOrg === true)
  if (daRole) {
    return daRole.orgId
  }
  return null
}

export const getOrgIdAndEdPeriod = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  return { orgId, educationPeriodCd }
}
