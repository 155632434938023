import { fetchFeatures } from '../../actions/features'
import { Loader } from '../../components/common'
import LoginUnavailablePage from './LoginUnavailablePage'
import LoginPage from './LoginPage'
import { isFeatureDisabled } from '../../selectors/features'

const mapStateToProps = state => ({
  loginDisabled: isFeatureDisabled(state, 'DISABLE_LOGIN'),
  fetchingFeatures: state.features.fetching,
})

export default connect(mapStateToProps, { fetchFeatures })(
  ({ loginDisabled, fetchingFeatures, fetchFeatures }) => {
    useEffect(() => {
      fetchFeatures()
    }, [])

    if (fetchingFeatures) return <Loader size="lg" />

    if (loginDisabled) {
      return <LoginUnavailablePage />
    }

    return <LoginPage />
  }
)
