import { Modal } from '../../components/common';

const footerActions = (buttonLabel) => {
  return [
    { buttonLabel: (buttonLabel || 'Close'), isDismissable: true, isPrimary: true },
  ]
}

export default ({title, BodyComponent, modalCloseFocusElem, onCloseAction, buttonLabel}) => (
  <Modal
    headerTitle={title}
    footerActions={footerActions(buttonLabel)}
    onCloseAction={onCloseAction}
    modalCloseFocusElem={modalCloseFocusElem}>
    <BodyComponent />
  </Modal>
)
