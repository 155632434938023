import { Link } from 'react-router-dom'
import { isTransferred } from '../../../../selectors/student'

import style from '../../../../assets/style/scss/students-table.scss'

export default ({ data, sortSelected, rowData, index, studentName, id }) => {
  const prev = index - 1 >= 0 ? data[index - 1] : {}
  const next = index + 1 < data.length ? data[index + 1] : {}
  const spanClass = isTransferred(rowData) ? style['inactive-border-top'] : ''
  const divClass =
    next.studentId === rowData.studentId && sortSelected ? style['first-of-many'] : ''

  return prev.studentId === rowData.studentId && sortSelected ? (
    <span className={spanClass} id={id}>
      <span className="sr-only">{studentName}</span>
    </span>
  ) : (
    <div className={divClass} id={id}>
      <Link to={`/student/${rowData.studentId}`}>{studentName}</Link>
    </div>
  )
}
