import { isValid, isDirty, submit, getFormValues } from 'redux-form'
import deepEqual from 'deep-equal'
import { Modal } from '../../components/common'
import { isEmpty } from '../../utils/common'
import { openModal } from '../../actions/app'
import { SELECT_SSD_MATERIALS_FORM, UPDATE_TYPE_SSD } from '../../constants/StudentConstants'
import SelectSSDMaterialsToBeOrderedForm from '../forms/SelectSSDMaterialsToBeOrderedForm'
import { getOrderDeadlineIsPast } from '../../selectors/section'
import { resetStudentUpdate } from '../../actions/studentsCommon'

const mapStateToProps = (state, { examId }) => {
  const {
    status: {
      data: { isSubmitted },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: settingsDeadlinesData },
    studentsByOrg: {
      update: { type, updating, updated, error, id },
      courseMap,
      studentMap,
      sectionMap,
      exams,
    },
  } = state
  const deadlinesData = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  const exam = exams.find(e => e.examId === examId)
  const isActive = type === UPDATE_TYPE_SSD && exam.examId === id
  const { lastName, firstName } = studentMap[exam.studentId]

  return {
    updating: isActive && updating,
    updated,
    error: isActive && error,
    valid: isValid(SELECT_SSD_MATERIALS_FORM)(state),
    dirty: isDirty(SELECT_SSD_MATERIALS_FORM)(state),
    selectedSSDMaterials: getFormValues(SELECT_SSD_MATERIALS_FORM)(state) || {},
    exam,
    courseName: courseMap[exam.testCd].name,
    studentName: `${firstName} ${lastName}`,
    isChangeOrder: isSubmitted || getOrderDeadlineIsPast(sectionMap[exam.sectionId], deadlinesData),
  }
}

function footerActions({ valid, updating, dirty, submitForm }) {
  return [
    {
      buttonLabel: 'Cancel',
      isDismissable: true,
      isPrimary: false,
      isDisabled: updating,
    },
    {
      buttonLabel: 'Update',
      isPrimary: true,
      onClick: submitForm,
      isDisabled: !valid || updating || !dirty,
      busy: updating,
    },
  ]
}

function OrderSSDMaterialsModal({
  updating,
  updated,
  error,
  valid,
  dirty,
  selectedSSDMaterials,
  exam,
  courseName,
  studentName,
  isChangeOrder,
  modalCloseFocusElem,
  openModal,
  submit,
  resetStudentUpdate,
}) {
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const prevSSDMaterials = useRef()
  useEffect(() => {
    prevSSDMaterials.current = exam?.ssdMaterials
  })
  
  useEffect(() => {
    if (updated) {
      setShouldCloseModal(true)
    }
    return () => resetStudentUpdate()
  }, [updated])

  const onCloseAction = () => {
    if (
      isChangeOrder &&
      !isEmpty(exam?.ssdMaterials) &&
      !deepEqual(prevSSDMaterials.current, exam?.ssdMaterials, { strict: true })
    ) {
      openModal('UpdateStudentExamSuccessModal', {
        exam,
        type: UPDATE_TYPE_SSD,
        courseName,
        studentName,
        modalCloseFocusElem,
      })
    }
  }

  return (
    <Modal
      shouldCloseModal={shouldCloseModal}
      onCloseAction={onCloseAction}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle={`${courseName}: Special Exam Materials for Students with Accommodations`}
      footerActions={footerActions({
        valid,
        updating,
        dirty,
        submitForm: () => submit(SELECT_SSD_MATERIALS_FORM),
      })}
    >
      <p>
        Indicate special exam materials based on approved or expected accommodations. (If this
        student will use a <strong>regular-format exam</strong>, don&#39;t indicate any special exam
        materials.){' '}
        <strong>
          Indicating materials below is not a formal request for accommodations; accommodations
          requests must be submitted to and approved by College Board SSD.
        </strong>
      </p>
      <SelectSSDMaterialsToBeOrderedForm exam={exam} />
    </Modal>
  )
}

export default connect(mapStateToProps, {
  submit,
  openModal,
  resetStudentUpdate,
})(OrderSSDMaterialsModal)
