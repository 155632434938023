import { formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { BasicInput, Loader, Error } from '../../../common'
import { getElementOffsetTop, getElementViewportHeight } from '../../../../utils/common'
import { SelectAllOrNone, ActionBar } from '../'

import styles from '../../../../assets/style/scss/csr.scss'

const DISABLED_COLOR = '#989795'

const StudentCheckbox = ({
  selections,
  enrollment,
  updateSelections,
  index,
  enrollmentsLength,
}) => {
  const { enrollmentId, firstName, lastName, feeRemoved, dateRemoved, studentId } = enrollment
  const msgId = `feeRemovalMsg-${enrollmentId}`

  return (
    <div
      style={{ marginBottom: 20, marginLeft: 25 }}
      role="listitem"
      aria-expanded={true}
      aria-level="2"
      aria-setsize={enrollmentsLength}
      aria-posinset={index}
    >
      <BasicInput
        type="checkbox"
        input={{
          name: 'feeRemovalsStudentSelect',
          value: enrollmentId,
          checked: selections.includes(enrollmentId) || feeRemoved,
          onChange: e => updateSelections(enrollmentId, e.target.checked),
          'aria-describedby': msgId,
        }}
        style={{ fontSize: '16px' }}
        label={`${lastName}, ${firstName}`}
        disabled={feeRemoved}
      />
      <div
        style={{ marginLeft: 27, color: feeRemoved ? DISABLED_COLOR : 'inherit' }}
        tabIndex="-1"
        id={msgId}
      >
        <div>{studentId}</div>
        {dateRemoved ? (
          <div>Removed {formatDateTime(dateRemoved, DATETIME_FORMATS.shortMonthDayYearTime)}</div>
        ) : null}
      </div>
    </div>
  )
}

class SectionSelections extends Component {
  constructor(props) {
    super()
    this.state = { expanded: props.expandedByDefault, checked: false }
  }

  componentDidMount() {
    this.headerWidth = this.header?.offsetWidth
    this.headerHeight = this.header?.offsetHeight
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { expanded, checked } = prevState
    const { enrollments, selections, allEnrollmentsFeesRemoved } = nextProps
    const allEnrollmentsChecked = enrollments.every(
      e => selections.find(id => id === e.enrollmentId) || e.feeRemoved
    )

    if (!expanded && !checked && allEnrollmentsChecked && !allEnrollmentsFeesRemoved)
      return {
        expanded: true,
        checked: true,
      }

    return { checked: allEnrollmentsChecked }
  }

  getStyles() {
    if (this.shouldBeSticky())
      return {
        headerStyle: {
          bottom: this.props.scroll.visibleHeight - this.headerHeight,
          height: this.headerHeight,
          width: this.headerWidth + 30,
        },
        containerStyle: {
          paddingTop: this.headerHeight,
        },
      }

    return {}
  }

  shouldBeSticky() {
    const { top } = this.props.scroll || {}
    const { offsetHeight = 0, offsetTop = 0 } = this.container || {}
    const outerBounds = offsetHeight + offsetTop - this.headerHeight
    return top > offsetTop && top < outerBounds
  }

  render() {
    const {
      selections,
      name,
      sectionId,
      enrollments,
      scroll,
      updateSelections,
      allEnrollmentsFeesRemoved,
      index,
      sectionsLength,
    } = this.props
    const groupId = 'feeRemovalsSection-' + sectionId
    const { expanded, checked } = this.state
    const { headerStyle = {}, containerStyle = {} } = this.getStyles()
    const enrollmentsIdsWithoutfeeRemoved = enrollments.reduce(
      (all, e) => (e.feeRemoved ? all : [...all, e.enrollmentId]),
      []
    )
    const stickyClass = this.shouldBeSticky() ? styles['sticky'] : ''
    const enrollmentsLength = enrollmentsLength

    return (
      <div
        className={`${styles['tool-body-accordion']} ${stickyClass}`}
        ref={node => (this.container = node)}
        style={containerStyle}
        role="listitem"
        aria-level="1"
        aria-setsize={sectionsLength}
        aria-posinset={index}
        aria-expanded={expanded}
      >
        <div
          className={`${styles['tool-body-accordion-header']} clearfix`}
          ref={node => (this.header = node)}
          style={headerStyle}
        >
          <BasicInput
            type="checkbox"
            disabled={allEnrollmentsFeesRemoved}
            input={{
              name: groupId,
              value: true,
              checked,
              onChange: e => updateSelections(enrollmentsIdsWithoutfeeRemoved, e.target.checked),
            }}
            label={`Select all students in ${name}`}
            srOnlyLabel={true}
          />
          <h5
            id={groupId + '-head'}
            style={allEnrollmentsFeesRemoved ? { color: DISABLED_COLOR } : {}}
          >
            <button
              type="button"
              aria-controls={groupId + '-body'}
              aria-expanded={expanded}
              onClick={() => this.setState({ expanded: !expanded })}
            >
              {name}
              <i className={expanded ? 'cb-glyph cb-up' : 'cb-glyph cb-down'} aria-hidden={true} />
            </button>
          </h5>
        </div>
        <div
          className={styles['tool-body-accordion-body']}
          id={groupId + '-body'}
          role="list"
          aria-hidden={!expanded}
          aria-labelledby={groupId + '-head'}
        >
          {expanded
            ? enrollments.map((e, i) => (
                <StudentCheckbox
                  enrollment={e}
                  selections={selections}
                  index={i + 1}
                  enrollmentsLength={enrollmentsLength}
                  updateSelections={updateSelections}
                  key={i}
                />
              ))
            : null}
        </div>
      </div>
    )
  }
}

export default class FeeRemovalsStudentSelect extends Component {
  constructor(props) {
    super()
    this.selectAllEnrollments = this.selectAllEnrollments.bind(this)
    this.resetSelections = this.resetSelections.bind(this)
    this.updateSelections = this.updateSelections.bind(this)
    this.stickyScroll = this.stickyScroll.bind(this)
    this.state = { selections: props.defaultSelections || [], scroll: null }
  }

  componentDidMount() {
    this.container.addEventListener('scroll', this.stickyScroll)
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.stickyScroll)
  }

  stickyScroll(e) {
    this.setState({
      scroll: {
        visibleHeight: getElementViewportHeight(this.container),
        top: getElementOffsetTop(this.container),
      },
    })
  }

  getAllEnrollments() {
    const { sections = [] } = this.props
    return sections.reduce(
      (all, section) => [
        ...all,
        ...section.enrollments.reduce(
          (all, e) => (e.feeRemoved ? all : [...all, e.enrollmentId]),
          []
        ),
      ],
      []
    )
  }

  selectAllEnrollments() {
    this.setState({ selections: this.getAllEnrollments() })
  }

  resetSelections() {
    this.setState({ selections: [] })
  }

  updateSelections(ids, add) {
    const { selections } = this.state
    const enrollmentIds = Array.isArray(ids) ? ids : [ids]

    if (add) this.setState({ selections: [...selections, ...enrollmentIds] })
    else this.setState({ selections: selections.filter(s => !enrollmentIds.includes(s)) })
  }

  render() {
    const { selections, scroll } = this.state
    const { error, saving, onStudentSelection, sections = [] } = this.props
    const selectionsLen = selections.length
    const hasNoSelections = !Boolean(selectionsLen)
    const allSelectableEnrollments = this.getAllEnrollments()
    const sectionsLength = sections.length

    return (
      <>
        <div className={styles['tool-body-inner']} ref={node => (this.container = node)}>
          <p className={styles['tool-body-item']}>
            Select the students who need their fee removed.
          </p>
          <SelectAllOrNone
            selectAll={this.selectAllEnrollments}
            selectNone={this.resetSelections}
            disableSelectAll={selectionsLen === allSelectableEnrollments.length}
            disableSelectNone={hasNoSelections}
          />
          {error ? (
            <Error title="Error Saving Data" message={error} style={{ margin: 0, padding: 15 }} />
          ) : null}
          <div role="list">
            {sections.map((s, i) => (
              <SectionSelections
                {...s}
                expandedByDefault={sections.length === 1}
                allEnrollmentsFeesRemoved={s.enrollments.every(e => e.feeRemoved)}
                updateSelections={this.updateSelections}
                scroll={scroll}
                selections={selections}
                sectionsLength={sectionsLength}
                index={i + 1}
                key={i}
              />
            ))}
          </div>
        </div>
        <ActionBar
          buttons={[
            {
              title: 'Remove Fee',
              onClick: () => onStudentSelection(selections),
              disabled: hasNoSelections,
            },
          ]}
        />
      </>
    )
  }
}
