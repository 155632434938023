import { formatDateTime, sortDates, DATETIME_FORMATS } from '@myap/ui-library'
import { Link } from 'react-router-dom'
import Accordions from '../common/Accordions'
import { percentFormat } from '../../utils/numbers'
import { NAV_LINKS } from '../../constants/NavConstants'
import { openModal } from '../../actions/app'
import { sortColumnByKey } from '../../utils/sort'
import { plural } from '../../utils/common'
import styles from '../../assets/style/scss/exam-room.scss'

const RoomProgress = ({ progress, total, roomCount }) => {
  const percent = percentFormat(progress / total)
  return (
    <div className={styles['room-progress']}>
      <div>
        {progress} of {total} {plural(total, 'Student')} Assigned to {plural(roomCount, 'Room')}
      </div>
      <div className="progress cb-progress-gray">
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow={percent}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: percent }}
        >
          <span className="sr-only">{percent} Complete</span>
        </div>
      </div>
    </div>
  )
}

const CourseBreakdown = connect(
  null,
  { openModal }
)(({ courseName, totalCapacity, assignedCapacity, rooms, testCd, sessionId, openModal }) => {
  const addRoomRef = useRef(null)
  const accessibleCourseId = `course-${testCd}`

  return (
    <>
      <div className={styles['course-breakdown']}>
        <div className="row">
          <h6 className="col-sm-4" id={accessibleCourseId}>
            {courseName}
          </h6>
          <div className="col-sm-5">
            <RoomProgress
              progress={assignedCapacity}
              total={totalCapacity}
              roomCount={rooms.length}
            />
          </div>
          <div className="col-sm-3">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              ref={addRoomRef}
              aria-haspopup="dialog"
              aria-describedby={accessibleCourseId}
              onClick={() =>
                openModal('ExamRoomCreateModal', {
                  testCd,
                  courseName,
                  sessionId,
                  modalCloseFocusElem: addRoomRef.current,
                })
              }
            >
              Add Room
            </button>
          </div>
        </div>
      </div>
      {rooms.length ? (
        <div className={styles['rooms-container']}>
          {sortColumnByKey(rooms, 'name', 'asc').map(
            ({ id, name, totalCapacity = 0, assignedCapacity = 0 }) => {
              const seatsAvailable = totalCapacity - assignedCapacity
              const accessibleRoomId = `room-${id}`
              return (
                <div className="row" key={id}>
                  <div className="col-sm-4" id={accessibleRoomId}>
                    {name}
                  </div>
                  <div className="col-sm-5">
                    {seatsAvailable} {plural(seatsAvailable, 'Seat')} Available
                  </div>
                  <div className="col-sm-3">
                    <Link
                      aria-describedby={accessibleRoomId}
                      to={NAV_LINKS.examroomdets.replace(':roomId', id)}
                    >
                      Room Details <i className="cb-glyph cb-east" />
                    </Link>
                  </div>
                </div>
              )
            }
          )}
        </div>
      ) : null}
    </>
  )
})

const TimeBreakdown = ({ sessionsByTime }) => {
  return sessionsByTime.map(s => (
    <div className={styles['time-breakdown']} key={s.id}>
      <h5>{formatDateTime(s.examTime, DATETIME_FORMATS.time)}</h5>
      {sortColumnByKey(s.exams, 'courseName', 'asc').map(e => (
        <CourseBreakdown key={e.testCd} {...e} sessionId={s.id} />
      ))}
    </div>
  ))
}

const mapStateToProps = (state, { type }) => state.examRoomSchedule.data[type] || {}

const ExamRoomSchedule = ({ examSessions = [] }) => {
  const sessionsByDate = examSessions.reduce((acc, s) => {
    const index = acc.findIndex(a => a.examTime.split('T')[0] === s.examTime.split('T')[0])
    const session = { sessionsByTime: [s], examTime: s.examTime }
    if (index !== -1) acc[index].sessionsByTime.push(s)
    else acc.push(session)
    return acc
  }, [])

  return examSessions.length ? (
    <Accordions
      id="examRooms"
      panels={sortDates(sessionsByDate, 'examTime').map((s, i) => ({
        title: formatDateTime(s.examTime, 'EEEE, MMM do'),
        PanelBody: () => <TimeBreakdown {...s} />,
        panelId: `examPanel-${i}`,
      }))}
    />
  ) : (
    <p>There are no students registered to take an exam during this testing window.</p>
  )
}

export default connect(mapStateToProps)(ExamRoomSchedule)
