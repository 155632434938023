import { Loader, Error } from '../../common'
import {
  fetchNonSecureAncillaryMaterials,
  saveNonSecureAncillaryMaterials,
} from '../../../actions/csr'
import { fetchCoordNonSecureAncillaryMaterials } from '../../../actions/orders'
import { LockUnlockComponent, BrowseItem } from '.'
import { getSortedExamWindowRefData, getExamWindowRefData } from '../../../selectors/examWindows'
import { sortColumnByKey } from '../../../utils/sort'

import styles from '../../../assets/style/scss/csr.scss'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  const examWindowRefData = getExamWindowRefData(state)
  return {
    educationPeriodCd,
    orgId,
    examWindowRefData,
  }
}

const NonSecureAncillaryMaterials = ({
  orgId,
  educationPeriodCd,
  examWindowRefData,
  subpanel,
  panel,
  updatePanel,
  fetchCoordNonSecureAncillaryMaterials,
}) => {
  const [nonSecureMaterials, setNonSecureMaterials] = useState([])
  const [errorFetching, setErrorFetching] = useState(null)
  const [fetchingNonSecureMaterials, setFetchingNonSecureMaterials] = useState(true)
  const [testWindow, setTestWindow] = useState(null)
  const sortedExamWindowData = getSortedExamWindowRefData(examWindowRefData)

  const fetchMaterials = async () => {
    const { data = [], error = null } = await fetchNonSecureAncillaryMaterials(
      orgId,
      educationPeriodCd
    )
    setNonSecureMaterials(data)
    setErrorFetching(error)
    setFetchingNonSecureMaterials(false)
  }

  const filterResponseByExamWindow = data => {
    const filteredData = data.filter(item => item.testWindow === testWindow)
    return { data: sortColumnByKey(filteredData, ['description', 'cbSku'], 'asc') }
  }

  const filterMaterialsByExamWindow = () => {
    return filterResponseByExamWindow(nonSecureMaterials)
  }

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchCoordNonSecureAncillaryMaterials(orgId, educationPeriodCd)
    }
    fetchMaterials()
  }, [orgId, educationPeriodCd])

  const saveData = async itemsToSave => {
    const response = await saveNonSecureAncillaryMaterials(itemsToSave, orgId, educationPeriodCd)
    if (response.data) {
      await setNonSecureMaterials(response.data)
      return filterResponseByExamWindow(response.data)
    }

    return response
  }

  const updateSubPanel = (subpanel, title) => {
    if (subpanel === -1) {
      updatePanel(-1)
    } else {
      updatePanel(panel, title, subpanel)
    }
  }

  const selectTestWindow = ({ displayName, adminWindow }) => {
    setTestWindow(adminWindow)
    updateSubPanel(1, `${displayName} Testing Materials`)
  }

  const showMaterial = ({ adminWindow }) =>
    nonSecureMaterials.find(item => item.testWindow === adminWindow)

  if (errorFetching) {
    return <Error message={errorFetching} title="Error retrieving Non-Secure Ancillary Materials" />
  }

  if (fetchingNonSecureMaterials) {
    return <Loader />
  }

  switch (subpanel) {
    case 0:
      return (
        <div className={styles['tool-body-inner']}>
          <p className={styles['tool-body-divider']}>
            Select the materials below that you wish to lock or unlock the ability to order.
          </p>
          {sortedExamWindowData.map(window =>
            showMaterial(window) ? (
              <BrowseItem
                key={APRICOT.utils.uniqueID()}
                title={`${window.displayName} Testing Materials`}
                action={() => selectTestWindow(window)}
              />
            ) : null
          )}
        </div>
      )
    case 1:
      return (
        <LockUnlockComponent
          compId="nonSecureAncillaryMaterials"
          introText="Select the materials below that you wish to lock or unlock the ability to order."
          emptyText="There are no non-secure ancillary materials to unlock."
          fnFetch={filterMaterialsByExamWindow}
          fnSave={saveData}
          itemProps={{
            itemId: 'cbSku',
            itemDescription: 'description',
            itemOptionalAttrs: ['testWindow'],
          }}
        />
      )
    default:
      return null
  }
}

export default connect(mapStateToProps, { fetchCoordNonSecureAncillaryMaterials })(
  NonSecureAncillaryMaterials
)
