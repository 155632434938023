import styles from '../../../assets/style/scss/exam-readiness.scss'
import CoordinatorPractice from './CoordinatorPractice'

const CoordinatorBanner = ({ breakpoint }) => (
  <div
    className={`${styles['coordinator-banner']} ${styles[breakpoint]} cb-accent1-blue-corporate-bg`}
  >
    <div className="container">
      <div className="cb-white-color">
        <h2>Digital Exam Readiness</h2>
        <div>
          Review students' exam readiness statuses and makeup requests.{' '}
          <a
            href="https://apcentral.collegeboard.org/about-ap-2021/updates"
            className="cb-white-color"
          >
            Learn More about 2021 Exams
          </a>
        </div>
      </div>
      <CoordinatorPractice />
    </div>
  </div>
)

export default connect(state => ({ breakpoint: state.app.breakpoint.name }))(CoordinatorBanner)
