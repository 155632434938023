/*
 * SectionConstants
 * These are the variables that determine what our central data store changes in our state.
 * When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */

export const FETCH_SINGLE_SECTION_PENDING = 'FETCH_SINGLE_SECTION_PENDING'
export const FETCH_SINGLE_SECTION_FULFILLED = 'FETCH_SINGLE_SECTION_FULFILLED'
export const FETCH_SINGLE_SECTION_REJECTED = 'FETCH_SINGLE_SECTION_REJECTED'
export const RESET_SECTION_DATA = 'RESET_SECTION_DATA'
export const UPDATE_SECTION_DATA = 'UPDATE_SECTION_DATA'

export const CREATE_SECTION_FULFILLED = 'CREATE_SECTION_FULFILLED'
export const CREATE_ENROLLMENTS_FULFILLED = 'CREATE_ENROLLMENTS_FULFILLED'
export const EDIT_SECTION_FULFILLED = 'EDIT_SECTION_FULFILLED'

export const DELETE_SECTION_PENDING = 'DELETE_SECTION_PENDING'
export const DELETE_SECTION_FULFILLED = 'DELETE_SECTION_FULFILLED'
export const DELETE_SECTION_REJECTED = 'DELETE_SECTION_REJECTED'

export const UPDATE_SECTION_JOINCODE_PENDING = 'UPDATE_SECTION_JOINCODE_PENDING'
export const UPDATE_SECTION_JOINCODE_FULFILLED = 'UPDATE_SECTION_JOINCODE_FULFILLED'
export const UPDATE_SECTION_JOINCODE_REJECTED = 'UPDATE_SECTION_JOINCODE_REJECTED'

export const GENERATE_SECTION_TRANSFERCODE_PENDING = 'GENERATE_SECTION_TRANSFERCODE_PENDING'
export const GENERATE_SECTION_TRANSFERCODE_FULFILLED = 'GENERATE_SECTION_TRANSFERCODE_FULFILLED'
export const GENERATE_SECTION_TRANSFERCODE_REJECTED = 'GENERATE_SECTION_TRANSFERCODE_REJECTED'

export const RESET_ENROLLMENT_DATA = 'RESET_ENROLLMENT_DATA'

export const CONFIRM_SECTION_EXAM_DECISION_PENDING = 'CONFIRM_SECTION_EXAM_DECISION_PENDING'
export const CONFIRM_SECTION_EXAM_DECISION_FULFILLED = 'CONFIRM_SECTION_EXAM_DECISION_FULFILLED'
export const CONFIRM_SECTION_EXAM_DECISION_REJECTED = 'CONFIRM_SECTION_EXAM_DECISION_REJECTED'

export const EXPIRE_ALL_JOIN_CODES_PENDING = 'EXPIRE_ALL_JOIN_CODES_PENDING'
export const EXPIRE_ALL_JOIN_CODES_FULFILLED = 'EXPIRE_ALL_JOIN_CODES_FULFILLED'
export const EXPIRE_ALL_JOIN_CODES_REJECTED = 'EXPIRE_ALL_JOIN_CODES_REJECTED'
export const RESET_EXPIRE_ALL_JOIN_CODES = 'RESET_EXPIRE_ALL_JOIN_CODES'

export const FETCH_EXPIRE_JOIN_CODES_STATUS_PENDING = 'FETCH_EXPIRE_JOIN_CODES_STATUS_PENDING'
export const FETCH_EXPIRE_JOIN_CODES_STATUS_FULFILLED = 'FETCH_EXPIRE_JOIN_CODES_STATUS_FULFILLED'
export const FETCH_EXPIRE_JOIN_CODES_STATUS_REJECTED = 'FETCH_EXPIRE_JOIN_CODES_STATUS_REJECTED'

export const EXPIRE_JOIN_CODES_STATUS_SUCCESS = 'SUCCESS'
export const EXPIRE_JOIN_CODES_STATUS_IN_PROGRESS = 'INPROGRESS'
export const EXPIRE_JOIN_CODES_STATUS_FAIL = 'FAILED'

export const CREATE_FORM_NAME = 'createAndEditSectionForm'
export const CREATE_FORM_FIELDS = {
  name: 'sectionName',
  students: 'maxNumberOfStudents',
  schedule: 'sectionType',
  date: 'sectionStartDate',
  teachers: 'sectionTeachers',
  testCd: 'testCd',
  edPeriod: 'educationPeriodCd',
  attested: 'attested',
  orgId: 'orgId',
  weService: 'weService',
  seminarCreditType: 'creditType',
}

export const COURSE_SCHEDULE_TYPES = {
  S_FY: {
    label: 'Standard Full Year',
    value: 'S_FY',
    isDefault: true,
  },
  S_FS: {
    label: 'Fall Semester Only',
    value: 'S_FS',
  },
  S_SS: {
    label: 'Spring Semester Only',
    value: 'S_SS',
  },
  EO: {
    label: 'Exam Only',
    value: 'EO',
  },
}

export const CREDIT_TYPE_OPTIONS = [
  { label: 'English core course', value: 'ECC' },
  { label: 'English elective', value: 'EET' },
  { label: 'Other core course', value: 'OCC' },
  { label: 'Other elective', value: 'OET' },
]

export const CREATE_FORM_COURSE_SCHEDULE_OPTIONS = [
  COURSE_SCHEDULE_TYPES.S_FY,
  COURSE_SCHEDULE_TYPES.S_FS,
  COURSE_SCHEDULE_TYPES.S_SS,
]
export const CREATE_FORM_EXAM_ONLY_VALUE = COURSE_SCHEDULE_TYPES.EO.value
export const CREATE_FORM_FIRST_SEMESTER_VALUE = COURSE_SCHEDULE_TYPES.S_FS.value
export const CREATE_FORM_SECOND_SEMESTER_VALUE = COURSE_SCHEDULE_TYPES.S_SS.value
export const CREATE_FORM_SCHEDULE_LABEL_ID = 'createSectionCourseScheduleLabel'

export const CODE_TYPES = {
  join: {
    name: 'join',
    url: 'joincode',
    text: 'join',
    title: 'Join',
    prop: 'joinCode',
    reason: 'JOIN',
  },
  transfer: {
    name: 'transfer',
    url: 'transfercode',
    text: 'transfer',
    title: 'Transfer',
    prop: 'transferCode',
    reason: 'TRANSFER',
  },
}
