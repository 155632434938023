import { Link } from 'react-router-dom'
import { TableTemplate } from '.'
import { DisplayExamDate } from '../../common'
import { numberFormat } from '../../../utils/numbers'
import { NAV_LINKS } from '../../../constants/NavConstants'
import { ORDER_NOT_SUBMITTED, ORDER_PENDING } from '../../../constants/OrderConstants'
import {
  COURSE_FILTER,
  ADMIN_FILTER,
  ORDER_STATUS_FILTER,
} from '../../../constants/FilterConstants'
import styles from '../../../assets/style/scss/table-utils.scss'

const headerItemizedColumns = id => [
  {
    headerText: { title: 'Course' },
    key: 'course',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Course` },
    defaultSort: true,
  },
  {
    headerText: { title: 'Exam Date' },
    key: 'examDate',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamDate` },
  },
  {
    headerText: { title: 'Exams Added' },
    key: 'examsAdded',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamsAdded` },
  },
  {
    headerText: { title: 'Exam Date Changed' },
    key: 'testDatesChanged',
    sorting: 'asc',
    headerAttributes: { id: `${id}-TestDate` },
  },
  {
    headerText: { title: 'SSD Materials Changed' },
    key: 'ssdMaterialsChanged',
    sorting: 'asc',
    headerAttributes: { id: `${id}-SSDMaterials` },
  },
  {
    headerText: { title: 'Exams Canceled' },
    key: 'examsCancelled',
    sorting: 'asc',
    headerAttributes: { id: `${id}-ExamsCancelled` },
  },
  {
    headerText: { title: 'Order Impact' },
    key: 'orderImpact',
    sorting: 'asc',
    headerAttributes: { id: `${id}-OrderImpact` },
  },
]

const renderItemizedCourses = (data, showCourseFilterLink) =>
  data.map(c => {
    const filters = [
      { filter: COURSE_FILTER.filter, val: c.testCd },
      { filter: ADMIN_FILTER.filter, val: c.examDateType },
      { filter: ORDER_STATUS_FILTER.filter, val: ORDER_NOT_SUBMITTED },
      { filter: ORDER_STATUS_FILTER.filter, val: ORDER_PENDING },
    ]
    const filterParams = filters
      .reduce((acc, f) => (f.val ? [...acc, `${f.filter}=${f.val}`] : acc), [])
      .join('&')

    return (
      <tr
        key={APRICOT.utils.uniqueID()}
        className={c.isAggregate ? styles['top-aggregate-row'] : ''}
      >
        <th scope="row">
          {showCourseFilterLink ? (
            <Link to={`${NAV_LINKS.students}?${filterParams}`}>{c.course}</Link>
          ) : (
            c.course
          )}
        </th>
        <td className={styles['with-icon']}>
          <div className={`${styles['exam-date-wrapper']} ${styles.flexible}`}>
            <DisplayExamDate course={c} />
          </div>
        </td>
        <td className={styles['numeric-column']}>{numberFormat(c.examsAdded)}</td>
        <td className={styles['numeric-column']}>{numberFormat(c.testDatesChanged)}</td>
        <td className={styles['numeric-column']}>{numberFormat(c.ssdMaterialsChanged)}</td>
        <td className={styles['numeric-column']}>{numberFormat(c.examsCancelled)}</td>
        <td className={styles['numeric-column']}>{numberFormat(c.orderImpact)}</td>
      </tr>
    )
  })

export default ({
  data = [],
  aggregateRow = null,
  headerStyle = { marginTop: '10px', marginBottom: '5px', fontSize: '1em' },
  id,
  caption = 'View of all exam adjustments',
  summary = 'Exam adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
  emptyText = 'This order does not have any exam adjustments.',
  isUnsubmitted = false,
}) => (
  <TableTemplate
    header="Exam Adjustments"
    headerStyle={headerStyle}
    emptyText={emptyText}
    tableAttrs={{
      id,
      caption,
      summary,
      data,
      aggregateRow,
      rowBuilder: data => renderItemizedCourses(data, isUnsubmitted),
      columns: headerItemizedColumns(id),
    }}
  />
)
