import { NavLink, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import style from '../../assets/style/scss/primary-navigation.scss'
import { Dropdown } from '../common/Dropdown'
import { getMyAPUrl, getDefaultRoute } from '../routes/utils'

const mapStateToProps = state => {
  const {
    user: { data },
    nav: { suppressNav },
  } = state

  return {
    suppressNav,
    ...data,
  }
}

const PrimaryNavigation = props => {
  const {
    isTeacher,
    isStudent,
    title,
    menu = [],
    hasSecondaryNav = true,
    routes,
    suppressNav,
  } = props

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const container = useRef(null)
  const MYAP_URL = getMyAPUrl({ isStudent })
  const defaultUrl = getDefaultRoute({ isTeacher, isStudent })
  const homeClass = 'title cb-program-name'

  useEffect(() => {
    const pageTitle = Object.values(routes).find(r => r.path === match.path)?.title
    $(container.current).cbLocalNavigation({ moreFeature: false })
    let documentTitle = ''
    if (isStudent) {
      documentTitle = pageTitle
    } else if (pageTitle) {
      documentTitle = `${pageTitle} | ${title}`
    } else {
      documentTitle = title
    }
    document.title = documentTitle
  }, [])

  if (suppressNav) {
    return null
  }

  return (
    <div
      className="cb-local-navigation"
      ref={container}
      style={hasSecondaryNav ? {} : { marginBottom: 20 }}
    >
      <div className="container">
        <nav role="navigation" aria-label="CB Local Navigation" className="cb-desktop-navigation">
          <div className="row">
            <div className="col-xs-12">
              <h1 className="cb-site-name">
                {isStudent ? (
                  <a href={MYAP_URL} className={homeClass}>
                    <span>{title}</span>
                  </a>
                ) : (
                  <NavLink className="title cb-program-name" to={defaultUrl}>
                    <span>{title}</span>
                  </NavLink>
                )}
              </h1>
              {menu ? (
                <ul className="cb-local-menu">
                  {menu.map(({ path, iconClass, title, isMulti, subnav }, i) =>
                    subnav ? (
                      <Dropdown
                        key={i}
                        id={`subnav${i}`}
                        tag="li"
                        label={title}
                        menuItems={subnav.map(s => ({
                          label: s.title,
                          clickEvent: () => history.push(s.path),
                        }))}
                      />
                    ) : (
                      <li
                        key={i}
                        className={`${style['nav-list-item']} ${
                          location.pathname === path ? 'active' : ''
                        } ${isMulti ? 'cb-multi' : ''}`}
                      >
                        <NavLink to={path}>
                          {iconClass ? <i className={iconClass} aria-hidden="true" /> : null}
                          <span>{title}</span>
                        </NavLink>
                      </li>
                    )
                  )}
                </ul>
              ) : null}
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(PrimaryNavigation)
