import { useHistory } from 'react-router-dom'
import GrantorAddress from './GrantorAddress'
import { getStateReimbursementGrantor, isDirty } from './utils'
import { NAV_LINKS } from '../../constants/NavConstants'
import { TYPE_STATE } from '../../constants/InvoiceConstants'

const StateReimbursement = ({ data }) => {
  const history = useHistory()
  const grantor = getStateReimbursementGrantor(data)

  if (grantor) {
    return (
      <>
        <h3 className="h4">State Reimbursement Copy</h3>
        <p>
          To receive reimbursement for your AP Exam fees, you can send the state reimbursement copy
          to the address below:
        </p>
        <GrantorAddress {...grantor} />
        <button
          type="button"
          className="btn btn-sm btn-primary"
          disabled={isDirty(data)}
          onClick={() => history.push(`${NAV_LINKS.invoice}/${TYPE_STATE.toLowerCase()}`)}
        >
          View State Copy
        </button>
      </>
    )
  }

  return null
}

export default StateReimbursement
