import FilterContainer from '../common/FilterContainer'
import { AdminFilterDropdown } from '../common/Dropdowns'
import {
  ExamDateFilterDropdown,
  LoginFilterDropdown,
  SetupStatusFilterDropdown,
  CheckinStatusFilterDropdown,
  ExamStatusFilterDropdown,
  MakeupStatusFilterDropdown,
  PracticeStatusFilterDropdown,
  SectionFilterDropdown,
} from './ExamFilterDropdowns'
import { DigitalOnlyFilterCheckbox, ActiveAlertsFilterCheckbox } from './ExamFilterCheckboxes'
import { getExamFilterTagData } from '../../../selectors/filters/examReadinessFilters'

const mapStateToProps = state => ({ ...state.examReadiness })

export default connect(mapStateToProps)(props => {
  const {
    activeFilters,
    examWindowMap = {},
    sectionMap = {},
    selectedCourse = {},
    examsByCourse,
  } = props
  const sectionsByCourse = Object.keys(sectionMap).reduce((arr, sectionId) => {
    const section = sectionMap[sectionId]
    return section.testCode === selectedCourse ? [...arr, { ...section, sectionId }] : arr
  }, [])
  const examWindowsByCourse = Object.keys(examWindowMap).reduce((obj, key) => {
    const window = examWindowMap[key]
    const courseSpecificExamMap = window.examMap[selectedCourse]
    return courseSpecificExamMap
      ? { ...obj, [key]: { ...window, examMap: { [selectedCourse]: courseSpecificExamMap } } }
      : obj
  }, {})

  return examsByCourse.length ? (
    <>
      <FilterContainer
        label="Exam Readiness Filters"
        activeFilters={activeFilters}
        getFilterTagData={(filter, value) =>
          getExamFilterTagData({ examWindowMap, sectionMap }, filter, value)
        }
      >
        <AdminFilterDropdown windows={Object.keys(examWindowsByCourse)} />
        <ExamDateFilterDropdown windows={Object.values(examWindowsByCourse)} />
        <SectionFilterDropdown sections={sectionsByCourse} />
        <LoginFilterDropdown />
        <PracticeStatusFilterDropdown />
        <SetupStatusFilterDropdown />
        <CheckinStatusFilterDropdown />
        <ExamStatusFilterDropdown />
        <MakeupStatusFilterDropdown />
        <DigitalOnlyFilterCheckbox />
        <ActiveAlertsFilterCheckbox />
      </FilterContainer>
    </>
  ) : null
})
