import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { SEE_SUBMISSION_DEADLINE_MSG } from '../../constants/StudentConstants'
import { getExamWindowsForCourse } from '../../selectors/examWindows'
import { TEST_ADMIN_EXCEPTION } from '../../constants/SettingsConstants'
import tableUtilStyles from '../../assets/style/scss/table-utils.scss'

const mapStateToProps = (state, { course }) => {
  // TBD: Only use one of testDayType or examDateType, prefer examDateType
  const { testCd, testDayType, examDateType, testWindow } = course
  const examWindow = testDayType || examDateType || testWindow
  const {
    displayName = '',
    examDateTime = '',
    noEndOfCourseExam = false,
    requiresUnlocking = false,
  } = getExamWindowsForCourse(state, testCd)[examWindow] ?? {}
  return {
    displayName,
    examDateTime,
    noEndOfCourseExam,
    requiresUnlocking,
  }
}

const DisplayWithDate = ({ displayName, examDate, testAdmin, requiresUnlocking }) => {
  if (requiresUnlocking) {
    return (
      <span className={tableUtilStyles['exam-type']}>
        {displayName}
        {testAdmin === TEST_ADMIN_EXCEPTION ? ' Testing' : ''}
      </span>
    )
  }

  return (
    <>
      <span className={tableUtilStyles['exam-type']}>{displayName} </span>
      <span className={tableUtilStyles['exam-date']}>
        {formatDate(examDate, DATETIME_FORMATS.shortMonthDay)}
      </span>
    </>
  )
}

export default connect(mapStateToProps)(
  ({ displayName, examDateTime, noEndOfCourseExam, requiresUnlocking }) => {
    if (noEndOfCourseExam) return SEE_SUBMISSION_DEADLINE_MSG

    if (displayName)
      return (
        <DisplayWithDate
          displayName={displayName}
          examDate={examDateTime}
          requiresUnlocking={requiresUnlocking}
        />
      )

    return null
  }
)
