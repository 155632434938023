import * as settingConstants from '../constants/SettingsConstants'
import template from './template'

const deadlinesInitialState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export const settingsDeadlines = (state = deadlinesInitialState, { type, payload }) => {
  switch (type) {
    case settingConstants.FETCH_DEADLINES_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      }

    case settingConstants.FETCH_DEADLINES_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: {
          ...state.data,
          [payload.educationPeriodCd]: payload.deadlines,
        },
      }

    case settingConstants.FETCH_DEADLINES_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: payload,
      }

    default:
      return state
  }
  //template(state, action, settingConstants, 'DEADLINES')
}
export const settingsSSDMaterials = (state, action) =>
  template(state, action, settingConstants, 'ALLOWED_SSD_MATERIALS')
export const settingsSpecialDigitalFormats = (state, action) =>
  template(state, action, settingConstants, 'SPECIAL_DIGITAL_FORMATS')
export const settingsPricing = (state, action) =>
  template(state, action, settingConstants, 'EXAM_PRICING')
