import axios from 'axios'

// options is an object that will accept 'zip' and 'searchWithinAP'
// and 'filterOutAiCodes' which is an array of aiCodes (where aiCodes are strings)
// that should not appear in search results
export const getAvailableOrgs = async (suggestion, options = {}) => {
  try {
    const searchTerm = Number.isNaN(parseInt(suggestion, 10))
      ? `name=${suggestion}`
      : `aicode=${suggestion}`
    const zipParam = options?.zip ? `&zip=${options.zip}&distance=100` : ''
    const useAPDomain = options?.searchWithinAP ? '&aidomain=ap' : ''
    const filterOut = Array.isArray(options?.filterOutAiCodes) ? options.filterOutAiCodes : []
    const {
      data: {
        hits: { total, hits },
      },
    } = await axios.request({
      method: 'get',
      url: `${Config.CBAPI_ORGCDS.url}/aisearch?${searchTerm}${zipParam}&size=10${useAPDomain}`,
      withCredentials: false,
      transformRequest: (data, headers) => {
        headers.common = { 'X-Api-Key': Config.CBAPI_ORGCDS.apiKey }
        return data
      },
    })
    // Filter out any results where the ais list is empty
    // If filterOutAiCodes is provided, filterOut orgs with these aiCodes from returned search results
    const suggestions = hits
      .filter(({ _source: h }) => h.ais.length > 0)
      .filter(({ _source: h }) => h.ais.every(ai => !filterOut.includes(ai.ai_code)))
      .map(({ _source: h }) => ({
        orgId: h.org_id,
        orgName: h.org_full_name,
        city: h.org_city,
        state: h.org_state_cd,
        province: h.org_province,
        country: h.org_country_iso_cd,
        address: h.org_street_addr1,
        zip: h.org_zip5,
        postal: h.org_intl_postal_code,
        aiList: h.ais,
      }))
    const filteredTotal = suggestions.length
    return {
      total: filteredTotal,
      suggestions,
    }
  } catch (err) {
    return { suggestions: [], total: 0 }
  }
}

export const fetchSchoolsByDICode = async code => {
  try {
    const searchTerm = Number.isNaN(parseInt(code, 10)) /* || code.length !== 4 */
      ? `name=${code}`
      : `dicode=${code}`
    const {
      data: {
        hits: { total, hits },
      },
    } = await axios.request({
      method: 'get',
      url: `${Config.CBAPI_ORGCDS.url}/disearch?${searchTerm}&ap_pgm=y`,
      withCredentials: false,
      transformRequest: (data, headers) => {
        headers.common = { 'X-Api-Key': Config.CBAPI_ORGCDS.apiKey }
        return data
      },
    })

    const suggestions = hits
      .filter(({ _source: h }) => h.di_ap_pgm_active_ind === 'Y')
      .map(({ _source: h }) => ({
        dicode: h.di_code,
        name: h.org_full_name,
        city: h.org_city,
        state: h.org_state_cd,
        country: h.org_country_iso_cd,
        address: h.org_street_addr1,
        zip: h.org_zip5,
        postal: h.org_intl_postal_code,
      }))
    const filteredTotal = suggestions.length

    return {
      total: filteredTotal,
      suggestions,
    }
  } catch (err) {
    return { suggestions: [], total: 0 }
  }
}

export const getOrgsFromOrgSearch = async (suggestion, options = {}) => {
  let searchTerm = ''
  if (options?.searchType) {
    switch (options.searchType) {
      case 'aiCode':
        searchTerm = `code=${suggestion}`
        break

      case 'orgId':
        searchTerm = `orgid=${suggestion}`
        break

      case 'name':
      default:
        searchTerm = `name=${suggestion}`
        break
    }
  } else
    searchTerm = Number.isNaN(parseInt(suggestion, 10))
      ? `name=${suggestion}`
      : `orgid=${suggestion}`

  try {
    const {
      data: {
        hits: { total, hits },
      },
    } = await axios.request({
      method: 'get',
      url: `${Config.CBAPI_ORGCDS.url}/orgsearch?${searchTerm}&size=10`,
      withCredentials: false,
      transformRequest: (data, headers) => {
        headers.common = { 'X-Api-Key': Config.CBAPI_ORGCDS.apiKey }
        return data
      },
    })

    return {
      total,
      suggestions: hits.map(({ _source: h }) => ({
        orgId: h.org_id,
        orgName: h.org_full_name,
        city: h.org_city,
        state: h.org_state_cd,
        country: h.org_country_iso_cd,
        address: h.org_street_addr1,
        zip: h.org_zip5,
        postal: h.org_intl_postal_code,
        aiCode: (h.ais && h.ais[0]?.ai_code) || '',
      })),
    }
  } catch (err) {
    console.info(err)
    return { suggestions: [], total: 0 }
  }
}

export const fetchSchoolsByAiCode = async suggestion => {
  try {
    const {
      data: {
        hits: { total, hits = [] },
      },
    } = await axios.request({
      method: 'get',
      url: `${Config.CBAPI_ORGCDS.url}/aisearch?name=${suggestion}&aidomain=ap&size=10`,
      withCredentials: false,
      transformRequest: (data, headers) => {
        headers.common = { 'X-Api-Key': Config.CBAPI_ORGCDS.apiKey }
        return data
      },
    })
    return {
      total,
      suggestions: hits.map(({ _source: o }) => ({
        aicode: o.ais[0]?.ai_code,
        orgid: parseInt(o.org_id, 10),
        name: o.org_full_name,
        city: o.org_city,
        state: o.org_state_cd,
        country: o.org_country_iso_cd,
        address: `${o.org_street_addr1}, ${o.org_city}, ${o.org_state_cd || o.org_country_iso_cd} ${
          o.org_zip5 || ''
        }`,
      })),
    }
  } catch (err) {
    return { suggestions: [], total: 0 }
  }
}
