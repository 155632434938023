export const FETCH_EDUCATION_PERIOD_PENDING = 'FETCH_EDUCATION_PERIOD_PENDING'
export const FETCH_EDUCATION_PERIOD_FULFILLED = 'FETCH_EDUCATION_PERIOD_FULFILLED'
export const FETCH_EDUCATION_PERIOD_REJECTED = 'FETCH_EDUCATION_PERIOD_REJECTED'

export const SET_SELECTED_EDUCATION_PERIOD = 'SET_SELECTED_EDUCATION_PERIOD'
export const CLEAR_SELECTED_EDUCATION_PERIOD = 'CLEAR_SELECTED_EDUCATION_PERIOD'
export const SET_SELECTED_IS_CURRENT_EDUCATION_PERIOD = 'SET_SELECTED_IS_CURRENT_EDUCATION_PERIOD'

export const FETCH_ORG_SETTINGS_PENDING = 'FETCH_ORG_SETTINGS_PENDING'
export const FETCH_ORG_SETTINGS_FULFILLED = 'FETCH_ORG_SETTINGS_FULFILLED'
export const FETCH_ORG_SETTINGS_REJECTED = 'FETCH_ORG_SETTINGS_REJECTED'

export const RESET_GRANT_REVOKE_TEACHER_ACCESS = 'RESET_GRANT_REVOKE_TEACHER_ACCESS'
export const RESET_ENABLE_DISABLE_TEACHER_AUTHORIZATION =
  'RESET_ENABLE_DISABLE_TEACHER_AUTHORIZATION'

export const SEARCH_FOR_TEACHER_PENDING = 'SEARCH_FOR_TEACHER_PENDING'
export const SEARCH_FOR_TEACHER_FULFILLED = 'SEARCH_FOR_TEACHER_FULFILLED'
export const SEARCH_FOR_TEACHER_REJECTED = 'SEARCH_FOR_TEACHER_REJECTED'

export const RESET_SEARCH_FOR_TEACHER = 'RESET_SEARCH_FOR_TEACHER'

export const FETCH_ALLOWED_TO_GRANT_ACCESS_PENDING = 'FETCH_ALLOWED_TO_GRANT_ACCESS_PENDING'
export const FETCH_ALLOWED_TO_GRANT_ACCESS_FULFILLED = 'FETCH_ALLOWED_TO_GRANT_ACCESS_FULFILLED'
export const FETCH_ALLOWED_TO_GRANT_ACCESS_REJECTED = 'FETCH_ALLOWED_TO_GRANT_ACCESS_REJECTED'

export const GRANT_TEACHER_ACCESS_TO_COURSE_PENDING = 'GRANT_TEACHER_ACCESS_TO_COURSE_PENDING'
export const GRANT_TEACHER_ACCESS_TO_COURSE_FULFILLED = 'GRANT_TEACHER_ACCESS_TO_COURSE_FULFILLED'
export const GRANT_TEACHER_ACCESS_TO_COURSE_REJECTED = 'GRANT_TEACHER_ACCESS_TO_COURSE_REJECTED'
export const RESET_GRANT_TEACHER_ACCESS_TO_COURSE = 'RESET_GRANT_TEACHER_ACCESS_TO_COURSE'

export const TEACHERS_GRANTED_ACCESS_PENDING = 'TEACHERS_GRANTED_ACCESS_PENDING'
export const TEACHERS_GRANTED_ACCESS_FULFILLED = 'TEACHERS_GRANTED_ACCESS_FULFILLED'
export const TEACHERS_GRANTED_ACCESS_REJECTED = 'TEACHERS_GRANTED_ACCESS_REJECTED'

export const REMOVE_TEACHER_GRANTED_ACCESS_PENDING = 'REMOVE_TEACHER_GRANTED_ACCESS_PENDING'
export const REMOVE_TEACHER_GRANTED_ACCESS_FULFILLED = 'REMOVE_TEACHER_GRANTED_ACCESS_FULFILLED'
export const REMOVE_TEACHER_GRANTED_ACCESS_REJECTED = 'REMOVE_TEACHER_GRANTED_ACCESS_REJECTED'

export const FETCH_AUTHORIZED_TEACHERS_PENDING = 'FETCH_AUTHORIZED_TEACHERS_PENDING'
export const FETCH_AUTHORIZED_TEACHERS_FULFILLED = 'FETCH_AUTHORIZED_TEACHERS_FULFILLED'
export const FETCH_AUTHORIZED_TEACHERS_REJECTED = 'FETCH_AUTHORIZED_TEACHERS_REJECTED'

export const TOGGLE_AUTHORIZED_TEACHERS_ACCESS_PENDING = 'TOGGLE_AUTHORIZED_TEACHERS_ACCESS_PENDING'
export const TOGGLE_AUTHORIZED_TEACHERS_ACCESS_FULFILLED =
  'TOGGLE_AUTHORIZED_TEACHERS_ACCESS_FULFILLED'
export const TOGGLE_AUTHORIZED_TEACHERS_ACCESS_REJECTED =
  'TOGGLE_AUTHORIZED_TEACHERS_ACCESS_REJECTED'

export const UPDATE_ORG_SETTINGS_PENDING = 'UPDATE_ORG_SETTINGS_PENDING'
export const UPDATE_ORG_SETTINGS_FULFILLED = 'UPDATE_ORG_SETTINGS_FULFILLED'
export const UPDATE_ORG_SETTINGS_REJECTED = 'UPDATE_ORG_SETTINGS_REJECTED'

export const FETCH_DEADLINES_PENDING = 'FETCH_DEADLINES_PENDING'
export const FETCH_DEADLINES_FULFILLED = 'FETCH_DEADLINES_FULFILLED'
export const FETCH_DEADLINES_REJECTED = 'FETCH_DEADLINES_REJECTED'

export const FETCH_ALLOWED_SSD_MATERIALS_PENDING = 'FETCH_ALLOWED_SSD_MATERIALS_PENDING'
export const FETCH_ALLOWED_SSD_MATERIALS_FULFILLED = 'FETCH_ALLOWED_SSD_MATERIALS_FULFILLED'
export const FETCH_ALLOWED_SSD_MATERIALS_REJECTED = 'FETCH_ALLOWED_SSD_MATERIALS_REJECTED'

export const FETCH_SPECIAL_DIGITAL_FORMATS_PENDING = 'FETCH_SPECIAL_DIGITAL_FORMATS_PENDING'
export const FETCH_SPECIAL_DIGITAL_FORMATS_FULFILLED = 'FETCH_SPECIAL_DIGITAL_FORMATS_FULFILLED'
export const FETCH_SPECIAL_DIGITAL_FORMATS_REJECTED = 'FETCH_SPECIAL_DIGITAL_FORMATS_REJECTED'

export const FETCH_EXAM_PRICING_PENDING = 'FETCH_EXAM_PRICING_PENDING'
export const FETCH_EXAM_PRICING_FULFILLED = 'FETCH_EXAM_PRICING_FULFILLED'
export const FETCH_EXAM_PRICING_REJECTED = 'FETCH_EXAM_PRICING_REJECTED'

export const FETCH_EXAM_DECISIONS_PENDING = 'FETCH_EXAM_DECISIONS_PENDING'
export const FETCH_EXAM_DECISIONS_FULFILLED = 'FETCH_EXAM_DECISIONS_FULFILLED'
export const FETCH_EXAM_DECISIONS_REJECTED = 'FETCH_EXAM_DECISIONS_REJECTED'

export const UPDATE_EXAM_DECISIONS_PENDING = 'UPDATE_EXAM_DECISIONS_PENDING'
export const UPDATE_EXAM_DECISIONS_FULFILLED = 'UPDATE_EXAM_DECISIONS_FULFILLED'
export const UPDATE_EXAM_DECISIONS_REJECTED = 'UPDATE_EXAM_DECISIONS_REJECTED'

export const FETCH_EXAM_WINDOWS_PENDING = 'FETCH_EXAM_WINDOWS_PENDING'
export const FETCH_EXAM_WINDOWS_FULFILLED = 'FETCH_EXAM_WINDOWS_FULFILLED'
export const FETCH_EXAM_WINDOWS_REJECTED = 'FETCH_EXAM_WINDOWS_REJECTED'

export const EXAM_DECISIONS_FORM_NAME = 'examDecisionsForm'
export const EXAM_DECISIONS_FORM_FIELDS = {
  enableDecisions: 'enableStudentDecisions',
  standardDeadline: 'standardDecisionDeadline',
  secondSemesterDeadline: 'secondSemesterDecisionDeadline',
  teacherConfirmation: 'enableTeacherConfirmation',
}

export const FETCH_SETTINGS_EXAMDATE_PENDING = 'FETCH_SETTINGS_EXAMDATE_PENDING'
export const FETCH_SETTINGS_EXAMDATE_FULFILLED = 'FETCH_SETTINGS_EXAMDATE_FULFILLED'
export const FETCH_SETTINGS_EXAMDATE_REJECTED = 'FETCH_SETTINGS_EXAMDATE_REJECTED'

export const SETTINGS_PANELS = {
  partForm: 'participationFormPanel',
  examAdmin: 'examAdministrationPanel',
  examDecisions: 'examDecisionsPanel',
  rosterManagement: 'rosterManagementPanel',
  access: 'accessManagementPanel',
}

export const PAPER_EXAM = 'PAPER'
export const DIGITAL_EXAM = 'DIGITAL'
export const HYBRID_EXAM = 'HYBRID'
export const TEST_LOCATION_HOME = 'HOME'
export const TEST_LOCATION_SCHOOL = 'SCHOOL'

export const DIGITAL_EXAM_OPT_OUT_PERIOD_DAYS = -1
export const DIGITAL_EXAM_OPT_OUT_PERIOD_START_TIME = '12:00:00'

export const DIGITAL_EXAM_INCLUSION_LIST = []

export const PARTICIPATING = 'Participating'

export const ADMIN_WINDOW_DISPLAY_NAME_REF_DATA = { ADMIN1: 'Standard' }

export const ADMIN_WINDOW_HIDE_EXAM_DATE = ['EXCEPTION', 'OFFCYCLE']
export const TEST_ADMIN_EXCEPTION = 'EXCEPTION'
export const TEST_ADMIN_OFFCYCLE = 'OFFCYCLE'

export const STUDENT_POPULATION_TOOLTIP =
  "For schools located in the US, US Territories, and Canada, in order to be considered for the AP School Honor Roll, provide your school's student enrollment, per grade. This is the total number of students who are enrolled at your institution full time, not only students taking AP. The data are used in AP score reports, including the AP Equity and Excellence report, and the AP School Honor Roll Progress Report. If you do not wish to be considered for the AP School Honor Roll, leave your 12th grade enrollment field BLANK. If your school does not have a 12th grade, enter zero. If you leave these fields blank, note that your school's AP Equity and Excellence Report will also be blank. These fields can continue to be updated after initial submission."
export const FRPL_STUDENTS_TOOLTIP =
  "For schools located in the US, US Territories, and Canada, if you wish to be considered for the AP Access Award, provide your school's overall percentage of qualifying students for Free or Reduced-Price Lunch or indicate that your school has adopted the Community Eligibility Provision (CEP) this year. This field can continue to be updated after initial submission. This additional AP Access Award is given to schools selected for the AP School Honor Roll when the percentage of underrepresented and/or low-income students who took at least one AP Exam before graduation is greater than or near the percentage in the school, demonstrating a clear and effective commitment to AP access. If you do not wish to be considered for the AP Access Award, leave the percent of qualifying students field BLANK. Schools may still be considered for the AP School Honor Roll even if they do not wish to be considered for the AP Access Award."

  export const DOMESTIC_PHONE_LENGTH = 10
  export const INTERNATIONAL_PHONE_LENGTH = 25
  