import { dollarFormat } from '../../../utils/numbers'
import { truncateLongNamesWithSpaces } from '../../../utils/common'
import styles from '../../../assets/style/scss/student-details.scss'

const mapStateToProps = (state, { exams = [], subsidies = []}) => {
  const { courseMap } = state.studentsByOrg
  const costBreakdown = exams.reduce((arr, { examPrice = {}, testCd, examId }) => {
    return examPrice?.totalPrice ? [ ...arr, {
      courseName: truncateLongNamesWithSpaces(courseMap[testCd].name, 45),
      cost: examPrice?.totalPrice,
      id: examId,
    }] : arr
  }, [])
  const subsidyTotal = subsidies.reduce((acc, { totalAmountApplied }) => acc + (totalAmountApplied || 0), 0)
  const examTotal =  exams.reduce((acc, { examPrice = {}}) => acc + (examPrice?.totalPrice || 0), 0)

  return {
    costBreakdown,
    totalCost: Math.max(0, examTotal - subsidyTotal),
    subsidies,
    shouldShowBreakdown: costBreakdown.length || subsidies.length,
  }
}

const iconStyle = { fontSize: '.8em' }

export class StudentDetailsTotalCost extends Component {
  state = { isOpen: false }

  render() {
    const { totalCost, costBreakdown, subsidies, shouldShowBreakdown } = this.props
    const { isOpen } = this.state

    return (
      <div>
        <h4 className="roboto-bold">AP Exams Cost</h4>
        <div className={styles['student-details-total-cost']}>{dollarFormat(totalCost)}</div>
        { shouldShowBreakdown
          ? <>
              <button type="button" className="btn-link cb-small-font-size" style={{padding: 0, marginTop: 10}} onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                { this.state.isOpen ? 
                  <>Hide Cost Breakdown <i className="cb-glyph cb-minus" style={iconStyle} aria-hidden={true} /></> :
                  <>Show Cost Breakdown <i className="cb-glyph cb-plus" style={iconStyle} aria-hidden={true} /></> }
              </button>
              { isOpen ? (
                <CSSTransition classNames="animate-slide" in={true} timeout={500} appear={true} enter={false} exit={false}>
                  <div className={styles['total-cost-breakdown']}>
                    {costBreakdown.map(exam => (
                      <div key={exam.id}>
                        <div>{exam.courseName}</div>
                        <div>{dollarFormat(exam.cost)}</div>
                      </div>
                    ))}
                    {subsidies.map(({ subsidyName, totalAmountApplied, subsidyAmount, timesApplied }) => (
                      <div key={subsidyName}>
                        <div>
                          <a href="https://apcentral.collegeboard.org/ap-2018/coordinator/state-fees-overview" target="_blank">
                          {subsidyName} <span className="sr-only">(Opens in a new window)</span>
                          </a>
                        </div>
                        <div>
                          {timesApplied > 1 ? `(${dollarFormat(subsidyAmount)} x ${timesApplied})` : null }
                          <span style={{ marginLeft: 5 }}>({dollarFormat(totalAmountApplied)})</span>
                        </div>
                      </div>
                    ))}
                    <div className={styles['breakdown-total']}>
                      <div>Total AP Exam Cost</div>
                      <div>{dollarFormat(totalCost)}</div>
                    </div>
                  </div> 
                </CSSTransition>
              ) : null }
            </> : null }
      </div>
    )
  }
}

export default connect(mapStateToProps)(StudentDetailsTotalCost)
