import { formValueSelector } from 'redux-form'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { OpenNewWindow } from '../common'
import { getSchoolNameAndAddress } from '../../selectors/settings'
import { getSortedExamWindowsForCourse } from '../../selectors/examWindows'
import {
  INCIDENT_REPORT_FORM_NAME,
  INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS,
  COURSES_WITH_HIDDEN_EXAM_FORM_SECTION,
} from '../../constants/IncidentReportConstants'
import { PAPER_EXAM, DIGITAL_EXAM } from '../../constants/SettingsConstants'
import { isEmpty } from '../../utils/common'

const mapStateToProps = state => {
  const schoolNameAndAddress = getSchoolNameAndAddress(state)
  const selector = formValueSelector(INCIDENT_REPORT_FORM_NAME)
  const selectedCourse = parseInt(selector(state, SHARED_FIELDS.testCode.name), 10)
  const selectedAdminWindow = selector(state, SHARED_FIELDS.examDate.name)
  const availableExamWindows = selectedCourse
    ? getSortedExamWindowsForCourse(state, selectedCourse)
    : []
  const selectedAdminWindowData =
    selectedCourse && selectedAdminWindow
      ? availableExamWindows.find(admin => admin.adminWindow === selectedAdminWindow)
      : {}
  const examFormat = !isEmpty(selectedAdminWindowData)
    ? selectedAdminWindowData?.examFormat
    : PAPER_EXAM
  const noEndOfCourseExam = !isEmpty(selectedAdminWindowData)
    ? selectedAdminWindowData?.noEndOfCourseExam
    : false

  return {
    ...schoolNameAndAddress,
    displayIncidentProceduresLink: examFormat === PAPER_EXAM || noEndOfCourseExam,
  }
}

const definitionListStyles = {
  dl: {
    display: 'block',
  },
  dt: {
    float: 'left',
    clear: 'left',
    margin: '0 10px 16px 0',
  },
  dd: {
    display: 'block',
    marginBottom: '16px',
  },
}

const CreateIncidentReport = ({
  schoolName,
  city,
  state,
  postalCode,
  country,
  displayIncidentProceduresLink,
}) => (
  <div className="row">
    <div className="col-sm-8 col-xs-12">
      <dl style={definitionListStyles.dl}>
        <dt style={definitionListStyles.dt}>Date:</dt>
        <dd style={definitionListStyles.dd}>
          {formatDate(new Date(), DATETIME_FORMATS.shortMonthDayYear)}
        </dd>
        <dt style={definitionListStyles.dt}>School Name:</dt>
        <dd style={definitionListStyles.dd}>
          {schoolName}
          <br />
          {city}, {state}, {postalCode}, {country}
        </dd>
      </dl>
    </div>
    <div className="col-sm-4 col-xs-12 cb-align-right">
      {displayIncidentProceduresLink ? (
        <OpenNewWindow url="https://apcentral.collegeboard.org/media/pdf/ap-administration-incidents.pdf">
          Administration Incidents Procedures
        </OpenNewWindow>
      ) : null}
    </div>
  </div>
)
export default connect(mapStateToProps, {})(CreateIncidentReport)
