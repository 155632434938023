import { DropdownPortal, ExamIntentDropdown } from '../../../common'
import ExamIntentConflict from '../updatemodals/ExamIntentConflict'
import ExamIntentSwap from '../updatemodals/ExamIntentSwap'
import ExamIntentUnused from '../updatemodals/ExamIntentUnused'
import ExamIntentUpdateOrder from '../updatemodals/ExamIntentUpdateOrder'
import {
  STUDENT_ENROLLMENT_STATUS_UNDECIDED,
  STUDENT_ENROLLMENT_STATUS_YES,
  STUDENT_ENROLLMENT_STATUS_NO,
  STUDENT_ENROLLMENT_STATUS_UNUSED,
  UPDATE_TYPE_INTENT,
  UPDATE_TYPE_DATE_INTENT,
  UPDATE_TYPE_UNUSED,
} from '../../../../constants/StudentConstants'
import { updateStudentExam } from '../../../../actions/studentsByOrg'
import { getOrderDeadlineIsPast } from '../../../../selectors/section'
import { isFeatureEnabled } from '../../../../selectors/features'
import { isDigitalExamInCutoffPeriod } from '../../../../selectors/examWindows'
import { openModal } from '../../../../actions/app'

const MENU_NAME = 'decisionOptions'

const mapStateToProps = (state, ownProps) => {
  const {
    user: {
      data: { isCoordinator },
    },
    status: {
      data: { isSubmitted },
    },
    settingsDeadlines: { data: settingsDeadlinesData },
    studentsByOrg,
    settingsEducationPeriod: { selectedIsCurrent, selectedEducationPeriod },
  } = state
  const { courseMap, update, sectionMap } = studentsByOrg
  const {
    exam: { examIntent, allowableExamIntents = [], sectionId, testCd, testWindow },
    exam,
  } = ownProps
  const filteredOptions = allowableExamIntents.filter(intent => intent !== examIntent)
  const hasExamIntentOptions = filteredOptions.length > 0
  const afterDigitalCutOff = isDigitalExamInCutoffPeriod(state, { testCd, testWindow })
  const deadlinesData = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}

  return {
    availableExamIntentOptions: hasExamIntentOptions ? allowableExamIntents : [],
    isChangeOrder:
      (isSubmitted || getOrderDeadlineIsPast(sectionMap[sectionId], deadlinesData)) &&
      isCoordinator,
    ...courseMap[exam.testCd],
    updating:
      (update.type === UPDATE_TYPE_INTENT || update.type === UPDATE_TYPE_DATE_INTENT) &&
      update.id === exam.id,
    isEnabled: isFeatureEnabled(state, 'CHANGE_EXAM_INTENT') && hasExamIntentOptions,
    afterDigitalCutOff,
  }
}

export class TakingExamDropdown extends Component {
  constructor(props) {
    super(props)
    this.openConflictModal = this.openConflictModal.bind(this)
    this.handleUpdateAction = this.handleUpdateAction.bind(this)
    this.modalCloseFocusElem = createRef()
  }

  isFlippingBetweenUndecidedAndNo(newIntent) {
    const { exam } = this.props
    return (
      (exam.examIntent === STUDENT_ENROLLMENT_STATUS_NO ||
        exam.examIntent === STUDENT_ENROLLMENT_STATUS_UNDECIDED) &&
      (newIntent === STUDENT_ENROLLMENT_STATUS_NO ||
        newIntent === STUDENT_ENROLLMENT_STATUS_UNDECIDED)
    )
  }

  isFlippingUnused(newIntent) {
    const { exam } = this.props
    return (
      (newIntent === STUDENT_ENROLLMENT_STATUS_YES &&
        exam.examIntent === STUDENT_ENROLLMENT_STATUS_UNUSED) ||
      newIntent === STUDENT_ENROLLMENT_STATUS_UNUSED
    )
  }

  handleUpdateAction(newIntent) {
    const {
      exam: { notTakingExamReasonCd, ...exam }, // pull notTakingExamReasonCd out of exam, will only be sent if examIntent is NO
      isChangeOrder,
      updateStudentExam,
      openModal,
      name,
      afterDigitalCutOff,
    } = this.props
    const showChangeOrderModal = isChangeOrder && !this.isFlippingBetweenUndecidedAndNo(newIntent)
    const showSwapModal =
      newIntent === STUDENT_ENROLLMENT_STATUS_NO && exam.swapWithEnrollmentIds?.length
    const showUnusedModal = this.isFlippingUnused(newIntent)
    const updatedExam = { ...exam, examIntent: newIntent }
    const domElem = document.getElementById(`${MENU_NAME}${exam.examId}`)
    let updateObj = null

    if (showSwapModal) {
      updateObj = { Component: ExamIntentSwap, type: UPDATE_TYPE_INTENT }
    } else if (showUnusedModal) {
      updateObj = {
        Component: ExamIntentUnused,
        type: exam.unused ? UPDATE_TYPE_UNUSED : UPDATE_TYPE_INTENT,
      }
    } else if (showChangeOrderModal) {
      updateObj = {
        Component: ExamIntentUpdateOrder,
        type:
          updatedExam.examIntent === STUDENT_ENROLLMENT_STATUS_YES && afterDigitalCutOff
            ? UPDATE_TYPE_DATE_INTENT
            : UPDATE_TYPE_INTENT,
      }
    }

    if (updateObj) {
      openModal('UpdateStudentExamModal', {
        ...updateObj,
        exam: updatedExam,
        modalCloseFocusElem: domElem,
      })
    } else if (newIntent === STUDENT_ENROLLMENT_STATUS_NO) {
      openModal('ExamIntentNotTakingReasonModal', {
        exam: { ...updatedExam, notTakingExamReasonCd },
        courseName: name,
        action: updateStudentExam,
        updateId: exam.examId,
        storeLocation: 'studentsByOrg',
        modalCloseFocusElem: domElem,
      })
    } else {
      updateStudentExam(updatedExam, UPDATE_TYPE_INTENT)
    }
  }

  openConflictModal() {
    const { openModal, exam } = this.props

    return openModal('UpdateStudentExamModal', {
      exam,
      Component: ExamIntentConflict,
      type: UPDATE_TYPE_INTENT,
      modalCloseFocusElem: this.modalCloseFocusElem.current.querySelectorAll('button')[0],
    })
  }

  render() {
    const {
      dropdownOptions,
      exam,
      availableExamIntentOptions,
      showLabel,
      updating,
      name,
      isEnabled,
    } = this.props

    return (
      <ExamIntentDropdown
        ref={this.modalCloseFocusElem}
        handleUpdateAction={this.handleUpdateAction}
        availableExamIntentOptions={availableExamIntentOptions}
        exam={exam}
        courseName={name}
        updating={updating}
        DropdownComponent={DropdownPortal}
        dropdownOptions={dropdownOptions}
        showLabel={showLabel}
        conflictModal={this.openConflictModal}
        isEditable={isEnabled && availableExamIntentOptions.length}
      />
    )
  }
}

export default connect(mapStateToProps, { updateStudentExam, openModal })(TakingExamDropdown)
