import { HeroIdentity } from '@myap/ui-library'
import { useCatapultAuth } from '../../utils/hooks'
import { isFeatureEnabled } from '../../selectors/features'
// import messages from '../../notifications/messages.json'
/*
 * SITECODE = 'apfym'
 * APP_ID = 366
 * */
import { SITECODE, APP_ID } from '../../constants/AppConstants'
import HeroOversizeBackgroundCircled from '../../assets/images/AP-Pilot-Hero-Oversize-circled.png'

export const AthenaGlobalHeader = connect(state => ({
  loginWidgetEnabled: isFeatureEnabled(state, 'DISABLE_LOGIN'),
  fetchingFeatures: state.features.fetching,
  isStudent: state.user.data.isStudent,
}))(({ loginWidgetEnabled, fetchingFeatures, isStudent }) => {
  const { origin, pathname, search } = window.location
  const redirectUrl = !search.length
    ? `${origin}${pathname}`
    : `${origin}${pathname}${encodeURIComponent(search)}`
  const { isAuthed } = useCatapultAuth()
  if (isAuthed && !isStudent) {
    return (
      <cbw-header
        app-id={APP_ID}
        site-title="AP"
        program-type="k-12"
        skip-target-id="main"
        use-icon-logo="true"
        use-search-widget="false"
        use-login-widget={(loginWidgetEnabled && !fetchingFeatures) || isAuthed}
        destination-url={redirectUrl}
      />
    )
  }
  return null
})

export const AthenaGlobalFooter = connect(state => ({ isStudent: state.user.data.isStudent }))(
  ({ isStudent }) => {
    const { isAuthed } = useCatapultAuth()
    if (isAuthed && !isStudent) {
      return <cbw-footer top-target-id="main" />
    }
    return null
  }
)

export const LoginHeroWidget = () => {
  const { origin, pathname, search } = window.location
  const redirectUrl = !search.length
    ? `${origin}${pathname}`
    : `${origin}${pathname}${encodeURIComponent(search)}`
  return (
    <HeroIdentity
      appId={APP_ID}
      redirectUrl={redirectUrl}
      heroContentText="Sign in to access your AP or Pre-AP resources and tools including AP Classroom."
      heroBackgroundImageUrl={HeroOversizeBackgroundCircled}
      identityDesc={
        <>
          Which type of account do you
          <br />
          want to <strong>sign in</strong> to?
        </>
      }
      studentLogin
      professionalLogin
      professionalBtnText="Educator"
    />
  )
}

/*
messages.json structure:
[
  {
    "id": "1",
    "header": "Header 1",
    "message": "Notification body 1",
    "type": "default"
  },
  {
    "id": "2",
    "header": "Header 2",
    "message": "Notification body 2",
    "type": "lv-emergency"
  }
]
*/
// export const AthenaNotificationWidget = () => {
//   if (messages?.length) {
//     return (
//       <cbw-notifications>
//         {messages.map(({ id, header, message, type }) => (
//           <cbw-notification key={id} nid={id} header={header} type={type}>
//             {message}
//           </cbw-notification>
//         ))}
//       </cbw-notifications>
//     )
//   }
//   return null
// }
