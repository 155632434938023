import { getFormValues } from 'redux-form'
import { Text, ConfirmEmailFields, LimitedStringValidation, LetterValidation } from '../../common'
import {
  SCHOOL_INFORMATION_FIELDS as FIELDS,
  SCHOOL_INFORMATION_FORM,
} from '../../../constants/SetupConstants'
import { getNestedAttr } from '../../../utils/common'

const mapStateToProps = state => {
  const values = getFormValues(SCHOOL_INFORMATION_FORM)(state)
  return {
    isRequired:
      getNestedAttr(values, FIELDS.AUTH_FNAME.name) ||
      getNestedAttr(values, FIELDS.AUTH_LNAME.name) ||
      getNestedAttr(values, FIELDS.AUTH_EMAIL.name) ||
      getNestedAttr(values, FIELDS.AUTH_MI.name),
  }
}

export default connect(mapStateToProps)(({ hideTitle, email, isRequired }) => (
  <fieldset>
    {!hideTitle ? (
      <>
        <legend className="h2" style={{ border: 0, paddingTop: 40 }}>
          Additional Authorized Staff{' '}
          <span className="roboto" style={{ fontSize: '.825em' }}>
            (optional)
          </span>
        </legend>
        <p>
          If you would like to authorize another person to speak on your behalf when contacting AP
          Services and to receive AP Exam administration-related communications, please complete the
          fields below.
        </p>
      </>
    ) : null}
    <div className="row">
      <div className="col-xs-5">
        <Text
          label="First Name"
          name={FIELDS.AUTH_FNAME.name}
          placeholder="Enter first name"
          maxlength={20}
          isRequired={isRequired || FIELDS.AUTH_FNAME.required}
          validate={[LimitedStringValidation]}
        />
      </div>
      <div className="col-xs-2">
        <Text
          label="MI"
          name={FIELDS.AUTH_MI.name}
          placeholder=" "
          maxlength={1}
          isRequired={FIELDS.AUTH_MI.required}
          validate={[LetterValidation]}
        />
      </div>
      <div className="col-xs-5">
        <Text
          label="Last Name"
          name={FIELDS.AUTH_LNAME.name}
          placeholder="Enter last name"
          maxlength={30}
          isRequired={isRequired || FIELDS.AUTH_LNAME.required}
          validate={[LimitedStringValidation]}
        />
      </div>
    </div>
    <ConfirmEmailFields
      label="Email"
      emailProps={{ ...FIELDS.AUTH_EMAIL, required: isRequired || FIELDS.AUTH_EMAIL.required }}
      showConfirmErrorOnLoad={Boolean(email)}
      commonPlaceholder="email address"
      maxlength={50}
    />
  </fieldset>
))
