import { Loader } from '../common'

const IncidentReportSaveDraftButton = ({ disabled, submitting, isDraft, setDraft, addStudent }) => {
  const handleSaveDraft = () => {
    // If user has entered student information but has not clicked
    // the Add button, we'll add the student for them
    addStudent()

    setDraft('isDraft', true)
  }

  return (
    <button
      type="submit"
      disabled={disabled}
      className="btn btn-sm btn-secondary"
      onClick={handleSaveDraft}
      style={{ position: 'relative', marginLeft: '40px' }}
    >
      Save Draft
      {isDraft && submitting ? (
        <Loader size="sm" style={{ position: 'absolute', top: '12px', right: '10px' }} />
      ) : null}
    </button>
  )
}

export default IncidentReportSaveDraftButton
