import { openModal } from '../../actions/app'
import { getRoom } from './utils'

import styles from '../../assets/style/scss/exam-room.scss'

const mapStateToProps = (state, { roomId }) => {
  const { examRoomSchedule, examRoomStudents } = state
  const { [roomId]: students = []} = examRoomStudents

  return {
    room: getRoom(examRoomSchedule.data, roomId),
    numOfStudents: students.length,
  }
}

const ExamRoomStudents = ({ numOfStudents, room, openModal }) => {
  const btnRef = useRef(null)

  if (numOfStudents !== 0) return null

  return (
    <div className={styles['exam-room-students-empty']}>
      <div>No students have been assigned to this room.</div>
      <button
        type="button"
        className="btn btn-link"
        ref={btnRef}
        aria-haspopup="dialog"
        onClick={() =>
          openModal('ExamRoomAssignStudentsModal', {
            ...room,
            modalCloseFocusElem: btnRef.current,
          })
        }
      >
        Click here to begin assigning students.
      </button>
    </div>
  )
}

export default connect(
  mapStateToProps,
  { openModal }
)(ExamRoomStudents)
