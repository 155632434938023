import { ACTIVE_ALERT_FILTER, DIGITAL_EXAM_FILTER } from '../../../constants/FilterConstants'
import FilterCheckbox from '../common/FilterCheckbox'

export const DigitalOnlyFilterCheckbox = ({ label = DIGITAL_EXAM_FILTER.label }) => (
  <FilterCheckbox
    id="digitalonly"
    label={
      <>
        <i className="cb-glyph cb-computer" aria-hidden={true} /> <span>{label}</span>
      </>
    }
    category={DIGITAL_EXAM_FILTER.filter}
  />
)

export const ActiveAlertsFilterCheckbox = ({ label = ACTIVE_ALERT_FILTER.label }) => (
  <FilterCheckbox
    id="activealert"
    label={
      <>
        <span className="alert-icon">
          <i className="cb-glyph cb-glyph-circular cb-exclamation" aria-hidden={true} />
        </span>
        <span>{label}</span>
      </>
    }
    category={ACTIVE_ALERT_FILTER.filter}
  />
)
