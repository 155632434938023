import ViewJoinCodeButton from '../common/ViewJoinCodeButton'
import { CODE_TYPES } from '../../../constants/SectionConstants'

export default ({section}) => {
  const labelStyle = {
    display: 'inline-block',
    marginRight: '8px'
  }
  const joinCodeStyle = {
    padding: '6px',
    textAlign: 'center',
    border: '1px solid #ccc'
  }

  return (
    <div>
      <div className="roboto-slab-bold" style={labelStyle}>Join Code</div>
      <input type="text" className="cb-digital-gray-bg roboto-slab-bold" style={joinCodeStyle} readOnly value={section[CODE_TYPES.join.prop]} size="8" aria-label={`Join code ${section[CODE_TYPES.join.prop]}`} />
      <ViewJoinCodeButton section={section} />
    </div>
  )
}
