import { Route, Switch } from 'react-router-dom'
import * as pages from '../../containers'
import { CSRSearchNavigation } from '../navigation'
import CSRSupportTool from '../csr/CSRSupportTool'
import { Loader, Error } from '../common'
import CoordinatorApp from './CoordinatorApp'
import { isEmpty } from '../../utils/common'

// We want to get the linkRoute and linkText so, even if we're a CSR, we can
// display the Back links when needed.
const mapStateToProps = state => {
  const {
    user,
    nav: { linkRoute, linkText },
  } = state
  return {
    user,
    linkRoute,
    linkText,
  }
}

const MainAppRoute = connect(mapStateToProps)(props => {
  const {
    user: {
      data: { selectedOrgId, isCoordinator, isSDP },
      isLoading,
      error,
    },
    linkRoute,
    linkText,
  } = props
  const orgLoaded = isCoordinator && selectedOrgId

  return (
    <>
      <CSRSearchNavigation />
      {orgLoaded && !isSDP ? <CSRSupportTool /> : null}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="container" style={{ marginTop: 30 }}>
          <Error title="Error Retrieving Organization" message={error} />
        </div>
      ) : orgLoaded ? (
        <CoordinatorApp showSecondaryNav={!isEmpty(linkRoute) && !isEmpty(linkText)} />
      ) : (
        <pages.DashboardPage />
      )}
    </>
  )
})

export default connect(mapStateToProps)(() => {
  return (
    <Switch>
      <Route component={MainAppRoute} />
      <Route component={pages.NotFoundPage} />
    </Switch>
  )
})
