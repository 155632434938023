import { reduxForm, getFormValues, change } from 'redux-form'
import { Input, Text, Label, Autosuggest, PositiveNumberValidation } from '../../common'
import EditButton from '../../settings/participationform/EditButton'
import {
  EXAM_ADMINISTRATION_FORM,
  EXAM_ADMINISTRATION_FIELDS_TWO,
  EXAM_ADMINISTRATION_FIELDS_ONE,
} from '../../../constants/SetupConstants'
import { getAvailableOrgs } from '../../../actions/typeaheads'
import { updateOrgSettings, changeStep } from '../../../actions/settingsOrg'
import { isEmpty } from '../../../utils/common'
import { getCountryName } from '../../../utils/addresses'

const normalize = val => val === 'true'

const defaultSchoolInfo = {}
const hasSavedSchool = obj => obj && Object.keys(obj).find(k => obj[k])
const showSchool = ({ name, city, aiCode, state }) =>
  name ? (
    <div>
      <div>
        {name} {aiCode ? <>({aiCode})</> : ''}
      </div>
      <div>
        {city || ''}
        {city && state ? ', ' : ''}
        {state || ''}
      </div>
    </div>
  ) : (
    ''
  )

const checkNotOwnSchool = (value, allValues, props) => {
  const { aiCode = '' } = allValues[props.fields.ORDER_SCHOOL.name] || {}
  return aiCode === props.selectedAiCode
    ? `You cannot enter your own school code. Enter a different school's code or select "Our school will order and administer most of our students' exams" to continue with your setup process.`
    : undefined
}

const formatSchoolName = schoolInfo => {
  const isObject = typeof schoolInfo === 'object'
  return schoolInfo && isObject
    ? !isEmpty(schoolInfo)
      ? `${schoolInfo.name} (${schoolInfo.aiCode})`
      : ''
    : schoolInfo
}

const createSavedSchoolObj = ({ orgId, orgName, city, aiList, state, country }) => ({
  aiCode: aiList[0]?.ai_code,
  city,
  name: orgName,
  state: state || getCountryName(country),
  orgId,
})

const mapStateToProps = (state, ownProps) => {
  const { selectedOrgId, selectedRole, roles } = state.user.data
  const { readOnly } = ownProps
  const isLevelOne = roles[selectedRole]?.level === 1
  const selectedAiCode = roles[selectedRole].aiCode
  const fields = isLevelOne ? EXAM_ADMINISTRATION_FIELDS_ONE : EXAM_ADMINISTRATION_FIELDS_TWO
  const formVals = getFormValues(EXAM_ADMINISTRATION_FORM)(state) || {}
  const orgSettings = state.settingsOrg[selectedOrgId] || {}
  const savedSchool = formVals[fields.ORDER_SCHOOL.name]
  const hasSchool = hasSavedSchool(savedSchool)

  return {
    showSchoolInfo:
      (formVals[fields.ORDER_LOCATION.name] === true && !readOnly) || (hasSchool && readOnly),
    initialValues: isLevelOne
      ? {
          ...orgSettings.data.examAdministration,
          [fields.ORDER_LOCATION.name]: true,
          [fields.ORDER_SCHOOL_DISPLAY.name]: formatSchoolName(
            orgSettings.data.examAdministration[fields.ORDER_SCHOOL.name]
          ),
        }
      : {
          ...orgSettings.data.examAdministration,
          [fields.ORDER_SCHOOL_DISPLAY.name]: formatSchoolName(
            orgSettings.data.examAdministration[fields.ORDER_SCHOOL.name]
          ),
        },
    orgSettings,
    savedSchool: hasSchool && savedSchool,
    fields,
    readOnly,
    selectedAiCode,
  }
}

const WillAdministerRadio = connect(mapStateToProps, { change })(
  ({ name, required, disabled, fields, change }) => (
    <Input
      type="radio"
      name={name}
      value={false}
      normalize={normalize}
      disabled={disabled}
      label="My school expects to order and administer at least one AP Exam (inclusive of Art and Design)."
      isRequired={required}
      onChange={() => {
        change(EXAM_ADMINISTRATION_FORM, fields.ORDER_SCHOOL.name, defaultSchoolInfo)
        change(EXAM_ADMINISTRATION_FORM, fields.ORDER_SCHOOL_DISPLAY.name, '')
      }}
    />
  )
)

const WillNotAdministerRadio = ({ name, required, disabled }) => (
  <Input
    type="radio"
    style={{ marginTop: '30px' }}
    name={name}
    normalize={normalize}
    value={true}
    disabled={disabled}
    label="Our students&#39; AP Exams will be ordered by another school or by our district office. The school code of the other school or district office that will order our students&#39; AP Exam is"
    isRequired={required}
  />
)

const ExamAdministrationFields = ({
  fields,
  readOnly,
  initialValues,
  showSchoolInfo,
  savedSchool,
  selectedAiCode,
  change,
}) => {
  if (!readOnly) {
    return (
      <>
        <fieldset
          role="radiogroup"
          aria-labelledby="who-will-administer-selection-group"
          aria-required="true"
          style={{ marginBottom: '24px' }}
        >
          <legend className="cb-base-font cb-required" id="who-will-administer-selection-group">
            Please tell us about who will be ordering and administering your students&#39; exams.
            Note that once you complete the setup process, you will no longer be able to edit this
            selection.
          </legend>
          <WillAdministerRadio
            name={fields.ORDER_LOCATION.name}
            required={!readOnly && fields.ORDER_LOCATION.required}
            disabled={readOnly || fields.ORDER_LOCATION.disabled}
          />
          <WillNotAdministerRadio
            name={fields.ORDER_LOCATION.name}
            required={!readOnly && fields.ORDER_LOCATION.required}
            disabled={readOnly || fields.ORDER_LOCATION.disabled}
          />
          <div style={{ marginLeft: '20px', marginTop: '8px' }}>
            <p
              aria-labelledby={`${fields.ORDER_LOCATION.name}-true`}
              style={{
                fontSize: '1em',
                color: fields.ORDER_LOCATION.disabled ? '#989795' : null,
              }}
            >
              <strong>Note:</strong> If your students&#39; AP Exams are ordered by another school or
              district office, those AP Exams must be administered at a central location.
            </p>

            {showSchoolInfo ? (
              <>
                <div style={{ width: '60%' }}>
                  <Autosuggest
                    name={fields.ORDER_SCHOOL_DISPLAY.name}
                    label="Enter the school code of the school or district office who will order your exams."
                    placeholder="Enter school code..."
                    isRequired={!readOnly && fields.ORDER_SCHOOL.required}
                    disabled={readOnly}
                    fetchSuggestions={suggestion =>
                      getAvailableOrgs(suggestion, {
                        searchWithinAP: true,
                      })
                    }
                    renderSuggestion={suggestion => {
                      return showSchool(createSavedSchoolObj(suggestion))
                    }}
                    selectSuggestion={val => {
                      change(fields.ORDER_SCHOOL.name, val)
                      change(fields.ORDER_SCHOOL_DISPLAY.name, formatSchoolName(val))
                    }}
                    normalizeSuggestion={val =>
                      Object.keys(val).length ? createSavedSchoolObj(val) : defaultSchoolInfo
                    }
                    onChange={e => {
                      // if user clears the org search field
                      if (e.target.value.length === 0) {
                        change(fields.ORDER_SCHOOL.name, defaultSchoolInfo)
                      }
                    }}
                    validate={[checkNotOwnSchool]}
                  />
                </div>
                <p>
                  <strong>Note:</strong> If your students&#39; AP Exams are ordered by another
                  school or district office, don&#39;t enter your own school code on this page. AP
                  Exams must be ordered by and administered at a central location.
                </p>
              </>
            ) : null}
          </div>
        </fieldset>
        <fieldset
          role="radiogroup"
          aria-labelledby="describe-exam-policy-selection-group"
          aria-required="true"
          style={{ marginBottom: '24px' }}
        >
          <legend
            className="cb-base-font cb-required"
            id="describe-exam-policy-selection-group"
            role="presentation"
          >
            Which best describes your school’s AP Exam Policy?
          </legend>
          <Input
            type="radio"
            name={fields.EXAM_POLICY.name}
            value="M"
            label="For all courses, we require all students in the course to take the exam."
            isRequired={!readOnly && fields.ORDER_LOCATION.required}
            isChecked={true}
          />
          <Input
            type="radio"
            name={fields.EXAM_POLICY.name}
            style={{ marginTop: '30px' }}
            value="O"
            label="For all courses, students are given the choice of taking the AP exam."
            isRequired={!readOnly && fields.ORDER_LOCATION.required}
          />
          <Input
            type="radio"
            name={fields.EXAM_POLICY.name}
            style={{ marginTop: '30px' }}
            value="V"
            label="Varies by course&mdash;for some but not all AP courses, we require all students in the course to take the exam."
            isRequired={!readOnly && fields.ORDER_LOCATION.required}
          />
        </fieldset>
      </>
    )
  }

  return (
    <div style={{ color: '#989795' }}>
      <WillAdministerRadio
        name={fields.ORDER_LOCATION.name}
        required={!readOnly && fields.ORDER_LOCATION.required}
        disabled={readOnly || fields.ORDER_LOCATION.disabled}
      />
      <WillNotAdministerRadio
        name={fields.ORDER_LOCATION.name}
        required={!readOnly && fields.ORDER_LOCATION.required}
        disabled={readOnly || fields.ORDER_LOCATION.disabled}
      />
      <div style={{ marginLeft: '20px', marginTop: '8px' }}>
        <p
          aria-labelledby={`${fields.ORDER_LOCATION.name}-false`}
          style={{ fontSize: '1em', color: fields.ORDER_LOCATION.disabled ? '#989795' : null }}
        >
          <strong>Note:</strong> If your students&#39; AP Exams are ordered by another school or
          district office, don&#39;t enter your own school code on this page. AP Exams must be
          ordered by and administered at a central location.
        </p>

        {showSchoolInfo ? (
          <div style={{ width: '90%' }}>
            <Text
              name={fields.ORDER_SCHOOL.name}
              placeholder="None selected"
              disabled={readOnly}
              input={{
                value: `${initialValues[fields.ORDER_SCHOOL.name].name} (${
                  initialValues[fields.ORDER_SCHOOL.name].orgId
                })`,
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, { change })(
  reduxForm({
    form: EXAM_ADMINISTRATION_FORM,
    onSubmit: (data, dispatch, props) => {
      delete data[props.fields.ORDER_SCHOOL_DISPLAY.name]
      return updateOrgSettings({
        ...props.orgSettings.data,
        examAdministration: {
          ...data,
          schoolInfo: !data.schoolInfo ? defaultSchoolInfo : data.schoolInfo,
          complete: true,
        },
      })(dispatch)
    },
    onSubmitSuccess: (result, dispatch, props) => {
      dispatch(changeStep(3))
      window.scrollTo(0, 0)
    },
  })(ExamAdministrationFields)
)
