import {
  DATETIME_FORMATS,
  formatDateTime,
  isDateSameOrAfter,
  isDateBefore,
} from '@myap/ui-library/esm/date'
import { Modal, BasicSelect, Loader, DisplayExamDate } from '../../../common'
import LateReason from './common/LateReason'
import TestingLocation from './common/TestingLocation'
import RequestExamHasStarted from './common/RequestExamHasStarted'
import { getAggregatedFees } from '../../../../actions/pricing'
import { UPDATE_TYPE_DATE } from '../../../../constants/StudentConstants'
import { COURSE_FILTER } from '../../../../constants/FilterConstants'
import { dollarFormat } from '../../../../utils/numbers'
import { isEmpty } from '../../../../utils/common'
import { canChangeExamWindow } from '../../../../selectors/student'
import {
  getExamWindowsForCourse,
  getSortedExamWindowsForCourse,
  isDigitalExamInCutoffPeriod,
  getExamDay,
  formatExamWindow,
} from '../../../../selectors/examWindows'
import { isAdministeringDigitalExams } from '../../../../selectors/settings'
import {
  PAPER_EXAM,
  DIGITAL_EXAM,
  TEST_LOCATION_SCHOOL,
} from '../../../../constants/SettingsConstants'

import style from '../../../../assets/style/scss/orders.scss'
import { getFilters } from '../../../../actions/filters'

const TestDateMultiSelect = connect(
  state => {
    const {
      pricingAggregatedFees: { error: feesError, ...aggregatedFees },
      studentsByOrg: { selected, exams },
    } = state
    const selectedEnrollments = []
    for (let i = 0; i < exams.length; i++) {
      if (selected.includes(exams[i].enrollmentId)) {
        selectedEnrollments.push(exams[i])
      }
    }
    const courseFilter = getFilters(window.location.search)[COURSE_FILTER.filter] || []
    const selectedTestCd = parseInt(courseFilter[0], 10)
    const courseExamWindows = getExamWindowsForCourse(state, selectedTestCd)
    const administeringDigitalExams = isAdministeringDigitalExams(state)
    let showHasStudentStartedExam = false
    let showDigitalExamCutOffPeriodWarning = false
    const availableExamWindows = []
    // iterate over all selected enrollments
    for (let i = 0; i < selectedEnrollments.length; i++) {
      // build a list of available exam windows from each enrollment
      for (let j = 0; j < selectedEnrollments[i].testWindows.length; j++) {
        if (
          !availableExamWindows.find(
            ({ code }) => code === selectedEnrollments[i].testWindows[j].code
          )
        ) {
          availableExamWindows.push(selectedEnrollments[i].testWindows[j])
        }
      }
      // if any of the selected enrollments is paper and exam window switching from is today or has passed
      if (
        selectedEnrollments[i].examFormat === PAPER_EXAM &&
        isDateSameOrAfter(
          new Date(),
          courseExamWindows[selectedEnrollments[i].testWindow]?.examDateTime
        )
      ) {
        showHasStudentStartedExam = true
      }
      // if any selected enrollments are in the digital cut-off warning period
      if (
        isDigitalExamInCutoffPeriod(state, {
          testCd: selectedTestCd,
          testWindow: selectedEnrollments[i].testWindow,
        }) &&
        isDateBefore(
          new Date(),
          getExamDay(state, selectedTestCd, selectedEnrollments[i].testWindow)
        )
      ) {
        showDigitalExamCutOffPeriodWarning = true
      }
    }
    const availableAdmins = []
    getSortedExamWindowsForCourse(state, selectedTestCd, availableExamWindows).forEach(w => {
      if (
        w.examFormat === PAPER_EXAM ||
        !isDigitalExamInCutoffPeriod(state, { testCd: w.testCd, testWindow: w.adminWindow })
      ) {
        availableAdmins.push({
          label: `${w.displayName} - ${formatExamWindow(
            w.examDateTime,
            `${DATETIME_FORMATS.shortMonthDay} ${DATETIME_FORMATS.hour}`,
            w.examFormat === DIGITAL_EXAM && administeringDigitalExams,
            true
          )}`,
          value: w.adminWindow,
        })
      }
    })
    return {
      isSubmitted: state.status.data.isSubmitted,
      aggregatedFees,
      selectedEnrollments,
      error: feesError || state.studentsByOrg.multi.error,
      educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
      courseExamWindows,
      showHasStudentStartedExam,
      showDigitalExamCutOffPeriodWarning,
      availableAdmins,
      administeringDigitalExams,
    }
  },
  { getAggregatedFees }
)(props => {
  const {
    getAggregatedFees,
    modalProps,
    getFooterActions,
    courseName,
    isSubmitted,
    selectedEnrollments,
    error,
    educationPeriod,
    aggregatedFees,
    courseExamWindows,
    availableAdmins,
    showHasStudentStartedExam,
    showDigitalExamCutOffPeriodWarning,
    setSuccessText,
    administeringDigitalExams,
  } = props
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedLateReason, setSelectedLateReason] = useState(null)
  const [selectedTestLocation, setTestLocation] = useState(null)
  const [examStarted, updateExamStarted] = useState(null)
  const [attested, setAttested] = useState(null)
  const { lateTestingFeeApplies: requiresReason, examFormat } =
    courseExamWindows[selectedDate] || {}
  const isDigital = examFormat === DIGITAL_EXAM
  const attemptedChangesCount = selectedEnrollments.length
  const actualChanges = selectedDate
    ? selectedEnrollments.filter(s => {
        let reasonChanging = false
        let locationChanging = false
        const examWindowChanging =
          canChangeExamWindow(s) &&
          s.testWindows.find(t => t.code === selectedDate) &&
          selectedDate !== s.testWindow
        if (examWindowChanging && requiresReason && selectedLateReason !== s.lateReasonCode) {
          reasonChanging = true
        }
        if (examWindowChanging && isDigital && selectedTestLocation !== s.testLocation) {
          locationChanging = true
        }
        return examWindowChanging || reasonChanging || locationChanging
      })
    : selectedEnrollments
  const actualChangesCount = actualChanges.length
  const selectionsComplete =
    selectedDate &&
    (!requiresReason || selectedLateReason) &&
    (!showHasStudentStartedExam || examStarted !== null) &&
    attested &&
    (!isDigital ||
      (isDigital && !administeringDigitalExams) ||
      (isDigital && administeringDigitalExams && !isEmpty(selectedTestLocation)))
  const hasAnySpecialExamFormats = selectedEnrollments.some(
    exam =>
      exam.specialDigitalFormats && Object.keys(exam.specialDigitalFormats).length > 0 && isDigital
  )

  useEffect(() => {
    if (selectedLateReason && selectedDate && actualChangesCount > 0)
      getAggregatedFees(actualChanges, educationPeriod, selectedDate, selectedLateReason)

    setAttested(isDigital || !administeringDigitalExams)

    if (!isDigital) {
      setTestLocation(null)
    } else if (isDigital && !administeringDigitalExams) {
      setTestLocation(TEST_LOCATION_SCHOOL)
    }
    if (!requiresReason) setSelectedLateReason(null)
  }, [selectedLateReason, selectedDate])

  const submittedReminder = isSubmitted
    ? 'You will need to review and submit your change order to apply this update.'
    : null

  useEffect(() => {
    setSuccessText(
      <>
        The <strong>{courseName}</strong> exam date has successfully been updated for{' '}
        {actualChangesCount} student{actualChangesCount > 1 ? 's' : ''}. You will need to submit
        your changes on the order screen before they apply.
      </>
    )
  }, [selectedDate, selectedLateReason, selectedTestLocation])

  return (
    <Modal
      {...modalProps}
      headerTitle="Change Exam Date"
      footerActions={getFooterActions(
        actualChanges,
        {
          type: UPDATE_TYPE_DATE,
          lateReasonCode: selectedLateReason,
          testWindow: selectedDate,
          testLocation: selectedTestLocation,
          ...(examStarted !== null && { examStarted }),
        },
        !selectionsComplete || actualChangesCount < 1
      )}
    >
      {showDigitalExamCutOffPeriodWarning && (
        <>
          <div
            className="alert alert-warning cb-alert-heading"
            style={{ marginBottom: 0 }}
            role="alert"
            tabIndex="0"
          >
            <h2>Cannot Undo</h2>
          </div>
          <p>
            <strong>Important:</strong> One or more of your selected student&#39;s exam date is past
            the registration deadline. If you change their exam date now, they won&#39;t be able to
            test on this exam date. Click <strong>Update</strong> to proceed with changing the
            student&#39;s exam date. Click <strong>Cancel</strong> to keep the student&#38;s exam
            date.
          </p>
        </>
      )}
      {availableAdmins.length > 0 ? (
        <div className="form-group">
          <BasicSelect
            label="Exam Date"
            options={availableAdmins}
            input={{
              name: 'multiSelectTestDayType',
              value: selectedDate,
              onChange: e => setSelectedDate(e.target.value),
            }}
          />
        </div>
      ) : (
        <p>
          Call AP Services for Educators to confirm available administration dates for this exam
          (877-274-6474 or 212-632-1781).
        </p>
      )}
      {selectedDate && actualChangesCount > 0 ? (
        <>
          <LateReason
            isLateTestingWindow={requiresReason}
            updateLateReason={e => setSelectedLateReason(e.target.value)}
            lateReasonCode={selectedLateReason}
          />
          <TestingLocation
            isDigital={isDigital}
            testLocation={selectedTestLocation}
            updateTestLocation={e => setTestLocation(e.target.value)}
            attestTestLocation={e => setAttested(e.target.checked)}
            multiStudentUpate={true}
          />
        </>
      ) : null}

      {selectedDate && (selectedLateReason || !requiresReason) ? (
        <>
          <hr className={style['order-border-warning']} />
          <RequestExamHasStarted
            showHasStudentStartedExam={showHasStudentStartedExam}
            examStarted={examStarted}
            updateHasStartedExam={updateExamStarted}
            isMulti={true}
          />
          {hasAnySpecialExamFormats && (
            <p style={{ fontWeight: 'bold' }}>
              Some students have accommodations which must be confirmed for the digital exams. Go to
              the Student Details page to review and verify the accommodations and exam format
              needed for digital exams being taken by students with approved accommodations.
            </p>
          )}
          {selectedLateReason && actualChangesCount > 0 ? (
            <>
              {aggregatedFees.fetching ? (
                <Loader />
              ) : (
                <>
                  <p>{submittedReminder}</p>
                  <div className="table-responsive" style={{ marginBottom: '1.5em' }}>
                    <table className="table">
                      <caption className="sr-only">Review of late-testing cost changes</caption>
                      <thead>
                        <tr role="row">
                          <th scope="col">Students</th>
                          <th scope="col">Course</th>
                          <th scope="col">Exam Date</th>
                          <th scope="col">Late-Testing Fee</th>
                          <th scope="col">Total Late-Testing Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {actualChangesCount} of {attemptedChangesCount} selected student
                            {attemptedChangesCount > 1 ? 's' : ''}
                          </td>
                          <td>{courseName}</td>
                          <td>
                            <DisplayExamDate
                              course={{
                                testCd: courseExamWindows[selectedDate].testCd,
                                testWindow: selectedDate,
                              }}
                            />
                          </td>
                          <td className="text-right">{dollarFormat(aggregatedFees?.lateFee)}</td>
                          <td className="text-right">
                            {dollarFormat(aggregatedFees?.aggregateLateFee)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          ) : null}
        </>
      ) : null}
      {!requiresReason && selectedDate && actualChangesCount > 0 ? (
        <p style={{ marginTop: '2em' }}>
          You are changing the <strong>{courseName}</strong> exam date for {actualChangesCount} out
          of the {attemptedChangesCount} selected students. {submittedReminder}
        </p>
      ) : null}
      {actualChangesCount < 1 && selectedDate ? (
        <p>
          The selected students are either already scheduled to take the exam on the selected exam
          date or their exam dates can not be changed.
        </p>
      ) : null}
      {error ? (
        <p className="cb-error-msg" aria-live="polite">
          {error}
        </p>
      ) : null}
    </Modal>
  )
})

export default TestDateMultiSelect
