import { reduxForm, submit, isInvalid } from 'redux-form'
import { Modal, Input } from '../../components/common'
import { updateOrgSettings } from '../../actions/settingsOrg'

const SURVEY_FORM = 'surveyForm'
const LEARNING_MODE_FIELD = 'learningMode'
const LEARNING_MODE_DIFFERENT_FIELD = 'learningModeDifferentFromStandard'
const LEARNING_MODE_SCHOOL = 'FULL_TIME_IN_PERSON'
const LEARNING_MODE_HYBRID = 'SOME_IN_PERSON_AND_SOME_REMOTE'
const LEARNING_MODE_IN_PERSON_AND_REMOTE = 'PART_TIME_IN_PERSON_AND_PART_TIME_REMOTE'
const LEARNING_MODE_REMOTE = 'FULL_TIME_REMOTE'
const LEARNING_MODE_DIFFERENT_YES = 'YES'
const LEARNING_MODE_DIFFERENT_NO = 'NO'
const LEARNING_MODE_DIFFERENT_PRIMARILY = 'PRIMARILY'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsOrg: {
      [selectedOrgId]: { updating, data: orgInfo = {} },
    },
  } = state

  return {
    updating,
    orgInfo,
    invalid: isInvalid(SURVEY_FORM)(state),
  }
}

const MidYearLearningModeSurveyModal = ({
  updating,
  invalid,
  handleSubmit,
  submitSucceeded,
  submit,
}) => {
  const legendStyles = {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.5',
    paddingTop: '1em',
    paddingBottom: '.5em',
    marginBottom: '1em',
  }
  const footerActions = [
    {
      buttonLabel: (
        <>
          Continue <span className="cb-glyph cb-east" />
        </>
      ),
      isDismissable: false,
      isPrimary: true,
      busy: updating,
      isDisabled: updating || invalid,
      onClick: () => submit(SURVEY_FORM),
    },
  ]

  return (
    <Modal
      headerTitle="Mid-Year Setup"
      shouldCloseModal={submitSucceeded}
      footerActions={footerActions}
      hideCloseButton={true}
      isDismissable={false}
    >
      <h5>Reminder</h5>
      <ol>
        <li>
          Exam dates have been updated in the student roster. Students previously assigned to a
          "Standard" exam date are now assigned to Administration 1. Students previously assigned to
          a "Late" exam date are now assigned to Administration 2.{' '}
          <a
            href="https://www.collegeboard.org/apexamdates"
            target="_blank"
            rel="noopener noreferrer"
          >
            Review the full updated 2021 AP Exam schedule
          </a>
          , including details about which exams are paper and pencil and which are digital during
          each administration.
        </li>
        <li>
          You may assign students to a later testing window (Administration 2 or Administration 3)
          if necessary by changing the exam date in the student roster. <strong>Important:</strong>{' '}
          If you make any changes to your exam order, you need to <strong>submit</strong> those
          changes from the Orders page.
          <a
            href="https://apcentral.collegeboard.org/exam-administration-ordering-scores/ordering-fees/late-testing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Review the steps for changing the exam dates for an individual student or multiple
            students
          </a>
          .
        </li>
        <li>
          If you&#39;re moving students with approved accommodations to a digital exam, you&#39;ll
          need to review details about their accommodations for their digital exam format on the
          Student Details page (per student, per exam).{' '}
          <a
            href="https://apcentral.collegeboard.org/exam-administration-ordering-scores/administering-exams/preparing-for-exam-day/digital-accommodations/essentials/moving-students"
            target="_blank"
            rel="noopener noreferrer"
          >
            Review the steps for confirming a student’s accommodations for a digital exam
          </a>
          .
        </li>
      </ol>
      <p>
        <strong>Important:</strong> If you make any changes to your exam order, you need to{' '}
        <strong>submit</strong> those changes from the Orders page.
      </p>
      <h5>
        Please answer these questions about your school&#39;s AP course instruction this year.
      </h5>
      <form onSubmit={handleSubmit}>
        <fieldset role="radiogroup" aria-labelledby="question-1" aria-required="true">
          <legend className="cb-required" id="question-1" role="presentation" style={legendStyles}>
            Which option below best describes AP instruction at your school at the start of the
            school year? If school has yet to start, please indicate instructional plans as of
            today.
          </legend>
          <Input
            type="radio"
            name={LEARNING_MODE_FIELD}
            value={LEARNING_MODE_SCHOOL}
            label="Each student participates in full-time in-person instruction."
            isRequired={true}
          />
          <Input
            type="radio"
            name={LEARNING_MODE_FIELD}
            value={LEARNING_MODE_HYBRID}
            label="Each student participates in combination of part-time in-person instruction and remote learning."
            isRequired={true}
          />
          <Input
            type="radio"
            name={LEARNING_MODE_FIELD}
            value={LEARNING_MODE_IN_PERSON_AND_REMOTE}
            label="Some students attend in-person and others opt into fully remote learning."
            isRequired={true}
          />
          <Input
            type="radio"
            name={LEARNING_MODE_FIELD}
            value={LEARNING_MODE_REMOTE}
            label="Each student participates in full-time remote learning"
            isRequired={true}
          />
        </fieldset>
        <fieldset role="radiogroup" aria-labelledby="question-2" aria-required="true">
          <legend className="cb-required" id="question-2" role="presentation" style={legendStyles}>
            Has your school been following this instructional model all year?
          </legend>
          <Input
            type="radio"
            name={LEARNING_MODE_DIFFERENT_FIELD}
            value={LEARNING_MODE_DIFFERENT_YES}
            label="Yes"
            isRequired={true}
          />
          <Input
            type="radio"
            name={LEARNING_MODE_DIFFERENT_FIELD}
            value={LEARNING_MODE_DIFFERENT_PRIMARILY}
            label="Primarily"
            isRequired={true}
          />
          <Input
            type="radio"
            name={LEARNING_MODE_DIFFERENT_FIELD}
            value={LEARNING_MODE_DIFFERENT_NO}
            label="No"
            isRequired={true}
          />
        </fieldset>
      </form>
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  { submit, updateOrgSettings }
)(
  reduxForm({
    form: SURVEY_FORM,
    onSubmit: async (values, dispatch, props) => {
      const { orgInfo, updateOrgSettings } = props
      const data = {
        ...orgInfo,
        schoolInformation: {
          ...orgInfo.schoolInformation,
          schoolInfo: {
            ...orgInfo.schoolInformation.schoolInfo,
            midYearSurvey: {
              ...values,
            },
          },
        },
      }

      await updateOrgSettings(data)
    },
  })(MidYearLearningModeSurveyModal)
)
