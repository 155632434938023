import { DATETIME_FORMATS, formatDateTime } from '@myap/ui-library/esm/date'
import {
  CHECKIN_STATUSES,
  EXAM_STATUSES,
  MAKEUP_STATUSES,
  PRACTICE_STATUSES,
  SETUP_STATUSES,
  STATUS_NOT_APPLICABLE,
} from '../../constants/ExamReadinessConstants'
import * as c from '../../constants/FilterConstants'
import { DIGITAL_EXAM } from '../../constants/SettingsConstants'
import { filterByKey, searchByKeys } from './utils'

/** *********************************************************** */
/** ***************** DATA FILTERING FUNCTIONS **************** */
/** *********************************************************** */
const filterByTeacher = (dataElem, valuesToFilterBy) => {
  const { teacherIds = [] } = dataElem
  return valuesToFilterBy.find(({ value }) => teacherIds.find(t => t.toString() == value))
}

const filterByLoginStatus = ({ appInstalled }, valuesToFilterBy) =>
  valuesToFilterBy.find(
    ({ value }) =>
      (value === c.FILTER_YES && appInstalled) || (value === c.FILTER_NO && !appInstalled)
  )

const filterByExamStatus = ({ examStatus, checkinStatus }, valuesToFilterBy) =>
  valuesToFilterBy.find(({ value }) =>
    value.indexOf(c.CHECKIN_STATUS_FILTER.filter) !== -1 &&
    (!examStatus || examStatus === STATUS_NOT_APPLICABLE)
      ? value === `${c.CHECKIN_STATUS_FILTER.filter}${checkinStatus}`
      : value === `${c.EXAM_STATUS_FILTER.filter}${examStatus}`
  )

const filterByDigitalExam = ({ examFormat }, valuesToFilterBy) =>
  valuesToFilterBy.find(({ value }) => value === c.FILTER_YES && examFormat === DIGITAL_EXAM)

const filterByActiveAlert = (dataElem, valuesToFilterBy) => {
  const hasAlert = Object.keys(dataElem).find(
    key =>
      key.indexOf('Alert') !== -1 && dataElem[key].showAlert && !dataElem[key].showAlertAsWarning
  )
  return valuesToFilterBy.find(({ value }) => value === c.FILTER_YES && hasAlert)
}

export const EXAM_FILTER_FUNCTIONS = {
  [c.COURSE_FILTER.filter]: (d, v) => filterByKey(d, v, 'testCode'),
  [c.SECTION_FILTER.filter]: (d, v) => filterByKey(d, v, 'sectionId'),
  [c.ADMIN_FILTER.filter]: (d, v) => filterByKey(d, v, 'examWindow'),
  [c.EXAM_DATE_FILTER.filter]: (d, v) => filterByKey(d, v, 'examDateTime'),
  [c.SETUP_STATUS_FILTER.filter]: (d, v) => filterByKey(d, v, 'setupStatus'),
  [c.CHECKIN_STATUS_FILTER.filter]: (d, v) => filterByKey(d, v, 'checkinStatus'),
  [c.MAKEUP_STATUS_FILTER.filter]: (d, v) => filterByKey(d, v, 'makeupStatus'),
  [c.PRACTICE_STATUS_FILTER.filter]: (d, v) => filterByKey(d, v, 'combinedPracticeStatus'),
  [c.EXAM_STATUS_FILTER.filter]: filterByExamStatus,
  [c.LOGIN_STATUS_FILTER.filter]: filterByLoginStatus,
  [c.TEACHER_FILTER.filter]: filterByTeacher,
  [c.DIGITAL_EXAM_FILTER.filter]: filterByDigitalExam,
  [c.ACTIVE_ALERT_FILTER.filter]: filterByActiveAlert,
  [c.STUDENT_NAME_ID_FILTER.filter]: (d, v) => searchByKeys(d, v, ['apId'], ['lastName']),
}

export const getExamFilterTagData = (state, filter, value) => {
  const { courses = [], teacherMap = {}, examWindowMap, sectionMap = {} } = state
  let displayValue
  let filterProps

  switch (filter) {
    case c.ACTIVE_ALERT_FILTER.filter:
      filterProps = c.ACTIVE_ALERT_FILTER
      displayValue = 'Active Alerts Only'
      break
    case c.ADMIN_FILTER.filter:
      filterProps = c.ADMIN_FILTER
      displayValue = examWindowMap[value]?.displayName
      break
    case c.CHECKIN_STATUS_FILTER.filter:
      filterProps = c.CHECKIN_STATUS_FILTER
      displayValue = CHECKIN_STATUSES[value]
      break
    case c.COURSE_FILTER.filter:
      filterProps = c.COURSE_FILTER
      displayValue = courses.find(c => c.testCode.toString() === value)?.courseName
      break
    case c.DIGITAL_EXAM_FILTER.filter:
      filterProps = c.DIGITAL_EXAM_FILTER
      displayValue = c.DIGITAL_EXAM_FILTER.label
      break
    case c.EXAM_DATE_FILTER.filter:
      filterProps = c.EXAM_DATE_FILTER
      displayValue = `${formatDateTime(
        value,
        `${DATETIME_FORMATS.shortMonthDay} ${DATETIME_FORMATS.hour}`
      )}${value.split('-')[2] ? ' ET' : ''}`
      break
    case c.EXAM_STATUS_FILTER.filter:
      filterProps = c.EXAM_STATUS_FILTER
      displayValue =
        value.indexOf(c.EXAM_STATUS_FILTER.filter) !== -1
          ? EXAM_STATUSES[value.replace(c.EXAM_STATUS_FILTER.filter, '')]
          : CHECKIN_STATUSES[value.replace(c.CHECKIN_STATUS_FILTER.filter, '')]
      break
    case c.LOGIN_STATUS_FILTER.filter:
      filterProps = c.LOGIN_STATUS_FILTER
      displayValue = c.FILTER_DEFAULT_VALUES[value]
      break
    case c.MAKEUP_STATUS_FILTER.filter:
      filterProps = c.MAKEUP_STATUS_FILTER
      displayValue = MAKEUP_STATUSES[value]
      break
    case c.PRACTICE_STATUS_FILTER.filter:
      filterProps = c.PRACTICE_STATUS_FILTER
      displayValue = PRACTICE_STATUSES[value]
      break
    case c.SECTION_FILTER.filter:
      filterProps = c.SECTION_FILTER
      displayValue = sectionMap[value]?.name
      break
    case c.SETUP_STATUS_FILTER.filter:
      filterProps = c.SETUP_STATUS_FILTER
      displayValue = SETUP_STATUSES[value]
      break
    case c.STUDENT_NAME_ID_FILTER.filter:
      filterProps = c.STUDENT_NAME_ID_FILTER
      displayValue = `'${value}'`
      break
    case c.TEACHER_FILTER.filter:
      filterProps = c.TEACHER_FILTER
      displayValue = `${teacherMap[value]?.firstName} ${teacherMap[value]?.lastName}`
      break
    default:
      filterProps = {}
      displayValue = ''
  }

  return { ...filterProps, displayValue }
}
