import { setNavLink, unsetNavLink } from '../../actions/nav'
import {
  fetchSpecialExamRequests,
  fetchSecureMaterialsRequests,
  fetchNonSecureMaterialsRequests,
  fetchOrdersAfterDeadlineRequests,
  fetchExceptionTestingRequests,
  resetExceptionTestingApprovalRequestDetail,
  resetOrdersAfterDeadlineApprovalRequestDetail,
} from '../../actions/approvalRequests'
import { Loader } from '../../components/common'
import {
  SpecialExamsRequests,
  SecureMaterialsRequests,
  NonSecureMaterialsRequests,
  OrdersAfterDeadlineRequests,
  ExceptionTestingRequests,
  FilterApprovalRequestsControl,
} from '../../components/orders/coordinator'
import {
  getFilteredSpecialExamReqeusts,
  getFilteredSecureMaterialsReqeusts,
  getFilteredNonSecureMaterialsReqeusts,
  getFilteredOrdersAfterDeadlineRequests,
  getFilteredExceptionTestingRequests,
  getSpecialExamRequestsStatusProps,
  getSecureMaterialsRequestsStatusProps,
  getNonSecureMaterialsRequestsStatusProps,
  getOrdersAfterDeadlineRequestsStatusProps,
  getExceptionTestingRequestsStatusProps,
} from '../../selectors/order'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => {
  const { fetching: fetchingSpecialExamRequests, error: specialExamRequestsError } =
    getSpecialExamRequestsStatusProps(state)
  const { fetching: fetchingSecureMaterialsRequests, error: secureMaterialsRequestsError } =
    getSecureMaterialsRequestsStatusProps(state)
  const { fetching: fetchingNonSecureMaterialsRequests, error: nonSecureMaterialsRequestsError } =
    getNonSecureMaterialsRequestsStatusProps(state)
  const { fetching: fetchingOrdersAfterDeadlineRequests, error: ordersAfterDeadlineRequestsError } =
    getOrdersAfterDeadlineRequestsStatusProps(state)
  const { fetching: fetchingExceptionTestingRequests, error: exceptionTestingRequestsError } =
    getExceptionTestingRequestsStatusProps(state)
  return {
    orgId: state.user.data.selectedOrgId,
    educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
    specialExamRequests: getFilteredSpecialExamReqeusts(state),
    secureMaterialsRequests: getFilteredSecureMaterialsReqeusts(state),
    nonSecureMaterialsRequests: getFilteredNonSecureMaterialsReqeusts(state),
    ordersAfterDeadlineRequests: getFilteredOrdersAfterDeadlineRequests(state),
    exceptionTestingRequests: getFilteredExceptionTestingRequests(state),
    fetchingSpecialExamRequests,
    fetchingSecureMaterialsRequests,
    fetchingNonSecureMaterialsRequests,
    fetchingOrdersAfterDeadlineRequests,
    fetchingExceptionTestingRequests,
    specialExamRequestsError,
    secureMaterialsRequestsError,
    nonSecureMaterialsRequestsError,
    ordersAfterDeadlineRequestsError,
    exceptionTestingRequestsError,
  }
}

const ApprovalRequestsPage = ({
  orgId,
  educationPeriod,
  specialExamRequests,
  secureMaterialsRequests,
  nonSecureMaterialsRequests,
  ordersAfterDeadlineRequests,
  exceptionTestingRequests,
  fetchingSpecialExamRequests,
  fetchingSecureMaterialsRequests,
  fetchingNonSecureMaterialsRequests,
  fetchingOrdersAfterDeadlineRequests,
  fetchingExceptionTestingRequests,
  specialExamRequestsError,
  secureMaterialsRequestsError,
  nonSecureMaterialsRequestsError,
  ordersAfterDeadlineRequestsError,
  exceptionTestingRequestsError,
  setNavLink,
  unsetNavLink,
  fetchSpecialExamRequests,
  fetchSecureMaterialsRequests,
  fetchNonSecureMaterialsRequests,
  fetchOrdersAfterDeadlineRequests,
  fetchExceptionTestingRequests,
  resetExceptionTestingApprovalRequestDetail,
  resetOrdersAfterDeadlineApprovalRequestDetail,
}) => {
  useEffect(() => {
    setNavLink(NAV_LINKS.orders, 'Back to order summary')
    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    if (educationPeriod) {
      fetchSpecialExamRequests(orgId, educationPeriod)
      fetchSecureMaterialsRequests(orgId, educationPeriod)
      fetchNonSecureMaterialsRequests(orgId, educationPeriod)
      fetchOrdersAfterDeadlineRequests(orgId, educationPeriod)
      fetchExceptionTestingRequests(orgId, educationPeriod)
    }
    return () => {
      resetExceptionTestingApprovalRequestDetail()
      resetOrdersAfterDeadlineApprovalRequestDetail()
    }
  }, [educationPeriod])

  return (
    <div className="container">
      <FilterApprovalRequestsControl />
      <h2>Approval Requests</h2>
      {fetchingSpecialExamRequests ? (
        <Loader />
      ) : (
        <SpecialExamsRequests
          data={specialExamRequests}
          error={specialExamRequestsError}
          id="specialExamRequestsTable"
        />
      )}
      {fetchingSecureMaterialsRequests ? (
        <Loader />
      ) : (
        <SecureMaterialsRequests
          data={secureMaterialsRequests}
          error={secureMaterialsRequestsError}
          id="secureMaterialsRequestTable"
        />
      )}
      {fetchingNonSecureMaterialsRequests ? (
        <Loader />
      ) : (
        <NonSecureMaterialsRequests
          data={nonSecureMaterialsRequests}
          error={nonSecureMaterialsRequestsError}
          id="nonSecureMaterialsRequestTable"
        />
      )}
      {fetchingExceptionTestingRequests ? (
        <Loader />
      ) : (
        <ExceptionTestingRequests
          data={exceptionTestingRequests}
          error={exceptionTestingRequestsError}
          id="exceptionTestingRequestTable"
        />
      )}
      {fetchingOrdersAfterDeadlineRequests ? (
        <Loader />
      ) : (
        <OrdersAfterDeadlineRequests
          data={ordersAfterDeadlineRequests}
          error={ordersAfterDeadlineRequestsError}
          id="ordersAfterDeadlineRequestTable"
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, {
  setNavLink,
  unsetNavLink,
  fetchSpecialExamRequests,
  fetchSecureMaterialsRequests,
  fetchNonSecureMaterialsRequests,
  fetchOrdersAfterDeadlineRequests,
  fetchExceptionTestingRequests,
  resetExceptionTestingApprovalRequestDetail,
  resetOrdersAfterDeadlineApprovalRequestDetail,
})(ApprovalRequestsPage)
