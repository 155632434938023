import {
  submit,
  hasSubmitFailed,
  isSubmitting,
  isValid,
  getFormSyncErrors,
  getFormError,
} from 'redux-form'
import { changeStep } from '../../actions/settingsOrg'
import { Loader } from '../common'
import { isEmpty } from '../../utils/common'

const mapStateToProps = (state, ownProps) => ({
  submitFailed: hasSubmitFailed(ownProps.form)(state),
  submitError: getFormError(ownProps.form)(state),
  hasValidationErrors: !isEmpty(getFormSyncErrors(ownProps.form)(state)),
  submitting: isSubmitting(ownProps.form)(state),
  valid: isValid(ownProps.form)(state),
  step: state.settingsOrg.setupStep,
})

const mapDispatchToProps = dispatch => bindActionCreators({ changeStep, submit }, dispatch)

class StepButtons extends Component {
  constructor() {
    super()
    this.state = { direction: null }
  }

  goStepBack() {
    let { changeStep, backStep } = this.props
    this.props.changeStep(backStep)
    window.scrollTo(0, 0)
  }

  render() {
    let {
      submitting,
      form,
      submit,
      hasValidationErrors,
      submitFailed,
      submitError,
      submitSucceeded,
      buttonText,
      openModal,
    } = this.props

    return (
      <div>
        {submitFailed ? (
          <p className="cb-error-msg text-right" role="alert" aria-live="polite">
            {!isEmpty(submitError)
              ? `Error: ${submitError}`
              : 'Error: An error occurred processing your request. Please try again.'}
          </p>
        ) : null}
        <div className="row">
          <div className="col-xs-4">
            <button
              type="button"
              className="btn-link"
              style={{ lineHeight: '48px', fontWeight: 700 }}
              onClick={this.goStepBack.bind(this)}
            >
              <i className="cb-glyph cb-west" aria-hidden={true} /> Back
            </button>
          </div>
          <div className="col-xs-8 text-right">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              disabled={submitting || hasValidationErrors}
              onClick={() => submit(form)}
            >
              {buttonText || 'Save & Continue'}
              {submitting ? (
                <Loader size="sm" style={{ position: 'absolute', top: '14px', right: '25px' }} />
              ) : null}
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StepButtons)
