import memoize from 'memoizee'
import * as c from '../../constants/FilterConstants'

/** *********************************************************** */
/** ***************** DATA FILTERING FUNCTIONS **************** */
/** *********************************************************** */
export const filterByKey = (dataElem, valuesToFilterBy, objCompareKey, additionalCheck) => {
  return valuesToFilterBy.find(
    ({ value }) =>
      dataElem[objCompareKey]?.toString() === value?.toString() &&
      (!additionalCheck || additionalCheck(dataElem))
  )
}

export const searchByKeys = (dataElem, valuesToFilterBy, exactMatchKeys, includeMatchKeys) =>
  valuesToFilterBy.find(({ value }) => {
    const searchElem = value.toLowerCase()
    return (
      exactMatchKeys.find(key => dataElem[key].toLowerCase() === searchElem) ||
      includeMatchKeys.find(key => dataElem[key].toLowerCase().indexOf(searchElem) !== -1)
    )
  })

const FILTER_SPECS = Object.keys(c).reduce(
  (filters, key) => (c[key]?.filter ? { ...filters, [c[key].filter]: c[key] } : filters),
  {}
)
const getFiltersByGroup = memoize(filters => {
  const keys = Object.keys(filters)
  return keys.length
    ? keys.reduce((obj, type) => {
        const groupType = FILTER_SPECS[type]?.group
        const group = obj[groupType] || []
        return {
          ...obj,
          [groupType || type]: [...group, ...filters[type].map(t => ({ filter: type, value: t }))],
        }
      }, {})
    : {}
})

export const filterData = memoize((data, selectedFilters, FILTER_FUNCTIONS) => {
  const filtered = []
  const selectedFiltersByGroup = getFiltersByGroup(selectedFilters)
  const filterTypes = Object.keys(selectedFiltersByGroup)
  const len = data && data.length

  if (len && filterTypes.length) {
    for (let i = 0; i < len; i += 1) {
      const elem = data[i]
      const test = filterTypes.every(type => {
        const valuesToFilterBy = selectedFiltersByGroup[type]
        const filterFunction = FILTER_FUNCTIONS[type] && memoize(FILTER_FUNCTIONS[type])
        return (
          !valuesToFilterBy.length || (filterFunction && filterFunction(elem, valuesToFilterBy))
        )
      })
      if (test) filtered.push(elem)
    }
  }

  return filtered
})

/** *********************************************************** */
/** ******************* FILTER TAG FUNCTIONS ****************** */
/** *********************************************************** */
export const getTagData = ({ label, groupLabel, displayValue, hideLabel }, showGroupLabel) => {
  return displayValue && label
    ? `${showGroupLabel && groupLabel ? `${groupLabel}: ` : ''}${
        hideLabel ? '' : `${label}: `
      }${displayValue}`
    : null
}

export const getFilterTags = (activeFilters, func, showGroupLabel) =>
  Object.keys(activeFilters).reduce(
    (arr, filter) =>
      !FILTER_SPECS[filter].hideTag
        ? [
            ...arr,
            ...activeFilters[filter].map(value => ({
              value,
              filter,
              label: getTagData(func(filter, value), showGroupLabel),
            })),
          ]
        : arr,
    []
  )
