import { OpenNewWindow } from '.'

export default () => (
  <div>
    <p>
      To change your school&#39;s address, complete and submit the{' '}
      <OpenNewWindow url="https://schoolcode.collegeboard.org/">
        School Code Request Form
      </OpenNewWindow>
      .
    </p>
  </div>
)
