import { DATETIME_FORMATS, formatDate } from '@myap/ui-library/esm/date'
import { DIGITAL_EXAM, TEST_ADMIN_EXCEPTION } from '../../constants/SettingsConstants'
import { formatExamWindow } from '../../selectors/examWindows'
import { isAdministeringDigitalExams } from '../../selectors/settings'

const mapStateToProps = (state, { course }) => {
  const {
    displayName = '',
    examDateTime = '',
    noEndOfCourseExam = false,
    requiresUnlocking = false,
    examFormat,
    testAdmin,
  } = course
  const isDigital = examFormat === DIGITAL_EXAM && isAdministeringDigitalExams(state)
  return {
    displayName,
    examDateTime,
    noEndOfCourseExam,
    requiresUnlocking,
    testAdmin,
    isDigital,
  }
}

// const labelStyles = (hasConflict = false) => ({
//   wrapper: { whiteSpace: 'nowrap', fontSize: '12px' },
//   type: { display: 'inline-block', width: hasConflict ? '87px' : '105px' },
//   date: { display: 'inline-block', width: '37px' },
//   time: { display: 'inline-block', width: '40px', textAlign: 'right' },
//   tz: { display: 'inline-block', width: '25px', textAlign: 'right' },
// })

const labelStyles = iconAdjustment => ({
  wrapper: { whiteSpace: 'nowrap', fontSize: '14px' },
  type: { display: 'inline-block', width: `${105 - iconAdjustment}px` },
  date: { display: 'inline-block', width: '37px' },
  time: { display: 'inline-block', width: '40px', textAlign: 'right' },
  tz: { display: 'inline-block', width: '25px', textAlign: 'right' },
})

// isDigital means the exam is digital AND administeringDigitalExams for this education period
const DisplayExamDateTime = ({
  isDigital,
  displayName,
  examDateTime,
  iconAdjustment,
  requiresUnlocking,
  testAdmin,
}) => {
  const { wrapper, type, date, time, tz } = labelStyles(iconAdjustment)
  if (displayName)
    return (
      <span style={wrapper}>
        <span style={type}>
          {displayName}
          {testAdmin === TEST_ADMIN_EXCEPTION ? ' Testing' : ''}
        </span>
        <span style={date}>
          {!requiresUnlocking ? formatDate(examDateTime, DATETIME_FORMATS.shortMonthDay) : ''}
        </span>
        <span style={time}>
          {!requiresUnlocking
            ? formatExamWindow(examDateTime, DATETIME_FORMATS.hour, isDigital)
            : ''}
        </span>
        {isDigital && !requiresUnlocking ? <span style={tz}>ET</span> : null}
      </span>
    )

  return null
}

export default connect(mapStateToProps)(DisplayExamDateTime)
