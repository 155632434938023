import { useHistory } from 'react-router-dom'
import { setNavLink, unsetNavLink } from '../../actions/nav'
import { NAV_LINKS } from '../../constants/NavConstants'
import { Loader } from '../../components/common'
import {
  CreateIncidentReportHeader,
  CreateIncidentReportForm,
} from '../../components/incidentReports'
import { fetchDraftIncidentReport, resetIncidentReport } from '../../actions/incidentReports'
import { getIncidentReportAvailability } from '../../selectors/incidentReports'
import { getOrgIdAndEdPeriod } from '../../selectors/user'

const mapStateToProps = state => {
  const {
    incidentReport: { fetching },
  } = state
  const { orgId, educationPeriodCd } = getOrgIdAndEdPeriod(state)
  const { active } = getIncidentReportAvailability(state)
  return { orgId, educationPeriodCd, active, fetching }
}

const CreateIncidentReportPage = ({
  orgId,
  educationPeriodCd,
  active,
  fetching,
  setNavLink,
  unsetNavLink,
  fetchDraftIncidentReport,
  resetIncidentReport,
}) => {
  const history = useHistory()

  useEffect(() => {
    setNavLink(NAV_LINKS.students, 'Back to Students')
    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    // Scroll view to top of page
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!active) {
      // Redirect back to Students page
      history.replace(NAV_LINKS.students)
    }
  }, [active])

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchDraftIncidentReport({ orgId, educationPeriodCd })
    }
    return () => resetIncidentReport()
  }, [orgId, educationPeriodCd])

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className="container">
        <h2>AP Coordinator Incident Report Form</h2>
        <CreateIncidentReportHeader />
        {fetching ? <Loader /> : <CreateIncidentReportForm />}
      </div>
    </CSSTransition>
  )
}

export default connect(mapStateToProps, {
  setNavLink,
  unsetNavLink,
  fetchDraftIncidentReport,
  resetIncidentReport,
})(CreateIncidentReportPage)
