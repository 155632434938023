import { Route, Switch, useLocation } from 'react-router-dom'
import { TEACHER_ROUTES, NAV_LINKS } from '../../constants/NavConstants'
import * as pages from '../../containers'
import { PrimaryNavigation, TeacherSecondaryNavigation } from '../navigation'

const MainAppRoute = ({ Component }) => {
  const { pathname } = useLocation()
  const onProfile = pathname === NAV_LINKS.teacherProfile
  const title =
    pathname === NAV_LINKS.examreadiness
      ? 'Digital Exam Readiness'
      : onProfile
      ? 'My AP Profile'
      : 'My Classes'

  return (
    <>
      <PrimaryNavigation title={title} routes={TEACHER_ROUTES} />
      <TeacherSecondaryNavigation />
      <div className="skipwrapper">
        <a id="main" tabIndex="-1">
          -
        </a>
      </div>
      <Component />
    </>
  )
}

export default () => (
  <Switch>
    {TEACHER_ROUTES.map((r, i) => (
      <Route
        exact
        path={r.path}
        render={() => {
          return <MainAppRoute Component={pages[r.component]} />
        }}
        key={i}
      />
    ))}
    <Route component={pages.NotFoundPage} />
  </Switch>
)
