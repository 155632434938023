import {
  SUBMISSION_STATUS,
  STATUS_CONFIRMED,
  STATUS_SUBMITTED,
  STATUS_UNCONFIRMED,
  STATUS_NOT_SUBMITTED,
  STATUS_NO_VALUE,
} from '../../constants/PackingListConstants'
import { submitPackingList } from '../../actions/packingList'
import { Error, Loader } from '../common'
import { openModal } from '../../actions/app'

import packingStyles from '../../assets/style/scss/packing-details.scss'
import boxStyles from '../../assets/style/scss/submission-box.scss'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    packingListDetails,
  } = state
  return { orgId, educationPeriodCd, packingListDetails }
}

function openSubmitModal({
  orgId,
  educationPeriodCd,
  openModal,
  submitPackingList,
  payload,
  packingShipmentsId,
  packingListId,
}) {
  openModal('ConfirmCancelFormModal', {
    customMessage:
      'Once a packing list has been submitted, you will be able to print it out for inclusion in your return shipment. After submission, you will not be able to make any changes to this packing list.',
    performActionOnContinue: () =>
      submitPackingList({ orgId, educationPeriodCd, packingShipmentsId, packingListId, payload }),
    confirmLabel: 'Submit',
    cancelLabel: 'Cancel',
  })
}

const SubmissionButton = ({ message, disabled, onClick }) => (
  <button
    id="PackingSubmitButton"
    type="button"
    className="btn btn-primary cb-btn-fluid btn-sm"
    disabled={disabled}
    onClick={onClick}
  >
    {message}
  </button>
)

const SubmissionBox = ({
  orgId,
  educationPeriodCd,
  status,
  cartonLock,
  packingListDetails: {
    packingListItems,
    packingShipmentsId,
    packingListId,
    submitPackingList: { error, submitting },
    carton: { error: cartonError, sending: cartonSending },
  },
  openModal,
  submitPackingList,
}) => {
  const areAllCoursesConfirmed =
    packingListItems && packingListItems.every(i => i.status === STATUS_CONFIRMED)
  const isAnyCourseConfirmed =
    packingListItems && packingListItems.some(i => i.status === STATUS_CONFIRMED)
  let newStatus
  if (status !== STATUS_SUBMITTED) {
    if (areAllCoursesConfirmed && cartonLock) newStatus = STATUS_CONFIRMED
    else if (areAllCoursesConfirmed && !cartonLock) newStatus = STATUS_NO_VALUE
    else if (isAnyCourseConfirmed || cartonLock) newStatus = STATUS_UNCONFIRMED
    else newStatus = STATUS_NOT_SUBMITTED
  } else newStatus = status

  const { title, explanation, message, lock } = SUBMISSION_STATUS[newStatus]
  const isSubmitDisabled =
    lock || !cartonLock || !areAllCoursesConfirmed || cartonError || cartonSending

  return (
    <div className={`submission-box ${boxStyles.submitBox}`}>
      {error && <Error title="Error submitting packing list." message={error} />}
      {submitting && (
        <div className={`${packingStyles.loader}`}>
          <Loader />
        </div>
      )}
      <h4>{`Packing List: ${title}`}</h4>
      <p>{explanation}</p>
      <SubmissionButton
        message={message}
        disabled={isSubmitDisabled || submitting}
        onClick={() => {
          if (status !== STATUS_SUBMITTED)
            return openSubmitModal({
              orgId,
              educationPeriodCd,
              openModal,
              submitPackingList,
              payload: { status: STATUS_SUBMITTED },
              packingShipmentsId,
              packingListId,
            })
          else
            window.open(
              `/packingshipments/${packingShipmentsId}/packinglist/${packingListId}?print=true`
            )
          return null
        }}
      />
    </div>
  )
}

export default connect(mapStateToProps, { openModal, submitPackingList })(SubmissionBox)
