
const labelStyles = {
  fontSize: 'inherit',
  borderBottom: 'none',
  marginBottom: 0,
  lineHeight: 'inherit',
  fontFamily: 'roboto, sans-serif',
  color: 'inherit'
}
export const LegendLabel = ({ isRequired, label, style = {}, id, classes = '' }) => {
  const styles = { ...style, ...labelStyles }
  return <legend id={id} className={`${classes} ${isRequired ? 'cb-required' : ''}`} style={styles}>{label}</legend>
}


export default ({ isRequired, name, label, style = {}, id, classes = '' }) => (
  <label htmlFor={name} id={id} className={`${classes !== '' ? `${classes} ` : ''}${isRequired ? 'cb-required' : ''}`} style={style}>{label}</label>
)
