import { clearFilters } from '../../../actions/filters'
import { numberFormat } from '../../../utils/numbers'

export const FilterNoResultsMessage = ({ text, className = 'container' }) => (
  <div className={className}>
    There are no {text} that match the selected filters.
    <button type="button" className="btn-link" onClick={clearFilters}>
      Clear filters
    </button>
  </div>
)

export const FilterResultsCount = ({ visibleCount, totalCount, resultsTypeText, style }) => {
  return (
    <div style={style} aria-live="polite" aria-atomic="true">
      Viewing <strong>{numberFormat(visibleCount)}</strong> of{' '}
      <strong>{numberFormat(totalCount)}</strong> {resultsTypeText}
      {totalCount !== 1 ? 's' : ''}
    </div>
  )
}
