export const HOLD_NOTIFICATION = 'HOLD'
export const AP_NOTIFICATIONS = 'AP_NOTIFICATIONS'
export const MERGE_NOTIFICATION = 'MERGE'
export const TRANSFER_NOTIFICATION = 'TRANSFER'
export const MATERIAL_CHANGE_NOTIFICATION = 'EXAM_MATERIAL_CHANGE'

export const NEEDS_INFO_NOTIFICATION = 'APPROVAL_REQUEST_NEEDS_INFO'
export const DENIED_NOTIFICATION = 'APPROVAL_REQUEST_DENIED'

export const FETCH_NOTIFICATION_PENDING = 'FETCH_NOTIFICATION_PENDING'
export const FETCH_NOTIFICATION_FULFILLED = 'FETCH_NOTIFICATION_FULFILLED'
export const FETCH_NOTIFICATION_REJECTED = 'FETCH_NOTIFICATION_REJECTED'

export const DISMISS_NOTIFICATION_PENDING = 'DISMISS_NOTIFICATION_PENDING'
export const DISMISS_NOTIFICATION_FULFILLED = 'DISMISS_NOTIFICATION_FULFILLED'
export const DISMISS_NOTIFICATION_REJECTED = 'DISMISS_NOTIFICATION_REJECTED'

export const SECURITY_HOLD_MESSAGE = {
  title: 'Security Hold',
  body: 'Your school has been placed on a security hold from the last AP Exam administration. You are unable to order exams until this matter is resolved. If you have questions, please contact AP Services for Educators at (877) 274-6474 (toll free in the U.S. and Canada) or (212) 632-1781.',
}
export const BILLING_HOLD_MESSAGE = {
  title: 'Payment Due',
  body: 'You still have an outstanding balance for a prior AP Exam Administration. You will not be able to order exams until payment has been remitted and your account is paid in full. Please contact AP Services for Educators at (877) 274-6474 (toll free in the U.S. and Canada) or (212) 632-1781 for additional assistance.',
}
export const SECURITY_AND_BILLING_HOLD_MESSAGE = {
  title: 'Payment Due and Security Hold',
  body: 'Your school has an outstanding balance and has been placed on a security hold from a prior AP Exam administration. You will not be able to order exams until these have been resolved. If you have questions, please contact AP Services for Educators at (877) 274-6474 (toll free in the U.S. and Canada) or (212) 632-1781 for additional assistance.',
}
export const SYSTEM_HOLD_MESSAGE = {
  title: 'Order Submission Unavailable',
  body: 'Exam orders are currently being processed for shipment. Ordering is temporarily unavailable. If you need to submit a change, please sign back in later.',
}
