import * as c from './'
import { fetchFeeRemovals, saveFeeRemovals } from '../../../actions/csr'

import styles from '../../../assets/style/scss/csr.scss'

const mapStateToProps = state => {
	return {
		educationPeriod: state.settingsEducationPeriod.selectedEducationPeriod,
		orgId: state.user.data.selectedOrgId
	}
}

const types = [
	{ label: 'Late Order Fee', value: 'LATE_ORDERING_FEE' },
	{ label: 'Late-Testing Fee', value: 'LATE_TESTING_FEE' },
	{ label: 'Unused/Cancellation Fee', value: 'CANCELLATION_FEE' }
]

class FeeRemovals extends Component {

	state = { selectedCourse: null, selectedType: null, selectedStudents: [], saving: false, fetching: false, fetchingError: null, savingError: null, data: {} }

	constructor() {
		super()
		this.selectCourse = this.selectCourse.bind(this)
		this.selectStudents = this.selectStudents.bind(this)
		this.fetchBrowseData = this.fetchBrowseData.bind(this)
		this.saveData = this.saveData.bind(this)
	}

	async fetchBrowseData(type) {
		const updatedState = await this.setState({ fetching: true })
		const { orgId, educationPeriod } = this.props
		this.updateSubPanel(1, type.label)

	  const { data = {}, error = null } = await fetchFeeRemovals(orgId, educationPeriod, type.value)
		this.setState({ fetching: false, data, fetchingError: error, selectedType: type })
	}

	async saveData() {
		const updatedState = await this.setState({ saving: true })
		const { orgId, educationPeriod } = this.props
		const { selectedStudents, selectedType, selectedCourse } = this.state
		const dataToSave = { feeType: selectedType.value, updates: selectedStudents.map(s => ({ enrollmentId: s, feeRemoved: true })) }
		const { data={}, error=null } = await saveFeeRemovals(orgId, educationPeriod, dataToSave)

		if(error)
			this.setState({ saving: false, savingError: error })
		else {
			this.setState({ saving: false, data, selectedCourse: data.courses.find(c => c.testCd === selectedCourse.testCd), selectedStudents: [] })
			this.updateSubPanel(-1)
		}
	}

	updateSubPanel(subpanel, title) {
		if(subpanel === -1)
			this.props.updatePanel(-1)
		else
			this.props.updatePanel(this.props.panel, title, subpanel)
	}

	selectCourse(course) {
		this.setState({ selectedCourse: course, selectedStudents: [], savingError: false })
		this.updateSubPanel(2, course.name)
	}

	selectStudents(students) {
		const { selectedCourse } = this.state
		this.setState({ selectedStudents: students })
		this.updateSubPanel(3, selectedCourse.name)
	}

	render() {
		switch(this.props.subpanel) {
			case 0:
				return <c.FeeRemovalsSearchOrBrowse types={types} fetchBrowseData={this.fetchBrowseData} />
			case 1:
				return <c.FeeRemovalsCourseSelect
					data={this.state.data.courses || []}
					loading={this.state.fetching}
					error={this.state.fetchingError}
					onCourseSelection={this.selectCourse} />
			case 2:
				return <c.FeeRemovalsStudentSelect
					sections={this.state.selectedCourse.sections}
					defaultSelections={this.state.selectedStudents}
					onStudentSelection={this.selectStudents} />
			case 3:
				return <c.FeeRemovalsConfirmation
					sections={this.state.selectedCourse.sections}
					selectedStudents={this.state.selectedStudents}
					feeTypeLabel={this.state.selectedType.label}
					goBack={() => this.updateSubPanel(-1)}
					saveData={this.saveData}
					saving={this.state.saving}
					error={this.state.savingError} />
			default:
				return null
		}
	}
}

export default connect(mapStateToProps)(FeeRemovals)