import { openModal } from '../../actions/app'
import { resetIncidentReport } from '../../actions/incidentReports'

const IncidentReportSubmitButton = ({ addStudent, openModal, disabled, resetIncidentReport }) => {
  const handleSubmit = e => {
    e.preventDefault()
    // If user has entered student information but has not clicked
    // the Add button, we'll add the student for them
    addStudent()

    openModal('ConfirmIncidentReportModal', { onCloseAction: resetIncidentReport })
  }

  return (
    <button
      type="submit"
      disabled={disabled}
      className="btn btn-sm btn-primary"
      onClick={handleSubmit}
    >
      Submit
    </button>
  )
}

export default connect(null, { openModal, resetIncidentReport })(IncidentReportSubmitButton)
