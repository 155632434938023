import * as c from '../constants/FeatureConstants'
import axios, { getServerErrorMessage } from '../utils/axios'

export const fetchFeatures = () => async dispatch => {
  try {
    dispatch({ type: c.FETCH_FEATURES_PENDING })
    const res = await axios.get(`${Config.API_URL}/v1/inactive-features`)
    dispatch({ type: c.FETCH_FEATURES_FULFILLED, payload: res.data })
  }
  catch (err) {
    let errMsg = getServerErrorMessage(err)
    dispatch({ type: c.FETCH_FEATURES_REJECTED, payload: errMsg })
  }
}

export const disableFeature = name => dispatch => dispatch({ type: c.SET_DISABLE_FEATURE, payload: name })
export const enableFeature = name => dispatch => dispatch({ type: c.SET_ENABLE_FEATURE, payload: name })
