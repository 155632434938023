import { Link } from 'react-router-dom'
import { isAdministeringDigitalExams } from '../../../selectors/settings'
import { NAV_LINKS } from '../../../constants/NavConstants'
import styles from '../../../assets/style/scss/exam-readiness.scss'

const CoordinatorExamReadinessPromo = ({ breakpoint, administeringDigitalExams }) => {
  if (administeringDigitalExams) {
    return (
      <div className="container">
        <div
          className={`${styles['coordinator-promo']} ${
            styles[breakpoint]
          } cb-accent1-blue-corporate-bg cb-white-color`}
        >
          <div>
            <h2 className="roboto-bold">Make Sure Your Students Are Ready for the Digital Exam</h2>
            <div>Review students' exam statuses and makeup requests.</div>
          </div>
          <Link to={NAV_LINKS.examreadiness} className="btn btn-sm btn-secondary-light">
            Track Digital Exam Readiness
          </Link>
        </div>
      </div>
    )
  }
  return null
}

export default connect(state => ({
  breakpoint: state.app.breakpoint.name,
  administeringDigitalExams: isAdministeringDigitalExams(state),
}))(CoordinatorExamReadinessPromo)
