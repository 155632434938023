import { useEffect } from 'react'
import { fetchPackingListSummary } from '../../actions/packingList'
import PackingListSummary from '../../components/packingList/PackingListSummary'
import { Error, Loader } from '../../components/common'
import InvoiceSummary from '../../components/invoices/InvoiceSummary'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedIsCurrent, selectedEducationPeriod: educationPeriodCd },
    packingListSummary: { error, fetched, fetching, showShipment, packingLists = {} },
  } = state
  return {
    orgId,
    educationPeriodCd,
    error,
    fetched,
    fetching,
    selectedIsCurrent,
    packingLists,
    showShipment,
  }
}

const PackingListUnavailableMessage = ({ selectedIsCurrent }) => {
  if (selectedIsCurrent) {
    return (
      <p>
        Packing list information will be available starting on the first day you administer exams.
      </p>
    )
  }
  return <p>You cannot view the packing list from last year&#39;s administration at this time.</p>
}

const PackingShipments = connect(mapStateToProps, { fetchPackingListSummary })(props => {
  const {
    orgId,
    educationPeriodCd,
    packingLists,
    showShipment,
    selectedIsCurrent,
    error,
    fetching,
    fetchPackingListSummary,
  } = props

  useEffect(() => {
    if (orgId && educationPeriodCd && selectedIsCurrent) {
      fetchPackingListSummary({ orgId, educationPeriodCd })
    }
  }, [orgId, educationPeriodCd, selectedIsCurrent])

  if (fetching) {
    return <Loader />
  }

  /**
   * If showShipment flag is false,
   * the current date is not more than the 1st exam date for the organization for a specific education period.
   */
  if (showShipment && selectedIsCurrent) {
    return <PackingListSummary packingLists={packingLists} />
  }

  return (
    <>
      <h2>Packing Shipments</h2>
      {error ? (
        <Error title="Error Retrieving Data" message={error} />
      ) : (
        <PackingListUnavailableMessage selectedIsCurrent={selectedIsCurrent} />
      )}
    </>
  )
})

export default () => (
  <CSSTransition
    classNames="animate-fade"
    in={true}
    timeout={500}
    appear={true}
    enter={false}
    exit={false}
  >
    <div className="container">
      <InvoiceSummary />
      <PackingShipments />
    </div>
  </CSSTransition>
)
