import InvoiceItemizedRows from './InvoiceItemizedRows'
import { formatter } from './utils'

import styles from '../../assets/style/scss/invoices.scss'

export default ({ title, data = [] }) => {
  const total = data.reduce((acc, d) => acc + d.amount, 0)

  return data.length ? (
    <div className={styles['invoice-table']}>
      <h3 className="h4">{title}</h3>
      <div className="table-responsive">
        <table className="table cb-no-table-border">
          <thead>
            <tr>
              <th scope="col" className={styles.description}>
                Description
              </th>
              <th scope="col" className={styles.quantity}>
                Quantity
              </th>
              <th scope="col" className={styles.price}>
                Unit Price
              </th>
              <th scope="col" className={styles.amount}>
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <InvoiceItemizedRows data={data} />
          </tbody>
        </table>
        <div className={styles['invoice-total']}>
          <div>Total {title}</div>
          <div>{formatter(total)}</div>
        </div>
      </div>
    </div>
  ) : null
}
