import { HOMESCHOOL_TEXT, PROFDEV_TEXT } from '../../../constants/SetupConstants'
import { formatPhoneNumber } from '../../../utils/numbers'
import EditButton from './EditButton'

import styles from '../../../assets/style/scss/settings-form.scss'

export default (p = {}) => (
  <div>
    <h5 className="h3" style={{ marginBottom: 5 }}>
      {p.title}
    </h5>
    <div className="full-name">
      {p.firstName} {p.middleInitial} {p.lastName}
    </div>
    {p.email ? <div className="email-address">{p.email}</div> : null}
    {p.telephone ? (
      <div className="phone-number">{formatPhoneNumber(p.telephone, p.countryCode)}</div>
    ) : null}
    {p.fax ? <div className="fax-number">{formatPhoneNumber(p.fax, p.countryCode)} (fax)</div> : null}
    {p.profdev ? <div className={styles['partform-checkbox-wrapper']}>{PROFDEV_TEXT}</div> : null}
    {p.homeschool ? (
      <div className={styles['partform-checkbox-wrapper']}>{HOMESCHOOL_TEXT}</div>
    ) : null}
    <EditButton
      modalType="UpdateOrgSettingsModal"
      modalOptions={{ type: p.type, title: `Edit ${p.title}` }}
      screenReaderText={`personal info for ${p.title}`}
    />
  </div>
)
