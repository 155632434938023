import { SubmissionError, getFormSyncErrors } from 'redux-form'
import { formatDate } from '@myap/ui-library/esm/date'
import axios, { getServerErrorMessage } from '../utils/axios'
import * as c from '../constants/SettingsConstants'
import { CHANGE_STEP, SET_PARTFORM_TO_SIGNED } from '../constants/SetupConstants'
import { DEFAULT_REQUEST_ERROR } from '../constants/MessageConstants'
import { NAV_LINKS } from '../constants/NavConstants'
import { isEmpty } from '../utils/common'

const reformat = ({
  secondSemesterDecisionDeadline,
  standardDecisionDeadline,
  enableStudentDecisions,
  enableTeacherConfirmation,
}) => {
  return {
    secondSemesterDecisionDeadline: formatDate(secondSemesterDecisionDeadline),
    standardDecisionDeadline: formatDate(standardDecisionDeadline),
    enableTeacherConfirmation: enableTeacherConfirmation || enableTeacherConfirmation === 'true',
    enableStudentDecisions: enableStudentDecisions || enableStudentDecisions === 'true',
  }
}

export const fetchExamDecisionsSettings = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_EXAM_DECISIONS_PENDING, payload: orgId })

  try {
    const response = await axios.get(`${Config.API_URL}/settings/org-settings`, {
      params: { orgId, educationPeriodCd },
    })
    dispatch({
      type: c.FETCH_EXAM_DECISIONS_FULFILLED,
      payload: { orgId, data: reformat(response.data.examDecisions) },
    })
  } catch (err) {
    dispatch({
      type: c.FETCH_EXAM_DECISIONS_REJECTED,
      payload: { orgId, error: getServerErrorMessage(err) },
    })
  }
}

export const updateExamDecisionsSettings = (data, orgId) => async (dispatch, getState) => {
  if (!isEmpty(getFormSyncErrors(c.EXAM_DECISIONS_FORM_NAME)(getState()))) {
    dispatch({
      type: c.UPDATE_EXAM_DECISIONS_REJECTED,
      payload: { orgId, error: 'There were some errors with your inputs. Try again.' },
    })
    return
  }

  dispatch({ type: c.UPDATE_EXAM_DECISIONS_PENDING, payload: orgId })

  try {
    const response = await axios.put(`${Config.API_URL}/settings/org-settings`, {
      examDecisions: data,
      orgId,
      educationPeriodCd: parseInt(getState().settingsEducationPeriod.selectedEducationPeriod, 10),
    })
    dispatch({ type: c.UPDATE_EXAM_DECISIONS_FULFILLED, payload: { orgId, data } })
  } catch (err) {
    const message = getServerErrorMessage(err)
    dispatch({ type: c.UPDATE_EXAM_DECISIONS_REJECTED, payload: { orgId, error: message } })
    throw new SubmissionError({ _error: message })
  }
}

export const fetchOrgSettings = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_ORG_SETTINGS_PENDING, payload: orgId })

  try {
    const { data } = await axios.get(`${Config.API_URL}/settings/organization-info`, {
      params: { orgId, educationPeriodCd },
    })
    const administeringDigitalExams = c.DIGITAL_EXAM_INCLUSION_LIST.includes(
      parseInt(educationPeriodCd, 10)
    )
    dispatch({
      type: c.FETCH_ORG_SETTINGS_FULFILLED,
      payload: {
        orgId,
        data: data || {},
        allowedToOrder: Boolean(data?.participationForm.coordinatorSigned),
        administeringDigitalExams,
      },
    })
  } catch (err) {
    console.info(err)
    dispatch({
      type: c.FETCH_ORG_SETTINGS_REJECTED,
      payload: { orgId, error: getServerErrorMessage(err) },
    })
  }
}

export const fetchDocusignUrl = async (orgId, edPeriod) => {
  try {
    const { data } = await axios.post(`${Config.API_URL}/docusign/signurl`, {
      orgId: parseInt(orgId, 10),
      educationPeriodCd: parseInt(edPeriod, 10),
      returnUrl: `${window.location.origin}${NAV_LINKS.docusign}?close=y`,
    })
    return { data }
  } catch (err) {
    return { error: getServerErrorMessage(err) }
  }
}

export const fetchDocusignSignedDocumentUrl = async (orgId, edPeriod) => {
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/v1/participation-form/organizations/${orgId}/education-periods/${edPeriod}/signed-pdf`
    )
    return { data }
  } catch (err) {
    return { error: getServerErrorMessage(err) }
  }
}

export const updateOrgSettings = data => async dispatch => {
  const { emailConfirm, ...dataWithoutConfirmEmailFields } = data

  dispatch({ type: c.UPDATE_ORG_SETTINGS_PENDING, payload: data.orgId })

  try {
    const response = await axios.put(
      `${Config.API_URL}/settings/organization-info`,
      dataWithoutConfirmEmailFields
    )
    dispatch({
      type: c.UPDATE_ORG_SETTINGS_FULFILLED,
      payload: { orgId: data.orgId, data: response.data },
    })
  } catch (err) {
    const message = getServerErrorMessage(err)
    dispatch({
      type: c.UPDATE_ORG_SETTINGS_REJECTED,
      payload: { orgId: data.orgId, error: message },
    })
    throw new SubmissionError({ _error: message || DEFAULT_REQUEST_ERROR })
  }
}

export const changeStep = step => ({ type: CHANGE_STEP, payload: step })
export const setPartFormToSigned = (orgId, date) => ({
  type: SET_PARTFORM_TO_SIGNED,
  payload: { orgId, date },
})
