import { createBrowserHistory } from 'history'
import store from '../store/configureStore'
import { openModal } from '../actions/app'
import { isAuthed } from './user'

// message can be a string with just the form name or it can be a stringified json object
// this is due to getUserConfirmation/Prompt limitations, you can only send a message attribute and it has to be a string
const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => {
    let opts

    try {
      opts = JSON.parse(message)
    } catch (err) {
      opts = { form: message }
    }

    if (isAuthed()) {
      store.dispatch(openModal('ConfirmUnsavedChangesModal', { ...opts, callback }))
    }
  },
})

export default history
