export const FETCH_STUDENT_PROFILE_PENDING = 'FETCH_STUDENT_PROFILE_PENDING'
export const FETCH_STUDENT_PROFILE_FULFILLED = 'FETCH_STUDENT_PROFILE_FULFILLED'
export const FETCH_STUDENT_PROFILE_REJECTED = 'FETCH_STUDENT_PROFILE_REJECTED'

export const FETCH_STUDENT_ENROLLMENTS_PENDING = 'FETCH_STUDENT_ENROLLMENTS_PENDING'
export const FETCH_STUDENT_ENROLLMENTS_FULFILLED = 'FETCH_STUDENT_ENROLLMENTS_FULFILLED'
export const FETCH_STUDENT_ENROLLMENTS_REJECTED = 'FETCH_STUDENT_ENROLLMENTS_REJECTED'

export const FETCH_STUDENT_SCORESEND_PENDING = 'FETCH_STUDENT_SCORESEND_PENDING'
export const FETCH_STUDENT_SCORESEND_FULFILLED = 'FETCH_STUDENT_SCORESEND_FULFILLED'
export const FETCH_STUDENT_SCORESEND_REJECTED = 'FETCH_STUDENT_SCORESEND_REJECTED'

export const SAVE_STUDENT_SCORESEND_PENDING = 'SAVE_STUDENT_SCORESEND_PENDING'
export const SAVE_STUDENT_SCORESEND_FULFILLED = 'SAVE_STUDENT_SCORESEND_FULFILLED'
export const SAVE_STUDENT_SCORESEND_REJECTED = 'SAVE_STUDENT_SCORESEND_REJECTED'

export const DELETE_STUDENT_SCORESEND_PENDING = 'DELETE_STUDENT_SCORESEND_PENDING'
export const DELETE_STUDENT_SCORESEND_FULFILLED = 'DELETE_STUDENT_SCORESEND_FULFILLED'
export const DELETE_STUDENT_SCORESEND_REJECTED = 'DELETE_STUDENT_SCORESEND_REJECTED'

export const REGISTRATION_TAB = 'reg'
export const PROFILE_TAB = 'profile'
export const SCORESEND_TAB = 'scoreSend'
