import { GRADE_OPTIONS } from '@myap/metadata'

const StudentSchoolInformation = ({ grade, studentId, aiCode }) => {
  const gradeLabel = GRADE_OPTIONS.find(({ value }) => value === grade)?.label

  return (
    <>
      <h4 className="roboto-bold">School Information</h4>
      {gradeLabel ? <div>Grade: {gradeLabel}</div> : null }
      {studentId ? <div>AP ID: {studentId}</div> : null}
      {aiCode ? <div>School Code: {aiCode}</div> : null}
    </>
  )
}

export default StudentSchoolInformation
