import {
  FieldArray,
  arrayRemoveAll,
  arrayRemove,
  getFormSyncErrors,
  getFormValues,
} from 'redux-form'
import { Select, Label, Loader } from '../../common'
import { CREATE_FORM_NAME, CREATE_FORM_FIELDS as FIELDS } from '../../../constants/SectionConstants'
import { mapToLabelValueObjectArrayAndSort } from '../../../utils/sort'
import { isEmpty } from '../../../utils/common'

const mapStateToProps = (state, { creating, testCd }) => {
  const {
    user: {
      data: { personId, isCoordinator },
    },
    teacher: { fetching: fetchingTeachers, data: availableTeachers = {} },
  } = state
  const currentFormValues = getFormValues(CREATE_FORM_NAME)(state) || {}
  const selectedTeachers =
    currentFormValues[FIELDS.teachers]?.filter(teacher => teacher?.teacherId) || []
  const formSyncErrors = getFormSyncErrors(CREATE_FORM_NAME)(state)
  return {
    personId: parseInt(personId, 10),
    isCoordinator,
    availableTeachers: availableTeachers[testCd],
    selectedTeachers,
    fetchingTeachers,
    creating,
    formSyncErrors,
  }
}

const FIELD_ID = 'createOrEditCoTeachers'

const validateTeacherSelected = val => {
  return !isEmpty(val?.teacherId) ? undefined : 'Error: Select a teacher'
}

const TeacherRows = props => {
  const {
    fields,
    selectedTeachers = [],
    availableTeachers = [],
    maxAllowed = 3,
    atLeastOneRequired = false,
    primaryTeacherId,
    creating,
    roleOfUser,
    syncErrors,
  } = props
  const numberSelected = selectedTeachers.length
  const reachedMaxAllowed = numberSelected === maxAllowed
  // So seleciing the "Choose..." option will not show the Add option till a teacher
  // has been selected
  const sectionTeacherErrors = syncErrors?.[FIELDS.teachers]?.some(er => er !== undefined) || 0
  const showAddAnother =
    !reachedMaxAllowed &&
    numberSelected < availableTeachers.length &&
    !sectionTeacherErrors &&
    (numberSelected === fields.length ||
      (roleOfUser === 'teacher' && !creating && numberSelected === fields.length - 1) ||
      (roleOfUser === 'coordinator' && creating && numberSelected === fields.length - 1))
  const showDelete =
    !atLeastOneRequired || (atLeastOneRequired && (numberSelected > 1 || fields.length > 1))

  return (
    <>
      {fields.map((field, i) => {
        const { teacherId: currentId } = fields.get(i) || {}
        const teachersWithPreviousSelectionsRemoved = availableTeachers.filter(availableTeacher => {
          // Filter out any teachers that have already been selected
          return !selectedTeachers.find(selectedTeacher => {
            if (
              selectedTeacher.teacherId === availableTeacher.proId &&
              selectedTeacher.teacherId !== currentId
            ) {
              return true
            }
            return false
          })
        })
        const options = mapToLabelValueObjectArrayAndSort(
          teachersWithPreviousSelectionsRemoved,
          t => `${t.firstName} ${t.lastName}`,
          'proId',
          ['lastName', 'firstName']
        )

        // Don't render a dropdown for the primary teacher
        if (primaryTeacherId && primaryTeacherId === currentId) {
          return null
        }

        return (
          <div className="row" key={currentId || i}>
            <div
              className={showDelete ? 'col-xs-9' : 'col-xs-12'}
              style={showDelete ? { paddingRight: 5 } : {}}
            >
              <Select
                name={field}
                options={options}
                isRequired={atLeastOneRequired && i === 0}
                labelledBy={FIELD_ID}
                normalize={val => {
                  const teacherId = val !== '' ? parseInt(val, 10) : ''
                  if (!isEmpty(teacherId)) {
                    const selectedTeacherData = availableTeachers.find(
                      availableTeacher => availableTeacher.proId === teacherId
                    )
                    return {
                      teacherId,
                      firstName: selectedTeacherData.firstName,
                      lastName: selectedTeacherData.lastName,
                      teacherName: `${selectedTeacherData.firstName} ${selectedTeacherData.lastName}`,
                    }
                  }
                  return null
                }}
                format={val => (val ? val.proId ?? val.teacherId : '')}
                truncate={showDelete ? 17 : false}
                style={{ marginBottom: 5 }}
                validate={[validateTeacherSelected]}
                // showEmptyOption={options.length > 1}
              />
            </div>
            {showDelete ? (
              <div className="col-xs-3" style={{ paddingLeft: 0, lineHeight: '40px' }}>
                <button
                  type="button"
                  className="btn-link cb-small-font-size"
                  onClick={() => fields.remove(i)}
                  style={{ padding: 0 }}
                >
                  Delete
                </button>
              </div>
            ) : null}
          </div>
        )
      })}
      {showAddAnother ? (
        <button
          type="button"
          onClick={() => fields.push()}
          className="btn-link cb-small-font-size"
          style={{ border: 'none', padding: 0, display: 'block' }}
        >
          <span aria-hidden={true} className="cb-glyph cb-plus" />
          Add Co-Teacher
        </button>
      ) : null}
      {reachedMaxAllowed ? (
        <p className="cb-small-font-size">You have added the maximum number of teachers.</p>
      ) : null}
    </>
  )
}

const CoordinatorFieldArray = ({
  fields,
  availableTeachers,
  selectedTeachers,
  creating,
  formSyncErrors,
}) => {
  useEffect(() => {
    if (!fields.length) {
      fields.push()
    }
  }, [fields])

  return (
    <div className="form-group">
      <Label id={FIELD_ID} label="Teachers" isRequired={true} />
      <TeacherRows
        fields={fields}
        selectedTeachers={selectedTeachers}
        availableTeachers={availableTeachers}
        atLeastOneRequired={true}
        roleOfUser="coordinator"
        creating={creating}
        syncErrors={formSyncErrors}
      />
    </div>
  )
}

const TeacherFieldArray = ({
  availableTeachers,
  selectedTeachers,
  creating,
  personId,
  fields,
  formSyncErrors,
}) => {
  // Get teacher data
  const selectedTeacherData = availableTeachers.find(
    availableTeacher => availableTeacher.proId === personId
  )

  useEffect(() => {
    if (!fields.length && creating) {
      // Add self to list of selected teachers
      fields.push({
        teacherId: personId,
        firstName: selectedTeacherData.firstName,
        lastName: selectedTeacherData.lastName,
        teacherName: `${selectedTeacherData.firstName} ${selectedTeacherData.lastName}`,
      })
    }
  }, [fields, creating])

  return availableTeachers.length > 1 ? (
    <div className="form-group">
      <Label id={FIELD_ID} label="Co-teachers" />
      <TeacherRows
        fields={fields}
        selectedTeachers={selectedTeachers}
        availableTeachers={availableTeachers}
        primaryTeacherId={personId}
        roleOfUser="teacher"
        creating={creating}
        syncErrors={formSyncErrors}
      />
    </div>
  ) : null
}

function CoTeacherSelector(props) {
  const {
    fetchingTeachers,
    availableTeachers,
    selectedTeachers,
    isCoordinator,
    examOnlySelected,
    arrayRemove,
    arrayRemoveAll,
  } = props

  useEffect(() => {
    if (!fetchingTeachers && availableTeachers?.length && selectedTeachers?.length) {
      // Remove any teachers that were previously assigned and are no longer authorized
      // i.e. teachers that were not returned in availableTeachers
      const savedTeachersNoLongerAuthorized = selectedTeachers.reduce((arr, selectedTeacher, i) => {
        const isTeacherInAvailableList = availableTeachers.find(
          availableTeacher => selectedTeacher.teacherId === availableTeacher.proId
        )
        return !isEmpty(isTeacherInAvailableList) ? arr : [...arr, i]
      }, [])
      savedTeachersNoLongerAuthorized.forEach(index => {
        arrayRemove(CREATE_FORM_NAME, FIELDS.teachers, index)
      })
    }
  }, [fetchingTeachers, availableTeachers, selectedTeachers, arrayRemove])

  useEffect(() => {
    if (examOnlySelected) {
      arrayRemoveAll(CREATE_FORM_NAME, FIELDS.teachers)
    }
  }, [examOnlySelected, arrayRemoveAll])

  if (fetchingTeachers) {
    return <Loader size="md" />
  }

  if (!examOnlySelected && availableTeachers?.length > 0) {
    return (
      <FieldArray
        {...props}
        name={FIELDS.teachers}
        component={isCoordinator ? CoordinatorFieldArray : TeacherFieldArray}
      />
    )
  }

  return null
}

export default connect(mapStateToProps, { arrayRemoveAll, arrayRemove })(CoTeacherSelector)
