import EducationPeriod24CourseSurvey from './EducationPeriod24CourseSurvey'
// import MidYearLearningModeSurvey from './MidYearLearningModeSurvey'

export default () => {
  // Has everything finished loading?
  return (
    <>
      {/*<MidYearLearningModeSurvey />*/}
      <EducationPeriod24CourseSurvey />
    </>
  )
}
