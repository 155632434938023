import * as c from '../constants/SurveyConstants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  saveError: null,
  fetchError: null,
  data: [],
}

export const survey = (state = initialState, { type, payload }) => {
  switch (type) {
    case c.FETCH_SURVEY_DATA_PENDING:
      return { ...state, fetching: true, fetched: false, fetchError: null }
    case c.FETCH_SURVEY_DATA_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: payload }
    case c.FETCH_SURVEY_DATA_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        fetchError: payload,
      }

    case c.SAVE_SURVEY_DATA_PENDING:
      return { ...state, saving: true, saved: false, saveError: null }
    case c.SAVE_SURVEY_DATA_FULFILLED:
      return {
        ...state,
        saving: false,
        saved: true,
        data: state.data.map(survey =>
          survey.surveyId === payload ? { active: false, surveyId: payload, questions: [] } : survey
        ),
      }
    case c.SAVE_SURVEY_DATA_REJECTED:
      return { ...state, saving: false, saved: false, saveError: payload }

    default:
      return state
  }
}
