import { NAV_LINKS } from './NavConstants'
import { STUDENT_ENROLLMENT_STATUS_YES, REDUCED_FEE_STATUS } from './StudentConstants'
import {
  ORDER_EXAM_FILTER,
  FEE_STATUS_FILTER,
  CAPSTONE_FILTER,
  FEES_FILTER,
  SUBSIDY_FILTER,
  FILTER_YES,
  FILTER_NO,
  LATE_ORDERING_FEE,
  LATE_TESTING_FEE,
  UNUSED_CANCELLATION_FEE,
  NOT_REFUNDED_FEE,
} from './FilterConstants'
import { ORDER_TAB_SERVICES } from './OrderConstants'

export const FETCH_INVOICES_PENDING = 'FETCH_INVOICES_PENDING'
export const FETCH_INVOICES_FULFILLED = 'FETCH_INVOICES_FULFILLED'
export const FETCH_INVOICES_REJECTED = 'FETCH_INVOICES_REJECTED'

export const FETCH_INVOICE_DOWNLOAD_URL_PENDING = 'FETCH_INVOICE_DOWNLOAD_URL_PENDING'
export const FETCH_INVOICE_DOWNLOAD_URL_FULFILLED = 'FETCH_INVOICE_DOWNLOAD_URL_FULFILLED'
export const FETCH_INVOICE_DOWNLOAD_URL_REJECTED = 'FETCH_INVOICE_DOWNLOAD_URL_REJECTED'

export const FETCH_INVOICE_HISTORY_PENDING = 'FETCH_INVOICE_HISTORY_PENDING'
export const FETCH_INVOICE_HISTORY_FULFILLED = 'FETCH_INVOICE_HISTORY_FULFILLED'
export const FETCH_INVOICE_HISTORY_REJECTED = 'FETCH_INVOICE_HISTORY_REJECTED'

export const FLUSH_INVOICE_DOWNLOAD_URL = 'FLUSH_INVOICE_DOWNLOAD_URL'

export const TYPE_DIRECT = 'DIRECT'
export const TYPE_REIMBURSE = 'REIMBURSEMENT'
export const TYPE_STATE = 'STATE'
export const TYPE_DISTRICT = 'DISTRICT'
export const TYPE_CENTRAL_BILL = 'CNTRL_BILL_SCHL'
const USED_AP_NON_CAPSTONE_EXAMS = 'NCPX'
const USED_AP_CAPSTONE_EXAMS = 'CPSX'
const USED_INTERNATIONAL_EXAMS = 'INTX'
const AP_SCORE_LABELS = 'SCLB'
const AP_LATE_ORDER_FEE = 'LORF'
const AP_LATE_TESTING_FEE = 'LTGF'
const UNUSED_CANCELLED_EXAMS = 'UCDX'
const CB_FEE_REDUCTION_NON_CAPSTONE = 'FRNC'
const CB_FEE_REDUCTION_CAPSTONE = 'FRCP'
export const AP_FREE_RESPONSE_BOOKS = 'FRBS'
export const STATE_SUBSIDY_CREDIT = 'SSSC'
export const DISTRICT_SUBSIDY_CREDIT = 'DSSC'

export const filterStudentsBase = `${NAV_LINKS.students}?${ORDER_EXAM_FILTER.filter}=${STUDENT_ENROLLMENT_STATUS_YES}&${FEES_FILTER.filter}=${NOT_REFUNDED_FEE}`
export const filterStudentsFeesBase = `${NAV_LINKS.students}?${FEES_FILTER.filter}=`

export const QUANTITY_LINKS = {
  [USED_AP_NON_CAPSTONE_EXAMS]: `${filterStudentsBase}&${CAPSTONE_FILTER.filter}=${FILTER_NO}`,
  [USED_AP_CAPSTONE_EXAMS]: `${filterStudentsBase}&${CAPSTONE_FILTER.filter}=${FILTER_YES}`,
  [USED_INTERNATIONAL_EXAMS]: filterStudentsBase,
  [AP_SCORE_LABELS]: `${NAV_LINKS.orders}#${ORDER_TAB_SERVICES}Tab`,
  [AP_LATE_ORDER_FEE]: `${filterStudentsFeesBase}${LATE_ORDERING_FEE}`,
  [AP_LATE_TESTING_FEE]: `${filterStudentsFeesBase}${LATE_TESTING_FEE}`,
  [UNUSED_CANCELLED_EXAMS]: `${filterStudentsFeesBase}${UNUSED_CANCELLATION_FEE}`,
  [CB_FEE_REDUCTION_NON_CAPSTONE]: `${filterStudentsBase}&${CAPSTONE_FILTER.filter}=${FILTER_NO}&${FEE_STATUS_FILTER.filter}=${REDUCED_FEE_STATUS}`,
  [CB_FEE_REDUCTION_CAPSTONE]: `${filterStudentsBase}&${CAPSTONE_FILTER.filter}=${FILTER_YES}&${FEE_STATUS_FILTER.filter}=${REDUCED_FEE_STATUS}`,
  [STATE_SUBSIDY_CREDIT]: `${filterStudentsBase}&${SUBSIDY_FILTER.filter}=`,
  [DISTRICT_SUBSIDY_CREDIT]: `${filterStudentsBase}&${SUBSIDY_FILTER.filter}=`,
}
