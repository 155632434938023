import { useHistory } from 'react-router-dom'
import { IncidentReportHistory } from '../../components/incidentReports'
import { setNavLink, unsetNavLink } from '../../actions/nav'
import { getIncidentReportAvailability } from '../../selectors/incidentReports'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { selectedIsCurrent },
  } = state
  const { active } = getIncidentReportAvailability(state)
  return { active, selectedIsCurrent }
}

const IncidentReportHistoryPage = ({ active, setNavLink, unsetNavLink, selectedIsCurrent }) => {
  const history = useHistory()

  useEffect(() => {
    setNavLink(NAV_LINKS.students, 'Back to Students')
    return () => unsetNavLink()
  }, [])

  useEffect(() => {
    if (selectedIsCurrent && !active) {
      // Redirect back to Students page
      history.replace(NAV_LINKS.students)
    }
  }, [active, selectedIsCurrent])

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className="container">
        <h2>Incident Report Submission History</h2>
        <IncidentReportHistory />
      </div>
    </CSSTransition>
  )
}

export default connect(mapStateToProps, { setNavLink, unsetNavLink })(IncidentReportHistoryPage)
