import { numberFormat } from '../../utils/numbers'
import { EXAM_NAME, ANCILLARY_MATERIALS } from '../../constants/ShipmentConstants'

const TableData = ({ lineItem, id }) => (
    <>
      <tr>
        <td>{lineItem.productDesc}</td>
        <td>{lineItem.quantity ? lineItem.quantity : 0}</td>
      </tr>
    {
        lineItem.cds?.map(item => (
          <tr key={`${lineItem.productDesc}_${item.examComponentDesc}_${id}`}>
            <td>&emsp;{item.examComponentDesc}</td>
            <td>{numberFormat(item.quantity)}</td>
          </tr>
        ))
    }
    </>
)

const ExamTable = ({ organizedSuborders, type, id }) => (
    <div className="cb-scrollbar-container" key={`${id}_${type}`} style={{ margin: '0 7px' }}>
      <div className="cb-scrollbar top">
        <div className="cb-scrollbar-thumb" />
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">{type === 'EXAM_MATERIALS' ? EXAM_NAME : ANCILLARY_MATERIALS}</th>
              <th scope="col">Quantity</th>
            </tr>
          </thead>
          <tbody>{organizedSuborders[type].map(lineItem => <TableData lineItem={lineItem} id={id} key={`${lineItem.productDesc}_${id}`} />)}</tbody>
        </table>
      </div>
    </div>)

export default ExamTable
