import { BasicInput, Loader } from '../../common'
import { updateEnrollmentSubsidies } from '../../../actions/studentsByOrg'
import {
  UPDATE_TYPE_ENROLLMENT_SUBSIDIES,
  EXCLUDE_SUBSIDIES,
  EXCLUDE_FROM_SUBSIDIES,
  UPDATE_TYPE_FEE,
} from '../../../constants/StudentConstants'
import { isAPCourse } from '../../../selectors/course'

const mapStateToProps = (state, { studentId }) => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod },
    studentsByOrg: {
      studentMap = {},
      update: { id, type, updating },
      exams,
      courseMap,
    },
    user: {
      data: { isLevelTwo, selectedOrgId },
    },
  } = state

  return {
    ...studentMap[studentId],
    studentId,
    updating: type === UPDATE_TYPE_ENROLLMENT_SUBSIDIES && studentId === id && updating,
    period: selectedEducationPeriod,
    shouldSeeCheckbox: isLevelTwo,
    orgId: selectedOrgId,
    enrollmentIds: exams.map(exam => exam.enrollmentId),
    allEnrollmentsExcluded: exams.every(exam => exam[EXCLUDE_FROM_SUBSIDIES] === true),
    updatingFeeStatus: type === UPDATE_TYPE_FEE && updating,

    eligibleExamsForExcludeSubsidies: exams.some(exam => {
      const course = courseMap[exam.testCd]
      return !!course && isAPCourse(course)
    }),
  }
}

const LABEL = 'Exclude all from Subsidies'

const StudentExcludeSubsidiesCheckbox = ({
  studentId,
  allEnrollmentsExcluded,
  period,
  orgId,
  shouldSeeCheckbox,
  updating,
  enrollmentIds,
  updatingFeeStatus,
  updateEnrollmentSubsidies,
  eligibleExamsForExcludeSubsidies,
}) => {
  if (updating)
    return (
      <div className="checkbox">
        <Loader size="sm" style={{ display: 'inline-block', marginRight: 6 }} />{' '}
        <strong>{LABEL}</strong>
      </div>
    )

  return shouldSeeCheckbox ? (
    <BasicInput
      type="checkbox"
      input={{
        name: `exclude-subsidy-${studentId}`,
        checked: allEnrollmentsExcluded,
        onChange: e =>
          updateEnrollmentSubsidies({
            studentId,
            orgId,
            period,
            enrollmentIds,
            action: EXCLUDE_SUBSIDIES,
            value: e.target.checked,
          }),
      }}
      label={LABEL}
      disabled={updatingFeeStatus || !eligibleExamsForExcludeSubsidies}
    />
  ) : null
}

export default connect(mapStateToProps, { updateEnrollmentSubsidies })(
  StudentExcludeSubsidiesCheckbox
)
