const mapStateToProps = state => ({
  step: state.funding.step,
})

const StepComplete = ({ step, title, totalSteps }) => (
  <li className="cb-stepper-complete">
    <span className="cb-glyph cb-glyph-circular cb-check" aria-hidden="true" />
    <p>{title}</p>
    <span className="sr-only cb-stepper-step">
      step {step} of {totalSteps} completed
    </span>
  </li>
)

const StepActive = ({ step, title, totalSteps }) => (
  <li className="cb-stepper-active">
    <span
      className="cb-glyph cb-disc"
      aria-hidden="true"
      style={{ fontSize: '2em', color: '#0077C8' }}
    />
    <p style={{ marginTop: 3 }}>{title}</p>
    <span className="sr-only cb-stepper-step">
      step {step} of {totalSteps} active
    </span>
  </li>
)

const StepIncomplete = ({ step, title, totalSteps }) => (
  <li>
    <span className="cb-glyph cb-glyph-circular" aria-hidden="true" />
    <p>{title}</p>
    <span className="sr-only cb-stepper-step">
      step {step} of {totalSteps} pending
    </span>
  </li>
)

const getStep = (current, totalSteps, active, title) => {
  if (current < active) return <StepComplete totalSteps={totalSteps} step={current} title={title} />
  if (current > active)
    return <StepIncomplete totalSteps={totalSteps} step={current} title={title} />
  return <StepActive totalSteps={totalSteps} step={current} title={title} />
}

export default connect(mapStateToProps)(({ step }) => {
  const totalSteps = 5

  return (
    <div>
      <div
        className="cb-stepper"
        role="region"
        aria-label="Steps for setting up AP District Funding"
      >
        <ol>
          {getStep(1, totalSteps, step, 'Contact Information')}
          {getStep(2, totalSteps, step, 'School Selection')}
          {getStep(3, totalSteps, step, 'Funding Type')}
          {getStep(4, totalSteps, step, 'Funding Configuration')}
          {getStep(5, totalSteps, step, 'Final Confirmation')}
        </ol>
      </div>
    </div>
  )
})
