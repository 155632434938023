import {
  fetchTeachersGrantedAccess,
  fetchAllowedToGrantAccess,
} from '../../../actions/settingsTeacherAccess'
import GrantedAccessTable from './GrantedAccessTable'
import AuthorizedTeachersTable from './AuthorizedTeachersTable'
import { getOrgIdAndEdPeriod } from '../../../selectors/user'

const mapStateToProps = state => {
  const {
    teachersGrantedAccess: { fetching, allowedToGrantAccess },
  } = state
  const { orgId, educationPeriodCd } = getOrgIdAndEdPeriod(state)

  return {
    orgId,
    educationPeriodCd,
    fetching,
    allowedToGrantAccess,
  }
}

const GrantedAccessPanel = ({ allowedToGrantAccess }) => {
  let TeachersGrantedAccessIntroText = null
  if (allowedToGrantAccess) {
    TeachersGrantedAccessIntroText = () => (
      <p>
        Teachers who have submitted the course-specific AP Course Audit form will automatically be
        granted access for the courses that have been approved by a school administrator. In special
        circumstances, such as a long term substitute teacher, you may grant access to teachers who
        haven&#39;t gone through this process.
      </p>
    )
  } else {
    TeachersGrantedAccessIntroText = () => (
      <p>
        Teachers who have submitted the course-specific AP Course Audit form will automatically be
        granted access for the courses that have been approved by a school administrator. In special
        circumstances, such as a long term substitute teacher, you may grant access to teachers who
        haven&#39;t gone through this process. Contact AP Services if you need to do so.
      </p>
    )
  }

  return (
    <>
      <TeachersGrantedAccessIntroText />
      <GrantedAccessTable />
    </>
  )
}

const AccessManagementPanel = ({
  orgId,
  educationPeriodCd,
  fetching,
  allowedToGrantAccess,
  fetchTeachersGrantedAccess,
  fetchAllowedToGrantAccess,
}) => {
  useEffect(() => {
    let isCurrent = true
    if (orgId && educationPeriodCd && isCurrent && !fetching) {
      fetchTeachersGrantedAccess(orgId, educationPeriodCd)
      fetchAllowedToGrantAccess({ orgId })
    }
    return () => (isCurrent = false)
  }, [orgId, educationPeriodCd])

  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <h5 className="h3" style={{ marginBottom: '10px' }}>
          Teachers: Granted Access
        </h5>
        <GrantedAccessPanel allowedToGrantAccess={allowedToGrantAccess} />
      </div>
      <div style={{ marginBottom: '24px' }}>
        <h5 className="h3" style={{ marginBottom: '10px' }}>
          Teachers: Authorized For My School
        </h5>
        <p>
          Teachers listed below have been automatically granted access to their courses via the AP
          Course Audit form.{' '}
          <strong>
            <em>
              If a teacher is no longer at your school or no longer teaches the AP course(s)
              specified
            </em>
          </strong>
          , click on the &lsquo;Disable&rsquo; link to remove their access; they will no longer be
          able to teach class sections or access AP Classroom for that course. If you want to
          disable a teacher&#39;s ability to make changes to the roster, use Roster Management
          instead.
        </p>
        <AuthorizedTeachersTable />
      </div>
    </>
  )
}

export default connect(mapStateToProps, { fetchTeachersGrantedAccess, fetchAllowedToGrantAccess })(
  AccessManagementPanel
)
