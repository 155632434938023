import { fetchNotTakingExamReasons } from '../../../actions/studentsCommon'
import { STUDENT_ENROLLMENT_STATUS_NO } from '../../../constants/StudentConstants'

const StudentNotTakingExamReason = ({ exam }) => {
  const [reasons, setReasons] = useState([])
  useEffect(() => {
    let isCurrent = true
    const fetchReasons = async () => {
      const { options } = await fetchNotTakingExamReasons()
      setReasons(options)
    }
    if (isCurrent) {
      fetchReasons()
    }
    return () => {
      isCurrent = false
    }
  }, [setReasons])

  if (exam.examIntent === STUDENT_ENROLLMENT_STATUS_NO && reasons.length > 0) {
    return (
      <div className="row">
        <div className="col-xs-12">
          Reason Not Taking:{' '}
          {reasons.find(reason => reason.value === exam.notTakingExamReasonCd)?.label ??
            'Reason not found'}
        </div>
      </div>
    )
  }

  return null
}

export default StudentNotTakingExamReason
