import { useHistory } from 'react-router-dom'
import { Modal } from '../../components/common'

const defaultMessage =
  'Are you sure you want to cancel? Any changes you have made will not be saved.'

const ConfirmCancelFormModal = ({
  destination,
  performActionOnContinue,
  confirmLabel,
  cancelLabel,
  customMessage,
}) => {
  const history = useHistory()

  const onContinue = e => {
    performActionOnContinue()
    if (destination) history.push(destination)
  }

  const footerActions = () => [
    { buttonLabel: cancelLabel || 'No', isDismissable: true, isPrimary: false },
    {
      buttonLabel: confirmLabel || 'Yes',
      isPrimary: true,
      isDismissable: true,
      onClick: onContinue,
    },
  ]

  return (
    <div>
      <Modal noHeader={true} showCloseButton={true} footerActions={footerActions()}>
        <div>
          <p>{customMessage || defaultMessage}</p>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmCancelFormModal
