import { openModal } from '../../actions/app'
import { updateStudentExam } from '../../actions/studentsByOrg'

const mapStateToProps = (state, { type, exam, makesOrderChange = false }) => {
  const {
    update: { type: updateType, updating, updated, error, id },
    studentMap,
    courseMap,
  } = state.studentsByOrg
  const { firstName, lastName } = studentMap[exam.studentId]
  const isActive = updateType === type && exam.examId === id

  return {
    updating: isActive && updating,
    updated: isActive && updated,
    error: isActive && error,
    studentName: `${firstName} ${lastName}`,
    courseName: courseMap[exam.testCd].name,
    breakpoint: state.app.breakpoint.name,
    makesOrderChange,
  }
}

const UpdateStudentExamModal = ({
  Component,
  exam,
  type,
  updating,
  updated,
  error,
  studentName,
  courseName,
  breakpoint,
  makesOrderChange,
  updateStudentExam,
  openModal,
}) => {
  const [shouldCloseModal, setShouldCloseModal] = useState(false)

  useEffect(() => {
    if (updated) {
      setShouldCloseModal(true)
    }
  }, [updated])

  const onCloseAction = (options = {}) => {
    if (makesOrderChange) {
      openModal('UpdateStudentExamSuccessModal', {
        type,
        exam,
        studentName,
        courseName,
        ...options,
      })
    }
  }

  const getFooterActions = (getUpdates, options = {}) => {
    const { disabled = false, actionBtnLabel, cancelBtnLabel } = options
    const updatedExam = getUpdates ? getUpdates() : exam
    return [
      { buttonLabel: cancelBtnLabel || 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: actionBtnLabel || 'Update',
        isPrimary: true,
        isDisabled: disabled || updating,
        busy: updating,
        onClick: () => updateStudentExam(updatedExam, type),
      },
    ]
  }

  return (
    <Component
      exam={exam}
      studentName={studentName}
      courseName={courseName}
      error={error}
      getFooterActions={getFooterActions}
      shouldCloseModal={shouldCloseModal}
      onCloseAction={onCloseAction}
      modalStyles={breakpoint !== 'mobile' ? { width: 750 } : {}}
    />
  )
}

export default connect(mapStateToProps, { openModal, updateStudentExam })(UpdateStudentExamModal)
