import { Link } from 'react-router-dom'
import { dismissNotification } from '../../../actions/notifications'
import { NAV_LINKS } from '../../../constants/NavConstants'
import { isEmpty } from '../../../utils/common'
import { AP_NOTIFICATIONS } from '../../../constants/NotificationConstants'

const mapStateToProps = (state, { notificationType }) => {
  const {
    user: {
      data: { selectedOrgId, isCSR, isSchoolAdmin },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  const { data = [], fetching, error } = state.notifications[AP_NOTIFICATIONS]
  const notifications = data.filter(notification => notification.messageType === notificationType)

  return {
    orgId: selectedOrgId,
    educationPeriodCd,
    isCSR,
    isSchoolAdmin,
    notificationType,
    notification: notifications.length ? notifications[0] : {},
    fetching,
    error,
  }
}

const CoordinatorApprovalRequestNotification = ({
  orgId,
  educationPeriodCd,
  notification,
  isCSR,
  isSchoolAdmin,
  notificationType,
  error,
  dismissNotification,
}) => {
  const [show, setShow] = useState(true)
  const { messageId, message } = notification

  const removeNotification = () => {
    setShow(false)
    dismissNotification({
      orgId,
      educationPeriodCd,
      notificationType,
      messageIds: [messageId],
    })
  }

  if (isCSR || isSchoolAdmin) return null

  if (!isEmpty(notification) && show && !error) {
    return (
      <div
        role="alert"
        className="alert alert-warning cb-alert-heading notification no-title"
        style={{
          marginTop: -21,
          border: 0,
          color: 'rgb(255, 255, 255)',
          backgroundColor: 'rgb(193, 49, 69)',
        }}
      >
        <div className="container" style={{ position: 'relative' }}>
          <p>
            {message}
            <Link
              to={NAV_LINKS.orderapprovalreqs}
              style={{
                marginLeft: '15px',
                whiteSpace: 'nowrap',
                color: 'rgb(255, 255, 255)',
              }}
            >
              <strong>View Approval Requests</strong>
              <i
                className="cb-glyph cb-east"
                aria-hidden={true}
                style={{ marginLeft: 5, fontSize: '.9em' }}
              />
            </Link>
          </p>
          <button
            type="button"
            className="close"
            style={{
              position: 'absolute',
              top: '-15px',
              right: '30px',
              color: 'rgb(255, 255, 255)',
            }}
            onClick={removeNotification}
          >
            <span className="sr-only">close</span>
          </button>
        </div>
      </div>
    )
  }
  return null
}

export default connect(mapStateToProps, { dismissNotification })(
  CoordinatorApprovalRequestNotification
)
