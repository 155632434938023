import { Text } from '../common'
import { TYPE_TEXT, TYPE_TEXT_AREA, TYPE_NUMERIC_RANGE } from '../../constants/SurveyConstants'

const SurveyTextQuestion = ({ question }) => {
  const { qid, question: label, type, range, required, options } = question
  const textField = type === TYPE_TEXT
  const numericRangeField = type === TYPE_NUMERIC_RANGE

  const formGroupStyle = {
    marginTop: '1em',
  }
  const labelStyle = {
    fontWeight: 'bold',
    marginBottom: '1em',
  }

  if (textField) {
    return (
      <Text
        key={qid}
        name={`${qid}.value`}
        label={<span dangerouslySetInnerHTML={{ __html: `${label}` }} />}
        placeholder=""
        isRequired={required}
        formGroupStyle={formGroupStyle}
        labelStyle={labelStyle}
        maxlength="500"
        errorOnPristine={true}
      />
    )
  }
  if (numericRangeField) {
    const [minRange, maxRange] = range?.split('-') ?? []
    return (
      <Text
        key={qid}
        name={`${qid}.value`}
        label={<span dangerouslySetInnerHTML={{ __html: `${label}` }} />}
        placeholder="Enter number"
        isRequired={required}
        formGroupStyle={formGroupStyle}
        labelStyle={labelStyle}
        maxlength={maxRange?.length ?? 2}
        errorOnPristine={true}
      />
    )
  }
  return null
}

export default SurveyTextQuestion
