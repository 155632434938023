import * as c from '../constants/SettingsConstants';

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  personId: '',
  name: '',
  email: ''
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case c.RESET_SEARCH_FOR_TEACHER:
      return { ...state,
        fetching: false,
        fetched: false,
        error: null,
        personId: '',
        name: '',
        email: ''
      }

    case c.SEARCH_FOR_TEACHER_PENDING:
      return { ...state,
        fetching: true,
        fetched: false,
        error: null,
        personId: '',
        name: '',
        email: ''
      }

    case c.SEARCH_FOR_TEACHER_FULFILLED:
      return { ...state,
        fetching: false,
        fetched: true,
        personId: payload.personId,
        name: `${payload.firstName} ${payload.lastName}`,
        email: payload.email
      }
    case c.SEARCH_FOR_TEACHER_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    default:
      return state
  }
}