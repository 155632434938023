import memoize from 'memoizee'

export const reformatExamRooms = memoize(data =>
  data.reduce((obj, d) => {
    const id = d.description.replace(/ /g, '')
    return {
      ...obj,
      [id]: {
        ...d,
        id,
        examSessions: d.examSessions.map(e => ({ ...e, typeId: id })),
      },
    }
  }, {})
)

export const formatSSDMaterials = memoize(
  data => data.ssdMaterialDescriptions && data.ssdMaterialDescriptions.sort().join(', ')
)

const partitionArr = (arr, compare) =>
  arr.reduce(
    (arr, item) => {
      if (compare(item)) arr[1] = item
      else arr[0].push(item)
      return arr
    },
    [[], {}]
  )

export const getSessions = memoize(schedule =>
  Object.values(schedule).reduce((arr, { examSessions = [] }) => [...arr, ...examSessions], [])
)

export const getSession = memoize((schedule, sessionId) =>
  getSessions(schedule).find(s => s.id === sessionId)
)

export const getRooms = memoize(schedule => {
  return getSessions(schedule).reduce(
    (rooms, session) => [
      ...rooms,
      ...session.exams.reduce(
        (arr, e) => [
          ...arr,
          ...e.rooms.map(r => ({
            courseName: e.courseName,
            testCd: e.testCd,
            sessionId: session.id,
            examTime: session.examTime,
            ...r,
          })),
        ],
        []
      ),
    ],
    []
  )
})

export const getRoom = memoize((schedule, roomId) => getRooms(schedule).find(r => r.id === roomId))

export const getNestedPartitions = memoize((schedule, typeId, sessionId, testCd) => {
  const [untouchedSessions, updatedSession] = partitionArr(
    schedule[typeId].examSessions,
    session => session.id === sessionId
  )
  const [untouchedExams, updatedExam] = partitionArr(
    updatedSession.exams,
    exam => exam.testCd === testCd
  )

  return {
    untouchedSessions,
    updatedSession,
    untouchedExams,
    updatedExam,
  }
})

export const updateRoom = (schedule, updates, isDelete) => {
  const { sessionId, testCd, room } = updates
  const { typeId } = getSession(schedule, sessionId)
  const { untouchedSessions, updatedSession, untouchedExams, updatedExam } = getNestedPartitions(
    schedule,
    typeId,
    sessionId,
    testCd
  )
  const [untouchedRooms] = partitionArr(updatedExam.rooms, r => r.id === room.id)

  return {
    ...schedule,
    [typeId]: {
      ...schedule[typeId],
      examSessions: [
        ...untouchedSessions,
        {
          ...updatedSession,
          exams: [
            ...untouchedExams,
            {
              ...updatedExam,
              rooms: isDelete ? untouchedRooms : [...untouchedRooms, room],
            },
          ],
        },
      ],
    },
  }
}

export const removeStudents = (existing, toRemove) => {
  return existing.filter(s =>
    Array.isArray(toRemove) ? !toRemove.find(id => id === s.id) : s.id !== toRemove
  )
}

export const filterStudents = (type, sections, roomId) => {
  function filter(condition) {
    return sections.reduce((acc, section) => {
      const filteredStudents = section.students.filter(condition)
      return filteredStudents.length ? [...acc, { ...section, students: filteredStudents }] : acc
    }, [])
  }

  if (roomId) // all students in a given room
    return filter(s => s.roomAssignment?.id === roomId)  
    

  switch (type) {
    case '0': // all unassigned students
      return filter(s => !s.roomAssignment)
    case '1': // unassigned, non-ssd id
      return filter(s => !s.roomAssignment && !s.ssdId)
    case '2': // unassigned, ssd id
      return filter(s => !s.roomAssignment && s.ssdId)
    default:
      return sections // all students
  }
}
