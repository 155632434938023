const mapStateToProps = state => {
  const {
    user: {
      data: { selectedRole, roles },
    },
  } = state
  return {
    aiCode: roles[selectedRole].aiCode,
  }
}

export default connect(mapStateToProps)(({ print, aiCode }) => {
  if (print && aiCode)
    return (
      <div
        style={{
          marginLeft: 'auto',
          fontSize: '1.5em',
          fontWeight: 'bold',
          border: '4px solid #505050',
          alignSelf: 'center',
          padding: '10px 15px',
        }}
      >
        AI Code: {aiCode}
      </div>
    )
  return null
})
