import { formatter, isCentralBill } from './utils'

import styles from '../../assets/style/scss/invoices.scss'

export default props => {
  const { totalInvoiceDueAmount, totalInvoiceAmount, totalPaymentAmount } = props
  return (
    <div className={styles['invoice-totals']}>
      <div className={styles['invoice-total']}>
        <div>Net Total</div>
        <div>{formatter(totalInvoiceAmount)}</div>
      </div>
      {totalPaymentAmount ? (
        <div className={styles['invoice-total']}>
          <div>Payments Received</div>
          <div>({formatter(totalPaymentAmount)})</div>
        </div>
      ) : null}
      {totalInvoiceDueAmount && !isCentralBill(props) ? (
        <div className={styles['invoice-total']}>
          <div>Amount Due</div>
          <div>{formatter(totalInvoiceDueAmount)}</div>
        </div>
      ) : null}
    </div>
  )
}
