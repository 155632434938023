import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { getExamWindowsForCourse, isExamPaper } from '../../../selectors/examWindows'
import {
  SSD_APPROVAL_STATUS,
  FILTER_NOT_SUBMITTED,
  SSD_SUBMITTED,
  SSD_UPDATED_NOT_SUBMITTED,
  SSD_UPDATED,
  SSD_CANCELLED_NOT_SUBMITTED,
  SSD_CANCELLED_SUBMITTED,
  SSD_APPROVED,
  SSD_REQUIRES_INFO,
  SSD_DENIED,
} from '../../../constants/StudentConstants'
import { SSDMaterialsList } from '../../common'

const STATUS_STYLE_MAP = {
  [FILTER_NOT_SUBMITTED]: { fontStyle: 'italic' },
  [SSD_SUBMITTED]: { fontWeight: 'bold' },
  [SSD_UPDATED_NOT_SUBMITTED]: { fontStyle: 'italic' },
  [SSD_UPDATED]: { fontWeight: 'bold' },
  [SSD_CANCELLED_NOT_SUBMITTED]: { fontStyle: 'italic' },
  [SSD_CANCELLED_SUBMITTED]: { fontWeight: 'bold' },
  [SSD_APPROVED]: { fontWeight: 'bold' },
  [SSD_REQUIRES_INFO]: { fontWeight: 'bold' },
  [SSD_DENIED]: { fontWeight: 'bold' },
}
const SSD_MATERIALS_STYLE_MAP = {
  [FILTER_NOT_SUBMITTED]: { fontStyle: 'italic' },
  [SSD_SUBMITTED]: { fontStyle: 'normal' },
  [SSD_UPDATED_NOT_SUBMITTED]: { fontStyle: 'italic' },
  [SSD_UPDATED]: { fontStyle: 'normal' },
}

const mapStateToProps = (state, { exam }) => {
  const { update } = state.studentsByOrg
  const examWindows = getExamWindowsForCourse(state, exam.testCd)
  const { examDateTime, displayName, requiresUnlocking } = examWindows[exam.testWindow]

  return {
    update,
    examDate: formatDate(examDateTime, DATETIME_FORMATS.shortMonthDay),
    examDisplayName: displayName,
    examRequiresUnlocking: requiresUnlocking,
    isPaper: isExamPaper(state, exam.testCd, exam.testWindow),
  }
}

const StatusExamDate = ({
  etsApprovalStatus,
  examDate,
  examDisplayName,
  examRequiresUnlocking,
}) => {
  if (examRequiresUnlocking) {
    return (
      <div style={SSD_MATERIALS_STYLE_MAP[etsApprovalStatus]}>
        Exam Date {examDisplayName} Testing
      </div>
    )
  }
  return examDate ? (
    <div style={SSD_MATERIALS_STYLE_MAP[etsApprovalStatus]}>Exam Date {examDate}</div>
  ) : null
}

const StatusComments = ({ comments }) => {
  return comments ? <div style={{ fontStyle: 'italic' }}>{`"${comments}"`}</div> : null
}

const SSDHistoryRow = ({
  status: {
    etsApprovalStatus,
    ssdDataSet,
    examDate,
    examDisplayName,
    examRequiresUnlocking,
    comments,
    updateTimestamp,
  },
  isPaper,
  first,
}) => (
  <tr>
    <td style={first ? { borderTopWidth: 0 } : {}}>
      <div style={STATUS_STYLE_MAP[etsApprovalStatus]}>
        {SSD_APPROVAL_STATUS[etsApprovalStatus]}
      </div>
      {ssdDataSet.length ? (
        <>
          <SSDMaterialsList selectedSSDMaterials={ssdDataSet} isPaper={isPaper}>
            <div style={SSD_MATERIALS_STYLE_MAP[etsApprovalStatus]} />
          </SSDMaterialsList>
          <StatusExamDate
            etsApprovalStatus={etsApprovalStatus}
            examDate={examDate}
            examDisplayName={examDisplayName}
            examRequiresUnlocking={examRequiresUnlocking}
          />
          <StatusComments comments={comments} />
        </>
      ) : null}
    </td>
    <td style={first ? { borderTopWidth: 0, whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}>
      {formatDate(updateTimestamp, DATETIME_FORMATS.longMonthDayYear)}
    </td>
  </tr>
)

const SSDHistoryTable = ({
  data = [],
  examDate,
  examDisplayName,
  examRequiresUnlocking,
  isPaper,
}) => (
  <table
    className="table"
    summary="Status of Special Exam Materials approvals are listed by status date in descending order."
    style={{ marginTop: '10px', marginBottom: 0 }}
  >
    <caption className="sr-only">
      A view of Special Exam Materials statuses that require approval
    </caption>
    <thead>
      <tr role="row">
        <th scope="col" role="columnheader">
          Status
        </th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      {data.map((row, index) => (
        <SSDHistoryRow
          key={APRICOT.utils.uniqueID(5, 'history')}
          status={{ ...row, examDate, examDisplayName, examRequiresUnlocking }}
          isPaper={isPaper}
          first={index === 0}
        />
      ))}
    </tbody>
  </table>
)

const ExamSSDApprovalHistory = ({
  exam,
  examDate,
  examDisplayName,
  examRequiresUnlocking,
  isPaper,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { etsApprovalHistories = [] } = exam
  const hasHistory = etsApprovalHistories?.length
  const showTable = hasHistory && isOpen
  const showNoHistoryMsg = !hasHistory && isOpen

  const clickApprovalStatusHistoryButton = () => setIsOpen(!isOpen)

  return (
    <>
      <div>
        <button
          type="button"
          className="btn-link cb-small-font-size"
          style={{ marginTop: '8px', padding: 0 }}
          onClick={clickApprovalStatusHistoryButton}
        >
          {isOpen ? 'Hide' : 'Show'} History{' '}
          <span aria-hidden="true" className={`cb-glyph ${isOpen ? 'cb-minus' : 'cb-plus'}`} />
        </button>
      </div>
      {showTable ? (
        <SSDHistoryTable
          exam={exam}
          data={etsApprovalHistories}
          examDate={examDate}
          examDisplayName={examDisplayName}
          examRequiresUnlocking={examRequiresUnlocking}
          isPaper={isPaper}
        />
      ) : null}
      {showNoHistoryMsg ? <div style={{ fontStyle: 'italic' }}>No history</div> : null}
    </>
  )
}

export default connect(mapStateToProps)(ExamSSDApprovalHistory)
