import { formatDate, parseDateTime } from '@myap/ui-library/esm/date'
import { STATUS_CONFIRMED } from '../../constants/PackingListConstants'
import packingStyles from '../../assets/style/scss/packing-details.scss'

function confirmedMarkup() {
  return (
    <>
      <i className="cb-glyph cb-check cb-green" aria-hidden /> Confirmed
    </>
  )
}
const PackingListStep2Title = ({ courseName, courseDateTime, status }) => {
  // Because courseDateTime might/will come as UTC and we don't want exam times to be zoned
  // parseDateTime() will then create a Date object in user's local time
  // Because the now parsed date object is already a Date, formatDate() will ignore the time cut-off
  // condition and simply format the provided locally-zoned Date object.
  const processedCourseDateTime = courseDateTime.split('.')[0]
  return (
    <>
      <div className={`${packingStyles.container}`}>
        <h4 style={{ marginBottom: '0' }}>{courseName}</h4>
        <span
          style={{ textAlign: 'right' }}
          className={status === STATUS_CONFIRMED ? 'cb-green-color' : ''}
        >
          {status === STATUS_CONFIRMED ? confirmedMarkup() : 'Review & Confirm'}
        </span>
      </div>
      <p style={{ padding: '0' }}>
        {formatDate(parseDateTime(processedCourseDateTime), 'MMMM do, yyyy, h:mm a')}
      </p>
    </>
  )
}

export default PackingListStep2Title
