import { Link } from 'react-router-dom'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { NAV_LINKS } from '../../constants/NavConstants'
import { Loader } from '../common'
import InvoiceStatus from './InvoiceStatus'
import { addFetchAction, removeFetchAction } from '../../actions/app'
import { fetchInvoices } from '../../actions/invoices'

import styles from '../../assets/style/scss/invoices.scss'

const mapStateToProps = ({
  invoices: { fetching, error },
  settingsDeadlines: { data: settingsDeadlinesData },
  app: { breakpoint },
  settingsOrg,
  user: {
    data: { selectedOrgId },
  },
  settingsEducationPeriod: { selectedEducationPeriod },
}) => {
  const { invoiceAvailableDate, invoiceAvailableDateIsPast } =
    settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  return {
    orgId: selectedOrgId,
    educationPeriodCd: selectedEducationPeriod,
    invoiceAvailableDate: formatDate(invoiceAvailableDate, DATETIME_FORMATS.longMonthDayYear),
    invoiceAvailableDateIsPast,
    fetching,
    error,
    breakpoint: breakpoint.name,
    isCentralBill: settingsOrg[selectedOrgId]?.data?.schoolInformation?.billing?.centralBilling,
  }
}

const InvoiceSummary = ({
  orgId,
  educationPeriodCd,
  fetching,
  error,
  invoiceAvailableDate,
  invoiceAvailableDateIsPast,
  breakpoint,
  isCentralBill,
  fetchInvoices,
  addFetchAction,
  removeFetchAction,
}) => {
  useEffect(() => {
    addFetchAction({
      name: 'fetchInvoices',
      action: (orgId, educationPeriodCd) => fetchInvoices(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchInvoices')
  }, [])

  if (error) {
    return (
      <div className={styles.invoices}>
        <h2>Invoices</h2>
        <p>Your invoice has not been generated. Once available, you can view it here.</p>
      </div>
    )
  }

  if (fetching) {
    return <Loader size="md" />
  }

  return (
    <div className={styles.invoices}>
      <h2>Invoices</h2>
      {invoiceAvailableDateIsPast ? (
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className={styles['invoice-status-box']}>
              <div className="row">
                <div className="col-sm-9">
                  <InvoiceStatus />
                </div>
                <div className="col-sm-3 cb-xs-align-left cb-align-right">
                  <Link to={NAV_LINKS.invoice} className={styles[breakpoint]}>
                    View invoice <i className="cb-glyph cb-east" aria-hidden={true} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>
          Your invoice will be ready {isCentralBill ? 'for your district' : ''} on{' '}
          <strong>{invoiceAvailableDate}</strong>.
          <Link to={NAV_LINKS.invoice} style={{ marginLeft: 10 }}>
            Prepare for invoice generation <i className="cb-glyph cb-east" aria-hidden={true} />
          </Link>
        </p>
      )}
    </div>
  )
}

export default connect(mapStateToProps, { fetchInvoices, addFetchAction, removeFetchAction })(
  InvoiceSummary
)
