import { connect } from 'react-redux'
import { submit } from 'redux-form'
import { Modal, Error } from '../../components/common'
import { deleteDistrictFunding } from '../../actions/funding'
import { getSelectedEducationPeriod } from '../../selectors/settings'

const mapStateToProps = state => {
  const {
    funding: { deleting, deleted, error, fundingType },
    user: {
      data: { selectedOrgId },
    },
  } = state
  return {
    educationPeriodCd: getSelectedEducationPeriod(state),
    orgId: selectedOrgId,
    data: { fundingType },
    deleting,
    deleted,
    error,
  }
}

const ConfirmDeleteDistrictFundingModal = ({
  deleteDistrictFunding,
  orgId,
  educationPeriodCd,
  data,
  deleting,
  deleted,
  error,
}) => {
  useEffect(() => {
    if (deleted) {
      window.location = Config.MYAP_URL.prof
    }
  }, [deleted])

  const closeButton = {
    buttonLabel: 'Cancel',
    isDismissable: true,
    isPrimary: false,
    isDisabled: deleting || deleted,
  }

  const confirmButton = {
    buttonLabel: 'Confirm',
    onClick: () => {
      deleteDistrictFunding({ orgId, educationPeriodCd, data })
    },
    isDismissable: false,
    isPrimary: true,
    busy: deleting,
    isDisabled: deleting || deleted,
  }
  return (
    <div>
      <Modal headerTitle="Delete Funding Plan" footerActions={[closeButton, confirmButton]}>
        {error ? (
          <Error message={`${error}`} style={{ marginTop: '24px' }} />
        ) : (
          <p>Are you sure you want to delete your district funding plan?</p>
        )}
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps, {
  submit,
  deleteDistrictFunding,
})(ConfirmDeleteDistrictFundingModal)
