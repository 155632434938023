import { formatDate, formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { fetchStudentChangeLog } from '../../../actions/studentsCommon'
import { Error, SortableTable, Loader } from '../../common'
import {
  STUDENT_CHANGELOG_STATUS,
  STUDENT_CHANGE_LOG_TYPE,
  SEE_SUBMISSION_DEADLINE_MSG,
} from '../../../constants/StudentConstants'

const mapStateToProps = (state, { studentId }) => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    user: {
      data: { selectedOrgId: orgId },
    },
    studentChangeLog: { error, fetching, data },
    studentsByOrg: {
      student: { firstName, lastName },
      update: { updated: updatedStudent },
    },
  } = state
  return {
    orgId,
    educationPeriodCd,
    studentId,
    error,
    fetching,
    data,
    firstName,
    lastName,
    updatedStudent,
  }
}

const ChangeLogRow = rows => (
  <>
    {rows.map(
      ({
        changeLogId,
        changeLogType,
        changeStatus,
        courseName,
        examDate,
        lastUpdatedByUserFirstName,
        lastUpdatedByUserLastName,
        lastUpdatedByUserName,
        lastUpdatedDate,
        noEndOfCourseExam,
      }) => (
        <tr key={changeLogId}>
          <td className={`logId-${changeLogId}`}>{STUDENT_CHANGE_LOG_TYPE[changeLogType]}</td>
          <td className="log-status" style={{ textTransform: 'capitalize' }}>
            {STUDENT_CHANGELOG_STATUS[changeStatus] || changeStatus.toLowerCase()}
          </td>
          <td className="log-coursename">{courseName || 'N/A'}</td>
          <td className="log-examdate">
            {noEndOfCourseExam
              ? SEE_SUBMISSION_DEADLINE_MSG
              : examDate
              ? formatDate(examDate, DATETIME_FORMATS.mediumMonthDayYear)
              : 'N/A'}
          </td>
          <td className="log-updatedby">
            {lastUpdatedByUserFirstName && lastUpdatedByUserLastName
              ? `${lastUpdatedByUserLastName}, ${lastUpdatedByUserFirstName}`
              : lastUpdatedByUserName}
          </td>
          <td className="log-updatedon">
            {formatDateTime(lastUpdatedDate, 'MM/dd/yyyy HH:mm:ss')}
          </td>
        </tr>
      )
    )}
  </>
)

const StudentChangeLog = ({
  orgId,
  educationPeriodCd,
  studentId,
  fetching,
  error,
  data,
  firstName,
  lastName,
  updatedStudent,
  fetchStudentChangeLog,
}) => {
  const headerColumns = [
    {
      headerText: { title: 'Update Type' },
      key: 'changeLogType',
      sorting: 'asc',
    },
    {
      headerText: { title: 'Value' },
      key: 'changeStatus',
      sorting: 'asc',
    },
    {
      headerText: { title: 'Exam Name' },
      key: 'courseName',
      sorting: 'asc',
    },
    {
      headerText: { title: 'Exam Date' },
      key: 'examDate',
      sorting: 'asc',
    },
    {
      headerText: { title: 'Updated By' },
      key: 'lastUpdatedByUserName',
      sorting: 'asc',
    },
    {
      headerText: { title: 'Updated On' },
      key: 'lastUpdatedDate',
      sorting: 'asc',
      defaultSort: true,
    },
  ]

  // Runs when page initially loads
  // Explicitly NOT including fetching in dependency array as that would lead to infinite loop
  useEffect(() => {
    let isCurrent = true
    if (isCurrent && !fetching && studentId && orgId && educationPeriodCd) {
      fetchStudentChangeLog(studentId, orgId, educationPeriodCd)
    }
    return () => {
      isCurrent = false
    }
  }, [studentId, orgId, educationPeriodCd])

  // Runs when a change happens on the student
  useEffect(() => {
    if (updatedStudent && studentId && orgId && educationPeriodCd) {
      fetchStudentChangeLog(studentId, orgId, educationPeriodCd)
    }
  }, [updatedStudent, studentId, orgId, educationPeriodCd])

  if (error) return <Error message={error} />

  if (fetching) {
    return <Loader />
  }

  if (data?.length)
    return (
      <div>
        <h3>Student Change Log</h3>
        <SortableTable
          tableAttributes={{
            id: 'StudentChangeLogTable',
            summary:
              'Change log events are listed by date events were updated on in ascending order. Additional sorting options are available by clicking on the column headers.',
            className: 'table cb-table-striped-reverse',
          }}
          data={data}
          rowBuilder={ChangeLogRow}
          columns={headerColumns}
          tableCaption={{
            caption: `Listing of all change log events for ${firstName} ${lastName}`,
            srOnly: true,
          }}
          tbody={true}
        />
      </div>
    )

  return null
}

export default connect(mapStateToProps, { fetchStudentChangeLog })(StudentChangeLog)
