import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'unfetch/polyfill/index'
import { Provider } from 'react-redux'
import { Switch, Router } from 'react-router-dom'
import { setEnvironment } from '@myap/ui-library/esm/profile/environment'
import PrivateRoute from './components/routes/PrivateRoute'
import PublicRoute from './components/routes/PublicRoute'
import store from './store/configureStore'
import MainApp from './components/app/MainApp'
import { LandingPage, RedirectPage, ModalContainer } from './containers'
import { startTracking } from './utils/analytics'
import { AthenaGlobalHeader, AthenaGlobalFooter } from './components/common'
import { NAV_LINKS } from './constants/NavConstants'
import history from './utils/history'

startTracking(history, store)

// init environment
setEnvironment(Config.ENV)

// Opt-in to Webpack hot module replacement
if (module.hot) module.hot.accept()

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <div>
        <AthenaGlobalHeader />
        <Switch>
          <PrivateRoute path="/" exact component={RedirectPage} />
          <PublicRoute path={NAV_LINKS.login} component={LandingPage} />
          <PrivateRoute component={MainApp} />
        </Switch>
        <ModalContainer />
        <AthenaGlobalFooter />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
)
