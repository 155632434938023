import { isInvalid, isPristine, submit } from 'redux-form'
import { Error } from '@myap/ui-library/esm/components/apricot'
import ExamRoomForm from '../../components/examRoom/ExamRoomForm'
import { Modal } from '../../components/common'
import { EXAM_ROOM_FORM, EXAM_ROOM_FIELDS as FIELDS } from '../../constants/ExamRoomConstants'
import styles from '../../assets/style/scss/exam-room.scss'
import { openModal } from '../../actions/app'

const mapStateToProps = (state, { id, oldRoom }) => {
  const { settingsEducationPeriod, user, examRoomSchedule, examRoomStudents } = state
  const {
    [id]: students = [],
    selectedStudents = [],
    saving: studentsSaving,
    saved: studentsSaved,
    serror: studentSaveError,
  } = examRoomStudents
  const { saving: roomSaving, saved: roomSaved, serror: roomSaveError } = examRoomSchedule
  const numOfStudents = students.length
  const numOfSelectedStudents = selectedStudents.length
  const isEdit = Boolean(id)
  const isCreateAndReassign = Boolean(!isEdit && oldRoom && numOfSelectedStudents)

  return {
    educationPeriod: settingsEducationPeriod.selectedEducationPeriod,
    orgId: user.data.selectedOrgId,
    invalid: isInvalid(EXAM_ROOM_FORM)(state) || isPristine(EXAM_ROOM_FORM)(state),
    minimumCapacity:
      isEdit && numOfStudents
        ? numOfStudents
        : isCreateAndReassign && numOfSelectedStudents
        ? numOfSelectedStudents
        : 1,
    isEdit,
    isCreateAndReassign,
    saving: roomSaving || (isCreateAndReassign && studentsSaving),
    saved: roomSaved && (!isCreateAndReassign || studentsSaved),
    roomSaveError,
    studentSaveError,
    selectedStudents,
  }
}

const ExamRoomCreateModal = props => {
  const {
    modalCloseFocusElem,
    courseName,
    testCd,
    educationPeriod,
    orgId,
    sessionId,
    saved,
    saving,
    submit,
    invalid,
    openModal,
    id,
    totalCapacity,
    description,
    name,
    proctorNames,
    minimumCapacity,
    assignedCapacity = 0,
    isEdit,
    isCreateAndReassign,
    oldRoom,
    selectedStudents,
    roomSaveError,
    studentSaveError,
  } = props
  const deleteFooterButton = {
    buttonLabel: 'Delete Room',
    className: styles['btn-delete'],
    isDismissable: true,
    onClick: () => openModal('ExamRoomDeleteModal', { ...props, modalCloseFocusElem }),
  }
  const commonFooterButtons = [
    { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false, isDisabled: saving },
    {
      buttonLabel: `${isEdit ? 'Update Room' : 'Save Room'}`,
      isPrimary: true,
      onClick: () => submit(EXAM_ROOM_FORM),
      isDisabled: invalid || saving || studentSaveError,
      busy: saving,
    },
  ]
  const formProps = {
    educationPeriod,
    orgId,
    sessionId,
    testCd,
    isEdit,
    isCreateAndReassign,
    oldRoom,
    selectedStudents,
    initialValues: { id, totalCapacity, description, name, proctorNames, assignedCapacity },
    [`minimum${FIELDS.capacity.field}`]: minimumCapacity,
  }

  return (
    <Modal
      shouldCloseModal={saved}
      headerTitle={`${courseName}: ${isEdit ? 'Edit' : 'Add'} Room`}
      modalCloseFocusElem={modalCloseFocusElem}
      footerActions={id ? [deleteFooterButton, ...commonFooterButtons] : commonFooterButtons}
    >
      <ExamRoomForm {...formProps} />
      {studentSaveError || roomSaveError ? (
        <Error
          title={
            studentSaveError
              ? 'Room was created, but an error occurred reassigning students'
              : `Error ${isEdit ? 'updating' : 'creating'} room`
          }
          message={studentSaveError || roomSaveError}
        />
      ) : null}
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  { submit, openModal }
)(ExamRoomCreateModal)
