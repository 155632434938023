import GrantorAddress from './GrantorAddress'
import DownloadInvoiceLink from './DownloadInvoiceLink'
import { isDirty, getStateDirectGrantor } from './utils'

const StateDirect = ({ data }) => {
  const { invoicePDFGenerated } = data
  const grantor = getStateDirectGrantor(data)
  const updating = !invoicePDFGenerated || isDirty(data)

  if (grantor) {
    return (
      <>
        <h3 className="h4">State Copy</h3>
        {updating ? (
          <p>
            You have made changes which have impacted the state copy of your invoice. We will notify
            you when an updated PDF version is ready to access.
          </p>
        ) : (
          <>
            <p>
              Your state requires you to send them a copy of this invoice in order to pay applicable
              exam fees. Please send this copy to the address below:
            </p>
            <GrantorAddress {...grantor} />
            <DownloadInvoiceLink showAsButton={true} title="View State Copy" />
          </>
        )}
      </>
    )
  }
  return null
}

export default StateDirect
