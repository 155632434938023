import { safeNumber } from '../../utils/numbers'
import {
  CANCELLATION,
  LATE_ORDER,
  LATE_TESTING,
  OTHER_FEES,
} from '../../constants/FundingConstants'

const getIncludedFeesAsCSV = feeTypeSubsidies => {
  const includedFees = []
  if (feeTypeSubsidies?.cancellation?.active) {
    includedFees.push(OTHER_FEES[CANCELLATION])
  }
  if (feeTypeSubsidies?.lateTesting?.active) {
    includedFees.push(OTHER_FEES[LATE_TESTING])
  }
  if (feeTypeSubsidies?.lateOrder?.active) {
    includedFees.push(OTHER_FEES[LATE_ORDER])
  }
  if (includedFees.length === 0) return 'None'
  return includedFees.join(', ')
}

const SelectedSubsidiesTable = ({ examPricing, subsidies, title }) => (
  <>
    {safeNumber(subsidies?.nonCapstone?.amount) > 0 ||
    safeNumber(subsidies?.capstone?.amount) > 0 ? (
      <>
        <dt>{title}</dt>
        <dd>
          <table className="table">
            <thead>
              <tr>
                <th>Course Type</th>
                <th>Taking Course?</th>
                <th>Other Fees</th>
                <th>State Pays</th>
                <th>District Funding</th>
                <th>School Pays</th>
              </tr>
            </thead>
            <tbody>
              {safeNumber(subsidies?.nonCapstone?.amount) > 0 ? (
                <tr>
                  <td>Non-Capstone</td>
                  <td>{subsidies?.nonCapstone?.courseTaken ? 'Yes' : 'No'}</td>
                  <td>{getIncludedFeesAsCSV(subsidies?.nonCapstone?.feeTypeSubsidies)}</td>
                  <td>${safeNumber(examPricing?.nonCapstone?.stateFunding)}</td>
                  <td>${safeNumber(subsidies?.nonCapstone?.amount)}</td>
                  <td>
                    $
                    {safeNumber(examPricing?.nonCapstone?.testPrice) -
                      safeNumber(examPricing?.nonCapstone?.stateFunding) -
                      safeNumber(subsidies?.nonCapstone?.amount)}{' '}
                    + applicable fees
                  </td>
                </tr>
              ) : null}
              {safeNumber(subsidies?.capstone?.amount) > 0 ? (
                <tr>
                  <td>Capstone</td>
                  <td>{subsidies?.capstone?.courseTaken ? 'Yes' : 'No'}</td>
                  <td>{getIncludedFeesAsCSV(subsidies?.capstone?.feeTypeSubsidies)}</td>
                  <td>${safeNumber(examPricing?.capstone?.stateFunding)}</td>
                  <td>${safeNumber(subsidies?.capstone?.amount)}</td>
                  <td>
                    $
                    {safeNumber(examPricing?.capstone?.testPrice) -
                      safeNumber(examPricing?.capstone?.stateFunding) -
                      safeNumber(subsidies?.capstone?.amount)}{' '}
                    + applicable fees
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </dd>
      </>
    ) : null}
  </>
)

export default SelectedSubsidiesTable
