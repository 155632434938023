import { Dropdown } from '../../common'

import styles from '../../../assets/style/scss/exam-readiness.scss'

const TeacherCourseDropdown = ({ selectedCourse = {}, setSelectedCourse, courses }) => {
  const { testCode, courseName } = selectedCourse

  return courses.length ? (
    <div className={styles['teacher-table-header']}>
      {courses.length > 1 ? (
        <Dropdown
          label={courseName}
          id="selectCourseDropdown"
          menuItems={courses.reduce(
            (arr, c) =>
              c.testCode !== testCode
                ? [...arr, { ...c, label: c.courseName, clickEvent: () => setSelectedCourse(c) }]
                : arr,
            []
          )}
        />
      ) : (
        <h3>{courseName}</h3>
      )}
    </div>
  ) : null
}

export default TeacherCourseDropdown
