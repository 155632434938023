import {
  StreetAddress,
  ChangeAddressInstructions,
  Loader,
  Error,
  Tooltip,
  OpenNewWindow,
} from '../../common'
import { numberFormat } from '../../../utils/numbers'
import EditButton from './EditButton'
import PersonInfo from './PersonInfo'
import SignatureStatus from './SignatureStatus'
import {
  STUDENT_POPULATION_TOOLTIP,
  FRPL_STUDENTS_TOOLTIP,
} from '../../../constants/SettingsConstants'

const mapStateToProps = state => {
  const { selectedOrgId } = state.user.data
  const { fetching, fetched, error, data = {} } = state.settingsOrg[selectedOrgId] || {}
  return { ...data, fetching, fetched, error, selectedOrgId }
}

const COORDINATOR_TITLE = 'AP Coordinator'
const PRINCIPAL_TITLE = 'Principal'
const TECHNOLOGY_COORD_TITLE = 'Technology Coordinator'
const STAFF_TITLE = 'Additional Authorized Staff'

const hasValues = obj => (obj ? Object.keys(obj).find(key => obj[key]) : null)

const ParticipationFormPanel = ({
  fetching,
  fetched,
  error,
  participationForm,
  schoolInformation = {},
  participationStatus = '',
  orderingHoldStatus = '',
}) => {
  const {
    address,
    name,
    principal,
    additionalStaff,
    coordinator = {},
    technologyCoordinator = {},
    studentPopulation = {},
    billing = { contactInformation: {} },
    purchaseOrderNumber = '',
    aiCode = '',
    schoolInfo = {},
  } = schoolInformation
  const {
    contactInformation: {
      lastName: billingLastName = '',
      firstName: billingFirstName = '',
      middleInitial: billingMiddleInitial = '',
      email: billingEmail = '',
      address: billingAddress = {},
    },
    centralBilling = false,
  } = billing
  const showPrincipalSign = hasValues(principal)
  const showCoordinatorSign = hasValues(coordinator.contactInformation)

  if (fetching) return <Loader />

  if (error)
    return (
      <Error title="Error Retrieving Data" message="An error occurred. Please try again later." />
    )

  if (fetched)
    return (
      <div style={{ marginBottom: 40 }}>
        <div className="row">
          <div className="col-xs-4">
            <h5 className="h3" style={{ marginBottom: 5 }}>
              CB School Code
            </h5>
            <div className="org-aicode">
              <strong>{aiCode}</strong>
            </div>
          </div>
          <div className="col-xs-4">
            <h5 className="h3" style={{ marginBottom: 5 }}>
              Participation Status
            </h5>
            <div className="org-participation-status">
              <strong>{participationStatus}</strong>
            </div>
          </div>
          {orderingHoldStatus && (
            <div className="col-xs-4">
              <h5 className="h3" style={{ marginBottom: 5 }}>
                Ordering Hold Status
              </h5>
              <div className="org-ordering-hold-status">
                <strong>{orderingHoldStatus}</strong>
              </div>
            </div>
          )}
        </div>
        <div className="row" style={{ marginTop: 40 }}>
          <div className="col-xs-4">
            <h5 className="h3" style={{ marginBottom: 5 }}>
              School Address
            </h5>
            <div>
              <strong>{name}</strong>
            </div>
            <StreetAddress {...address} styles={{ marginBottom: 0 }} />
            <EditButton
              modalType="TextModal"
              modalOptions={{
                title: 'Edit School Address',
                BodyComponent: ChangeAddressInstructions,
              }}
              screenReaderText="school address"
            />
          </div>
          {!centralBilling && (
            <>
              <div className="col-xs-4">
                <h5 className="h3" style={{ marginBottom: 5 }}>
                  Billing Contact
                </h5>
                <div className="full-name">
                  {billingFirstName} {billingMiddleInitial} {billingLastName}
                </div>
                <div className="email-address">{billingEmail}</div>
                <StreetAddress {...billingAddress} styles={{ marginBottom: 0 }} />
                <EditButton
                  modalType="UpdateOrgSettingsModal"
                  modalOptions={{ type: 'B', title: 'Billing Contact' }}
                  screenReaderText="billing contact"
                />
              </div>
              <div className="col-xs-4">
                <h5 className="h3" style={{ marginBottom: 5 }}>
                  Purchase Order Number
                </h5>
                {purchaseOrderNumber !== '' ? (
                  <div className="po-number">{purchaseOrderNumber}</div>
                ) : null}
                <EditButton
                  modalType="UpdateOrgSettingsModal"
                  modalOptions={{
                    type: 'PO',
                    title: `${purchaseOrderNumber !== '' ? 'Edit' : 'Add'} Purchase Order Number`,
                  }}
                  screenReaderText="purchase order number"
                  buttonText={purchaseOrderNumber !== '' ? 'edit' : 'add'}
                />
              </div>
            </>
          )}
        </div>

        <div className="row" style={{ marginTop: 40 }}>
          <div className="col-xs-4">
            <PersonInfo
              {...coordinator.contactInformation}
              profdev={coordinator.allowShareProfessionalDevelopment}
              homeschool={coordinator.allowShareHomeSchool}
              title={COORDINATOR_TITLE}
              countryCode={address.countryCode}
              type="C"
            />
          </div>
          <div className="col-xs-4">
            <PersonInfo
              {...principal}
              countryCode={address.countryCode}
              title={PRINCIPAL_TITLE}
              type="P"
            />
          </div>
          <div className="col-xs-4">
            <PersonInfo {...additionalStaff} title={STAFF_TITLE} type="A" />
          </div>
        </div>

        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-xs-4">
            {showCoordinatorSign ? (
              <SignatureStatus srTitle={COORDINATOR_TITLE} data={participationForm} />
            ) : null}
          </div>
          <div className="col-xs-4">
            {showPrincipalSign ? <SignatureStatus srTitle={PRINCIPAL_TITLE} /> : null}
          </div>
          <div className="col-xs-4">
            <PersonInfo
              {...technologyCoordinator}
              countryCode={address.countryCode}
              title={TECHNOLOGY_COORD_TITLE}
              type="T"
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-xs-4">
            <h5 className="h3" style={{ marginBottom: 5 }}>
              Student Population{' '}
              <div style={{ display: 'inline-block' }}>
                <Tooltip
                  title={STUDENT_POPULATION_TOOLTIP}
                  placement="top"
                  container="body"
                  label="More information: Student Population"
                >
                  <i
                    aria-hidden="true"
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
                    style={{ display: 'inline-block', fontSize: '8px' }}
                    data-alt={STUDENT_POPULATION_TOOLTIP}
                  />
                </Tooltip>
              </div>
            </h5>
            <div>
              9<sup>th</sup> Grade:{' '}
              {studentPopulation.grade9 !== undefined ? (
                numberFormat(studentPopulation.grade9)
              ) : (
                <em>Enter population</em>
              )}
            </div>
            <div>
              10<sup>th</sup> Grade:{' '}
              {studentPopulation.grade10 !== undefined ? (
                numberFormat(studentPopulation.grade10)
              ) : (
                <em>Enter population</em>
              )}
            </div>
            <div>
              11<sup>th</sup> Grade:{' '}
              {studentPopulation.grade11 !== undefined ? (
                numberFormat(studentPopulation.grade11)
              ) : (
                <em>Enter population</em>
              )}
            </div>
            <div>
              12<sup>th</sup> Grade:{' '}
              {studentPopulation.grade12 !== undefined ? (
                numberFormat(studentPopulation.grade12)
              ) : (
                <em>Enter population</em>
              )}
            </div>
            <EditButton
              modalType="UpdateOrgSettingsModal"
              modalOptions={{
                type: 'S',
                title: 'Edit Student Population',
                formattedDescription: (
                  <>
                    <p>
                      For schools located in the US, US Territories, and Canada, in order to be
                      considered for the AP School Honor Roll, provide your school&#39;s student
                      enrollment, per grade. This is the total number of students who are enrolled
                      at your institution full time, not only students taking AP. The data are used
                      in AP score reports, including the{' '}
                      <OpenNewWindow url="https://apcentral.collegeboard.org/exam-administration-ordering-scores/scores/available-reports/summary-reports">
                        AP Equity and Excellence
                      </OpenNewWindow>{' '}
                      report, and the{' '}
                      <OpenNewWindow url="https://apcentral.collegeboard.org/exam-administration-ordering-scores/scores/awards/school-districts-awards">
                        AP School Honor Roll Progress Report
                      </OpenNewWindow>
                      .
                    </p>
                    <p>
                      <strong>If you do not wish to be considered</strong> for the AP School Honor
                      Roll, leave your 12th grade enrollment field <strong>BLANK</strong>. If your
                      school does not have a 12th grade, enter zero. If you leave these fields
                      blank, note that your school&#39;s AP Equity and Excellence Report will also
                      be blank. These fields can continue to be updated after initial submission.
                    </p>
                  </>
                ),
              }}
              screenReaderText="student population"
            />
          </div>
          <div className="col-xs-4">
            <h5 className="h3" style={{ marginBottom: 5 }}>
              FRPL Students{' '}
              <div style={{ display: 'inline-block' }}>
                <Tooltip
                  title={FRPL_STUDENTS_TOOLTIP}
                  placement="top"
                  container="body"
                  label="More information: FRPL Students"
                >
                  <i
                    aria-hidden="true"
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-exclamation"
                    style={{ display: 'inline-block', fontSize: '8px' }}
                    data-alt={FRPL_STUDENTS_TOOLTIP}
                  />
                </Tooltip>
              </div>
            </h5>
            {schoolInfo.reducedFeeCEPInd === false &&
            schoolInfo.reducedFeeStudentPercentage !== undefined ? (
              <div>
                Percent of qualifying students:{' '}
                {numberFormat(schoolInfo.reducedFeeStudentPercentage)}
              </div>
            ) : null}
            {schoolInfo.reducedFeeCEPInd ? (
              <div>
                My school has adopted the Community Eligibility Provision (CEP) for the current
                academic year.
              </div>
            ) : null}
            <EditButton
              modalType="UpdateOrgSettingsModal"
              modalOptions={{
                type: 'FRPL',
                title: 'Edit FRPL Students',
                formattedDescription: (
                  <>
                    <p>
                      For schools located in the US, US Territories, and Canada, if you wish to be
                      considered for the AP Access Award, provide your school&#39;s overall
                      percentage of qualifying students for Free or Reduced-Price Lunch or indicate
                      that your school has adopted the Community Eligibility Provision (CEP) this
                      year. This field can continue to be updated after initial submission.
                    </p>
                    <p>
                      This additional AP Access Award is given to schools selected for the School
                      Honor Roll when the percentage of underrepresented and/or low-income students
                      who took at least one AP Exam before graduation is greater than or near the
                      percentage in the school, demonstrating a clear and effective commitment to AP
                      access.
                    </p>
                    <p>
                      If you <strong>do not wish to be considered</strong> for the AP Access Award,
                      leave the percent of qualifying students field <strong>BLANK</strong>. Schools
                      may still be considered for the AP School Honor Roll even if they do not wish
                      to be considered for the AP Access Award.
                    </p>
                  </>
                ),
              }}
              screenReaderText="FRPL Students"
            />
          </div>
        </div>
      </div>
    )

  return null
}

export default connect(mapStateToProps)(ParticipationFormPanel)
