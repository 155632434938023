import { Field } from 'redux-form'
import Label from './Label'
import Input from './Input'

import styles from '../../../assets/style/scss/forms.scss'

/**
 * The "controlling input" is the radio or checkbox that controls or is linked to the text box
 **/

const renderInputTextInputField = ({
  input,
  maxlength,
  disabled,
  isRequired,
  label,
  placeholder,
  formGroupStyles = {},
  textboxGroupStyles = {},
  inputStyles = {},
  withFlexbox = true,
  fullWidthError = false,
  labelBeforeTextInput = false,
  meta: { touched, error, warning },
  controlInput: {
    type: controlInputType,
    name: controlInputName,
    value: controlInputValue,
    label: controlInputLabel,
    normalize: controlInputNormalize,
    disabled: controlInputDisabled,
    ariaDescribedBy: controlAriaDescribedBy,
    srOnly: controlInputSrOnly,
    style: controlInputStyle,
    input: controlInputObj = {}, // should be an object like { input: { name: 'someName', value: false }}
  },
}) => (
  <div
    className={`form-group${
      withFlexbox ? ` ${styles['inline-radiogroup-flexwrap']}` : ` ${styles['inline-radiogroup']}`
    }${touched && error ? ' has-error' : ''}`}
    style={{ ...formGroupStyles, paddingTop: 0 }}
  >
    <div style={inputStyles}>
      <Input
        type={controlInputType}
        name={controlInputName}
        value={controlInputValue}
        label={controlInputLabel}
        normalize={controlInputNormalize}
        disabled={controlInputDisabled}
        ariaDescribedBy={controlAriaDescribedBy}
        srOnly={controlInputSrOnly}
        style={controlInputStyle}
        {...controlInputObj}
      />
    </div>
    <div style={withFlexbox ? { display: 'flex', ...textboxGroupStyles } : textboxGroupStyles}>
      {label && labelBeforeTextInput ? (
        <div
          className={`${styles['text-label-before']}${
            isRequired ? ` ${styles['is-required']}` : ''
          }`}
        >
          <Label name={input.name} isRequired={isRequired} label={label} />
        </div>
      ) : null}
      <div className="cb-input-state" style={withFlexbox ? { marginTop: -5 } : {}}>
        <input
          {...input}
          id={input.name}
          type="text"
          placeholder={placeholder ?? `Enter ${label}`}
          className={`form-control ${styles['inline-control']}`}
          maxLength={maxlength}
          aria-label={label}
          aria-required={isRequired}
          disabled={disabled}
          aria-disabled={disabled}
        />
        {touched && error ? (
          <i
            className="cb-glyph cb-x-mark"
            aria-hidden={true}
            style={
              withFlexbox ? { fontSize: '.8em', top: '10px' } : { fontSize: '.8em', top: '19px' }
            }
          />
        ) : null}
      </div>
      {label && !labelBeforeTextInput ? (
        <div
          className={styles['text-label-after']}
          style={withFlexbox ? { marginLeft: '8px' } : {}}
        >
          <Label name={input.name} isRequired={isRequired} label={label} />
        </div>
      ) : null}

      {!withFlexbox && !fullWidthError && touched && error ? (
        <div
          className={styles['cb-error-msg']}
          role="alert"
          aria-live="polite"
          style={withFlexbox ? { flexBasis: '100%' } : {}}
        >
          {error}
        </div>
      ) : null}
    </div>
    {(withFlexbox || fullWidthError) && touched && error ? (
      <div
        className="cb-error-msg"
        role="alert"
        aria-live="polite"
        style={{
          ...(withFlexbox ? { flexBasis: '100%' } : {}),
          ...(fullWidthError ? { display: 'block' } : {}),
        }}
      >
        {error}
      </div>
    ) : null}
  </div>
)

export default props => (
  <Field {...props} component={renderInputTextInputField} validate={props.validate || []} />
)
