import { getOrdersNonSecureAncillaryMaterialsData } from '../../../../selectors/order'
import NonSecureMaterialRow from './NonSecureMaterialRow'

const mapStateToProps = (state, ownProps) => {
  const { testWindow } = ownProps
  return {
    materials: getOrdersNonSecureAncillaryMaterialsData(state)[testWindow],
    testWindow,
  }
}

const NonSecureMaterialsTestWindowPanel = props => {
  const { materials, testWindow } = props
  const materialAncillaryCodes = Object.keys(materials)
  const numMaterials = materialAncillaryCodes.length

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          paddingBottom: '.1em',
          marginBottom: '1em',
          borderBottom: '1px solid #8e8e8e',
        }}
      >
        <span
          style={{
            margin: '0 3em 0 auto',
            fontWeight: 'bold',
            fontSize: '.9em',
            color: '#8e8e8e',
          }}
        >
          Qty. Ordered
        </span>
      </div>
      {materialAncillaryCodes.map((cd, i) => (
        <NonSecureMaterialRow
          key={cd}
          ancillaryCd={cd}
          testWindow={testWindow}
          isLast={i === numMaterials - 1}
        />
      ))}
    </>
  )
}

export default connect(mapStateToProps)(NonSecureMaterialsTestWindowPanel)
