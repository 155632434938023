import { formatDateTime } from '@myap/ui-library/esm/date'
import RosterDownload from './ExamRoomRosterDownload'
import { getRoom } from './utils'
import { openModal } from '../../actions/app'
import { plural } from '../../utils/common'

import styles from '../../assets/style/scss/exam-room.scss'

const mapStateToProps = (state, { roomId }) => {
  const { examRoomSchedule, examRoomStudents } = state
  const { [roomId]: students = [], saving } = examRoomStudents
  return { students, saving, ...getRoom(examRoomSchedule.data, roomId) }
}

export default connect(
  mapStateToProps,
  { openModal }
)(props => {
  const {
    courseName,
    examTime,
    openModal,
    proctorNames,
    totalCapacity,
    description,
    name,
    students,
    saving,
  } = props
  const [printTime, setPrintTime] = useState(new Date())
  const editRoomBtnRef = useRef(null)
  const editRoomProctorRef = useRef(null)
  const editRoomDescLinkRef = useRef(null)
  const rosterBtnsDisabled = students.length === 0 || saving

  function openEditModal(elem) {
    openModal('ExamRoomCreateModal', {
      ...props,
      modalCloseFocusElem: elem,
    })
  }

  return (
    <div className={styles['exam-room-details']}>
      <div className="row">
        <div className="col-sm-5 col-md-6">
          <h2>
            {courseName}: {name}
          </h2>
          <div className="print-only">
            Generated on {formatDateTime(printTime, 'MMMM d, yyyy h:mm a')}`
          </div>
        </div>
        <div className={`${styles['exam-room-action-bar']} col-sm-7 col-md-6`}>
          <RosterDownload
            {...props}
            disabled={rosterBtnsDisabled}
            style={{ display: 'inline-block' }}
          />
          <button
            type="button"
            className="btn btn-link cb-small-font-size"
            disabled={rosterBtnsDisabled}
            onClick={async () => {
              await setPrintTime(new Date())
              window.print()
            }}
          >
            <i className="cb-glyph cb-print" aria-hidden={true} /> Print Roster
          </button>
          <button
            type="button"
            className="btn btn-link cb-small-font-size"
            ref={editRoomBtnRef}
            aria-haspopup="dialog"
            onClick={() => openEditModal(editRoomBtnRef.current)}
          >
            <i className="cb-glyph cb-compose" aria-hidden={true} /> Edit Room
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-md-4">
          <div>
            <strong>Exam Date</strong>
          </div>
          <div>{formatDateTime(new Date(examTime), "EEEE, MMM do 'at' h:mm a")}</div>
        </div>
        <div className="col-xs-6 col-md-4">
          <div>
            <strong>Proctor(s)</strong>
          </div>
          <div>
            {proctorNames || (
              <>
                <a
                  href="#"
                  ref={editRoomProctorRef}
                  aria-haspopup="dialog"
                  onClick={() => openEditModal(editRoomProctorRef.current)}
                >
                  Assign a proctor
                </a>{' '}
                before test day!
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-md-4">
          <div>
            <strong>Room Capacity</strong>
          </div>
          <div>
            {totalCapacity} {plural(totalCapacity, 'student')}
          </div>
        </div>
        <div className="col-xs-6 col-md-4">
          <div>
            <strong>Room Description</strong>
          </div>
          <div>
            {description || (
              <a
                href="#"
                ref={editRoomDescLinkRef}
                aria-haspopup="dialog"
                onClick={() => openEditModal(editRoomDescLinkRef.current)}
              >
                Add a room description
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
