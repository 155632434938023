import { DATETIME_FORMATS, formatDate } from '@myap/ui-library/esm/date'
import { Link } from 'react-router-dom'
import { NAV_LINKS } from '../../constants/NavConstants'
import { Tooltip } from '../common'
import { getIncidentReportAvailability } from '../../selectors/incidentReports'

const linkStyles = { display: 'inline-block', whiteSpace: 'nowrap', margin: '15px auto 0 auto' }

const tooltipStyles = { display: 'inline-block', verticalAlign: 'middle' }

const iconStyles = {
  display: 'inline-block',
  color: 'rgb(0, 119, 200)',
  fontSize: '.7rem',
}

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { current, selectedIsCurrent },
    settingsDeadlines: { data: deadlines = {} },
  } = state
  const { incidentReportActiveDate } = deadlines?.[current] ?? {}
  const { visible, active } = getIncidentReportAvailability(state)

  return { incidentReportActiveDate, visible, active, selectedIsCurrent }
}

const IncidentReportSubmissionAndHistoryLinks = ({
  incidentReportActiveDate,
  visible,
  active,
  selectedIsCurrent,
}) => {
  const PAST_TEXT =
    'The online Incident Report (IR) form can no longer be submitted for this AP Exam administration.'
  const IR_ENABLED_TEXT = 'Click the link to access the online Incident Report (IR) form.'
  const IR_DISABLED_TEXT = `The online Incident Report (IR) form will be available when the AP Exam 
              administration begins (as of ${formatDate(
                incidentReportActiveDate,
                DATETIME_FORMATS.longMonthDay
              )}).`

  const tooltipText = !selectedIsCurrent ? PAST_TEXT : active ? IR_ENABLED_TEXT : IR_DISABLED_TEXT

  if (visible) {
    return (
      <div className="cb-small-font-size" style={linkStyles}>
        {selectedIsCurrent ? (
          active ? (
            <>
              <Link to={NAV_LINKS.createIncidentReport}>Incident Report Submission</Link> (
              <Link to={NAV_LINKS.incidentReportHistory}>View History</Link>)
            </>
          ) : (
            <span>Incident Report Submission (View History)</span>
          )
        ) : (
          <span>
            Incident Report Submission (
            <Link to={NAV_LINKS.incidentReportHistory}>View History</Link>)
          </span>
        )}{' '}
        <Tooltip
          title={tooltipText}
          placement="top"
          container="body"
          label="More information: Incident Report Submission"
          style={tooltipStyles}
        >
          <i
            className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
            aria-hidden="true"
            style={iconStyles}
            data-alt="More information: Incident Report Submission and View History"
          />
        </Tooltip>
      </div>
    )
  }
  return null
}

export default connect(mapStateToProps)(IncidentReportSubmissionAndHistoryLinks)
