/*
 * TeacherConstants
 * These are the variables that determine what our central data store changes in our state.
 * When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */

export const FETCH_TEACHERS_PENDING = 'FETCH_TEACHERS_PENDING'
export const FETCH_TEACHERS_FULFILLED = 'FETCH_TEACHERS_FULFILLED'
export const FETCH_TEACHERS_REJECTED = 'FETCH_TEACHERS_REJECTED'
export const FETCH_ORG_TEACHERS_PENDING = 'FETCH_ORG_TEACHERS_PENDING'
export const FETCH_ORG_TEACHERS_FULFILLED = 'FETCH_ORG_TEACHERS_FULFILLED'
export const FETCH_ORG_TEACHERS_REJECTED = 'FETCH_ORG_TEACHERS_REJECTED'

export const SEARCH_FOR_TEACHER_FORM_NAME = 'searchForTeacherForm'
export const SEARCH_FOR_TEACHER_FORM_FIELDS = {
  courseField: 'selectedCourseField',
  emailField: 'searchEmailField',
}
