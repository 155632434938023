import {
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  submit,
  formValueSelector,
  isPristine,
} from 'redux-form'
import CreateAndEditSectionForm from '../forms/CreateAndEditSectionForm'
import { Modal, Error } from '../../components/common'
import { CREATE_FORM_NAME, CREATE_FORM_FIELDS } from '../../constants/SectionConstants'
import { fetchOrgsByCourse } from '../../actions/course'
import { fetchTeachers } from '../../actions/teacher'

const selector = formValueSelector(CREATE_FORM_NAME)

const mapStateToProps = (state, { section, courseTestCd, orgIdUnknown = false, creating }) => {
  const formOrgId = selector(state, CREATE_FORM_FIELDS.orgId)
  const formTestCd = selector(state, CREATE_FORM_FIELDS.testCd)
  const testCd = parseInt(courseTestCd || section?.testCd || formTestCd, 10)
  const {
    teacher: { fetching: fetchingTeachers, error: teacherError, data: teacherData },
    orgsByCourse: { fetching: fetchingOrgsByCourse, error: orgsByCourseError },
    settingsEducationPeriod: { selectedEducationPeriod },
    user: {
      data: { selectedOrgId },
    },
  } = state
  const orgId = formOrgId || (orgIdUnknown ? 0 : selectedOrgId)
  return {
    testCd,
    orgId,
    selectedEducationPeriod,
    creating,
    hasTeacherData: teacherData?.[testCd]?.length > 0 ?? false,
    errors: teacherError || orgsByCourseError,
    fetchingTeachers,
    fetchingOrgsByCourse,
    invalid: isInvalid(CREATE_FORM_NAME)(state),
    submitting: isSubmitting(CREATE_FORM_NAME)(state),
    submitSucceeded: hasSubmitSucceeded(CREATE_FORM_NAME)(state),
    pristine: isPristine(CREATE_FORM_NAME)(state),
  }
}

const CreateAndEditSectionModal = ({
  section,
  testCd,
  orgId,
  selectedEducationPeriod,
  creating,
  hasTeacherData,
  isCourseCreateMode,
  fetchingOrgsByCourse,
  fetchingTeachers,
  errors,
  modalCloseFocusElem,
  invalid,
  submitting,
  submitSucceeded,
  pristine,
  fetchOrgsByCourse,
  fetchTeachers,
  submit,
}) => {
  useEffect(() => {
    async function getTeachers(orgId, selectedEducationPeriod, testCd) {
      await fetchTeachers(orgId, selectedEducationPeriod, testCd)
    }
    if (orgId !== 0 && testCd && !hasTeacherData) {
      getTeachers(orgId, selectedEducationPeriod, testCd)
    }
  }, [orgId, testCd, selectedEducationPeriod, hasTeacherData])

  useEffect(() => {
    if (testCd && !orgId && !fetchingOrgsByCourse) {
      fetchOrgsByCourse(testCd)
    }
  }, [testCd])

  const getFooterButtons = () => [
    { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false, isDisabled: submitting },
    {
      buttonLabel: `${section ? 'Save' : 'Create Section'}`,
      isPrimary: true,
      onClick: () => submit(CREATE_FORM_NAME),
      isDisabled: invalid || submitting || errors || pristine,
      busy: submitting,
    },
  ]

  return (
    <Modal
      shouldCloseModal={submitSucceeded}
      headerTitle={`${section ? 'Edit' : 'Add'} ${!testCd ? 'Course' : 'Section'}`}
      footerActions={getFooterButtons()}
      modalCloseFocusElem={modalCloseFocusElem}
    >
      {errors ? (
        <Error message={errors} />
      ) : (
        <CreateAndEditSectionForm
          testCd={testCd}
          orgId={orgId}
          section={section}
          creating={creating}
          isCourseCreateMode={isCourseCreateMode}
        />
      )}
    </Modal>
  )
}

export default connect(mapStateToProps, { submit, fetchOrgsByCourse, fetchTeachers })(
  CreateAndEditSectionModal
)
