import { useHistory } from 'react-router-dom'
import { BasicSelect } from '../../common'
import { sortColumnByKey } from '../../../utils/sort'

const mapStateToProps = (state, ownProps) => {
  const { section: selectedSection } = ownProps
  const { sections } = state.courses.entities

  return {
    selectedSection,
    sections: sortColumnByKey(Object.values(sections), ['courseName', 'sectionName'], 'asc'),
  }
}

const getSectionOptions = sections =>
  sections.map(s => ({ label: `${s.courseName} - ${s.sectionName}`, value: s.sectionId }))

const CourseSectionSelector = ({ sections, selectedSection: { sectionId } }) => {
  const history = useHistory()
  const numberOfSections = sections.length

  const handleChange = e => {
    const value = parseInt(e.target.value, 10)
    const course = sections.find(s => s.sectionId === value).testCd
    history.replace(`/course/${course}/section/${value}`)
  }

  if (numberOfSections === 1)
    return (
      <div style={{ float: 'left', marginTop: '8px', fontWeight: 'bold' }}>
        {sections[0].courseName} - {sections[0].sectionName}
      </div>
    )

  if (numberOfSections > 1)
    return (
      <div style={{ float: 'left', width: '80%' }}>
        <BasicSelect
          showEmptyOption={false}
          srOnlyLabel={true}
          label="Select Section"
          input={{
            name: 'selectSection',
            value: sectionId,
            onChange: handleChange,
          }}
          options={getSectionOptions(sections)}
        />
      </div>
    )

  return null
}

export default connect(mapStateToProps)(CourseSectionSelector)
