import { STUDENT_EXAM_URLS } from '@myap/ui-library/esm/profile/content'
import { openModal } from '../../../actions/app'

const TeacherPractice = ({ openModal, testCode, courseName }) => {
  const triggerRef = useRef(null)
  return (
    <div>
      <a
        href={STUDENT_EXAM_URLS[testCode]}
        target="_blank"
        title={`${courseName} Scoring Materials (Opens in new window)`}
        className="cb-small-font-size"
      >
        Digital Practice Scoring Materials
      </a>

      <button
        type="button"
        className="btn btn-sm btn-primary"
        ref={triggerRef}
        onClick={() =>
          openModal('ProvisionPracticeModal', {
            courseName,
            testCode,
            modalCloseFocusElem: triggerRef.current,
            defaultStep: 2,
          })
        }
      >
        Take Digital Practice
      </button>
    </div>
  )
}

export default connect(
  state => ({ ...state.examReadiness.provision }),
  { openModal }
)(TeacherPractice)
