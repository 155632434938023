import { setNavLink, unsetNavLink } from '../../actions/nav'
import { fetchExamOrderHistory } from '../../actions/orders'
import { addFetchAction, removeFetchAction } from '../../actions/app'
import { Loader, Error } from '../../components/common'
import { NAV_LINKS } from '../../constants/NavConstants'
import OrderHistory from '../../components/orders/coordinator/OrderHistory'

const mapStateToProps = state => state.ordersHistory
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { setNavLink, unsetNavLink, fetchExamOrderHistory, addFetchAction, removeFetchAction },
    dispatch
  )

export class OrderHistoryPage extends Component {
  componentDidMount() {
    const { fetching, setNavLink, addFetchAction, fetchExamOrderHistory } = this.props

    addFetchAction({
      name: 'fetchExamOrderHistory',
      action: (orgId, educationPeriodCd) => fetchExamOrderHistory(orgId, educationPeriodCd),
    })
    setNavLink(NAV_LINKS.orders, 'Back to order summary')
  }

  componentWillUnmount() {
    const { unsetNavLink, removeFetchAction } = this.props

    removeFetchAction('fetchExamOrderHistory')
    unsetNavLink()
  }

  render() {
    const { error, fetching } = this.props

    if (error)
      return (
        <div className="container">
          <Error title="Error Retrieving Data" message={error} />
        </div>
      )

    if (fetching)
      return (
        <div className="container">
          <Loader />
        </div>
      )

    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <OrderHistory />
      </CSSTransition>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryPage)
