import {
  APPROVAL_STATUS,
  APPROVAL_STATUS_LABEL
} from '../../constants/OrderConstants'
import { BootstrapLabel } from '.'
import statusLabelStyles from '../../assets/style/scss/labels.scss'

export const DotSizeStatusLabel = ({ status, style = {}}) => (
  <div className={`${statusLabelStyles[`status-dot-${APPROVAL_STATUS_LABEL[status]}`]}`} style={style}>
    <span className={`cb-glyph cb-disc-alt ${statusLabelStyles.dot}`} />{APPROVAL_STATUS[status]}
  </div>
)

export const FullSizeStatusLabel = ({ status, alignment }) => (
  <BootstrapLabel
    type={`status-${APPROVAL_STATUS_LABEL[status]}`}
    alignment={alignment}
  >
    {APPROVAL_STATUS[status]}
  </BootstrapLabel>
)
