import { convertTitleCase } from '../../utils/common'
import * as c from '../../constants/PackingListConstants'

export const ShipmentWindowTitle = ({ pkg: { testDayType, weekNumber } }) => {
  let title = `${convertTitleCase(testDayType)} Testing`
  if (weekNumber) {
    title += weekNumber === 1 ? `, ${c.TITLE_WEEK_1}` : `, ${c.TITLE_WEEK_2}`
  }
  return title
}

export default ({ pkg }) => {
  const { examType } = pkg
  let exam = ''
  switch (examType) {
    case c.STANDARD_PACKAGE_ENUM:
      exam = c.STANDARD_PACKAGE
      break
    case c.BRAILLE_LARGE_FONT_PACKAGE_ENUM:
      exam = c.BRAILLE_LARGE_FONT_PACKAGE
      break
    case c.STUDIO_ART_PACKAGE_ENUM:
      exam = c.STUDIO_ART_PACKAGE
      break
    case c.NON_STOCK_PACKAGE_ENUM:
      exam = c.NON_STOCK_PACKAGE
      break
    default:
      break
  }
  const title = (
    <>
      <ShipmentWindowTitle pkg={pkg} /> - {exam}
    </>
  )
  return (
    <h3>
      Packing Shipment:
      <br />
      {title === ' - ' ? '' : title}
    </h3>
  )
}
