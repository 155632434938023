import { Fragment } from 'react'
import CollapsableSections from './CollapsableSections'
import { Accordions } from '../common'
import { convertTitleCase } from '../../utils/common'
import * as c from '../../constants/ShipmentConstants'

import {
  TYPE_LABELS,
  CONTENT,
  SCORE_CONTENT,
  TYPE,
  AP_ID_LABELS,
  SECTION_EXAM_WITH_SPECIAL_MATERIALS_NONSTOCK,
} from '../../constants/ShipmentConstants'

const mapStateToProps = state => {
  const {
    shipments: { suborders },
  } = state

  return {
    suborders,
  }
}

const isNotTestDayOrder = suborder =>
  !suborder.testDayType || TYPE_LABELS[suborder.testDayType] === TYPE_LABELS.ALL_ADMIN

const isNonStock = suborder => suborder.contents === SECTION_EXAM_WITH_SPECIAL_MATERIALS_NONSTOCK

const subordersArray = (suborder, i) => {
  // non stock
  if (isNonStock(suborder)) {
    return (
      <CollapsableSections
        key={suborder.id}
        suborder={suborder}
        sectionHeader={`${CONTENT[suborder.contents]}: ${suborder.lastName}, ${
          suborder.firstName
        } - ${suborder.courseName}`}
        sectionBody={true}
      />
    )
  }

  /**
   * Handle AP Id label and Score content related suborders.
   * These order do NOT fall under the any test day umbrella, and,
   * these order do NOT have any line items.
   */
  if (isNotTestDayOrder(suborder)) {
    return (
      <CollapsableSections
        key={suborder.id}
        suborder={suborder}
        sectionHeader={SCORE_CONTENT[suborder.contents] || `${AP_ID_LABELS} - ${i + 1}`}
        sectionBody={false}
      />
    )
  }

  return (
    <CollapsableSections
      key={suborder.id}
      suborder={suborder}
      sectionHeader={`${CONTENT[suborder.contents]} - ${TYPE[suborder.type]}`}
      sectionBody={true}
    />
  )
}

const Suborders = ({ suborders }) => {
  const allLabels = {
    [c.TYPE_AP_LABELS]: { displayName: c.TYPE_LABELS[c.TYPE_AP_LABELS] },
    [c.TYPE_ALL_ADMIN]: { displayName: c.TYPE_LABELS[c.TYPE_ALL_ADMIN] },
  }
  Object.keys(suborders).forEach(item => {
    Object.assign(allLabels, {
      [item]: { displayName: `${convertTitleCase(item)} Testing` },
    })
  })

  // Create an array of suborders ordered by SHIPMENT_ORDER
  const shipments = []
  c.SHIPMENT_ORDER.forEach(testAdmin => {
    if (suborders?.[testAdmin]) {
      shipments.push([...suborders[testAdmin]])
    }
  })

  const panels = ([testDay, subordersByTestDay]) => ({
    PanelBody: () =>
      subordersByTestDay.map((suborder, i) => (
        <Fragment key={suborder.id}>{subordersArray(suborder, i)}</Fragment>
      )),
    id: `${testDay}_ID`,
    title: allLabels[testDay]?.displayName,
    panelId: `PANEL_${testDay}_ID`,
  })

  return (
    <Accordions
      id="shipmentAccordion"
      panels={shipments.map(shipment => panels([shipment[0].testDayType, shipment]))}
    />
  )
}

export default connect(mapStateToProps)(Suborders)
