import { TeacherRegistration } from '@myap/ui-library/esm/components'
import { AppsyncClient } from '@myap/ui-library/esm/appsync'
import { profile as MyApProfile } from '@myap/ui-library/esm/profile/access'
import { ChangesSavedTextNotification } from '../common'

const mapStateToProps = state => {
  const {
    user: {
      data: { personId },
    },
    settingsEducationPeriod: {
      selectedEducationPeriod,
      availableEducationPeriods,
      selectedIsCurrent,
    },
  } = state

  return {
    personId,
    educationPeriodData: {
      availableEducationPeriods,
      selectedEducationPeriod,
      selectedIsCurrent,
    },
  }
}

const Profile = ({ educationPeriodData, personId }) => {
  const [saved, setSaved] = useState(false)
  const { myapUrl } = MyApProfile()
  const client = AppsyncClient(myapUrl)

  return (
    <>
      <ChangesSavedTextNotification inProp={saved} durationOut={500}>
        <p className="success-text">
          <i
            className="cb-glyph cb-check-circle"
            aria-hidden={true}
            style={{ verticalAlign: 'text-bottom', marginRight: 5 }}
          />
          <strong>Your updates have been saved.</strong>
        </p>
      </ChangesSavedTextNotification>
      <TeacherRegistration
        client={client}
        educationPeriodData={educationPeriodData}
        personId={personId}
        onSaveSuccess={() => {
          window.scrollTo(0, 0)
          setSaved(true)
          setTimeout(() => setSaved(false), 1000)
        }}
      />
    </>
  )
}

export default connect(mapStateToProps)(Profile)
