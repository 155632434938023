import memoize from 'memoizee'
import { GRADE_OPTIONS } from '@myap/metadata'
import {
  formatDateTime,
  formatDate,
  parseDateTime,
  DATETIME_FORMATS,
} from '@myap/ui-library/esm/date'
import { DownloadToCSV, Tooltip } from '../../common'
import { COURSE_SCHEDULE_TYPES } from '../../../constants/SectionConstants'
import {
  STUDENT_ENROLLMENT_STATUS,
  STUDENT_FEE_STATUS,
  SEE_SUBMISSION_DEADLINE_MSG,
} from '../../../constants/StudentConstants'
import {
  ORDER_STATUS_FILTER_VALUES,
  LATE_ORDERING_FEE,
  LATE_TESTING_FEE,
  FEES_FILTER_VALUES,
  UNUSED_CANCELLATION_FEE,
  TEST_LOCATION_FILTER_VALUES,
} from '../../../constants/FilterConstants'
import { isAdministeringDigitalExams } from '../../../selectors/settings'
import { buildSSDMaterials, buildSpecialDigitalFormats } from '../../../selectors/section'
import {
  formatExamWindow,
  getAllExamWindows,
  getExamWindowRefData,
} from '../../../selectors/examWindows'
import {
  fetchAllowedSSDMaterials,
  fetchSpecialDigitalFormatsMetadata,
} from '../../../actions/settingsGlobal'
import { isEmpty } from '../../../utils/common'
import { sortColumnByKey } from '../../../utils/sort'
import { DIGITAL_EXAM, TEST_LOCATION_HOME } from '../../../constants/SettingsConstants'
import { getStudentsFilterDataTag } from '../../../selectors/filters/studentFilters'
import { getFilterTags } from '../../../selectors/filters/utils'

const getExamPriceLabel = (obj, key) => (isEmpty(obj) ? '' : obj[key] ? 'Yes' : 'No')

const transformData = memoize(
  (
    studentData,
    ssdMetadata,
    examWindowData,
    ssdCdDescriptions,
    hasDigital,
    administeringDigitalExams
  ) => {
    const { exams, sectionMap, teacherMap } = studentData

    const processedRows = exams.map(e => {
      const {
        studentId,
        sectionId,
        orderStatus,
        ssdMaterials,
        specialDigitalFormats,
        testWindow,
        examIntent,
        examFormat,
        examPrice = {},
        firstName,
        lastName,
        grade,
        orgStudentId,
        emailAddress,
        feesStatus,
        aiCode,
        ssdId,
        name: courseName,
        sectionTeachers = [],
        enrolledDate,
        orderUpdateDate,
        approvedSSDAccommodations = [],
        testCd,
        testLocation,
      } = e
      const { name: sectionName, type } = sectionMap[sectionId] || {}
      const teachers = sectionTeachers.map(id => teacherMap[id]?.name).join('; ')
      const {
        examDateTime = '',
        noEndOfCourseExam = false,
        displayName = '',
      } = examWindowData[testCd]?.[testWindow] || {}
      const isDigital = examFormat === DIGITAL_EXAM

      return {
        firstName,
        lastName,
        studentId,
        aiCode: aiCode || '',
        orgStudentId: orgStudentId || '',
        grade: grade ? GRADE_OPTIONS.find(({ value }) => value === grade)?.label : '',
        email: emailAddress,
        feeStatus: STUDENT_FEE_STATUS[feesStatus],
        courseName: courseName || '',
        sectionName: sectionName || '',
        sectionType: type ? COURSE_SCHEDULE_TYPES[type].label : '',
        teachers: teachers.length ? teachers : '',
        examIntent: STUDENT_ENROLLMENT_STATUS[examIntent] || '',
        examDate: noEndOfCourseExam
          ? SEE_SUBMISSION_DEADLINE_MSG
          : examDateTime
          ? `${formatExamWindow(
              examDateTime,
              DATETIME_FORMATS.monthDayHour,
              examFormat === DIGITAL_EXAM && administeringDigitalExams,
              true
            )}`
          : '',
        testWindow: noEndOfCourseExam ? '' : displayName,
        orderStatus: orderStatus ? ORDER_STATUS_FILTER_VALUES[orderStatus] : '',
        ssdMaterials:
          ssdMaterials && !isDigital ? buildSSDMaterials(ssdMetadata, ssdMaterials) : '',
        ...(hasDigital
          ? {
              specialDigitalFormats:
                specialDigitalFormats && isDigital
                  ? buildSpecialDigitalFormats({
                      descriptions: ssdCdDescriptions,
                      specialDigitalFormats,
                    })
                  : '',
            }
          : {}),
        ssdId: ssdId || '',
        enrolledDate: formatDate(enrolledDate, DATETIME_FORMATS.shortMonthDayYear),
        orderUpdateDate: formatDate(orderUpdateDate, DATETIME_FORMATS.shortMonthDayYear),
        approvedSSDAccommodations: approvedSSDAccommodations.join(', '),
        [LATE_ORDERING_FEE]: getExamPriceLabel(examPrice, LATE_ORDERING_FEE),
        [LATE_TESTING_FEE]: getExamPriceLabel(examPrice, LATE_TESTING_FEE),
        [UNUSED_CANCELLATION_FEE]: getExamPriceLabel(examPrice, UNUSED_CANCELLATION_FEE),
        ...(hasDigital && administeringDigitalExams
          ? { testLocation: TEST_LOCATION_FILTER_VALUES[testLocation] }
          : {}),
      }
    })
    return sortColumnByKey(processedRows, ['lastName', 'firstName'], ['asc', 'asc'])
  }
)

const ROW_HEADERS = (hasDigital, administeringDigitalExams) => ({
  firstName: 'Student First Name',
  lastName: 'Student Last Name',
  aiCode: 'School Code',
  grade: 'Grade',
  email: 'Email Address',
  studentId: 'AP ID',
  orgStudentId: 'Student ID',
  courseName: 'Course Enrolled In',
  sectionName: 'Class Section Name',
  sectionType: 'Class Section Type',
  teachers: 'Teacher Name(s)',
  feeStatus: 'Fee Status',
  examIntent: 'Order Exam?',
  testWindow: 'Testing Window',
  [LATE_ORDERING_FEE]: FEES_FILTER_VALUES[LATE_ORDERING_FEE],
  [UNUSED_CANCELLATION_FEE]: FEES_FILTER_VALUES[UNUSED_CANCELLATION_FEE],
  [LATE_TESTING_FEE]: FEES_FILTER_VALUES[LATE_TESTING_FEE],
  examDate: 'Exam Date',
  orderStatus: 'Order Status',
  ssdMaterials: 'SSD Materials',
  ...(hasDigital ? { specialDigitalFormats: 'SSD Digital Formats' } : {}),
  ssdId: 'SSD ID',
  enrolledDate: 'Enrolled Date',
  orderUpdateDate: 'Latest Order Submission Date',
  approvedSSDAccommodations: 'Approved SSD Accommodations',
  ...(hasDigital && administeringDigitalExams ? { testLocation: 'Testing Location' } : {}),
})

const STUDENT_KEYS = (hasDigital, administeringDigitalExams) =>
  Object.keys(ROW_HEADERS(hasDigital, administeringDigitalExams))

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedRole, roles },
    },
    studentsByOrg: { courseMap, teacherMap, sectionMap, accommodationCategoryMap },
    settingsSSDMaterials: { metaData, fetching, fetched },
    settingsSpecialDigitalFormats: {
      ssdCdDescriptions,
      fetching: fetchingSpecialDigital,
      fetched: fetchedSpecialDigital,
    },
  } = state
  const examWindowData = getAllExamWindows(state)
  const examWindowRefData = getExamWindowRefData(state)
  const { orgName } = roles[selectedRole]

  return {
    shouldFetchAllowedMaterials: !fetching && !fetched,
    shouldFetchSpecialDigitalFormats: !fetchingSpecialDigital && !fetchedSpecialDigital,
    ssdMetadata: metaData,
    ssdCdDescriptions,
    orgName,
    examWindowRefData,
    examWindowData,
    courseMap,
    teacherMap,
    sectionMap,
    accommodationCategories: accommodationCategoryMap,
    sections: state.courses.entities.sections,
    administeringDigitalExams: isAdministeringDigitalExams(state),
  }
}

const DownloadStudentRosterButton = props => {
  const {
    shouldFetchAllowedMaterials,
    shouldFetchSpecialDigitalFormats,
    ssdMetadata,
    ssdCdDescriptions,
    orgName,
    examWindowData,
    examWindowRefData,
    fetchAllowedSSDMaterials,
    fetchSpecialDigitalFormatsMetadata,
    activeFilters,
    visibleExams,
    sectionMap,
    teacherMap,
    courseMap,
    accommodationCategories,
    sections,
    administeringDigitalExams,
  } = props
  const studentData = { exams: visibleExams, sectionMap, teacherMap }
  const filterData = {
    courses: courseMap,
    teachers: teacherMap,
    sections,
    examWindowRefData,
    accommodationCategories,
  }
  const filterTags = getFilterTags(
    activeFilters,
    (filter, value) => getStudentsFilterDataTag(filterData, filter, value),
    true
  ).map(tag => tag.label)
  const hasFilters = filterTags.length
  const hasDigital = visibleExams.some(({ examFormat }) => examFormat === DIGITAL_EXAM)

  const [mainHeader, filterHeader] = STUDENT_KEYS(hasDigital, administeringDigitalExams).reduce(
    (acc, key, i) => {
      const [main, flt] = acc
      return [
        [...main, { label: i === 0 ? `Student roster for ${orgName}` : '', key }],
        {
          ...flt,
          [key]: i === 0 && hasFilters ? `Filtered by: ${filterTags.join(' | ')}` : '',
        },
      ]
    },
    [[], {}]
  )

  useEffect(() => {
    if (shouldFetchAllowedMaterials) fetchAllowedSSDMaterials()
    if (hasDigital && shouldFetchSpecialDigitalFormats) fetchSpecialDigitalFormatsMetadata()
  }, [])

  if (ssdMetadata && (!hasDigital || ssdCdDescriptions)) {
    return (
      <>
        <DownloadToCSV
          filename={`Student Roster - ${orgName} `}
          header={mainHeader}
          title={`${hasFilters ? 'Filtered ' : ''}Student Roster`}
          timestampHeaderKey="firstName"
          transformData={() => {
            const dataArr = [
              ROW_HEADERS(hasDigital, administeringDigitalExams),
              ...transformData(
                studentData,
                ssdMetadata,
                examWindowData,
                ssdCdDescriptions,
                hasDigital,
                administeringDigitalExams
              ),
            ]
            // Add filterHeader to beginning of dataArr
            if (hasFilters) dataArr.unshift(filterHeader, {})
            return dataArr
          }}
          style={{ display: 'inline-block' }}
        />
        {Object.keys(activeFilters).length ? (
          <Tooltip
            title="Filters are currently applied to the student roster. Clear all filters to download the complete roster."
            placement="top"
            container="body"
            label="More information: Roster download filtered"
            style={{
              display: 'inline-block',
              verticalAlign: 'top',
              paddingLeft: '5px',
              fontSize: '.7em',
            }}
          >
            <i className="cb-glyph cb-glyph-circular cb-exclamation" />
          </Tooltip>
        ) : null}
      </>
    )
  }

  return null
}

export default connect(mapStateToProps, {
  fetchAllowedSSDMaterials,
  fetchSpecialDigitalFormatsMetadata,
})(DownloadStudentRosterButton)
