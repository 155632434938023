import { FadeInOutTransition } from '.'

export const ChangesSavedLabelNotification = ({
  in: inProp,
  durationIn=150,
  durationOut=1000,
  message="Changes Saved",
  overrideDefaultStyle={},
  overrideTransitionStyles={} }) =>
  <FadeInOutTransition
    inProp={inProp}
    durationIn={durationIn}
    durationOut={durationOut}
    message={message}
    overrideDefaultStyle={overrideDefaultStyle}
    overrideTransitionStyles={overrideTransitionStyles} />

/*export const ChangesSavedTextNotification => ({
  in: inProp,
  durationIn=150,
  durationOut=1000,
  overrideDefaultStyle={},
  overrideTransitionStyles={},
  children }) =>
<FadeInOutTransition
  inProp={inProp}
  durationIn={durationIn}
  durationOut={durationOut}
  overrideDefaultStyle={overrideDefaultStyle}
  overrideTransitionStyles={overrideTransitionStyles}
  children={children} />*/


export class ChangesSavedTextNotification extends Component {

    constructor(props) {
      super()
      this.turnOffActiveFlag = this.turnOffActiveFlag.bind(this)
      this.state = { in: props.inProp, active: false }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if(nextProps.inProp) {
        return { active: true }
      }
      return null
    }

    shouldComponentUpdate(nextProps, nextState) {
      return this.props.inProp !== nextProps.inProp || nextState.in !== this.state.in || nextState.active !== this.state.active
    }

    componentDidUpdate(prevProps) {
      const { durationActive=1000 } = this.props

      if(this.state.active)
        setTimeout(() => this.setState({ in: true }), 500)
      if(this.state.in)
        setTimeout(() => this.setState({ in: false }), durationActive)
    }

    turnOffActiveFlag() {
      const { durationOut=50 } = this.props
      setTimeout(() => this.setState({ active: false }), durationOut)
    }

    render() {
      return this.state.active ? <FadeInOutTransition inProp={this.state.in} onExited={this.turnOffActiveFlag} {...this.props} /> : null
    }
}
