import * as c from '../constants/PricingConstants'
import axios from '../utils/axios'

export const getProposedExamPrices = exam => async (dispatch, getState) => {
  const {
    settingsEducationPeriod: { selectedEducationPeriod },
    user: {
      data: { selectedOrgId },
    },
    studentsByOrg: { sectionMap, studentMap },
  } = getState()

  dispatch({ type: c.FETCH_PROPOSED_EXAM_PRICE_PENDING })

  try {
    const { data } = await axios.post(
      `${Config.API_URL}/orders/organizations/${selectedOrgId}/education-periods/${selectedEducationPeriod}/exam-price`,
      {
        enrollmentId: exam.enrollmentId,
        examIntent: exam.examIntent,
        feeStatus: studentMap[exam.studentId].feesStatus,
        lateReasonCode: exam.lateReasonCode,
        sectionType: sectionMap[exam.sectionId].type,
        testCd: exam.testCd,
        testDayType: exam.testWindow,
        testLocation: exam.testLocation || 'DOMESTIC',
        enrollReason: exam.enrollReason,
      }
    )
    dispatch({ type: c.FETCH_PROPOSED_EXAM_PRICE_FULFILLED, payload: { examPrice: data } })
  } catch (err) {
    dispatch({
      type: c.FETCH_PROPOSED_EXAM_PRICE_REJECTED,
      payload: 'Error: An error occurred retrieving updated pricing information for this exam.',
    })
  }
}

export const getAggregatedFees = (
  exams,
  educationPeriodCd,
  examWindow,
  lateReasonCd
) => async dispatch => {
  dispatch({ type: c.FETCH_AGGREGATED_FEES_PENDING })

  try {
    const { data } = await axios.post(
      `${Config.API_URL}/paapro/v1/coordinator/enrollments/exams:aggregate-late-fee`,
      {
        enrollmentIds: Array.from(new Set(exams.map(e => e.enrollmentId))),
        educationPeriodCd,
        examWindow,
        lateReasonCd,
      }
    )
    dispatch({ type: c.FETCH_AGGREGATED_FEES_FULFILLED, payload: data })
  } catch (err) {
    dispatch({
      type: c.FETCH_AGGREGATED_FEES_REJECTED,
      payload: 'Error: An error occurrred retrieving pricing information.',
    })
  }
}
