import { SortableTable } from '../../common'
import { isEmpty } from '../../../utils/common'

const getTotalRow = aggregateRow => ({
  ...aggregateRow,
  defaultToTop: true,
  course: 'All Courses',
  isAggregate: true,
})

const Table = ({ data, id, summary, rowBuilder, columns, caption, aggregateRow }) => (
  <SortableTable
    tableAttributes={{
      id,
      summary,
      className: 'table cb-no-table-border',
    }}
    data={!isEmpty(aggregateRow) ? [getTotalRow(aggregateRow), ...data] : data}
    rowBuilder={rowBuilder}
    columns={columns}
    tableCaption={{ caption: caption, srOnly: true }}
    tbody={true}
  />
)

export default ({ header, headerStyle, error, emptyText, tableAttrs }) => {
  let headerOutput = header ? (
    <h5 className="h4" style={headerStyle}>
      {header}
    </h5>
  ) : null
  let errorOutput = error ? (
    <p className="cb-error-msg" aria-live="polite">
      Error: {error}
    </p>
  ) : null
  let tableOutput = !isEmpty(tableAttrs.data) ? <Table {...tableAttrs} /> : <p>{emptyText}</p>
  // console.info(tableAttrs)
  return (
    <>
      {headerOutput}
      {errorOutput}
      {tableOutput}
    </>
  )
}
