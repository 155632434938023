export const FETCH_EXAM_ROOM_SCHEDULE_PENDING = 'FETCH_EXAM_ROOM_SCHEDULE_PENDING'
export const FETCH_EXAM_ROOM_SCHEDULE_FULFILLED = 'FETCH_EXAM_ROOM_SCHEDULE_FULFILLED'
export const FETCH_EXAM_ROOM_SCHEDULE_REJECTED = 'FETCH_EXAM_ROOM_SCHEDULE_REJECTED'

export const ADD_EXAM_ROOM_PENDING = 'ADD_EXAM_ROOM_PENDING'
export const ADD_EXAM_ROOM_FULFILLED = 'ADD_EXAM_ROOM_FULFILLED'
export const ADD_EXAM_ROOM_REJECTED = 'ADD_EXAM_ROOM_REJECTED'

export const EDIT_EXAM_ROOM_PENDING = 'EDIT_EXAM_ROOM_PENDING'
export const EDIT_EXAM_ROOM_FULFILLED = 'EDIT_EXAM_ROOM_FULFILLED'
export const EDIT_EXAM_ROOM_REJECTED = 'EDIT_EXAM_ROOM_REJECTED'

export const DELETE_EXAM_ROOM_PENDING = 'DELETE_EXAM_ROOM_PENDING'
export const DELETE_EXAM_ROOM_FULFILLED = 'DELETE_EXAM_ROOM_FULFILLED'
export const DELETE_EXAM_ROOM_REJECTED = 'DELETE_EXAM_ROOM_REJECTED'

export const EXAM_ROOM_FORM = 'examRoomForm'
export const EXAM_ROOM_FIELDS = {
  id: { field: 'id' },
  roomName: { field: 'name', required: true },
  capacity: { field: 'totalCapacity', required: true },
  proctor: { field: 'proctorNames' },
  description: { field: 'description' },
}

export const EXAM_ROOM_FILTERS = [
  { label: 'All Unassigned Students', value: '0' },
  { label: 'Unassigned, w/o SSD ID', value: '1' },
  { label: 'Unassigned, with SSD ID', value: '2' },
  { label: 'All Students', value: '3' },
]

export const FETCH_ASSIGNABLE_STUDENTS_PENDING = 'FETCH_ASSIGNABLE_STUDENTS_PENDING'
export const FETCH_ASSIGNABLE_STUDENTS_FULFILLED = 'FETCH_ASSIGNABLE_STUDENTS_FULFILLED'
export const FETCH_ASSIGNABLE_STUDENTS_REJECTED = 'FETCH_ASSIGNABLE_STUDENTS_REJECTED'

export const ASSIGN_STUDENTS_PENDING = 'ASSIGN_STUDENTS_PENDING'
export const ASSIGN_STUDENTS_FULFILLED = 'ASSIGN_STUDENTS_FULFILLED'
export const ASSIGN_STUDENTS_REJECTED = 'ASSIGN_STUDENTS_REJECTED'

export const FETCH_EXAM_ROOM_STUDENTS_PENDING = 'FETCH_EXAM_ROOM_STUDENTS_PENDING'
export const FETCH_EXAM_ROOM_STUDENTS_FULFILLED = 'FETCH_EXAM_ROOM_STUDENTS_FULFILLED'
export const FETCH_EXAM_ROOM_STUDENTS_REJECTED = 'FETCH_EXAM_ROOM_STUDENTS_REJECTED'

export const UNASSIGN_STUDENTS_PENDING = 'UNASSIGN_STUDENTS_PENDING'
export const UNASSIGN_STUDENTS_FULFILLED = 'UNASSIGN_STUDENTS_FULFILLED'
export const UNASSIGN_STUDENTS_REJECTED = 'UNASSIGN_STUDENTS_REJECTED'

export const SELECT_ASSIGNED_STUDENT = 'SELECT_ASSIGNED_STUDENT'
export const UNSELECT_ASSIGNED_STUDENT = 'UNSELECT_ASSIGNED_STUDENT'
export const RESET_ASSIGNED_STUDENTS = 'RESET_ASSIGNED_STUDENTS'
