import { BrowseItem } from '..'
import { Loader, Error } from '../../../common'

import styles from '../../../../assets/style/scss/csr.scss'


export default ({ loading, error, data = [], onCourseSelection }) => {
  if (loading)
    return <Loader />

  if (error)
    return <Error title="Error Retrieving Data" message={error} style={{ padding: 15 }} />

  return (
    <div className={styles['tool-body-inner']}>
      { data.length ? (
        <>
          <p className={styles['tool-body-divider']}>Select a course to view students.</p>
          {data.map((course, i) => (
            <BrowseItem
              key={i}
              title={course.name}
              action={() => onCourseSelection(course)}
            />
          ))}
        </>
      ) : <p className={styles['tool-body-item']} style={{ marginTop: 10 }}><em>No students available</em></p> }
    </div>
  )
}
