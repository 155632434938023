import { reduxForm, change } from 'redux-form'
import { Autosuggest, Dropdown } from '../common'
import { SelectAcademicYear } from '.'
import { getOrgsFromOrgSearch } from '../../actions/typeaheads'
import { getOrgDetails } from '../../actions/user'
import { clearSelectedEducationPeriod } from '../../actions/settingsGlobal'
import { getCountryName } from '../../utils/addresses'

const FORM_NAME = 'csrOrgSearch'
const FIELD_NAME = 'csrOrgName'
const FIELD_ID = 'csrOrgId'
const AICODE = { label: 'AI Code', type: 'aiCode' }
const ORGID = { label: 'Org Id', type: 'orgId' }
const ORGNAME = { label: 'School Name', type: 'name' }

const CSRSearchNavigation = props => {
  const { change, getOrgDetails, clearSelectedEducationPeriod } = props
  const [searchType, setSearchType] = useState(AICODE)
  const [currentSchoolName, setCurrentSchoolName] = useState('')

  const clickedSearchType = searchType => {
    setSearchType(searchType)
    change(FIELD_NAME, '')
    document.getElementById(FIELD_NAME).focus()
  }

  return (
    <div
      style={{
        backgroundColor: 'aliceblue',
        paddingTop: 24,
        paddingBottom: 24,
        borderBottom: '1px solid #ccc',
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-9 col-md-6">
            <form onSubmit={e => e.preventDefault()}>
              <Autosuggest
                name={FIELD_NAME}
                label={
                  <>
                    Search for school by{' '}
                    <Dropdown
                      label={searchType.label}
                      applyStyles={{ display: 'inline' }}
                      id="tyepOfSearch"
                      menuItems={[
                        {
                          label: AICODE.label,
                          clickEvent: () => clickedSearchType(AICODE),
                        },
                        {
                          label: ORGID.label,
                          clickEvent: () => clickedSearchType(ORGID),
                        },
                        {
                          label: ORGNAME.label,
                          clickEvent: () => clickedSearchType(ORGNAME),
                        },
                      ]}
                    />
                  </>
                }
                arialabel={`Search for school by ${searchType.label}`}
                placeholder={
                  searchType === ORGID
                    ? `Start typing ${ORGID.label} to search for school`
                    : searchType === AICODE
                    ? `Start typing ${AICODE.label} to search for school`
                    : `Start typing ${ORGNAME.label} to search for school`
                }
                formGroupStyles={{ marginBottom: 0 }}
                isRequired={false}
                fetchSuggestions={val =>
                  getOrgsFromOrgSearch(val, { searchWithinAP: true, searchType: searchType.type })
                }
                renderSuggestion={s => (
                  <div>
                    <div style={{ fontWeight: 'bold' }}>{s.orgName}</div>
                    <div>{s.address}</div>
                    <div>
                      {s.city}
                      {(s.city && s.state) || s.province ? ',' : null} {s.state || s.province}{' '}
                      {s.zip || s.postal}
                    </div>
                    {s.country !== 'US' ? <div>{getCountryName(s.country)}</div> : null}
                  </div>
                )}
                selectSuggestion={val => {
                  change(FIELD_NAME, val.orgName)
                  change(FIELD_ID, val.orgId)
                  setCurrentSchoolName(val.orgName)
                  getOrgDetails(parseInt(val.orgId, 10))
                  clearSelectedEducationPeriod()
                }}
                onBlur={e => {
                  if (e.target.value === '' && currentSchoolName) {
                    change(FIELD_NAME, currentSchoolName)
                    e.preventDefault()
                  }
                }}
              />
            </form>
          </div>
          <div className="col-xs-3">
            <SelectAcademicYear
              style={{ lineHeight: '48px', fontSize: '1.4em', marginTop: '22px' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  null,
  { change, getOrgDetails, clearSelectedEducationPeriod }
)(
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      [FIELD_ID]: null,
      [FIELD_NAME]: null,
    },
    destroyOnUnmount: false,
  })(CSRSearchNavigation)
)
