import { openModal } from '../../../actions/app'

class EditButton extends Component {

  render() {
    const { modalType, modalOptions, openModal, screenReaderText, buttonText = 'edit' } = this.props

    return (
      <button
        type="button"
        className="btn-link cb-small-font-size"
        style={{ padding: 0 }}
        onClick={() => openModal(modalType, { ...modalOptions, modalCloseFocusElem: this.node })}
        ref={node => this.node = node}
      >
        ({buttonText}{screenReaderText && <span className="sr-only">: {screenReaderText}</span>})
      </button>
    )
  }
}

export default connect(null, { openModal })(EditButton)
