import memoize from 'memoizee'
import history from '../utils/history'
import { queryParamsToObj, isEmpty } from '../utils/common'
import * as c from '../constants/FilterConstants'

export const getFilters = memoize(search => {
  const params = queryParamsToObj(search)
  const hasParams = !isEmpty(params)

  return hasParams
    ? Object.keys(params).reduce((obj, filterType) => {
        const { filter: type } =
          Object.values(c).find(constant => constant?.filter === filterType) || {}
        const filters = params[type]
        return type
          ? {
              ...obj,
              [type]: Array.isArray(filters)
                ? filters.map(filter => decodeURIComponent(filter))
                : [decodeURIComponent(filters)],
            }
          : obj
      }, {})
    : {}
})

export const addFilter = (category, filter) => {
  const { search, pathname } = history.location

  history.replace(
    `${pathname}${search}${search ? '&' : '?'}${category}=${encodeURIComponent(filter)}`
  )
}

export const removeFilter = (category, filter) => {
  const { search, pathname } = history.location
  const params = search
    .substr(1)
    .split('&')
    .filter(p => p !== `${category}=${encodeURIComponent(filter)}`)
    .join('&')

  history.replace(`${pathname}?${params}`)
}

export const clearFilters = () => {
  history.replace(history.location.pathname)
}
