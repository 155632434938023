import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import * as reducers from '../reducers'

const reducer = combineReducers({ ...reducers })

const store = (initialState = {}) => {
  const store = createStore(reducer, initialState, applyMiddleware(thunkMiddleware))

  return store
}

export default store()
