import { Modal, Error } from '../../components/common'
import {
  toggleTeacherAuthorization,
  resetEnableDisableTeacherAuthorization,
} from '../../actions/settingsTeacherAccess'
import { getSelectedOrgId } from '../../selectors/settings'

const mapStateToProps = state => {
  const {
    teachersGrantedAccess: { saveError, saving, saved },
    settingsEducationPeriod: { selectedEducationPeriod, availableEducationPeriods },
  } = state
  const { descr } = availableEducationPeriods[selectedEducationPeriod]

  return {
    orgId: getSelectedOrgId(state),
    saveError,
    saving,
    saved,
    academicYear: descr,
  }
}

const ToggleTeacherAccessModal = ({
  authorization: {
    courseName,
    firstName,
    lastName,
    proId,
    certOverrideInd,
    testCd,
    educationPeriodCd,
    onlineInd,
  },
  orgId,
  saveError,
  saving,
  saved,
  academicYear,
  modalCloseFocusElem,
  toggleTeacherAuthorization,
  resetEnableDisableTeacherAuthorization,
}) => {
  useEffect(() => {
    return () => resetEnableDisableTeacherAuthorization()
  }, [resetEnableDisableTeacherAuthorization])

  return (
    <Modal
      shouldCloseModal={saved}
      modalCloseFocusElem={modalCloseFocusElem}
      headerTitle={`${certOverrideInd ? 'Enabled' : 'Disable'} Teacher Access`}
      footerActions={[
        { buttonLabel: 'No', isDismissable: true },
        {
          buttonLabel: 'Yes',
          isPrimary: true,
          onClick: () =>
            toggleTeacherAuthorization({
              proId,
              orgId,
              educationPeriodCd,
              testCd,
              onlineInd,
              certOverrideInd: !certOverrideInd,
            }),
          isDisabled: saving,
          busy: saving,
        },
      ]}
    >
      <>
        <p>
          Are you sure you want to {certOverrideInd ? 'enable' : 'disable'} {courseName} access for{' '}
          {firstName} {lastName} for {academicYear}?{' '}
          {!certOverrideInd ? (
            <>
              <strong>Note:</strong> The teacher&#39;s access to AP Classroom will also be disabled.
            </>
          ) : null}
        </p>
        {saveError ? <Error message={saveError} /> : null}
      </>
    </Modal>
  )
}

export default connect(mapStateToProps, {
  toggleTeacherAuthorization,
  resetEnableDisableTeacherAuthorization,
})(ToggleTeacherAccessModal)
