import { Field } from 'redux-form'
import Label from './Label'
import { truncateLongNames, isEmpty } from '../../../utils/common'
import { AddRequiredValidation } from '..'

export class renderSelect extends Component {
  constructor(props) {
    super(props)
    this.state = { focusClass: '' }
  }

  onFocusHandler(e) {
    this.setState({ focusClass: 'focus' })
    this.props.input.onFocus && this.props.input.onFocus(e)
  }

  onBlurHandler(e) {
    this.setState({ focusClass: '' })
    this.props.input.onBlur && this.props.input.onBlur(e)
  }

  render() {
    const {
      label,
      options,
      input,
      labelledBy,
      disabled,
      meta,
      truncate,
      showEmptyOption = true,
      style = {},
      selectContainerStyle = {},
      labelStyle = {},
      labelClasses = '',
      srOnlyLabel = false,
      multiple = false,
    } = this.props
    const { error, touched, active } = meta || {}
    const { value = '', label: visibleLabel } =
      options.find(({ value }) => value == input.value) || {}
    const hasError = error && touched
    const errorId = `${input.name}-error`
    const blankLabel = 'Choose...'

    return (
      <div className={`form-group ${hasError ? 'has-error' : ''}`} style={style}>
        {label ? (
          <Label
            {...this.props}
            name={input.name}
            classes={`${labelClasses}${srOnlyLabel ? ' sr-only' : ''}`}
            style={labelStyle}
          />
        ) : null}
        <div className="cb-select-container" style={selectContainerStyle}>
          <select
            {...input}
            id={input.name}
            name={input.name}
            className="form-control replaced"
            disabled={disabled}
            aria-labelledby={labelledBy}
            aria-describedby={hasError ? errorId : null}
            value={value}
            onFocus={this.onFocusHandler.bind(this)}
            onBlur={this.onBlurHandler.bind(this)}
            multiple={multiple}
          >
            {showEmptyOption ? <option value="">{blankLabel}</option> : null}
            {options.map((c, i) => (
              <option value={c.value} key={`${c.value}_${i}`}>
                {c.label}
              </option>
            ))}
          </select>
          <span
            className={`cb-select ${this.state.focusClass} ${disabled ? 'disabled' : ''}`}
            aria-hidden={disabled}
          >
            <span>
              <span>
                <i className="cb-select-arrow" />
                {!isEmpty(visibleLabel) ? truncateLongNames(visibleLabel, truncate) : blankLabel}
              </span>
            </span>
          </span>
        </div>
        {hasError ? (
          <p tabIndex="-1" id={errorId} className="cb-error-msg" role="alert" aria-live="polite">
            {error}
          </p>
        ) : null}
      </div>
    )
  }
}

export default props => (
  <Field
    {...props}
    component={renderSelect}
    validate={AddRequiredValidation(props.isRequired, props.validate)}
    normalize={props.normalize}
  />
)
