import {
  fetchScoreReportingServicesLockStatus,
  saveScoreReportingServicesLockStatus,
} from '../../../actions/csr'
import { LockUnlockComponent } from '.'

import styles from '../../../assets/style/scss/csr.scss'

export default () => (
  <LockUnlockComponent
    compId="scoreReportingServices"
    introText="Select Score Reporting Services to unlock ordering for Score Reporting Services. Score Reporting Services ordering will be active for 24 hours."
    emptyText="Score Reporting Services cannot be changed"
    fnFetch={fetchScoreReportingServicesLockStatus}
    fnSave={saveScoreReportingServicesLockStatus}
    itemProps={{ itemId: 'itemId', itemDescription: 'description' }}
  />
)
