import { StepTracker, ConfirmCentralBillFunding, ConfigureDistrictSubsidyFunding } from '.'
import { FUNDING_CENTRAL_BILL } from '../../constants/FundingConstants'

const mapStateToProps = state => {
  const {
    funding: { fundingType = null },
  } = state

  return {
    fundingType,
  }
}

const FundingConfiguration = ({ fundingType }) => {
  const alertBeforeLeaving = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    window.scroll(0, 0)
    window.addEventListener('beforeunload', alertBeforeLeaving)
    return () => {
      window.removeEventListener('beforeunload', alertBeforeLeaving)
    }
  }, [])

  return (
    <>
      <StepTracker />
      {fundingType === FUNDING_CENTRAL_BILL ? (
        <ConfirmCentralBillFunding />
      ) : (
        <ConfigureDistrictSubsidyFunding />
      )}
    </>
  )
}

export default connect(mapStateToProps)(FundingConfiguration)
