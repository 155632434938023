import * as c from '../constants/CourseConstants'
import * as s from '../constants/SectionConstants'
import template from './template'

const initialCoursesState = {
  fetching: false,
  fetched: false,
  error: null,
  entities: {
    courses: {},
    sections: {},
  },
  result: {
    courses: [],
  },
}

export const courses = (state = initialCoursesState, { type, payload }) => {
  const { sections = {}, courses = {} } = state.entities || {}

  switch (type) {
    // FETCH
    case c.FETCH_COURSES_PENDING:
      return { ...state, fetching: true, error: null }
    case c.FETCH_COURSES_FULFILLED:
      return {
        ...state,
        ...payload,
        entities: {
          courses: { ...payload.entities.courses },
          sections: { ...payload.entities.sections },
        },
        fetching: false,
        fetched: true,
      }
    case c.FETCH_COURSES_REJECTED:
      return { ...state, fetching: false, error: payload }

    //COURSE SECTIONS
    case s.EDIT_SECTION_FULFILLED:
    case s.UPDATE_SECTION_JOINCODE_FULFILLED:
    case s.UPDATE_SECTION_JOINCODE_PENDING:
    case s.UPDATE_SECTION_JOINCODE_REJECTED:
    case s.GENERATE_SECTION_TRANSFERCODE_FULFILLED:
    case s.GENERATE_SECTION_TRANSFERCODE_PENDING:
    case s.GENERATE_SECTION_TRANSFERCODE_REJECTED:
    case s.DELETE_SECTION_PENDING:
    case s.DELETE_SECTION_REJECTED:
    case s.CONFIRM_SECTION_EXAM_DECISION_FULFILLED:
    case s.CONFIRM_SECTION_EXAM_DECISION_PENDING:
    case s.CONFIRM_SECTION_EXAM_DECISION_REJECTED:
      return {
        ...state,
        entities: {
          ...state.entities,
          sections: { ...state.entities.sections, ...payload.entities.sections },
        },
      }

    case s.CREATE_SECTION_FULFILLED: {
      let {
        result,
        entities: { sections: nSections },
      } = payload
      let courseId = nSections[result].testCd
      let { courses } = state.entities
      // Creating a section for an existing course
      if (courses[courseId] !== undefined) {
        return {
          ...state,
          entities: {
            courses: {
              ...state.entities.courses,
              [courseId]: {
                ...state.entities.courses[courseId],
                sections: [...state.entities.courses[courseId].sections, result],
              },
            },
            sections: { ...state.entities.sections, ...nSections },
          },
        }
        // Creating a section for a new course
      } else {
        return {
          ...state,
          entities: {
            courses: {
              ...state.entities.courses,
              [courseId]: {
                testCd: courseId,
                courseName: nSections[result].courseName,
                sections: [result],
              },
            },
            sections: { ...state.entities.sections, ...nSections },
          },
          result: { ...state.result, courses: [...state.result.courses, courseId] },
        }
      }
    }

    case s.DELETE_SECTION_FULFILLED: {
      const { sectionId, testCd } = payload
      const dSections = { ...sections }
      delete dSections[sectionId]

      // Remove deleted section from the course's list and the list of all section entities
      const courseState = {
        ...state,
        entities: {
          courses: {
            ...state.entities.courses,
            [testCd]: {
              ...state.entities.courses[testCd],
              sections: courses[testCd].sections.filter(id => id !== sectionId),
            },
          },
          sections: dSections,
        },
      }

      return courseState
    }

    case c.UPDATE_SECTION_AFTER_STUDENT_MOVED_OR_DROPPED:
      return {
        ...state,
        entities: {
          ...state.entities,
          sections: {
            ...state.entities.sections,
            [payload.sectionId]: {
              ...state.entities.sections[payload.sectionId],
              ...payload,
            },
          },
        },
      }

    default:
      return state
  }
}

export const availableCourses = (state, action) => template(state, action, c, 'AVAILABLE_COURSES')

export const availableCourseTypes = (state, action) => template(state, action, c, 'COURSE_TYPES')
