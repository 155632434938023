
export default ({
  streetAddr1,
  street1,
  streetAddr2,
  street2,
  streetAddr3,
  street3,
  city,
  state, // can be string or object
  stateCode,
  isoCountry,
  zip5, // zip v1
  postalCode, // zip v2
  internationalPostalCode,
  province,
  countryCode,
  country,
  styles = {},
}) => {
  const stateAbbr = typeof state === 'string' ? state : null
  const { code: sCode } = state || {}
  const { code: cCode, description: cDescription } = isoCountry || {}
  const isInternational = (cCode && cCode !== 'US') || (countryCode && countryCode !== 'US')
    || (country && country !== 'United States' && country !== 'US')
  const stateOrProvince = stateAbbr || sCode || stateCode || province

  return (
    <address style={styles}>
      {streetAddr1 ? <div className="street-address-1">{streetAddr1}</div> : null}
      {streetAddr2 ? <div className="street-address-2">{streetAddr2}</div> : null}
      {streetAddr3 ? <div className="street-address-3">{streetAddr3}</div> : null}
      {street1 ? <div className="street-1">{street1}</div> : null}
      {street2 ? <div className="street-2">{street2}</div> : null}
      {street3 ? <div className="street-3">{street3}</div> : null}
      <div className="city-state-zip">{city}{city && stateOrProvince ? ', ' : null} {stateOrProvince} {zip5 || postalCode || internationalPostalCode}</div>
      {isInternational ? <div className="country">{cDescription || country || countryCode}</div> : null}
    </address>
  )
}
