import { SortableTable } from '../../common'
import * as Columns from '../common/Columns'

import styles from '../../../assets/style/scss/exam-readiness.scss'
import { SORT_KEYS, TABLE_KEYS } from '../../../constants/ExamReadinessConstants'

const headerColumns = [
  {
    headerText: { title: 'Student Name' },
    key: SORT_KEYS[TABLE_KEYS.student],
    sorting: 'asc',
    headerAttributes: { id: 'examsStudentName' },
  },
  {
    headerText: { title: <Columns.TooltipHeader column={TABLE_KEYS.date} isTeacher={true} /> },
    key: SORT_KEYS[TABLE_KEYS.date],
    sorting: 'asc',
    headerAttributes: { id: 'examsExamDateTime' },
    defaultSort: true,
  },
  {
    headerText: { title: <Columns.TooltipHeader column={TABLE_KEYS.practice} isTeacher={true} /> },
    key: SORT_KEYS[TABLE_KEYS.practice],
    sorting: 'asc',
    headerAttributes: { id: 'examsPracticeStatus' },
  },
  {
    headerText: { title: <Columns.TooltipHeader column={TABLE_KEYS.install} isTeacher={true} /> },
    key: SORT_KEYS[TABLE_KEYS.install],
    sorting: 'asc',
    headerAttributes: { id: 'examsAppInstalled' },
  },
  {
    headerText: { title: <Columns.TooltipHeader column={TABLE_KEYS.setup} isTeacher={true} /> },
    key: SORT_KEYS[TABLE_KEYS.setup],
    sorting: 'asc',
    headerAttributes: { id: 'examsSetupStatus' },
  },
  {
    headerText: { title: <Columns.TooltipHeader column={TABLE_KEYS.checkin} isTeacher={true} /> },
    key: SORT_KEYS[TABLE_KEYS.checkin],
    sorting: 'asc',
    headerAttributes: { id: 'examsCheckinStatus' },
  },
  {
    headerText: { title: <Columns.TooltipHeader column={TABLE_KEYS.exam} isTeacher={true} /> },
    key: SORT_KEYS[TABLE_KEYS.exam],
    sorting: 'asc',
    headerAttributes: { id: 'examsExamStatus' },
  },
  {
    headerText: { title: <Columns.TooltipHeader column={TABLE_KEYS.makeup} isTeacher={true} /> },
    key: SORT_KEYS[TABLE_KEYS.makeup],
    sorting: 'asc',
    headerAttributes: { id: 'examsMakeupStatus' },
  },
]

const TeacherTable = ({ exams }) => (
  <div className="table-responsive">
    <SortableTable
      tableAttributes={{
        summary:
          'Exams are listed in ascending order by student name. Additional sorting options are available by clicking on the column header. Use the filter controls above to filter the exams list down further.',
        className: `table cb-no-table-border ${styles['teacher-table']}`,
      }}
      data={exams}
      columns={headerColumns}
      rowBuilder={exams =>
        exams.map(e => (
          <tr key={e.studentExamId}>
            <th scope="col">
              {e.lastName}, {e.firstName}
            </th>
            <td>
              <Columns.ExamDate rowData={e} />
            </td>
            <td>
              <Columns.Practice rowData={e} />
            </td>
            <td>
              <Columns.Login rowData={e} />
            </td>
            <td>
              <Columns.Setup rowData={e} />
            </td>
            <td>
              <Columns.Checkin rowData={e} />
            </td>
            <td>
              <Columns.Exam rowData={e} />
            </td>
            <td>{e.makeupStatusFormatted}</td>
          </tr>
        ))
      }
      tableCaption={{
        caption: 'A consolidated list of all digital exams by course.',
        srOnly: true,
      }}
    />
  </div>
)

export default TeacherTable
