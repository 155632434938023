import { Link } from 'react-router-dom'
import { dismissNotification } from '../../../actions/notifications'
import { AP_NOTIFICATIONS, TRANSFER_NOTIFICATION } from '../../../constants/NotificationConstants'
import { NAV_LINKS } from '../../../constants/NavConstants'
import { isEmpty } from '../../../utils/common'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId, isCSR, isSchoolAdmin },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  const { data = [], fetching, error } = state.notifications[AP_NOTIFICATIONS]
  const notification = data.filter(
    notification => notification.messageType === TRANSFER_NOTIFICATION
  )
  return {
    orgId: selectedOrgId,
    educationPeriodCd,
    isCSR,
    isSchoolAdmin,
    notification: notification.length ? notification[0] : {},
    fetching,
    error,
  }
}

const CoordinatorTransferNotification = ({
  orgId,
  educationPeriodCd,
  notification,
  isCSR,
  isSchoolAdmin,
  error,
  dismissNotification,
}) => {
  const { messageId, message } = notification
  const [show, setShow] = useState(true)

  const removeNotification = () => {
    setShow(false)
    dismissNotification({
      orgId,
      educationPeriodCd,
      notificationType: TRANSFER_NOTIFICATION,
      messageIds: [messageId],
    })
  }

  if (isCSR || isSchoolAdmin) return null

  if (!isEmpty(notification) && show && !error) {
    return (
      <div
        role="alert"
        className="alert alert-warning cb-alert-heading notification"
        style={{ marginTop: -21, border: 0, backgroundColor: 'rgb(0, 119, 200, .15)' }}
      >
        <div className="container" style={{ position: 'relative' }}>
          <h2>Submit Order Changes</h2>
          <p>
            {message}
            <Link
              to={NAV_LINKS.unsubmittedchanges}
              className="cb-base-font"
              style={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
            >
              <strong>View Unsubmitted Changes</strong>
              <i
                className="cb-glyph cb-east"
                aria-hidden={true}
                style={{ marginLeft: 5, fontSize: '.9em' }}
              />
            </Link>
          </p>
          <button
            type="button"
            className="close"
            style={{ position: 'absolute', top: '-15px', right: 30 }}
            onClick={removeNotification}
          >
            <span className="sr-only">close</span>
          </button>
        </div>
      </div>
    )
  }
  return null
}

export default connect(mapStateToProps, { dismissNotification })(CoordinatorTransferNotification)
