import { SubmissionError } from 'redux-form'
import * as c from '../constants/StudentConstants'
import { UPDATE_STATUS_SUMMARY } from '../constants/StatusConstants'
import axios, { getServerErrorMessage } from '../utils/axios'
import {
  processStudentData,
  processStudentsByOrgData,
  processStudentAndSubsidyData,
} from '../selectors/student'
import { analyticsExamChangeEvent } from '../utils/analytics'

const getStudentsDispatchObj = ({
  data: { students, ...maps },
  type = c.FETCH_ORG_STUDENTS_FULFILLED,
  examWindowsData = [],
}) => ({
  type,
  payload: {
    ...maps,
    ...processStudentsByOrgData({ students, sectionMap: maps.sectionMap, examWindowsData }),
  },
})

export const fetchStudents = (orgId, educationPeriodCd) => async (dispatch, getState) => {
  const examWindowsData = getState().settingsExamWindows.data[orgId]?.[educationPeriodCd]
  dispatch({ type: c.FETCH_ORG_STUDENTS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/coordinator/organizations/${orgId}/education-periods/${educationPeriodCd}/students`
    )
    dispatch(getStudentsDispatchObj({ data, examWindowsData }))
  } catch (err) {
    console.info(err?.response?.data?.message ?? 'Error fetching students')
    dispatch({ type: c.FETCH_ORG_STUDENTS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchStudentDetails = (studentId, orgId, period) => async (dispatch, getState) => {
  const examWindowsData = getState().settingsExamWindows.data[orgId]?.[period]
  dispatch({ type: c.FETCH_STUDENT_DETAILS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/coordinator/organizations/${orgId}/education-periods/${period}/student-details/${studentId}`
    )
    dispatch(
      getStudentsDispatchObj({
        data: { ...data, students: [data.student] },
        type: c.FETCH_STUDENT_DETAILS_FULFILLED,
        examWindowsData,
      })
    )
  } catch (err) {
    dispatch({ type: c.FETCH_STUDENT_DETAILS_REJECTED, payload: getServerErrorMessage(err) })
  }
}

/* ************* UPDATE EXAM (examIntent, testWindow, ssd) ************* */
const getUpdateUrl = (exam, type) => {
  const { enrollmentId, examId } = exam
  switch (type) {
    case c.UPDATE_TYPE_INTENT:
      return {
        event: c.UPDATE_STUDENT_EXAM_INTENT_FULFILLED,
        url: `paapro/v1/coordinator/enrollments/${enrollmentId}/exams/current/exam-intent`,
      }
    case c.UPDATE_TYPE_UNUSED:
      return {
        event: c.UPDATE_STUDENT_EXAM_INTENT_UNUSED_FULFILLED,
        url: `paapro/v1/coordinator/enrollments/${enrollmentId}/exams/${examId}/use`,
      }
    case c.UPDATE_TYPE_DATE:
      return {
        event: c.UPDATE_STUDENT_EXAM_TESTWINDOW_FULFILLED,
        url: `paapro/v1/coordinator/enrollments/${enrollmentId}/exams/current/test-window`,
      }
    case c.UPDATE_TYPE_DATE_INTENT:
      return {
        event: c.UPDATE_STUDENT_EXAM_TESTWINDOW_INTENT_FULFILLED,
        url: `paapro/v1/coordinator/enrollments/${enrollmentId}/exams/current/test-window-exam-intent`,
      }
    default:
      return null
  }
}

export const updateStudentExam = (exam, type) => async (dispatch, getState) => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    studentsByOrg: { sectionMap },
    settingsExamWindows,
  } = getState()
  const examWindowsData = settingsExamWindows.data[selectedOrgId]?.[selectedEducationPeriod]
  const { url, event } = getUpdateUrl(exam, type)
  const { hasConflict, notTakingExamReasonCd, ...cleanExam } = exam
  dispatch({ type: c.UPDATE_ORG_STUDENT_PENDING, payload: { id: exam.examId, type } })
  try {
    const { data } = await axios.post(`${Config.API_URL}/${url}`, {
      ...cleanExam,
      ...(exam?.examIntent === c.STUDENT_ENROLLMENT_STATUS_NO ? { notTakingExamReasonCd } : {}),
    })
    analyticsExamChangeEvent({ exam, type, studentAfterUpdate: data, getState })
    dispatch({
      type: event,
      payload: {
        ...processStudentData({ student: data, sectionMap, examWindowsData }),
        exam,
        type,
      },
    })
    dispatch({ type: c.RESET_UPDATE_TYPE })
    dispatch({ type: UPDATE_STATUS_SUMMARY })
  } catch (err) {
    // throw new Error(err)
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.UPDATE_ORG_STUDENT_REJECTED, payload: { exam, error: errMsg } })
  }
}

export const updateStudentExamMaterials = (exam, type, payload) => async (dispatch, getState) => {
  const { sectionMap } = getState().studentsByOrg
  dispatch({
    type: c.UPDATE_ORG_STUDENT_PENDING,
    payload: { id: exam.examId, type },
  })
  let endpoint = ''
  let fulfilledType = ''
  switch (type) {
    case c.UPDATE_TYPE_SSD:
      endpoint = 'exams/current/ssd-materials'
      fulfilledType = c.UPDATE_STUDENT_EXAM_SSDMATERIALS_FULFILLED
      break

    case c.UPDATE_TYPE_DIGITAL_ACCOMMODATIONS:
      endpoint = 'update-special-digital-formats'
      fulfilledType = c.UPDATE_STUDENT_EXAM_DIGITAL_ACCOMMODATIONS_FULFILLED
      break

    default:
      break
  }
  try {
    const { data } = await axios.post(
      `${Config.API_URL}/paapro/v1/coordinator/enrollments/${exam.enrollmentId}/${endpoint}`,
      { ...payload }
    )
    dispatch({
      type: fulfilledType,
      payload: processStudentData({ student: data, sectionMap }),
    })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.UPDATE_ORG_STUDENT_REJECTED, payload: { exam, error: errMsg } })
    throw new SubmissionError({ _error: errMsg })
  }
}

/* ************* UPDATE FEE STATUS *************** */
export const updateFeesStatusStudent = (exam, feesStatus) => async (dispatch, getState) => {
  const {
    studentsByOrg: { sectionMap },
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = getState()
  const { studentId } = exam

  dispatch({
    type: c.UPDATE_ORG_STUDENT_PENDING,
    payload: { id: exam.studentId, type: c.UPDATE_TYPE_FEE },
  })

  try {
    const { data } = await axios.put(
      `${Config.API_URL}/paapro/v1/coordinator/organizations/${selectedOrgId}/education-periods/${selectedEducationPeriod}/students/${studentId}/fee-status`,
      { feesStatus }
    )
    dispatch({
      type: c.UPDATE_STUDENT_FEESTATUS_FULFILLED,
      payload: processStudentData({ student: data, sectionMap }),
    })
    dispatch({ type: UPDATE_STATUS_SUMMARY })
  } catch (err) {
    dispatch({
      type: c.UPDATE_ORG_STUDENT_REJECTED,
      payload: { exam, error: getServerErrorMessage(err) },
    })
  }
}

export const updateEnrollmentSubsidies =
  ({ studentId, orgId, period, enrollmentIds, action, value }) =>
  async dispatch => {
    dispatch({
      type: c.UPDATE_ORG_STUDENT_PENDING,
      payload: { id: enrollmentIds, type: c.UPDATE_TYPE_ENROLLMENT_SUBSIDIES },
    })

    try {
      await axios.put(
        `${Config.API_URL}/enrollments/students/${studentId}/organizations/${orgId}/education-periods/${period}/subsidies`,
        {
          enrollmentIds,
          action,
          value,
        }
      )
      dispatch({
        type: c.UPDATE_STUDENT_ENROLLMENT_SUBSIDIES_FULFILLED,
        payload: { enrollmentIds, action, value },
      })
    } catch (err) {
      dispatch({
        type: c.UPDATE_ORG_STUDENT_REJECTED,
        payload: { error: getServerErrorMessage(err) },
      })
    }
  }

export const updateRegistrationSubsidy =
  ({ studentId, subsidyId, enrollmentId, action }) =>
  async (dispatch, getState) => {
    const {
      studentsByOrg: { sectionMap },
      user: {
        data: { selectedOrgId },
      },
      settingsEducationPeriod: { selectedEducationPeriod },
    } = getState()

    dispatch({
      type: c.UPDATE_ORG_STUDENT_PENDING,
      payload: { id: `${subsidyId}_${enrollmentId}`, type: c.UPDATE_TYPE_REGISTRATION_SUBSIDY },
    })

    try {
      const { data } = await axios.put(
        `${Config.API_URL}/paapro/v1/coordinator/organizations/${selectedOrgId}/education-periods/${selectedEducationPeriod}/${studentId}/enrollments/${enrollmentId}/subsidies/${subsidyId}/${action}`
      )
      const { subsidies, student } = data

      dispatch({
        type: c.UPDATE_STUDENT_REGISTRATION_SUBSIDY_FULFILLED,
        payload: processStudentAndSubsidyData({ student, sectionMap, subsidies }),
      })
      dispatch({ type: UPDATE_STATUS_SUMMARY })
    } catch (err) {
      dispatch({
        type: c.UPDATE_ORG_STUDENT_REJECTED,
        payload: { error: getServerErrorMessage(err) },
      })
    }
  }

/* ************* DROP and CHANGE ************** */
const dropUrl = {
  [c.STUDENT_DROP_OPTION_KEEP]: {
    dropType: 'DROP',
  },
  [c.STUDENT_DROP_OPTION_REMOVE]: {
    dropType: 'DROP_UNREGEXAM',
    updateStatus: true,
  },
  [c.STUDENT_DROP_OPTION_UNUSED]: {
    dropType: 'DROP_MARKUNUSED',
    updateStatus: true,
  },
}

export const dropStudentEnrollment = (dropOption, exam) => async (dispatch, getState) => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsExamWindows,
  } = getState()
  const examWindowsData = settingsExamWindows.data[selectedOrgId]?.[selectedEducationPeriod]
  const { dropType, updateStatus } = dropUrl[dropOption]
  dispatch({
    type: c.UPDATE_ORG_STUDENT_PENDING,
    payload: { id: exam.examId, type: c.UPDATE_TYPE_DROP },
  })

  try {
    const { data } = await axios.post(
      `${Config.API_URL}/paapro/v1/enrollments/${exam.enrollmentId}/drop`,
      { dropType }
    )
    const { student, sectionMap, teacherMap } = data

    if (!student) dispatch({ type: c.DROP_STUDENT_FULFILLED, payload: { exam } })
    else
      dispatch({
        type: c.DROP_STUDENT_ENROLLMENT_FULFILLED,
        payload: {
          ...processStudentData({ student, sectionMap, examWindowsData }),
          sectionMap,
          teacherMap,
        },
      })

    if (updateStatus) dispatch({ type: UPDATE_STATUS_SUMMARY })
  } catch (err) {
    dispatch({
      type: c.UPDATE_ORG_STUDENT_REJECTED,
      payload: { exam, error: getServerErrorMessage(err) },
    })
  }
}

export const changeStudentSection = (sectionId, exam) => async dispatch => {
  dispatch({
    type: c.UPDATE_ORG_STUDENT_PENDING,
    payload: { id: exam.examId, type: c.UPDATE_TYPE_CHANGE },
  })
  try {
    const { data } = await axios.put(
      `${Config.API_URL}/paapro/v1/enrollments/${exam.enrollmentId}/move-to-section`,
      { sectionId }
    )
    const { student, sectionMap, teacherMap } = data
    dispatch({
      type: c.CHANGE_STUDENT_SECTION_FULFILLED,
      payload: {
        ...processStudentData({ student, sectionMap }),
        sectionMap,
        teacherMap,
      },
    })
  } catch (err) {
    dispatch({
      type: c.UPDATE_ORG_STUDENT_REJECTED,
      payload: { exam, error: getServerErrorMessage(err) },
    })
  }
}

export const transferStudentOut = (exam, orgId, educationPeriodCd) => async dispatch => {
  dispatch({
    type: c.UPDATE_ORG_STUDENT_PENDING,
    payload: { id: exam.examId, type: c.UPDATE_TYPE_TRANSFER },
  })

  try {
    const { data = {} } = await axios.post(
      `${Config.API_URL}/paapro/v1/enrollments/students/${exam.studentId}/organizations/${orgId}/education-periods/${educationPeriodCd}/transfer-out`
    )
    const { student, sectionMap } = data

    if (!student) dispatch({ type: c.TRANSFER_OUT_STUDENT_FULFILLED, payload: { exam } })
    else
      dispatch({
        type: c.TRANSFER_OUT_STUDENT_WITHPENDING_FULFILLED,
        payload: { ...processStudentData({ student, sectionMap }) },
      })
    dispatch({ type: UPDATE_STATUS_SUMMARY })
  } catch (err) {
    dispatch({
      type: c.UPDATE_ORG_STUDENT_REJECTED,
      payload: { exam, error: getServerErrorMessage(err) },
    })
  }
}

export const changeTestedWithAccommodations =
  ({ testedWithAccomInd, exam }) =>
  async dispatch => {
    // dispatch({
    //   type: c.UPDATE_ORG_STUDENT_PENDING,
    //   payload: { id: exam.examId, type: c.UPDATE_TYPE_CHANGE },
    // })
    try {
      const { data } = await axios.put(
        `${Config.API_URL}/paapro/v1/enrollments/${exam.enrollmentId}/tested-with-accom`,
        testedWithAccomInd,
        { headers: { 'Content-Type': 'application/json' } }
      )
      const { student, sectionMap, teacherMap } = data
      const { enrollments } = student
      const updatedEnrollments = enrollments.map(enrollment => {
        if (enrollment.enrollmentId === exam.enrollmentId) {
          return { ...enrollment, menuOpen: true }
        }
        return enrollment
      })
      dispatch({
        type: c.CHANGE_TESTING_WITH_ACCOMMODATIONS_FULFILLED,
        payload: {
          ...processStudentData({
            student: { ...student, enrollments: updatedEnrollments },
            sectionMap,
          }),
          sectionMap,
          teacherMap,
        },
      })
    } catch (err) {
      dispatch({
        type: c.UPDATE_ORG_STUDENT_REJECTED,
        payload: { exam, error: getServerErrorMessage(err) },
      })
    }
  }

export const toggleStudentActionMenu =
  ({ enrollmentId, menuOpen }) =>
  dispatch => {
    dispatch({ type: c.TOGGLE_STUDENT_ACTION_MENU, payload: { enrollmentId, menuOpen } })
  }

/* ************* MULTI-SELECT ************** */
export const selectEnrollments = id => ({
  type: c.STUDENT_MULTISELECT_CHECK,
  payload: id,
})

export const unselectEnrollments = (id = null) => ({
  type: c.STUDENT_MULTISELECT_UNCHECK,
  payload: id,
})

export const resetMultiSelect = () => ({ type: c.UPDATE_STUDENT_MULTISELECT_RESET })

const getMultiSelectSuffix = type => {
  switch (type) {
    case c.UPDATE_TYPE_DATE:
      return 'updateTestWindow'
    case c.UPDATE_TYPE_TESTED_WITH_ACCOM_IND:
      return 'updateTestedWithAccom'
    default:
      return null
  }
}
export const updateMultiSelect =
  ({ selected, options, orgId, educationPeriodCd, settingsExamWindows }) =>
  async dispatch => {
    const examWindowsData = settingsExamWindows.data[orgId]?.[educationPeriodCd]
    const { type, ...change } = options
    dispatch({ type: c.UPDATE_STUDENT_MULTISELECT_PENDING })

    try {
      const { data = {} } = await axios.post(
        `${Config.API_URL}/paapro/v1/coordinator/enrollments/exams:${getMultiSelectSuffix(type)}`,
        {
          enrollmentIds: Array.from(new Set(selected.map(s => s.enrollmentId))),
          change,
        }
      )
      const { failed = [], updated } = data
      const failedCount = failed.length
      if (updated) {
        dispatch(getStudentsDispatchObj({ data: updated, examWindowsData }))
        dispatch({ type: UPDATE_STATUS_SUMMARY })
        dispatch(unselectEnrollments())
      }
      if (failedCount) {
        dispatch({
          type: c.UPDATE_STUDENT_MULTISELECT_REJECTED,
          payload: {
            error: `Error: ${failedCount} student${
              failedCount > 1 ? 's' : ''
            } failed to update. Please try again.`,
          },
        })
      } else {
        dispatch({ type: c.UPDATE_STUDENT_MULTISELECT_FULFILLED })
      }
    } catch (err) {
      dispatch({
        type: c.UPDATE_STUDENT_MULTISELECT_REJECTED,
        payload: { error: getServerErrorMessage(err), selected },
      })
    }
  }

export const resetStudentData = () => dispatch => dispatch({ type: c.RESET_STUDENT_DATA })
