import Axios from 'axios'
import { DEFAULT_REQUEST_ERROR } from '../constants/MessageConstants'


Axios.defaults.responseType = 'json'
Axios.defaults.withCredentials = true
Axios.defaults.headers.common['Content-Type'] = 'application/json'
Axios.defaults.headers.common['Cache-Control'] = 'no-cache,no-store,must-revalidate,max-age=-1,private'
Axios.defaults.headers.common['Pragma'] = 'no-cache'
Axios.defaults.headers.common['Expires'] = 0

export const getServerErrorMessage = (res, defaultMessage) => {
	let {message} = res && res.response && res.response.data || {}
 	return message || defaultMessage || DEFAULT_REQUEST_ERROR
}

export const dispatchError = (dispatch, type, res, customMessage) => dispatch({ type, payload: getServerErrorMessage(res, customMessage) })

export const dispatchNormalizedError = (dispatch, type, normfn, data, res) => {
	dispatch({ type, payload: normfn({...data, error: getServerErrorMessage(res, data.error), fetching: false})})	
} 

export default Axios