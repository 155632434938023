import { parse } from '@myap/ui-library'
import { fetchStudentProfile, saveStudentProfileData } from '../../../actions/csr'
import { SearchForStudents, BrowseItem, ActionBar } from '.'
import {
  BasicTextInput as Text,
  BasicSelect as Select,
  Loader,
  Error,
  RequiredValidation,
  LetterValidation,
  NameValidation,
} from '../../common'
import { getSelectedEducationPeriod, getSelectedOrgId } from '../../../selectors/settings'
import { isEmpty } from '../../../utils/common'

import styles from '../../../assets/style/scss/csr.scss'

const FIELD_FN = 'firstName'
const FIELD_MI = 'middleInitial'
const FIELD_LN = 'lastName'
const FIELD_MONTH = 'dobMonth'
const FIELD_DAY = 'dobDay'
const FIELD_YEAR = 'dobYear'

const DEFAULT_FORM_STATE = {
  [FIELD_FN]: '',
  [FIELD_MI]: '',
  [FIELD_LN]: '',
  [FIELD_MONTH]: '',
  [FIELD_DAY]: '',
  [FIELD_YEAR]: '',
}

const FORM_VALIDATIONS = {
  [FIELD_FN]: [RequiredValidation, NameValidation],
  [FIELD_MI]: [LetterValidation],
  [FIELD_LN]: [RequiredValidation, NameValidation],
}

const getDaysInMonth = (month, year) => {
  const days = new Date(year, month, 0).getDate()
  const options = []
  for (let i = 1; i < days + 1; i++) {
    options.push({ label: i, value: i })
  }
  return options
}

const getYears = yearsBack =>
  Array.from({ length: yearsBack + 1 }, (_, i) => new Date().getFullYear() - i)

const mapStateToProps = state => {
  return {
    educationPeriod: getSelectedEducationPeriod(state),
    orgId: getSelectedOrgId(state),
  }
}

const UpdateStudentProfile = ({ orgId, educationPeriod, updatePanel, panel, subpanel }) => {
  const [selected, setSelected] = useState([])
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)
  const [saved, setSaved] = useState({})
  const [fetching, setFetching] = useState(false)
  const [studentProfile, setStudentProfile] = useState(null)
  const [formData, setFormData] = useState(DEFAULT_FORM_STATE)
  const [formMeta, setFormMeta] = useState({
    [FIELD_FN]: { touched: false, dirty: false, error: undefined },
    [FIELD_LN]: { touched: false, dirty: false, error: undefined },
  })
  const isFormValid = Object.values(formMeta).every(fieldMetaProps => isEmpty(fieldMetaProps.error))

  useEffect(() => {
    updatePanel(panel, 'Update Student Profile: Search Students')
  }, [])

  useEffect(() => {
    setSaved({})
  }, [subpanel])

  useEffect(() => {
    const fetchProfile = async ({ studentId }) => {
      setFetching(true)
      const { data } = await fetchStudentProfile({ studentId })
      setFetching(false)
      setStudentProfile(data) // save original profile data
      const dob = data?.dob ? parse(data.dob) : undefined
      // set form data
      setFormData({
        [FIELD_FN]: data.firstName || '',
        [FIELD_MI]: data.middleInitial || '',
        [FIELD_LN]: data.lastName || '',
        [FIELD_MONTH]: dob?.getMonth() + 1,
        [FIELD_DAY]: dob?.getDate(),
        [FIELD_YEAR]: dob?.getFullYear(),
      })
    }

    if (!isEmpty(selected) && subpanel === 1) {
      fetchProfile({ studentId: selected.studentId })
    }
  }, [selected, subpanel])

  const updateSubPanel = (subpanel, title) => {
    if (subpanel === -1) {
      updatePanel(-1)
    } else {
      updatePanel(panel, title, subpanel)
    }
  }

  const selectUpdateTypePanel = () =>
    updateSubPanel(-1, 'Update Student Profile: Select Update Type')
  const selectUpdateLegalNamePanel = () => updateSubPanel(2, "Update Student's Legal Name")
  const selectUpdateDateOfBirthPanel = () => updateSubPanel(3, "Update Student's Date of Birth")

  const save = async () => {
    await setSaving(true)
    const { data = [], error } = await saveStudentProfileData({
      profileData: {
        ...studentProfile,
        firstName: formData.firstName,
        middleInitial: formData.middleInitial,
        lastName: formData.lastName,
        dob: `${formData[FIELD_YEAR]}-${formData[FIELD_MONTH]}-${formData[FIELD_DAY]}`,
      },
    })
    setSaving(false)
    setError(error)
    if (!error) {
      setSaved({
        data,
      })
      selectUpdateTypePanel()
    }
  }

  // const setFieldTouched = fieldName => {
  //   setFormMeta({ ...formMeta, [fieldName]: { ...formMeta[fieldName], touched: true } })
  // }

  // const setFieldDirty = (fieldName, value) => {
  //   setFormMeta({
  //     ...formMeta,
  //     [fieldName]: { ...formMeta[fieldName], dirty: value !== studentProfile[fieldName] },
  //   })
  // }

  const setFieldValue = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const setFieldError = (fieldName, error) => {
    setFormMeta({ ...formMeta, [fieldName]: { ...formMeta[fieldName], error, touched: true } })
  }

  switch (subpanel) {
    case 0:
      return (
        <SearchForStudents
          orgId={orgId}
          educationPeriod={educationPeriod}
          inputLabel="Search for student to update."
          getButtons={selected => [
            {
              title: 'Update Selected Student',
              disabled: selected.length !== 1,
              onClick: selections => {
                updatePanel(panel, 'Update Student Profile: Select Update Type', 1)
                setSelected(selections[0])
              },
            },
          ]}
        />
      )

    case 1: {
      if (fetching) {
        return <Loader />
      }

      return (
        <div className={styles['tool-body-inner']}>
          <BrowseItem title="Student Legal Name" action={selectUpdateLegalNamePanel} />
          <BrowseItem title="Student Date of Birth" action={selectUpdateDateOfBirthPanel} />
        </div>
      )
    }

    case 2: // Student Legal Name
      return (
        <form>
          <div className={styles['tool-body-inner']}>
            <p className={styles['tool-body-divider']}>Update student&#39;s legal name.</p>
            <div className={styles['tool-body-item']} style={{ marginTop: '20px' }}>
              <Text
                id={FIELD_FN}
                label="First Name"
                placeholder="Enter first name"
                name={FIELD_FN}
                maxlength="20"
                autocomplete="off"
                input={{
                  name: FIELD_FN,
                  defaultValue: formData[FIELD_FN],
                  onBlur: e => {
                    const { value } = e.target
                    setFormMeta({
                      ...formMeta,
                      [FIELD_FN]: {
                        ...formMeta[FIELD_FN],
                        touched: true,
                        dirty: value !== studentProfile[FIELD_FN],
                      },
                    })
                  },
                  onChange: e => {
                    const { value } = e.target
                    setFieldValue(FIELD_FN, value)
                    let hasError = false
                    FORM_VALIDATIONS[FIELD_FN].forEach(validation => {
                      const error = validation(value)
                      if (error) {
                        hasError = true
                        setFieldError(FIELD_FN, error)
                      }
                    })
                    if (!hasError) {
                      setFieldError(FIELD_FN, undefined)
                    }
                  },
                }}
                meta={formMeta[FIELD_FN]}
              />
            </div>
            <div className={styles['tool-body-item']}>
              <Text
                id={FIELD_MI}
                label="Middle Initial"
                placeholder="Enter middle initial"
                name={FIELD_MI}
                maxlength="1"
                autocomplete="off"
                input={{
                  name: FIELD_MI,
                  defaultValue: formData[FIELD_MI],
                  onBlur: e => {
                    const { value } = e.target
                    setFormMeta({
                      ...formMeta,
                      [FIELD_MI]: {
                        ...formMeta[FIELD_MI],
                        touched: true,
                        dirty: value !== studentProfile[FIELD_MI],
                      },
                    })
                  },
                  onChange: e => {
                    const { value } = e.target
                    setFieldValue(FIELD_MI, value)
                    let hasError = false
                    FORM_VALIDATIONS[FIELD_MI].forEach(validation => {
                      const error = validation(value)
                      if (error) {
                        hasError = true
                        setFieldError(FIELD_MI, error)
                      }
                    })
                    if (!hasError) {
                      setFieldError(FIELD_MI, undefined)
                    }
                  },
                }}
                meta={formMeta[FIELD_MI]}
              />
            </div>
            <div className={styles['tool-body-item']}>
              <Text
                id={FIELD_LN}
                label="Last Name"
                placeholder="Enter last name"
                name={FIELD_LN}
                maxlength="35"
                autocomplete="off"
                input={{
                  name: FIELD_LN,
                  defaultValue: formData[FIELD_LN],
                  onBlur: e => {
                    const { value } = e.target
                    setFormMeta({
                      ...formMeta,
                      [FIELD_LN]: {
                        ...formMeta[FIELD_LN],
                        touched: true,
                        dirty: value !== studentProfile[FIELD_LN],
                      },
                    })
                  },
                  onChange: e => {
                    const { value } = e.target
                    setFieldValue(FIELD_LN, value)
                    let hasError = false
                    FORM_VALIDATIONS[FIELD_LN].forEach(validation => {
                      const error = validation(value)
                      if (error) {
                        hasError = true
                        setFieldError(FIELD_LN, error)
                      }
                    })
                    if (!hasError) {
                      setFieldError(FIELD_LN, undefined)
                    }
                  },
                }}
                meta={formMeta[FIELD_LN]}
              />
            </div>
            {error && !saving ? (
              <div className={styles['tool-body-item']}>
                <Error title="Error Saving Data" message={error} />
              </div>
            ) : null}
          </div>
          <ActionBar
            buttons={[
              { title: 'Cancel', isPrimary: false, onClick: selectUpdateTypePanel },
              { title: 'Save', disabled: saving || !isFormValid, onClick: save },
            ]}
          />
        </form>
      )

    case 3: // Student Date of Birth
      return (
        <form>
          <div className={styles['tool-body-inner']}>
            <p className={styles['tool-body-divider']}>Update student&#39;s date of birth.</p>
            <div className={styles['tool-body-item']} style={{ marginTop: '20px' }}>
              <Select
                id={FIELD_MONTH}
                label="Month"
                name={FIELD_MONTH}
                input={{
                  name: FIELD_MONTH,
                  value: formData[FIELD_MONTH],
                  onChange: e => {
                    const { value } = e.target
                    setFieldValue(FIELD_MONTH, value)
                  },
                }}
                showEmptyOption={false}
                options={[
                  { label: 'January', value: 1 },
                  { label: 'February', value: 2 },
                  { label: 'March', value: 3 },
                  { label: 'April', value: 4 },
                  { label: 'May', value: 5 },
                  { label: 'June', value: 6 },
                  { label: 'July', value: 7 },
                  { label: 'August', value: 8 },
                  { label: 'September', value: 9 },
                  { label: 'October', value: 10 },
                  { label: 'November', value: 11 },
                  { label: 'December', value: 12 },
                ]}
              />
              <Select
                id={FIELD_DAY}
                label="Day"
                name={FIELD_DAY}
                input={{
                  name: FIELD_DAY,
                  value: formData[FIELD_DAY],
                  onChange: e => {
                    const { value } = e.target
                    setFieldValue(FIELD_DAY, value)
                  },
                }}
                showEmptyOption={false}
                options={getDaysInMonth(formData.dobMonth, formData.dobYear)}
              />
              <Select
                id={FIELD_YEAR}
                label="Year"
                name={FIELD_YEAR}
                input={{
                  name: FIELD_YEAR,
                  value: formData[FIELD_YEAR],
                  onChange: e => {
                    const { value } = e.target
                    setFieldValue(FIELD_YEAR, value)
                  },
                }}
                showEmptyOption={false}
                options={getYears(30).map(y => ({ label: y, value: y }))}
              />
            </div>
            {error && !saving ? (
              <div className={styles['tool-body-item']}>
                <Error title="Error Saving Data" message={error} />
              </div>
            ) : null}
          </div>
          <ActionBar
            buttons={[
              { title: 'Cancel', isPrimary: false, onClick: selectUpdateTypePanel },
              { title: 'Save', disabled: saving || !isFormValid, onClick: save },
            ]}
          />
        </form>
      )

    default:
      return null
  }
}

export default connect(mapStateToProps)(UpdateStudentProfile)
