import { useLocation } from 'react-router-dom'
import * as Columns from './studentstablecolumns'
import { Error, Loader, InfiniteScrollTable } from '../../common'
import CoordinatorStudentFilters from '../../filters/student/CoordinatorStudentFilters'
import DownloadStudentRosterButton from './DownloadStudentRosterButton'
import StudentSearchFilter from '../../filters/common/StudentNameIDSearch'
import FeesStatusCheckbox from './updatedropdowns/FeesStatusCheckbox'
import TestDateDropdown from './updatedropdowns/TestDateDropdown'
import { IncidentReportSubmissionAndHistoryLinks } from '../../incidentReports'
import { sortStudentExams, filterExams } from '../../../selectors/student'
import MultiSelectDropdown from './multiselect/MultiSelectDropdown'
import { isFeatureEnabled } from '../../../selectors/features'
import {
  getDeadlineIsPast,
  getSchoolNameAndAddress,
  getSchoolAICode,
} from '../../../selectors/settings'
import { getFilters } from '../../../actions/filters'

import 'react-virtualized/styles.css'
import style from '../../../assets/style/scss/students-table.scss'

const mapStateToProps = state => {
  const {
    studentsByOrg: {
      error,
      fetching,
      exams,
      sectionMap,
      courseMap,
      teacherMap,
      studentMap,
      selected,
      update: { updating },
    },
    settingsEducationPeriod: { selectedIsCurrent },
    user: {
      data: { isLevelTwo, isPreAPOnly, isETS },
    },
  } = state

  return {
    exams,
    error,
    fetching,
    dataMaps: { sectionMap, courseMap, teacherMap, studentMap },
    isLevelTwo,
    isPreAPOnly,
    multiSelectEnabled:
      selectedIsCurrent && isLevelTwo && isFeatureEnabled(state, 'CHANGE_EXAM_DATE'),
    dropEnabled: isFeatureEnabled(state, 'DROP_STUDENT') && selectedIsCurrent,
    selected,
    updating,
    isTestedWithAccommAvailable:
      getDeadlineIsPast(state, 'testedWithAccomStartDate') || !selectedIsCurrent,
    viewingPriorEdPd: !selectedIsCurrent,
    schoolNameAndAddress: getSchoolNameAndAddress(state),
    isNARAvailable: !isETS && getDeadlineIsPast(state, 'narGenerationStartDate'),
    schoolAICode: getSchoolAICode(state),
  }
}

const studentCellId = studentId => `studentNameCell-${studentId}`
const enrollmentCellId = enrollmentId => `enrollmentCell-${enrollmentId}`
const TEST_DATE_COLUMN_ID = 'testDateColumn'
const TAKING_EXAM_COLUMN_ID = 'takingExamColumn'
const FEE_STATUS_COLUMN_ID = 'feesStatusColumn'

const CoordinatorStudentsScroll = props => {
  const { search } = useLocation()
  const {
    error,
    fetching,
    multiSelectEnabled,
    dataMaps,
    isLevelTwo,
    isPreAPOnly,
    exams,
    dropEnabled,
    selected,
    updating,
    isTestedWithAccommAvailable,
    viewingPriorEdPd,
    schoolNameAndAddress,
    isNARAvailable,
    schoolAICode,
  } = props
  const activeFilters = getFilters(search)
  const visibleExams = filterExams(activeFilters, exams, dataMaps)
  const examLength = visibleExams.length

  const getColumns = () => {
    const multiSelectColumn = {
      label: 'Multi-Select',
      id: 'multiSelectColumn',
      dataKey: 'multiselect',
      className: style['multiselect-column'],
      headerClassName: style['multiselect-column'],
      cellRenderer: ({ rowData }) => (
        <Columns.MultiSelectCheckbox selected={selected} rowData={rowData} />
      ),
      headerRenderer: () => (
        <Columns.MultiSelectCheckbox
          selected={selected}
          visibleExams={visibleExams}
          isSelectAll={true}
        />
      ),
      width: 40,
      flexGrow: 0,
      flexShrink: 1,
    }
    const studentColumn = {
      label: 'Student Name',
      id: 'studentNameColumn',
      dataKey: 'studentName',
      className: style['student-name-column'],
      sortable: true,
      cellRenderer: ({ data, sortSelected, rowData, rowIndex }) => {
        const { firstName, lastName } = rowData
        return (
          <Columns.Name
            data={data}
            sortSelected={sortSelected}
            rowData={rowData}
            index={rowIndex}
            studentName={`${firstName} ${lastName}`}
            id={studentCellId(rowData.studentId)}
          />
        )
      },
      width: 250,
      flexShrink: 1,
      flexGrow: 1,
    }
    const courseColumn = {
      label: 'Course Name',
      id: 'courseNameColumn',
      dataKey: 'courseName',
      sortable: true,
      cellRenderer: ({ rowData }) => (
        <Columns.Course rowData={rowData} id={enrollmentCellId(rowData.enrollmentId)} />
      ),
      width: 250,
      flexShrink: 1,
      flexGrow: 1,
    }
    const examDateColumn = {
      label: 'Exam Date',
      id: TEST_DATE_COLUMN_ID,
      dataKey: 'testDate',
      className: style['test-date-column'],
      cellRenderer: ({ rowData }) => (
        <TestDateDropdown
          exam={rowData}
          dropdownOptions={{
            rightAligned: true,
            ariaLabelledBy: `${TEST_DATE_COLUMN_ID} ${studentCellId(
              rowData.studentId
            )} ${enrollmentCellId(rowData.enrollmentId)}`,
          }}
        />
      ),
      width: 245,
      flexShrink: 0,
      flexGrow: 1,
    }
    const orderExamColumn = {
      headerRenderer: Columns.OrderExamHeader,
      id: TAKING_EXAM_COLUMN_ID,
      dataKey: 'examIntent',
      className: `${style['taking-exam-column']} ${isLevelTwo ? style['level-two'] : ''}`,
      cellRenderer: ({ rowData }) => (
        <Columns.OrderExam
          rowData={rowData}
          dropdownOptions={{
            rightAligned: true,
            ariaLabelledBy: `${TAKING_EXAM_COLUMN_ID} ${studentCellId(
              rowData.studentId
            )} ${enrollmentCellId(rowData.enrollmentId)}`,
          }}
        />
      ),
      width: 150,
      flexShrink: 0,
      flexGrow: 1,
    }
    const feeColumn = {
      label: 'Reduced Fee',
      id: FEE_STATUS_COLUMN_ID,
      dataKey: 'feesStatus',
      cellRenderer: ({ rowData }) => (
        <FeesStatusCheckbox
          exam={rowData}
          ariaLabelledBy={`${FEE_STATUS_COLUMN_ID} ${studentCellId(
            rowData.studentId
          )} ${enrollmentCellId(rowData.enrollmentId)}`}
        />
      ),
      width: 120,
      flexShrink: 0,
      flexGrow: 1,
    }
    const actionsColumn = {
      label: 'Actions',
      id: 'actionsColumn',
      dataKey: 'actions',
      headerRenderer: ({ label }) => <span className="sr-only">{label}</span>,
      cellRenderer: ({ rowData }) => {
        const section = dataMaps.sectionMap[rowData.sectionId]
        const MENU_NAME = 'Actions'
        const id = `${MENU_NAME}${rowData.examId}`
        return (
          <Columns.Actions
            section={{ ...section, teachers: section.teacherIds.map(t => dataMaps.teacherMap[t]) }}
            rowData={{ ...rowData, aiCode: schoolAICode }}
            dropEnabled={dropEnabled}
            updating={updating}
            dropdownOptions={{
              rightAligned: true,
              id,
              ariaLabelledBy: `${id} ${studentCellId(rowData.studentId)} ${enrollmentCellId(
                rowData.enrollmentId
              )}`,
            }}
            isTestedWithAccommAvailable={isTestedWithAccommAvailable}
            viewingPriorEdPd={viewingPriorEdPd}
            schoolNameAndAddress={schoolNameAndAddress}
            isNARAvailable={isNARAvailable}
            menuName={MENU_NAME}
          />
        )
      },
      width: 90,
      flexShrink: 0,
      flexGrow: 1,
    }

    return {
      multiColumn: multiSelectEnabled ? multiSelectColumn : null,
      studentColumn,
      courseColumn,
      examDateColumn: isLevelTwo ? examDateColumn : null,
      orderExamColumn: !isPreAPOnly ? orderExamColumn : null,
      feeColumn: isLevelTwo ? feeColumn : null,
      actionsColumn,
    }
  }

  if (error) {
    return (
      <div className="container" style={{ marginTop: 30 }}>
        <Error title="Error Retrieving Data" message={error} />
      </div>
    )
  }

  if (fetching) {
    return (
      <div className="container">
        <Loader />
      </div>
    )
  }

  return (
    <>
      <CoordinatorStudentFilters
        visibleExams={visibleExams}
        allExams={exams}
        activeFilters={activeFilters}
      />
      <div className="container">
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '15px 0 0' }}>
            {examLength ? (
              <>
                {multiSelectEnabled ? <MultiSelectDropdown /> : null}
                <DownloadStudentRosterButton
                  activeFilters={activeFilters}
                  visibleExams={visibleExams}
                />
              </>
            ) : null}
          </div>
          <IncidentReportSubmissionAndHistoryLinks />
          {examLength ? <StudentSearchFilter style={{ marginLeft: 'auto' }} /> : null}
        </div>
        <InfiniteScrollTable
          data={visibleExams}
          sortFn={(data, sortBy, sortDirection) =>
            sortStudentExams(data, sortBy, sortDirection, dataMaps)
          }
          defaultSort="studentName"
          columns={getColumns()}
        />

        {!exams.length ? <p>No students available.</p> : null}
      </div>
    </>
  )
}

export default connect(mapStateToProps)(CoordinatorStudentsScroll)
