import StudentLabelSortByForm from '../../../containers/forms/StudentLabelSortByForm'
import StudentLabelSortOrderPreviewTable from './StudentLabelSortOrderPreviewTable'
import LabelPreviewImage from '../../../assets/images/barcode-label-preview.png'

import styles from '../../../assets/style/scss/student-labels.scss'

const mapStateToProps = state => ({ currentBreakpoint: state.app.breakpoint.name })

const StudentLabelsTab = ({ currentBreakpoint, tabLabel }) => (
  <div className={styles['student-labels-content']} aria-labelledby={tabLabel} role="tabpanel">
    <div className="row">
      <div className="col-md-5 col-sm-6 col-xs-12">
        <p>
          Labels for each student will be included in your shipment. These labels must be placed on
          answer sheets at the time of the exam.
        </p>
        <p style={{ marginBottom: '12px' }}>AP ID Label Sort Order options:</p>
        <ul style={{ marginLeft: 0, marginBottom: '48px' }}>
          <li>
            <strong>Student Name:</strong> Sorted in alphabetical order by students&#39; last names.
          </li>
          <li>
            <strong>Grade Level:</strong> Sorted by grade and then by last name within each grade.
          </li>
          <li>
            <strong>Student First Exam:</strong> Sorted by exam based on administration date and
            then by last name for each exam.
          </li>
        </ul>
      </div>
      <div className="col-md-6 col-md-offset-1 col-sm-6 col-xs-12">
        <img src={LabelPreviewImage} alt="Label Preview" style={{ width: '100%' }} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <h2>Label Sort Order</h2>
      </div>
    </div>
    <div className="row">
      <div
        className={`${styles['sort-description']} col-sm-6 col-xs-12 ${styles[currentBreakpoint]}`}
      >
        <p>You can choose how student labels will be sorted when they are shipped to you.</p>
        <p>
          <em>
            The table below provides an example of how student labels can be sorted with your exam
            order. The information displayed is a sample and not the data for the labels in your
            shipment.
          </em>
        </p>
      </div>
      <div
        className={`${styles['sort-selection']} col-sm-6 col-xs-12 ${styles[currentBreakpoint]}`}
      >
        <StudentLabelSortByForm />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <StudentLabelSortOrderPreviewTable />
      </div>
    </div>
  </div>
)

export default connect(mapStateToProps)(StudentLabelsTab)
