import {
  FETCH_PACKING_LIST_SUMMARY_PENDING,
  FETCH_PACKING_LIST_SUMMARY_FULFILLED,
  FETCH_PACKING_LIST_SUMMARY_REJECTED,
} from '../constants/PackingListConstants'

const initialSubmissionState = {
  error: null,
  fetching: false,
  fetched: false,
  firstExamDate: '',
  packingLists: {},
  packingShipmentsId: null,
  showShipment: false,
  splitShipment: false,
}

export default (state = initialSubmissionState, { type, payload }) => {
  switch (type) {
    case FETCH_PACKING_LIST_SUMMARY_PENDING:
      return { ...state, fetching: true, error: null, fetched: false }

    case FETCH_PACKING_LIST_SUMMARY_FULFILLED:
      return {
        ...state,
        ...payload.data,
        error: null,
        fetching: false,
        fetched: true,
      }

    case FETCH_PACKING_LIST_SUMMARY_REJECTED:
      return { ...state, error: payload, fetching: false, fetched: false }

    default:
      return state
  }
}
