import { fetchProgramStats } from '../../../actions/orders'
import { Loader, Error } from '../../common'
import { percentFormat, numberFormat } from '../../../utils/numbers'
import style from '../../../assets/style/scss/program-statistics.scss'

const mapStateToProps = state => {
  const {
    ordersProgramStats: { fetching, error, currentYearSummary = {}, previousYearSummary = {} },
    user: {
      data: { roles, selectedRole },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
  } = state

  return {
    fetching,
    error,
    currentSummaryByCourse: currentYearSummary.courseSummaries || [],
    previousSummaryByCourse: previousYearSummary.courseSummaries || [],
    orgId: roles[selectedRole].orgId,
    educationPeriodCd: selectedEducationPeriod,
  }
}

const trendIcon = (value, formatFn) => {
  const val = Math.abs(value)
  const increase = value > 0

  return (
    <div className={`${style.trend} ${style[increase ? 'trend-up' : 'trend-down']}`}>
      <div>
        <i className="cb-glyph cb-east" aria-hidden={true} />
      </div>
      <span className="sr-only">{increase ? 'increased by ' : 'decreased by '}</span>
      {formatFn ? formatFn(val) : val}
    </div>
  )
}

const ProgramStatistics = ({
  fetching,
  error,
  orgId,
  educationPeriodCd,
  currentSummaryByCourse,
  previousSummaryByCourse,
  fetchProgramStats,
}) => {
  const [compare, setCompare] = useState(false)
  const [percentage, setPercentage] = useState(false)
  const numberButtonRef = useRef(null)
  const percentButtonRef = useRef(null)

  useEffect(() => {
    if (orgId && educationPeriodCd) {
      fetchProgramStats(orgId, educationPeriodCd)
    }
  }, [orgId, educationPeriodCd])

  useEffect(() => {
    // Accessibility: Select the opposite button from what was clicked
    if (percentage) {
      numberButtonRef.current?.focus()
    } else {
      percentButtonRef.current?.focus()
    }
  }, [percentage])

  const calculateComparison = (testCd, value, type, specialFormatFn) => {
    const previousCourse = previousSummaryByCourse.find(p => p.testCd === testCd)
    const prevValue = previousCourse?.statistics[type]
    const difference = value - prevValue

    if (!previousCourse) return null

    if (!difference) return null

    if (percentage) return trendIcon(difference / (prevValue || 1), percentFormat)

    return trendIcon(difference, specialFormatFn)
  }

  if (error)
    return (
      <Error
        title="Error Retrieving Data"
        message="An error occurred retrieving program statistics. Please try again later."
      />
    )

  if (fetching) return <Loader />

  return (
    <div>
      <h2>Program Statistics</h2>
      <div className={style['compare-controls']}>
        <div className="checkbox">
          <label htmlFor="compareWithLastYearCheckbox">
            <input
              type="checkbox"
              id="compareWithLastYearCheckbox"
              value={compare}
              className="cb-checkbox"
              aria-label="Compare with last year"
              onChange={() => setCompare(!compare)}
            />
            <span className="cb-span" />
            Compare with last year
          </label>
        </div>
        <span>
          (
          <button
            type="button"
            title="Show numbers"
            className="btn-link"
            disabled={!compare || !percentage}
            ref={numberButtonRef}
            onClick={() => setPercentage(false)}
          >
            #
          </button>
          /
          <button
            type="button"
            title="Show percentages"
            className="btn-link"
            disabled={!compare || percentage}
            ref={percentButtonRef}
            onClick={() => setPercentage(true)}
          >
            %
          </button>
          )
        </span>
      </div>
      <table
        className={`table cb-table-no-border ${style['statistics-table']} ${
          compare ? style.compare : ''
        }`}
        summary="Select the COMPARE WITH LAST YEAR checkbox above to compare this years data, with last years. Additionally, you can choose to display comparison as actual number difference, or by percentage."
      >
        <caption className="sr-only">
          A view of the program data by Course, with an option to compare data for current year and
          previous year.
        </caption>
        <thead>
          <tr role="row">
            <th scope="col" className={style['courses-column']} role="columnheader">
              Courses
            </th>
            <th scope="col" className={style['compare-column']} role="columnheader">
              AP Students
            </th>
            <th
              scope="col"
              className={style['stat-column']}
              role="columnheader"
              aria-hidden={!compare}
            >
              <span className="sr-only">Compare Students to Last Year</span>
            </th>
            <th scope="col" className={style['compare-column']} role="columnheader">
              Exams
            </th>
            <th
              scope="col"
              className={style['stat-column']}
              role="columnheader"
              aria-hidden={!compare}
            >
              <span className="sr-only">Compare Exams to Last Year</span>
            </th>
            <th scope="col" className={style['special-column']} role="columnheader">
              Special Accommodations
            </th>
          </tr>
        </thead>
        <tbody>
          {currentSummaryByCourse.map((course, i) => {
            const {
              courseName,
              testCd,
              statistics: { numberStudentsEnrolled, numberExams, numberSpecialAccomodations },
            } = course
            return (
              <tr key={i}>
                <th scope="row" className={style['courses-column']}>
                  {courseName}
                </th>
                <td className={style['compare-column']}>{numberFormat(numberStudentsEnrolled)}</td>
                <td className={style['stat-column']} aria-hidden={!compare}>
                  {calculateComparison(
                    testCd,
                    numberStudentsEnrolled,
                    'numberStudentsEnrolled',
                    numberFormat
                  )}
                </td>
                <td className={style['compare-column']}>{numberFormat(numberExams)}</td>
                <td className={style['stat-column']} aria-hidden={!compare}>
                  {calculateComparison(testCd, numberExams, 'numberExams', numberFormat)}
                </td>
                <td className={style['special-column']}>
                  {numberFormat(numberSpecialAccomodations)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

ProgramStatistics.displayName = 'ProgramStatistics'

export default connect(mapStateToProps, { fetchProgramStats })(ProgramStatistics)
