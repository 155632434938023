import { useHistory, useLocation } from 'react-router-dom'
import { Modal, Error } from '../../components/common'
import { deleteSection } from '../../actions/section'
import { DELETE_SECTION_ENROLLED_ERROR } from '../../constants/MessageConstants'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = (state, ownProps) => ({
  section: state.courses.entities.sections[ownProps.sectionId] || {},
})

const SectionDeleteModal = ({
  section: { courseName, sectionName, error, enrollmentCount, fetching },
  section,
  modalCloseFocusElem,
  onCloseAction,
  deleteSection,
}) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const hasEnrollments = enrollmentCount && enrollmentCount > 0

  const onSuccess = () => {
    if (pathname !== NAV_LINKS.courses) history.replace(`${NAV_LINKS.courses}${search}`)
  }

  const footerActions = () => {
    if (hasEnrollments) {
      return [{ buttonLabel: 'Close', isDismissable: true, isPrimary: true }]
    }

    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: 'Delete',
        isPrimary: true,
        onClick: () => deleteSection(section).then(() => onSuccess()),
        isDisabled: fetching,
        busy: fetching,
      },
    ]
  }

  return (
    <Modal
      shouldCloseModal={!Object.keys(section).length}
      modalCloseFocusElem={modalCloseFocusElem}
      onCloseAction={onCloseAction}
      headerTitle={hasEnrollments ? 'Cannot Delete' : `${courseName} - Delete Section`}
      footerActions={footerActions()}
    >
      {hasEnrollments ? (
        <p>{DELETE_SECTION_ENROLLED_ERROR}</p>
      ) : (
        <div>
          {error ? <Error message={error} /> : null}
          <p>
            Are you sure you want to delete {courseName} - {sectionName}?
          </p>
        </div>
      )}
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  { deleteSection }
)(SectionDeleteModal)
