import packingListStyle from '../../assets/style/scss/packing-list.scss'
import Footer from './PackingListFooter'

export default props => {
  const { heading, progressBarText, footerObj, totalCount, progressedCount } = props
  const fillerWidth = (progressedCount / totalCount) * 100
  return (
    <div className={packingListStyle['detailed-container']}>
      <h3>{heading}</h3>
      <p style={{ marginBottom: '5px' }}>{progressedCount} of {totalCount} {progressBarText}</p>
      <div className="progress">
        <div className="progress-bar" role="progressbar" aria-valuenow={fillerWidth} aria-valuemin="0" aria-valuemax={totalCount} style={{ width: `${fillerWidth}%` }}>
          <span className="sr-only">{fillerWidth}% Complete</span>
        </div>
      </div>
      <Footer footerObj={footerObj} />
    </div>
  )
}
