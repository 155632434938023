import {
  Text,
  Input,
  ConfirmEmailFields,
  PhoneValidation,
  LimitedStringValidation,
  LetterValidation,
} from '../../common'
import {
  SCHOOL_INFORMATION_FIELDS as FIELDS,
  PROFDEV_TEXT,
  HOMESCHOOL_TEXT,
} from '../../../constants/SetupConstants'
import { DOMESTIC_PHONE_LENGTH, INTERNATIONAL_PHONE_LENGTH } from '../../../constants/SettingsConstants'
import { convertToNumberString } from '../../../utils/numbers'
import PhoneNumber from '../../common/forms/PhoneNumber'

export default ({ hideTitle, contactInformation, countryCode }) => {
  const isDomestic = countryCode === 'US'
  return (
    <fieldset>
      {!hideTitle ? (
        <legend className="h2" style={{ border: 0 }}>
          AP Coordinator
        </legend>
      ) : null}
      <div className="row">
        <div className="col-xs-5">
          <Text
            label="First Name"
            name={FIELDS.COORD_FNAME.name}
            placeholder="Enter first name"
            maxlength={20}
            isRequired={FIELDS.COORD_FNAME.required}
            validate={[LimitedStringValidation]}
          />
        </div>
        <div className="col-xs-2">
          <Text
            label="MI"
            name={FIELDS.COORD_MI.name}
            placeholder=" "
            maxlength={1}
            isRequired={FIELDS.COORD_MI.required}
            validate={[LetterValidation]}
          />
        </div>
        <div className="col-xs-5">
          <Text
            label="Last Name"
            name={FIELDS.COORD_LNAME.name}
            placeholder="Enter last name"
            maxlength={30}
            isRequired={FIELDS.COORD_LNAME.required}
            validate={[LimitedStringValidation]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-5">
          <PhoneNumber
            label="Telephone"
            name={FIELDS.COORD_PHONE.name}
            placeholder={isDomestic ? 'Enter a 10 digit number' : 'Enter telephone number'}
            isRequired={FIELDS.COORD_PHONE.required}
            validate={[PhoneValidation]}
            shouldFormat={true}
            countryCode={countryCode}
            normalize={convertToNumberString}
            maxlength={isDomestic ? DOMESTIC_PHONE_LENGTH : INTERNATIONAL_PHONE_LENGTH}
          />
        </div>
        <div className="col-xs-5">
          <PhoneNumber
            label="Fax"
            name={FIELDS.COORD_FAX.name}
            placeholder={isDomestic ? 'Enter a 10 digit number' : 'Enter fax number'}
            isRequired={FIELDS.COORD_FAX.required}
            validate={[PhoneValidation]}
            shouldFormat={true}
            countryCode={countryCode}
            normalize={convertToNumberString}
            maxlength={isDomestic ? DOMESTIC_PHONE_LENGTH : INTERNATIONAL_PHONE_LENGTH}
          />
        </div>
      </div>
      <ConfirmEmailFields
        label="Email"
        emailProps={FIELDS.COORD_EMAIL}
        commonPlaceholder="email address"
        showConfirmErrorOnLoad={Boolean(contactInformation?.email)}
        maxlength={50}
      />
      <fieldset>
        <legend style={{ fontWeight: 700 }}>Share Contact Information (optional)</legend>
        <p>
          I give the College Board permission to share my AP coordinator&#39;s contact information
          with:
        </p>
        <div style={{ marginBottom: 10 }}>
          <Input
            type="checkbox"
            label={PROFDEV_TEXT}
            name={FIELDS.PROFDEV.name}
            isRequired={FIELDS.PROFDEV.required}
          />
        </div>
        <div className="form-group">
          <Input
            type="checkbox"
            label={HOMESCHOOL_TEXT}
            name={FIELDS.HOMESCH.name}
            isRequired={FIELDS.HOMESCH.required}
          />
        </div>
      </fieldset>
    </fieldset>
  )
}
