
export default ({courseId, numSections, sectionsVisible, toggleVisibility}) => {
  let sectionSuffix = numSections > 1 ? 's' : '';
  let visibilityIcon = sectionsVisible ? 'cb-glyph cb-up' : 'cb-glyph cb-down';

  if (numSections > 1) {
    return (
      <button type="button" className="btn btn-link" aria-expanded={sectionsVisible} onClick={toggleVisibility}>
        {numSections} Section{sectionSuffix} <i className={visibilityIcon} aria-hidden="true"></i>
      </button>
    )
  } else {
    return (<span className="no-sections"></span>);
  }
}
