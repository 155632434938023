import {
  PORTFOLIO_DESCRIPTION_MAPPING,
  PORTFOLIO_LABEL_MAPPING,
  PACKING_LIST_LABEL_MAPPING,
} from '../../constants/PackingListConstants'
import { BasicInput } from '../common'
import { updatePackingListItems } from '../../actions/packingList'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    packingListDetails,
  } = state
  return { orgId, educationPeriodCd, packingListDetails }
}

const PortfolioSubmission = ({
  orgId,
  educationPeriodCd,
  id,
  readOnly,
  value,
  testCd,
  courseName,
  packingListDetails,
  packingListItemId,
  updatePackingListItems,
}) => {
  const label = PORTFOLIO_LABEL_MAPPING[testCd] || PACKING_LIST_LABEL_MAPPING[id]
  const description =
    PORTFOLIO_DESCRIPTION_MAPPING[testCd] || `These have been submitted via ${courseName}`

  const handleChange = e => {
    if (!readOnly) {
      const payload = { [id]: e.target.checked }
      updatePackingListItems({
        orgId,
        educationPeriodCd,
        packingListDetails,
        packingListItemId,
        payload,
      })
    }
  }

  return (
    <div style={{ marginBottom: '24px' }}>
      <h4>
        {`${label}`} <span style={{ fontSize: '.8em', color: 'rgb(193, 49, 69)' }}>(Required)</span>
      </h4>
      <BasicInput
        type="checkbox"
        label={description}
        input={{
          name: `${id}_${packingListItemId}`,
          value,
          onChange: handleChange,
        }}
        isRequired={true}
        disabled={readOnly}
        checked={value}
      />
    </div>
  )
}

export default connect(mapStateToProps, { updatePackingListItems })(PortfolioSubmission)
