import { reduxForm, getFormValues } from 'redux-form'
import {
  SELECT_SPECIAL_DIGITAL_FORMATS_FORM,
  SDF_SORT_ORDER,
  SDF_MULTIDAY,
  PAPERFORMATS_PAPER,
  PAPERFORMATS_BRAILLE,
  MULTIDAY_ONLY2DAY,
  MULTIDAY_OVER2DAY,
  MULTIDAY_MULTIDAY,
  UPDATE_TYPE_DIGITAL_ACCOMMODATIONS,
} from '../../constants/StudentConstants'
import { FormGroup } from '../../components/students/specialdigitalformats'
import { isEmpty } from '../../utils/common'
import { updateStudentExamMaterials } from '../../actions/studentsByOrg'
import { Error } from '../../components/common'
import { flattenSpecialDigitalFormats } from '../../selectors/section'
import { DIGITAL_EXAM, HYBRID_EXAM } from '../../constants/SettingsConstants'
import { PAPERFORMATS_SORT_ORDER } from '../../constants/StudentConstants'

const mapStateToProps = (state, { exam }) => {
  // Used only to set the initial state of the digital accommodations controls
  const { specialDigitalFormats = {} } = exam

  const {
    settingsSpecialDigitalFormats: {
      ssdCategoryCdDescriptions = {},
      ssdCdDescriptions = {},
      multiDayEligibleSsdCds = [],
    },
    studentsByOrg: {
      accommodationsInfo: { accommodations = [] },
    },
  } = state
  return {
    exam,
    accommodationsInfo: accommodations,
    categories: ssdCategoryCdDescriptions,
    descriptions: ssdCdDescriptions,
    initialValues: specialDigitalFormats,
    selectedSpecialDigitalFormats:
      getFormValues(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state) || specialDigitalFormats,
    multiDayEligibleSsdCds,
  }
}

const SelectSpecialDigitalFormatsToBeOrderedForm = ({
  exam,
  accommodationsInfo,
  categories,
  descriptions,
  initialValues,
  selectedSpecialDigitalFormats,
  multiDayEligibleSsdCds,
  error,
  handleSubmit,
}) => {
  const groups = Object.keys(initialValues).sort(
    (a, b) => SDF_SORT_ORDER.indexOf(a) - SDF_SORT_ORDER.indexOf(b)
  )
  const flatSDF = flattenSpecialDigitalFormats(selectedSpecialDigitalFormats)
  const anyMultiDayEligibleSelected = flatSDF.some(
    item => multiDayEligibleSsdCds.includes(item[0]) && item[1] === true
  )
  return (
    <form onSubmit={handleSubmit}>
      {error && <Error message={error} />}

      {groups.map(category => (
        <FormGroup
          key={category}
          categoryTitle={categories[category]}
          categoryName={category}
          descriptions={descriptions}
          accommodations={selectedSpecialDigitalFormats[category]}
          anyMultiDayEligibleSelected={anyMultiDayEligibleSelected}
          isDigital={exam.examFormat === DIGITAL_EXAM}
          isHybrid={exam.examFormat === HYBRID_EXAM}
          testCd={exam.testCd}
          accommodationsInfo={accommodationsInfo}
        />
      ))}
    </form>
  )
}

export default connect(mapStateToProps, { updateStudentExamMaterials })(
  reduxForm({
    form: SELECT_SPECIAL_DIGITAL_FORMATS_FORM,
    onSubmit: (values, dispatch, props) => {
      const { exam, updateStudentExamMaterials } = props
      const { paperFormats = {}, ...remainingValues } = values // pull paperFormats out of values
      let multiDay = null
      let paperInd = null
      let brailleInd = null
      let paperFormatsSelected = {}
      // Process paperFormats separately before flattening values to avoid overlaps with same keys
      // in other sections.
      Object.entries(paperFormats).forEach(([key, value]) => {
        switch (key) {
          case PAPERFORMATS_PAPER:
            paperInd = value
            break

          case PAPERFORMATS_BRAILLE:
            brailleInd = value
            break

          default:
            paperFormatsSelected[key] = value
        }
      })
      const flattenedValues = flattenSpecialDigitalFormats(remainingValues)
      const optedOutDigitalAccommodationCds = []
      flattenedValues.forEach(item => {
        const [key, value] = item
        switch (key) {
          case MULTIDAY_ONLY2DAY:
            if (value) {
              multiDay = '2D'
            }
            break

          case MULTIDAY_OVER2DAY:
            if (value) {
              multiDay = 'O2D'
            }
            break

          case MULTIDAY_MULTIDAY:
            if (value) {
              multiDay = 'MD'
            }
            break

          default:
            if (value === false) {
              optedOutDigitalAccommodationCds.push(key)
            }
        }
      })
      const processedValues = {
        multiDay,
        optedOutDigitalAccommodationCds,
        paperInd,
        brailleInd,
        ...(Object.keys(paperFormatsSelected).length > 0
          ? { paperFormats: { ...paperFormatsSelected } }
          : {}),
      }
      return updateStudentExamMaterials(exam, UPDATE_TYPE_DIGITAL_ACCOMMODATIONS, processedValues)
    },
    onChange: (values, dispatch, props) => {
      if (props.error || !isEmpty(props.submitErrors)) {
        props.clearSubmitErrors(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)
      }
    },
  })(SelectSpecialDigitalFormatsToBeOrderedForm)
)
