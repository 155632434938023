import { useLocation } from 'react-router-dom'
import { useSubscription } from '@apollo/client'
import { COORINDATOR_EXAM_READINESS_SUBSCRIPTION } from '../../../queries/subscriptions'
import { InfiniteScrollTable } from '../../common'
import CoordinatorExamFilters from '../../filters/exam/CoordinatorExamFilters'
import CoordinatorBanner from './CoordinatorBanner'
import DownloadButton from './DownloadButton'
import StudentSearchFilter from '../../filters/common/StudentNameIDSearch'
import * as Columns from '../common/Columns'
import { TABLE_KEYS } from '../../../constants/ExamReadinessConstants'
import {
  filterExamReadinessData,
  sortExamReadinessData,
  getExamValues,
} from '../../../selectors/examReadiness'
import { getAppsyncClient } from '../common/appsync'
import { updateExamReadinessStatus } from '../../../actions/examReadiness'
import { getFilters } from '../../../actions/filters'

import styles from '../../../assets/style/scss/exam-readiness.scss'
import CoordinatorMakeupColumn from './CoordinatorMakeupColumn'

const CoordinatorExamReadiness = ({
  examObj = {},
  orgId,
  educationPeriodCd,
  realTimeUpdatesDisabledMsg,
  updateExamReadinessStatus,
}) => {
  const { search } = useLocation()
  const activeFilters = getFilters(search)
  const exams = getExamValues(examObj)
  const visibleExams = filterExamReadinessData(activeFilters, exams)
  const hasData = exams.length
  const visibleCount = visibleExams.length
  const commonColumnProps = { flexShrink: 0, flexGrow: 1, sortable: true }

  if (!realTimeUpdatesDisabledMsg)
    useSubscription(COORINDATOR_EXAM_READINESS_SUBSCRIPTION, {
      client: getAppsyncClient(),
      variables: { orgId, educationPeriodCd },
      onSubscriptionData: updateExamReadinessStatus,
    })

  return (
    <>
      <CoordinatorBanner />
      <CoordinatorExamFilters visibleExams={visibleExams} activeFilters={activeFilters} />
      <div className="container" role="region" aria-live="polite" style={{ marginTop: 20 }}>
        {!hasData ? (
          <p style={{ marginTop: '2em' }}>There are no students registered to take exams.</p>
        ) : visibleCount ? (
          <>
            <div className={`${styles['makeup-note']} cb-digital-gray-bg`}>
              <strong>Note:</strong> Requests for makeup digital exams will be automatically
              approved 7 days before the makeup exam, unless the AP coordinator denies the
              request(s) in the Digital Exam Readiness dashboard.
            </div>
            <div className={styles['table-actions-legend']}>
              <div>
                <DownloadButton visibleExams={visibleExams} activeFilters={activeFilters} />
              </div>
              <div>
                <span>
                  <i className="cb-glyph cb-compose" aria-hidden={true} /> Paper Exam
                </span>
                <span>
                  <i className="cb-glyph cb-computer" aria-hidden={true} /> Digital Exam
                </span>
                <StudentSearchFilter />
              </div>
            </div>
            {realTimeUpdatesDisabledMsg ? (
              <p className="cb-error-msg" style={{ marginBottom: '.5em' }}>
                {realTimeUpdatesDisabledMsg}
              </p>
            ) : null}
            <InfiniteScrollTable
              data={visibleExams}
              defaultSort={TABLE_KEYS.date}
              sortFn={(students, sortBy, sortDirection) =>
                sortExamReadinessData(students, sortBy, sortDirection)
              }
              columns={[
                {
                  label: 'Student Name',
                  id: 'studentColumn',
                  dataKey: TABLE_KEYS.student,
                  cellRenderer: Columns.StudentName,
                  ...commonColumnProps,
                  width: 220,
                  flexShrink: 1,
                },
                {
                  label: 'Course Name',
                  id: 'courseColumn',
                  dataKey: TABLE_KEYS.course,
                  cellRenderer: Columns.CourseName,
                  ...commonColumnProps,
                  width: 235,
                  flexShrink: 1,
                },
                {
                  label: <Columns.TooltipHeader column={TABLE_KEYS.date} />,
                  id: 'examDateColumn',
                  dataKey: TABLE_KEYS.date,
                  cellRenderer: Columns.ExamDate,
                  width: 155,
                  ...commonColumnProps,
                },
                {
                  label: <Columns.TooltipHeader column={TABLE_KEYS.install} />,
                  id: 'loggedInColumn',
                  dataKey: TABLE_KEYS.install,
                  cellRenderer: Columns.Login,
                  width: 97,
                  ...commonColumnProps,
                },
                {
                  label: <Columns.TooltipHeader column={TABLE_KEYS.setup} />,
                  id: 'setupColumn',
                  dataKey: TABLE_KEYS.setup,
                  cellRenderer: Columns.Setup,
                  width: 132,
                  ...commonColumnProps,
                },
                {
                  label: <Columns.TooltipHeader column={TABLE_KEYS.exam} />,
                  id: 'examStatusColumn',
                  dataKey: TABLE_KEYS.exam,
                  cellRenderer: props => <Columns.Exam {...props} showCheckinStatus={true} />,
                  width: 185,
                  ...commonColumnProps,
                },
                {
                  label: <Columns.TooltipHeader column={TABLE_KEYS.makeup} />,
                  id: 'makeupColumn',
                  dataKey: TABLE_KEYS.makeup,
                  cellRenderer: CoordinatorMakeupColumn,
                  width: 117,
                  ...commonColumnProps,
                },
              ]}
            />
          </>
        ) : null}
      </div>
    </>
  )
}

export default connect(
  state => ({
    orgId: state.user.data.selectedOrgId,
    educationPeriodCd: state.settingsEducationPeriod.selectedEducationPeriod,
    examObj: state.examReadiness.examObj,
  }),
  { updateExamReadinessStatus }
)(CoordinatorExamReadiness)
