import { SubmissionError } from 'redux-form'
import * as c from '../constants/SectionConstants'
import { UPDATE_STATUS_SUMMARY } from '../constants/StatusConstants'
import {
  CREATE_SECTION_ERROR,
  EDIT_SECTION_ERROR,
  UPDATE_SECTION_JOINCODE_ERROR,
  SECTION_TRANSFERCODE_ERROR,
  DELETE_SECTION_ERROR,
  CONFIRM_SECTION_EXAM_DECISION_ERROR,
} from '../constants/MessageConstants'
import { normalizeSection } from '../utils/normalize'
import axios, { dispatchNormalizedError, getServerErrorMessage } from '../utils/axios'
import { setSelectedRole } from './user'

export const processSectionData = data => {
  const { isFallSemester, sectionType, ...sectionData } = data
  return {
    ...sectionData,
    ...(isFallSemester
      ? { sectionType: c.CREATE_FORM_FIRST_SEMESTER_VALUE }
      : sectionType === c.CREATE_FORM_FIRST_SEMESTER_VALUE
      ? { sectionType: c.COURSE_SCHEDULE_TYPES.S_FY.value, isFallSemester: true }
      : { sectionType }),
  }
}

export const createOrEditSection = data => {
  const { sectionId } = data
  const requestInfo = sectionId
    ? {
        method: 'put',
        type: c.EDIT_SECTION_FULFILLED,
        url: `/${sectionId}`,
        err: EDIT_SECTION_ERROR,
      }
    : { method: 'post', type: c.CREATE_SECTION_FULFILLED, url: '', err: CREATE_SECTION_ERROR }
  const teacherField = c.CREATE_FORM_FIELDS.teachers
  const modifiedData = { ...data, [teacherField]: data[teacherField].filter(t => t && t.teacherId) }

  return (dispatch, getState) => {
    const config = {
      method: requestInfo.method,
      url: `${Config.API_URL}/section${requestInfo.url}`,
      data: processSectionData(modifiedData),
    }
    const { selectedOrgId } = getState().user.data

    return axios
      .request(config)
      .then(({ data }) => {
        if (data.orgId !== selectedOrgId) {
          dispatch(setSelectedRole(data.orgId))
        } else {
          dispatch({ type: requestInfo.type, payload: normalizeSection(processSectionData(data)) })
          dispatch({ type: UPDATE_STATUS_SUMMARY })
        }
      })
      .catch(res => {
        throw new SubmissionError({ _error: getServerErrorMessage(res) })
      })
  }
}

export const updateJoinCode = section => dispatch => {
  const { sectionId } = section

  dispatch({
    type: c.UPDATE_SECTION_JOINCODE_PENDING,
    payload: normalizeSection({ ...section, fetching: true, fetched: false }),
  })

  return axios
    .post(`${Config.API_URL}/section/${sectionId}/joincode`, {})
    .then(({ data }) =>
      dispatch({
        type: c.UPDATE_SECTION_JOINCODE_FULFILLED,
        payload: normalizeSection({ ...processSectionData(data), fetching: false, fetched: true }),
      })
    )
    .catch(
      dispatchNormalizedError.bind(
        this,
        dispatch,
        c.UPDATE_SECTION_JOINCODE_REJECTED,
        normalizeSection,
        { ...section, error: UPDATE_SECTION_JOINCODE_ERROR }
      )
    )
}

export const generateTransferCode = section => dispatch => {
  const { sectionId } = section

  dispatch({
    type: c.GENERATE_SECTION_TRANSFERCODE_PENDING,
    payload: normalizeSection({ ...section, fetching: true, fetched: false }),
  })

  return axios
    .post(`${Config.API_URL}/section/${sectionId}/transfercode`, {})
    .then(({ data }) =>
      dispatch({
        type: c.GENERATE_SECTION_TRANSFERCODE_FULFILLED,
        payload: normalizeSection({ ...processSectionData(data), fetching: false, fetched: true }),
      })
    )
    .catch(
      dispatchNormalizedError.bind(
        this,
        dispatch,
        c.GENERATE_SECTION_TRANSFERCODE_REJECTED,
        normalizeSection,
        { ...section, error: SECTION_TRANSFERCODE_ERROR }
      )
    )
}

export const deleteSection = section => dispatch => {
  const { sectionId } = section

  dispatch({
    type: c.DELETE_SECTION_PENDING,
    payload: normalizeSection({ ...section, fetching: true }),
  })

  return axios
    .delete(`${Config.API_URL}/section/${sectionId}`, {})
    .then(deleteSuccess => {
      dispatch({ type: c.DELETE_SECTION_FULFILLED, payload: section })
      dispatch({ type: UPDATE_STATUS_SUMMARY })
      return deleteSuccess
    })
    .catch(
      dispatchNormalizedError.bind(this, dispatch, c.DELETE_SECTION_REJECTED, normalizeSection, {
        ...section,
        error: DELETE_SECTION_ERROR,
      })
    )
}

export const fetchSection = sectionId => dispatch => {
  dispatch({ type: c.FETCH_SINGLE_SECTION_PENDING })
  return axios
    .get(`${Config.API_URL}/section/${sectionId}`)
    .then(res => dispatch({ type: c.FETCH_SINGLE_SECTION_FULFILLED, payload: res.data }))
    .catch(err =>
      dispatch({ type: c.FETCH_SINGLE_SECTION_REJECTED, payload: getServerErrorMessage(err) })
    )
}

// currently unused, think about removing...
export const fetchSectionToCourses = async (state, { toSection }) => {
  try {
    const newSection =
      state.courses.entities.sections[toSection.sectionId] ||
      (await axios.get(`${Config.API_URL}/section/${toSection.sectionId}`))
    return newSection
  } catch (err) {
    return getServerErrorMessage(err)
  }
}

export const confirmSectionExamDecision =
  (section, confirmExamDecision = true) =>
  dispatch => {
    dispatch({
      type: c.CONFIRM_SECTION_EXAM_DECISION_PENDING,
      payload: normalizeSection({ ...section, fetching: true }),
    })

    return axios
      .post(`${Config.API_URL}/section/${section.sectionId}/exam-decision-confirmation`, {
        confirmExamDecision,
      })
      .then(({ data }) =>
        dispatch({
          type: c.CONFIRM_SECTION_EXAM_DECISION_FULFILLED,
          payload: normalizeSection(processSectionData(data)),
        })
      )
      .catch(
        dispatchNormalizedError.bind(
          this,
          dispatch,
          c.CONFIRM_SECTION_EXAM_DECISION_REJECTED,
          normalizeSection,
          { ...section, error: CONFIRM_SECTION_EXAM_DECISION_ERROR }
        )
      )
  }

export const expireAllJoinCodes = orgId => async dispatch => {
  dispatch({ type: c.EXPIRE_ALL_JOIN_CODES_PENDING })
  try {
    await axios.post(`${Config.API_URL}/section/${orgId}/expire-joincodes`)
    dispatch({ type: c.EXPIRE_ALL_JOIN_CODES_FULFILLED })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.EXPIRE_ALL_JOIN_CODES_REJECTED, payload: errMsg })
  }
}

export const fetchExpireJoinCodesStatus = orgId => async dispatch => {
  dispatch({ type: c.FETCH_EXPIRE_JOIN_CODES_STATUS_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/section/${orgId}/last-expired-joincodes-run-history`
    )
    dispatch({ type: c.FETCH_EXPIRE_JOIN_CODES_STATUS_FULFILLED, payload: data })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.FETCH_EXPIRE_JOIN_CODES_STATUS_REJECTED, payload: errMsg })
  }
}

export const resetExpireAllJoinCodes = () => dispatch =>
  dispatch({ type: c.RESET_EXPIRE_ALL_JOIN_CODES })
