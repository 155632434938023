import {
  AllAccessOrderTabs,
  RestrictedOrderTabs,
  OrderDetails,
  ProgramSummary,
} from './coordinator'

const mapStateToProps = state => ({ isAllAccess: !state.user.data.isLevelOne })

export default connect(mapStateToProps)(({ isAllAccess }) => {
  return (
    <>
      <h2 className="sr-only">Orders</h2>
      {isAllAccess ? (
        <div className="row">
          <div className="col-xs-12">
            <ProgramSummary />
          </div>
        </div>
      ) : null}
      <OrderDetails isAllAccess={isAllAccess} />
      {isAllAccess ? (
        <div className="row">
          <div className="col-xs-12">
            <AllAccessOrderTabs />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-xs-12">
            <RestrictedOrderTabs />
          </div>
        </div>
      )}
    </>
  )
})
