import { Modal, Error } from '../../components/common'
import { resetStudentUpdate } from '../../actions/studentsCommon'
import SelectNotTakingExamReasons from '../../components/students/common/SelectNotTakingExamReason'
import { UPDATE_TYPE_INTENT } from '../../constants/StudentConstants'

const mapStateToProps = (state, { storeLocation, updateId }) => {
  const {
    update: { id, updating, updated, type: updateType, error },
  } = state[storeLocation]
  const isActive = updateType === UPDATE_TYPE_INTENT && id === updateId
  return {
    updating: isActive && updating,
    updated: isActive && updated,
    error: isActive && error,
  }
}

const ExamIntentNotTakingReasonModal = ({
  exam,
  courseName,
  updating,
  updated,
  error,
  action,
  onCloseAction,
  modalCloseFocusElem,
  resetStudentUpdate,
}) => {
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const [notReasonObj, setNotReasonObj] = useState({})

  useEffect(() => {
    if (updated) {
      setShouldCloseModal(true)
    }

    return () => resetStudentUpdate()
  }, [updated])

  const updateNotReason = reason => setNotReasonObj(reason)

  const getFooterActions = () => {
    return [
      { buttonLabel: 'Cancel', isDismissable: true, isPrimary: false },
      {
        buttonLabel: 'Update',
        isPrimary: true,
        isDisabled: updating,
        busy: updating,
        onClick: () => action({ ...exam, ...notReasonObj }, UPDATE_TYPE_INTENT),
      },
    ]
  }

  return (
    <Modal
      headerTitle="Change Exam Order Status"
      shouldCloseModal={shouldCloseModal}
      onCloseAction={onCloseAction}
      modalCloseFocusElem={modalCloseFocusElem}
      footerActions={getFooterActions()}
    >
      {error ? <Error title="Error updated Student" message={error} /> : null}
      <p>
        You will not be ordering an <strong>{courseName}</strong> exam for this student.
      </p>
      <SelectNotTakingExamReasons onChangeAction={updateNotReason} />
    </Modal>
  )
}

export default connect(mapStateToProps, { resetStudentUpdate })(ExamIntentNotTakingReasonModal)
