import { Modal, Error, BasicInput } from '../../../common'
import { openModal } from '../../../../actions/app'
import { STUDENT_ENROLLMENT_STATUS_YES } from '../../../../constants/StudentConstants'

const mapStateToProps = state => ({ isSubmitted: state.status.data.isSubmitted })

class ExamIntentConflict extends Component {
  state = { checked: false }

  constructor(props) {
    super(props)
    this.getUpdatedExamIntent = this.getUpdatedExamIntent.bind(this)
  }

  onCloseAction() {
    const { onCloseAction, courseName, exam: { examConflictCourseName }, isSubmitted } = this.props
    if (isSubmitted)
      onCloseAction({
        swapCourseName: courseName,
        courseName: examConflictCourseName,
      })
  }

  getUpdatedExamIntent() {
    const { exam } = this.props
    return {
      ...exam,
      examIntent: STUDENT_ENROLLMENT_STATUS_YES,
      swapConflictEnrollmentExamIntent: true,
    }
  }

  render() {
    const { error, courseName, modalCloseFocusElem, shouldCloseModal, getFooterActions, exam: { examConflictCourseName } } = this.props
    const { checked } = this.state

    return (
      <Modal
        shouldCloseModal={shouldCloseModal}
        headerTitle="Changing an AP Calculus Exam Order"
        modalCloseFocusElem={modalCloseFocusElem}
        footerActions={getFooterActions(this.getUpdatedExamIntent, { actionBtnLabel: 'Save', disabled: !checked })}
      >
        {error ? <Error title="Error Saving Data" message={error} /> : null}

        <p>This student is already registered for the <strong>{examConflictCourseName}</strong> exam. You can switch this student from <strong>{examConflictCourseName}</strong> exam to an <strong>{courseName}</strong> exam without any additional fee, but a student cannot take both of the Calculus exams in the same year.</p>
        <p>To make the change, check the box below.</p>
        <BasicInput
          input={{
            name: 'switchExamIntent',
            onChange: () => this.setState({ checked: !checked }),
            value: checked,
          }}
          type="checkbox"
          label={<>Order the <strong>{courseName}</strong> exam for this student and drop the <strong>{examConflictCourseName}</strong> exam</>}
          checked={checked}
        />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, { openModal })(ExamIntentConflict)
