import { getFormValues, change } from 'redux-form'
import get from 'lodash-es/get'
import {
  Input,
  InlineText,
  PositiveNumberValidation,
  PositiveMaximumNumberValidation,
} from '../../common'
import {
  SCHOOL_INFORMATION_FORM,
  SCHOOL_INFORMATION_FIELDS as FIELDS,
} from '../../../constants/SetupConstants'

const mapStateToProps = state => {
  return {
    currentFormValues: getFormValues(SCHOOL_INFORMATION_FORM)(state) || {},
  }
}

const AvgCostPerExam = ({ currentFormValues, change }) => {
  const reducedFeeAvgCost = get(currentFormValues, FIELDS.REDUCED_FEE_AVG_COST.name)
  const reducedFeeAvgCostInd = get(
    currentFormValues,
    'schoolInformation.schoolInfo.reducedFeeAvgCostInd'
  )
  const standardFeeAvgCost = get(currentFormValues, FIELDS.STANDARD_FEE_AVG_COST.name)
  const standardFeeAvgCostInd = get(
    currentFormValues,
    'schoolInformation.schoolInfo.standardFeeAvgCostInd'
  )

  useEffect(() => {
    if (!reducedFeeAvgCostInd) {
      change(SCHOOL_INFORMATION_FORM, FIELDS.REDUCED_FEE_AVG_COST.name, 0)
    }
  }, [reducedFeeAvgCostInd, reducedFeeAvgCost])

  useEffect(() => {
    if (!standardFeeAvgCostInd) {
      change(SCHOOL_INFORMATION_FORM, FIELDS.STANDARD_FEE_AVG_COST.name, 0)
    }
  }, [standardFeeAvgCostInd, standardFeeAvgCost])

  return (
    <fieldset role="radiogroup" aria-labelledby="avg-cost-selection-group" aria-required="true">
      <legend id="avg-cost-selection-group" role="presentation">
        On average, how much do students pay for each AP Exam? If you don&#39;t know how much
        students will pay for this school year&#39;s AP administration, use the average per-exam
        amount from last year.
      </legend>
      <div className="row">
        <div className="col-xs-12">
          <p className="cb-required">
            Students who <strong>are</strong> eligible for the College Board fee reduction:
          </p>
          <InlineText
            controlInput={{
              type: 'radio',
              name: 'schoolInformation.schoolInfo.reducedFeeAvgCostInd',
              ariaDescribedBy: FIELDS.REDUCED_FEE_AVG_COST.name,
              normalize: val => val === 'true',
              srOnly: true,
              style: { display: 'inline-block' },
              value: true,
            }}
            name={FIELDS.REDUCED_FEE_AVG_COST.name}
            label="$"
            labelBeforeTextInput={true}
            placeholder="Enter fee"
            withFlexbox={false}
            value={reducedFeeAvgCost}
            disabled={reducedFeeAvgCostInd === null || reducedFeeAvgCostInd === false}
            validate={
              reducedFeeAvgCostInd
                ? [PositiveNumberValidation, PositiveMaximumNumberValidation]
                : []
            }
            inputStyles={{ width: '20px' }}
            normalize={val => (!Number.isNaN(parseInt(val, 10)) ? parseInt(val, 10) : val)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12" style={{ marginBottom: '48px' }}>
          <Input
            type="radio"
            name="schoolInformation.schoolInfo.reducedFeeAvgCostInd"
            label="Students who are eligible for the College Board fee reduction pay $0."
            value={false}
            normalize={val => val === 'true'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p className="cb-required">
            Students who <strong>are not</strong> eligible for the College Board fee reduction:
          </p>
          <InlineText
            controlInput={{
              type: 'radio',
              name: 'schoolInformation.schoolInfo.standardFeeAvgCostInd',
              ariaDescribedBy: FIELDS.STANDARD_FEE_AVG_COST.name,
              normalize: val => val === 'true',
              srOnly: true,
              style: { display: 'inline-block' },
              value: true,
            }}
            name={FIELDS.STANDARD_FEE_AVG_COST.name}
            label="$"
            labelBeforeTextInput={true}
            placeholder="Enter fee"
            withFlexbox={false}
            value={standardFeeAvgCost}
            disabled={standardFeeAvgCostInd === null || standardFeeAvgCostInd === false}
            validate={
              standardFeeAvgCostInd
                ? [PositiveNumberValidation, PositiveMaximumNumberValidation]
                : []
            }
            inputStyles={{ width: '20px' }}
            normalize={val => (!Number.isNaN(parseInt(val, 10)) ? parseInt(val, 10) : val)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12" style={{ marginBottom: '48px' }}>
          <Input
            type="radio"
            name="schoolInformation.schoolInfo.standardFeeAvgCostInd"
            label={
              <>Students who aren&#39;t eligible for the College Board fee reduction pay $0.</>
            }
            ariaLabel="Students who aren't eligible for the College Board fee reduction pay $0."
            value={false}
            normalize={val => val === 'true'}
          />
        </div>
      </div>
    </fieldset>
  )
}

export default connect(mapStateToProps, { change })(AvgCostPerExam)
