
const mapStateToProps = state => {
	const {settingsOrg, user: {data: {selectedOrgId}}} = state

	return { 
		signed: settingsOrg.allowedToOrder[selectedOrgId], 
		loading: settingsOrg[selectedOrgId] && settingsOrg[selectedOrgId].fetching
	}
}

export default connect(mapStateToProps)(({signed=false, loading}) => {
	return (
		<div>School Information and Participation Contacts
			{signed && !loading ?  
				<span className="success-text" style={{marginLeft: 10}}>
					<i className="cb-glyph cb-check" aria-hidden={true} /> Complete
				</span> : null}
			{!signed && !loading ?
				<span className="warning-text" style={{marginLeft: 10}}>
					<i className="cb-glyph cb-bell" aria-hidden={true} /> Incomplete
				</span> : null}
		</div>
	)
})

