import { change as changeFieldValue } from 'redux-form'
import { Input, Tooltip } from '../../common'
import { ConditionalMaterialField } from '../common/SsdUtils'
import {
  SELECT_SSD_MATERIALS_FORM,
  ALLOWED_SSD_MATERIALS,
  SSD_MATERIALS_FIELDS,
} from '../../../constants/StudentConstants'
import { isEmpty } from '../../../utils/common'

import styles from '../../../assets/style/scss/materials-modal.scss'

const readerCopyInfoMsg =
  'This is an extra copy of the exam booklets provided when a student will test with a reader.'
const printedScriptInfoMsg =
  'This is a printed script for the master audio part of AP Exams in French, German, Italian, or Spanish Language and Culture, or Spanish Literature and Culture, which may be used by a student who is deaf or hard of hearing.'

class ReaderMaterials extends Component {
  componentDidUpdate(prevProps) {
    const { selectedSSDMaterials, changeFieldValue } = this.props
    const selectedFontSize = selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name]
    const isFontSizeBraille = selectedFontSize === SSD_MATERIALS_FIELDS.braille.value
    const wasBrailleSelected =
      !isEmpty(prevProps.selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name]) &&
      prevProps.selectedSSDMaterials[SSD_MATERIALS_FIELDS.fontSize.name] ===
        SSD_MATERIALS_FIELDS.braille.value

    // If Braille has been selected in Special Formats, must check and disable Reader Copy option
    if (!wasBrailleSelected && isFontSizeBraille) {
      if (selectedSSDMaterials.readerMaterialCheck === false)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, 'readerMaterialCheck', true)
      if (selectedSSDMaterials[SSD_MATERIALS_FIELDS.readerCopy.name] === false)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, SSD_MATERIALS_FIELDS.readerCopy.name, true)
    }

    // If Braille has been de-selected in Special Formats, must uncheck and re-enable Reader Materials section check
    if (wasBrailleSelected && !isFontSizeBraille) {
      // Only de-select the Reader Materials check if Printed Script is not already selected
      if (selectedSSDMaterials[SSD_MATERIALS_FIELDS.printedScript.name] !== true) {
        if (selectedSSDMaterials.readerMaterialCheck === true)
          changeFieldValue(SELECT_SSD_MATERIALS_FORM, 'readerMaterialCheck', false)
      } else if (selectedSSDMaterials[SSD_MATERIALS_FIELDS.readerCopy.name] === true)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, SSD_MATERIALS_FIELDS.readerCopy.name, false)
    }

    // if this group is being toggled off, empty the fields
    if (!selectedSSDMaterials.readerMaterialCheck) {
      if (selectedSSDMaterials[SSD_MATERIALS_FIELDS.readerCopy.name] !== false)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, SSD_MATERIALS_FIELDS.readerCopy.name, false)
      if (selectedSSDMaterials[SSD_MATERIALS_FIELDS.printedScript.name] !== false)
        changeFieldValue(SELECT_SSD_MATERIALS_FORM, SSD_MATERIALS_FIELDS.printedScript.name, false)
    }
  }

  render() {
    const { metaData, allowedSSDMaterialCodes, selectedSSDMaterials } = this.props
    const inlineBlock = { display: 'inline-block' }
    const iconStyles = {
      display: 'inline-block',
      color: 'rgb(0, 119, 200)',
      fontSize: '8px',
    }

    return (
      <div name="reader-materials-group" className={styles['materials-fieldset']}>
        <Input
          type="checkbox"
          name="readerMaterialCheck"
          labelId="readerMaterialsGroup"
          label="Reader Materials"
          disabled={
            selectedSSDMaterials[SSD_MATERIALS_FIELDS.braille.name] ===
            SSD_MATERIALS_FIELDS.braille.value
          }
        />
        <div
          role="group"
          aria-labelledby="readerMaterialsGroup"
          className={styles['materials-radiogroup']}
        >
          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.readerCopys}
            fieldId={SSD_MATERIALS_FIELDS.readerCopy.value}
          >
            <div className={styles['material-radiogroup-wrap']}>
              <Input
                type="checkbox"
                name={SSD_MATERIALS_FIELDS.readerCopy.name}
                label={metaData[SSD_MATERIALS_FIELDS.readerCopy.value].description}
                normalize={value => !!value}
                disabled={
                  !selectedSSDMaterials.readerMaterialCheck ||
                  selectedSSDMaterials[SSD_MATERIALS_FIELDS.braille.name] ===
                    SSD_MATERIALS_FIELDS.braille.value
                }
                style={inlineBlock}
              />{' '}
              <div style={inlineBlock}>
                <Tooltip
                  title={readerCopyInfoMsg}
                  placement="top"
                  container="body"
                  label="More information: Reader Copy"
                >
                  <i
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    aria-hidden="true"
                    style={iconStyles}
                    data-alt={readerCopyInfoMsg}
                  />
                </Tooltip>
              </div>
            </div>
          </ConditionalMaterialField>
          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.printedScripts}
            fieldId={SSD_MATERIALS_FIELDS.printedScript.value}
          >
            <div className={styles['material-radiogroup-wrap']}>
              <Input
                type="checkbox"
                name={SSD_MATERIALS_FIELDS.printedScript.name}
                label={metaData[SSD_MATERIALS_FIELDS.printedScript.value].description}
                normalize={value => !!value}
                disabled={!selectedSSDMaterials.readerMaterialCheck}
                style={inlineBlock}
              />{' '}
              <div style={inlineBlock}>
                <Tooltip
                  title={printedScriptInfoMsg}
                  placement="top"
                  container="body"
                  label="More information: Printed Script"
                >
                  <i
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    aria-hidden="true"
                    style={iconStyles}
                    data-alt={printedScriptInfoMsg}
                  />
                </Tooltip>
              </div>
            </div>
          </ConditionalMaterialField>
        </div>
      </div>
    )
  }
}

export default connect(null, { changeFieldValue })(ReaderMaterials)
