import * as c from '../constants/FundingConstants'
import axios, { getServerErrorMessage } from '../utils/axios'

export const changeStep = step => dispatch => {
  dispatch({ type: c.SET_FUNDING_STEP, payload: step })
}

export const fetchDistrictFunding = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_DISTRICT_FUNDING_DATA_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/district-funding/organizations/${orgId}/education-periods/${educationPeriodCd}`
    )
    dispatch({ type: c.FETCH_DISTRICT_FUNDING_DATA_FULFILLED, payload: data })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.FETCH_DISTRICT_FUNDING_DATA_REJECTED, payload: errMsg })
  }
}

export const fetchStateFundingAndPricing = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_STATE_FUNDING_AND_PRICING_PENDING })
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/paapro/v1/district-funding/organizations/${orgId}/education-periods/${educationPeriodCd}/exam-pricing-summary`
    )
    dispatch({ type: c.FETCH_STATE_FUNDING_AND_PRICING_FULFILLED, payload: data })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.FETCH_STATE_FUNDING_AND_PRICING_REJECTED, payload: errMsg })
  }
}

export const saveDistrictFunding = (orgId, educationPeriodCd, data) => async dispatch => {
  dispatch({ type: c.SAVE_DISTRICT_FUNDING_DATA_PENDING })
  try {
    await axios.put(
      `${Config.API_URL}/paapro/v1/district-funding/organizations/${orgId}/education-periods/${educationPeriodCd}`,
      data
    )
    dispatch({ type: c.SAVE_DISTRICT_FUNDING_DATA_FULFILLED })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.SAVE_DISTRICT_FUNDING_DATA_REJECTED, payload: errMsg })
  }
}

export const fetchChildOrgs = orgId => async dispatch => {
  dispatch({ type: c.FETCH_CHILD_ORGS_PENDING })
  try {
    const { data } = await axios.get(`${Config.API_URL}/organizations/${orgId}/child-orgs`)
    dispatch({ type: c.FETCH_CHILD_ORGS_FULFILLED, payload: data })
  } catch (err) {
    const errMsg = getServerErrorMessage(err)
    dispatch({ type: c.FETCH_CHILD_ORGS_REJECTED, payload: errMsg })
  }
}

export const fetchDistrictFundingLockDate =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    try {
      dispatch({ type: c.FETCH_DISTRICT_FUNDING_LOCK_DATE_PENDING })
      const { data } = await axios.get(
        `${Config.API_URL}/paapro/v1/district-funding/organizations/${orgId}/education-periods/${educationPeriodCd}/lock-status`
      )
      dispatch({
        type: c.FETCH_DISTRICT_FUNDING_LOCK_DATE_FULFILLED,
        payload: data,
      })
    } catch (err) {
      const msg = getServerErrorMessage(err)
      dispatch({ type: c.FETCH_DISTRICT_FUNDING_LOCK_DATE_REJECTED, payload: msg })
    }
  }

export const deleteDistrictFunding =
  ({ orgId, educationPeriodCd, data }) =>
  async dispatch => {
    dispatch({ type: c.DELETE_DISTRICT_FUNDING_PENDING })
    try {
      await axios.delete(
        `${Config.API_URL}/paapro/v1/district-funding/organizations/${orgId}/education-periods/${educationPeriodCd}`,
        { data }
      )
      dispatch({ type: c.DELETE_DISTRICT_FUNDING_FULFILLED })
    } catch (err) {
      dispatch({ type: c.DELETE_DISTRICT_FUNDING_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const resetDistrictFundingData = () => dispatch => {
  dispatch({ type: c.RESET_DISTRICT_FUNDING_DATA })
}

export const setDistrictFundingContactInfoData = data => dispatch => {
  dispatch({ type: c.SET_DISTRICT_FUNDING_CONTACT_INFO_DATA, payload: data })
}

export const setDistrictFundingSchoolSelectionData = data => dispatch => {
  dispatch({ type: c.SET_DISTRICT_FUNDING_SCHOOL_SELECTION_DATA, payload: data })
}

export const setDistrictFundingTypeData = data => dispatch => {
  dispatch({ type: c.SET_DISTRICT_FUNDING_TYPE_DATA, payload: data })
}

export const setDistrictFundingSubsidyData = data => dispatch => {
  dispatch({ type: c.SET_DISTRICT_FUNDING_SUBSIDY_DATA, payload: data })
}

export const setSavedDistrictFundingData = data => dispatch => {
  dispatch({ type: c.SET_SAVED_DISTRICT_FUNDING_DATA, payload: data })
}
