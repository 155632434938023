import { formValueSelector } from 'redux-form'
import { Input, Tooltip } from '../common'
import { FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME } from '../../constants/FundingConstants'
import styles from '../../assets/style/scss/funding.scss'

const mapStateToProps = (
  state,
  { openByDefault, cancellationFeesField, lateOrderFeesField, lateTestingFeesField }
) => {
  const selector = formValueSelector(FUNDING_SUBSIDY_CONFIGURATION_FORM_NAME)
  const isCancellationChecked = selector(state, cancellationFeesField)
  const isLateTestingChecked = selector(state, lateOrderFeesField)
  const isLateOrderChecked = selector(state, lateTestingFeesField)
  return {
    openByDefault:
      openByDefault || isCancellationChecked || isLateTestingChecked || isLateOrderChecked,
    isCancellationChecked,
    isLateTestingChecked,
    isLateOrderChecked,
  }
}

const DistrictFundingOtherFees = ({
  mustTakeField,
  cancellationFeesField,
  lateOrderFeesField,
  lateTestingFeesField,
  hasStateFunding,
  disabled,
  openByDefault,
  isCancellationChecked,
  isLateTestingChecked,
  isLateOrderChecked,
}) => {
  const [showOptions, setShowOptions] = useState(false)
  useEffect(() => {
    if (disabled && !openByDefault) {
      setShowOptions(false)
    }
  }, [disabled, openByDefault])

  useEffect(() => {
    if (openByDefault) {
      setShowOptions(true)
    }
  }, [openByDefault])

  return (
    <div className={styles['other-fees']}>
      {!hasStateFunding ? (
        <Input
          type="checkbox"
          label="Students must take the course associated with the AP exam to receive funding"
          name={mustTakeField}
          style={{ marginBottom: '12px' }}
          disabled={disabled}
        />
      ) : null}
      <button
        type="button"
        className="btn-link"
        onClick={() => setShowOptions(!showOptions)}
        disabled={disabled}
      >
        Other fees to be funded <span className={`cb-glyph ${showOptions ? 'cb-up' : 'cb-down'}`} />
      </button>
      <div
        className={styles['other-fees-container']}
        style={{ display: showOptions ? 'block' : 'none' }}
      >
        <Input
          type="checkbox"
          name={cancellationFeesField}
          checked={isCancellationChecked}
          label={
            <>
              Unused/Cancellation Fees{' '}
              <div style={{ display: 'inline-block' }}>
                <Tooltip
                  title="Fee ($40 per exam) that is assessed when orders are cancelled after the final ordering deadline."
                  placement="top"
                  container="body"
                  label="More information: Unused/Cancellation Fees"
                >
                  <i
                    aria-hidden="true"
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    style={{ display: 'inline-block', fontSize: '8px' }}
                    data-alt="Fee ($40 per exam) that is assessed when orders are placed after the final ordering deadline."
                  />
                </Tooltip>
              </div>
            </>
          }
          disabled={disabled}
        />
        <Input
          type="checkbox"
          name={lateOrderFeesField}
          checked={isLateOrderChecked}
          label={
            <>
              Late Order Fees{' '}
              <div style={{ display: 'inline-block' }}>
                <Tooltip
                  title="Fee ($40 per exam) that is assessed when orders are placed after the final ordering deadline."
                  placement="top"
                  container="body"
                  label="More information: Late Order Fees"
                >
                  <i
                    aria-hidden="true"
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    style={{ display: 'inline-block', fontSize: '8px' }}
                    data-alt="Fee ($40 per exam) that is assessed when orders are placed after the final ordering deadline."
                  />
                </Tooltip>
              </div>
            </>
          }
          disabled={disabled}
        />
        <Input
          type="checkbox"
          name={lateTestingFeesField}
          checked={isLateTestingChecked}
          label={
            <>
              Late-Testing Fees{' '}
              <div style={{ display: 'inline-block' }}>
                <Tooltip
                  title="Fee ($40 per exam) that is assessed for alternate exams for late testing that don&#39;t have an approved reason."
                  placement="top"
                  container="body"
                  label="More information: Late-Testing Fees"
                >
                  <i
                    aria-hidden="true"
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    style={{ display: 'inline-block', fontSize: '8px' }}
                    data-alt="Fee ($40 per exam) that is assessed for alternate exams for late testing that don&#39;t have an approved reason."
                  />
                </Tooltip>
              </div>
            </>
          }
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(DistrictFundingOtherFees)
