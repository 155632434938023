import { formatDate, formatDateTime, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { InitialExamOrderTable, ExamAdjustmentsTable, MaterialsAdjustmentsTable } from '.'
import { Accordions } from '../../common'
import { isEmpty } from '../../../utils/common'
import { sortColumnByKey } from '../../../utils/sort'

const mapStateToProps = state => state.ordersHistory

const GetTitle = ({ sectionTitle, submissionDate, submittedBy }) => (
  <div
    data-cbtrack-label={`${sectionTitle} ${formatDate(
      submissionDate,
      DATETIME_FORMATS.mediumMonthYear
    )}`}
  >
    <span style={{ fontWeight: 'bold' }}>{sectionTitle}</span>
    <span style={{ marginLeft: '60px', fontWeight: 'normal', fontSize: '.9rem', width: '200px' }}>
      {formatDateTime(submissionDate, DATETIME_FORMATS.longMonthDayYearTime)}
    </span>
    {submittedBy ? (
      <span style={{ marginLeft: '60px', fontWeight: 'normal', fontSize: '.9rem' }}>
        Submitted by {submittedBy}
      </span>
    ) : null}
  </div>
)

const InitialSubmissionTable = ({ examSummaries, allExamsSummary, materialSummaries }) => (
  <>
    <div className="row">
      <div className="col-xs-9">
        <InitialExamOrderTable
          data={examSummaries}
          aggregateRow={allExamsSummary}
          id="orderHistoryMain"
          caption="A view of the initial order submission"
          summary="Orders details are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers."
        />
      </div>
    </div>
    {materialSummaries?.length > 0 && (
      <div className="row">
        <div className="col-xs-12">
          <MaterialsAdjustmentsTable
            data={materialSummaries}
            id={`orderMaterialsAdjustmentsMain`}
            headerStyle={{ marginTop: '20px', marginBottom: '5px' }}
          />
        </div>
      </div>
    )}
  </>
)

// Will iterate over each of the orders generating an accordion that contains
// the Exam Adjustments table and Materials Adjustments table as needed for this order.
const OrderUpdates = additionalOrders =>
  additionalOrders.map((order, i) => ({
    panelId: order.apOrderId,
    PanelBody: () => (
      <>
        <ExamAdjustmentsTable
          data={order.examSummaries}
          aggregateRow={order.allExamsSummary}
          id={`orderExamUpdates${i}`}
          caption={`A view of the order submitted on ${order.submissionDate}`}
        />
        <MaterialsAdjustmentsTable
          data={order.materialSummaries}
          id={`orderMaterialsAdjustments${i}`}
          headerStyle={{ marginTop: '20px', marginBottom: '5px' }}
        />
      </>
    ),
    title: () => (
      <GetTitle
        sectionTitle="Order Update"
        submissionDate={order.submissionDate}
        submittedBy={order.submittedBy}
      />
    ),
    openByDefault: i === 0,
  }))

const ExamOrderHistoryWrapper = ({ content }) => (
  <div className="container">
    <h2>Exam Order History</h2>
    {content}
  </div>
)

export default connect(mapStateToProps)(({ mainOrder = {}, additionalOrders = [] }) => {
  // When there are order updates, we need to assemble all the accordion panels
  if (!isEmpty(additionalOrders)) {
    const mainPanel = {
      panelId: mainOrder.apOrderId,
      PanelBody: () => <InitialSubmissionTable {...mainOrder} />,
      title: () => (
        <GetTitle
          sectionTitle="Original Order"
          submissionDate={mainOrder.submissionDate}
          submittedBy={mainOrder.submittedBy}
        />
      ),
    }
    const orderUpdatePanels = OrderUpdates(
      sortColumnByKey(additionalOrders, 'submissionDate', 'desc')
    )

    return (
      <ExamOrderHistoryWrapper
        content={<Accordions id="historyAccordions" panels={[...orderUpdatePanels, mainPanel]} />}
      />
    )
  }
  // When we only have the initial order to display, we don't need any accordions
  const output = (
    <>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-xs-12">
          <GetTitle
            sectionTitle="Original Order"
            submissionDate={mainOrder.submissionDate}
            submittedBy={mainOrder.submittedBy}
          />
        </div>
      </div>
      <InitialSubmissionTable {...mainOrder} />
    </>
  )
  return <ExamOrderHistoryWrapper content={output} />
})
