import { useCatapultAuth } from '../../utils/hooks'
import { getMyAPUrl } from '../routes/utils'

export default () => {
  const { basicProfile } = useCatapultAuth()

  window.location.replace(getMyAPUrl(basicProfile))

  return null
}
