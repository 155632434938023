import { Link } from 'react-router-dom'
import { getExamWindowsForCourse } from '../../selectors/examWindows'
import { NAV_LINKS } from '../../constants/NavConstants'
import {
  EXAM_MATERIAL,
  EXAM,
  LISTENING_CDS,
  SPEAKING_CDS,
  SINGING_CDS,
  EXAM_CDS,
  PORTFOLIOS,
  STUDENTS,
  RESPONSES_RETURNED,
  PORTFOLIO_SUBMISSION,
  ONLINE_SUBMISSION,
  SUBMITTED,
  RETURNED_RESPONSE,
  BRAILLE_LARGE_FONT_PACKAGE_ENUM,
  NON_STOCK_PACKAGE_ENUM,
  STATUS_CONFIRMED,
  SUBMISSIONS,
  SSD_MATERIALS_SUBMISSION,
  PACKING_LIST_LABEL_MAPPING,
} from '../../constants/PackingListConstants'
import {
  COURSE_FILTER,
  ADMIN_FILTER,
  SSD_FILTER,
  FILTER_YES,
} from '../../constants/FilterConstants'

import ExamMaterialsReturned from './ExamMaterialsReturned'
import CourseMaterialsReturned from './CourseMaterialsReturned'
import SubmissionComponent from './SubmissionComponent'
import PortfolioSubmission from './PortfolioSubmission'
import ResponseReturned from './ResponseReturned'
import NonStockExamComponent from './NonStockExamComponent'

import packingStyles from '../../assets/style/scss/packing-details.scss'
import { Loader, Error } from '../common'
import { updatePackingListItems } from '../../actions/packingList'

const mapStateToProps = (state, ownProps) => {
  const {
    user: {
      data: { isCSR, selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    packingListDetails,
  } = state
  const {
    packingListItems,
    status,
    examType,
    testDayType,
    updatePackingListItem: { packingListItemId: IdInProgress, updating, error },
  } = packingListDetails
  const packingListItem = packingListItems.find(
    p => p.packingListItemId === ownProps.packingListItemId
  )
  const examWindows = getExamWindowsForCourse(state, packingListItem.testCd)
  // This will be unreliable for any testAdmin with multiple windows like OFFCYCLE
  const { adminWindow } =
    Object.values(examWindows).find(examWindow => examWindow.testAdmin === testDayType) ?? {}
  return {
    orgId,
    educationPeriodCd,
    isCSR,
    packingListDetails,
    examType,
    packingListItem,
    readOnly: status === SUBMITTED,
    updating,
    error,
    IdInProgress,
    adminWindow,
  }
}

/**
 * Route to student's page with filters.
 */
const RouteToUnused = ({ testCd, adminWindow, isSSD }) => {
  const SSDfilter = (isSSD && `&${SSD_FILTER.filter}=${FILTER_YES}`) || ''
  const routeTo = `${NAV_LINKS.students}?${COURSE_FILTER.filter}=${testCd}&${ADMIN_FILTER.filter}=${adminWindow}${SSDfilter}`
  return <Link to={routeTo}>Edit Unused Exams</Link>
}

/**
 * Handle packing list item.
 */
const PackingListStep2PanelBody = ({
  orgId,
  educationPeriodCd,
  packingListDetails,
  IdInProgress,
  packingListItem,
  readOnly,
  packingListId,
  updating,
  error,
  examType,
  adminWindow,
  isCSR,
  updatePackingListItems,
}) => {
  const {
    packingListItemId,
    examMaterial,
    listeningCDs,
    speakingCDs,
    singingCDs,
    examCDs,
    portfolios,
    students,
    responsesReturned,
    portfolioSubmission,
    onlineSubmission,
    testCd,
    status,
    courseName,
    examsQuantity,
    unused,
  } = packingListItem
  const isSSD = examType === NON_STOCK_PACKAGE_ENUM || examType === BRAILLE_LARGE_FONT_PACKAGE_ENUM
  const examComponent = {
    quantity: examsQuantity,
    unused,
  }
  const hasPortfolioSubmission = typeof portfolioSubmission === 'boolean'
  const hasOnlineSubmission = typeof onlineSubmission === 'boolean'
  const itemConfirmed = status === STATUS_CONFIRMED

  // If exists, portfolioSubmission, onlineSubmission and responsesReturned are required.
  const isSubmissionDisabled =
    (hasPortfolioSubmission && !portfolioSubmission) ||
    (hasOnlineSubmission && !onlineSubmission) ||
    (responsesReturned && !RETURNED_RESPONSE.includes(responsesReturned)) ||
    itemConfirmed ||
    (IdInProgress === packingListItemId && updating)

  return (
    <div style={{ position: 'relative' }}>
      {IdInProgress === packingListItemId && updating && (
        <div className={`${packingStyles.loader}`}>
          <Loader />
        </div>
      )}

      {IdInProgress === packingListItemId && error && (
        <Error title="Error Updating Exam Material." message={error} />
      )}

      <div style={{ marginBottom: '24px' }}>
        <div className={`${packingStyles.grouping}`}>
          <CourseMaterialsReturned
            id={EXAM}
            materials={examComponent}
            GoToUnused={() => (
              <RouteToUnused testCd={testCd} adminWindow={adminWindow} isSSD={isSSD} />
            )}
            readOnly={readOnly}
            inline
          />

          {packingListItem.students ? (
            <NonStockExamComponent id={STUDENTS} students={students} inline />
          ) : null}
        </div>

        {examMaterial ? (
          <ExamMaterialsReturned
            id={EXAM_MATERIAL}
            materials={examMaterial}
            shippedQuantity={examsQuantity}
            packingListItemId={packingListItemId}
            headerTitle={`${courseName}: Edit Materials Quantities`}
            readOnly={readOnly}
            unused={unused}
          />
        ) : null}

        <div className={`${packingStyles.grouping}`}>
          {listeningCDs ? (
            <CourseMaterialsReturned
              id={LISTENING_CDS}
              materials={listeningCDs}
              packingListItemId={packingListItemId}
              headerTitle={`${courseName}: Edit Quantities`}
              inline
              readOnly={readOnly}
            />
          ) : null}

          {speakingCDs ? (
            <CourseMaterialsReturned
              id={SPEAKING_CDS}
              materials={speakingCDs}
              packingListItemId={packingListItemId}
              headerTitle={`${courseName}: Edit Quantities`}
              readOnly={readOnly}
            />
          ) : null}

          {singingCDs ? (
            <CourseMaterialsReturned
              id={SINGING_CDS}
              materials={singingCDs}
              packingListItemId={packingListItemId}
              headerTitle={`${courseName}: Edit Quantities`}
              readOnly={readOnly}
            />
          ) : null}
        </div>

        {examCDs ? (
          <CourseMaterialsReturned
            id={EXAM_CDS}
            materials={examCDs}
            packingListItemId={packingListItemId}
            headerTitle={`${courseName}: Edit Quantities`}
            readOnly={readOnly}
          />
        ) : null}

        {portfolios ? (
          <CourseMaterialsReturned
            id={PORTFOLIOS}
            materials={portfolios}
            packingListItemId={packingListItemId}
            headerTitle={`${courseName}: Edit Quantities`}
            readOnly={readOnly}
          />
        ) : null}

        {hasPortfolioSubmission ? (
          <PortfolioSubmission
            id={PORTFOLIO_SUBMISSION}
            value={portfolioSubmission}
            courseName={courseName}
            packingListId={packingListId}
            packingListItemId={packingListItemId}
            readOnly={readOnly || isCSR}
          />
        ) : null}

        {hasOnlineSubmission ? (
          <SubmissionComponent
            id={ONLINE_SUBMISSION}
            value={onlineSubmission}
            title={
              examType === NON_STOCK_PACKAGE_ENUM
                ? PACKING_LIST_LABEL_MAPPING[SSD_MATERIALS_SUBMISSION]
                : PACKING_LIST_LABEL_MAPPING[ONLINE_SUBMISSION]
            }
            label={
              examType === NON_STOCK_PACKAGE_ENUM
                ? SUBMISSIONS[SSD_MATERIALS_SUBMISSION]
                : SUBMISSIONS[ONLINE_SUBMISSION]
            }
            packingListId={packingListId}
            packingListItemId={packingListItemId}
            readOnly={readOnly || isCSR}
          />
        ) : null}

        {responsesReturned ? (
          <ResponseReturned
            id={RESPONSES_RETURNED}
            value={responsesReturned}
            packingListId={packingListId}
            packingListItemId={packingListItemId}
            readOnly={readOnly || isCSR}
          />
        ) : null}
      </div>

      {!readOnly ? (
        <button
          type="button"
          disabled={isSubmissionDisabled}
          style={{ marginBottom: '24px' }}
          className="btn btn-primary btn-sm"
          onClick={() =>
            updatePackingListItems({
              orgId,
              educationPeriodCd,
              packingListDetails,
              packingListItemId,
              payload: {
                status: STATUS_CONFIRMED,
              },
            })
          }
        >
          {itemConfirmed ? 'Confirmed' : 'Confirm Packing List'}
        </button>
      ) : null}
    </div>
  )
}

export default connect(mapStateToProps, { updatePackingListItems })(PackingListStep2PanelBody)
