import ExamDecisionsForm from '../../../containers/forms/ExamDecisionsForm'
import { Error } from '../../common'


const mapStateToProps = state => {
  const { user: { data: { selectedOrgId } }, settingsExamDecisions, settingsDeadlines} = state
  const { fetched, error, fetching } = settingsExamDecisions[selectedOrgId] || {}

  return {
    isLoaded: fetched && settingsDeadlines.fetched,
    isErrored: error || settingsDeadlines.error,
    isFetching: fetching || settingsDeadlines.fetching
  }
}


const ExamDecisionsPanel = ({ isLoaded, isErrored, isFetching, showAdvancedSettingsForm }) => {

  if(isLoaded && !isErrored)
    return (
      <div className="row">
        <div className="col-xs-12">
          <p>You can determine whether students will be directly included in your exam order after they enroll in class sections, or whether they need to indicate their exam registrations.</p>
          <ExamDecisionsForm />
        </div>
      </div>
    )

  if(isErrored)
    return <Error title="Error Retrieving Data" message={isErrored} />

  return null
}

export default connect(mapStateToProps)(ExamDecisionsPanel)

