import { Redirect, Route } from 'react-router-dom'
import { SetupPage } from '../../containers'
import { NAV_LINKS } from '../../constants/NavConstants'
import { coordinatorNeedsToSetupSchool } from './utils'

const mapStateToProps = ({ user, settingsEducationPeriod }) => ({
  showPage: coordinatorNeedsToSetupSchool(user, settingsEducationPeriod),
})

const SetupRoute = connect(mapStateToProps)(({ component: Component, showPage, ...rest }) => {
  return showPage ? (
    <>
      <div className="skipwrapper">
        <a id="main" tabIndex="-1">
          -
        </a>
      </div>
      <Component {...rest} />
    </>
  ) : (
    <Redirect to={{ pathname: NAV_LINKS.dashboard }} />
  )
})

export default () => (
  <Route path={NAV_LINKS.setup} render={props => <SetupRoute {...props} component={SetupPage} />} />
)
