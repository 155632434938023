import stylesheet from '../../assets/style/scss/terms-and-conditions.scss'

export default () => (
  <div className={`${stylesheet['tac-container']}`}>
    <p>
      These AP<sup>&reg;</sup> Services Terms and Conditions (&#34;AP Services T&C&#34;) govern
      access to and use of My AP, which provides access to AP Classroom, Pre-AP<sup>&reg;</sup>{' '}
      Classroom, and AP Registration and Ordering System (collectively, &#34;AP Services&#34;).
    </p>
    <p>
      &#34;End Users&#34; means Educators and Students. &#34;Educators&#34; include AP coordinators,
      Pre-AP coordinators, AP teachers, Pre-AP teachers, and all other school and education agency
      officials. Educators access AP Services as authorized users of their respective educational
      institutions, which are the account owners (&#34;Schools&#34;). &#34;Students&#34; access AP
      Services as authorized users of their respective Schools.
    </p>
    <p>
      The AP Services T&C is binding on Schools and each of its End Users, any of whom may be
      referred to as &#34;you.&#34;
    </p>
    <div id="school">
      <h3>School</h3>
      <p>
        School shall be bound by these AP Services T&C upon their acceptance by the AP coordinator
        or the principal/head of School or such person&#39;s designee, whoever accepts first.
      </p>
      <p>
        In ordering and administering AP exams, School is bound by the additional terms found within
        the{' '}
        <a
          href="https://collegeboard.org/apcoordinatorsmanual-part1"
          target="_blank"
          rel="noopener noreferrer"
        >
          AP Coordinator’s Manual Part 1<span className="sr-only"> (opens in new window)</span>
        </a>{' '}
        and the{' '}
        <a
          href="https://collegeboard.org/apcoordinatorsmanual-part2"
          target="_blank"
          rel="noopener noreferrer"
        >
          AP Coordinator’s Manual Part 2<span className="sr-only"> (opens in new window)</span>
        </a>
        .
      </p>
      <p>
        The AP Participation Form, to be signed by School annually in order to manage and submit
        exam orders, details some of the most critical of these additional terms.
      </p>
      <p>
        School shall be responsible for setting and managing Educator access to AP Services and for
        Educator use of AP Services, including exam ordering, making changes to exam orders,
        generating and sharing join codes, creating classes on AP and Pre-AP Classroom, assigning
        Student work, and accessing personally identifiable information (&#34;PII&#34;) of Educators
        and Students that is entered into and/or accessible to authorized users on AP Services
        (&#34;Data&#34;).
      </p>
      <p>
        School shall take all reasonable steps to ensure that Educators and Students comply with
        these AP Services T&C.
      </p>
      <p>
        If School uses a College Board endorsed provider for instructional support, College Board
        may share aggregated school-level data with said endorsed provider. Endorsed provider lists
        can be found on the corresponding course pages in AP Central.
      </p>
      <p>
        If a School district (&#34;District&#34;) requests access to Data of Schools and Students
        within its district, College Board<sup>&reg;</sup> will not provide such access unless
        District provides a representation to College Board that it has authorization or consent
        from the Schools within its District to access Data. Upon receipt of such representation,
        College Board may provide authorized users of District with access to Data.
      </p>
    </div>
    <div id="endusers">
      <h3>End Users</h3>
      <p>
        End Users must keep their access credentials (such as username and password) for AP Services
        confidential and may not share this information with anyone else. College Board will not be
        responsible for any loss or damage arising from unauthorized use of your account. You agree
        to promptly notify College Board in the event of any unauthorized use or compromise of your
        account. College Board may, in its sole discretion, suspend or cancel a School or End User
        account at any time, with or without notice or cause.
      </p>
      <p>
        College Board provides a license to each End User to access and use AP Services subject to
        these AP Services T&C. End User licenses are dependent on the End User being an authorized
        user of a School.
      </p>
    </div>
    <div id="educators">
      <h3>Educators</h3>
      <p>Educators access AP Services as authorized users of their respective Schools.</p>
      <p>AP coordinators, Pre-AP coordinators, and their delegates may:</p>
      <ul>
        <li>
          order exams for eligible Students, designate eligible Students for exam fee reduction
          status, and view and edit Students&#39; exam registration status and AP class rosters and
          Pre-AP class rosters;
        </li>
        <li>
          generate join codes for Students to access classes on AP Classroom and Pre-AP Classroom;
        </li>
        <li>view reporting data related to classes on AP Classroom and Pre-AP Classroom; and</li>
        <li>
          access professional development resources for AP coordinators and Pre-AP coordinators.
        </li>
      </ul>
      <p>AP teachers and Pre-AP teachers may:</p>
      <ul>
        <li>
          view and edit Students&#39; exam registration status and AP class rosters or Pre-AP class
          rosters;
        </li>
        <li>
          generate join codes for Students to access classes on AP Classroom and Pre-AP Classroom;
        </li>
        <li>
          assign Students in their class personal progress checks and other activities on AP
          Classroom and Pre-AP Classroom;
        </li>
        <li>view reporting data related to classes on AP Classroom and Pre-AP Classroom;</li>
        <li>
          create, share and use Educator Content (defined below) with AP Classroom and Pre-AP
          Classroom; and
        </li>
        <li>access professional development resources for AP teachers and Pre-AP teachers.</li>
      </ul>
      <p>
        Educators may not provide anyone who has not been so authorized by their School access to AP
        Services.
      </p>
      <p>
        Educators shall ensure that join codes are used for the purpose of enrolling eligible
        Students in class sections and/or exam only sections. Students may only be given join codes
        for AP or Pre-AP classes in which they are enrolled, or to avail themselves of an exam-only
        registration. Students may not share their join code(s) with anyone else.
      </p>
    </div>
    <div id="students">
      <h3>Students</h3>
      <h4>AP Exams</h4>
      <p>
        <strong>
          Students access AP Services as authorized users of their respective Schools. However, when
          Students take AP Exams, they do so in their <em>personal capacities</em>, and the scores
          they earn are their own.
        </strong>{' '}
        Accordingly, in order to take AP Exams, Students are required to enter into a separate
        agreement by accepting the AP Exam Terms & Conditions prior to taking their first AP Exam.
      </p>
      <p>
        School is responsible for ordering AP Exams.{' '}
        <strong>
          A Student&#39;s use of AP Services does not automatically register that Student for any AP
          Exam.
        </strong>{' '}
        A Student is registered for an AP Exam only if an AP coordinator, on behalf of School, has
        placed an exam order for the Student and the exam order is not cancelled.
      </p>
      <p>
        Students may submit their AP Exam fees directly to Schools or other third parties, but
        College Board does not collect AP Exam fees directly from Students. Following the completion
        of AP Exam administrations, College Board invoices Schools, Districts, states, or other
        third parties for AP Exam fees.
      </p>
      <h4>Non-AP Services</h4>
      <p>
        <strong>
          Similarly, when Students access College Board services that are not AP Services, Students
          again do so in their <em>personal capacities</em>, not as Students of School.
        </strong>{' '}
        Non-AP Services may include, but are not limited to, SAT<sup>&reg;</sup> registration,
        college score sends, linking a College Board account to Khan Academy<sup>&reg;</sup>, or
        using BigFuture<sup>&#8482;</sup>.
      </p>
    </div>
    <div id="apro">
      <h3>AP Exam Registration and Ordering</h3>
      <p>
        In order to manage and submit exam orders, School must agree to the AP Participation Form,
        which is accepted on behalf of the School by the AP coordinator, the principal/head of
        School, or such person&#39;s designee.
      </p>
      <p>
        Exam ordering information, including deadlines and fee information, can be found in the{' '}
        <em>AP Coordinator&#39;s Manual</em>{' '}
        <a
          href="http://collegeboard.org/apcoordinatorsmanual-part1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Part 1<span className="sr-only"> (opens in new window)</span>
        </a>{' '}
        and{' '}
        <a
          href="https://collegeboard.org/apcoordinatorsmanual-part2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Part 2<span className="sr-only"> (opens in new window)</span>
        </a>
        .
      </p>
      <p>
        Exam orders and changes must be submitted by the applicable deadlines and School agrees to
        pay exam fees as invoiced by College Board.
      </p>
    </div>
    <div id="prohibited">
      <h3>Prohibited Uses</h3>
      <p>
        You agree to use the AP Services only for their intended use and in compliance with all
        applicable laws. You agree that you will not: (a) impair or attempt to impair the
        performance, availability, or accessibility of the AP Services, (b) harm, reverse engineer,
        or gain unauthorized access to user accounts or the technology and equipment supporting the
        AP Services, (c) act unlawfully, illegally, fraudulently, or harmfully with respect to the
        AP Services, or (d) enter incomplete, false, or misleading information, impersonate another
        person, or misrepresent your affiliation with School.
      </p>
    </div>
    <div id="privacy">
      <h3>Privacy</h3>
      <p>
        These privacy policies supplement the privacy policies found at the{' '}
        <a
          href="https://about.collegeboard.org/privacy-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          College Board Privacy Center<span className="sr-only"> (opens in new window)</span>
        </a>{' '}
        (&#34;Privacy Policies&#34;). Where there is a conflict between these AP Services T&C and
        the Privacy Policies, these AP Services T&C shall control.
      </p>
      <p>
        Student PII collected on AP Classroom and Pre-AP Classroom is subject to the Family
        Educational Rights and Privacy Act (&#34;FERPA&#34;). College Board will be considered a
        &#34;School Official&#34; (as that term is used in FERPA and its implementing regulations)
        and will comply with FERPA with respect to such PII. To the extent that any other data
        collected through the use of AP Services includes educational records subject to FERPA,
        College Board will be considered a &#34;School Official&#34; (as that term is used in FERPA
        and its implementing regulations) and will comply with FERPA.
      </p>
      <p>
        AP Classroom and Pre-AP Classroom are hosted on a platform owned by Prometric. Prometric and
        trusted vendors that help deliver AP Classroom and Pre-AP Classroom are prohibited from
        using data for any purpose other than delivering services to College Board.
      </p>
      <p>
        College Board may use Data for its internal research purposes. College Board may also
        disclose aggregated and/or de-identified Data with trusted third parties.
      </p>
    </div>
    <div id="intellectualproperty">
      <h3>Intellectual Property</h3>
      <p>
        AP Services, and all content available through AP Services, including but not limited to
        questions, answers, video and audio recordings, text, images, animations, and all other
        content and materials (collectively, &#34;Content&#34;), are protected under the Copyright
        Law of the United States and are the sole property of College Board.
      </p>
      <p>
        Content may not be performed, distributed, downloaded, uploaded, modified, reused,
        reproduced, reposted, retransmitted, disseminated, sold, published, broadcast, circulated or
        otherwise used in any way without express written permission of College Board. Any
        modification of the Content, or any portion thereof, or use of the Content for any other
        purpose constitutes an infringement of College Board copyrights and other proprietary
        rights.
      </p>
      <p>
        College Board, AP, Pre-AP, SAT, Advanced Placement, AP Classroom, Pre-AP Classroom, Big
        Future, and the acorn logo are trademarks of College Board. Khan Academy is a registered
        trademark in the United States and other jurisdictions. You acquire no rights under these AP
        Services T&C to use any College Board trademarks.
      </p>
    </div>
    <div id="educatorContent">
      <h3>Educator Content</h3>
      <p>
        Educators may be able to create and share content (&#34;Educator Content&#34;) in AP
        Classroom and Pre-AP Classroom, including authoring questions, answers, and explanations,
        (&#34;Teacher-Authored Questions&#34;). Educators may also be able to create quizzes and
        share those quizzes with other Educators (&#34;Educator Quizzes&#34;), who may provide
        access to other Educators and End Users, including Students. Educators with whom Educator
        Quizzes have been shared may be able to change, modify, edit and/or revise (collectively,
        &#34;Modify&#34;) Teacher-Authored Questions that have been shared with them, and such
        Modifications do not require the consent of College Board or any Educator. Teacher-Authored
        Questions are flagged to other Educators in AP Classroom, and flags may be visible to other
        End Users, including Students. Educators may only use their own, original content to create
        Teacher-Authored Questions, and Educators may only use their own, original content to make
        Modifications to Teacher-Authored Questions. Educators may not Modify questions or any other
        content authored by College Board. Educators are solely responsible for their Educator
        Content, including Modifications they make, which are also Educator Content. Educators
        indemnify and hold harmless College Board from all claims, judgements, awards and expenses
        (including reasonable attorneys&#39; fees) that arise from the creation, Modification or use
        of their Educator Content. College Board does not review or endorse any Educator Content,
        and College Board makes no representations, guarantees or warranties of any kind about
        Educator Content (including that the Educator Content is aligned to the Course and Exam
        Description, non-infringing, non-defamatory, lawful, or correct). Educators create, share
        and use Educator Content at their own risk. Educators grant College Board an unlimited,
        worldwide, royalty-free, perpetual, sublicensable, assignable license to their Educator
        Content.
      </p>
    </div>
    <div id="arbitration">
      <h3>Arbitration</h3>
      <p>
        All disputes between School and College Board (each a &#34;party&#34;), will exclusively be
        resolved in binding arbitration or small claims court. By agreeing to arbitration in
        accordance with this Section, you are waiving your right to have your dispute heard by a
        judge or jury except as set forth below.
      </p>
      <p>
        Either party can seek to have a claim resolved in small claims court if the rules of that
        court will allow it. Additionally, if the claims asserted in any request or demand for
        arbitration could have been brought in small claims court, then either you or College Board
        may elect to have the claims heard in small claims court, rather than in arbitration, at any
        time before an arbitrator is appointed, by notifying the other party of that election in
        writing. Any dispute about whether a claim qualifies for small claims court will be resolved
        by that court and not by an arbitrator. In the event that either party elects to have their
        claims heard in small claims court, the arbitration proceeding will remain closed unless and
        until there is a decision by the small claims court that the claim should proceed in
        arbitration.
      </p>
      <p>
        All claims that are not decided in small claims court must be resolved through binding,
        individual arbitration before a single arbitrator. The arbitration will be administered by
        the American Arbitration Association (&#34;
        <strong>
          <em>AAA</em>
        </strong>
        &#34;) under the AAA Consumer Arbitration Rules, supplemented <strong>by</strong> the AAA
        Mass Arbitration Supplementary Rules as applicable, in effect at the time a request for
        arbitration is filed with the AAA. Copies of the AAA Consumer Arbitration Rules and the AAA
        Mass Arbitration Supplementary Rules are located at{' '}
        <a href="https://www.adr.org" target="_blank" rel="noopener noreferrer">
          adr.org<span className="sr-only"> (opens in new window)</span>
        </a>
        . The arbitrator will have the authority to resolve any dispute regarding the scope or
        enforceability of this Agreement, except only a court can decide claims that a party
        violated the intellectual property rights of the other party. In addition, only a court can
        decide issues relating to (a) the pre-arbitration requirements contained in this Agreement
        or (b) the interpretation of the prohibition of class and representative actions contained
        in this Agreement.
      </p>
      <p>
        Before commencing a small claims court or arbitration proceeding, that party (the
        &#34;complainant&#34;) must provide the other party (the &#34;respondent&#34;) with a
        written notice of dispute that includes the complainant&#39;s name and contact information,
        a detailed description of the dispute, relevant documents, the specific relief sought, and
        the complainant&#39;s physical signature (signature by counsel to the party is not
        sufficient). If you are the complainant, you must send the notice of dispute by first class
        mail, FedEx, or UPS to Legal Department, 250 Vesey Street, New York, NY 10281. College Board
        will send its notice to your address as reflected in College Board&#39;s records.
      </p>
      <p>
        Also, before the complainant may commence a small claims court or arbitration proceeding,
        the parties must attempt to resolve the dispute through informal, good-faith negotiation. If
        the parties have not resolved the dispute within sixty (60) days of the respondent&#39;s
        receipt of the written notice of dispute, the parties will mutually schedule a settlement
        conference which must occur within fourteen (14) days of the completion of the sixty (60)
        day period, unless otherwise mutually agreed by the parties. Each party must personally
        appear at the settlement conference (if a party is represented by counsel, their counsel may
        also participate), and appearances may be made telephonically or by video. If the parties
        are unable to resolve the dispute at the settlement conference, either party may commence
        arbitration or file a small claims court proceeding. The statute of limitations and any
        filing fee deadlines will be tolled while the parties engage in this informal dispute
        resolution process. If any aspect of the requirements in this subsection 8(a) have not been
        met, a court can enjoin the filing or prosecution of an arbitration or the assessment of any
        arbitration fees. In addition, unless prohibited by law, the AAA cannot accept or administer
        the arbitration, nor assess any fees for an arbitration that has not met the requirements of
        this subsection 8(a). If the arbitration already is pending, it must be dismissed.
      </p>
      <p>
        If the dispute proceeds to arbitration, the complainant must personally attend all
        arbitration conferences, hearings, and mediations scheduled by the AAA or by an arbitrator
        or mediator appointed by the AAA. If the complainant is represented by counsel,
        complainant&#39;s counsel may also participate, and all participation may be made
        telephonically or by video except as directed by the arbitrator or mediator. If a
        complainant fails to personally appear at any conference, hearing or mediation scheduled by
        the AAA or by a AAA arbitrator or mediator, regardless of whether the complainant&#39;s
        counsel attends, the arbitrator will administratively close the arbitration proceeding
        without prejudice, unless the complainant shows good cause as to why the complainant was not
        able to attend the conference, hearing, or mediation.
      </p>
      <p>
        This arbitration will be conducted as a documents-only arbitration (i.e., there will be no
        in-person or telephonic hearing) unless otherwise agreed by the parties or required by the
        arbitrator. If the parties agree to or the arbitrator requires proceedings, such proceedings
        should be conducted at a location which is reasonably convenient to both parties with due
        consideration of their ability to travel and other pertinent circumstances. If the parties
        are unable to agree on a location, the parties agree that the proceedings will be conducted
        via a video or telephonic call or, in the event that face-to-face proceedings are agreed to
        by the parties or required by the arbitrator, at a location that is reasonably convenient to
        both parties in accordance with the AAA Consumer Arbitration Rules. The arbitrator may
        consider rulings in arbitrations involving other individuals, but an arbitrator&#39;s
        rulings will not be binding in proceedings involving different individuals. The existence
        and content of the arbitration proceedings, including documents and briefs submitted by the
        parties, any correspondence from the AAA, and correspondence, orders, and awards issued by
        the arbitrator, will remain strictly confidential and will not be disclosed to any third
        party without the express written consent from the other party, unless disclosure to the
        third party is reasonably required in the context of conducting the arbitration proceedings
        or related court proceedings.
      </p>
      <p>
        For disputes meeting the definition of &#34;Mass Arbitration&#34; under the AAA Mass
        Arbitration Supplementary Rules, the parties agree that the dispute is subject to the
        AAA&#39;s Mass Arbitration Supplementary Rules and the parties agree to the appointment of a
        Process Arbitrator, except as may otherwise be decided by the arbitrator or the AAA.
      </p>
      <p>
        The parties agree that the Federal Arbitration Act (&#34;
        <strong>
          <em>FAA</em>
        </strong>
        &#34;) 9 U.S.C. &sect; 1 <em>et seq.</em> governs this Section, and it is the intent of the
        parties that the FAA will preempt all State laws to the fullest extent permitted by law.
      </p>
      <p>
        No arbitration may be maintained as a class or collective action; a party may bring a claim
        on their own behalf and cannot seek a relief that would affect other individuals. Unless all
        parties agree otherwise, the arbitrator will not have the authority to consolidate the
        claims of more than 1 individual, conduct any class or collective proceeding, make any class
        or collective award, or make an award to any person or entity not a party to the
        arbitration, without the express written consent of College Board.
      </p>
      <p>
        Payment of all filing, administrative, and arbitrator fees and costs will be governed by the
        AAA&#39;s rules. If the arbitrator finds that either the substance of your claim or the
        relief sought was frivolous or was brought for an improper purpose (as measured by the
        standards set forth in Federal Rule of Civil Procedure 11(b)), then College Board may seek
        applicable fee-shifting.
      </p>
    </div>
    <div id="liabilities">
      <h3>LIMITATION OF LIABILITIES</h3>
      <p>
        TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COLLEGE BOARD BE LIABLE FOR ANY DIRECT,
        SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, REGARDLESS OF
        THE NATURE OF THE CLAIM OR THEORY OF LIABILITY (INCLUDING CLAIMS UNDER CONTRACT, TORT, OR
        STRICT LIABILITY) REGARDLESS OF WHETHER COLLEGE BOARD KNEW OR SHOULD HAVE KNOWN ABOUT THE
        POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES.
      </p>
    </div>
    <div id="warranties">
      <h3>DISCLAIMER OF WARRANTIES</h3>
      <p>
        EXCEPT AS EXPRESSLY PROVIDED HEREIN, AP SERVICES ARE PROVIDED &#34;AS IS&#34; WITHOUT
        WARRANTY OF ANY KIND, AND COLLEGE BOARD EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES IN RESPECT
        THEREOF, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES
        OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. COLLEGE BOARD MAKES NO
        REPRESENTATIONS ABOUT ANY CONTENT OR INFORMATION MADE ACCESSIBLE BY OR THROUGH THE SERVICES.
      </p>
    </div>
    <div id="assignment">
      <h3>Assignment</h3>
      <p>
        No party to these AP Services T&C may assign or transfer any part of the AP Services T&C.
      </p>
    </div>
    <div id="forcemajeure">
      <h3>Force Majeure</h3>
      <p>
        No party will be liable for inadequate performance to the extent caused by a condition (for
        example, natural disaster, epidemic, act of war or terrorism, riot, labor condition,
        governmental action, or internet disturbance) that was beyond the party&#39;s reasonable
        control.
      </p>
    </div>
    <div id="nowaiver">
      <h3>No Waiver</h3>
      <p>Failure to enforce any provision of these AP Services T&C will not constitute a waiver.</p>
    </div>
    <div id="severability">
      <h3>Severability</h3>
      <p>
        If any provision of these AP Services T&C is found unenforceable, the balance of the AP
        Services T&C will remain in full force and effect.
      </p>
    </div>
    <div id="noagency">
      <h3>No Agency</h3>
      <p>
        The parties are independent contractors, and this AP Services T&C does not create an agency,
        partnership, or joint venture.
      </p>
    </div>
    <div id="thirdpartybeneficiaries">
      <h3>No Third-Party Beneficiaries</h3>
      <p>There are no third-party beneficiaries to this AP Services T&C.</p>
    </div>
    <div id="govorninglaw">
      <h3>Governing Law</h3>
      <p>
        For Schools that are government entities, the parties agree to remain silent regarding
        governing law and venue. For all other Schools and End Users, these AP Services T&C are
        governed by New York law, excluding that state&#39;s choice of law rules.
      </p>
    </div>
    <div id="amendments">
      <h3>Amendments</h3>
      <p>These AP Services T&C may not be amended except in writing signed by College Board.</p>
    </div>
    <div id="entireagreement">
      <h3>Entire Agreement</h3>
      <p>
        These AP Services T&C, and all documents referenced herein, constitute the parties&#39;
        entire agreement relating to these subjects and supersede any prior or contemporaneous
        agreements on these subjects. If an End User is presented with a similar agreement on the
        same subject matter upon logging in to use AP Services, this AP Services T&C supersedes and
        replaces that agreement.
      </p>
    </div>
    <div id="accessibility">
      <h3>Accessibility of These Terms and Conditions</h3>
      <p>
        If you are a student having difficulty accessing these AP Services T&C, please contact AP
        Services for Students at 888-225-5427 (+1-212-632-1780 internationally) or{' '}
        <a href="mailto:apstudents@info.collegeboard.org">apstudents@info.collegeboard.org</a>. If
        you are an educator having difficulty accessing these AP Services T&C, please contact AP
        Services for Educators at 877-274-6474 (+1-212-632-1781 internationally) or{' '}
        <a href="mailto:apexams@info.collegeboard.org">apexams@info.collegeboard.org</a>. We will be
        happy to provide these AP Services T&C in an alternative format or assist you in some other
        manner as reasonably necessary to enable you to access these AP Services T&C.
      </p>
    </div>
  </div>
)
