import {
  MATERIALS,
  SHIPPEDKEY,
  UNUSEDKEY,
  RETURNCDSKEY,
  ANSWERSHEETKEY,
  FREERESPONSEKEY,
  RESPONSEBOOKSKEY,
  MULTICHOICEKEY,
  SHORTKEY,
  ORANGEKEY,
  CJCDSKEY,
  ANSWER_SHEETS_RETURNED,
  FREE_RESPONSE_BOOKLETS_RETURNED,
  MULTIPLE_CHOICE_BOOKLETS_RETURNED,
  SHORT_ANSWER_BOOKLETS_RETURNED,
  ORANGE_BOOKLETS_RETURNED,
  RESPONSE_BOOKS_RETURNED,
} from '../../constants/PackingListConstants'

import packingStyles from '../../assets/style/scss/packing-details.scss'

const mapStateToProps = ({ packingListDetails: { packingListItems } }) => ({ packingListItems })

const normalize = packingListItems => {
  const initializeTotals = {
    Exams: { [SHIPPEDKEY]: 0, [UNUSEDKEY]: 0 },
    ReturningMaterials: {
      [ANSWERSHEETKEY]: 0,
      [FREERESPONSEKEY]: 0,
      [RESPONSEBOOKSKEY]: 0,
      [MULTICHOICEKEY]: 0,
      [SHORTKEY]: 0,
      [ORANGEKEY]: 0,
      [CJCDSKEY]: 0,
      [RETURNCDSKEY]: 0,
    },
  }
  const isIncludedReturnCDs = item =>
    item.courseName.startsWith('German Language and Culture') ||
    item.courseName.startsWith('Spanish Language and Culture') ||
    item.courseName.startsWith('Spanish Literature and Culture') ||
    item.courseName.startsWith('Italian Language and Culture') ||
    item.courseName.startsWith('French Language and Culture') ||
    item.courseName.startsWith('Music Theory')

  return packingListItems.reduce((acc, item) => {
    const {
      Exams: { Shipped, Unused },
      ReturningMaterials: {
        AnswerSheets,
        FreeResponse,
        ResponseBooks,
        MultipleChoice,
        ShortAnswer,
        Orange,
        CJExamCDs,
        ReturnedCDs,
      },
    } = acc
    const { examsQuantity, unused, examCDs, listeningCDs, speakingCDs, singingCDs, examMaterial } =
      item

    return {
      ...acc,
      Exams: {
        ...acc.Exams,
        [SHIPPEDKEY]: Shipped + examsQuantity,
        [UNUSEDKEY]: Unused + unused,
      },
      ReturningMaterials: {
        ...acc.ReturningMaterials,
        [ANSWERSHEETKEY]: AnswerSheets + (examMaterial?.[ANSWER_SHEETS_RETURNED] || 0),
        [FREERESPONSEKEY]: FreeResponse + (examMaterial?.[FREE_RESPONSE_BOOKLETS_RETURNED] || 0),
        [RESPONSEBOOKSKEY]: ResponseBooks + (examMaterial?.[RESPONSE_BOOKS_RETURNED] || 0),
        [MULTICHOICEKEY]: MultipleChoice + (examMaterial?.[MULTIPLE_CHOICE_BOOKLETS_RETURNED] || 0),
        [SHORTKEY]: ShortAnswer + (examMaterial?.[SHORT_ANSWER_BOOKLETS_RETURNED] || 0),
        [ORANGEKEY]: Orange + (examMaterial?.[ORANGE_BOOKLETS_RETURNED] || 0),
        [CJCDSKEY]: CJExamCDs + (examCDs?.returned || 0),
        [RETURNCDSKEY]:
          ReturnedCDs +
          (isIncludedReturnCDs(item)
            ? (listeningCDs?.returned || 0) +
              (speakingCDs?.returned || 0) +
              (singingCDs?.returned || 0)
            : 0),
      },
    }
  }, initializeTotals)
}

const isSectionEmpty = sectionList => {
  return !Object.values(sectionList).reduce((acc, listValue) => acc + listValue)
}

const MaterialTotals = ({ packingListItems }) => (
  <div id={`${packingStyles.materialsList}`}>
    <h3>Material Totals</h3>
    {Object.entries(normalize(packingListItems)).map(section => {
      const [sectionTitle, sectionList] = section
      const sectionEmpty = isSectionEmpty(sectionList)
      const shouldDisplay =
        MATERIALS.TITLES[sectionTitle] !== MATERIALS.TITLES.ReturningMaterials
          ? true
          : !sectionEmpty
      return (
        shouldDisplay && (
          <div key={sectionTitle}>
            <h4 style={{ fontWeight: 'bold' }}>{MATERIALS.TITLES[sectionTitle]}</h4>
            {Object.entries(sectionList).map(material => {
              const [materialTitle, materialValue] = material
              return materialValue ? (
                <div key={materialTitle}>
                  <span className="item-label">{MATERIALS.ITEMS[materialTitle]}</span>
                  <span className="quantity" style={{ float: 'right' }}>
                    {materialValue}
                  </span>
                </div>
              ) : null
            })}
          </div>
        )
      )
    })}
  </div>
)

export default connect(mapStateToProps)(MaterialTotals)
