import {
  submit,
  isSubmitting,
  getFormSyncErrors,
  getFormError,
  hasSubmitFailed,
  getFormSubmitErrors,
  isPristine,
} from 'redux-form'
import { changeStep, saveDistrictFunding } from '../../actions/funding'
import { Loader } from '../common'
import { isEmpty } from '../../utils/common'
import { openModal } from '../../actions/app'

const mapStateToProps = (state, { form }) => {
  const {
    user: {
      data: { selectedOrgId: orgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
    funding: { saving, step: currentStep, data: fundingData, fundingType },
  } = state
  return {
    orgId,
    educationPeriodCd,
    hasValidationErrors: !isEmpty(getFormSyncErrors(form)(state)),
    submitFailed: hasSubmitFailed(form)(state),
    submitError: getFormSubmitErrors(form)(state),
    submitting: isSubmitting(form)(state),
    formError: getFormError(form)(state),
    pristine: isPristine(form)(state),
    saving,
    currentStep,
    form,
    fundingData,
    fundingType,
  }
}

const StepButtons = ({
  orgId,
  educationPeriodCd,
  hasValidationErrors,
  submitFailed,
  submitError,
  submitting,
  formError,
  pristine,
  saving,
  currentStep,
  previousStep,
  nextButtonText,
  previousButtonText,
  totalSteps,
  formComplete = false,
  openModal,
  hideNextStep = false,
  form,
  fundingData,
  fundingType,
  changeStep,
  submit,
  saveDistrictFunding,
  deleteDistrictFunding,
}) => {
  const goBack = () => {
    if (previousStep === 0) {
      window.location = Config.MYAP_URL.prof
    } else {
      changeStep(previousStep)
    }
    window.scrollTo(0, 0)
  }

  return (
    <div>
      {submitFailed ? (
        <p className="cb-error-msg text-right" role="alert" aria-live="polite">
          {!isEmpty(submitError)
            ? `Error: ${submitError}`
            : 'Error: An error occurred processing your request. Please try again.'}
        </p>
      ) : null}
      <div className="row">
        <div className="col-xs-4">
          <button
            type="button"
            className="btn-link"
            style={{ lineHeight: '48px', fontWeight: 700 }}
            onClick={goBack}
          >
            <i className="cb-glyph cb-west" aria-hidden={true} /> {previousButtonText || 'Previous'}
          </button>
        </div>
        <div className="col-xs-8 text-right">
          {!hideNextStep ? (
            <button
              type="button"
              className={`btn btn-sm ${!deleteDistrictFunding ? 'btn-primary' : 'btn-secondary'}`}
              disabled={
                formError ||
                submitting ||
                saving ||
                hasValidationErrors ||
                (pristine &&
                  !formComplete) /* if user has already filled out a form they should be able to move forward */
              }
              onClick={() => {
                if (deleteDistrictFunding) {
                  openModal('ConfirmDeleteDistrictFundingModal')
                } else if (form) {
                  submit(form)
                } else if (currentStep === totalSteps) {
                  saveDistrictFunding(orgId, educationPeriodCd, {
                    fundingType,
                    definition: { ...fundingData, includeOrgs: [] },
                  })
                }
              }}
            >
              {nextButtonText || 'Next'}
              {saving ? (
                <Loader size="sm" style={{ position: 'absolute', top: '14px', right: '25px' }} />
              ) : null}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, { changeStep, submit, saveDistrictFunding, openModal })(
  StepButtons
)
