import * as c from '../constants/EnrollmentConstants'
import * as sc from '../constants/StudentConstants'
import template from './template'

export const studentEnrollments = (state = {}, { type, payload }) =>
  template(state, { type, payload }, c, 'STUDENT_ENROLLMENTS')

export const studentProfile = (state, action) => template(state, action, c, 'STUDENT_PROFILE')

export const studentChangeLog = (state, action) => template(state, action, sc, 'STUDENT_CHANGE_LOG')

export const studentScoreSend = (state, action) => {
  switch (action.type) {
    case c.SAVE_STUDENT_SCORESEND_FULFILLED:
    case c.DELETE_STUDENT_SCORESEND_FULFILLED:
      return { ...state, saving: false, saved: true, ...action.payload }
    case c.SAVE_STUDENT_SCORESEND_REJECTED:
    case c.DELETE_STUDENT_SCORESEND_REJECTED:
      return { ...state, saving: false, saveError: action.payload }

    case c.SAVE_STUDENT_SCORESEND_PENDING:
    case c.DELETE_STUDENT_SCORESEND_PENDING:
      return { ...state, saving: true, saved: false }

    default:
      return template(state, action, c, 'STUDENT_SCORESEND')
  }
}
