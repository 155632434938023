
import styles from '../../../../assets/style/scss/csr.scss'

export default ({ buttons = []}) => buttons.length ? (
  <div className={`${styles['tool-buttons']}`}>
    <div className="row">
      { buttons.map(({ title, disabled, onClick, isPrimary = true }, i) => (
        <div className="col-xs-6" key={i}>
          <button type="button" className={`btn btn-sm ${isPrimary ? 'btn-primary' : 'btn-secondary'}`} disabled={disabled} onClick={onClick}>{title}</button>
        </div>
      ))}
    </div>
  </div>
) : null
