import * as c from '../constants/CourseConstants'
import axios, { getServerErrorMessage } from '../utils/axios'
import { normalizeCourses } from '../utils/normalize'
import { processSectionData } from './section'

export const fetchCourses = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_COURSES_PENDING })
  try {
    const { data } = await axios.get(`${Config.API_URL}/paapro/v1/courses`, {
      params: { orgId, educationPeriodCd },
    })

    // Convert sections with isFallSemester flag to sectionType S_FS
    const courses = data.map(course => {
      const sections = course.sections.map(section => processSectionData(section))
      return { ...course, sections }
    })

    dispatch({
      type: c.FETCH_COURSES_FULFILLED,
      payload: { ...normalizeCourses({ courses }), orgId, educationPeriodCd },
    })
  } catch (err) {
    dispatch({ type: c.FETCH_COURSES_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchAvailableCourses =
  ({ orgId, educationPeriodCd }) =>
  async dispatch => {
    dispatch({ type: c.FETCH_AVAILABLE_COURSES_PENDING })

    try {
      const response = await axios.get(`${Config.API_URL}/course-auth/all-ap-courses`, {
        params: { orgId, educationPeriodCd },
      })
      dispatch({
        type: c.FETCH_AVAILABLE_COURSES_FULFILLED,
        payload: { [educationPeriodCd]: { [orgId]: response.data } },
      })
    } catch (err) {
      dispatch({ type: c.FETCH_AVAILABLE_COURSES_REJECTED, payload: getServerErrorMessage(err) })
    }
  }

export const fetchOrgsByCourse = testCd => async dispatch => {
  dispatch({ type: c.FETCH_ORGS_BY_COURSE_PENDING })
  try {
    const response = await axios.get(`${Config.API_URL}/v1/teacher-orgs`, { params: { testCd } })
    dispatch({ type: c.FETCH_ORGS_BY_COURSE_FULFILLED, payload: { data: response.data } })
  } catch (err) {
    dispatch({ type: c.FETCH_ORGS_BY_COURSE_REJECTED, payload: getServerErrorMessage(err) })
  }
}

export const fetchCourseTypes = (orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_COURSE_TYPES_PENDING })
  try {
    const { data } = await axios.get(`${Config.API_URL}/paapro/v1/course-types`, {
      params: { educationPeriodCd, orgId },
    })
    dispatch({ type: c.FETCH_COURSE_TYPES_FULFILLED, payload: data })
  } catch (err) {
    dispatch({ type: c.FETCH_COURSE_TYPES_REJECTED, payload: getServerErrorMessage(err) })
  }
}
