import { Error, Loader, Accordions } from '../../../common'
import NonSecureMaterialsTestWindowPanel from './NonSecureMaterialsTestWindowPanel'
import {
  getOrdersNonSecureAncillaryMaterialsData,
  getOrdersNonSecureAncillaryMaterialsProps,
} from '../../../../selectors/order'
import { getSortedExamWindowRefData, getExamWindowRefData } from '../../../../selectors/examWindows'
import {
  fetchCoordNonSecureAncillaryMaterials,
  setupNonSecureAncillaryMaterialsFormData,
  clearNonSecureAncillaryMaterialsFormData,
} from '../../../../actions/orders'
import { addFetchAction, removeFetchAction } from '../../../../actions/app'
import { OTHER_MATERIALS_FORM_NAME } from '../../../../constants/OrderConstants'

const mapStateToProps = state => {
  // TBD: Is it okay non-secure materials uses course exam windows?
  const examWindowRefData = getExamWindowRefData(state)
  const { fetching, fetched, error } = getOrdersNonSecureAncillaryMaterialsProps(state)
  const allTestingMaterials = getOrdersNonSecureAncillaryMaterialsData(state)
  return {
    fetching,
    fetched,
    error,
    allTestingMaterials,
    examWindowRefData,
  }
}

const NonSecureMaterialsOrderForm = props => {
  const {
    fetching,
    fetched,
    error,
    allTestingMaterials,
    examWindowRefData,
    fetchCoordNonSecureAncillaryMaterials,
    setupNonSecureAncillaryMaterialsFormData,
    clearNonSecureAncillaryMaterialsFormData,
    addFetchAction,
    removeFetchAction,
  } = props
  const testingMaterialsPanels = []

  const sortedExamWindowData = getSortedExamWindowRefData(examWindowRefData)
  sortedExamWindowData.forEach(({ adminWindow, displayName }) => {
    if (allTestingMaterials[adminWindow] && Object.keys(allTestingMaterials[adminWindow]).length) {
      testingMaterialsPanels.push({
        title: `${displayName} Testing Materials`,
        PanelBody: () => <NonSecureMaterialsTestWindowPanel testWindow={adminWindow} />,
        panelId: `${adminWindow.replace(/\s/, '').toLowerCase()}TestingMaterials`,
      })
    }
  })

  useEffect(() => {
    addFetchAction({
      name: 'fetchCoordNonSecureAncillaryMaterials',
      action: (orgId, educationPeriodCd) =>
        fetchCoordNonSecureAncillaryMaterials(orgId, educationPeriodCd),
    })
    return () => removeFetchAction('fetchCoordNonSecureAncillaryMaterials')
  }, [])

  useEffect(() => {
    const availableTestingWindowMaterials = sortedExamWindowData.reduce(
      (acc, { adminWindow }) => Object.assign(acc, allTestingMaterials[adminWindow]),
      {}
    )
    setupNonSecureAncillaryMaterialsFormData(availableTestingWindowMaterials)
    return () => clearNonSecureAncillaryMaterialsFormData()
  }, [fetched])

  if (error) return <Error title="Error Retrieving Data" message={error} />

  if (fetching) return <Loader />

  return (
    <>
      {testingMaterialsPanels.length > 0 && (
        <form name={OTHER_MATERIALS_FORM_NAME} onSubmit={e => e.preventDefault()}>
          <Accordions id="testingMaterials" panels={testingMaterialsPanels} />
        </form>
      )}
    </>
  )
}

export default connect(mapStateToProps, {
  fetchCoordNonSecureAncillaryMaterials,
  setupNonSecureAncillaryMaterialsFormData,
  clearNonSecureAncillaryMaterialsFormData,
  addFetchAction,
  removeFetchAction,
})(NonSecureMaterialsOrderForm)
