import { Link } from 'react-router-dom'
import packingListStyle from '../../assets/style/scss/packing-list.scss'

export default ({ footerObj: { footerText, footerTextIcon, linkText, link }}) => {
  if (footerText || linkText) {
    return (
      <div className={packingListStyle['pb-footer']}>
        {(footerTextIcon || footerText)
        && <div className={packingListStyle['pb-footer-left']}>
              {footerTextIcon && <span className={`cb-glyph cb-${footerTextIcon}`} aria-hidden="true" style={{ marginRight: '5px' }} />}
              {footerText && <span>{footerText}</span>}
           </div>}
        {linkText && <Link to={link}>{linkText} <i className="cb-glyph cb-east" aria-hidden /></Link>}
      </div>)
  }
  return null
}
