import { BasicTextInput } from '../../common'
import { addFilter } from '../../../actions/filters'
import { STUDENT_NAME_ID_FILTER } from '../../../constants/FilterConstants'

const StudentSearchFilter = ({ style }) => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        addFilter(STUDENT_NAME_ID_FILTER.filter, e.target[0].value.trim())
        e.target[0].value = ''
      }}
      style={style}
    >
      <BasicTextInput
        input={{
          name: 'filterStudentsByNameOrId',
        }}
        isRequired={false}
        placeholder="Search by AP ID or Last Name"
        isSearchBox={true}
        ariaLabel="Search students by AP ID or Last Name"
        inputStyle={{ width: '300px' }}
        formGroupStyle={{ marginBottom: '5px' }}
      />
    </form>
  )
}

export default StudentSearchFilter
