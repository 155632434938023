import ExamRoomActionBar from './ExamRoomActionBar'
import ExamRoomStudentsTable from './ExamRoomStudentsTable'
import ExamRoomNoStudentsCallout from './ExamRoomNoStudentsCallout'

import styles from '../../assets/style/scss/exam-room.scss'

const ExamRoomStudents = ({ roomId }) => {
  const attrs = { roomId }

  return (
    <div className={styles['exam-room-students']}>
      <h3>Students In This Room</h3>
      <ExamRoomActionBar {...attrs} />
      <ExamRoomStudentsTable {...attrs} />
      <ExamRoomNoStudentsCallout {...attrs} />
    </div>
  )
}

export default ExamRoomStudents
