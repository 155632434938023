import FilterDropdown from '../common/FilterDropdown'
import {
  ENROLLMENT_FILTER,
  ENROLLMENT_FILTER_VALUES,
  TEACHER_CONFIRM_FILTER,
  TEACHER_CONFIRM_FILTER_VALUES,
} from '../../../constants/FilterConstants'

export const EnrollmentFilterDropdown = ({ label = ENROLLMENT_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="enrollment"
    categories={[
      {
        key: ENROLLMENT_FILTER.filter,
        options: Object.keys(ENROLLMENT_FILTER_VALUES).map(key => ({
          label: ENROLLMENT_FILTER_VALUES[key],
          value: key,
        })),
      },
    ]}
  />
)

export const TeacherConfirmFilterDropdown = ({ label = TEACHER_CONFIRM_FILTER.label }) => (
  <FilterDropdown
    label={label}
    id="teacherConfirm"
    categories={[
      {
        key: TEACHER_CONFIRM_FILTER.filter,
        options: Object.keys(TEACHER_CONFIRM_FILTER_VALUES).map(key => ({
          label: TEACHER_CONFIRM_FILTER_VALUES[key],
          value: key,
        })),
      },
    ]}
  />
)
