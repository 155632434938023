import RenderExamComponent from './RenderExamComponent'
import { openModal } from '../../actions/app'
import {
  PACKING_LIST_COMPONENT_MAPPING,
  PACKING_LIST_CTA_MAPPING,
  PACKING_LIST_LABEL_MAPPING,
  PACKING_GENERAL_COMPONENTS_ORDER,
  CHANGE_PACKING_LIST_ITEMS_MODAL,
} from '../../constants/PackingListConstants'

import packingStyles from '../../assets/style/scss/packing-details.scss'

const ActionElement = ({
  id,
  materials,
  packingListItemId,
  headerTitle,
  goToUnused: GoToUnused,
  openModal,
}) => {
  const editButtonRef = useRef(null)

  const handleClick = e => {
    e.preventDefault()

    const formattedMaterials = [
      {
        label: PACKING_LIST_LABEL_MAPPING[id],
        name: id,
        quantity: materials.returned,
        returnedReason: materials.returnedReason || '',
      },
    ]

    openModal(CHANGE_PACKING_LIST_ITEMS_MODAL, {
      materials: formattedMaterials,
      shippedQuantity: materials.quantity,
      packingListItemId,
      headerTitle,
      modalCloseFocusElem: editButtonRef.current,
    })
  }

  if (GoToUnused) {
    return <GoToUnused />
  }

  return (
    <button
      style={{ padding: '0' }}
      type="button"
      className="btn-link"
      ref={editButtonRef}
      onClick={handleClick}
    >
      {PACKING_LIST_CTA_MAPPING[id] || 'Edit '}
    </button>
  )
}

// Handle other materials like exam cds.
const CourseMaterialsReturned = ({
  id = '',
  materials,
  packingListItemId,
  inline,
  readOnly,
  headerTitle,
  GoToUnused = null,
  openModal,
}) => (
  <div className={inline ? `${packingStyles.inline}` : ''}>
    <h4>{PACKING_LIST_LABEL_MAPPING[id] || ''}</h4>
    {PACKING_GENERAL_COMPONENTS_ORDER.map(item => {
      if (materials[item] === null || materials[item] === undefined) {
        return null
      }
      return (
        <div key={item} style={{ display: 'inline-block', padding: '12px 24px 24px 0' }}>
          <RenderExamComponent
            label={PACKING_LIST_COMPONENT_MAPPING[item]}
            count={materials[item]}
          />
        </div>
      )
    })}
    {!readOnly && (
      <div style={{ marginBottom: '24px' }}>
        <ActionElement
          id={id}
          materials={materials}
          packingListItemId={packingListItemId}
          headerTitle={headerTitle}
          goToUnused={GoToUnused}
          openModal={openModal}
        />
      </div>
    )}
  </div>
)

export default connect(null, { openModal })(CourseMaterialsReturned)
