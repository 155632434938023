import { change as changeFieldValue } from 'redux-form'
import { Input, Tooltip } from '../../common'
import { ConditionalMaterialField } from '../common/SsdUtils'
import {
  SELECT_SSD_MATERIALS_FORM,
  ALLOWED_SSD_MATERIALS,
  SSD_MATERIALS_FIELDS,
} from '../../../constants/StudentConstants'

import styles from '../../../assets/style/scss/materials-modal.scss'

const multipleDayTestingInfoMsg =
  'Select if the student expects to test over multiple days due to limited-time testing or 100% extended time exceeding 6 hours of testing time.'

class TestingTime extends Component {
  componentDidUpdate() {
    const { selectedSSDMaterials, changeFieldValue } = this.props

    // if this group is being toggled off, empty the fields
    if (!selectedSSDMaterials.testingTimeCheck) {
      if (selectedSSDMaterials[SSD_MATERIALS_FIELDS.multipleDayTesting.name] !== false)
        changeFieldValue(
          SELECT_SSD_MATERIALS_FORM,
          SSD_MATERIALS_FIELDS.multipleDayTesting.name,
          false
        )
    }
  }

  render() {
    const { metaData, allowedSSDMaterialCodes, selectedSSDMaterials } = this.props
    const inlineBlock = { display: 'inline-block' }
    const iconStyles = {
      display: 'inline-block',
      color: 'rgb(0, 119, 200)',
      fontSize: '8px',
    }

    return (
      <div name="extra-time-group" className={styles['materials-fieldset']}>
        <Input
          type="checkbox"
          name="testingTimeCheck"
          labelId="extraTimeGroup"
          label="Multiple-Day Materials"
        />
        <div
          role="group"
          aria-labelledby="extraTimeGroup"
          className={styles['materials-radiogroup']}
        >
          <ConditionalMaterialField
            allowedCodes={allowedSSDMaterialCodes}
            fieldName={ALLOWED_SSD_MATERIALS.multipleDayTestings}
            fieldId={SSD_MATERIALS_FIELDS.multipleDayTesting.value}
          >
            <div className={styles['material-radiogroup-wrap']}>
              <Input
                type="checkbox"
                name={SSD_MATERIALS_FIELDS.multipleDayTesting.name}
                label={metaData[SSD_MATERIALS_FIELDS.multipleDayTesting.value].description}
                normalize={value => !!value}
                disabled={!selectedSSDMaterials.testingTimeCheck}
                style={inlineBlock}
              />{' '}
              <div style={inlineBlock}>
                <Tooltip
                  title={multipleDayTestingInfoMsg}
                  placement="top"
                  container="body"
                  label="More information: Multi-Day Testing"
                >
                  <i
                    className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                    aria-hidden="true"
                    style={iconStyles}
                    data-alt={multipleDayTestingInfoMsg}
                  />
                </Tooltip>
              </div>
            </div>
          </ConditionalMaterialField>
        </div>
      </div>
    )
  }
}

export default connect(null, { changeFieldValue })(TestingTime)
