import { getFormValues } from 'redux-form'
import { Input } from '../../common'
import {
  SCHOOL_INFORMATION_FORM,
  SCHOOL_INFORMATION_FIELDS as FIELDS,
  GPA_WEIGHTAGE_EQUAL,
  GPA_WEIGHTAGE_MORE_WITH_AP_EXAM_TAKEN,
  GPA_WEIGHTAGE_MORE,
} from '../../../constants/SetupConstants'

const mapStateToProps = state => {
  return {
    currentFormValues: getFormValues(SCHOOL_INFORMATION_FORM)(state) || {},
  }
}

const SchoolGPAPolicy = () => {
  return (
    <fieldset
      role="radiogroup"
      aria-labelledby="gpa-weightage-selection-group"
      aria-required="true"
      style={{ marginTop: 0 }}
    >
      <legend className="cb-required" id="gpa-weightage-selection-group" role="presentation">
        Which best describes your school&#39;s GPA policies for AP courses?
      </legend>
      <div className="row">
        <div className="col-xs-12">
          <Input
            type="radio"
            name={FIELDS.GPA_WEIGHTAGE_POLICY.name}
            label={
              <>
                AP course grades receive more weight in a student&#39;s GPA than standard course
                grades only if the student takes the corresponding AP Exam.
              </>
            }
            value={GPA_WEIGHTAGE_MORE_WITH_AP_EXAM_TAKEN}
            ariaLabel="AP course grades receive more weight in a student's GPA than standard course
                grades only if the student takes the corresponding AP Exam."
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Input
            type="radio"
            name={FIELDS.GPA_WEIGHTAGE_POLICY.name}
            label={
              <>
                AP course grades receive more weight in a student&#39;s GPA than standard course
                grades regardless of whether the student takes the corresponding AP Exam.
              </>
            }
            value={GPA_WEIGHTAGE_MORE}
            ariaLabel="AP course grades receive more weight in a student's GPA than standard course
                grades regardless of whether the student takes the corresponding AP Exam."
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Input
            type="radio"
            name={FIELDS.GPA_WEIGHTAGE_POLICY.name}
            label={
              <>
                AP course grades receive the same weight in a student&#39;s GPA as standard course
                grades.
              </>
            }
            value={GPA_WEIGHTAGE_EQUAL}
            ariaLabel="AP course grades receive the same weight in a student's GPA as standard course
                grades."
          />
        </div>
      </div>
    </fieldset>
  )
}

export default connect(mapStateToProps)(SchoolGPAPolicy)
