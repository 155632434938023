import { formatPhoneNumber } from '../../../utils/numbers'

const StudentContactInformation = ({ emailAddress, phoneNumber }) => (
  <>
    <h4 className="roboto-bold">Contact Information</h4>
    {emailAddress ? (
      <div>
        Email: <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
      </div>
    ) : null}
    {phoneNumber ? <div>Phone: {formatPhoneNumber(phoneNumber)}</div> : null}
  </>
)

export default StudentContactInformation
