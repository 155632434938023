import { useLocation } from 'react-router-dom'
import ExamDecisionsPanel from '../../components/settings/examdecisions/ExamDecisionsPanel'
import AccessManagementPanel from '../../components/settings/accessmanagement/AccessManagementPanel'
import ExamAdministrationPanel from '../../components/settings/examadministration/ExamAdministrationPanel'
import RosterManagementPanel from '../../components/settings/rostermanagement/RosterManagementPanel'
import ParticipationFormPanel from '../../components/settings/participationform/ParticipationFormPanel'
import ParticipationFormTitle from '../../components/settings/participationform/ParticipationFormTitle'
import { Accordions } from '../../components/common'
import { SETTINGS_PANELS } from '../../constants/SettingsConstants'
import { queryParamsToObj } from '../../utils/common'

const mapStateToProps = state => {
  const { isLevelOne, roles, selectedRole } = state.user.data
  const { preAPOnly, aiCode } = roles[selectedRole]
  return {
    isLevelOne,
    isPreAPOnly: preAPOnly,
    isNoAiCode: !aiCode,
  }
}

const PreAPOnlyPanels = [
  { title: 'Access Management', PanelBody: AccessManagementPanel, panelId: SETTINGS_PANELS.access },
  {
    title: 'Roster Management',
    PanelBody: RosterManagementPanel,
    panelId: SETTINGS_PANELS.rosterManagement,
  },
]

const NoAICodePanels = [
  { title: 'Access Management', PanelBody: AccessManagementPanel, panelId: SETTINGS_PANELS.access },
  {
    title: 'Roster Management',
    PanelBody: RosterManagementPanel,
    panelId: SETTINGS_PANELS.rosterManagement,
  },
]

const LevelOnePanels = [
  {
    title: ParticipationFormTitle,
    PanelBody: ParticipationFormPanel,
    panelId: SETTINGS_PANELS.partForm,
  },
  {
    title: 'Exam Administration',
    PanelBody: ExamAdministrationPanel,
    panelId: SETTINGS_PANELS.examAdmin,
  },
  {
    title: 'Roster Management',
    PanelBody: RosterManagementPanel,
    panelId: SETTINGS_PANELS.rosterManagement,
  },
  { title: 'Access Management', PanelBody: AccessManagementPanel, panelId: SETTINGS_PANELS.access },
]

const LevelTwoPanels = [
  {
    title: ParticipationFormTitle,
    PanelBody: ParticipationFormPanel,
    panelId: SETTINGS_PANELS.partForm,
  },
  {
    title: 'Exam Administration',
    PanelBody: ExamAdministrationPanel,
    panelId: SETTINGS_PANELS.examAdmin,
  },
  {
    title: 'Student Exam Decision Indicator',
    PanelBody: ExamDecisionsPanel,
    panelId: SETTINGS_PANELS.examDecisions,
  },
  {
    title: 'Roster Management',
    PanelBody: RosterManagementPanel,
    panelId: SETTINGS_PANELS.rosterManagement,
  },
  { title: 'Access Management', PanelBody: AccessManagementPanel, panelId: SETTINGS_PANELS.access },
]

const SettingsPage = ({ isPreAPOnly, isLevelOne, isNoAiCode }) => {
  const { search } = useLocation()
  const openPanel = queryParamsToObj(search).scroll
  const appropriatePanels = isPreAPOnly
    ? PreAPOnlyPanels
    : isNoAiCode
    ? NoAICodePanels
    : isLevelOne
    ? LevelOnePanels
    : LevelTwoPanels
  const panels = openPanel
    ? appropriatePanels.map(p => (p.panelId === openPanel ? { ...p, openByDefault: true } : p))
    : appropriatePanels

  useEffect(() => {
    const panel = document.getElementById(openPanel)
    panel && window.scrollTo(0, panel.getBoundingClientRect().top - 60)
  }, [])

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h3>Settings</h3>
            <Accordions id="settingsAccordions" panels={panels} />
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default connect(mapStateToProps)(SettingsPage)
