import CoordinatorOrders from '../../components/orders/CoordinatorOrders'
import NoAccessPage from './NoAccessPage'

const mapStateToProps = state => ({
  isCoordinator: state.user.data?.isCoordinator,
})

const OrdersPage = ({ isCoordinator }) => {
  if (isCoordinator) {
    return (
      <CSSTransition
        classNames="animate-fade"
        in={true}
        timeout={500}
        appear={true}
        enter={false}
        exit={false}
      >
        <div className="container">
          <CoordinatorOrders />
        </div>
      </CSSTransition>
    )
  }

  return <NoAccessPage />
}

export default connect(mapStateToProps)(OrdersPage)
