import { BasicTextInput } from '../../../common'
import { BrowseItem } from '..'

import styles from '../../../../assets/style/scss/csr.scss'

export default ({ fetchBrowseData, types }) => (
  <div className={styles['tool-body-inner']}>
    <div className={styles['tool-body-item']}>
      <BasicTextInput
        label="Search for students whose fee(s) needs to be removed."
        placeholder="Enter student name"
        labelStyle={{ fontWeight: 400 }}
        input={{ name: 'studentName' }}
      />
      <p style={{ marginBottom: 5 }}>Or, browse students by type of fee to remove.</p>
    </div>
    {types.map((type, i) => (
      <BrowseItem
        key={i}
        title={type.label}
        action={() => fetchBrowseData(type)}
      />
    ))}
  </div>
)
