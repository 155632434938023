import { fetchSecureAncillaryMaterials, saveSecureAncillaryMaterials } from '../../../actions/csr'
import { LockUnlockComponent } from '.'

import styles from '../../../assets/style/scss/csr.scss'

export default () => (
  <LockUnlockComponent
    compId="secureAncillaryMaterials"
    introText="Select the courses below that you wish to lock or unlock the ability to order more than the allowable number of CDs."
    emptyText="There are no secure ancillary materials to unlock."
    fnFetch={fetchSecureAncillaryMaterials}
    fnSave={saveSecureAncillaryMaterials}
    itemProps={{ itemId: 'testCd', itemDescription: 'courseName' }}
  />
)
