import { openModal, addFetchAction, removeFetchAction } from '../../actions/app'
import { fetchSurveyData } from '../../actions/survey'
import { isFeatureEnabled } from '../../selectors/features'

const mapStateToProps = state => {
  const {
    user: {
      data: {
        selectedRole,
        roles,
        isPreAPOnly,
        isCoordinator,
        isDistrictAdmin,
        isDistrictFundingAdmin,
        isDistrictSuperAdmin,
        isCSR,
        isSchoolAdmin,
        isSDP,
      },
    },
    settingsEducationPeriod: { selectedIsCurrent },
    survey: { fetched, data, error },
  } = state
  const setupComplete = !roles[selectedRole]?.needsSetup
  const surveyQuestions = data?.some(({ active, questions = [] }) => active && questions.length > 0)
  const surveyEnabled = isFeatureEnabled(state, 'MIDYEAR_SURVEY')
  const surveyAvailable =
    surveyEnabled &&
    selectedIsCurrent &&
    setupComplete &&
    isCoordinator &&
    !error &&
    !isCSR &&
    !isSchoolAdmin &&
    !isSDP &&
    !isDistrictAdmin &&
    !isDistrictFundingAdmin &&
    !isDistrictSuperAdmin &&
    !isPreAPOnly
  return {
    surveyAvailable,
    surveyQuestions,
    fetched,
    error,
  }
}

const EducationPeriod24CourseSurvey = ({
  surveyAvailable,
  surveyQuestions,
  fetched,
  error,
  fetchSurveyData,
  openModal,
  addFetchAction,
  removeFetchAction,
}) => {
  useEffect(() => {
    if (surveyAvailable) {
      addFetchAction({
        name: 'fetchSurveyData',
        action: (orgId, educationPeriodCd) => fetchSurveyData(orgId, educationPeriodCd),
      })
    }
    return () => {
      if (surveyAvailable) {
        removeFetchAction('fetchSurveyData')
      }
    }
  }, [surveyAvailable])

  if (surveyAvailable && fetched && surveyQuestions) {
    openModal('EducationPeriod24CourseSurveyModal')
  }

  return null
}

export default connect(mapStateToProps, {
  fetchSurveyData,
  openModal,
  addFetchAction,
  removeFetchAction,
})(EducationPeriod24CourseSurvey)
