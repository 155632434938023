import * as c from './supporttool'
import { IconDropdown } from '../common'
import { getViewportHeight, getAllFocusableElementsByContainer } from '../../utils/common'
import styles from '../../assets/style/scss/csr.scss'

const DEFAULT_PANEL = 0
const DEFAULT_TITLE = 'Support Tools'
const DEFAULT_STATE = {
  panel: DEFAULT_PANEL,
  subpanel: DEFAULT_PANEL,
  title: DEFAULT_TITLE,
  expanded: false,
  prev: [],
}
const getToolHeight = () => {
  const viewportHeight = getViewportHeight() - 100
  const maxHeight = 525
  return viewportHeight > maxHeight ? maxHeight : viewportHeight
}

class CSRSupportTool extends Component {
  state = DEFAULT_STATE

  constructor() {
    super()
    this.updatePanel = this.updatePanel.bind(this)
    this.closePanel = this.closePanel.bind(this)
    this.keyboardAccessibility = this.keyboardAccessibility.bind(this)
    this.setToPreviousPanel = this.setToPreviousPanel.bind(this)
  }

  getRenderComponent() {
    const { panel } = this.state
    switch (panel) {
      case 0:
        return c.SupportToolMenu
      case 1:
        return c.SecureAncillaryMaterials
      case 2:
        return c.NonSecureAncillaryMaterials
      case 3:
        return c.FeeRemovals
      case 4:
        return c.OrdersAfterDeadline
      case 5:
        return c.ExceptionTesting
      case 6:
        return c.OffCycleTesting
      case 7:
        return c.FeeStatus
      case 8:
        return c.ScoreReportingServices
      case 9:
        return c.ChangeGradeLevel
      case 10:
        return c.RemoveAttendingSchool
      case 11:
        return c.ChangeAttendingSchool
      case 12:
        return c.LargeVolumeRebates
      case 13:
        return c.UpdateStudentProfile
      case 14:
        return c.ExamIntent
      default:
        return null
    }
  }

  getCollapseButton() {
    const { panel, expanded, title } = this.state

    return (
      <button
        type="button"
        aria-haspopup={expanded ? null : 'dialog'}
        aria-expanded={expanded}
        aria-label={`${expanded ? 'Hide' : 'Show'} Support Tool`}
        onClick={this.closePanel}
      >
        {panel === DEFAULT_PANEL ? <span>{title}</span> : null}
        <i className={expanded ? 'cb-glyph cb-down' : 'cb-glyph cb-up'} aria-hidden={true} />
      </button>
    )
  }

  getBackButton() {
    const { prev } = this.state
    const prevLength = prev.length
    const prevIndex = prevLength - 1

    if (prevLength > 1)
      return (
        <IconDropdown
          iconClass="cb-glyph cb-west"
          srTitle="Go Back..."
          id="CSRGoBackMenu"
          dropdownClass={styles['dropdown-trigger']}
          menuItems={prev
            .map((p, i) => ({
              label: p.title,
              val: i,
              clickEvent: () => this.setToPreviousPanel(i),
            }))
            .reverse()}
        />
      )

    return (
      <button
        type="button"
        onClick={() => this.setToPreviousPanel(prevIndex)}
        aria-label={`Go Back to ${prev[prevIndex]?.title}`}
      >
        <i className="cb-glyph cb-west" aria-hidden={true} />
      </button>
    )
  }

  getRenderHeader() {
    const { panel, title } = this.state
    const isDefaultPanel = panel === DEFAULT_PANEL
    const closeButton = this.getCollapseButton()
    const backButton = this.getBackButton()

    return isDefaultPanel ? (
      <h4>{closeButton}</h4>
    ) : (
      <>
        {backButton}
        <h4>{title}</h4>
        {closeButton}
      </>
    )
  }

  updatePanel(newPanel, newTitle, newSubpanel = DEFAULT_PANEL) {
    const { panel, title, subpanel, prev } = this.state

    if (newPanel === -1) this.setToPreviousPanel(prev.length - 1)
    else {
      this.setState({
        prev:
          subpanel === newSubpanel && panel === newPanel
            ? prev
            : [...prev, { panel, title, subpanel }],
        panel: newPanel,
        title: newTitle,
        subpanel: newSubpanel,
      })
    }
  }

  setToPreviousPanel(index) {
    const { prev } = this.state
    const hasPrevious = index !== 0
    const prevPanel = hasPrevious
      ? prev[index]
      : { panel: DEFAULT_PANEL, title: DEFAULT_TITLE, subpanel: DEFAULT_PANEL }

    this.setState({
      ...prevPanel,
      prev: hasPrevious ? prev.slice(0, -1 * (prev.length - index)) : [],
    })
  }

  closePanel() {
    const { expanded } = this.state
    this.setState({ ...DEFAULT_STATE, expanded: !expanded })
  }

  keyboardAccessibility(e) {
    const { keyCode, shiftKey, target } = e
    const { TAB, ESC } = APRICOT.utils.keys
    const focusableElems = Array.from(getAllFocusableElementsByContainer(this.container))
    const index = focusableElems.findIndex(elem => elem === target)
    const len = focusableElems.length
    const { expanded } = this.state

    if (!expanded) return

    if (keyCode === ESC) this.closePanel()
    else if (keyCode === TAB && !shiftKey && index === len - 1) {
      e.preventDefault()
      focusableElems[0].focus()
    } else if (keyCode === TAB && shiftKey && index === 0) {
      e.preventDefault()
      focusableElems[len - 1].focus()
    }
  }

  render() {
    const { expanded, panel, subpanel } = this.state
    const Component = expanded && this.getRenderComponent()
    const toolHeight = getToolHeight()

    return (
      <>
        <div
          className="container"
          aria-label="Support Tools"
          role={expanded ? 'dialog' : 'region'}
          aria-modal={expanded ? true : null}
          onKeyDown={this.keyboardAccessibility}
          ref={node => (this.container = node)}
        >
          <div className={`row ${styles['tool-container']}`}>
            <div className={`${styles['tool-header']} clearfix`} ref={node => (this.header = node)}>
              {this.getRenderHeader()}
            </div>
            {Component ? (
              <div className={styles['tool-body']} style={{ height: toolHeight }}>
                <Component updatePanel={this.updatePanel} panel={panel} subpanel={subpanel} />
              </div>
            ) : null}
          </div>
        </div>
        {expanded ? <div className={styles['tool-backdrop']} /> : null}
      </>
    )
  }
}

export default CSRSupportTool
