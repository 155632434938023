import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { IncidentReportView } from '.'
import { Accordions } from '../common'
import { INCIDENT_REPORT_SHARED_FIELD_NAMES as SHARED_FIELDS } from '../../constants/IncidentReportConstants'
import styles from '../../assets/style/scss/incident-report-history.scss'

const sortReportGroupKeys = (a, b) => a - b
const sortReports = (a, b) => Date.parse(b.reportDateTime) - Date.parse(a.reportDateTime)

export default ({ courseName, reports }) => {
  // Group reports by date and coordinator name
  const reportGroups = {}
  reports.forEach(report => {
    // This is the key we'll use to group reports within a course under the same submitter for the
    // same date.
    const reportGroupKey = `${formatDate(report.reportDateTime, 'yyyy-MM-dd')}_${report.coordinator[
      SHARED_FIELDS.lastName.name
    ].replaceAll(/ '`/g, '')}_${report.coordinator[SHARED_FIELDS.firstName.name].replaceAll(
      / '`/g,
      ''
    )}`
    const reportGroupArray = reportGroups[reportGroupKey] ?? []
    reportGroups[reportGroupKey] = [...reportGroupArray, { ...report }]
  })
  // Build panels
  const reportPanels = []
  const orderedReportGroupKeys = Object.keys(reportGroups).sort(sortReportGroupKeys)
  orderedReportGroupKeys.forEach(key => {
    reportPanels.push({
      title: () => (
        <>
          <span className={styles['incident-date']}>
            {formatDate(reportGroups[key][0].reportDateTime, DATETIME_FORMATS.longMonthDayYear)}
          </span>
          <span className={styles['incident-count']}>
            {reportGroups[key]?.length} incident{reportGroups[key]?.length !== 1 ? 's' : ''}
          </span>
          <span className={styles['incident-submitter']}>
            submitted by {reportGroups[key][0].coordinator[SHARED_FIELDS.firstName.name]}{' '}
            {reportGroups[key][0].coordinator[SHARED_FIELDS.lastName.name]}
          </span>
        </>
      ),
      panelId: key,
      PanelBody: () => (
        <>
          {reportGroups[key].sort(sortReports).map(report => (
            <IncidentReportView key={report.reportId} report={report} courseName={courseName} />
          ))}
        </>
      ),
    })
  })

  return (
    <>
      <h3>{courseName}</h3>
      <Accordions id="incidentReportAccordions" panels={reportPanels} />
    </>
  )
}
