import memoize from 'memoizee'
import * as c from '../constants/StudentConstants'
import axios, { getServerErrorMessage } from '../utils/axios'

export const resetStudentUpdate = () => dispatch => dispatch({ type: c.RESET_UPDATE_TYPE })

export const fetchNotTakingExamReasons = memoize(async () => {
  try {
    const { data } = await axios.get(`${Config.API_URL}/enrollments/not-taking-exam-reasons`)
    return {
      options: data.map(reason => ({
        label: reason.notTakingExamReasonDescr,
        value: reason.notTakingExamReasonCd,
      })),
    }
  } catch (err) {
    return { error: getServerErrorMessage(err), options: [] }
  }
})

export const fetchDropStudentOptions = async enrollmentId => {
  try {
    const { data } = await axios.get(`${Config.API_URL}/enrollments/${enrollmentId}/drop-options`)
    return { data }
  } catch (err) {
    return { error: getServerErrorMessage(err) }
  }
}

export const fetchAvailableSectionsForMove = async (sectionId, studentId) => {
  try {
    const { data } = await axios.get(
      `${Config.API_URL}/section/${sectionId}/students/${studentId}/allowed-move-to-sections`
    )
    return { data }
  } catch (err) {
    return { error: getServerErrorMessage(err) }
  }
}

export const fetchStudentChangeLog = (studentId, orgId, educationPeriodCd) => async dispatch => {
  dispatch({ type: c.FETCH_STUDENT_CHANGE_LOG_PENDING })
  try {
    const { data = [] } = await axios.get(
      `${Config.API_URL}/paapro/v1/coordinator/organizations/${orgId}/education-periods/${educationPeriodCd}/student-details/${studentId}/history`
    )
    dispatch({
      type: c.FETCH_STUDENT_CHANGE_LOG_FULFILLED,
      payload: { studentId, data },
    })
  } catch (err) {
    dispatch({ type: c.FETCH_STUDENT_CHANGE_LOG_REJECTED, payload: getServerErrorMessage(err) })
  }
}

// This isn't a real action in that it's not dispatching to a reducer
export const reportNARGenerated = async ({ enrollmentIds, downloadStatus }) => {
  try {
    await axios.post(`${Config.API_URL}/enrollments/reports/nar`, { enrollmentIds, downloadStatus })
  } catch (err) {
    return
  }
}
