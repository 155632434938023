import FilterDropdown from './FilterDropdown'
import {
  COURSE_FILTER,
  ADMIN_FILTER,
  TEACHER_FILTER,
  COURSE_TYPE_FILTER,
  COURSE_TYPE_FILTER_VALUES,
  EXAM_DATE_CONFLICTS_OPTION,
} from '../../../constants/FilterConstants'
import { getExamWindowRefData } from '../../../selectors/examWindows'
import { hasMultiCourseTypes } from '../../../selectors/course'
import { sortColumnByKey } from '../../../utils/sort'

// coordinator courses, students, and digital exam pages
export const CourseFilterDropdown = ({
  courses = {},
  label = `${COURSE_FILTER.label}s`,
  labelKey = 'name',
  valueKey = 'testCd',
}) =>
  Object.keys(courses).length ? (
    <FilterDropdown
      label={label}
      id="courses"
      categories={[
        {
          key: COURSE_FILTER.filter,
          options: sortColumnByKey(Object.values(courses), labelKey, 'asc').map(c => ({
            label: c[labelKey],
            value: c[valueKey],
          })),
        },
      ]}
    />
  ) : null

// coordinator courses, students, and digital exam pages
export const TeacherFilterDropdown = ({
  teachers = [],
  label = `${TEACHER_FILTER.label}s`,
  labelKey = 'name',
  valueKey = 'teacherId',
}) =>
  teachers.length ? (
    <FilterDropdown
      label={label}
      id="teachers"
      categories={[
        {
          key: TEACHER_FILTER.filter,
          options: sortColumnByKey(teachers, labelKey, 'asc').map(c => ({
            label: c[labelKey],
            value: c[valueKey],
          })),
        },
      ]}
    />
  ) : null

// coordinator students, digital exams and teacher digital exams
export const AdminFilterDropdown = connect((state, { windows = [] }) => {
  const examWindowRefData = getExamWindowRefData(state)
  const availableWindows = windows.map(w => ({
    ...examWindowRefData[w],
    label: examWindowRefData[w]?.displayName,
    value: w,
  }))
  const options = [
    ...sortColumnByKey(availableWindows, 'sequence', 'asc'),
    { label: EXAM_DATE_CONFLICTS_OPTION.label, value: EXAM_DATE_CONFLICTS_OPTION.value },
  ]

  return {
    options,
  }
})(({ options, label = ADMIN_FILTER.label }) =>
  options.length ? (
    <FilterDropdown
      label={label}
      categories={[{ key: ADMIN_FILTER.filter, options }]}
      id="admins"
    />
  ) : null
)

// coordinator students and courses page
export const CourseTypeFilterDropdown = ({ courses }) => {
  return hasMultiCourseTypes(Object.values(courses)) ? (
    <FilterDropdown
      label={COURSE_TYPE_FILTER.label}
      id="courseType"
      categories={[
        {
          key: COURSE_TYPE_FILTER.filter,
          options: Object.keys(COURSE_TYPE_FILTER_VALUES).map(key => ({
            label: COURSE_TYPE_FILTER_VALUES[key],
            value: key,
          })),
        },
      ]}
    />
  ) : null
}
