import { Redirect, Route } from 'react-router-dom'
import { DistrictFundingPage } from '../../containers'
import { NAV_LINKS } from '../../constants/NavConstants'

const mapStateToProps = ({ user }) => {
  const {
    data: { isDistrictFundingAdmin, isDistrictSuperAdmin },
  } = user
  return {
    showPage: isDistrictFundingAdmin || isDistrictSuperAdmin,
  }
}

const FundingRoute = connect(mapStateToProps)(({ component: Component, showPage, ...rest }) => {
  return showPage ? (
    <>
      <div className="skipwrapper">
        <a id="main" tabIndex="-1">
          -
        </a>
      </div>
      <Component {...rest} />
    </>
  ) : (
    <Redirect to={{ pathname: NAV_LINKS.dashboard }} />
  )
})

export default () => (
  <Route
    path={NAV_LINKS.districtfunding}
    render={props => (
      <FundingRoute {...props} component={DistrictFundingPage} title="AP District Funding" />
    )}
  />
)
