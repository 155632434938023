import { Tooltip } from '../../common'
import { isTakingExam } from '../../../selectors/section'
import ListOfSelectedSpecialDigitalFormats from './ListOfSelectedSpecialDigitalFormats'
import ExamSSDApprovalStatus from './ExamSSDApprovalStatus'
import ExamSSDApprovalHistory from './ExamSSDApprovalHistory'
import { hasNonStockedApprovalStatus } from '../../../selectors/student'
import { DIGITAL_EXAM, HYBRID_EXAM } from '../../../constants/SettingsConstants'
import styles from '../../../assets/style/scss/student-details.scss'

const tooltipDigitalMsg =
  'Review the accommodations indicated for the student’s digital exam. If the student doesn’t wish to use any of the accommodations on their digital exam, de-select the accommodation and submit the change to your exam order.'

const tooltipHybridMsg =
  'Review the accommodations indicated for the student’s hybrid exam. If the student doesn’t wish to use any of the accommodations on their hybrid exam, de-select the accommodation and submit the change to your exam order.'

const mapStateToProps = state => {
  const {
    user: {
      data: { isLevelTwo },
    },
  } = state

  return {
    isLevelTwo,
  }
}

const StudentDetailsSpecialDigitalFormatsSection = connect(mapStateToProps)(
  ({ exam, isLevelTwo }) => {
    const editMaterialsStyles = {
      display: 'inline-block',
      color: 'rgb(0, 119, 200)',
      fontSize: '8px',
    }

    const isDigital = exam.examFormat === DIGITAL_EXAM
    const isHybrid = exam.examFormat === HYBRID_EXAM
    const etsApprovalHistoryExists = hasNonStockedApprovalStatus(exam)

    if (isTakingExam(exam) && !exam.inactiveReason && isLevelTwo) {
      return (
        <div className={`${styles['ssd-materials-container']} col-lg-5 col-md-4 col-xs-12`}>
          <h5>
            {isDigital ? 'Digital ' : isHybrid ? 'Hybrid ' : ''}Exam: Special Format
            <div style={{ position: 'absolute', top: 1, right: '-20px' }}>
              <Tooltip
                title={isDigital ? tooltipDigitalMsg : isHybrid ? tooltipHybridMsg : ''}
                placement="top"
                container="body"
                label={`More information: ${
                  isDigital ? 'Digital ' : isHybrid ? 'Hybrid ' : ''
                }Exam: Special Format Information`}
              >
                <i
                  className="cb-glyph cb-glyph-xs cb-glyph-circular cb-question"
                  aria-hidden="true"
                  style={editMaterialsStyles}
                  data-alt={isDigital ? tooltipDigitalMsg : isHybrid ? tooltipHybridMsg : ''}
                />
              </Tooltip>
            </div>
          </h5>
          <div className={styles.ssd}>
            <ListOfSelectedSpecialDigitalFormats exam={exam} />
          </div>
          {etsApprovalHistoryExists ? (
            <>
              {isTakingExam(exam) ? <hr style={{ margin: '10px 0' }} /> : null}
              <ExamSSDApprovalStatus exam={exam} />
              <ExamSSDApprovalHistory exam={exam} />
            </>
          ) : null}
        </div>
      )
    }
    return null
  }
)

export default connect(mapStateToProps)(StudentDetailsSpecialDigitalFormatsSection)
