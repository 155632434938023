import { getHoldNotification } from '../../../actions/notifications'
import {
  HOLD_NOTIFICATION,
  SECURITY_HOLD_MESSAGE,
  BILLING_HOLD_MESSAGE,
  SECURITY_AND_BILLING_HOLD_MESSAGE,
  SYSTEM_HOLD_MESSAGE,
} from '../../../constants/NotificationConstants'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    settingsEducationPeriod: { selectedEducationPeriod: educationPeriodCd },
  } = state
  const { data, fetching, error } = state.notifications[HOLD_NOTIFICATION]
  return {
    orgId: selectedOrgId,
    educationPeriodCd,
    notification: data || {},
    fetching,
    error,
  }
}

const CoordinatorHoldNotification = ({
  orgId,
  educationPeriodCd,
  notification,
  fetching,
  error,
  getHoldNotification,
}) => {
  let message = {}

  useEffect(() => {
    if (orgId) {
      getHoldNotification({ orgId })
    }
  }, [ orgId ])

  if (error || fetching) return null

  if (notification?.allowedToOrder) return null

  if (!notification?.systemHold?.allowedToOrder) {
    message = SYSTEM_HOLD_MESSAGE
  } else {
    if (!notification?.securityHold?.allowedToOrder && !notification?.billingHold?.allowedToOrder) {
      message = SECURITY_AND_BILLING_HOLD_MESSAGE
    } else if (!notification?.securityHold?.allowedToOrder) {
      message = SECURITY_HOLD_MESSAGE
    } else {
      message = BILLING_HOLD_MESSAGE
    }
  }

  return (
    <div
      role="alert"
      className="alert alert-danger cb-alert-heading"
      style={{ marginTop: '-21px', border: 0, backgroundColor: 'rgb(193, 49, 69)' }}
    >
      <div className="container" style={{ position: 'relative' }}>
        <h2 style={{ color: 'rgb(255, 255, 255)', marginBottom: '.5em' }}>{message.title}</h2>
        <p style={{ color: 'rgb(255, 255, 255)', fontWeight: 'bold', marginBottom: 0 }}>
          {message.body}
        </p>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, { getHoldNotification })(CoordinatorHoldNotification)
