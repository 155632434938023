import { Loader, Error, Text, Input } from '@myap/ui-library'
import { ActionBar, SelectAllOrNone } from '..'
import { fetchStudentsBySearch } from '../../../../actions/csr'
import { sortColumnByKey } from '../../../../utils/sort'

import styles from '../../../../assets/style/scss/csr.scss'

const DEFAULT_STATE = { loading: false, error: null, saving: false, fetched: false }

const SearchForStudent = ({ label, fetch }) => {
  const [term, setTerm] = useState('')
  return (
    <Text
      label={label}
      placeholder="Enter AP ID or Last name"
      name="studentSearch"
      value={term}
      className={styles['search-input']}
      onChange={e => setTerm(e.target.value)}
      onKeyDown={e => e.keyCode === APRICOT.utils.keys.ENTER && fetch(term)}
    />
  )
}

const SearchForStudents = ({
  inputLabel,
  getButtons,
  orgId,
  educationPeriod,
  component: SelectionComponent,
  searchType,
}) => {
  const [actions, setActions] = useState(DEFAULT_STATE)
  const [selected, setSelected] = useState([])
  const [students, setStudents] = useState([])
  const hasStudents = students.length
  const noStudents = actions.fetched && !actions.loading && !hasStudents
  const buttons = getButtons(selected, students)
  const sort = data => sortColumnByKey(data, ['lastName', 'firstName'], 'asc')
  const params = searchType ? { searchType } : {}

  const fetchStudentData = async searchTerm => {
    setSelected([])
    setStudents([])
    await setActions({ ...actions, loading: true, error: null })
    const { data = [], error = null } = await fetchStudentsBySearch({
      orgId,
      educationPeriod,
      searchTerm,
      params,
    })
    setActions({
      ...actions,
      loading: false,
      error,
      fetched: true,
    })
    setStudents(sort(data))
  }

  const saveStudentData = async save => {
    await setActions({ ...actions, saving: true, error: null })
    const { data = [], error = null } = await save(selected)

    if (!error) {
      setSelected(selected.filter(s => !data.find(d => d.studentId === s.studentId)))
      setStudents(
        sort(
          students.map(stu => {
            const updated = data.find(retStu => stu.studentId === retStu.studentId) || {}
            return { ...stu, ...updated }
          })
        )
      )
    }
    setActions({ ...actions, saving: false, error })
  }

  const StudentSelection = () => (
    <>
      <SelectAllOrNone
        selectAll={() => setSelected(students)}
        selectNone={() => setSelected([])}
        dataLen={students.length}
        selectionsLen={selected.length}
      />
      <div style={{ marginTop: 20 }} className={styles['tool-body-item']}>
        {actions.error ? <Error title="Error Saving Data" message={actions.error} /> : null}
        {students.map(stu => {
          const checked = Boolean(selected.find(s => s.studentId === stu.studentId))
          return (
            <Fragment key={stu.studentId}>
              <Input
                name="studentSearchCheckbox"
                value={stu.studentId}
                checked={checked}
                onChange={e =>
                  setSelected(
                    e.target.checked
                      ? [...selected, stu]
                      : selected.filter(s => s.studentId !== stu.studentId)
                  )
                }
                aria-describedby={`msg${stu.studentId}`}
                label={`${stu.lastName}, ${stu.firstName}`}
                disabled={stu.lockedSubsidyApplied}
              />
              <div
                style={{ marginLeft: 27, marginBottom: 10 }}
                tabIndex="-1"
                id={`msg${stu.studentId}`}
              >
                <div>{stu.studentId}</div>
                {SelectionComponent ? <SelectionComponent student={stu} checked={checked} /> : null}
                {stu.lockedSubsidyApplied ? (
                  <div>Cannot update student due to a locked subsidy</div>
                ) : null}
              </div>
            </Fragment>
          )
        })}
      </div>
      <ActionBar
        buttons={buttons.map(b => ({
          ...b,
          onClick: () => (b.save ? saveStudentData(b.save) : b.onClick(selected, students)),
          disabled: b.disabled || !selected.length || actions.saving,
        }))}
      />
    </>
  )

  return (
    <div className={styles['tool-body-inner']}>
      <div className={styles['tool-body-item']}>
        <SearchForStudent label={inputLabel} fetch={fetchStudentData} />
      </div>
      {actions.loading ? <Loader /> : null}
      {noStudents ? (
        <p className={styles['tool-body-item']}>No students found that match the criteria.</p>
      ) : null}
      {hasStudents ? <StudentSelection /> : null}
    </div>
  )
}

export default SearchForStudents
