import { useParams } from 'react-router-dom'
import { fetchCourses } from '../../actions/course'
import { Loader, Error } from '../../components/common'
import SectionDetails from '../../components/section/teacher/SectionDetails'
import {
  setNavLink,
  unsetNavLink,
  suppressSchoolMenu,
  unSuppressSchoolMenu,
} from '../../actions/nav'

const mapStateToProps = state => {
  const {
    user: {
      data: { selectedOrgId },
    },
    courses: { fetched: coursesFetched, fetching: coursesFetching, error: coursesError },
    settingsEducationPeriod: {
      selectedEducationPeriod,
      fetching: fetchingEducationPeriod,
      error: errorEducationPeriod,
    },
  } = state
  return {
    selectedOrgId,
    coursesFetched,
    coursesFetching,
    coursesError,
    fetchingEducationPeriod,
    errorEducationPeriod,
    educationPeriod: selectedEducationPeriod,
  }
}

const SectionDetailsPage = ({
  coursesFetched,
  coursesFetching,
  coursesError,
  selectedOrgId,
  educationPeriod,
  errorEducationPeriod,
  fetchingEducationPeriod,
  fetchCourses,
  setNavLink,
  unsetNavLink,
  suppressSchoolMenu,
  unSuppressSchoolMenu,
}) => {
  const { sectionId } = useParams()

  useEffect(() => {
    setNavLink('/courses', 'Back to Course List')
    suppressSchoolMenu()
    if (educationPeriod && !coursesFetched && !coursesFetching) {
      fetchCourses(selectedOrgId, educationPeriod)
    }

    return () => {
      unsetNavLink()
      unSuppressSchoolMenu()
    }
  }, [coursesFetched, educationPeriod, coursesFetching])

  if (coursesError || errorEducationPeriod)
    return (
      <div className="container" style={{ marginTop: '20px' }}>
        <Error
          title="Error Retrieving Data"
          message="An error occurred retrieving courses. Please try again later."
        />
      </div>
    )

  if (coursesFetching || fetchingEducationPeriod) return <Loader />

  return (
    <CSSTransition
      classNames="animate-fade"
      in={true}
      timeout={500}
      appear={true}
      enter={false}
      exit={false}
    >
      <SectionDetails sectionId={sectionId} />
    </CSSTransition>
  )
}

export default connect(mapStateToProps, {
  fetchCourses,
  setNavLink,
  unsetNavLink,
  suppressSchoolMenu,
  unSuppressSchoolMenu,
})(SectionDetailsPage)
