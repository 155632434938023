import { Redirect, Route } from 'react-router-dom'
import { parse } from '@myap/ui-library'
import { isDateSameOrAfter } from '@myap/ui-library/esm/date'
import { TermsAndConditionsPage } from '../../containers'
import FetchByOrgIdOrYear from '../app/FetchByOrgIdOrYear'
import { NAV_LINKS } from '../../constants/NavConstants'
import { coordinatorNeedsToSetupSchool } from './utils'

const mapStateToProps = ({
  user,
  settingsEducationPeriod,
  settingsDeadlines: { data: deadlines, fetched: deadlinesFetched },
}) => {
  const {
    data: { isDistrictFundingAdmin, isDistrictSuperAdmin, acceptedGeneralTAC, acceptedFundingTAC },
  } = user
  const { availableEducationPeriods, current } = settingsEducationPeriod
  const { districtFundingStartDate } = deadlines?.[current] ?? {}
  const { academicYrStartDate } = availableEducationPeriods?.[current] ?? {}
  const today = new Date()
  const isAfterFundingStartDate = districtFundingStartDate
    ? isDateSameOrAfter(today, districtFundingStartDate)
    : false
  const isTransitionPeriod = academicYrStartDate
    ? today.getMonth() === parse(academicYrStartDate).getMonth()
    : false
  const needsToAcceptFundingTAC =
    (isDistrictFundingAdmin || isDistrictSuperAdmin) &&
    acceptedGeneralTAC &&
    !acceptedFundingTAC &&
    isAfterFundingStartDate

  return {
    showPage: user.hasAccess && (!acceptedGeneralTAC || needsToAcceptFundingTAC),
    redirectToSetup:
      coordinatorNeedsToSetupSchool(user, settingsEducationPeriod) && !isTransitionPeriod,
    redirectToFunding: (isDistrictFundingAdmin || isDistrictSuperAdmin) && acceptedFundingTAC,
    deadlinesFetched,
    isFundingUser: isDistrictFundingAdmin || isDistrictSuperAdmin,
  }
}

const TACRoute = connect(mapStateToProps)(
  ({
    component: Component,
    showPage,
    redirectToSetup,
    redirectToFunding,
    deadlinesFetched,
    isFundingUser,
    ...rest
  }) => {
    const [showContent, setShowContent] = useState(false)

    useEffect(() => {
      if (!isFundingUser || (isFundingUser && deadlinesFetched)) {
        setShowContent(true)
      }
    }, [deadlinesFetched])

    if (showContent) {
      const redirectObj = redirectToSetup
        ? { pathname: NAV_LINKS.setup }
        : redirectToFunding
        ? { pathname: NAV_LINKS.districtfunding }
        : { pathname: NAV_LINKS.redirect, from: NAV_LINKS.tac }
      return showPage ? <Component {...rest} /> : <Redirect to={redirectObj} />
    }
    return null
  }
)

export default () => (
  <FetchByOrgIdOrYear>
    <Route
      path={NAV_LINKS.tac}
      render={props => <TACRoute {...props} component={TermsAndConditionsPage} />}
    />
  </FetchByOrgIdOrYear>
)
