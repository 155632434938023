import { Link } from 'react-router-dom'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { NAV_LINKS } from '../../../constants/NavConstants'
import {
  ORDER_TAB_EXAMS,
  ORDER_TAB_SERVICES,
  ORDER_TAB_REBATE,
} from '../../../constants/OrderConstants'
import { BrowseItem } from '.'

import styles from '../../../assets/style/scss/csr.scss'

const mapStateToProps = state => {
  const {
    settingsEducationPeriod: { selectedIsCurrent, selectedEducationPeriod, current },
    settingsDeadlines: { data: settingsDeadlinesData },
  } = state

  const {
    feeRemovalLockDateIsPast,
    feeRemovalLockDate,
    frplLockDateIsPast,
    frplLockDate,
    scoreReportingOrderLockDateIsPast,
    scoreReportingOrderLockDate,
    examIntentLockDateIsPast,
    examIntentLockDate,
  } = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  const { finalOrderingDeadlineIsPast, finalOrderingDeadline } =
    settingsDeadlinesData?.[current] ?? {}
  return {
    selectedIsCurrent,
    feeRemovalLockDateIsPast,
    feeRemovalLockDate,
    frplLockDateIsPast,
    frplLockDate,
    scoreReportingOrderLockDateIsPast,
    scoreReportingOrderLockDate,
    finalOrderingDeadlineIsPast,
    finalOrderingDeadline,
    examIntentLockDateIsPast,
    examIntentLockDate,
  }
}

const DISABLED_TEXT = 'This feature has now been disabled for prior-year updates.'

export default connect(mapStateToProps)(
  ({
    updatePanel,
    selectedIsCurrent,
    feeRemovalLockDateIsPast,
    feeRemovalLockDate,
    frplLockDateIsPast,
    frplLockDate,
    scoreReportingOrderLockDateIsPast,
    scoreReportingOrderLockDate,
    finalOrderingDeadlineIsPast,
    finalOrderingDeadline,
    examIntentLockDateIsPast,
    examIntentLockDate,
  }) => {
    const feeRemovalDate = formatDate(feeRemovalLockDate, DATETIME_FORMATS.shortMonthDay)
    const frplDate = formatDate(frplLockDate, DATETIME_FORMATS.shortMonthDay)
    const scoreReportingDate = formatDate(
      scoreReportingOrderLockDate,
      DATETIME_FORMATS.shortMonthDay
    )
    const finalOrderingDate = formatDate(finalOrderingDeadline, DATETIME_FORMATS.shortMonthDay)
    const examIntentDate = formatDate(examIntentLockDate, DATETIME_FORMATS.shortMonthDay)
    const options = [
      {
        title: 'Secure Ancillary Materials',
        desc: 'Toggle the ability to order more language/music theory CDs than the allowable limit.',
        dest: NAV_LINKS.orders,
        show: selectedIsCurrent,
      },
      {
        title: 'Non-Secure Ancillary Materials',
        desc: 'Toggle the ability to order additional materials for the exam administration.',
        dest: `${NAV_LINKS.orders}#${ORDER_TAB_EXAMS}Tab`,
        show: selectedIsCurrent,
      },
      {
        title: 'Fee Removals',
        subtitle: !selectedIsCurrent ? ` - Available until ${feeRemovalDate}` : '',
        desc: feeRemovalLockDateIsPast
          ? DISABLED_TEXT
          : 'Remove the late order, late-testing and/or cancellation fees for selected students.',
        dest: NAV_LINKS.students,
        show: true,
        disabled: feeRemovalLockDateIsPast,
      },
      {
        title: 'Orders After Deadline',
        desc: 'Unlock the ability for exams to be ordered past the final ordering deadline for 24 hours.',
        dest: NAV_LINKS.orders,
        show: selectedIsCurrent,
      },
      {
        title: 'Exception Testing',
        desc: 'Unlock the ability for exams to be ordered for the exception window for 24 hours.',
        dest: NAV_LINKS.orders,
        show: true,
      },
      {
        title: 'Off-cycle Testing',
        desc: 'Unlock the ability for exams to be ordered for the Off-cycle Testing window for 24 hours.',
        dest: NAV_LINKS.orders,
        show: true,
      },
      {
        title: 'AP Fee Status',
        subtitle: !selectedIsCurrent ? ` - Available until ${frplDate}` : '',
        desc: frplLockDateIsPast ? DISABLED_TEXT : "Change students' AP Fee Status.",
        dest: NAV_LINKS.students,
        show: true,
        disabled: frplLockDateIsPast,
      },
      {
        title: 'Score Reporting Services',
        subtitle: !selectedIsCurrent ? ` - Available until ${scoreReportingDate}` : '',
        desc: scoreReportingOrderLockDateIsPast
          ? DISABLED_TEXT
          : 'Select Score Reporting Services to unlock ordering for Score Reporting Services. Score Reporting Services ordering will be active for 24 hours.',
        dest: `${NAV_LINKS.orders}#${ORDER_TAB_SERVICES}Tab`,
        show: true,
        disabled: scoreReportingOrderLockDateIsPast,
      },
      {
        title: 'Change Grade Level',
        desc: "Change students' Grade Levels.",
        dest: NAV_LINKS.students,
        show: !selectedIsCurrent,
      },
      {
        title: 'Remove Attending School',
        desc: 'Remove Attending School.',
        dest: NAV_LINKS.students,
        show: !selectedIsCurrent,
      },
      {
        title: 'Change Attending School',
        desc: 'Change Attending School.',
        dest: NAV_LINKS.students,
        show: !selectedIsCurrent,
      },
      {
        title: 'Unlock Large Volume Rebates',
        subtitle: !selectedIsCurrent ? ` - Available until ${finalOrderingDate}` : '',
        desc: 'Unlock Large Volume Rebates.',
        dest: `${NAV_LINKS.orders}#${ORDER_TAB_REBATE}Tab`,
        show: !selectedIsCurrent,
        disabled: finalOrderingDeadlineIsPast,
      },
      {
        title: 'Update Student Profile',
        desc: "Change a student's legal name and birthdate.",
        dest: NAV_LINKS.students,
        show: selectedIsCurrent,
      },
      {
        title: 'Exam Intent',
        subtitle: !selectedIsCurrent ? ` - Available until ${examIntentDate}` : '',
        desc: 'Unlock the ability for exam intent to be changed on eligible enrollments',
        dest: NAV_LINKS.students,
        show: !selectedIsCurrent,
        disabled: examIntentLockDateIsPast,
      },
    ]

    return (
      <div className={styles['tool-body-inner']} style={{ height: 'inherit' }}>
        {options.map((option, i) =>
          option.show ? (
            <BrowseItem
              key={i}
              title={`${option.title}${option.subtitle || ''}`}
              action={() => updatePanel(i + 1, option.title)}
              className="browse-item-main"
              disabled={option.disabled}
            >
              <p style={{ marginTop: 5 }}>{option.desc}</p>
              {option.disabled ? (
                <div className="disabled">View this page</div>
              ) : (
                <Link to={option.dest}>View this page</Link>
              )}
            </BrowseItem>
          ) : null
        )}
      </div>
    )
  }
)
