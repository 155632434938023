import { useEffect } from 'react'
import { pdf, Document } from '@react-pdf/renderer'
import { formatDate } from '@myap/ui-library/esm/date'
import { openModal } from '../../actions/app'
import ExamNARForm from '../../components/students/coordinator/pdfs/ExamNARForm'
import { resetMultiSelect, unselectEnrollments } from '../../actions/studentsByOrg'
import { reportNARGenerated } from '../../actions/studentsCommon'
import { getDeadlineIsPast, getSchoolAICode } from '../../selectors/settings'
import { getExamWindowsForCourse } from '../../selectors/examWindows'
import {
  STUDENT_ENROLLMENT_STATUS_YES,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAILED,
} from '../../constants/StudentConstants'
import { sortColumnByKey } from '../../utils/sort'

const mapStateToProps = state => {
  const {
    studentsByOrg: { exams, studentMap, courseMap, selected },
    app: {
      breakpoint: { name: breakpoint },
    },
  } = state

  const aiCode = getSchoolAICode(state)

  const selectedEnrollments = []
  for (let i = 0; i < exams.length; i++) {
    if (selected.includes(exams[i].enrollmentId)) {
      selectedEnrollments.push({
        ...exams[i],
        ...studentMap?.[exams[i]?.studentId],
        courseName: courseMap?.[exams[i]?.testCd]?.name,
        aiCode, // overwrite the student's aiCode with the school's aiCode
      })
    }
  }

  return {
    breakpoint,
    selectedEnrollments,
    courseName: selectedEnrollments?.[0]?.courseName, // multi-select only works with enrollments from one course
    narGenerationStartDate: getDeadlineIsPast(state, 'narGenerationStartDate'),
    examWindows: getExamWindowsForCourse(state, selectedEnrollments?.[0]?.testCd),
  }
}

const MultiSelectGenerateNARModal = connect(mapStateToProps, {
  resetMultiSelect,
  openModal,
  unselectEnrollments,
})(
  ({
    modalCloseFocusElem,
    ModalComponent,
    breakpoint,
    selectedEnrollments,
    courseName,
    narGenerationStartDate,
    examWindows,
    openModal,
    resetMultiSelect,
    unselectEnrollments,
  }) => {
    const [successModalText, setSuccessText] = useState(null)
    const [generatingPdf, setGeneratingPdf] = useState(false)
    const [generatedPdf, setGeneratedPdf] = useState(false)
    const [errorGeneratingPdf, setErrorGeneratingPdf] = useState(null)
    const filteredEnrollments =
      selectedEnrollments?.filter(s => {
        return (
          s.examIntent === STUDENT_ENROLLMENT_STATUS_YES &&
          s.approvedSSDAccommodations?.length > 0 &&
          narGenerationStartDate
        )
      }) ?? []

    // not using selections here since we already have the filtered results
    const getFooterActions = (selections, options, disabled) => [
      {
        buttonLabel: 'Cancel',
        isDismissable: true,
        isDisabled: generatingPdf && !errorGeneratingPdf,
      },
      {
        buttonLabel: 'Generate',
        isPrimary: true,
        isDisabled: (disabled || generatingPdf) && !errorGeneratingPdf,
        busy: generatingPdf && !errorGeneratingPdf,
        onClick: async () => {
          const { schoolNameAndAddress } = options
          const { aiCode } = filteredEnrollments[0]
          const dateGenerated = formatDate(new Date(), 'MMddyyyy')
          setGeneratingPdf(true)
          setErrorGeneratingPdf(null)
          try {
            const pdfBlob = await pdf(
              <Document>
                {sortColumnByKey(
                  filteredEnrollments,
                  ['lastName', 'firstName'],
                  ['asc', 'asc']
                ).map(enrollment => {
                  const examDate = examWindows?.[enrollment.testWindow]?.examDateTime
                  return (
                    <ExamNARForm
                      key={`${enrollment.studentId}_${enrollment.testCd}_${enrollment.testWindow}`}
                      data={{ ...enrollment, schoolNameAndAddress, examDate }}
                    />
                  )
                })}
              </Document>
            ).toBlob()
            const url = URL.createObjectURL(pdfBlob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `NAR_${aiCode}_${courseName}_${dateGenerated}.pdf`)
            // Append to html link element page
            document.body.appendChild(link)
            // Start download
            link.click()
            // Clean up and remove the link
            link.parentNode.removeChild(link)
            // Clean up url for blob
            URL.revokeObjectURL(url)
            reportNARGenerated({
              enrollmentIds: filteredEnrollments.map(({ enrollmentId }) => enrollmentId),
              downloadStatus: DOWNLOAD_SUCCESS,
            })
            setGeneratingPdf(false)
            setGeneratedPdf(true)
            unselectEnrollments()
          } catch (err) {
            reportNARGenerated({
              enrollmentIds: filteredEnrollments.map(({ enrollmentId }) => enrollmentId),
              downloadStatus: DOWNLOAD_FAILED,
            })
            setErrorGeneratingPdf('There was an error generating the NAR. Please try again.')
          }
        },
      },
    ]

    useEffect(() => {
      resetMultiSelect()
    }, [])

    useEffect(() => {
      if (generatedPdf && successModalText) {
        openModal('UpdateMultiSelectSuccessModal', {
          modalCloseFocusElem,
          text: successModalText,
          overrideShowModal: true,
          overrideTitle: 'NAR Forms Generated',
          onCloseAction: () => resetMultiSelect(),
        })
      }
    }, [generatedPdf, successModalText])

    return (
      <ModalComponent
        modalProps={{
          shouldCloseModal: generatedPdf,
          modalCloseFocusElem,
          modalStyles: breakpoint !== 'mobile' ? { width: 750 } : {},
        }}
        getFooterActions={getFooterActions}
        setSuccessText={setSuccessText}
        selectedEnrollments={selectedEnrollments}
        filteredEnrollments={filteredEnrollments}
        courseName={courseName}
        updated={generatedPdf}
        error={errorGeneratingPdf}
      />
    )
  }
)

export default MultiSelectGenerateNARModal
