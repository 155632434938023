import { openModal } from '../../../actions/app'

const mapStateToProps = state => ({})

const ToggleAuthorizationButtons = connect(mapStateToProps, { openModal })(
  ({ authorization, openModal }) => {
    const { certOverrideInd } = authorization

    return (
      <button
        type="button"
        className="btn-link"
        onClick={e => {
          e.preventDefault()
          openModal('ToggleTeacherAccessModal', {
            authorization,
            modalCloseFocusElem: e.target,
          })
        }}
      >
        {certOverrideInd ? 'Enable' : 'Disable'}
      </button>
    )
  }
)

const AuthorizedTeachersTableRow = ({ authorization, num }) => {
  const { firstName, lastName, email = '', courseName } = authorization
  const fullName = `${firstName} ${lastName}`

  return (
    <tr>
      <td
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          ...(num === 0 && { borderTopWidth: 0 }),
        }}
      >
        {fullName}
        {email && (
          <div>
            (<a href={`mailto:${email}`}>{email}</a>)
          </div>
        )}
      </td>
      <td
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          ...(num === 0 && { borderTopWidth: 0 }),
        }}
      >
        {courseName}
      </td>
      <td
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          ...(num === 0 && { borderTopWidth: 0 }),
        }}
      >
        <ToggleAuthorizationButtons authorization={authorization} />
      </td>
    </tr>
  )
}

export default AuthorizedTeachersTableRow
