import { useHistory } from 'react-router-dom'
import { Modal } from '../../components/common'
import { NAV_LINKS } from '../../constants/NavConstants'

const SavedIncidentReportDraftModal = () => {
  const history = useHistory()
  return (
    <Modal
      headerTitle="Incident Report Saved"
      footerActions={[{ buttonLabel: 'Close', isDismissable: true, isPrimary: true }]}
      onCloseAction={() => history.push(NAV_LINKS.students)}
    >
      <p>
        The Incident Report draft has been saved. You can access the saved report by clicking the
        &#39;Incident Report Submission&#39; link on the Students page.
      </p>
      <p>
        <strong>Note:</strong> You need to &#39;Submit&#39; the Incident Report once it&#39;s
        completed.
      </p>
    </Modal>
  )
}

export default SavedIncidentReportDraftModal
