import * as c from '../constants/UserConstants'
import { FINISH_COORDINATOR_SETUP } from '../constants/SetupConstants'
import { UPDATE_EXAM_DECISIONS_FULFILLED } from '../constants/SettingsConstants'
import { getOrgLevelAttrs } from '../selectors/section'
import { setUserTypeAttributes } from '../actions/user'

const initialState = {
  data: {
    firstName: '',
    lastName: '',
    roles: [],
    hasMultipleRoles: false,
    activeRoleCd: '',
    roleTypes: [],
    selectedRole: 0,
    selectedOrgId: null,
    isCoordinator: false,
    isLevelOne: false,
    isLevelTwo: false,
    isPreAPOnly: false,
    isTeacher: false,
    isStudent: false,
    isCSR: false,
    isSDP: false,
    isSchoolAdmin: false,
    isDistrictAdmin: false,
    isDistrictFundingAdmin: false,
    isDistrictSuperAdmin: false,
    termsAndConditions: {
      general: false,
    },
  },
  isLoading: false,
  isLoaded: false,
  hasAccess: true,
  error: null,
}

export default function userUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case FINISH_COORDINATOR_SETUP: {
      const { selectedRole, roles } = state.data
      return {
        ...state,
        data: {
          ...state.data,
          ...getOrgLevelAttrs({ ...roles[selectedRole], ...payload }),
          roles: state.data.roles.map(r =>
            r.orgId == payload.orgId
              ? { ...r, needsSetup: false, orderByAnotherSchool: payload.orderByAnotherSchool }
              : r
          ),
        },
      }
    }

    case UPDATE_EXAM_DECISIONS_FULFILLED:
      return {
        ...state,
        data: {
          ...state.data,
          roles: state.data.roles.map(r =>
            r.orgId == payload.orgId ? { ...r, editExamDecisionSettings: false } : r
          ),
        },
      }

    case c.USER_SET_SELECTED_ROLE:
      return { ...state, data: { ...state.data, ...payload } }

    case c.GET_USER_DETAILS_PENDING:
      return { ...state, isLoading: true, isLoaded: false }
    case c.GET_USER_DETAILS_FULFILLED:
      return {
        ...state,
        data: { ...state.data, ...payload },
        isLoading: false,
        isLoaded: true,
        hasAccess: true,
        error: null,
      }
    case c.GET_USER_DETAILS_NOACCESS:
      return { ...state, hasAccess: false, isLoading: false, isLoaded: true, error: null }
    case c.GET_USER_DETAILS_REJECTED:
      return { ...state, isLoading: false, isLoaded: true, error: payload }

    case c.ACCEPT_TERMS_AND_CONDITIONS_PENDING:
      return { ...state }
    case c.ACCEPT_TERMS_AND_CONDITIONS_FULFILLED:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
          termsAndConditions: { ...state.data.termsAndConditions, ...payload.termsAndConditions },
        },
      }
    case c.ACCEPT_TERMS_AND_CONDITIONS_REJECTED:
      return { ...state, error: payload }

    case c.SELECT_ROLE_PENDING:
      return { ...state, isLoading: true, isLoaded: false, error: null }

    case c.SELECT_ROLE_FULFILLED: {
      const roles = state.data.roles.filter(({ role }) => role === payload)
      const types = setUserTypeAttributes(roles[0])
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {
          ...state.data,
          ...types,
          activeRoleCd: payload,
          hasMultipleRoles: false,
          roleTypes: [payload],
          roles,
          selectedRole: 0,
        },
      }
    }

    case c.SELECT_ROLE_REJECTED:
      return { ...state, isLoading: false, isLoaded: false, error: payload }

    default:
      return state
  }
}
