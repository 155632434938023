import { change, getFormSyncErrors, getFormMeta } from 'redux-form'
import { Text, PositiveNumberValidation } from '../common'
import styles from '../../assets/style/scss/funding.scss'

const mapStateToProps = (state, { formName, amountField }) => ({
  hasError: Boolean(getFormSyncErrors(formName)(state)[amountField]),
  touched: getFormMeta(formName)(state)[amountField]?.touched,
})

const DistrictFundsControls = ({
  amountField,
  mustTakeField,
  formName,
  hasError,
  touched,
  testPrice,
  stateFunding = 0,
  updateCost,
  calcNewCost,
  change,
}) => {
  return (
    <>
      <Text
        name={amountField}
        isRequired={false}
        label="Your district funds: $"
        placeholder="Funding"
        validate={[PositiveNumberValidation]}
        maxlength={3}
        formGroupStyle={{
          display: 'inline-block',
          position: 'relative',
          marginBottom: hasError && touched ? '24px' : 0,
        }}
        inputStyle={{ display: 'inline-block', width: '80px', marginLeft: '5px' }}
        errorStyle={{ position: 'absolute', width: '500px', top: '55px' }}
        onChange={e => {
          const fundingAmount = parseInt(e.target.value, 10)
          updateCost(calcNewCost(testPrice, stateFunding, fundingAmount))
          if (fundingAmount < 1) {
            change(formName, mustTakeField, false)
          }
        }}
      />
      <button
        type="button"
        className={`btn btn-sm ${styles['pay-remainder']}`}
        onClick={() => {
          const districtFunding = testPrice - stateFunding
          change(formName, amountField, districtFunding)
          updateCost(calcNewCost(testPrice, stateFunding, districtFunding))
        }}
      >
        Pay Remainder
      </button>
    </>
  )
}

export default connect(mapStateToProps, { change })(DistrictFundsControls)
