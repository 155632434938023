import * as c from '../constants/SectionConstants'

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    //FULFILLED
    case c.FETCH_SINGLE_SECTION_FULFILLED:
      return {
        ...state,
        data: payload,
        fetching: false,
        fetched: true,
      }

    //PENDING REQUESTS
    case c.FETCH_SINGLE_SECTION_PENDING:
      return { ...state, fetching: true, fetched: false, error: null }

    //ERROR REQUESTS
    case c.FETCH_SINGLE_SECTION_REJECTED:
      return { ...state, fetching: false, fetched: false, error: payload }

    //RESET
    case c.RESET_SECTION_DATA:
      return initialState

    //UPDATE
    case c.UPDATE_SECTION_DATA:
      return { ...state, data: { ...state.data, ...payload } }

    default:
      return state
  }
}
