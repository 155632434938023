import { LoginHeroWidget } from '../../components/common'

export default () => (
  <CSSTransition
    classNames="animate-fade"
    in={true}
    timeout={500}
    appear={true}
    enter={false}
    exit={false}
  >
    <div>
      <LoginHeroWidget />
    </div>
  </CSSTransition>
)
