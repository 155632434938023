import { numberFormat } from '../../../utils/numbers'
import { TableTemplate } from '.'
import { DisplayExamDate } from '../../common'
import { getExamWindowRefData } from '../../../selectors/examWindows'
import styles from '../../../assets/style/scss/table-utils.scss'

const mapStateToProps = state => ({ examWindowRefData: getExamWindowRefData(state) })

const materialsColumns = id => [
  {
    headerText: { title: 'Qty' },
    key: 'materialAdjustment',
    headerAttributes: { id: `${id}-Qty` },
  },
  {
    headerText: { title: 'Material' },
    key: 'materialName',
    sorting: 'asc',
    headerAttributes: { id: `${id}-Material` },
  },
  {
    headerText: { title: 'Course' },
    key: ['course', 'materialName'],
    sorting: ['asc', 'asc'],
    headerAttributes: { id: `${id}-Course` },
  },
  {
    headerText: { title: 'Exam Date' },
    key: ['examDate', 'course', 'materialName'],
    sorting: ['asc', 'asc', 'asc'],
    headerAttributes: { id: `${id}-ExamDate` },
    defaultSort: true,
  },
]

const renderMaterialsChangesRow = (data, examWindowRefData) =>
  data.map((m, i) => (
    <tr key={i}>
      <td className={styles['numeric-column']}>
        {Number.isNaN(parseInt(m.materialAdjustment, 10))
          ? m.materialAdjustment
          : m.materialAdjustment > 0
          ? numberFormat(m.materialAdjustment)
          : 0}
      </td>
      <th scope="row">{m.materialName}</th>
      <td>{m.course}</td>
      <td className={styles['with-icon']}>
        <div className={`${styles['exam-date-wrapper']} ${styles.flexible}`}>
          {m.examDate ? (
            <DisplayExamDate course={m} />
          ) : m.examDateType ? (
            <>{examWindowRefData[m.examDateType]?.displayName} Testing</>
          ) : null}
        </div>
      </td>
    </tr>
  ))

export default connect(mapStateToProps)(
  ({
    data = [],
    examWindowRefData,
    headerStyle = { marginTop: '60px', marginBottom: '5px' },
    id,
    caption = 'View of all materials adjustments',
    summary = 'Materials adjustments to your order are listed by course name in ascending order. Additional sorting options are available by clicking on the column headers.',
    emptyText = 'This order does not have any materials adjustments.',
    error,
  }) => (
    <TableTemplate
      header="Materials Adjustments"
      headerStyle={headerStyle}
      emptyText={emptyText}
      error={error}
      tableAttrs={{
        id,
        caption,
        summary,
        data,
        rowBuilder: () => renderMaterialsChangesRow(data, examWindowRefData),
        columns: materialsColumns(id),
      }}
    />
  )
)
