import { SelectSchool, BackLink } from '.'

import styles from '../../assets/style/scss/navigation.scss'

const mapStateToProps = state => {
  const { linkRoute, linkText, suppressNav, suppressSchoolMenu } = state.nav
  return {
    linkRoute,
    linkText,
    suppressNav,
    suppressSchoolMenu,
  }
}

export default connect(mapStateToProps)(
  ({ linkRoute, linkText, suppressNav, suppressSchoolMenu }) => {
    if (suppressNav) {
      return null
    }

    return (
      <div className={styles['secondary-nav']}>
        <div className="container">
          <div className="row">
            {linkRoute !== '' && linkText !== '' ? (
              <div>
                <div className="col-sm-6 col-xs-12">
                  <BackLink linkText={linkText} linkRoute={linkRoute} />
                </div>
                {!suppressSchoolMenu ? (
                  <div className="col-sm-6 col-xs-12">
                    <SelectSchool alignSchoolMenuRight={true} />
                  </div>
                ) : null}
              </div>
            ) : !suppressSchoolMenu ? (
              <div className="col-xs-12">
                <SelectSchool alignSchoolMenuRight={true} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
)
