import { formatDate, DATETIME_FORMATS } from '@myap/ui-library/esm/date'
import { hasFullYearSections, hasSecondSemesterSections } from '../../selectors/section'
import { COURSE_SCHEDULE_TYPES } from '../../constants/SectionConstants'
import { openModal } from '../../actions/app'
import { isAPCourse, isCareerKickstartCourse } from '../../selectors/course'

const mapStateToProps = (state, ownProps) => {
  const {
    settingsExamDecisions,
    user: {
      data: { selectedOrgId, isLevelOne },
    },
  } = state
  const { data: orgExamDecision } = settingsExamDecisions[selectedOrgId] || {}
  const {
    standardDecisionDeadline,
    secondSemesterDecisionDeadline,
    enableStudentDecisions,
    enableTeacherConfirmation,
  } = orgExamDecision || {}
  const {
    courses: {
      entities: { sections },
    },
  } = state
  const section = sections[ownProps.sectionId]
  const sectionIsFY = section && section.sectionType === COURSE_SCHEDULE_TYPES.S_FY.value

  return {
    formattedStandardDecisionDeadline: formatDate(
      standardDecisionDeadline,
      DATETIME_FORMATS.longMonthDayYear
    ),
    formattedSecondSemesterDecisionDeadline: formatDate(
      secondSemesterDecisionDeadline,
      DATETIME_FORMATS.longMonthDayYear
    ),
    enableTeacherConfirmation,
    hasSSSections: hasSecondSemesterSections(sections),
    hasFYSections: hasFullYearSections(sections),
    section: section || {},
    sectionIsFY,
    showNotice:
      enableStudentDecisions &&
      !isLevelOne &&
      ((section && (isAPCourse(section) || isCareerKickstartCourse(section))) || !section),
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ openModal }, dispatch)

export class ExamDecisionDeadlineNotice extends Component {
  handleClick(e) {
    const { openModal, section } = this.props

    e.preventDefault()
    openModal('ConfirmExamDecisionModal', {
      sectionId: section.sectionId,
      modalCloseFocusElem: this.confirmationWrapper,
    })
  }

  headDateDeadline() {
    const {
      section,
      isSectionDetails,
      sectionId,
      sectionIsFY,
      hasFYSections,
      hasSSSections,
      formattedStandardDecisionDeadline,
      formattedSecondSemesterDecisionDeadline,
    } = this.props
    let calendarIconStyles = { marginRight: '6px' }

    if (isSectionDetails) {
      return (
        <div>
          <span className="cb-glyph cb-cal-full" aria-hidden={true} style={calendarIconStyles} />{' '}
          {sectionIsFY
            ? formattedStandardDecisionDeadline
            : formattedSecondSemesterDecisionDeadline}
        </div>
      )
    } else {
      if (hasFYSections && hasSSSections) return null
      else
        return (
          <div>
            <span className="cb-glyph cb-cal-full" aria-hidden={true} style={calendarIconStyles} />{' '}
            {hasSSSections
              ? formattedSecondSemesterDecisionDeadline
              : formattedStandardDecisionDeadline}
          </div>
        )
    }
  }

  teacherConfirmationMessage() {
    const { enableTeacherConfirmation, isSectionDetails } = this.props

    if (enableTeacherConfirmation) {
      if (isSectionDetails)
        return 'Your AP Coordinator has requested that you review and confirm their exam decisions before this deadline.'
      else
        return 'Your AP Coordinator has requested that you also review the list of confirmed students before this deadline.'
    } else return null
  }

  decisionDeadlineMessage() {
    const {
      isSectionDetails,
      sectionIsFY,
      formattedStandardDecisionDeadline,
      formattedSecondSemesterDecisionDeadline,
    } = this.props

    if (isSectionDetails) {
      return `To avoid potential late fees, your students must enroll online and decide if they will take exams before ${
        sectionIsFY ? formattedStandardDecisionDeadline : formattedSecondSemesterDecisionDeadline
      }.`
    } else
      return 'To avoid potential late fees, your students must enroll online and confirm they are taking exams before the decision deadline.'
  }

  multiDecisionDeadlineBlock() {
    const {
      isSectionDetails,
      hasFYSections,
      hasSSSections,
      formattedStandardDecisionDeadline,
      formattedSecondSemesterDecisionDeadline,
    } = this.props
    let deadlineHeaderStyles = {
      fontWeight: 'bold',
    }
    let deadlineDateStyles = {
      marginLeft: '26px',
      marginBottom: '10px',
    }
    let calendarIconStyles = { marginRight: '6px' }

    if (!isSectionDetails && hasFYSections && hasSSSections) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <div style={deadlineHeaderStyles}>
              <span
                className="cb-glyph cb-cal-full"
                aria-hidden={true}
                style={calendarIconStyles}
              />{' '}
              Standard Decision Deadline
            </div>
            <div style={deadlineDateStyles}>{formattedStandardDecisionDeadline}</div>
            <div style={deadlineHeaderStyles}>
              <span
                className="cb-glyph cb-cal-full"
                aria-hidden={true}
                style={calendarIconStyles}
              />{' '}
              Second Semester Decision Deadline
            </div>
            <div style={deadlineDateStyles}>{formattedSecondSemesterDecisionDeadline}</div>
          </div>
        </div>
      )
    } else return null
  }

  teacherConfirmationBlock() {
    const {
      section: { teacherConfirmedExamDecisionsDate, teacherCanConfirmExamDecisions },
      sectionId,
      enableTeacherConfirmation,
    } = this.props

    if (enableTeacherConfirmation) {
      return (
        <div style={{ marginTop: '1.5em' }}>
          <p style={{ marginBottom: '1.5em' }}>
            After you have confirmed, decisions will be locked and can only be changed by your AP
            coordinator.
          </p>
          <div ref={node => (this.confirmationWrapper = node)}>
            {teacherConfirmedExamDecisionsDate ? (
              <div>
                <i
                  className="cb-glyph cb-check"
                  aria-hidden="true"
                  style={{ color: 'rgb(50, 200, 50)' }}
                />{' '}
                Confirmed on{' '}
                {formatDate(teacherConfirmedExamDecisionsDate, DATETIME_FORMATS.longMonthDayYear)}
              </div>
            ) : teacherCanConfirmExamDecisions ? (
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={e => this.handleClick(e)}
              >
                Confirm Exam Decisions
              </button>
            ) : null}
          </div>
        </div>
      )
    } else return null
  }

  render() {
    const {
      formattedStandardDecisionDeadline,
      formattedSecondSemesterDecisionDeadline,
      enableTeacherConfirmation,
      isSectionDetails,
      hasSSSections,
      hasFYSections,
      showNotice,
    } = this.props

    let examDecisionDeadlineStyles = {
      borderColor: 'rgb(0, 98, 152)',
      borderWidth: '5px 0 2px',
      borderStyle: 'solid',
      padding: '10px 0',
      marginBottom: '1.5em',
    }

    if (showNotice) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <h3>Exam Decision Deadline</h3>
            <div style={examDecisionDeadlineStyles}>
              {this.headDateDeadline()}
              <p>
                {this.decisionDeadlineMessage()} {this.teacherConfirmationMessage()}
              </p>
              {this.multiDecisionDeadlineBlock()}
            </div>
            {isSectionDetails ? this.teacherConfirmationBlock() : null}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamDecisionDeadlineNotice)
